import React from "react";
import AddForm from './form';
import {
    Spinner
} from "reactstrap";
import { subCategoryUpdate, subCategoryDetail } from '../../services/actions/subCategory';
import { category } from '../../services/actions/category'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { initialStateSub } from '../../services/actions/stateInitial'
import { toast } from 'react-toastify';
import { addSubValid } from '../../sharedFile/validator';
var toastId = null
class EditSubCate extends React.Component {
    state = {
        data: {
            name: '',
            description: '',
            categoryId: ''
        },
        errors: {},
        id: this.props.editId,
        loader: false,
        loaderGet: false,
        message: "",
        pageNumber: 1
    }
    onSubmit = (e) => {
        e.preventDefault();
        const errors = addSubValid(this.state.data);
        this.setState({ errors });
        if (Object.keys(errors).length === 0) {
            this.setState({
                loader: true
            })
            this.props.subCategoryUpdate(this.state.data, this.state.id)
        }
    }
    onChange = (e) => {
        this.setState({
            data: {
                ...this.state.data,
                [e.target.name]: e.target.value.trimLeft().replace(/\s\s+/g, ' ')
            }
        })
    }
    componentDidMount() {
        const { id } = this.state
        this.props.category();
        this.props.subCategoryDetail(id);
        this.setState({
            loaderGet: true
        })
    }
    componentWillReceiveProps(newProps) {
        const { subListDetail, subListUpdate } = newProps
        var items = subListDetail && subListDetail.subcategory && subListDetail.subcategory
        if (subListDetail) {
            if (subListDetail.success) {
                this.setState({
                    data: {
                        ...this.state.data,
                        name: items.name,
                        description: items.description,
                        categoryId: items.categoryId,
                        slug: subListDetail && subListDetail.mainCat
                    },
                    slugCate: items.subcategoryCategory.slug,
                    loader: false,
                    loaderGet: false,
                })
                if (toast.isActive(toastId)) {
                    toast.dismiss(toastId)
                    toastId = toast.success(subListDetail.message);
                } else {
                    toastId = toast.success(subListDetail.message);
                }
                if (subListDetail.message) {
                    newProps.initialStateSub()
                }
            }
        }
        if (subListUpdate && subListUpdate.success === false) {
            this.setState({
                loader: false,
                loaderGet: false,
            })
            newProps.initialStateSub()
        }
    }
    render() {
        const { categorySuccess, location } = this.props
        const { loaderGet } = this.state
        return (
            <>
                {
                    !loaderGet ?
                        <AddForm  {...this.state} category={categorySuccess} onSubmit={this.onSubmit} onChange={this.onChange} title="Edit Sub Category" location={location} />
                        :
                        <div className="text-center">
                            <Spinner />
                        </div>
                }
            </>
        );
    }
}
const mapDispatchState = (dispatch) => {
    return bindActionCreators({
        subCategoryUpdate,
        initialStateSub,
        subCategoryDetail,
        category
    }, dispatch)
}
const mapGetStateNew = (state) => {
    return {
        categorySuccess: state.category.category,
        subListDetail: state.subCategory.detailSubCategory,
        subListUpdate: state.subCategory.updateSubCategory,
        subListRedirect: state.subCategory,
    }
}
export default connect(mapGetStateNew, mapDispatchState)(EditSubCate);
