import React from "react";
import Header from "../../components/Headers/Header";
import {viewVarient} from '../../services/actions/varient'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Nav,
  } from "reactstrap";
class ViewVarient extends React.Component {
  state = {
    data:{
      category: '',
      name:'',
      value: '',
      description:''
    },
    errors:{},
    loader:false,
    message: ""
  }
  componentDidMount()  {
    this.props.viewVarient(this.props.match.params.id);
    this.setState({
      loader: true
    })
}
componentWillReceiveProps(newProps){
    const varient=newProps.detailvarient&&newProps.detailvarient.varient
    if(newProps.detailvarient&&newProps.detailvarient.success){
      this.setState({
        data:{
          ...this.state.data,
          name:varient.name,
          description: varient.description,
          value: varient.value,
        },
        loader: false
      })
    }else{
      this.setState({
        loader:false
      })
      toast.error(newProps.detailvarient)
    }
  }
  render() {
    return (
      <>
        <Header />
        <ToastContainer autoClose={10000}/>
        <Container className="mt-0" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="card_border">
                <CardHeader className="bg-white border-0 mb-4">
                <Row className="align-items-center mobile_sec_col">
                  <div className="col">
                    <h2 className="mb-0 text-mob-left text-center">View Variant</h2>
                  </div>
                  <div className="col back_btn">
                      <Nav className="justify-content-end" pills>
                        <NavItem>
                          <NavLink className="btn btn-primary " to={'/variants'} tag={Link}  >Back</NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                </Row>
                </CardHeader>
                <CardBody className="pt-0 detail_page mb-5">
                        {/* <Row>
                            <Col lg="4">
                            <CardBody><h4>Name:</h4></CardBody>
                            </Col>
                            <Col lg="8">
                            <CardBody>{this.state.data.name}</CardBody>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4">                       
                            <CardBody><h4>Description:</h4></CardBody>
                            </Col>
                            <Col lg="8">
                            <CardBody>{this.state.data.description}</CardBody>
                            </Col>
                        
                        </Row>  */}
                        <Row>
                          <Col className="shadow p-md-4 p-3" md="8">
                            <h3 className="mb-md-3 mb-2">Category Detail</h3>
                            <CardBody className="p-1">
                              <h4 className="mb-0">Name:</h4>
                              <p className="mb-0">{this.state.data.name}</p>
                            </CardBody>
                            <CardBody className="p-1">
                              <h4 className="mb-0">Description:</h4>
                              <p className="mb-0">{this.state.data.description}</p>
                            </CardBody>
                        </Col>
                      </Row>
                </CardBody>
            </Card>
            </Col>
        </Row>
        </Container>
      </>
    );
  }
}
const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    viewVarient
  }, dispatch)
}
const mapGetStateNew = (state) => {
  return {
    detailvarient: state.varient.detailvarient
  }
}
export default connect(mapGetStateNew, mapDispatchState)(ViewVarient);
