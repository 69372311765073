
import React from "react";
import {
  Container,
  Row,
  Button,
  Spinner
} from "reactstrap";
// core components
import Header from "../../components/Headers/Header.jsx";
import Table from "./varientsTable"
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { varient, deleteVarient } from '../../services/actions/varient';
import ConfirmModal from '../../components/ConfirmModal';
import FormModel from "../../components/formModel.jsx";
import { initialState } from '../../services/actions/stateInitial'
import AddVarient from "./AddVarient.jsx";
import EditVarient from "./EditVarient.jsx";
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import { MDBIcon } from 'mdbreact';
var toastId = null

class VarientsList extends React.Component {

  state = {
    data: {
      columns: [
        { label: '#', field: '#', sort: 'asc', width: 150 },
        { label: 'Variant Name', field: 'Name', sort: 'asc', width: 200 },
        { label: 'Variant Description', field: 'Description', sort: 'asc', width: 100 },
        { label: 'Created on', field: 'Created on', sort: 'asc' },
        { label: 'Updated on', field: 'Updated on', sort: 'asc' },
        { label: 'Action', field: 'Action', width: 150, sort: 'disabled' }
      ],
      rows: []
    },
    pageNumber: 1,
    loader: false,
    open: false,
    id: '',
    openEditFrom: false,
    openForm: false,
    editId: null,
    name: ''
  }
  componentDidMount() {
    this.props.varient();
    this.setState({
      loader: true
    })
  }
  confirm = (event, id, name) => {
    this.setState({
      open: true,
      id: id,
      name: name
    })
  }
  toggle = () => {
    this.setState({
      open: false,
      openEditFrom: false,
      openForm: false,
    })
  }
  confirmation = (event, id) => {
    this.setState({
      open: false
    })
    this.props.deleteVarient(id)
  }
  componentWillReceiveProps(props) {
    const { varientSuccess, updateRedirect, detailvarient, deleteVarientFailed } = props
    if (updateRedirect.redirect) {
      this.setState({
        openEditFrom: false
      })
    }
    if (deleteVarientFailed && deleteVarientFailed.success === false) {
      if (deleteVarientFailed.message) {
        if (toast.isActive(toastId)) {
          toast.dismiss(toastId)
          toastId = toast.error(deleteVarientFailed.message);
          props.initialState();
        } else {
          toastId = toast.error(deleteVarientFailed.message);
          props.initialState();
        }
      }
    }
    if (detailvarient && detailvarient.success === false) {
      if (detailvarient.message && toast.isActive(toastId)) {
        toast.dismiss(toastId)
        toastId = toast.error(detailvarient.message);
      } else {
        toastId = toast.error(detailvarient.message);
      }
    }
    if (varientSuccess && varientSuccess.success) {
      let descrip
      var testData = varientSuccess.varients && varientSuccess.varients.map((items, key) => {
        if (items.description.length > 30){
          descrip = items.description.substring(0, 30) + '...';
        }
        return (
          {
            '#': key + 1,
            'Name': items.name,
            'Value': items.value,
            'Description': <>{items.description.length > 30 ? <span className="tooltip1" key={'abcdeZ' + key} >{descrip}<span className="tooltiptext textFLow">{items.description}</span></span> : items.description}</>,
            'Created on': moment(items.createdAt).utcOffset("+05:30").format('DD/MM/YY, H:mm'),
            'Updated on': moment(items.updatedAt).format('DD/MM/YY, H:mm'),
            'Action': [<>{items.deletable !== 'Yes' ? <div className="text-danger" style={{ fontSize: '12px' }}>Cant Edit/Delete</div> : ''}</>, <>{items.deletable === 'Yes' ? <Button className="action_btn text-black" outline color="" onClick={e => this.editFrom(e, items.id)}><MDBIcon far icon="edit" title="Edit" /></Button> : <></>}</>, <>{items.deletable === 'Yes' ? <Button className="action_btn text-black" outline color="" onClick={e => this.confirm(e, items.id, items.name)}><MDBIcon far icon="trash-alt" title="Delete" /></Button> : <></>}</>]
          }
        )
      })
      if (updateRedirect.deleted) {
        if (toast.isActive(toastId)) {
          toast.dismiss(toastId)
          toastId = toast.success(updateRedirect.messageDelete);
          props.initialState();
        } else {
          toastId = toast.success(updateRedirect.messageDelete);
          props.initialState();
        }
      }
      this.setState({
        data: {
          ...this.state.data,
          rows: testData
        },
        loader: false
      })
    }
    if (varientSuccess && varientSuccess.success === false) {
      this.setState({
        loader: false
      })
    }
  }
  addForm = () => {
    this.setState({
      openForm: true
    })
  }
  editFrom = (e, id) => {
    this.setState({
      openEditFrom: true,
      editId: id
    })
  }
  render() {
    const { open, loader, openForm, openEditFrom, editId } = this.state
    return (
      <>
        <ToastContainer autoClose={4000} />
        {
          !loader ?
            <>
              <Header />
              <Container className="mt-0" fluid>
                <Row>
                  <div className="col">
                    <Table title="Variants Listing" confirm={this.confirm} addForm={this.addForm} {...this.state} />
                    <ConfirmModal open={open} toggle={this.toggle} confirmation={this.confirmation} id={this.state.id} name={this.state.name} />
                    <FormModel modelForm={<AddVarient />} toggle={this.toggle} openForm={openForm} />
                    <FormModel modelForm={<EditVarient editId={editId} />} toggle={this.toggle} openForm={openEditFrom} />
                  </div>
                </Row>
              </Container>
            </>
            :
            <div className="loader">
              <Spinner variant="light" />
              <p style={{ color: '#fff', paddingTop: '15px' }}>Please wait......</p>
            </div>
        }
      </>
    );
  }
}
const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    varient,
    deleteVarient,
    initialState
  }, dispatch)
}
const mapGetStateNew = (state) => {
  return {
    varientSuccess: state.varient.varient,
    detailvarient: state.varient.detailvarient,
    updateRedirect: state.varient,
    deleteVarientFailed: state.varient.deleteVarient
  }
}
export default connect(mapGetStateNew, mapDispatchState)(VarientsList);
