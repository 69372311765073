import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { brand } from '../../../services/actions/brand';
import { uploadOrder, initialState } from '../../../services/actions/order';
import { uploadOrderValid } from '../../../sharedFile/validator';
import OrdersFrom from "./orderForm";
var toastId = null
class Addorder extends React.Component {
  state = {
    data: {
      brandId: '',
      picture: []
    },
    loader: false,
    loaderBrand: false,
    errors: {},
  }
  componentDidMount() {
    this.props.brand();
    this.setState({
      loaderBrand: true
    })
  }
  onChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value
      }
    })
  }
  onFileChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        picture: e.target.files
      }
    })
  }

  onSubmit = (e) => {
    e.preventDefault();
    const errors = uploadOrderValid(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({
        loader: true
      })
      this.props.uploadOrder(this.state.data)
    }
  }

  componentWillReceiveProps(newProps) {
    const { brandSuccess } = newProps
    if (brandSuccess && brandSuccess.success || brandSuccess && brandSuccess.success === false) {
      this.setState({
        loaderBrand: false,
        loader: false
      })
    }
    if (newProps.orderUpload && newProps.orderUpload.success) {
      if (newProps.orderUpload && newProps.orderUpload.message) {
        toastId = toast.success(newProps.orderUpload.message);
      }
      setTimeout(function () { newProps.history.push('/mainordersheet/' + newProps.orderUpload.order.id) }, 1000);
      this.setState({
        loader: false
      })
    } else {
      this.setState({
        loader: false
      })
    } if (newProps.orderUpload && newProps.orderUpload.success === false) {
      this.setState({
        loader: false
      })
      if (!toast.isActive(toastId))
        toastId = toast.error(newProps.orderUpload.message)
    } else {
      this.setState({
        loader: false
      })
    }
  }
  componentWillUnmount() {
    this.props.initialState()
  }
  render() {
    const { brandSuccess, location } = this.props
    return (
      <>
        <ToastContainer autoClose={10000} />
        <OrdersFrom onSubmit={this.onSubmit} onChange={this.onChange} onFileChange={this.onFileChange} brandSuccess={brandSuccess} location={location} {...this.state} />
      </>
    );
  }
}

const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    brand,
    uploadOrder,
    initialState
  }, dispatch)
}
const mapGetStateNew = (state) => {
  return {
    brandSuccess: state.brand.brand,
    orderUpload: state.order.uploadorder
  }
}
export default connect(mapGetStateNew, mapDispatchState)(Addorder);
