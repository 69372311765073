import React from "react";
import Header from "../../components/Headers/Header";
import AddForm from './addForm';
import { addVarient } from '../../services/actions/varient';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addVarientValid } from '../../sharedFile/validator';
import { initialState } from '../../services/actions/stateInitial'
import { toast } from 'react-toastify';
var toastId = null
class AddVarient extends React.Component {
  state = {
    data: {
      name: '',
      description: ''
    },
    errors: {},
    loader: false,
    message: "",
    pageNumber: 1
  }
  onSubmit = (e) => {
    const {data} = this.state
    e.preventDefault();
    const errors = addVarientValid(data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({
        loader: true
      })
      this.props.addVarient(data)
    }
  }
  onChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value.trimLeft().replace( /\s\s+/g, ' ' )
      }
    })
  }
  componentWillReceiveProps(newProps) {
    const {varientAddSuccess} = newProps
    if (varientAddSuccess && varientAddSuccess.success) {
      this.setState({
        data: {
          ...this.state.data,
          name: '',
          description: ''
        },
        loader: false
      })
      if (toast.isActive(toastId)) {
        toast.dismiss(toastId)
        toastId = toast.success(varientAddSuccess.message);
        newProps.initialState()
      } else {
        toastId = toast.success(varientAddSuccess.message);
        newProps.initialState()
      }
    }
    if (varientAddSuccess && varientAddSuccess.success === false) {
      this.setState({
        loader: false
      })
      if (toast.isActive(toastId)) {
        toast.dismiss(toastId)
        toastId = toast.error(varientAddSuccess.message);
        newProps.initialState()
      } else {
        toastId = toast.error(varientAddSuccess.message);
        newProps.initialState()
      }
    }
  }
  componentWillMount() {
    this.props.initialState()
  }
  render() {
    return (
      <>
        <AddForm  {...this.state} onSubmit={this.onSubmit} onChange={this.onChange} />
      </>
    );
  }
}
const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    addVarient,
    initialState
  }, dispatch)
}
const mapGetStateNew = (state) => {
  return {
    varientAddSuccess: state.varient.varientAdd
  }
}
export default connect(mapGetStateNew, mapDispatchState)(AddVarient);
