import {
    SET_VARIENT_FAILED,
    SET_VARIENT_SUCCESS,
    ADD_VARIENT_FAILED,
    ADD_VARIENT_SUCCESS,
    DELETE_VARIENT_FAILED,
    DELETE_VARIENT_SUCCESS,
    VIEW_VARIENT_FAILED,
    VIEW_VARIENT_SUCCESS,
    UPDATE_VARIENT_FAILED,
    UPDATE_VARIENT_SUCCESS,
    ASSIGN_VARIENT_SUCCESS,
    ASSIGN_VARIENT_FAILED,
    SET_VARIENT_SLUG_SUCCESS,
    SET_VARIENT_SLUG_FAILED,
    GET_INITIALSTATE,
    GET_INITIALSTATE_VARIENT
} from "../../sharedFile/actionType";

const initialState = {
    varient: [],
    error: false,
    redirect: false,
    deleted: false
}
const varient = (state = initialState, action) => {
    switch (action.type) {
        case SET_VARIENT_SLUG_SUCCESS:
            return {
                varient: action.data,
                redirect: false
            }
        case SET_VARIENT_SLUG_FAILED:
            return {
                varient: action.error,
                error: true
            }
        case SET_VARIENT_SUCCESS:
            return {
                ...state,
                varient: action.data,
                redirect: false
            }
        case SET_VARIENT_FAILED:
            return {
                ...state,
                error: true,
                varient: action.error,
                redirect: false
            }
        case ADD_VARIENT_SUCCESS:
            return {
                ...state,
                varientAdd: action.data,
                redirect: false
            }
        case ADD_VARIENT_FAILED:
            return {
                ...state,
                error: true,
                varientAdd: action.error
            }
        case DELETE_VARIENT_SUCCESS:
            return {
                ...state,
                varient: {varients: state.varient.varients.filter(item => item.id !== action.data.varients.id), success: true},
                messageDelete: 'Variant Deleted Successfully',
                deleted: true
            }
        case DELETE_VARIENT_FAILED:
            return {
                ...state,
                error: true,
                deleteVarient:action.error
            }
        case VIEW_VARIENT_SUCCESS:
            return {
                ...state,
                detailvarient: action.data
            }
        case VIEW_VARIENT_FAILED:
            return {
                ...state,
                error: true,
                detailvarient: action.error
            }
        case UPDATE_VARIENT_SUCCESS:
            return {
                ...state,
                detailvarient: action.data,
                redirect: action.redirect
            }
        case UPDATE_VARIENT_FAILED:
            return {
                ...state,
                error: true,
                detailvarient: action.error
            }
        case ASSIGN_VARIENT_SUCCESS:
            return {
                ...state,
                assign: action.data
            }
        case ASSIGN_VARIENT_FAILED:
            return {
                ...state,
                error: true,
                assign: { error: true, message: action.error }
            }
        case GET_INITIALSTATE:
            return {
                ...state,
                varientAdd: [],
                detailvarient: [],
                redirect: false,
                deleteVarient:[],
                deleted: false
            }
        case GET_INITIALSTATE_VARIENT:
            return{
                ...state,
                varient:[]
            }
        default:
            return state
    }

}
export default varient;