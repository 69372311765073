import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
const FormModel = (props) => {
  return (
    <div>
      <Modal isOpen={props.openForm} toggle={props.toggle} className='modal-dialog-centered'>
        <div className="col crossBtn">
          <IconButton aria-label="close" onClick={props.toggle}><CloseIcon /></IconButton >
        </div>
        <ModalBody>
          {props.modelForm}
        </ModalBody>
      </Modal>
    </div>
  );
}
export default FormModel;