import React from "react";
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import {
  Container,
  NavItem,
  NavLink,
  Nav,
  Card,
  CardHeader,
  Row,
  Button,
  Spinner,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { getBrandOrder, editMainOrder, deleteMainOrder, repeatOrder, initialState, noteinitialState } from '../../../services/actions/order';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import EditTable from '../../../components/EditTable'
import { imagerUrl } from '../../../config/config';
import ConfirmModal from '../../../components/ConfirmModal';
import RepeatConfirmModal from '../../../components/repeatOrderConfirmation';
var toastId = null
class Mainordersheet extends React.Component {
  state = {
    data: {
      columns: [
        { label: "Action", field: "Action", class: '' },
        { label: '#', field: 'Sr No', sort: 'asc', },
        { label: 'Sub Brand', field: 'Sub Brand', sort: 'asc', },
        { label: 'Style No', field: 'Style No', sort: 'asc', },
        { label: 'Style code', field: 'Style code', sort: 'asc', },
        { label: 'Price', field: 'Price', sort: 'asc', },
        { label: 'Delivery month', field: 'Delivery month', sort: 'asc', },
        { label: 'XS', field: 'xs', sort: 'asc', },
        { label: 'S', field: 's', sort: 'asc', },
        { label: 'M', field: 'm', sort: 'asc', },
        { label: 'L', field: 'l', sort: 'asc', },
        { label: 'XL', field: 'xl', sort: 'asc', },
        { label: 'XXL', field: 'xxl', sort: 'asc', },
        { label: '3XL', field: '3xl', sort: 'asc', },
        { label: '4XL', field: '4xl', sort: 'asc', },
        { label: '5XL', field: '5xl', sort: 'asc', },
        { label: 'Grand Total', field: 'Grand Total', sort: 'asc', },
        { label: 'Remarks', field: 'Remarks', sort: 'asc', },
      ],
      rows: []
    },
    brand: '',
    brandImg: '',
    brandOrderId: this.props.match.params.id,
    rows: [],
    pageNumber: 1,
    loader: false,
    errors: {},
    total: '',
    search: '',
    open: false,
    openRepeat: false,
    modelId: '',
    name: ''
  }

  componentDidMount() {
    const { brandOrderId, pageNumber } = this.state
    this.props.getBrandOrder({ brandOrderId, pageNumber });
    this.setState({
      loader: true
    })
  }
  searchChange = (e) => {
    const { brandOrderId, pageNumber } = this.state
    this.setState({
      ...this.state,
      search: e.target.value,
      data: {
        ...this.state.data,
        rows:[]
      }
    })
    this.props.getBrandOrder({ brandOrderId, pageNumber, search: e.target.value });
  }
  changePage = (e, pageNumber) => {
    const { brandOrderId } = this.state
    this.setState({
      ...this.state,
      pageNumber: pageNumber
    })
    this.props.getBrandOrder({ brandOrderId, pageNumber: pageNumber, search: this.state.search });
  }
  onKeyPress(evt) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }
  valuesEditChange(value, key, rowId, rowsName, rows) {
    if (key === rowId) {
      value['Action'] = [<i className="fas fa-check pr-2 cursor-pointer" onClick={e => (this.onSubmit(e, key))} key={key}></i>]
      value['Sr No'] = rows[key]['Sr No']
      value['Sub Brand'] = [<input type="text" name="subBrand" value={rowsName[key]['subBrand']} key={'subBrand' + key} onChange={e => (this.onChange(e, key))} />]
      value['Style No'] = [<input type="text" name="styleNo" value={rowsName[key]['styleNo']} key={'styleNo' + key} onChange={e => (this.onChange(e, key))} />]
      value['Style code'] = [<input type="text" name="styleCode" value={rowsName[key]['styleCode']} key={'styleCode' + key} onChange={e => (this.onChange(e, key))} />]
      value['Price'] = [<input type="text" name="price" value={rowsName[key]['price']} key={'price' + key} onChange={e => (this.onChange(e, key))} onKeyPress={this.onKeyPress.bind(this)} />]
      value['Delivery month'] = [<input type="text" name="delivery" value={rowsName[key]['delivery']} key={'delivery' + key} onChange={e => (this.onChange(e, key))} />]
      value['xs'] = [<input type="text" onKeyPress={this.onKeyPress.bind(this)} name="xs" value={rowsName[key]['xs']} key={'xs' + key} onChange={e => (this.onChange(e, key))} />]
      value['s'] = [<input type="text" onKeyPress={this.onKeyPress.bind(this)} name="s" value={rowsName[key]['s']} key={'s' + key} onChange={e => (this.onChange(e, key))} />]
      value['m'] = [<input type="text" onKeyPress={this.onKeyPress.bind(this)} name="m" value={rowsName[key]['m']} key={'m' + key} onChange={e => (this.onChange(e, key))} />]
      value['l'] = [<input type="text" onKeyPress={this.onKeyPress.bind(this)} name="l" value={rowsName[key]['l']} key={'l' + key} onChange={e => (this.onChange(e, key))} />]
      value['xl'] = [<input type="text" onKeyPress={this.onKeyPress.bind(this)} name="xl" value={rowsName[key]['xl']} key={'xl' + key} onChange={e => (this.onChange(e, key))} />]
      value['xxl'] = [<input type="text" onKeyPress={this.onKeyPress.bind(this)} name="xxl" value={rowsName[key]['xxl']} key={'xxl' + key} onChange={e => (this.onChange(e, key))} />]
      value['3xl'] = [<input type="text" onKeyPress={this.onKeyPress.bind(this)} name="3xl" value={rowsName[key]['3xl']} key={'3xl' + key} onChange={e => (this.onChange(e, key))} />]
      value['4xl'] = [<input type="text" onKeyPress={this.onKeyPress.bind(this)} name="4xl" value={rowsName[key]['4xl']} key={'4xl' + key} onChange={e => (this.onChange(e, key))} />]
      value['5xl'] = [<input type="text" onKeyPress={this.onKeyPress.bind(this)} name="5xl" value={rowsName[key]['5xl']} key={'5xl' + key} onChange={e => (this.onChange(e, key))} />]
      value['Grand Total'] = rowsName[key]['total']
      value['Remarks'] = [<input type="text" onKeyPress={this.onKeyPress.bind(this)} name="remarks" value={rowsName[key]['remarks']} key={'remarks' + key} onChange={e => (this.onChange(e, key))} />]
    }else{
      value['Action'] = [<i title="Edit" style={{ margin: '0px 8px 0px 0px' }} className="disabledAction fas fa-pen pr-2 cursor-pointer" key={key}></i>, <i style={{ margin: '0px' }} key={'abc' + key} title="Delete" className="disabledAction fas fas fa-minus-circle cursor-pointer"></i>]
    }
  }
  valueSubmit(value, key, rowId, rowsName) {
    if (key === rowId) {
      value['Action'] = [<i title="Edit" style={{ margin: '0px 8px 0px 0px' }} className="fas fa-pen pr-2 cursor-pointer" key={key} onClick={e => (this.onEdit(e, key))}></i>, <i style={{ margin: '0px' }} key={'abc' + key} title="Delete" className="fas fas fa-minus-circle cursor-pointer" onClick={e => (this.confirm(e, key))} ></i>]
      value['Sr No'] = key + 1
      value['Sub Brand'] = rowsName[rowId]['subBrand']
      value['Style No'] = rowsName[rowId]['styleNo']
      value['Style code'] = rowsName[rowId]['styleCode']
      value['Price'] = rowsName[rowId]['price']
      value['Delivery month'] = rowsName[rowId]['delivery']
      value['xs'] = rowsName[rowId]['xs'] === '' ? 0 : rowsName[rowId]['xs']
      value['s'] = rowsName[rowId]['s'] === '' ? 0 : rowsName[rowId]['s']
      value['m'] = rowsName[rowId]['m'] === '' ? 0 : rowsName[rowId]['m']
      value['l'] = rowsName[rowId]['l'] === '' ? 0 : rowsName[rowId]['l']
      value['xl'] = rowsName[rowId]['xl'] === '' ? 0 : rowsName[rowId]['xl']
      value['xxl'] = rowsName[rowId]['xxl'] === '' ? 0 : rowsName[rowId]['xxl']
      value['3xl'] = rowsName[rowId]['3xl'] === '' ? 0 : rowsName[rowId]['3xl']
      value['4xl'] = rowsName[rowId]['4xl'] === '' ? 0 : rowsName[rowId]['4xl']
      value['5xl'] = rowsName[rowId]['5xl'] === '' ? 0 : rowsName[rowId]['5xl']
      value['Grand Total'] = rowsName[rowId]['total']
      value['Remarks'] = rowsName[rowId]['remarks']
    }
    else{
      value['Action'] = [<i title="Edit" style={{ margin: '0px 8px 0px 0px' }} className="fas fa-pen pr-2 cursor-pointer" key={key} onClick={e => (this.onEdit(e, key))}></i>, <i style={{ margin: '0px' }} key={'abc' + key} title="Delete" className="fas fas fa-minus-circle cursor-pointer" onClick={e => (this.confirm(e, key))} ></i>]
    }
  }
  onSubmit(e, rowId) {
    const rowsName = this.state.rows
    const { rows } = this.state.data
    var data = {
      id: rowsName[rowId]['id'],
      subbrand: rowsName[rowId]['subBrand'],
      styleno: rowsName[rowId]['styleNo'],
      stylecode: rowsName[rowId]['styleCode'],
      price: rowsName[rowId]['price'],
      deliverymonth: rowsName[rowId]['delivery'],
      xs: rowsName[rowId]['xs'] === '' ? 0 : rowsName[rowId]['xs'],
      s: rowsName[rowId]['s'] === '' ? 0 : rowsName[rowId]['s'],
      m: rowsName[rowId]['m'] === '' ? 0 : rowsName[rowId]['m'],
      l: rowsName[rowId]['l'] === '' ? 0 : rowsName[rowId]['l'],
      xl: rowsName[rowId]['xl'] === '' ? 0 : rowsName[rowId]['xl'],
      xxl: rowsName[rowId]['xxl'] === '' ? 0 : rowsName[rowId]['xxl'],
      '3xl': rowsName[rowId]['3xl'] === '' ? 0 : rowsName[rowId]['3xl'],
      '4xl': rowsName[rowId]['4xl'] === '' ? 0 : rowsName[rowId]['4xl'],
      '5xl': rowsName[rowId]['5xl'] === '' ? 0 : rowsName[rowId]['5xl'],
      total: rowsName[rowId]['total'],
      remarks: rowsName[rowId]['remarks'],
    }
    var row = rows.map((value, key) => {
      this.valueSubmit(value, key, rowId, rowsName)
      return value;
    })
    this.setState({
      data: {
        ...this.state.data,
        rows: row
      }
    })
    this.props.editMainOrder(data)
  }
  onChange = (e, rowId) => {
    const rowsName = this.state.rows
    const { rows } = this.state.data
    const { name } = e.target
    const row = rowsName.map((value, key) => {
      if (key === rowId) {
        value[name] = e.target.value
        if (name === 'xs' || name === 's' || name === 'm' || name === 'l' || name === 'xl' || name === 'xxl' || name === '3xl' || name === '4xl' || name === '5xl') {
          value['total'] = (value['xs'] === '' ? 0 : parseInt(value['xs'])) + (value['s'] === '' ? 0 : parseInt(value['s'])) + (value['m'] === '' ? 0 : parseInt(value['m'])) + (value['l'] === '' ? 0 : parseInt(value['l'])) + (value['xl'] === '' ? 0 : parseInt(value['xl'])) + (value['xxl'] === '' ? 0 : parseInt(value['xxl'])) + (value['3xl'] === '' ? 0 : parseInt(value['3xl'])) + (value['4xl'] === '' ? 0 : parseInt(value['4xl'])) + (value['5xl'] === '' ? 0 : parseInt(value['5xl']))
        }
      }
      return value;
    })
    const rowData = rows.map((value, key) => {
      this.valuesEditChange(value, key, rowId, rowsName, rows);
      return value;
    })
    this.setState({
      data: {
        ...this.state.data,
        rows: rowData
      },
      rows: row
    })
  }
  onEdit = (e, rowId) => {
    const { rows } = this.state.data;
    const rowsName = this.state.rows
    const row = rows.map((value, key) => {
      this.valuesEditChange(value, key, rowId, rowsName, rows);
      return value;
    })
    this.setState({
      data: {
        ...this.state.data,
        rows: row
      }
    })
  }
  confirm = (event, rowId) => {
    this.setState({
      open: true,
      modelId: rowId,
    })
  }
  toggle = () => {
    this.setState({
      open: false,
      openRepeat: false
    })
    this.props.noteinitialState()
  }
  confirmation = (event, rowId) => {
    this.setState({
      open: false,
    })
    const rowsName = this.state.rows
    this.props.deleteMainOrder(rowsName[rowId]['id'], this.state.pageNumber, this.props.match.params.id)
  }
  componentWillReceiveProps(newProps) {
    const { repeatOrderList, deleteMessage } = newProps
    if (repeatOrderList && repeatOrderList.success) {
      this.setState({
        loader: false
      })
      if (!toast.isActive(toastId))
        toastId = toast.success("Repeated order successfully added in old order list.")
    }
    if (repeatOrderList && repeatOrderList.success === false) {
      this.setState({
        loader: false
      })
      if (toast.isActive(toastId)){
        toast.dismiss(toastId)
        toastId = toast.error(repeatOrderList.message)
        newProps.initialState()
      }else {
        toastId = toast.error(repeatOrderList.message);
        newProps.initialState()
      }
    }
    if (newProps.brandorder && newProps.brandorder.success) {
      let brand = newProps.brandorder.order
      if (brand) {
        let data = brand.brandorder && brand.brandorder.map((value, key) => {
          return ({
            'Action': [<i style={{ margin: '0px 8px 0px 0px' }} title="Edit" className="fas fa-pen pr-2 cursor-pointer" key={key} onClick={e => (this.onEdit(e, key))}></i>, <i style={{ margin: '0px' }} key={'abc' + key} title="Delete" className="fas fas fa-minus-circle cursor-pointer" onClick={e => (this.confirm(e, key))} ></i>],
            'Sr No': ((this.state.pageNumber - 1) * 10) + (key + 1),
            'Sub Brand': value.subbrand,
            'Style No': value.styleno,
            'Style code': [<span className="tooltip1" key={'abcd' + key}>{value.stylecode !== null && value.stylecode ? (value.stylecode.length > 5 ? value.stylecode.substr(value.stylecode.length - 5) : value.stylecode) : value.stylecode}<span className="tooltiptext">{value.stylecode}</span></span>],
            'Price': value.price,
            'Delivery month': value.deliverymonth ? value.deliverymonth : '-',
            'xs': value.xs,
            's': value.s,
            'm': value.m,
            'l': value.l,
            'xl': value.xl,
            'xxl': value.xxl,
            '3xl': value['3xl'],
            '4xl': value['4xl'],
            '5xl': value['5xl'],
            'Grand Total': value.xs + value.s + value.m + value.l + value.xl + value.xxl + value['3xl'] + value['4xl'] + value['5xl'],
            'Remarks': value.remarks
          })
        })
        let data1 = brand.brandorder && brand.brandorder.map((value, key) => {
          return ({
            'id': value.id,
            'subBrand': value.subbrand,
            'styleNo': value.styleno,
            'styleCode': value.stylecode,
            'price': value.price,
            'delivery': value.deliverymonth ? value.deliverymonth : '-',
            'xs': value.xs,
            's': value.s,
            'm': value.m,
            'l': value.l,
            'xl': value.xl,
            'xxl': value.xxl,
            '3xl': value['3xl'],
            '4xl': value['4xl'],
            '5xl': value['5xl'],
            'total': value.xs + value.s + value.m + value.l + value.xl + value.xxl + value['3xl'] + value['4xl'] + value['5xl'],
            'remarks': value.remarks
          })
        })
        this.setState({
          data: {
            ...this.state.data,
            rows: data
          },
          rows: data1,
          brand: brand.brandsId && brand.brandsId.name,
          brandImg: brand.brandsId && brand.brandsId.logo,
          brandOrderId: brand.id,
          name: brand.name,
          total: newProps.brandorder.page,
          loader: false
        })
        if (newProps.brandorder.message) {
          if (toast.isActive(toastId)){
            toast.dismiss(toastId)
            toastId = toast.success(newProps.brandorder.message)
            newProps.initialState()
          }else {
            toastId = toast.success(newProps.brandorder.message);
            newProps.initialState()
          }
        }
      } else {
        if (!toast.isActive(toastId))
          toastId = toast.error('Data not found')
      }
      if (deleteMessage.message === true) {
        if (toast.isActive(toastId)){
          toast.dismiss(toastId)
          toastId = toast.success('Deleted Successfully')
          newProps.initialState()
        }else {
          toastId = toast.success('Deleted Successfully');
          newProps.initialState()
        }
      }
    } else if (newProps.brandorder && newProps.brandorder.error) {
      this.setState({
        loader: false
      })
      if (newProps.brandorder) {
        if (toast.isActive(toastId)){
          toast.dismiss(toastId)
          toastId = toast.error(newProps.brandorder)
          newProps.initialState()
        }else {
          toastId = toast.error(newProps.brandorder);
          newProps.initialState()
        }
      }
    }
  }
  repeatConfirm = (e, id) => {
    this.setState({
      openRepeat: false,
      loader: true
    })
    this.props.repeatOrder(this.props.match.params.id)
  }
  dataClone = () => {
    this.setState({
      openRepeat: true,
    })
  }
  componentWillUnmount() {
    this.props.initialState()
  }
  render() {
    const { open, openRepeat, loader, brandOrderId } = this.state
    return (
      <>
        {!loader ?
          <>
            <Container className="mt-0" fluid>
              <ToastContainer autoClose={10000} />
              <Card className="card_border">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col-md-8 d-flex">
                      <div style={{ paddingBottom: '5px', marginRight: '25px' }}>
                        <h6 className="text-uppercase text-light ls-1 mb-1">
                          Brand
                      </h6>
                        <h3 className="mb-0 profile_sec text-capitalize">
                          {
                            this.state.brandImg != null && this.state.brandImg !== '' ?
                              <img className="mr-3" src={`${imagerUrl}` + this.state.brandImg} width="40" alt="brand"></img>
                              : <img className="mr-3" src={'/logo.png'} width="40" alt="brand"></img>
                          }
                          {this.state.brand}</h3>
                      </div>
                      <div style={{ paddingBottom: '5px' }}>
                        <h6 className="text-uppercase text-light ls-1 mb-1">
                          Order id
                        </h6>
                        <h2 className="mb-0">
                          {this.state.name}</h2>
                      </div>
                    </div>
                    <div className="col-md-4 text-right d-flex justify-contant-flex-end">
                      <Button style={{ marginRight: '20px' }} type="submit" className="btn btn-primary" color="primary" onClick={this.dataClone}>Repeat Order</Button>
                      <Nav className="justify-content-end" pills>
                        <NavItem>
                          <NavLink className="btn btn-primary " to='/oldOrders' tag={Link}  >Back</NavLink>
                        </NavItem>
                      </Nav>


                    </div>
                  </Row>
                </CardHeader>
                <EditTable data={this.state.data} searchChange={this.searchChange} pageNumber={this.state.pageNumber} total={this.state.total} changePage={this.changePage} />
              </Card>
            </Container>
            <ConfirmModal open={open} toggle={this.toggle} confirmation={this.confirmation} id={this.state.modelId} />
            <RepeatConfirmModal open={openRepeat} toggle={this.toggle} confirmation={this.repeatConfirm} id={brandOrderId} />
          </>
          :
          <div className="loader">
            <Spinner variant="light" />
            <p style={{ color: '#fff', paddingTop: '15px' }}>Please wait......</p>
          </div>
        }
      </>
    );
  }
}

const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    getBrandOrder,
    noteinitialState,
    editMainOrder,
    deleteMainOrder,
    repeatOrder,
    initialState
  }, dispatch)
}

const mapGetStateNew = (state) => {
  return {
    brandorder: state.order.brandorder,
    repeatOrderList: state.order.repeatOrderList,
    deleteMessage: state.order
  }
}
export default connect(mapGetStateNew, mapDispatchState)(Mainordersheet);