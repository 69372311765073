import { 
    GET_BRAND,     
    GET_BRAND_ADD, 
    GET_BRAND_DELETE,
    GET_BRAND_VIEW,
    GET_BRAND_LIST_DELETE,
    GET_BRAND_UPDATE,
    GET_REMOVE_IMAGE_BRAND
} from "../../sharedFile/actionType";
export const brandImageRemove = (id)=>({
    type: GET_REMOVE_IMAGE_BRAND,
    id,
})
export const brand = ()=>({
    type:GET_BRAND
})
export const oldBrandListDelete = (id)=>({
    type:GET_BRAND_LIST_DELETE,
    id
})

export const addBrand = (data) => ({
    type:GET_BRAND_ADD,
    data
})

export const deleteBrand = (data) => ({
    type:GET_BRAND_DELETE,
    data
})

export const viewBrand = (data) => ({
    type: GET_BRAND_VIEW,
    data
})

export const updateBrand = (id,data) => ({
    type: GET_BRAND_UPDATE,
    id,
    data
})