import {
    SET_ORDER_FAILED,
    SET_ORDER_SUCCESS,
    ADD_MATERIALORDER_FAILED,
    ADD_MATERIALORDER_SUCCESS,
    DELETE_ORDER_FAILED,
    DELETE_ORDER_SUCCESS,
    VIEW_ORDER_FAILED,
    VIEW_ORDER_SUCCESS,
    UPDATE_ORDER_FAILED,
    UPDATE_ORDER_SUCCESS,
    UPLOAD_ORDER_FAILED,
    UPLOAD_ORDER_SUCCESS,
    VIEW_BRANDORDER_FAILED,
    VIEW_BRANDORDER_SUCCESS,
    VIEW_BRAND_ORDER_List_SUCCESS,
    VIEW_BRAND_ORDER_List_FAILED,
    SET_ORDERGROUP_FAILED,
    SET_ORDERGROUP_SUCCESS,
    SET_MAIN_ZIPPER_SUCCESS,
    SET_MAIN_ZIPPER_FAILED,
    GET_INITIALSTATE,
    ADD_RIBDHAAGAORDER_SUCCESS,
    ADD_RIBDHAAGAORDER_FAILED,
    GET_RIBDHAAGAORDER_SUCCESS,
    GET_RIBDHAAGAORDER_FAILED,
    SET_POST_MAIN_ZIPPER_FAILED,
    ADD_POLYFILLORDER_SUCCESS,
    ADD_POLYFILLORDER_FAILED,
    GET_POLYFILLORDER_SUCCESS,
    GET_POLYFILLORDER_FAILED,
    ADD_LININGORDER_SUCCESS,
    ADD_LININGORDER_FAILED,
    GET_LININGORDER_SUCCESS,
    GET_LININGORDER_FAILED,
    ADD_MAINQUILTORDER_SUCCESS,
    ADD_MAINQUILTORDER_FAILED,
    GET_MAINQUILTORDER_SUCCESS,
    GET_MAINQUILTORDER_FAILED,
    ADD_LININGQUILTORDER_SUCCESS,
    ADD_LININGQUILTORDER_FAILED,
    GET_LININGQUILTORDER_SUCCESS,
    GET_LININGQUILTORDER_FAILED,
    TOOLTIP_TOGGLE,
    UPLOAD_INHOUSE_FAILED,
    UPLOAD_INHOUSE_SUCCESS,
    STOCK_INHOUSE_SUCCESS,
    STOCK_INHOUSE_FAILED,
    STOCK_RATIOINHOUSE_SUCCESS,
    STOCK_RATIOINHOUSE_FAILED,
    SET_OLD_ORDERS_SUCCESS,
    SET_OLD_ORDERS_FAILED,
    SET_DELETE_MAIN_ORDERS_SUCCESS,
    SET_REPEAT_ORDERS_SUCCESS,
    SET_NOTE_SUCCESS,
    SET_NOTE_FAILED,
    SET_ADD_COLOR_DHAAGA_SUCCESS,
    SET_ADD_COLOR_DHAAGA_FAILED,
    SET_COLOR_DHAAGA_SUCCESS,
    SET_COLOR_DHAAGA_FAILED,
    GET_INITIALSTATE_NOTE,
    SET_BRAND_LIST_DELETE_SUCCESS,
    SET_BRAND_LIST_DELETE_FAILED,
    GET_SUPPLIER_INITIAL
} from "../../sharedFile/actionType";

const initialState = {
    order: [],
    orderZipper: [],
    brandOrderList: [],
    inhousestock: {},
    ratioinhousestock: [],
    colorDhaaga: [],
    mainquiltorder: [],
    error: false,
    orderOld: [],
    orderFailed: '',
    message:false,
    tooltip: false
}
const order = (state = initialState, action) => {
    switch (action.type) {
        case SET_NOTE_SUCCESS:
            return {
                ...state,
                note: action.data
            }
        case SET_NOTE_FAILED:
            return {
                ...state,
                note: action.error
            }
        case GET_INITIALSTATE_NOTE:
            return {
                note: [],
                message:false,
            }
        case SET_COLOR_DHAAGA_SUCCESS:
            return {
                ...state,
                colorDhaaga: action.data
            }
        case SET_COLOR_DHAAGA_FAILED:
            return {
                ...state,
                colorDhaaga: action.data
            }
        case SET_ADD_COLOR_DHAAGA_FAILED:
            return {
                ...state,
                colorDhaagaAdd: action.error
            }
        case TOOLTIP_TOGGLE:
            return {
                ...state,
                tooltip: action.data,
                tooltipId: action.id
            }
        case SET_REPEAT_ORDERS_SUCCESS:
            return {
                ...state,
                repeatOrderList: action.data
            }
        case SET_DELETE_MAIN_ORDERS_SUCCESS:
            return {
                ...state,
                brandorder: { order: { brandorder: state.brandorder.order.brandorder.filter(item => item.id !== action.data.brandorder.id) }, success: true, page: state.brandorder.page },
                message:action.message
            }
        case SET_OLD_ORDERS_SUCCESS:
            return {
                ...state,
                orderOld: action.data
            }
        case SET_OLD_ORDERS_FAILED:
            return {
                ...state,
                error: true,
                orderOld: action.error
            }
        case SET_BRAND_LIST_DELETE_SUCCESS:
            return {
                ...state,
                orderOld: { order: state.orderOld.order.filter(item => item.id !== action.data.brandorder.id), success: true },
                deleteOldOrder:action.data
            }
        case SET_BRAND_LIST_DELETE_FAILED:
            return {
                ...state,
                deleteOldOrder:action.error
            }
        case GET_SUPPLIER_INITIAL:
            return{
                ...state,
                deleteOldOrder:[]
            }
        case SET_MAIN_ZIPPER_SUCCESS:
            return {
                ...state,
                orderZipper: action.data
            }
        case SET_MAIN_ZIPPER_FAILED:
            return {
                ...state,
                error: true,
                orderZipper: action.error
            }
        case SET_POST_MAIN_ZIPPER_FAILED:
            return {
                ...state,
                error: true,
                orderZipperPost: action.error
            }
        case VIEW_BRAND_ORDER_List_SUCCESS:
            return {
                ...state,
                brandOrderList: action.data
            }
        case VIEW_BRAND_ORDER_List_FAILED:
            return {
                ...state,
                error: true,
                brandOrderList: action.error
            }
        case SET_ORDER_SUCCESS:
            return {
                ...state,
                order: action.data
            }
        case SET_ORDER_FAILED:
            return {
                ...state,
                error: true,
                order: action.error
            }
        case ADD_MATERIALORDER_SUCCESS:
            return {
                ...state,
                order: action.data
            }
        case ADD_MATERIALORDER_FAILED:
            return {
                ...state,
                error: true,
                orderFailed: action.error
            }
        case DELETE_ORDER_SUCCESS:
            return {
                ...state,
                order: { orders: state.order.orders.filter(item => item.id !== action.data.orders.id), success: true }
            }
        case DELETE_ORDER_FAILED:
            return {
                ...state,
                error: true
            }
        case VIEW_ORDER_SUCCESS:
            return {
                ...state,
                detailorder: action.data
            }
        case VIEW_ORDER_FAILED:
            return {
                ...state,
                error: true,
                detailorder: action.error
            }
        case UPDATE_ORDER_SUCCESS:
            return {
                ...state,
                detailorder: action.data
            }
        case UPDATE_ORDER_FAILED:
            return {
                ...state,
                error: true,
                detailorder: action.error
            }
        case UPLOAD_ORDER_SUCCESS:
            return {
                ...state,
                uploadorder: action.data
            }
        case UPLOAD_ORDER_FAILED:
            return {
                ...state,
                error: true,
                uploadorder: action.error
            }
        case VIEW_BRANDORDER_SUCCESS:
            return {
                ...state,
                brandorder: action.data
            }
        case VIEW_BRANDORDER_FAILED:
            return {
                ...state,
                error: true,
                brandorder: action.error
            }
        case SET_ORDERGROUP_SUCCESS:
            return {
                ...state,
                order: action.data
            }
        case SET_ORDERGROUP_FAILED:
            return {
                ...state,
                error: true,
                order: action.error
            }
        case GET_INITIALSTATE:
            return {
                ...state,
                brandorder: [],
                order: [],
                brandOrderList: [],
                orderZipper: [],
                inhousestock: {},
                ratioinhousestock: [],
                uploadorder: [],
                mainquiltorder: [],
                riborder: [],
                colorDhaaga: [],
                orderOld: [],
                repeatOrderList: [],
                message:false,
                repeatOrderList:[]
            }
        case ADD_RIBDHAAGAORDER_SUCCESS:
            return {
                ...state,
                riborder: action.data
            }
        case ADD_RIBDHAAGAORDER_FAILED:
            return {
                ...state,
                error: true,
                riborder: action.error
            }
        case GET_RIBDHAAGAORDER_SUCCESS:
            return {
                ...state,
                riborder: action.data
            }
        case GET_RIBDHAAGAORDER_FAILED:
            return {
                ...state,
                error: true,
                riborder: action.error
            }
        case ADD_POLYFILLORDER_SUCCESS:
            return {
                ...state,
                addpolyorder: action.data
            }
        case ADD_POLYFILLORDER_FAILED:
            return {
                ...state,
                error: true,
                addpolyorder: action.error
            }
        case GET_POLYFILLORDER_SUCCESS:
            return {
                ...state,
                polyorder: action.data
            }
        case GET_POLYFILLORDER_FAILED:
            return {
                ...state,
                error: true,
                polyorder: action.error
            }
        case ADD_LININGORDER_SUCCESS:
            return {
                ...state,
                addliningorder: action.data
            }
        case ADD_LININGORDER_FAILED:
            return {
                ...state,
                error: true,
                addliningorder: action.error
            }
        case GET_LININGORDER_SUCCESS:
            return {
                ...state,
                liningorder: action.data
            }
        case GET_LININGORDER_FAILED:
            return {
                ...state,
                error: true,
                liningorder: action.error
            }
        case ADD_MAINQUILTORDER_SUCCESS:
            return {
                ...state,
                addmainquiltorder: action.data
            }
        case ADD_MAINQUILTORDER_FAILED:
            return {
                ...state,
                error: true,
                addmainquiltorder: action.error
            }
        case GET_MAINQUILTORDER_SUCCESS:
            return {
                ...state,
                mainquiltorder: action.data
            }
        case GET_MAINQUILTORDER_FAILED:
            return {
                ...state,
                error: true,
                mainquiltorder: action.error
            }
        case ADD_LININGQUILTORDER_SUCCESS:
            return {
                ...state,
                addliningquiltorder: action.data
            }
        case ADD_LININGQUILTORDER_FAILED:
            return {
                ...state,
                error: true,
                addliningquiltorder: action.error
            }
        case GET_LININGQUILTORDER_SUCCESS:
            return {
                ...state,
                liningquiltorder: action.data
            }
        case GET_LININGQUILTORDER_FAILED:
            return {
                ...state,
                error: true,
                liningquiltorder: action.error
            }
        case UPLOAD_INHOUSE_SUCCESS:
            return {
                ...state,
                uploadINHOUSE: action.data
            }
        case UPLOAD_INHOUSE_FAILED:
            return {
                ...state,
                error: true,
                uploadINHOUSE: action.error
            }
        case STOCK_INHOUSE_SUCCESS:
            return {
                ...state,
                inhousestock: action.data
            }
        case STOCK_INHOUSE_FAILED:
            return {
                ...state,
                error: true,
                inhousestock: { message: action.error, key: action.key, quantity:action.quantity,success:action.success }
            }
        case STOCK_RATIOINHOUSE_SUCCESS:
            return {
                ...state,
                ratioinhousestock: action.data
            }
        case STOCK_RATIOINHOUSE_FAILED:
            return {
                ...state,
                error: true,
                ratioinhousestock: { message: action.error, key: action.key, quantity:action.quantity,success:action.success }
            }
        default:
            return state
    }

}
export default order;