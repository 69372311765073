import { 
    GET_INITIALSTATE,
    GET_INITIALSTATE_NOTE,
    GET_INITIALSTATE_SUB
} from "../../sharedFile/actionType";
export const initialStateSub =()=>({
    type:GET_INITIALSTATE_SUB
})
export const noteinitialState = (data) => ({
    type: GET_INITIALSTATE_NOTE,
    data,
})
export const initialState =()=>({
    type:GET_INITIALSTATE
})