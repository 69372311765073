import { call, put, takeLatest, all } from "redux-saga/effects";
import {
  SET_ORDER_SUCCESS,
  SET_ORDER_FAILED,
  GET_ORDER,
  GET_ORDER_DELETE,
  ADD_MATERIALORDER_FAILED,
  ADD_MATERIALORDER_SUCCESS,
  GET_MATERIALORDER_ADD,
  VIEW_BRAND_ORDER_List_SUCCESS,
  VIEW_BRAND_ORDER_List_FAILED,
  DELETE_ORDER_FAILED,
  DELETE_ORDER_SUCCESS,
  VIEW_ORDER_FAILED,
  GET_ORDER_VIEW,
  VIEW_ORDER_SUCCESS,
  GET_ORDER_UPDATE,
  UPDATE_ORDER_FAILED,
  UPDATE_ORDER_SUCCESS,
  GET_ORDER_UPLOAD,
  UPLOAD_ORDER_FAILED,
  UPLOAD_ORDER_SUCCESS,
  GET_BRANDORDER_VIEW,
  VIEW_BRANDORDER_SUCCESS,
  VIEW_BRANDORDER_FAILED,
  GET_BRAND_ORDER_List_VIEW,
  GET_MAIN_GROUP,
  SET_ORDERGROUP_SUCCESS,
  SET_ORDERGROUP_FAILED,
  GET_MAIN_ZIPPER,
  SET_MAIN_ZIPPER_SUCCESS,
  SET_MAIN_ZIPPER_FAILED,
  GET_POST_MAIN_ZIPPER,
  GET_RIB_DHAAGA,
  ADD_RIBDHAAGAORDER_SUCCESS,
  ADD_RIBDHAAGAORDER_FAILED,
  GET_RIB_DHAAGA_DETAIL,
  GET_RIBDHAAGAORDER_SUCCESS,
  GET_RIBDHAAGAORDER_FAILED,
  SET_POST_MAIN_ZIPPER_FAILED,
  GET_POLYFILL,
  ADD_POLYFILLORDER_SUCCESS,
  ADD_POLYFILLORDER_FAILED,
  GET_POLYFILL_DETAIL,
  GET_POLYFILLORDER_SUCCESS,
  GET_POLYFILLORDER_FAILED,
  GET_LINING,
  ADD_LININGORDER_SUCCESS,
  ADD_LININGORDER_FAILED,
  GET_LINING_DETAIL,
  GET_LININGORDER_SUCCESS,
  GET_LININGORDER_FAILED,
  GET_MAINQUILT,
  ADD_MAINQUILTORDER_SUCCESS,
  ADD_MAINQUILTORDER_FAILED,
  GET_MAINQUILT_DETAIL,
  GET_MAINQUILTORDER_SUCCESS,
  GET_MAINQUILTORDER_FAILED,
  GET_LININGQUILT,
  ADD_LININGQUILTORDER_SUCCESS,
  ADD_LININGQUILTORDER_FAILED,
  GET_LININGQUILT_DETAIL,
  GET_LININGQUILTORDER_SUCCESS,
  GET_LININGQUILTORDER_FAILED,
  GET_INHOUSE_UPLOAD,
  UPLOAD_INHOUSE_FAILED,
  UPLOAD_INHOUSE_SUCCESS,
  VIEW_INHOUSE_SUCCESS,
  VIEW_INHOUSE_FAILED,
  GET_INHOUSE_VIEW,
  GET_INHOUSE_STOCK,
  STOCK_INHOUSE_SUCCESS,
  STOCK_INHOUSE_FAILED,
  GET_RATIOINHOUSE_STOCK,
  STOCK_RATIOINHOUSE_SUCCESS,
  STOCK_RATIOINHOUSE_FAILED,
  GET_OLD_ORDERS,
  SET_OLD_ORDERS_SUCCESS,
  SET_OLD_ORDERS_FAILED,
  GET_EDIT_MAIN_ORDERS,
  SET_EDIT_MAIN_ORDERS_SUCCESS,
  SET_EDIT_MAIN_ORDERS_FAILED,
  GET_DELETE_MAIN_ORDERS,
  SET_DELETE_MAIN_ORDERS_SUCCESS,
  SET_DELETE_MAIN_ORDERS_FAILED,
  GET_REPEAT_ORDERS,
  SET_REPEAT_ORDERS_SUCCESS,
  SET_REPEAT_ORDERS_FAILED,
  GET_NOTE,
  SET_NOTE_SUCCESS,
  SET_NOTE_FAILED,
  GET_ADD_COLOR_DHAAGA,
  SET_ADD_COLOR_DHAAGA_SUCCESS,
  SET_ADD_COLOR_DHAAGA_FAILED,
  GET_COLOR_DHAAGA,
  SET_COLOR_DHAAGA_SUCCESS,
  SET_COLOR_DHAAGA_FAILED,
  GET_ZIPPER_ADD_ROW,
  SET_ZIPPER_ADD_ROW_FAILED,
  GET_ZIPPER_ADD_REPEAT,
  SET_ZIPPER_ADD_REPEAT_FAILED
} from "../../sharedFile/actionType";
import Api from "../../lib/api";

function getOrderListApi(action) {
  return Api.post(
    `order/fabric/list`,
    action.data
  );
}
function* getOrderList(action) {
  try {
    const resp = yield call(getOrderListApi, action);
    if (resp.success === true) {
      yield put({ type: SET_ORDER_SUCCESS, data: resp })
    } else {
      yield put({ type: SET_ORDER_FAILED, error: resp })
    }
  } catch (resp) {
    yield put({ type: SET_ORDER_FAILED, error: resp })
  }
}


function deleteOrderApi(action) {
  const { data } = action
  return Api.delete(
    `order/` + data
  );
}
function* deleteOrder(action) {
  try {
    const resp = yield call(deleteOrderApi, action);
    if (resp.success === true) {
      yield put({ type: DELETE_ORDER_SUCCESS, data: resp })
    } else {
      yield put({ type: DELETE_ORDER_FAILED, error: resp.message })
    }
  } catch (resp) {
    yield put({ type: DELETE_ORDER_FAILED, error: 'Something Went Wrong' })
  }
}


function addMaterialOrderApi(action) {
  return Api.post(
    `order/fabric`,
    action.data
  );
}
function* addMaterialOrder(action) {
  try {
    const resp = yield call(addMaterialOrderApi, action);
    if (resp.success === true) {
      yield put({ type: SET_ORDER_SUCCESS, data: resp })
    } else {
      yield put({ type: ADD_MATERIALORDER_FAILED, error: resp })
    }
  } catch (resp) {
    yield put({ type: ADD_MATERIALORDER_FAILED, error: resp })
  }
}

function viewOrderApi(action) {
  const { data } = action
  return Api.get(
    `order/` + data
  );
}
function* viewOrder(action) {
  try {
    const resp = yield call(viewOrderApi, action);
    if (resp.success === true) {
      yield put({ type: VIEW_ORDER_SUCCESS, data: resp })
    } else {
      yield put({ type: VIEW_ORDER_FAILED, error: resp.message })
    }
  } catch (resp) {
    yield put({ type: VIEW_ORDER_FAILED, error: 'Something Went Wrong' })
  }
}


function updateOrderApi(action) {
  return Api.put(
    `order/` + action.id,
    {
      picture: action.data.picture,
      name: action.data.name,
      description: action.data.description
    }
  );
}
function* updateOrder(action) {
  try {
    const resp = yield call(updateOrderApi, action);
    if (resp.success === true) {
      yield put({ type: UPDATE_ORDER_SUCCESS, data: resp })
    } else {
      yield put({ type: UPDATE_ORDER_FAILED, error: resp.message })
    }
  } catch (resp) {
    yield put({ type: UPDATE_ORDER_FAILED, error: 'Something Went Wrong' })
  }
}



function uploadOrderApi(action) {
  return Api.post(
    `order/brandOrder`,
    action.data
  );
}
function* uploadOrder(action) {
  try {
    const resp = yield call(uploadOrderApi, action);
    if (resp.success === true) {
      yield put({ type: UPLOAD_ORDER_SUCCESS, data: resp })
    } else {
      yield put({ type: UPLOAD_ORDER_FAILED, error: resp })
    }
  } catch (resp) {
    yield put({ type: UPLOAD_ORDER_FAILED, error: resp })
  }
}

function viewBrandOrderApi(action) {
  const { data } = action
  return Api.post(
    `order/getbrandOrder/`,
    data
  );
}
function* viewBrandOrder(action) {
  try {
    const resp = yield call(viewBrandOrderApi, action);
    if (resp.success === true) {
      yield put({ type: VIEW_BRANDORDER_SUCCESS, data: resp })
    } else {
      yield put({ type: VIEW_BRANDORDER_FAILED, error: resp.message })
    }
  } catch (resp) {
    yield put({ type: VIEW_BRANDORDER_FAILED, error: 'Something Went Wrong' })
  }
}
function brandOrderListApi(action) {
  const { data } = action
  return Api.get(
    `order/brandOrderList/` + data
  );
}
function* brandOrderList(action) {
  try {
    const resp = yield call(brandOrderListApi, action);
    if (resp.success === true) {
      yield put({ type: VIEW_BRAND_ORDER_List_SUCCESS, data: resp })
    } else {
      yield put({ type: VIEW_BRAND_ORDER_List_FAILED, error: resp.message })
    }
  } catch (resp) {
    yield put({ type: VIEW_BRAND_ORDER_List_FAILED, error: 'Something Went Wrong' })
  }
}

function getOrderGROUPListApi(action) {
  return Api.post(
    `order/fabric/group/`, action.data,
  );
}
function* getOrderGROUPList(action) {
  try {
    const resp = yield call(getOrderGROUPListApi, action);
    if (resp.success === true) {
      yield put({ type: SET_ORDERGROUP_SUCCESS, data: resp })
    } else {
      yield put({ type: SET_ORDERGROUP_FAILED, error: 'Something Went Wrong' })
    }
  } catch (resp) {
    yield put({ type: SET_ORDERGROUP_FAILED, error: 'Something Went Wrong' })
  }
}

function getMainZipperListApi(action) {
  return Api.post(
    `order/zipper/list`, action.data,
  );
}
function* getMainZipperList(action) {
  try {
    const resp = yield call(getMainZipperListApi, action);
    if (resp.success === true) {
      yield put({ type: SET_MAIN_ZIPPER_SUCCESS, data: resp })
    } else {
      yield put({ type: SET_MAIN_ZIPPER_FAILED, error: resp })
    }
  } catch (resp) {
    yield put({ type: SET_MAIN_ZIPPER_FAILED, error: resp })
  }
}

function mainZipperAddApi(action) {
  return Api.post(
    `order/zipper/`,
    action.data
  );
}
function* mainZipperAdd(action) {
  try {
    const resp = yield call(mainZipperAddApi, action);
    if (resp.success === true) {
      yield put({ type: SET_MAIN_ZIPPER_SUCCESS, data: resp })
    } else {
      yield put({ type: SET_MAIN_ZIPPER_FAILED, error: resp })
    }
  } catch (resp) {
    yield put({ type: SET_MAIN_ZIPPER_FAILED, error: resp })
  }
}


function addRibDhaagaOrderApi(action) {
  return Api.post(
    `order/ribDhaagaWorking`,
    action.data
  );
}
function* addRibDhaagaOrder(action) {
  try {
    const resp = yield call(addRibDhaagaOrderApi, action);
    if (resp.success === true) {
      yield put({ type: ADD_RIBDHAAGAORDER_SUCCESS, data: resp })
    } else {
      yield put({ type: ADD_RIBDHAAGAORDER_FAILED, error: resp })
    }
  } catch (resp) {
    yield put({ type: ADD_RIBDHAAGAORDER_FAILED, error: resp })
  }
}


function getRibDhaagaOrderApi(action) {
  return Api.post(
    `order/ribDhaagaWorking/list`, action.data,
  );
}
function* getRibDhaagaOrder(action) {
  try {
    const resp = yield call(getRibDhaagaOrderApi, action);
    if (resp.success === true) {
      yield put({ type: GET_RIBDHAAGAORDER_SUCCESS, data: resp })
    } else {
      yield put({ type: GET_RIBDHAAGAORDER_FAILED, error: resp.message })
    }
  } catch (resp) {
    yield put({ type: GET_RIBDHAAGAORDER_FAILED, error: resp.message })
  }
}

function addPolyfillOrderApi(action) {
  return Api.post(
    `order/polyfill`,
    action.data
  );
}
function* addPolyfillOrder(action) {
  try {
    const resp = yield call(addPolyfillOrderApi, action);
    if (resp.success === true) {
      yield put({ type: ADD_POLYFILLORDER_SUCCESS, data: resp })
    } else {
      yield put({ type: ADD_POLYFILLORDER_FAILED, error: resp.message })
    }
  } catch (resp) {
    yield put({ type: ADD_POLYFILLORDER_FAILED, error: resp.message })
  }
}


function getPolyfillOrderApi(action) {
  return Api.get(
    `order/polyfill/` + action.data,
  );
}
function* getPolyfillOrder(action) {
  try {
    const resp = yield call(getPolyfillOrderApi, action);
    if (resp.success === true) {
      yield put({ type: GET_POLYFILLORDER_SUCCESS, data: resp })
    } else {
      yield put({ type: GET_POLYFILLORDER_FAILED, error: resp.message })
    }
  } catch (resp) {
    yield put({ type: GET_POLYFILLORDER_FAILED, error: resp.message })
  }
}

function addLiningOrderApi(action) {
  return Api.post(
    `order/lining`,
    action.data
  );
}
function* addLiningOrder(action) {
  try {
    const resp = yield call(addLiningOrderApi, action);
    if (resp.success === true) {
      yield put({ type: ADD_LININGORDER_SUCCESS, data: resp })
    } else {
      yield put({ type: ADD_LININGORDER_FAILED, error: resp.message })
    }
  } catch (resp) {
    yield put({ type: ADD_LININGORDER_FAILED, error: resp.message })
  }
}
function getLiningOrderApi(action) {
  return Api.get(
    `order/lining/` + action.data,
  );
}
function* getLiningOrder(action) {
  try {
    const resp = yield call(getLiningOrderApi, action);
    if (resp.success === true) {
      yield put({ type: GET_LININGORDER_SUCCESS, data: resp })
    } else {
      yield put({ type: GET_LININGORDER_FAILED, error: resp.message })
    }
  } catch (resp) {
    yield put({ type: GET_LININGORDER_FAILED, error: resp.message })
  }
}



function addMainQuiltOrderApi(action) {
  return Api.post(
    `order/quilting`,
    action.data
  );
}
function* addMainQuiltOrder(action) {
  try {
    const resp = yield call(addMainQuiltOrderApi, action);
    if (resp.success === true) {
      yield put({ type: GET_MAINQUILTORDER_SUCCESS, data: resp })
    } else {
      yield put({ type: ADD_MAINQUILTORDER_FAILED, error: resp.message })
    }
  } catch (resp) {
    yield put({ type: ADD_MAINQUILTORDER_FAILED, error: resp.message })
  }
}
function getMainQuiltOrderApi(action) {
  return Api.post(
    `order/quilting/list`, action.data,
  );
}
function* getMainQuiltOrder(action) {
  try {
    const resp = yield call(getMainQuiltOrderApi, action);
    if (resp.success === true) {
      yield put({ type: GET_MAINQUILTORDER_SUCCESS, data: resp })
    } else {
      yield put({ type: GET_MAINQUILTORDER_FAILED, error: resp })
    }
  } catch (resp) {
    yield put({ type: GET_MAINQUILTORDER_FAILED, error: resp })
  }
}


function addLiningQuiltOrderApi(action) {
  return Api.post(
    `order/liningquilting`,
    action.data
  );
}
function* addLiningQuiltOrder(action) {
  try {
    const resp = yield call(addLiningQuiltOrderApi, action);
    if (resp.success === true) {
      yield put({ type: ADD_LININGQUILTORDER_SUCCESS, data: resp })
    } else {
      yield put({ type: ADD_LININGQUILTORDER_FAILED, error: resp.message })
    }
  } catch (resp) {
    yield put({ type: ADD_LININGQUILTORDER_FAILED, error: resp.message })
  }
}
function getLiningQuiltOrderApi(action) {
  return Api.get(
    `order/liningquilting/` + action.data,
  );
}
function* getLiningQuiltOrder(action) {
  try {
    const resp = yield call(getLiningQuiltOrderApi, action);
    if (resp.success === true) {
      yield put({ type: GET_LININGQUILTORDER_SUCCESS, data: resp })
    } else {
      yield put({ type: GET_LININGQUILTORDER_FAILED, error: resp.message })
    }
  } catch (resp) {
    yield put({ type: GET_LININGQUILTORDER_FAILED, error: resp.message })
  }
}



function uploadInhouseApi(action) {
  return Api.post(
    `material/inhouse/material`,
    action.data
  );
}
function* uploadInhouse(action) {
  try {
    const resp = yield call(uploadInhouseApi, action);
    if (resp.success === true) {
      yield put({ type: UPLOAD_INHOUSE_SUCCESS, data: resp })
    } else {
      yield put({ type: UPLOAD_INHOUSE_FAILED, error: resp.message })
    }
  } catch (resp) {
    yield put({ type: UPLOAD_INHOUSE_FAILED, error: resp.message })
  }
}

function viewInhouseApi(action) {
  return Api.get(
    `material/inhouse/material`
  );
}
function* viewInhouse(action) {
  try {
    const resp = yield call(viewInhouseApi, action);
    if (resp.success === true) {
      yield put({ type: VIEW_INHOUSE_SUCCESS, data: resp })
    } else {
      yield put({ type: VIEW_INHOUSE_FAILED, error: resp.message })
    }
  } catch (resp) {
    yield put({ type: VIEW_INHOUSE_FAILED, error: 'Something Went Wrong' })
  }
}

function stockInhouseApi(action) {
  return Api.post(
    `material/inhouse/match`,
    action.data
  );
}
function* stockInhouse(action) {
  try {
    const resp = yield call(stockInhouseApi, action);
    if (resp.success === true) {
      yield put({ type: STOCK_INHOUSE_SUCCESS, data: resp })
    } else {
      yield put({ type: STOCK_INHOUSE_FAILED, error: resp.message, key: resp.key, quantity: resp.quantity })
    }
  } catch (resp) {
    yield put({ type: STOCK_INHOUSE_FAILED, error: resp.message, key: resp.key, quantity: resp.quantity, success: resp.success })
  }
}


function ratiostockInhouseApi(action) {
  return Api.post(
    `material/inhouseRatio/match`,
    action.data
  );
}
function* ratiostockInhouse(action) {
  try {
    const resp = yield call(ratiostockInhouseApi, action);
    if (resp.success === true) {
      yield put({ type: STOCK_RATIOINHOUSE_SUCCESS, data: resp })
    } else {
      yield put({ type: STOCK_RATIOINHOUSE_FAILED, error: resp.message, key: resp.key })
    }
  } catch (resp) {
    yield put({ type: STOCK_RATIOINHOUSE_FAILED, error: resp.message, key: resp.key, quantity: resp.quantity, success: resp.success })
  }
}
// old orders

function oldOrdersApi(action) {
  const {brandId,dateFrom,dateTo} = action.data
  return Api.post(
    `/order/brandOrderList`,
    {brandId,dateFrom:dateFrom+'T00:00:00Z',dateTo:dateTo+'T11:59:00Z'}
  );
}
function* oldOrders(action) {
  try {
    const resp = yield call(oldOrdersApi, action);
    if (resp.success === true) {
      yield put({ type: SET_OLD_ORDERS_SUCCESS, data: resp })
    } else {
      yield put({ type: SET_OLD_ORDERS_FAILED, error: resp.message, key: resp.key })
    }
  } catch (resp) {
    yield put({ type: SET_OLD_ORDERS_FAILED, error: 'Something Went Wrong', key: resp.key })
  }
}

// edit main orders

function editMainOrderApi(action) {
  const { data } = action
  return Api.put(
    `order/brandOrder/` + data.id, data
  );
}
function* editMainOrder(action) {
  try {
    const resp = yield call(editMainOrderApi, action);
    if (resp.success === true) {
      yield put({ type: SET_EDIT_MAIN_ORDERS_SUCCESS, data: resp })
    } else {
      yield put({ type: SET_EDIT_MAIN_ORDERS_FAILED, error: resp.message, key: resp.key })
    }
  } catch (resp) {
    yield put({ type: SET_EDIT_MAIN_ORDERS_FAILED, error: 'Something Went Wrong', key: resp.key })
  }
}

// delete main orders

function deleteMainOrderApi(action) {
  return Api.delete(
    `order/brandOrder/` + action.data);
}
function* deleteMainOrder(action) {
  try {
    const resp = yield call(deleteMainOrderApi, action);
    if (resp.success === true) {
      let data = {
        brandOrderId: action.id,
        pageNumber: action.pageNumber
      }
      yield put({ type: SET_DELETE_MAIN_ORDERS_SUCCESS, data: resp, message: true })
      yield put({ type: GET_BRANDORDER_VIEW, data: data })
    } else {
      yield put({ type: SET_DELETE_MAIN_ORDERS_FAILED, error: resp })
    }
  } catch (resp) {
    yield put({ type: SET_DELETE_MAIN_ORDERS_FAILED, error: resp })
  }
}
// repeat main orders

function repeatOrderApi(action) {
  return Api.get(
    `/order/repeatBrandOrder/` + action.data);
}
function* repeatOrder(action) {
  try {
    const resp = yield call(repeatOrderApi, action);
    if (resp.success === true) {
      yield put({ type: SET_REPEAT_ORDERS_SUCCESS, data: resp })
    } else {
      yield put({ type: SET_REPEAT_ORDERS_FAILED, error: resp.message, key: resp.key })
    }
  } catch (resp) {
    yield put({ type: SET_REPEAT_ORDERS_FAILED, error: 'Something Went Wrong', key: resp.key })
  }
}

// po note

function noteApi(action) {
  return Api.post(
    `order/fabric/notes`, action.data);
}
function* note(action) {
  try {
    const resp = yield call(noteApi, action);
    if (resp.success === true) {
      yield put({ type: SET_NOTE_SUCCESS, data: resp })
    } else {
      yield put({ type: SET_NOTE_FAILED, error: resp })
    }
  } catch (resp) {
    yield put({ type: SET_NOTE_FAILED, error: resp })
  }
}

// color dhaaga api's

function colorDhaagaAddApi(action) {
  return Api.post(
    `/order/colorDhaaga`,
    action.data
  );
}
function* colorDhaagaAdd(action) {
  try {
    const resp = yield call(colorDhaagaAddApi, action);
    if (resp.success === true) {
      yield put({ type: SET_COLOR_DHAAGA_SUCCESS, data: resp })
    } else {
      yield put({ type: SET_ADD_COLOR_DHAAGA_FAILED, error: resp })
    }
  } catch (resp) {
    yield put({ type: SET_ADD_COLOR_DHAAGA_FAILED, error: resp })
  }
}
function colorDhaagaApi(action) {
  return Api.post(
    `/order/colorDhaaga/list`, action.data
  );
}
function* colorDhaaga(action) {
  try {
    const resp = yield call(colorDhaagaApi, action);
    if (resp.success === true) {
      yield put({ type: SET_COLOR_DHAAGA_SUCCESS, data: resp })
    } else {
      yield put({ type: SET_COLOR_DHAAGA_FAILED, error: resp.message })
    }
  } catch (resp) {
    yield put({ type: SET_COLOR_DHAAGA_FAILED, error: resp.message })
  }
}
function mainAddRowZipperApi(action) {
  return Api.post(
    `order/zipper/addArea`, action.data
  );
}
function* mainAddRowZipper(action) {
  try {
    const resp = yield call(mainAddRowZipperApi, action);
    if (resp.success === true) {
      yield put({ type: SET_MAIN_ZIPPER_SUCCESS, data: resp })
    } else {
      yield put({ type: SET_ZIPPER_ADD_ROW_FAILED, error: resp.message })
    }
  } catch (resp) {
    yield put({ type: SET_ZIPPER_ADD_ROW_FAILED, error: resp.message })
  }
}
function mainAddRepeatZipperApi(action) {
  return Api.post(
    `order/zipper/repeatArea`, action.data
  );
}
function* mainAddRepeatZipper(action) {
  try {
    const resp = yield call(mainAddRepeatZipperApi, action);
    if (resp.success === true) {
      yield put({ type: SET_MAIN_ZIPPER_SUCCESS, data: resp })
    } else {
      yield put({ type: SET_ZIPPER_ADD_REPEAT_FAILED, error: resp.message })
    }
  } catch (resp) {
    yield put({ type: SET_ZIPPER_ADD_REPEAT_FAILED, error: resp.message })
  }
}
function* order() {
  yield all([
    takeLatest(GET_ORDER, getOrderList),
    takeLatest(GET_ORDER_DELETE, deleteOrder),
    takeLatest(GET_MATERIALORDER_ADD, addMaterialOrder),
    takeLatest(GET_ORDER_VIEW, viewOrder),
    takeLatest(GET_ORDER_UPDATE, updateOrder),
    takeLatest(GET_ORDER_UPLOAD, uploadOrder),
    takeLatest(GET_BRANDORDER_VIEW, viewBrandOrder),
    takeLatest(GET_BRAND_ORDER_List_VIEW, brandOrderList),
    takeLatest(GET_MAIN_GROUP, getOrderGROUPList),
    takeLatest(GET_RIB_DHAAGA, addRibDhaagaOrder),
    takeLatest(GET_RIB_DHAAGA_DETAIL, getRibDhaagaOrder),
    takeLatest(GET_MAIN_ZIPPER, getMainZipperList),
    takeLatest(GET_POST_MAIN_ZIPPER, mainZipperAdd),
    takeLatest(GET_POLYFILL, addPolyfillOrder),
    takeLatest(GET_POLYFILL_DETAIL, getPolyfillOrder),
    takeLatest(GET_LINING, addLiningOrder),
    takeLatest(GET_LINING_DETAIL, getLiningOrder),
    takeLatest(GET_MAINQUILT, addMainQuiltOrder),
    takeLatest(GET_MAINQUILT_DETAIL, getMainQuiltOrder),
    takeLatest(GET_LININGQUILT, addLiningQuiltOrder),
    takeLatest(GET_LININGQUILT_DETAIL, getLiningQuiltOrder),
    takeLatest(GET_INHOUSE_UPLOAD, uploadInhouse),
    takeLatest(GET_INHOUSE_VIEW, viewInhouse),
    takeLatest(GET_INHOUSE_STOCK, stockInhouse),
    takeLatest(GET_RATIOINHOUSE_STOCK, ratiostockInhouse),
    takeLatest(GET_OLD_ORDERS, oldOrders),
    takeLatest(GET_EDIT_MAIN_ORDERS, editMainOrder),
    takeLatest(GET_DELETE_MAIN_ORDERS, deleteMainOrder),
    takeLatest(GET_REPEAT_ORDERS, repeatOrder),
    takeLatest(GET_NOTE, note),
    takeLatest(GET_COLOR_DHAAGA, colorDhaaga),
    takeLatest(GET_ADD_COLOR_DHAAGA, colorDhaagaAdd),
    takeLatest(GET_ZIPPER_ADD_ROW, mainAddRowZipper),
    takeLatest(GET_ZIPPER_ADD_REPEAT, mainAddRepeatZipper),
  ])
}
export default order;