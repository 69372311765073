import React from 'react';
import {
    Container,
} from 'reactstrap';
import DateOrder from '../dateByOrder/dateOrder';
class OldOrders extends React.Component {
    state={
        url:'mainordersheet'
    }
    render() {
        const {url} = this.state
        return (
            <>
                <Container className="mt-0" fluid>
                    <div className="card_border card">
                        <DateOrder url={url}/>
                    </div>
                </Container>
            </>
        )
    }
}
export default OldOrders;