import { 
    GET_VARIENT,     
    GET_VARIENT_ADD, 
    GET_VARIENT_DELETE,
    GET_VARIENT_VIEW,
    GET_VARIENT_UPDATE,
    GET_VARIENT_SLUG,
    GET_INITIALSTATE_VARIENT
} from "../../sharedFile/actionType";

export const varient = ()=>({
    type:GET_VARIENT
})
export const varienInitial = ()=>({
    type:GET_INITIALSTATE_VARIENT
})

export const addVarient = (data) => ({
    type:GET_VARIENT_ADD,
    data
})

export const deleteVarient = (data) => ({
    type:GET_VARIENT_DELETE,
    data
})
export const varientBySlug = (data) => ({
    type:GET_VARIENT_SLUG,
    data
})

export const viewVarient = (data) => ({
    type: GET_VARIENT_VIEW,
    data
})

export const updateVarient = (id,data) => ({
    type: GET_VARIENT_UPDATE,
    id,
    data
})