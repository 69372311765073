import {
    SET_MATERIAL_FAILED,
    SET_MATERIAL_SUCCESS,
    ADD_MATERIAL_FAILED,
    ADD_MATERIAL_SUCCESS,
    DELETE_MATERIAL_FAILED,
    DELETE_MATERIAL_SUCCESS,
    VIEW_MATERIAL_FAILED,
    VIEW_MATERIAL_SUCCESS,
    UPDATE_MATERIAL_FAILED,
    UPDATE_MATERIAL_SUCCESS,
    SET_POLYFILL_MATERIAL_SUCCESS,
    SET_POLYFILL_MATERIAL_FAILED,
    GET_CATEGORY_MATERIAL_SUCCESS,
    GET_CATEGORY_MATERIAL_FAILED,
    GET_SUB_CATEGORY_MATERIAL_SUCCESS,
    GET_SUB_CATEGORY_MATERIAL_FAILED,
    GET_INITIALSTATE,
    GET_INITIALSTATE_MATERIAL
} from "../../sharedFile/actionType";

const initialState = {
    material: [],
    error: false,
    deleted:false
}
const material = (state = initialState, action) => {
    switch (action.type) {
        case SET_MATERIAL_SUCCESS:
            return {
                ...state,
                material: action.data
            }
        case SET_MATERIAL_FAILED:
            return {
                ...state,
                error: true,
                material: action.error
            }
        case ADD_MATERIAL_SUCCESS:
            return {
                ...state,
                addmaterial: action.data
            }
        case ADD_MATERIAL_FAILED:
            return {
                ...state,
                error: true,
                addmaterial: action.error
            }
        case DELETE_MATERIAL_SUCCESS:
            return {
                ...state,
                material: { materials: state.material.materials.filter(item => item.id !== action.data.materials.id),success: true,message:'Material Deleted Successfully'},
                deleted:true
            }
        case DELETE_MATERIAL_FAILED:
            return {
                ...state,
                error: true,
                deleteMaterial:action.error
            }
        case VIEW_MATERIAL_SUCCESS:
            return {
                ...state,
                detailmaterial: action.data,
                redirect: action.redirect
            }
        case VIEW_MATERIAL_FAILED:
            return {
                ...state,
                error: true,
                detailmaterial: action.error
            }
        case UPDATE_MATERIAL_SUCCESS:
            return {
                ...state,
                detailmaterial: action.data
            }
        case UPDATE_MATERIAL_FAILED:
            return {
                ...state,
                error: true,
                detailmaterial: action.error
            }
        case SET_POLYFILL_MATERIAL_SUCCESS:
            return {
                ...state,
                polyfillmaterial: action.data
            }
        case SET_POLYFILL_MATERIAL_FAILED:
            return {
                ...state,
                error: true,
                polyfillmaterial: action.error
            }
        case GET_CATEGORY_MATERIAL_SUCCESS:
            return {
                ...state,
                materialCategory: action.data
            }
        case GET_CATEGORY_MATERIAL_FAILED:
            return {
                ...state,
                error: true,
                materialCategory: action.error
            }
        case GET_SUB_CATEGORY_MATERIAL_SUCCESS:
            return {
                ...state,
                materialCategory: action.data
            }
        case GET_SUB_CATEGORY_MATERIAL_FAILED:
            return {
                ...state,
                error: true,
                materialCategory: action.error
            }
        case GET_INITIALSTATE_MATERIAL:
            return {
                materialCategory: [],
            }
        case GET_INITIALSTATE:
            return {
                ...state,
                detailmaterial: [],
                deleteMaterial:[],
                addmaterial:[],
                redirect: false,
                deleted:false
            }
        default:
            return state
    }

}
export default material;