import React from "react";
import { MDBDataTable } from 'mdbreact';
import {
  Card,
  CardHeader,
  Button,
  NavItem,
  Nav,
  Row
} from "reactstrap";
function Tables(props) {
  const { data } = props
  return (
    <>
      <Card className="card_border">
        <CardHeader className="border-0 pt-0">
          <Row className="align-items-center mobile_sec_col">
            <div className="col">
              <h2 className="mb-0">{props.title}</h2>
            </div>
            <div className="col">
              <Nav className="justify-content-end" pills>
                <NavItem>
                  <Button onClick={props.addForm} className="btn btn-primary">Add Brand</Button>
                </NavItem>
              </Nav>
            </div>
          </Row>
        </CardHeader>
        <MDBDataTable
          className="tableSize imageSize"
          striped
          bordered
          hover
          responsive
          data={data}
        />
      </Card>
    </>
  );
}

export default Tables;
