import React from 'react'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Spinner
} from "reactstrap";
import InlineError from '../../../sharedFile/inlineError'
export default function ChangePassword(props) {
    const { data, errors, loader } = props
    return (
        <Container className="mt-0" fluid>
            <Row>
                <Col className="order-xl-1" xl="12">
                    <Card className="bg-white card_border ">
                        <CardHeader className="bg-white border-0">
                            <Row className="align-items-center">
                                <Col xs="12">
                                    <h2 className="mb-0 text-center">Change Password</h2>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="pt-0">
                            <Form className="form_section mb-5" onSubmit={props.onSubmitPassword}>
                                <Row>
                                    <Col lg="6">
                                    </Col>
                                    <Col lg="6" className="text-right">
                                        <Button onClick={props.changePassword}>Back</Button>
                                    </Col>
                                </Row>
                                <div className="pl-lg-0">
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup error={!errors.oldPassword}>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-email"
                                                >
                                                    Old Password
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-email"
                                                    defaultValue={data.oldPassword}
                                                    onChange={props.onChange}
                                                    name="oldPassword"
                                                    placeholder="old password"
                                                    type="password"
                                                />
                                                {errors.oldPassword && <InlineError text={errors.oldPassword} />}
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup error={!errors.newPassword}>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-username"
                                                >
                                                    New Password
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    defaultValue={data.newPassword}
                                                    onChange={props.onChange}
                                                    id="input-username"
                                                    placeholder="New Password"
                                                    name="newPassword"
                                                    type="password"
                                                />
                                                {errors.newPassword && <InlineError text={errors.newPassword} />}
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup error={!errors.confirmPassword}>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-username"
                                                >
                                                    Confirm Password
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    defaultValue={data.confirmPassword}
                                                    onChange={props.onChange}
                                                    id="input-username"
                                                    placeholder="New Password"
                                                    name="confirmPassword"
                                                    type="password"
                                                />
                                                {errors.confirmPassword && <InlineError text={errors.confirmPassword} />}
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                </div>
                                <Row>
                                    <Col className="custom-submit">
                                        <Button type="submit" className="my-4 submit-button" color="primary">
                                            {
                                                loader
                                                    ?
                                                    <Spinner color="light" />
                                                    :
                                                    'Change Password'
                                            }
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}