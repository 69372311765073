import React from "react";
import {
  Table,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row
} from "reactstrap";

const EditTable = props => {
  const { data, total } = props;
  let pages = [];
  for (let i = 1; i < total + 1; i++) {
    pages.push(i);
  }
  return (
    <Row>
      <div className="col-sm-12">
        <div className="d-flex search_bar">
          <label className="pr-3">Search</label>
          <Input
            type="search"
            name="search"
            value={props.search}
            id="search"
            placeholder="..search"
            onChange={props.searchChange}
          />
        </div>
        <div className="tableEdit_table">
          <Table responsive striped bordered className="tableEdit">
            <thead className="pageList">
              <tr>
                {data.columns &&
                  data.columns.map((val, key) => {
                    return <th className={val.class}>{val.label}</th>;
                  })}
              </tr>
            </thead>
            {data.rows && data.rows.length > 0 ? (
              <tbody>
                {data.rows &&
                  data.rows.map((val, key) => {
                    return (
                      <tr
                        className={
                          props.rows &&
                          props.rows[key] &&
                          props.rows[key].updateClass
                        }
                      >
                        {Object.values(val).map(value => {
                          return <td>{value}</td>;
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={data.columns && data.columns.length}>
                    No result found
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
        {/* <Pagination aria-label="Page navigation example">
        <PaginationItem disabled={props.pageNumber ==1 ? true: false }>
          <PaginationLink onClick={e=>props.changePage(e, props.pageNumber-1)}>
            Previous
          </PaginationLink>
        </PaginationItem>
        {
          pages.map(val => {
            return <PaginationItem active = {val==props.pageNumber ? true: false }>
              <PaginationLink onClick={e=>props.changePage(e, val)}>
                {val}
              </PaginationLink>
            </PaginationItem>
          })
        }
        <PaginationItem disabled={props.pageNumber == total ? true: false }>
          <PaginationLink  onClick={e=>props.changePage(e, props.pageNumber+1)}>
            Next
          </PaginationLink>
        </PaginationItem>
      </Pagination> */}
      </div>
    </Row>
  );
};
export default EditTable;
