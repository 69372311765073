import React from 'react';
import { Button, Card, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Col, Spinner } from "reactstrap";
import InlineError from '../../../sharedFile/inlineError';

function LoginForm(props) {
    const { errors, loader } = props
    return (
        <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                        <p>Sign in with credentials</p>
                    </div>
                    <Form role="form" onSubmit={props.onSubmit}>
                        <FormGroup className="mb-3" error={errors.email}>
                            <InputGroup className="input-group-alternative" >
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-email-83" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="Username" type="text" name="email" onChange={props.onChange} />
                            </InputGroup>
                            {errors.email && <InlineError text={errors.email} />}
                        </FormGroup>
                        <FormGroup error={errors.password}>
                            <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-lock-circle-open" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="Password" type="password" name="password" onChange={props.onChange} />
                            </InputGroup>
                            {errors.password && <InlineError text={errors.password} />}
                        </FormGroup>
                        <div className="text-center custom-submit">
                            <Button className="my-4 submit-button" color="primary" type="submit">
                                {
                                    loader
                                        ?
                                        <Spinner color="light" />
                                        :
                                        'Sign in'
                                }
                            </Button>
                        </div>
                    </Form>
                </CardBody>
            </Card>
            {/* <Row className="mt-3">
                <Col xs="6">
                    <span
                        className="text-light"
                        onClick={e => e.preventDefault()}
                    >
                        <small>Forgot password?</small>
                    </span>
                </Col>
            </Row> */}
        </Col>
    )
}
export default LoginForm;