import React from "react";
import AddForm from './addForm';
import { addMaterial } from '../../services/actions/material';
import { subCategory } from '../../services/actions/subCategory';
import { initialState } from '../../services/actions/stateInitial';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addMaterialValid, imageSizeValid } from '../../sharedFile/validator';
import { toast } from 'react-toastify';
var toastId = null
class AddMaterial extends React.Component {
  state = {
    data: {
      name: '',
      description: '',
      subCategoryId: '',
      picture: []
    },
    errors: {},
    loader: false,
    errImage: {},
    loaderGet: false,
    message: "",
    imagesPreviewUrls: [],
    pageNumber: 1
  }
  onSubmit = (e) => {
    e.preventDefault();
    const { data } = this.state
    const errors = addMaterialValid(data);
    const errImage = imageSizeValid(data)
    this.setState({ errors, errImage });
    if (Object.keys(errors).length === 0 && Object.keys(errImage.picture).length === 0) {
      this.setState({
        loader: true
      })
      this.props.addMaterial(data)
    }
  }
  onChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value.trimLeft().replace(/\s\s+/g, ' ')
      }
    })
  }
  onFileChange = (e) => {
    let files = Array.from(e.target.files);
    files.forEach((file) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          data: {
            ...this.state.data,
            picture: [...this.state.data.picture, file],
          },
          imagesPreviewUrls: [...this.state.imagesPreviewUrls, reader.result]
        });
      }
      reader.readAsDataURL(file);
    });
  }
  blankPicture = () => {
    this.setState({
      data: {
        ...this.state.data,
        picture: [],
      },
      imagesPreviewUrls: []
    });
  }
  removeImage = (e, id) => {
    this.setState({
      data: {
        ...this.state.data,
        picture: this.state.data.picture.filter((s, sidx) => id !== sidx),
      },
      imagesPreviewUrls: this.state.imagesPreviewUrls.filter((s, sidx) => id !== sidx)
    });
  }
  componentDidMount() {
    this.props.subCategory();
    this.setState({
      loaderGet: true
    })
  }
  componentWillReceiveProps(newProps) {
    const { materialAdd, categorySuccess } = newProps
    if (categorySuccess) {
      if (categorySuccess.success || categorySuccess.success === false) {
        this.setState({
          loaderGet: false
        })
      }
    }
    if (materialAdd && materialAdd.success) {
      this.setState({
        data: {
          ...this.state.data,
          name: '',
          description: '',
          subCategoryId: '',
          picture: []
        },
        imagesPreviewUrls: [],
        loader: false
      })

      if (toast.isActive(toastId)) {
        toast.dismiss(toastId)
        toastId = toast.success(materialAdd.message);
        newProps.initialState()
      } else {
        toastId = toast.success(materialAdd.message);
        newProps.initialState()
      }
    }
    if (materialAdd && materialAdd.success === false) {
      this.setState({
        loader: false
      })
      if (toast.isActive(toastId)) {
        toast.dismiss(toastId)
        toastId = toast.error(materialAdd.message);
        newProps.initialState()
      } else {
        toastId = toast.error(materialAdd.message);
        newProps.initialState()
      }
    }
  }

  render() {
    const { categorySuccess } = this.props
    return (
      <>
        <AddForm  {...this.state} category={categorySuccess} onFileChange={this.onFileChange} onSubmit={this.onSubmit} onChange={this.onChange} removeImage={this.removeImage} blankPicture={this.blankPicture} />
      </>
    );
  }
}
const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    subCategory,
    addMaterial,
    initialState
  }, dispatch)
}
const mapGetStateNew = (state) => {
  return {
    categorySuccess: state.subCategory.subCategory,
    materialAdd: state.material.addmaterial
  }
}
export default connect(mapGetStateNew, mapDispatchState)(AddMaterial);
