import {
    SET_SUB_CATEGORY_FAILED,
    SET_SUB_CATEGORY_SUCCESS,
    SET_SUB_CATEGORY_DETAIL_SUCCESS,
    SET_SUB_CATEGORY_DETAIL_FAILED,
    SET_DELETE_SUB_CATEGORY_FAILED,
    SET_DELETE_SUB_CATEGORY_SUCCESS,
    SET_ADD_SUB_CATEGORY_FAILED,
    SET_ADD_SUB_CATEGORY_SUCCESS,
    SET_UPDATE_SUB_CATEGORY_FAILED,
    GET_INITIALSTATE_SUBCATE,
    GET_INITIALSTATE_SUB
} from "../../sharedFile/actionType";

const initialState = {
    subCategory: [],
    redirect: false,
    error: false,
    deleted: false
}
const subCategory = (state = initialState, action) => {
    switch (action.type) {
        case GET_INITIALSTATE_SUBCATE:
            return {
                ...state,
                subCategory: []
            }
        case SET_SUB_CATEGORY_SUCCESS:
            return {
                ...state,
                subCategory: action.data
            }
        case SET_SUB_CATEGORY_FAILED:
            return {
                ...state,
                error: true,
                subCategory: action.error
            }
        case SET_SUB_CATEGORY_DETAIL_SUCCESS:
            return {
                ...state,
                detailSubCategory: action.data,
                redirect: action.redirect
            }
        case SET_SUB_CATEGORY_DETAIL_FAILED:
            return {
                ...state,
                error: true,
                detailSubCategory: action.error
            }
        case SET_DELETE_SUB_CATEGORY_SUCCESS:
            return {
                ...state,
                subCategory: { subcategories: state.subCategory.subcategories.filter(item => item.id !== action.data.subcategory.id), success: true, message: 'Sub Category Deleted Successfully' },
                deleted: true
            }
        case SET_DELETE_SUB_CATEGORY_FAILED:
            return {
                ...state,
                error: true,
                deleteSub:action.error
            }
        case SET_ADD_SUB_CATEGORY_SUCCESS:
            return {
                ...state,
                subCategoryAdd: action.data
            }
        case SET_ADD_SUB_CATEGORY_FAILED:
            return {
                ...state,
                error: true,
                subCategoryAdd: action.error
            }
        case SET_UPDATE_SUB_CATEGORY_FAILED:
            return {
                ...state,
                updateSubCategory: action.error
            }
        case GET_INITIALSTATE_SUB:
            return {
                ...state,
                subCategoryAdd: [],
                updateSubCategory:[],
                detailSubCategory: [],
                deleteSub:[],
                redirect: false,
                deleted: false
            }
        default:
            return state
    }

}
export default subCategory;