
import React from "react";
import {
  Container,
  Row,
  Button,
  Spinner
} from "reactstrap";
// core components
import Header from "../../components/Headers/Header.jsx";
import Table from "./brandTable"
import { initialState } from '../../services/actions/order'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { brand, deleteBrand } from '../../services/actions/brand';
import ConfirmModal from '../../components/ConfirmModal';
import FormModel from "../../components/formModel.jsx";
import EditBrand from "./EditBrand.jsx";
import AddBrand from "./AddBrand.jsx";
import { MDBIcon } from 'mdbreact';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import { imagerUrl } from '../../config/config';
var toastId = null

class BrandList extends React.Component {
  state = {
    data: {
      columns: [
        { label: '#', field: '#', sort: 'asc' },
        { label: 'Brand Logo', field: 'logo', sort: 'asc' },
        { label: 'Brand Name', field: 'Brand', sort: 'asc' },
        { label: 'Brand Description', field: 'Description', sort: 'asc' },
        { label: 'Created on', field: 'Created on', sort: 'asc', width: 30 },
        { label: 'Updated on', field: 'Updated on', sort: 'asc' },
        { label: 'Action', field: 'Action', sort: 'disabled' }
      ],
      rows: []
    },
    loader: false,
    open: false,
    id: '',
    name: '',
    openEditFrom: false,
    openMaterialImg:false,
    openForm: false,
    editId: null,
    materialImg:null
  }
  componentDidMount() {
    this.props.brand();
    this.setState({
      loader: true
    })
  }

  confirm = (event, id, name) => {
    this.setState({
      open: true,
      id: id,
      name: name
    })
  }
  toggle = () => {
    this.setState({
      open: false,
      openEditFrom: false,
      openForm: false,
      openMaterialImg:false,
    })
  }
  confirmation = (event, id) => {
    this.setState({
      open: false
    })
    this.props.deleteBrand(id)
  }
  openSlider = (e,materialImg) =>{
    this.setState({
      openMaterialImg:true,
      materialImg:materialImg
    })
  }
  componentWillReceiveProps(props) {
    const { brandSuccess, detailbrand, detailbrandRedirect, deleteBrandFailed } = props
    if (detailbrandRedirect.redirect == true) {
      this.setState({
        openEditFrom: false
      })
    }
    if (detailbrand && detailbrand.success === false) {
      if (detailbrand.message) {
        if(toast.isActive(toastId)){
          toast.dismiss(toastId)
          toastId = toast.error(detailbrand.message);
        }else{
          toastId = toast.error(detailbrand.message);
        }
      }
    }
    if (deleteBrandFailed && deleteBrandFailed.success === false) {
      if (deleteBrandFailed.message) {
        if(toast.isActive(toastId)){
          toast.dismiss(toastId)
          toastId = toast.error(deleteBrandFailed.message);
          props.initialState()
        }else{
          toastId = toast.error(deleteBrandFailed.message);
          props.initialState()
        }
      }
    }
    if (brandSuccess && brandSuccess.success) {
      let descrip
      var testData = brandSuccess && brandSuccess.brands && brandSuccess.brands.map((items, key) => {
        let imgSrc = items && items.logo ? imagerUrl + items.logo : '/logo.png'
        if (items.description.length > 30)
          descrip = items.description.substring(0, 30) + '...';
        else
          descrip = items.description
        return (
          {
            '#': key + 1,
            'logo':<img src={imgSrc} width="50" style={{height: '50px',borderRadius: '50%',objectFit: 'cover'}} className="cursor-pointer" alt="brand" onClick={e => this.openSlider(e, imgSrc)}></img>,
            'Brand': items.name,
            'Description': <>{items.description.length > 30 ? <span className="tooltip1" key={'abcdeZ' + key} >{descrip}<span className="tooltiptext textFLow">{items.description}</span></span> : items.description}</>,
            'Created on': moment(items.createdAt).utcOffset("+05:30").format('DD/MM/YY, H:mm'),
            'Updated on': moment(items.updatedAt).format('DD/MM/YY, H:mm'),
            'Action': [
              // <Button className="action_btn text-black" outline color="" tag={Link} to={`/view-brand/${items.id}`}><MDBIcon far icon="eye" title="View" /></Button>,
            <Button className="action_btn text-black" outline color="" onClick={e => this.editFrom(e, items.id)}><MDBIcon far icon="edit" title="Edit" /></Button>,
            <Button className="action_btn text-black" outline color="" onClick={e => this.confirm(e, items.id, items.name)}><MDBIcon far icon="trash-alt" title="Delete" /></Button>]
          }
        )
      })
      if (detailbrandRedirect.deleted) {
        if(toast.isActive(toastId)){
          toast.dismiss(toastId)
          toastId = toast.success(brandSuccess.message);
          props.initialState()
        }else{
          toastId = toast.success(brandSuccess.message);
          props.initialState()
        }
      }
      this.setState({
        data: {
          ...this.state.data,
          rows: testData
        },
        loader: false
      })
    }
    if (brandSuccess && brandSuccess.success == false) {
      this.setState({
        loader: false
      })
    }
  }
  addForm = () => {
    this.setState({
      openForm: true
    })
  }
  editFrom = (e, id) => {
    this.setState({
      openEditFrom: true,
      editId: id
    })
  }
  render() {
    const { open, loader, openForm, openEditFrom, editId,openMaterialImg,materialImg } = this.state
    return (
      <>
        <ToastContainer autoClose={4000} />
        {
          !loader ?
            <>
              <Header />
              <Container className="mt-0" fluid>
                <Row>
                  <div className="col">
                    <Table title="Brands Listing" pageNumber={this.state.pageNumber} addForm={this.addForm} {...this.state} />
                    <ConfirmModal open={open} toggle={this.toggle} confirmation={this.confirmation} id={this.state.id} name={this.state.name} />
                  </div>
                </Row>
                <FormModel modelForm={<img src={materialImg} width="100%"/>} toggle={this.toggle} openForm={openMaterialImg} />
                <FormModel modelForm={<AddBrand />} toggle={this.toggle} openForm={openForm} />
                <FormModel modelForm={<EditBrand editId={editId} />} toggle={this.toggle} openForm={openEditFrom} />
              </Container>
            </>
            :
            <div className="loader">
              <Spinner variant="light" />
              <p style={{ color: '#fff', paddingTop: '15px' }}>Please wait......</p>
            </div>
        }

      </>
    );
  }
}
const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    brand,
    initialState,
    deleteBrand
  }, dispatch)
}
const mapGetStateNew = (state) => {
  return {
    brandSuccess: state.brand.brand,
    brandAddSuccess: state.brand.brandAdd,
    detailbrand: state.brand.detailbrand,
    detailbrandRedirect: state.brand,
    deleteBrandFailed: state.brand.deleteBrand,
  }
}
export default connect(mapGetStateNew, mapDispatchState)(BrandList);
