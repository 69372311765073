import { GET_SUB_CATEGORY, GET_SUB_CATEGORY_DETAIL, GET_DELETE_SUB_CATEGORY, GET_UPDATE_SUB_CATEGORY, GET_ADD_SUB_CATEGORY } from "../../sharedFile/actionType";

export const subCategory = () => ({
    type: GET_SUB_CATEGORY,
})
export const subCategoryDetail = (data) => ({
    type: GET_SUB_CATEGORY_DETAIL,
    data,
})
export const subCategoryDelete = (data) => ({
    type: GET_DELETE_SUB_CATEGORY,
    data,
})
export const subCategoryUpdate = (data, id) => ({
    type: GET_UPDATE_SUB_CATEGORY,
    data,
    id
})
export const subCategoryAdd = (data) => ({
    type: GET_ADD_SUB_CATEGORY,
    data,
})