import React, {useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import AdminNavbar from "../components/Navbars/AdminNavbar.jsx";
import routes from "../routes.js";

const AdminRoute = ({ isLoggedin,location, component: Component, ...rest} ) => {
    useEffect(() => {
        document.body.classList.remove("bg-default");
    });
    return <div className="main-content" >
            <AdminNavbar
                routes={routes}
                logo={{
                    innerLink: "/dashboard",
                    // imgSrc: require("assets/img/brand/argon-react.png"),
                    imgAlt: "..."
                }}
                location={location}
            />
            <Route {...rest} render={props => isLoggedin ? <Component {...props} /> : <Redirect to="/" />} />
        </div>
}
const mapStateAuth = (state) => {
    return {
        isLoggedin: state.user.token
    }
}
export default connect(mapStateAuth)(AdminRoute);