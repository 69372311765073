import { 
    GET_MATERIAL,
    GET_MATERIAL_ADD, 
    GET_MATERIAL_DELETE,
    GET_MATERIAL_VIEW,
    GET_MATERIAL_UPDATE,
    GET_POLYFILL_MATERIAL,
    GET_CATEGORY_MATERIAL,
    GET_INITIALSTATE_MATERIAL,
    GET_SUB_CATEGORY_MATERIAL,
    GET_IMAGE_MATERIAL_DELETE
} from "../../sharedFile/actionType";

export const material = (data)=>{
    return{
        type:GET_MATERIAL
    }
}
export const addMaterial = (data) => {
    return{
        type:GET_MATERIAL_ADD,
        data
    }
}
export const deleteMaterial = (data) => ({
    type:GET_MATERIAL_DELETE,
    data
})
export const deleteImageMaterial = (id) => ({
    type:GET_IMAGE_MATERIAL_DELETE,
    id
})
export const viewMaterial = (data) => ({
    type: GET_MATERIAL_VIEW,
    data
})
export const updateMaterial = (id,data) => ({
    type: GET_MATERIAL_UPDATE,
    id,
    data
})
export const categoryMaterial = (id) => ({
    type: GET_CATEGORY_MATERIAL,
    id
})
export const subCategoryMaterial = (id) => ({
    type: GET_SUB_CATEGORY_MATERIAL,
    id
})
export const initialStateMaterial = () => ({
    type: GET_INITIALSTATE_MATERIAL,
})
export const polyfillMaterial = ()=>{
    return{
        type:GET_POLYFILL_MATERIAL
    }
}