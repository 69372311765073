import React from "react";
import Header from "../../components/Headers/Header";
import { viewBrand } from '../../services/actions/brand'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { imagerUrl } from '../../config/config';
import { ToastContainer, toast } from 'react-toastify';

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
} from "reactstrap";
var toastId = null
class ViewBrand extends React.Component {
  state = {
    data: {
      name: '',
      logo: '',
      description: ''
    },
    errors: {},
    loader: false,
    message: ""
  }
  componentDidMount() {
    this.props.viewBrand(this.props.match.params.id);
    this.setState({
      loader: true
    })
  }
  componentWillReceiveProps(newProps) {
    const brand = newProps.detailbrand && newProps.detailbrand.brand
    if (newProps.detailbrand && newProps.detailbrand.success) {
      this.setState({
        data: {
          ...this.state.data,
          name: brand.name,
          description: brand.description,
          logo: brand && brand.logo ? imagerUrl + brand.logo : '',
        },
        loader: false
      })
    } else {
      this.setState({
        loader: false
      })
      if (!toast.isActive(toastId))
        toastId = toast.error(newProps.detailvarient)
    }
  }
  render() {
    return (
      <>
        <Header />
        <ToastContainer autoClose={10000} />
        <Container className="mt-0" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="card_border">
                <CardHeader className="bg-white border-0 mb-4">
                  <Row className="align-items-center mobile_sec_col">
                    <div className="col">
                      <h6 className="text-uppercase text-light ls-1 mb-1">
                      </h6>
                      <h2 className="mb-0 text-mob-left text-center">View Brand</h2>
                    </div>
                    <div className="col back_btn">
                      <Nav className="justify-content-end" pills>
                        <NavItem>
                          <NavLink className="btn btn-primary" to={'/brands'} tag={Link}  >Back</NavLink>
                          {/* <NavLink className="btn btn-primary"  onClick={e => this.manage(e, this.state.data.id , this.state.data.name)}>Manage Varients</NavLink> */}
                        </NavItem>
                      </Nav>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0 detail_page mb-5 shadow">
                  <Row>
                    <Col className="p-md-4 p-3" md="5">
                      <h3 className="mb-md-3 mb-2">Brand Picture</h3>
                      <div className="card_body">
                        <Col className="curcle_img text-center">
                          <img src={this.state.data.logo ? this.state.data.logo : '/logo.png'} width="100" alt="brand"></img>
                        </Col>


                      </div>
                    </Col>
                    <Col className="p-md-4 p-3" md="6">
                      <h3 className="mb-md-3 mb-2">Brand Detail</h3>
                      <CardBody className="p-1">
                        <h4 className="mb-0">Name:</h4>
                        <p className="mb-0">{this.state.data.name}</p>
                      </CardBody>
                      {/* <CardBody className="p-1">
                          <h4>Catgeory:</h4>
                          <p>{this.state.data.category}</p>
                        </CardBody> */}
                      <CardBody className="p-1">
                        <h4 className="mb-0">Description:</h4>
                        <p className="mb-0">{this.state.data.description}</p>
                      </CardBody>
                    </Col>
                  </Row>
                  {/* <div className="pl-lg-4">
                        <Row>
                            <Col lg="4">
                            <CardBody><h4>Logo:</h4></CardBody>
                            </Col>
                            <Col lg="8">
                            <CardBody><img src = {this.state.data.logo} width="100" alt="brand"></img></CardBody>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4">
                            <CardBody><h4>Name:</h4></CardBody>
                            </Col>
                            <Col lg="8">
                            <CardBody>{this.state.data.name}</CardBody>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4">                       
                            <CardBody><h4>Description:</h4></CardBody>
                            </Col>
                            <Col lg="8">
                            <CardBody>{this.state.data.description}</CardBody>
                            </Col>
                        
                        </Row>                    
                    </div> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    viewBrand
  }, dispatch)
}
const mapGetStateNew = (state) => {
  return {
    detailbrand: state.brand.detailbrand
  }
}
export default connect(mapGetStateNew, mapDispatchState)(ViewBrand);
