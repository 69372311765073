import React from "react";
import { Link } from 'react-router-dom';
import { viewCategory, assignVarient, viewAssignVarient, deleteAssignVarient } from '../../services/actions/category';
import { varient } from '../../services/actions/varient';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ToastContainer, toast } from 'react-toastify';
import { manageVarientValid } from '../../sharedFile/validator';
import Table from "./categoryTable"
import ManageVarient from './ManageVarient';
import { Card, CardBody, NavItem, NavLink, Nav, Container, Spinner, Row, Col, Button } from "reactstrap";
import { initialState } from '../../services/actions/order'
import { MDBIcon } from 'mdbreact';
import ConfirmModal from "../../components/ConfirmModal";
var toastId = null
class ViewCategory extends React.Component {
  state = {
    listVarient: {
      columns: [
        { label: '#', field: '#', sort: 'asc' },
        { label: 'Variant Name', field: 'Variant Name', sort: 'asc', },
        { label: 'Values', field: 'Values', sort: 'asc', },
        { label: 'Action', field: 'Action', sort: 'disabled', }
      ],
      rows: []
    },
    data: {
      id: '',
      name: '',
      description: '',
      varientId: []
    },
    dataVarient: {
      categoryId: '',
      varient: [{
        varientId: '',
        value: ''
      }]
    },
    open: false,
    showAll: false,
    openDelete: false,
    errors: { varientId: [] },
    loader: false,
    message: "",
    idDelete: null,
    variantEdit: false,
    id: this.props.match.params.id
  }
  componentDidMount() {
    const { id } = this.state
    this.setState({
      dataVarient: {
        ...this.state.dataVarient,
        categoryId: id
      }
    })
    this.props.viewCategory(id);
    this.props.varient()
    this.props.viewAssignVarient(id);
    this.setState({
      loader: true
    })
  }

  toggle = () => {
    this.setState({
      open: false,
      openDelete: false,
      dataVarient: {
        ...this.state.dataVarient,
        varient: [{
          varientId: '',
          value: ''
        }]
      }
    })
  }

  manage = (event, id) => {
    this.setState({
      open: true,
      data: {
        ...this.state.data,
        id: id
      }
    })
    this.props.initialState()
  }
  onChange = idx => e => {
    const { dataVarient } = this.state
    const newvarientList = dataVarient.varient.map((itemList, sidx) => {
      if (idx !== sidx) return itemList;
      return { ...itemList, [e.target.name]: e.target.value };
    });
    this.setState({
      dataVarient: {
        ...this.state.dataVarient,
        varient: newvarientList
      }
    });
  }
  onAddMoreVraients = (e) => {
    const { dataVarient } = this.state
    const { varientSuccess } = this.props
    if (varientSuccess.varients && varientSuccess.varients.length !== dataVarient.varient.length) {
      this.setState({
        dataVarient: {
          ...this.state.dataVarient,
          varient: [...dataVarient.varient, { varientId: "", value: '' }]
        }
      });
    } else { }
  }
  onSubmit = () => {
    const errors = manageVarientValid(this.state.dataVarient);
    this.setState({ errors });
    if (Object.keys(errors.varientId).length === 0) {
      this.setState({
        loader: true,
        open: false,
        dataVarient: {
          ...this.state.dataVarient,
          varient: [{
            varientId: '',
            value: ''
          }]
        },
      })
      this.props.assignVarient(this.state.dataVarient)
    }
  }
  handleRemove = idx => () => {
    this.setState({
      dataVarient: {
        ...this.state.dataVarient,
        varient: this.state.dataVarient.varient.filter((s, sidx) => idx !== sidx),
      }
    });
  };
  confirm = (event, id, name) => {
    this.setState({
      openDelete: true,
      idDelete: id,
    })
    this.props.initialState()
  }
  confirmation = (event, id) => {
    this.setState({
      openDelete: false,
      loader: true
    })
    this.props.deleteAssignVarient(id)
  }
  edit = () => {
    this.setState({
      variantEdit: true
    })
  }
  componentWillReceiveProps(newProps) {
    const { detailcategory, assignvarient, varientAssign, varientAssignDelete, varientAssignAll } = newProps
    const { id } = this.state
    if (varientAssign && varientAssign.success) {
      let i = 0;
      var testData = []
      varientAssign.categories && varientAssign.categories.map((items, key) => {
        if (items.varientCategory.id === parseInt(id)) {
          let vals = ''
          let value = items.value.split(',')
          vals = value.map((val, valueKey) => {
            return (<>{vals + val + (value.length === (valueKey + 1) ? '' : ', ')}</>)
          })
          i++;
          testData.push(
            {
              '#': i,
              'Variant Name': items.assignedVarient.name,
              'Values': <>{items.value ? vals : '-'}</>,
              'Action': [<Button className="action_btn text-black" outline color="" onClick={e => this.confirm(e, items.id, items.name)}><MDBIcon far icon="trash-alt" title="Delete" /></Button>]
            }
          )
        }
      })
      this.setState({
        listVarient: {
          ...this.state.listVarient,
          rows: testData
        },
        loader: false
      })
    }
    if (varientAssignAll.deleted) {
      if (!toast.isActive(toastId))
        toastId = toast.success(varientAssign.message)
    }
    if (varientAssignAll.message === true) {
      this.setState({
        loader: false
      })
      if (toast.isActive(toastId)) {
        toast.dismiss(toastId)
        toastId = toast.success("Variant Added Successfully")
      } else {
        toastId = toast.success("Variant Added Successfully")
      }
    }
    if (varientAssign && varientAssign.success === false) {
      if (!toast.isActive(toastId))
        toastId = toast.error(varientAssign.message)
    }
    if (detailcategory && detailcategory.success) {
      const category = detailcategory.category
      this.setState({
        data: {
          ...this.state.data,
          id: category.id,
          name: category.name,
          description: category.description
        },
        dataVarient: {
          ...this.state.dataVarient,
          categoryId: category.id
        },
        loader: false
      })
    }
    if (assignvarient && assignvarient.success === false) {
      if (!toast.isActive(toastId))
        toastId = toast.error(assignvarient && assignvarient.message)
      this.setState({
        loader: false
      })
    }
    if (varientAssignDelete && varientAssignDelete.success === false) {
      this.setState({
        loader: false
      })
      if (toast.isActive(toastId)) {
        toast.dismiss(toastId)
        toastId = toast.error(varientAssignDelete.message)
        newProps.initialState()
      }
      else {
        toastId = toast.error(varientAssignDelete.message)
        newProps.initialState()
      }
    }
  }
  componentWillUnmount() {
    this.props.initialState()
  }
  showMore = () => this.setState({ showAll: true });
  showLess = () => this.setState({ showAll: false });
  render() {
    const { varientSuccess } = this.props
    const { listVarient, openDelete, idDelete, data, showAll, loader } = this.state
    const limit = 200
    var toShow = data.description.substring(0, limit) + "...";
    return (
      <>
        <ToastContainer autoClose={10000} />
        {
          !loader ?
            <>
              <Container className="mt-0" fluid>
                <Row>
                  <Col className="order-xl-1" xl="12">
                    <Card className="card_border">
                      <Row className="align-items-center">
                        <Col className="pb-2" md="8">
                          <h2 className="mb-0">Category Detail</h2>
                          <CardBody className="p-1 d-flex">
                            <h4 className="mb-0" style={{ fontSize: '14px', fontWeight: '600', paddingRight: '3px' }}>Name:</h4>
                            <p className="mb-0" style={{ fontSize: '13px' }}>{data.name}</p>
                          </CardBody>
                          <CardBody className="p-1">
                            <h4 className="mb-0" style={{ fontSize: '14px', fontWeight: '600', paddingRight: '3px' }}>Description:</h4>
                            {
                              showAll ?
                                <>
                                  <p className="mb-0" style={{ fontSize: '13px' }}>{data.description}</p>
                                  <span className="cursor-pointer" style={{ fontSize: '13px', color: '#244a6b', fontWeight: '600' }} onClick={this.showLess}>Show less</span>
                                </>
                                :
                                <>
                                  {
                                    data.description.length > limit ?
                                      <>
                                        <span className="mb-0" style={{ fontSize: '13px', paddingRight: '3px' }}>{toShow}</span>
                                        <p className="cursor-pointer" style={{ fontSize: '13px', color: '#244a6b', fontWeight: '600' }} onClick={this.showMore}>Show more</p></> :
                                      <>
                                        <p className="mb-0" style={{ fontSize: '13px' }}>{data.description}</p>
                                      </>
                                  }
                                </>
                            }
                          </CardBody>
                        </Col>
                        <Col className="pb-2" md="4">
                          <Nav className="justify-content-end mobileCenter" pills>
                            <NavItem style={{ paddingRight: '1rem' }}>
                              <NavLink className="btn btn-primary " onClick={e => this.manage(e, this.state.data.id, this.state.data.name)}>Add Variants</NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink className="btn btn-primary " to={'/categories'} tag={Link}  >Back</NavLink>
                            </NavItem>
                          </Nav>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <ManageVarient {...this.state} toggle={this.toggle} onSubmit={this.onSubmit} varients={varientSuccess} onChange={this.onChange} onAddMoreVraients={this.onAddMoreVraients} handleRemove={this.handleRemove} />
                <Table dataListtitle="Variants" data={listVarient} pageNumber={this.state.pageNumber} />
                <ConfirmModal open={openDelete} toggle={this.toggle} confirmation={this.confirmation} id={idDelete} />
              </Container>
            </>
            :
            <div className="loader" style={{ top: '0px' }}>
              <Spinner variant="light" />
              <p style={{ color: '#fff', paddingTop: '15px' }}>Please wait......</p>
            </div>
        }
      </>
    );
  }
}
const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    viewCategory,
    assignVarient,
    viewAssignVarient,
    varient,
    initialState,
    deleteAssignVarient
  }, dispatch)
}
const mapGetStateNew = (state) => {
  return {
    detailcategory: state.category.detailcategory,
    varientSuccess: state.varient.varient,
    varientAssign: state.category.varientAssign,
    assignvarient: state.varient.assign,
    varientAssignDelete: state.category.varientAssignDelete,
    varientAssignAll: state.category
  }
}
export default connect(mapGetStateNew, mapDispatchState)(ViewCategory);
