
import React from 'react'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import { Link } from 'react-router-dom';
import { Select, FormControl, InputLabel, MenuItem, Checkbox, ListItemText } from '@material-ui/core'
import MaterialInput from '@material-ui/core/Input'
import InlineError from '../../sharedFile/inlineError'
import Required from '../../sharedFile/required';
// import { Multiselect } from 'multiselect-react-dropdown';
const Supplierform = (props) => {
  const { errors, loader, material, category, materialSuccess, title } = props
  const {
    firstName,
    lastName,
    companyName,
    email,
    mobile,
    gstNumber,
    phone,
    state,
    city,
    address,
    zip,
    categoryId,
    materialId,
    country,
    materialjson
  } = props.data
  return (

    <Container className="mt-0" fluid>
      <Row>
        <Col className="order-xl-1" xl="12">
          <Card className="card_border">
            <CardHeader className="white border-0">
              <Row className="align-items-center">
                <div className="col">
                  <h2 className="mb-0 text-center">{title} Supplier</h2>
                </div>
                <div className="col back_btn">
                  <Nav className="justify-content-end" pills>
                    <NavItem>
                      <NavLink className="btn btn-primary " to={'/suppliers'} tag={Link} >Back</NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Row>
            </CardHeader>
            <CardBody className="pt-0">
              <Form className="form_section mb-5" onSubmit={props.onSubmit}>
                <div className="">
                  <Row>
                    <Col lg="12" mg="12">
                    <span className="italic-message">* - indicate required fields!!</span>
                    </Col>
                    <Col lg="6">
                      <FormGroup
                        error={!errors.companyName}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="input-companyName"
                        >
                          Company Name<Required />
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={companyName}
                          onChange={props.onChange}
                          maxLength="30"
                          id="input-companyName"
                          placeholder="Company Name"
                          name="companyName"
                          type="text"
                        />

                        {errors.companyName && <InlineError text={errors.companyName} />}

                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup
                        error={!errors.email}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="input-description"
                        >
                          Email<Required />
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={email ? email : ''}
                          onFocus={props.onFocus}
                          onChange={props.onChange}
                          name="email"
                          id="input-email"
                          placeholder="email"
                          type="text"
                        />
                        {errors.email && <InlineError text={errors.email} />}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>

                    <Col lg="6">
                      <FormGroup
                        error={!errors.firstName}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="input-firstName"
                        >
                          First Name<Required />
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={firstName ? firstName : ''}
                          onChange={props.onChange}
                          id="input-firstName"
                          maxLength="30"
                          placeholder="First name"
                          name="firstName"
                          type="text"
                        />
                        {errors.firstName && <InlineError text={errors.firstName} />}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup
                        error={!errors.lastName}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="input-name">
                          Last Name
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={lastName}
                          onChange={props.onChange}
                          maxLength="30"
                          id="input-lastname"
                          placeholder="last name"
                          name="lastName"
                          type="text"
                        />
                        {errors.lastName && <InlineError text={errors.lastName} />}
                      </FormGroup>
                    </Col>

                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup
                        error={!errors.email}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="input-mobile"
                        >
                          Mobile<Required />
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={mobile}
                          onKeyPress={props.onKeyPress}
                          onChange={props.onChange}
                          name="mobile"
                          maxLength="15"
                          id="input-mobile"
                          placeholder="mobile"
                          type="text"
                        />
                        {errors.mobile && <InlineError text={errors.mobile} />}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup
                        error={!errors.phone}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="input-description"
                        >
                          Phone
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={phone}
                          onKeyPress={props.onKeyPress}
                          onChange={props.onChange}
                          maxLength="15"
                          name="phone"
                          id="input-phone"
                          placeholder="Phone"
                          type="text"
                        />
                        {errors.phone && <InlineError text={errors.phone} />}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                  <Col lg="6">
                      <FormGroup
                        error={!errors.address}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="input-address"
                        >
                          Office Address<Required />
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={address}
                          onChange={props.onChange}
                          name="address"
                          id="input-address"
                          placeholder="Office address"
                          type="text"
                        />
                        {errors.address && <InlineError text={errors.address} />}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup
                        error={!errors.city}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="input-description"
                        >
                          City<Required />
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={city}
                          onChange={props.onChange}
                          maxLength="30"
                          name="city"
                          id="input-city"
                          placeholder="City"
                          type="text"
                        />
                        {errors.city && <InlineError text={errors.city} />}
                      </FormGroup>

                    </Col>
                    <Col lg="6">
                      <FormGroup
                        error={!errors.state}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="input-description"
                        >
                          State<Required />
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={state}
                          onChange={props.onChange}
                          maxLength="30"
                          name="state"
                          id="input-state"
                          placeholder="state"
                          type="text"
                        />
                        {errors.state && <InlineError text={errors.state} />}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup
                        error={!errors.state}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="input-description"
                        >
                          Country<Required />
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={country}
                          onChange={props.onChange}
                          name="country"
                          id="input-state"
                          maxLength="20"
                          placeholder="Country"
                          type="text"
                        />
                        {errors.country && <InlineError text={errors.country} />}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup
                        error={!errors.zipcode}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="input-description"
                        >
                          Zipcode<Required />
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={zip}
                          onChange={props.onChange}
                          name="zip"
                          id="input-code"
                          maxLength="15"
                          placeholder="zipcode"
                          type="text"
                        />
                        {errors.zip && <InlineError text={errors.zip} />}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup
                        error={!errors.gstNumber}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="input-gstNumber"
                        >
                          GST number<Required />
                        </label>
                        <Input
                          className="form-control-alternative"
                          value={gstNumber ? gstNumber : ''}
                          onChange={props.onChange}
                          name="gstNumber"
                          id="input-gstNumber"
                          placeholder="GST Number"
                          maxLength="30"
                          type="text" supplierList
                        />
                        {errors.gstNumber && <InlineError text={errors.gstNumber} />}
                      </FormGroup>
                    </Col>
                    
                  </Row>
                </div>
                <div className="">
                  <Row>
                    <Col lg="6">
                      <FormGroup className="select_box"
                        error={!errors.category}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="input-category"
                        >
                          Select Catgeory<Required />
                        </label>
                        <Input type="select" name="categoryId" id="exampleSelect" defaultValue={categoryId} onChange={props.supplierChange}>
                          <option>Select Catgeory</option>
                          {
                            category.categories && category.categories.map(values => {
                              return <>
                                {
                                  <option value={values.id} selected={categoryId == values.id}>{values.name}</option>
                                }
                              </>
                            })
                          }
                        </Input>
                        {errors.categoryId && <InlineError text={errors.categoryId} />}
                      </FormGroup>
                    </Col>
                    {

                      material && material.length >= 0 ?
                        <Col lg="6">
                          <FormGroup className="select_box"
                            error={!errors.category}
                          >
                            <label
                              className="form-control-label"
                              htmlFor="input-material"
                            >
                              Select Materials
                            </label>
                            <FormControl style={{ width: '100%' }} className="materialList">
                              <Select
                                labelId="demo-mutiple-checkbox-label"
                                id="demo-mutiple-checkbox"
                                multiple
                                value={materialjson}
                                onChange={props.handleChange}
                                input={<MaterialInput />}
                                renderValue={selected => materialSuccess.join(', ')}
                              >
                                {material.map((item, key) => (
                                  <MenuItem key={key} value={JSON.stringify({ id: item.id, name: item.name })}>
                                    <Checkbox checked={materialjson.indexOf(JSON.stringify({ id: item.id, name: item.name })) > -1} />
                                    <ListItemText primary={item.name} />
                                  </MenuItem>

                                ))}
                              </Select>
                            </FormControl>
                            {/* <Multiselect
                              options={material}
                              onSelect={props.onSelect}
                              onRemove={props.onRemove}
                              selectedValues={selectedValues}
                              name="materialId"
                              displayValue="name"
                            /> */}
                          </FormGroup>
                        </Col>
                        : ''
                    }
                  </Row>
                </div>
                <Row>
                  <Col lg="12" className="custom-submit">
                    <Button type="submit" className="my-4 submit-button" disabled={loader ? true : false} color="primary">
                      {
                        loader
                          ?
                          <Spinner color="light" />
                          :
                          'Submit'
                      }

                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>)
}
export default Supplierform;