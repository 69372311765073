import React from 'react';
import {
    Container,
    Spinner,
    Row,
    Badge
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { initialState } from '../../services/actions/order';
import { poDetail } from '../../services/actions/po'
import { ToastContainer} from 'react-toastify';
import Table from './table';
class PoListDetail extends React.Component {
    state = {
        data: {
            poId: this.props.match.params.id,
            subCategoryId: this.props.match.params.subId,
        },
        dataTable: {
            columns: [
                {
                    label: '#',
                    field: '#',
                    sort: 'asc'
                },
                {
                    label: 'Supplier',
                    field: 'Supplier',
                    sort: 'asc'
                },
                {
                    label: 'Style Code',
                    field: 'Style Code',
                    sort: 'asc'
                },
                {
                    label: 'Material',
                    field: 'Material',
                    sort: 'asc'
                },
                { label: 'Status', field: 'Status', width: 150, sort: 'asc' },
                //{ label: 'PO PDF', field: 'PO PDF', sort: 'asc' },
            ],
            rows: []
        },
        loader: false
    }
    componentDidMount() {
        this.props.poDetail(this.state.data)
        this.setState({
            loader: true
        })
    }
    componentWillReceiveProps(props) {
        const { poDetailSuccess } = props
        if (poDetailSuccess) {
            if (poDetailSuccess.success === true) {
                let dataMap = poDetailSuccess.result
                var testData = dataMap.map((items, key) => {
                    let valueName; let supplier;let material;
                    if(items.ribDesc){
                        valueName = items.ribDesc.brandorder&&items.ribDesc.brandorder.name
                    }
                    if(items.febricDesc){
                        valueName = items.febricDesc&&items.febricDesc.stylecode
                    }
                    if(items.ribColor){
                        valueName = items.ribColor&&items.ribColor.ribDesc&&items.ribColor.ribDesc.stylecode
                    }
                    if(items.zipperDesc){
                        valueName = items.zipperDesc&& items.zipperDesc.stylecode
                    }
                    if(items.quiltingDesc){
                        valueName = items.quiltingDesc&&items.quiltingDesc.stylecode
                    }
                    if(items.unitsDesc){
                        valueName = items.unitsDesc&&items.unitsDesc.stylecode
                    }
                    if(items.ratioDesc){
                        valueName = items.ratioDesc&&items.ratioDesc.stylecode
                    }
                    if(items.colorSupllier){
                        supplier = items.colorSupllier&&items.colorSupllier.companyName
                    }
                    if(items.febricSupllier){
                        supplier = items.febricSupllier&&items.febricSupllier.companyName
                    }
                    if(items.ribSupllier){
                        supplier = items.ribSupllier&&items.ribSupllier.companyName
                    }
                    if(items.zipperSupllier){
                        supplier = items.zipperSupllier&&items.zipperSupllier.companyName
                    }
                    if(items.quiltingSupllier){
                        supplier = items.quiltingSupllier&&items.quiltingSupllier.companyName
                    }
                    if(items.unitsSupllier){
                        supplier = items.unitsSupllier&&items.unitsSupllier.companyName
                    }
                    if(items.ratioSupllier){
                        supplier = items.ratioSupllier&&items.ratioSupllier.companyName
                    }

                    if(items.colorMaterial){
                        material = items.colorMaterial&&items.colorMaterial.name
                    }
                    if(items.febricMaterial){
                        material = items.febricMaterial&&items.febricMaterial.name
                    }
                    if(items.ribMaterial){
                        material = items.ribMaterial&&items.ribMaterial.name
                    }
                    if(items.zipperMaterial){
                        material = items.zipperMaterial&&items.zipperMaterial.name
                    }
                    if(items.quiltingMaterial){
                        material = items.quiltingMaterial&&items.quiltingMaterial.name
                    }
                    if(items.unitsMaterial){
                        material = items.unitsMaterial&&items.unitsMaterial.name
                    }
                    if(items.ratioMaterial){
                        material = items.ratioMaterial&&items.ratioMaterial.name
                    }
                    return (
                        {
                            '#': key + 1,
                            'Style Code':valueName,
                            'Supplier': supplier ? supplier : ' ',
                            'Material': material ? material : ' ',//poDetailSuccess.material&&poDetailSuccess.material.name ? poDetailSuccess.material.name : ' ',
                            'Status':[<><Badge color="success">{items.recdStatus > 0 ? items.recdStatus : 0} Received</Badge><br></br></>,
                            <Badge color="warning">{items.pendingStatus > 0 ? items.pendingStatus : 0} Pending</Badge>],
                            //'PO PDF': [<a href={items.poLinks ? imagerUrl+items.poLinks: ''} target="_blank">Show Po</a>],
                        }
                    )
                })
                this.setState({
                    dataTable: {
                        ...this.state.dataTable,
                        rows: testData
                    },
                    loader: false
                })
            }
        }
    }
    render() {
        const { loader } = this.state
        return <>
            <ToastContainer autoClose={10000} />
            {
                !loader ?
                    <Container className="mt-0" fluid>
                        <Row>
                            <Table {...this.state} />
                        </Row>
                    </Container>
                    :
                    <div className="loader" style={{ top: '-70px' }}>
                        <Spinner variant="light" />
                        <p style={{ color: '#fff', paddingTop: '15px' }}>Please wait......</p>
                    </div>
            }
        </>
    }
}
const mapDispatchState = (dispatch) => {
    return bindActionCreators({
        initialState,
        poDetail
    }, dispatch)
}
const mapGetStateNew = (state) => {
    return {
        poDetailSuccess: state.poPDF.poDetail,
    }
}
export default connect(mapGetStateNew, mapDispatchState)(PoListDetail);