import React from "react";
import LoginForm from './loginForm';
import { loginValid } from '../../../sharedFile/validator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login } from '../../../services/actions/auth';
import { ToastContainer, toast } from 'react-toastify';
var toastId = null
class Login extends React.Component {
  state = {
    data: {
      email: '',
      password: ''
    },
    errors: {},
    loader: false
  }
  onSubmit = (e) => {
    e.preventDefault();
    const errors = loginValid(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({
        loader: true
      })
      this.props.login(this.state.data);
    }
  }
  onChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value.trim()
      }
    })
  }
  componentWillReceiveProps(newProps) {
    const { loginFailed } = newProps
    if (!loginFailed.success) {
      this.setState({
        loader: false
      })
      if (!toast.isActive(toastId))
        toast.error(loginFailed.message);
    } else {
      this.setState({
        loader: false
      })
    }
  }
  render() {
    const { errors, loader } = this.state
    return (
      <>
        <ToastContainer autoClose={10000} />
        <LoginForm onSubmit={this.onSubmit} onChange={this.onChange} errors={errors} loader={loader} />
      </>
    );
  }
}
const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    login
  }, dispatch)
}
const mapGetStateNew = (state) => {
  return {
    loginFailed: state.user.login
  }
}
export default connect(mapGetStateNew, mapDispatchState)(Login);
