import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  FormText,
  Form,
  Input,
  Container,
  Row,
  NavLink,
  Col,
  Nav,
  NavItem,
  Spinner
} from "reactstrap";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import InlineError from '../../sharedFile/inlineError'
import Required from '../../sharedFile/required';
function AddForm(props) {
  const { errors, loader, data, title, category, location, slugCate, valueCate, loadCategory } = props
  return (
    <Container className="mt-0" fluid>
      <Row>
        <Col className="order-xl- " xl="12">
          <Row className="align-items-center mobile_sec_col">
            <div className="col">
              <h2 className="mb-0 text-center">{title}</h2>
              <span className="italic-message">* - indicate required fields!!</span>
            </div>
          </Row>
          <Form onSubmit={props.onSubmit}>
            <div className="pl-lg-0">
              <Row>
                <Col lg="12">
                  <FormGroup error={!errors.categoryId}>
                    <label
                      className="form-control-label"
                      htmlFor="input-name"
                    >
                      Parent Category<Required />
                    </label>
                    {
                      location === '/add-subcategory' ?
                        <div className="position-relative" >
                          {
                            loadCategory ?
                              <div className="position-absolute loader-select">
                                <Spinner color="danger" />
                              </div> :
                              ''
                          }
                          <Input type="select" value={valueCate} name="categoryId" id="exampleSelectMulti" onChange={props.onChange} disabled={loadCategory ? true : false}>
                            <option>Select category</option>
                            {category.categories && category.categories.map((items, key) => {
                              return (
                                <>
                                  {
                                    items.slug === 'zipper-family' || items.slug === 'rib-dhaaga-family' ?
                                      ''
                                      :
                                      <option value={JSON.stringify({ 'id': items.id, 'slug': items.slug, })}>{items.name}</option>
                                  }
                                </>

                              )
                            })
                            }
                          </Input>
                        </div>
                        :
                        <Input type="select" disabled value={data.categoryId} name="categoryId" id="exampleSelectMulti" onChange={props.onChange}>
                          <option>Select category</option>
                          {category.categories && category.categories.map((items, key) => {
                            return (
                              <option value={items.id}>{items.name}</option>
                            )
                          })
                          }
                        </Input>
                    }
                    {errors.categoryId && <InlineError text={errors.categoryId} />}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  {
                    slugCate === 'quilting-family' ?
                      <FormGroup error={!errors.slug}>
                        <label
                          className="form-control-label"
                          htmlFor="input-name"
                        >
                          Fabric Sub Category<Required />
                        </label>
                        {
                          location === '/add-subcategory'?
                          <Input type="select"  defaultValue={data.slug} name="slug" id="exampleSelectMulti" onChange={props.onChange}>
                          <option>Select Fabric Sub Category</option>
                          {category.cat && category.cat.map((items, key) => {
                            return (
                              <option value={items.slug}>{items.name}</option>
                            )
                          })
                          }
                        </Input>
                        :
                        <Input type="text" disabled value={data.slug} name="slug" id="exampleSelectMulti" onChange={props.onChange}></Input>
                        }
                        {errors.slug && <InlineError text={errors.slug} />}
                      </FormGroup>
                      :
                      ''
                  }
                </Col>
                <Col lg="12">
                  <FormGroup error={!errors.name}>
                    <label
                      className="form-control-label"
                      htmlFor="input-name"
                    >
                      Sub Category Name<Required />
                    </label>
                    <Input
                      className="form-control-alternative"
                      value={data.name}
                      onChange={props.onChange}
                      id="input-name"
                      maxLength='30'
                      placeholder="Name"
                      name="name"
                      type="text"
                    />
                    {errors.name && <InlineError text={errors.name} />}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup error={!errors.description}>
                    <label
                      className="form-control-label"
                      htmlFor="input-description"
                    >
                      Sub Category Description<Required />
                    </label>
                    <Input
                      className="form-control-alternative"
                      onChange={props.onChange}
                      value={data.description}
                      name="description"
                      id="input-description"
                      placeholder="Description"
                      type="textarea"
                    />
                    {errors.description && <InlineError text={errors.description} />}
                  </FormGroup>
                </Col>
              </Row>

            </div>
            <Row>
              <Col lg="12" className="mobi_padd">
                <FormGroup className="m-0 custom-submit">
                  <Button type="submit" className="my-4 submit-button" disabled={loader ? true : false} color="primary">
                    {
                      loader
                        ?
                        <Spinner color="light" />
                        :
                        'Submit'
                    }

                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}
export default AddForm;