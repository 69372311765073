import React from "react";
import EditForm from './editForm';
import { updateVarient, viewVarient } from '../../services/actions/varient'
import {
  Spinner
} from "reactstrap";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addVarientValid } from '../../sharedFile/validator';
import { initialState } from '../../services/actions/stateInitial'
import { toast } from 'react-toastify';
var toastId = null
class EditVarient extends React.Component {
  state = {
    data: {
      name: '',
      description: ''
    },
    errors: {},
    loader: false,
    loaderView: false,
    message: ""
  }
  componentDidMount() {
    this.props.viewVarient(this.props.editId);
    this.setState({
      loaderView: true
    })
  }
  onSubmit = (e) => {
    e.preventDefault();
    const errors = addVarientValid(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({
        loader: true
      })
      this.props.updateVarient(this.props.editId, this.state.data)
    }
  }
  onChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value.trimLeft().replace(/\s\s+/g, ' ')
      }
    })
  }
  componentWillReceiveProps(newProps) {
    const { detailvarient } = newProps
    if (detailvarient && detailvarient.success) {
      const varients = newProps.detailvarient.varient
      this.setState({
        data: {
          ...this.state.data,
          name: varients.name,
          description: varients.description,
        },
        loaderView: false,
        loader: false
      })
      if (detailvarient.message) {
        if (toast.isActive(toastId)) {
          toast.dismiss(toastId)
          toastId = toast.success(detailvarient.message);
          newProps.initialState();
        } else {
          toastId = toast.success(detailvarient.message);
          newProps.initialState();
        }
      }
    } if (detailvarient && detailvarient.success === false) {
      this.setState({
        loaderView: false,
        loader: false
      })
      newProps.initialState();
    }
  }
  render() {
    const { data, loaderView } = this.state
    return (
      <>
        {
          !loaderView ?
            <EditForm  {...this.state} onSubmit={this.onSubmit} data={data} onChange={this.onChange} />
            :
            <div className="text-center">
              <Spinner />
            </div>
        }
      </>
    );
  }
}
const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    viewVarient,
    updateVarient,
    initialState
  }, dispatch)
}
const mapGetStateNew = (state) => {
  return {
    detailvarient: state.varient.detailvarient,
    updateRedirect: state.varient
  }
}
export default connect(mapGetStateNew, mapDispatchState)(EditVarient);
