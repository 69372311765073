import React from 'react';
import OrdersFrom from "./orderForm";
import {
    Container,
    Spinner
} from 'reactstrap';
import { initialState, oldOrder } from '../../../services/actions/order';
import { ToastContainer, toast } from 'react-toastify';
import { brand, oldBrandListDelete } from '../../../services/actions/brand';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { oldOrderValid } from '../../../sharedFile/validator'
import ConfirmModal from '../../../components/ConfirmModal';
import { initialDelete } from '../../../services/actions/supplier';
import OldOrderList from './oldOrderList';
var toastId = null
class DateOrders extends React.Component {
    state = {
        data: {
            brandId: '',
            dateFrom: '',
            dateTo: ''
        },
        open: false,
        loader: false,
        loaderBrand:false,
        loaderBrandList:false,
        errors: {},
        lock: true
    }
    onChange = (e) => {
        this.setState({
            data: {
                ...this.state.data,
                [e.target.name]: e.target.value
            }
        })
    }
    componentWillMount() {
        let currentDate = new Date();
        let getYear = currentDate.getFullYear()
        let getMonth = currentDate.getMonth() + 1
        let fullCurrentDate = getYear + '-' + (getMonth <= 9 ? '0' + getMonth : getMonth) + '-' + (currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate())
        this.setState({
            data: {
                ...this.state.data,
                dateFrom: getYear + '-01-01',
                dateTo: fullCurrentDate
            },
        })
    }
    onSubmit = (e) => {
        e.preventDefault();
        const errors = oldOrderValid(this.state.data);
        this.setState({ errors });
        if (Object.keys(errors).length === 0) {
            this.props.oldOrder(this.state.data)
            this.setState({
                loaderBrandList:true
            })
        }
    }
    confirm = (event, id, name) => {
        this.setState({
            open: true,
            id: id,
            name: name
        })
    }
    toggle = () => {
        this.setState({
            open: false,
        })
    }
    confirmation = (event, id) => {
        this.setState({
            open: false,
            loader:true
        })
        this.props.oldBrandListDelete(id)
    }
    componentDidMount() {
        this.props.brand();
        this.setState({
            loaderBrand: true
        })
    }
    componentWillUnmount() {
        this.props.initialState()
    }
    componentWillReceiveProps(newProps) {
        const { order, brandSuccess, deleteOldOrder } = newProps
        if (order && order.success) {
                if (!toast.isActive(toastId))
                    toastId = toast.success(order.message)
                // window.scrollTo(0, 500);
                this.setState({
                    loader: false,
                    loaderBrandList:false
                })
        } else {
            this.setState({
                loader: false,
                loaderBrandList:false
            })
            if (order&&order.message) {
                if (!toast.isActive(toastId))
                    toastId = toast.error(order && order.message)
            }
        }
        if ((brandSuccess && brandSuccess.success) === true || (brandSuccess && brandSuccess.success) === false) {
            this.setState({
                loaderBrand: false,
                loader:false
            })
        }
        if(deleteOldOrder){
            if(deleteOldOrder.success){
                this.setState({
                    loader: false
                })
                if (!toast.isActive(toastId))
                    toastId = toast.success('Old Order List Deleted Successfully')
                    newProps.initialDelete()
            }
            if(deleteOldOrder.success ===false){
                this.setState({
                    loader: false
                })
                if (!toast.isActive(toastId))
                    toastId = toast.error(deleteOldOrder.message)
                    newProps.initialDelete()
            }
        }
    }
    lockDate = () => {
        this.setState({
            lock: !this.state.lock
        })
    }
    render() {
        const { location, brandSuccess, order, url, urlHeading } = this.props;
        const { open, loader } = this.state
        return (
            <>
                <ToastContainer autoClose={4000} />
                {
                    !loader ?
                        <Container className="mt-0" fluid>
                            <div className="card_border card">
                                <OrdersFrom onSubmit={this.onSubmit} onChange={this.onChange} brandSuccess={brandSuccess} {...this.state} location={location} url={url} lockDate={this.lockDate} />
                                <OldOrderList order={order} url={url} confirm={this.confirm} />
                                <ConfirmModal open={open} toggle={this.toggle} confirmation={this.confirmation} id={this.state.id} name={this.state.name} />
                            </div>
                        </Container>
                        :
                        <div className="loader" style={{ top: '-70px' }}>
                            <Spinner variant="light" />
                            <p style={{ color: '#fff', paddingTop: '15px' }}>Please wait......</p>
                        </div>
                }
            </>
        )
    }
}
const mapDispatchState = (dispatch) => {
    return bindActionCreators({
        initialState,
        brand,
        oldOrder,
        initialDelete,
        oldBrandListDelete
    }, dispatch)
}
const mapGetStateNew = (state) => {
    return {
        brandSuccess: state.brand.brand,
        order: state.order.orderOld,
        deleteOldOrder:state.order.deleteOldOrder
    }
}
export default connect(mapGetStateNew, mapDispatchState)(DateOrders);