import { call, put, takeLatest, all } from "redux-saga/effects";
import { 
  SET_BRAND_SUCCESS, 
  SET_BRAND_FAILED,
  GET_BRAND,   
  GET_BRAND_DELETE,
  ADD_BRAND_FAILED,
  ADD_BRAND_SUCCESS, 
  GET_BRAND_ADD,
  DELETE_BRAND_FAILED,
  DELETE_BRAND_SUCCESS, 
  VIEW_BRAND_FAILED,
  GET_BRAND_VIEW,
  VIEW_BRAND_SUCCESS, 
  GET_BRAND_UPDATE,
  UPDATE_BRAND_FAILED,
  UPDATE_BRAND_SUCCESS,
  GET_BRAND_LIST_DELETE,
  SET_BRAND_LIST_DELETE_FAILED,
  SET_BRAND_LIST_DELETE_SUCCESS,
  GET_REMOVE_IMAGE_BRAND,
  SET_REMOVE_IMAGE_BRAND_FAILED,
  SET_REMOVE_IMAGE_BRAND_SUCCESS
} from "../../sharedFile/actionType";
import Api from "../../lib/api";

function getBrandListApi(){
    return Api.get(
        `brand/`,
    );
}
function* getBrandList(){
    try {
        const resp = yield call(getBrandListApi);
        if (resp.success === true) {
          yield put({ type: SET_BRAND_SUCCESS, data: resp })
        } else {
          yield put({ type: SET_BRAND_FAILED, error: 'Something Went Wrong' })
        }
      } catch (resp) {
        yield put({ type: SET_BRAND_FAILED, error: 'Something Went Wrong' })
      }
}


function deleteBrandApi(action){
  const {data} = action
  return Api.delete(
      `brand/`+data
  );
}
function* deleteBrand(action){
  try {
      const resp = yield call(deleteBrandApi, action);
      if (resp.success === true) {
        yield put({ type: DELETE_BRAND_SUCCESS, data: resp })
      } else {
        yield put({ type: DELETE_BRAND_FAILED, error: resp })
      }
    } catch (resp) {
      yield put({ type: DELETE_BRAND_FAILED, error: resp })
    }
}


function addBrandApi(action){
  return Api.post(
      `brand/`,
      action.data
  );
}
function* addBrand(action){
  try {
      const resp = yield call(addBrandApi, action);
      if (resp.success === true) {
        yield put({ type: ADD_BRAND_SUCCESS, data: resp })
        yield put({ type: GET_BRAND })
      } else {
        yield put({ type: ADD_BRAND_FAILED, error: resp })
      }
    } catch (resp) {
      yield put({ type: ADD_BRAND_FAILED, error: resp })
    }
}


function viewBrandApi(action){
  const {data} = action
  return Api.get(
      `brand/`+data
  );
}
function* viewBrand(action){
  try {
      const resp = yield call(viewBrandApi, action);
      if (resp.success === true) {
        yield put({ type: VIEW_BRAND_SUCCESS, data: resp, redirect: false })
      } else {
        yield put({ type: VIEW_BRAND_FAILED, error: resp.message })
      }
    } catch (resp) {
      yield put({ type: VIEW_BRAND_FAILED, error: 'Something Went Wrong' })
    }
}

function updateBrandApi(action){
  let data = {
    name: action.data.name,
    description: action.data.description
  }
  if(action.data.picture.length > 0) {
    data.picture = action.data.picture
  }
  return Api.put(
      `brand/`+action.id,
      data
  );
}
function* updateBrand(action){
  try {
      const resp = yield call(updateBrandApi, action);
      if (resp.success === true) {
        yield put({ type: UPDATE_BRAND_SUCCESS, data: resp, redirect: true })
        yield put({ type: GET_BRAND })
      } else {
        yield put({ type: UPDATE_BRAND_FAILED, error: resp })
      }
    } catch (resp) {
      yield put({ type: UPDATE_BRAND_FAILED, error: resp })
    }
}
function oldBrandListDeleteApi(action){
  const {id} = action
  return Api.delete(
      `order/mainOrder/`+id
  );
}
function* oldBrandListDelete(action){
  try {
      const resp = yield call(oldBrandListDeleteApi, action);
      if (resp.success === true) {
        yield put({ type: SET_BRAND_LIST_DELETE_SUCCESS, data: resp })
      } else {
        yield put({ type: SET_BRAND_LIST_DELETE_FAILED, error: resp })
      }
    } catch (resp) {
      yield put({ type: SET_BRAND_LIST_DELETE_FAILED, error:resp })
    }
}
function brandImageRemoveApi(action){
  const {id} = action
  return Api.delete(
      `brand/removeLogo/`+id
  );
}
function* brandImageRemove(action){
  try {
      const resp = yield call(brandImageRemoveApi, action);
      if (resp.success === true) {
        yield put({ type: SET_REMOVE_IMAGE_BRAND_SUCCESS, data: resp })
        yield put({ type: GET_BRAND })
      } else {
        yield put({ type: SET_REMOVE_IMAGE_BRAND_FAILED, error: resp })
      }
    } catch (resp) {
      yield put({ type: SET_REMOVE_IMAGE_BRAND_FAILED, error:resp })
    }
}

function* brand() {
    yield all([
      takeLatest(GET_BRAND, getBrandList),
      takeLatest(GET_BRAND_DELETE, deleteBrand),
      takeLatest(GET_BRAND_ADD,addBrand),
      takeLatest(GET_BRAND_VIEW, viewBrand),
      takeLatest(GET_BRAND_UPDATE, updateBrand),
      takeLatest(GET_BRAND_LIST_DELETE, oldBrandListDelete),
      takeLatest(GET_REMOVE_IMAGE_BRAND, brandImageRemove)
    ])
}
export default brand;