import React from "react";
import { toast } from 'react-toastify';
import Header from "../../components/Headers/Header";
import AddForm from './form';
import { subCategoryAdd } from '../../services/actions/subCategory';
import { category } from '../../services/actions/category';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addSubValid } from '../../sharedFile/validator';
import { initialStateSub } from '../../services/actions/stateInitial'
var toastId = null
class AddSubCate extends React.Component {
    state = {
        data: {
            name: '',
            description: '',
            categoryId: '',
            slug: ''
        },
        errors: {},
        loader: false,
        loadCategory: false,
        message: "",
        valueCate: '',
        slugCate: '',
        pageNumber: 1
    }
    onSubmit = (e) => {
        e.preventDefault();
        const { data, slugCate } = this.state
        const errors = addSubValid(data, slugCate);
        this.setState({ errors });
        if (Object.keys(errors).length === 0) {
            this.setState({
                loader: true
            })
            this.props.subCategoryAdd(data)
        }
    }
    onChange = (e) => {
        if (e.target.name == 'categoryId') {
            let value = JSON.parse(e.target.value)
            this.setState({
                data: {
                    ...this.state.data,
                    [e.target.name]: value.id
                },
                slugCate: value.slug,
                valueCate: e.target.value
            })
        } else {
            this.setState({
                data: {
                    ...this.state.data,
                    [e.target.name]: e.target.value.trimLeft().replace( /\s\s+/g, ' ' )
                }
            })
        }
    }
    componentDidMount() {
        this.props.category();
        this.setState({
            loadCategory: true
        })
    }
    componentWillReceiveProps(newProps) {
        const { subCate, categorySuccess } = newProps
        if(categorySuccess && categorySuccess.success === true || categorySuccess && categorySuccess.success === false){
            this.setState({
                loadCategory:false
            })
        }
        if (subCate && subCate.success === true) {
            this.setState({
                data: {
                    ...this.state.data,
                    name: '',
                    description: '',
                    categoryId: '',
                    slug:''
                },
                valueCate: JSON.stringify({ 'id': '', 'slug': '', }),
                loader: false
            })
            if (toast.isActive(toastId)) {
                toast.dismiss(toastId)
                toastId = toast.success(subCate.message);
                newProps.initialStateSub()
            } else {
                toastId = toast.success(subCate.message);
                newProps.initialStateSub()
            }

        } if (subCate && subCate.success === false) {
            this.setState({
                loader: false
            })
            if (toast.isActive(toastId)) {
                toast.dismiss(toastId)
                toastId = toast.error(subCate.message);
                newProps.initialStateSub()
            } else {
                toastId = toast.error(subCate.message);
                newProps.initialStateSub()
            }
        }
    }
    render() {
        const { categorySuccess, location } = this.props
        return (
            <>
                <AddForm  {...this.state} category={categorySuccess} onSubmit={this.onSubmit} onChange={this.onChange} title="Add Sub Category" location={location} />
            </>
        );
    }
}
const mapDispatchState = (dispatch) => {
    return bindActionCreators({
        category,
        subCategoryAdd,
        initialStateSub
    }, dispatch)
}
const mapGetStateNew = (state) => {
    return {
        categorySuccess: state.category.category,
        subCate: state.subCategory.subCategoryAdd
    }
}
export default connect(mapGetStateNew, mapDispatchState)(AddSubCate);
