import React from "react";
import Header from "../../components/Headers/Header";
import AddForm from './SupplierForm';
import {addSupplier} from '../../services/actions/supplier'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {addSupplierValid} from '../../sharedFile/validator';
import { ToastContainer, toast } from 'react-toastify';
import { categoryMaterial,initialStateMaterial } from '../../services/actions/material';
import {initialState} from '../../services/actions/order'
import { category } from '../../services/actions/category';
var toastId = null
class AddSupplier extends React.Component {
  state = {
    data:{
      companyName:'',
      firstName:'',
      lastName:'',
      email:'',
      gstNumber:'',
      phone:'',
      mobile:'',
      country:'',
      address:'',
      city:'',
      state:'',
      zip:'',
      materialId: [],
      categoryId: '',
      materialjson:[]
    },
    errors:{},
    materialSuccess: [],
    loader:false,
    category: [],
    message: ""
  }
  componentDidMount()  {
    this.props.category();
  }
  onSubmit=(e)=>{
    e.preventDefault();
    const errors = addSupplierValid(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({
        loader: true
      })  
      this.props.addSupplier(this.state.data)
    }
  }
  onChange=(e)=>{
    this.setState({
      data:{
        ...this.state.data,
        [e.target.name]:e.target.value.trimLeft().replace( /\s\s+/g, ' ')
      },
    })
  }
  supplierChange = (e) => {
    this.props.categoryMaterial(e.target.value)
    this.setState({
      ...this.state,
      materialSuccess: [],
      data: {
        ...this.state.data,
        categoryId: e.target.value,
        materialId: [],
        materialjson:[]
      },
    })
    this.props.initialStateMaterial();
  }
  onSelect = (optionsList, selectedItem) => {
    let select = []
    for(let val  of optionsList) {
      select.push(val.id)
    }
    this.setState({
      data: {
        ...this.state.data,
        materialId: select
      }
    })
  }
  onRemove = (optionList, removedItem) => {
    let select = []
    for(let val  of optionList) {
      select.push(val.id)
    }
    this.setState({
      data: {
        ...this.state.data,
        materialId: select
      }
    })
  }
  onKeyPress = (evt) =>{
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /^[0-9\b]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }
  componentWillReceiveProps(newProps){
   const {supplier} = newProps
    if(supplier&&supplier.success === true && supplier.message){
      this.setState({
        data:{
          ...this.state.data,
          companyName: '',
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          gstNumber: '',
          mobile: '',
          address: '',
          city:  '',
          state: '',
          zip: '',
          country:'',
          categoryId:'',
          materialjson:[]
        },
        loader: false
      })
      let message = supplier.message
      if(toast.isActive(toastId)){
        toast.dismiss(toastId)
        toastId = toast.success(message);
        newProps.initialState()
      }else{
        toastId = toast.success(message);
        newProps.initialState()
      }
    }
    if(supplier&&supplier.success === false){
      let message = supplier.message
      if(toast.isActive(toastId)){
        toast.dismiss(toastId)
        toastId = toast.error(message);
        newProps.initialState()
      }else{
        toastId = toast.error(message);
        newProps.initialState()
      }
      this.setState({
        loader:false
      })
    }
  }
  componentWillUnmount(){
    this.props.initialState()
  }
  handleChange= (e)=>{
    let matnam = []
    let matId = []
    for(let val of e.target.value) {
      let value = JSON.parse(val)
      matnam.push(value.name)
      matId.push(value.id)
    }
    this.setState({
      data:{
        ...this.state.data,
        materialId:matId,
        materialjson: e.target.value
      },
      materialSuccess:matnam
    })
  }
  render() {
    const {materialSuccess,categorySuccess} = this.props
    return (
      <>
        <Header/>
        <ToastContainer autoClose={4000}/>
        <AddForm  {...this.state} onSubmit={this.onSubmit} supplierChange={this.supplierChange}  onSelect={this.onSelect} onRemove={this.onRemove} category = {categorySuccess} material={materialSuccess&&materialSuccess.materials} onChange={this.onChange} handleChange={this.handleChange} onKeyPress={this.onKeyPress} title="Add"/>
      </>
    );
  }
}
const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    category,
    categoryMaterial,
    addSupplier,
    initialState,
    initialStateMaterial
  }, dispatch)
}
const mapGetStateNew = (state) => {
  return {
    categorySuccess: state.category.category,
    materialSuccess: state.material.materialCategory,
    supplier: state.supplier.addSupplier,
  }
}
export default connect(mapGetStateNew, mapDispatchState)(AddSupplier);
