import { GET_RATIO, GET_UNIT,GET_RATIO_ADD,GET_UNIT_ADD, GET_RATIO_ADD_PICTURE, GET_UNIT_ADD_PICTURE } from "../../sharedFile/actionType";
export const ratioGet = (data) =>({
    type: GET_RATIO,
    data
})
export const getUnit = (data) =>({
    type: GET_UNIT,
    data
})
export const ratioAdd = (data, dataPic) =>({
    type: GET_RATIO_ADD,
    data,
    dataPic
})
export const addUnit = (data, dataPic) =>({
    type: GET_UNIT_ADD,
    data,
    dataPic
})
export const addRationPicture = (id,dataPic) =>({
    type: GET_RATIO_ADD_PICTURE,
    dataPic,
    id,
})
export const addUnitPicture = (id,dataPic) =>({
    type: GET_UNIT_ADD_PICTURE,
    dataPic,
    id,
})