import React from 'react';
import {  Button,  CardBody,  FormGroup,  FormText,  Form,  Input,  Container,  Row,  Col,  Spinner} from "reactstrap";
import InlineError from '../../sharedFile/inlineError'
import Required from '../../sharedFile/required';
function AddForm(props) {
  const { errors, loader, data, imagesPreviewUrls, loadCategory,errImage } = props
  let role = sessionStorage.userROLE.replace('_worker', '')
  return (
    <Container className="mt-0" fluid>
      <Row>
        <Col className="order-xl-1" xl="12">
          <Row className="align-items-center mobile_sec_col">
            <div className="col">
              <h2 className="mb-0 text-center">Add Material</h2>

              <span className="italic-message">* - indicate required fields!!</span>
            </div>
          </Row>
          <Form onSubmit={props.onSubmit}>
            <div className="pl-lg-0">
              <Row>
                <Col lg="12">
                  <FormGroup error={!errors.name}>
                    <label
                      className="form-control-label"
                      htmlFor="input-name"
                    >
                      Material Name<Required />
                    </label>
                    <Input
                      className="form-control-alternative"
                      value={data.name}
                      onChange={props.onChange}
                      id="input-name"
                      placeholder="Name"
                      maxLength="100"
                      name="name"
                      type="text"
                    />
                    {errors.name && <InlineError text={errors.name} />}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup error={!errors.name}>
                    <label
                      className="form-control-label"
                      htmlFor="input-name"
                    >
                      Sub Category<Required />
                    </label>
                    <div className="position-relative" >
                      {
                        loadCategory ?
                          <div className="position-absolute loader-select">
                            <Spinner color="danger" />
                          </div> :
                          ''
                      }
                      <Input disabled={loadCategory ? true : false} type="select" name="subCategoryId" value={data.subCategoryId} id="exampleSelectMulti" onChange={props.onChange}>
                        <option>Select sub category</option>
                        {props.category.subcategories && props.category.subcategories.map((items, key) => {
                          return (
                            <>
                              {
                                sessionStorage.userROLE === 'super-admin' ?
                                  <>
                                    {
                                      items.slug === 'zipper' || items.slug === 'dhaaga_color' ?
                                        ''
                                        :
                                        <option value={items.id} key={key}>{items.name}</option>
                                    }
                                  </>
                                  : role === items.slug ?
                                    <option value={items.id} key={key}>{items.name}</option>
                                    :
                                    ''
                              }
                            </>

                          )
                        })
                        }
                      </Input>
                      {errors.subCategoryId && <InlineError text={errors.subCategoryId} />}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                {
                  imagesPreviewUrls.length ?
                    <Col lg="12">
                      <CardBody className="p-0">
                        <FormGroup className="m-0">
                          <Row className="pl-0 pl-sm-3">
                            {
                              imagesPreviewUrls.map((items, id) => {
                                return <Col error={!errImage.picture} xs={3} className="mb-3 px-1"><div className="upload_img position-relative" key={id}>
                                  <img src={items} width="100%" height="90px" style={{ objectFit: 'cover', border: '1px solid #e1e1e1', borderRadius: '6px' }} />
                                  <p className="position-absolute icon-file" style={{ padding: '0px 9px' }} onClick={e => props.removeImage(e, id)}><i class="fas fa-times"></i></p>
                                </div>
                                {errImage&&errImage.picture&&errImage.picture.map((err, keyList) => { if (id === err.key) return err.err && <InlineError text={err.err} /> })}
                                </Col>
                              })
                            }
                          </Row>
                        </FormGroup>
                      </CardBody>
                    </Col>
                    :
                    ''
                }
                <Col lg="12" className="upload_sheet">
                  <label
                    className="form-control-label"
                    htmlFor="input-name"
                  >
                    Add Material Images
                            </label>
                  <FormGroup error={!errors.picture} style={{ width: '100%', position: 'relative' }}>

                    <label
                      className="form-control-label  custom_label_input cursor-pointer"
                      htmlFor="input-image"
                      for="exampleFile"
                      style={{maxWidth: '127px'}}
                    >
                      <i class="fa fa-image" aria-hidden="true"></i> Add Images
                        </label>
                    <Input type="file"
                      className="form-control-alternative"
                      multiple placeholder="Name" onChange={props.onFileChange} onClick={props.blankPicture} name="picture" maxLength="12" id="exampleFile" accept="image/gif,image/jpeg,image/png" />
                    {errors.picture && <InlineError text={errors.picture} />}
                    <FormText color="muted">*Press CTRL and select multiple images</FormText>
                  </FormGroup>

                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup error={!errors.description}>
                    <label
                      className="form-control-label"
                      htmlFor="input-description"
                    >
                      Material Description<Required />
                    </label>
                    <Input
                      className="form-control-alternative"
                      value={data.description}
                      onChange={props.onChange}
                      name="description"
                      id="input-description"
                      placeholder="Description"
                      type="textarea"
                    />
                    {errors.description && <InlineError text={errors.description} />}
                  </FormGroup>
                </Col>
              </Row>

            </div>
            <Row>
              <Col lg="12" className="custom-submit">
                <Button type="submit" className="my-4 submit-button" disabled={loader ? true : false} color="primary">
                  {
                    loader
                      ?
                      <Spinner color="light" />
                      :
                      'Submit'
                  }

                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}
export default AddForm;