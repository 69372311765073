import { call, put, takeLatest, all } from "redux-saga/effects";
import { 
  SET_STYLE_SUCCESS, 
  SET_STYLE_FAILED,
  GET_STYLE,   
  GET_STYLE_DELETE,
  ADD_STYLE_FAILED,
  ADD_STYLE_SUCCESS, 
  GET_STYLE_ADD,
  DELETE_STYLE_FAILED,
  DELETE_STYLE_SUCCESS, 
  VIEW_STYLE_FAILED,
  GET_STYLE_VIEW,
  VIEW_STYLE_SUCCESS, 
  GET_STYLE_UPDATE,
  UPDATE_STYLE_FAILED,
  UPDATE_STYLE_SUCCESS  
} from "../../sharedFile/actionType";
import Api from "../../lib/api";

function getStyleListApi(){
    return Api.get(
        `style/`,
    );
}
function* getStyleList(){
    try {
        const resp = yield call(getStyleListApi);
        if (resp.success === true) {
          yield put({ type: SET_STYLE_SUCCESS, data: resp })
        } else {
          yield put({ type: SET_STYLE_FAILED, error: 'Something Went Wrong' })
        }
      } catch (resp) {
        yield put({ type: SET_STYLE_FAILED, error: 'Something Went Wrong' })
      }
}


function deleteStyleApi(action){
  const {data} = action
  return Api.delete(
      `style/`+data
  );
}
function* deleteStyle(action){
  try {
      const resp = yield call(deleteStyleApi, action);
      if (resp.success === true) {
        yield put({ type: DELETE_STYLE_SUCCESS, data: resp })
      } else {
        yield put({ type: DELETE_STYLE_FAILED, error: resp.message })
      }
    } catch (resp) {
      yield put({ type: DELETE_STYLE_FAILED, error: 'Something Went Wrong' })
    }
}


function addStyleApi(action){
  return Api.post(
      `style/`,
      action.data
  );
}
function* addStyle(action){
  try {
      const resp = yield call(addStyleApi, action);
      if (resp.success === true) {
        yield put({ type: ADD_STYLE_SUCCESS, data: resp })
      } else {
        yield put({ type: ADD_STYLE_FAILED, error: resp.message })
      }
    } catch (resp) {
      yield put({ type: ADD_STYLE_FAILED, error: resp.message })
    }
}


function viewStyleApi(action){
  const {data} = action
  return Api.get(
      `style/`+data
  );
}
function* viewStyle(action){
  try {
      const resp = yield call(viewStyleApi, action);
      if (resp.success === true) {
        yield put({ type: VIEW_STYLE_SUCCESS, data: resp })
      } else {
        yield put({ type: VIEW_STYLE_FAILED, error: resp.message })
      }
    } catch (resp) {
      yield put({ type: VIEW_STYLE_FAILED, error: 'Something Went Wrong' })
    }
}

function updateStyleApi(action){
  return Api.put(
      `style/`+action.id,
      action.data
  );
}
function* updateStyle(action){
  try {
      const resp = yield call(updateStyleApi, action);
      if (resp.success === true) {
        yield put({ type: UPDATE_STYLE_SUCCESS, data: resp })
      } else {
        yield put({ type: UPDATE_STYLE_FAILED, error: resp.message })
      }
    } catch (resp) {
      yield put({ type: UPDATE_STYLE_FAILED, error: 'Something Went Wrong' })
    }
}




function* style() {
    yield all([
      takeLatest(GET_STYLE, getStyleList),
      takeLatest(GET_STYLE_DELETE, deleteStyle),
      takeLatest(GET_STYLE_ADD,addStyle),
      takeLatest(GET_STYLE_VIEW, viewStyle),
      takeLatest(GET_STYLE_UPDATE, updateStyle)
    ])
}
export default style;