import React from 'react'
import {
    Container,
    Spinner
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PoOrderList from './poOrderList'
import PoForm from './poForm';
import { poOrderList } from '../../services/actions/po'
import { poOrderValid } from '../../sharedFile/validator'
import { subCategory } from '../../services/actions/subCategory';
import { brand } from '../../services/actions/brand';
import { initialState } from '../../services/actions/order';
var toastId = null
class PoList extends React.Component {
    state = {
        data: {
            brandId: '',
            dateFrom: '',
            dateTo: '',
            subCategoryId: ''
        },
        open: false,
        loader: false,
        errors: {},
        lock: true,
        loaderBrand: false,
        loaderCate: false
    }
    onChange = (e) => {
        this.setState({
            data: {
                ...this.state.data,
                [e.target.name]: e.target.value
            }
        })
    }
    componentWillMount() {
        let currentDate = new Date();
        let getYear = currentDate.getFullYear()
        let getMonth = currentDate.getMonth() + 1
        let fullCurrentDate = getYear + '-' + (getMonth <= 9 ? '0' + getMonth : getMonth) + '-' + (currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate())
        this.setState({
            data: {
                ...this.state.data,
                dateFrom: getYear + '-01-01',
                dateTo: fullCurrentDate
            },
        })
    }
    componentDidMount() {
        this.props.brand();
        this.props.subCategory();
        this.setState({
            loaderBrand: true,
            loaderCate: true
        })
    }
    onSubmit = (e) => {
        e.preventDefault();
        const errors = poOrderValid(this.state.data);
        this.setState({ errors });
        if (Object.keys(errors).length === 0) {
            this.props.poOrderList(this.state.data)
            this.setState({
                loader: true
            })
        }
    }
    componentWillUnmount() {
        this.props.initialState()
    }
    componentWillReceiveProps(newProps) {
        const { order, brandSuccess, poGenrate, categorySuccess } = newProps
        if (poGenrate && poGenrate.success) {
            if (poGenrate.result.length === 0) {
                if (!toast.isActive(toastId))
                    toastId = toast.success('No record found')
            } else {
                window.scrollTo(0, 500);
            }
            this.setState({
                ...this.state,
                loader: false
            })
        }
        if (poGenrate && poGenrate.success === false) {
            this.setState({
                loader: false
            })
            if (poGenrate && poGenrate.result) {
                if (!toast.isActive(toastId))
                    toastId = toast.error(poGenrate.message)

            }
        }
        if ((brandSuccess && brandSuccess.success) && (categorySuccess && categorySuccess.success)) {
            this.setState({
                loader: false,
                loaderBrand: false,
                loaderCate: false
            })
        }
        if ((brandSuccess && brandSuccess.success) === false && (categorySuccess && categorySuccess.success) === false) {
            this.setState({
                loader: false,
                loaderBrand: false,
                loaderCate: false
            })
        }
    }
    lockDate = () => {
        this.setState({
            lock: !this.state.lock
        })
    }
    render() {
        const { location, brandSuccess, poGenrate, categorySuccess } = this.props;
        const { open, loader, data } = this.state
        let url = location.pathname
        return (
            <>
                <ToastContainer autoClose={10000} />
                {
                    !loader ?
                        <Container className="mt-0" fluid>
                            <PoForm onSubmit={this.onSubmit} onChange={this.onChange} brandSuccess={brandSuccess} categorySuccess={categorySuccess} {...this.state} location={location} lockDate={this.lockDate} />
                            <PoOrderList data={data} poGenrate={poGenrate} url={url} confirm={this.confirm} />
                        </Container>
                        :
                        <div className="loader" style={{ top: '0px' }}>
                            <Spinner variant="light" />
                            <p style={{ color: '#fff', paddingTop: '15px' }}>Please wait......</p>
                        </div>
                }
            </>
        )
    }
}
const mapDispatchState = (dispatch) => {
    return bindActionCreators({
        initialState,
        brand,
        subCategory,
        poOrderList
    }, dispatch)
}
const mapGetStateNew = (state) => {
    return {
        categorySuccess: state.subCategory.subCategory,
        brandSuccess: state.brand.brand,
        poGenrate: state.poPDF.poGenrate
    }
}
export default connect(mapGetStateNew, mapDispatchState)(PoList);