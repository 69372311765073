import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, Form, Input, FormGroup } from 'reactstrap';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdbreact';
import { noteSubmit, noteinitialState } from '../services/actions/order';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addNote } from '../sharedFile/validator';
import InlineError from '../sharedFile/inlineError'
import { toast } from 'react-toastify';
import {
    Card,
    Spinner,
    CardBody,
    Row,
    Col
} from "reactstrap";
import { koraPo } from '../services/actions/po'
var toastId = null
class PreviewModal extends React.Component {
    state = {
        data: {
            notes: '',
            slug: '',
            id: null
        },
        loaderNote: false,
        errors: {},
        message: ''
    }
    noteChange = (e) => {
        this.setState({
            data: {
                ...this.state.data,
                notes: e.target.value,
            }
        })
    }
    noteSubmit = (e) => {
        e.preventDefault();
        const errors = addNote(this.state.data);
        this.setState({ errors });
        if (Object.keys(errors).length === 0) {
            this.setState({
                loaderNote: true
            })
            this.props.noteSubmit(this.state.data)
        }
    }
    componentWillReceiveProps(newProps) {
        const { liningsupplier, previewDataId, noteSuccess, noteGetRow } = newProps
        if (liningsupplier === 'dhaaga_color') {
            this.setState({
                data: {
                    ...this.state.data,
                    slug: liningsupplier,
                    id: previewDataId,
                    notes: noteGetRow
                },
                message: ''
            })
        } else {
            const suppliers = liningsupplier && liningsupplier[0]
            this.setState({
                data: {
                    ...this.state.data,
                    slug: suppliers && suppliers.suppliercategory && suppliers.suppliercategory.slug,
                    id: previewDataId,
                    notes: noteGetRow
                },
                message: ''
            })
        }

        if (noteSuccess && noteSuccess.success) {
            this.setState({
                data: {
                    ...this.state.data,
                    notes: noteSuccess.order && noteSuccess.order.notes
                },
                message: noteSuccess.message,
                loaderNote: false,
            })
            if (noteSuccess.message) {
                if (!toast.isActive(toastId))
                    toastId = toast.success(noteSuccess.message)
                this.props.noteinitialState()
            }
        }
        if (noteSuccess && noteSuccess.success === false) {
            if (noteSuccess.message) {
                if (!toast.isActive(toastId))
                    toastId = toast.error(noteSuccess.message)
                this.props.noteinitialState()
            }
            this.setState({
                loaderNote: false
            })
        }
    }
    render() {
        const { data, errors, loaderNote } = this.state
        const { previewData, poSupplierDetail, open, orderIdPo, loaderPo, poStatus, poSucc } = this.props
        return (
            <div>
                <Modal isOpen={open} toggle={this.props.toggle} style={{ maxWidth: '880px', width: '90%', margin: '0 auto', top: '10%' }} className={'clssName main_feb_model '}>
                    <Card className="card_border  border-bottom-0 border-radius-inherit">
                        <CardBody className="pt-0 detail_page pb-0">
                            <Row className=" mt-md-0">
                                <Col className=" p-md-4 mb-md-0 mb-2 p-0" md="6">
                                    <h3 className="mb-2 first">Basic Information</h3>
                                    <CardBody className="p-1">
                                        <span>Supplier Name : </span>
                                        <p>{poSupplierDetail && poSupplierDetail.companyName}</p>
                                    </CardBody>
                                    <CardBody className="p-1">
                                        <span>Name : </span>
                                        <p>{poSupplierDetail && poSupplierDetail.firstName + ' ' + poSupplierDetail.lastName}</p>
                                    </CardBody>
                                    <CardBody className="p-1">
                                        <span>Email : </span>
                                        <p>{poSupplierDetail && poSupplierDetail.email}</p>
                                    </CardBody>
                                    <CardBody className="p-1">
                                        <span>Mobile Number : </span>
                                        <p>{poSupplierDetail && poSupplierDetail.mobile}</p>
                                    </CardBody>
                                </Col>

                                <Col className=" p-md-4 mb-md-0 mb-2 p-0" md="6">
                                    <h3 className="mb-2 second">Address Information</h3>
                                    <CardBody className="p-1">
                                        <span>State : </span>
                                        <p>{poSupplierDetail && poSupplierDetail.state}</p>
                                    </CardBody>
                                    <CardBody className="p-1">
                                        <span>City : </span>
                                        <p>{poSupplierDetail && poSupplierDetail.city}</p>
                                    </CardBody>
                                    <CardBody className="p-1">
                                        <span>Address : </span>
                                        <p>{poSupplierDetail && poSupplierDetail.address}</p>
                                    </CardBody>
                                    <CardBody className="p-1">
                                        <span>GST Number : </span>
                                        <p>{poSupplierDetail && poSupplierDetail.gstNumber}</p>
                                    </CardBody>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <ModalBody className="pt-0 mb-0">
                        <div className="table-responsive modal_detail_tabel">
                            <MDBTable className=" table-bordered table-striped" btn>
                                <MDBTableHead columns={previewData.columns} />
                                <MDBTableBody rows={previewData.rows} />
                            </MDBTable>
                        </div>
                    </ModalBody>
                    <ModalFooter className="pt-0">
                        <Form onSubmit={this.noteSubmit} className="p-0" style={{ width: "100%" }}>
                            <div style={{ color: 'green', fontSize: '12px' }}>{this.state.message}</div>
                            <FormGroup error={!errors.notes}>
                                <label className="form-control-label">Note</label>
                                <Input type="textarea" value={data.notes} className="form-control-alternative"
                                    id="input-name" name="notes" onChange={this.noteChange} />
                                {errors.notes && <InlineError text={errors.notes} />}
                            </FormGroup>
                            <Button color="primary" className="submit-button" type="submit" disabled={loaderNote === true ? true : false}>{loaderNote ? <Spinner /> : 'Add Note'}</Button>
                            <Button color="secondary" style={{ background: '#c1c1c1' }} onClick={this.props.toggle}>Cancel</Button>
                            {
                                sessionStorage.userROLE === 'super-admin' || poStatus === 'Pending' ?
                                    <Button className="submit-button" color="primary" disabled={loaderPo === true || poSucc ? true : false} onClick={e => this.props.POSingle(e, orderIdPo)}>{
                                        loaderPo === true ?
                                            <Spinner /> :
                                            'Generate PO'
                                    }</Button>
                                    : ''
                            }

                        </Form>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}
const mapDispatchState = (dispatch) => {
    return bindActionCreators({
        noteSubmit,
        koraPo,
        noteinitialState
    }, dispatch)
}

const mapGetStateNew = (state) => {
    return {
        noteSuccess: state.order.note
    }
}
export default connect(mapGetStateNew, mapDispatchState)(PreviewModal);