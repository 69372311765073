import { GET_SUPPLIER,
        GET_SUPPLIER_ADD,
        GET_SUPPLIER_VIEW,
        GET_SUPPLIER_UPDATE,
        GET_SUPPLIER_DELETE,
        GET_LINING_SUPPLIER,
        GET_MAIN_QUILT_SUPPLIER,
        GET_LINING_QUILT_SUPPLIER,
        GET_RIB_SUPPLIER,
        GET_INITIALSTATE,
        GET_INITIALSTATE_SUPPLIER,
        GET_ZIPPER_SUPPLIER,
        GET_UNIT_SUPPLIER,
        GET_RATIO_SUPPLIER,
        GET_SUPPLIER_INITIAL
} from "../../sharedFile/actionType";
export const supplier = (data)=>({
        type:GET_SUPPLIER,
        data
})
export const initialStateSupplier = (data)=>({
        type:GET_INITIALSTATE_SUPPLIER,
        data
})
export const ribSupplier = (data)=>({
        type:GET_RIB_SUPPLIER,
        data
})
export const unitSupplier = (data)=>({
        type:GET_UNIT_SUPPLIER,
        data
})
export const ratioSupplier = (data)=>({
        type:GET_RATIO_SUPPLIER,
        data
})
export const zipperSupplier = (data)=>({
        type:GET_ZIPPER_SUPPLIER,
        data
})
export const addSupplier =(data)=>({
        type:GET_SUPPLIER_ADD,
        data
})
export const viewSupplier = (data) => ({
        type: GET_SUPPLIER_VIEW,
        data
})    
export const updateSupplier = (id,data) => ({
        type: GET_SUPPLIER_UPDATE,
        id,
        data
})
export const initialDelete = () =>({
        type:GET_SUPPLIER_INITIAL
})
export const deleteSupplier = (id,data) => ({
        type:GET_SUPPLIER_DELETE,
        id,
        data
})
export const liningSupplier = () => ({
        type: GET_LINING_SUPPLIER,
})
export const mainquiltSupplier = () => ({
        type: GET_MAIN_QUILT_SUPPLIER,
})

export const liningquiltSupplier = () => ({
        type: GET_LINING_QUILT_SUPPLIER,
})

export const initialState = () => ({
        type: GET_INITIALSTATE,
    })