import {
    SET_SUPPLIER_FAILED,
    SET_SUPPLIER_SUCCESS,
    ADD_SUPPLIER_FAILED,
    ADD_SUPPLIER_SUCCESS,
    DELETE_SUPPLIER_FAILED,
    DELETE_SUPPLIER_SUCCESS,
    SET_LINING_SUPPLIER_FAILED,
    SET_LINING_SUPPLIER_SUCCESS,
    SET_MAIN_QUILT_SUPPLIER_FAILED,
    SET_MAIN_QUILT_SUPPLIER_SUCCESS,
    SET_LINING_QUILT_SUPPLIER_FAILED,
    SET_LINING_QUILT_SUPPLIER_SUCCESS,
    SET_RIB_SUPPLIER_SUCCESS,
    GET_INITIALSTATE,
    DETAIL_SUPPLIER_SUCCESS,
    DETAIL_SUPPLIER_FAILED,
    SET_ZIPPER_SUPPLIER_FAILED,
    SET_ZIPPER_SUPPLIER_SUCCESS,
    SET_RATIO_SUPPLIER_SUCCESS,
    SET_UNIT_SUPPLIER_SUCCESS,
    GET_INITIALSTATE_SUPPLIER,
    GET_SUPPLIER_INITIAL
} from "../../sharedFile/actionType";
const initialState = {
    supplier: [],
    error: false,
    redirect: false,
    addSupplier: [],
    updateMessage:false
}
const supplier = (state = initialState, action) => {
    switch (action.type) {
        case SET_SUPPLIER_SUCCESS:
            return {
                ...state,
                supplier: action.data
            }
        case SET_RIB_SUPPLIER_SUCCESS:
            return {
                ...state,
                supplier: action.data
            }
        case SET_UNIT_SUPPLIER_SUCCESS:
            return {
                ...state,
                supplier: action.data
            }

        case SET_RATIO_SUPPLIER_SUCCESS:
            return {
                ...state,
                supplier: action.data
            }
        case SET_ZIPPER_SUPPLIER_SUCCESS:
            return {
                ...state,
                supplier: action.data
            }
        case SET_ZIPPER_SUPPLIER_FAILED:
            return {
                ...state,
                supplier: action.data
            }
        case SET_SUPPLIER_FAILED:
            return {
                ...state,
                error: true,
                supplier: action.error
            }
        case ADD_SUPPLIER_SUCCESS:
            return {
                ...state,
                addSupplier: action.data
            }
        case ADD_SUPPLIER_FAILED:
            return {
                ...state,
                error: true,
                addSupplier: action.data
            }
        case DETAIL_SUPPLIER_SUCCESS:
            return {
                ...state,
                addSupplier: action.data,
                redirect: action.redirect,
                material: action.data && action.data.material,
                updateMessage:true
            }
        case GET_INITIALSTATE_SUPPLIER:
            return {
                ...state,
                addSupplier: [],
                material:[],
                redirect:false,
            }
        case DETAIL_SUPPLIER_FAILED:
            return {
                ...state,
                error: true,
                addSupplier: action.error
            }
        case DELETE_SUPPLIER_SUCCESS:
            return {
                ...state,
                supplier: {
                    suppliers: state.supplier.suppliers.filter(item => item.id !== action.data.supplier.id),
                    success: true
                },
                deleteSupplier:action.data
            }
        case DELETE_SUPPLIER_FAILED:
            return {
                ...state,
                error: true,
                deleteSupplier: action.error
            }
        case SET_LINING_SUPPLIER_SUCCESS:
            return {
                ...state,
                liningsupplier: action.data
            }
        case SET_LINING_SUPPLIER_FAILED:
            return {
                ...state,
                error: true,
                liningsupplier: action.error
            }
        case SET_MAIN_QUILT_SUPPLIER_SUCCESS:
            return {
                ...state,
                mainquiltsupplier: action.data
            }
        case SET_MAIN_QUILT_SUPPLIER_FAILED:
            return {
                ...state,
                error: true,
                mainquiltsupplier: action.error
            }
        case SET_LINING_QUILT_SUPPLIER_SUCCESS:
            return {
                ...state,
                liningquiltsupplier: action.data
            }
        case SET_LINING_QUILT_SUPPLIER_FAILED:
            return {
                ...state,
                error: true,
                liningquiltsupplier: action.error
            }
        case GET_INITIALSTATE:
            return {
                ...state,
                supplier: [],
                redirect: false,
                addSupplier: []
            }
        case GET_SUPPLIER_INITIAL:
            return{
                ...state,
                deleteSupplier:[],
                updateMessage:false
            }
        default:
            return state
    }
}
export default supplier;