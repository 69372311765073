
import React from "react";
import {
  Container,
  Row,
  Spinner,
  Button,
  Col
} from "reactstrap";
// core components
import Header from "../../components/Headers/Header.jsx";
import Table from "./materialsTable"
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss'
import { material, deleteMaterial } from '../../services/actions/material';
import ConfirmModal from '../../components/ConfirmModal';
import { initialState } from '../../services/actions/order'
import FormModel from "../../components/formModel.jsx";
import AddMaterial from "./AddMaterial.jsx";
import EditMaterial from "./EditMaterial.jsx";
import { MDBIcon } from 'mdbreact';
import moment from 'moment';
import { imagerUrl } from '../../config/config';
import { ToastContainer, toast } from 'react-toastify';
// import loaderImg from '../../assets/img/loader_img.gif'
var toastId = null

class MaterialsList extends React.Component {
  state = {
    data: {
      columns: [
        { label: '#', field: '#', sort: 'asc', },
        { label: 'Material Image', field: 'Image', sort: 'asc', },
        { label: 'Material Name', field: 'Name', sort: 'asc', },
        { label: 'Sub Category', field: 'Sub Category', sort: 'asc', },
        { label: 'Material Description', field: 'Description', sort: 'asc', },
        { label: 'Created on', field: 'Created on', sort: 'asc' },
        { label: 'Updated on', field: 'Updated on', sort: 'asc' },
        { label: 'Action', field: 'Action', sort: 'disabled', }
      ],
      rows: []
    },
    pageNumber: 1,
    open: false,
    loader: false,
    openForm: false,
    openEditFrom: false,
    editId: null,
    openMaterialImg: false,
    materialImg: []
  }
  componentDidMount() {
    const { pageNumber } = this.state
    this.props.material(pageNumber);
    this.setState({
      loader: true
    })
  }
  confirm = (event, id, name) => {
    this.setState({
      ...this.state,
      open: true,
      id: id,
      name: name
    })
  }
  openSlider = (e, id) => {
    const { materialSuccess } = this.props
    let materialImg = []
    materialSuccess && materialSuccess.materials && materialSuccess.materials.map((items, key) => {
      if (id === items.id) {
        materialImg.push(items.materialImages)
      }
    })
    this.setState({
      openMaterialImg: true,
      materialImg: materialImg
    })
  }
  toggle = () => {
    this.setState({
      open: false,
      openEditFrom: false,
      openForm: false,
      openMaterialImg: false
    })
  }
  confirmation = (event, id) => {
    this.setState({
      open: false
    })
    this.props.deleteMaterial(id)
  }
  componentWillReceiveProps(newProps) {
    const { materialSuccess, detailmaterial, materialRedirect,deleteMaterialFailed } = newProps
    if(deleteMaterialFailed && deleteMaterialFailed.success === false){
      if (toast.isActive(toastId)) {
        toast.dismiss(toastId)
        toastId = toast.error(deleteMaterialFailed.message);
        newProps.initialState()
      } else {
        toastId = toast.error(deleteMaterialFailed.message);
        newProps.initialState()
      }
    }
    if (materialRedirect.redirect) {
      this.setState({
        openEditFrom: false
      })
    }
    if (detailmaterial && detailmaterial.success === false) {
      if (toast.isActive(toastId)) {
        toast.dismiss(toastId)
        toastId = toast.error(detailmaterial.message);
      } else {
        toastId = toast.error(detailmaterial.message);
      }
    }
    if (materialSuccess && materialSuccess.success) {
      let role = sessionStorage.userROLE.replace('_worker', '')
      let descrip
      var testData = []
      materialSuccess && materialSuccess.materials && materialSuccess.materials.map((items, key) => {
        let imgSrc = items.materialImages[0] && items.materialImages[0].image ? imagerUrl + items.materialImages[0].image : '/defult_image.jpg';
        let subCategory = items.assignedMaterial && items.assignedMaterial[0].materialSubCategory.name
        let subCategorySlug = items.assignedMaterial && items.assignedMaterial[0].materialSubCategory.slug
        if (items.description.length > 30)
          descrip = items.description.substring(0, 30) + '...';
        else
          descrip = items.description
        if (role === subCategorySlug || sessionStorage.userROLE === 'super-admin') {
          testData.push(
            {
              '#': key + 1,
              'Image': <img src={imgSrc} style={{height: '50px',borderRadius: '50%',objectFit: 'cover'}} width="50" alt="material" className="cursor-pointer" onClick={e => this.openSlider(e, items.id)}></img>,
              'Name': items.name,
              'Sub Category': subCategory,
              'Description': <>{items.description.length > 30 ? <span className="tooltip1" key={'abcdeZ' + key} >{descrip}<span className="tooltiptext textFLow">{items.description}</span></span> : items.description}</>,
              'Created on': moment(items.createdAt).utcOffset("+05:30").format('DD/MM/YY, H:mm'),
              'Updated on': moment(items.updatedAt).utcOffset("+05:30").format('DD/MM/YY, H:mm'),
              'Action': [<>{sessionStorage.userROLE == 'super-admin' ? <><Button className="action_btn text-black" outline onClick={e => this.editFrom(e, items.id)}><MDBIcon far icon="edit" title="Edit" /></Button> <Button className="action_btn text-black" outline color="" onClick={e => this.confirm(e, items.id, items.name)}><MDBIcon far icon="trash-alt" title="Delete" /></Button></> : <div className="text-danger" style={{ fontSize: '12px' }}>Can't Edit/Delete</div>}</>]
            }
          )
        } else {
        }

      })
      if (materialRedirect.deleted) {
        if (toast.isActive(toastId)) {
          toast.dismiss(toastId)
          toastId = toast.success(materialSuccess.message);
          newProps.initialState()
        } else {
          toastId = toast.success(materialSuccess.message);
          newProps.initialState()
        }
      }
      this.setState({
        data: {
          ...this.state.data,
          rows: testData
        },
        loader: false
      })
    }
    if (materialSuccess && materialSuccess.success === false) {
      this.setState({
        loader: false
      })
    }
  }
  componentWillUnmount() {
    this.props.initialState();
  }
  addForm = () => {
    this.setState({
      openForm: true
    })
  }
  editFrom = (e, id) => {
    this.setState({
      openEditFrom: true,
      editId: id
    })
  }
  render() {
    const { open, loader, openForm, openEditFrom, editId, openMaterialImg } = this.state
    return (
      <>
        <ToastContainer autoClose={5000} />
        {
          !loader ?
            <>
              <Header />
              <Container className="mt-0" fluid>
                <Row>
                  <div className="col">
                    <Table title="Materials Listing" addForm={this.addForm} confirm={this.confirm} editFrom={this.editFrom} {...this.state} />
                  </div>
                  <ConfirmModal open={open} toggle={this.toggle} confirmation={this.confirmation} id={this.state.id} name={this.state.name} />
                </Row>
                <FormModel modelForm={<AwesomeSlider
                  className="pt-2"
                  animation="foldOutAnimation"
                // cssModule={[coreStyles, animationStyles]}
                >
                  {this.state.materialImg && this.state.materialImg[0] && this.state.materialImg[0].length ?
                    this.state.materialImg[0].map((items, key) => {
                      return <div data-src={items.image ? `${imagerUrl}` + items.image : '/defult_image.jpg'} />
                    })
                    :
                    <Col className="curcle_img text-center">
                      <img src={'/defult_image.jpg'} height="100" alt="material"></img>
                    </Col>
                  }
                </AwesomeSlider>} toggle={this.toggle} openForm={openMaterialImg} />
                <FormModel modelForm={<AddMaterial />} toggle={this.toggle} openForm={openForm} />
                <FormModel modelForm={<EditMaterial editId={editId} />} toggle={this.toggle} openForm={openEditFrom} />
              </Container>
            </>
            :
            <div className="loader">
              <Spinner variant="light" />
              <p style={{ color: '#fff', paddingTop: '15px' }}>Please wait......</p>
            </div>
        }
      </>
    );
  }
}
const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    material,
    deleteMaterial,
    initialState
  }, dispatch)
}
const mapGetStateNew = (state) => {
  return {
    materialSuccess: state.material.material,
    detailmaterial: state.material.detailmaterial,
    materialRedirect: state.material,
    deleteMaterialFailed: state.material.deleteMaterial,
  }
}
export default connect(mapGetStateNew, mapDispatchState)(MaterialsList);
