import { call, put, takeLatest, all } from "redux-saga/effects";
import { 
    SET_SUPPLIER_FAILED,
    SET_SUPPLIER_SUCCESS,
    GET_SUPPLIER,
    GET_SUPPLIER_ADD,
    ADD_SUPPLIER_SUCCESS,
    ADD_SUPPLIER_FAILED,
    GET_SUPPLIER_VIEW,
    GET_SUPPLIER_UPDATE,
    GET_SUPPLIER_DELETE,
    DELETE_SUPPLIER_SUCCESS,
    DELETE_SUPPLIER_FAILED,
    SET_LINING_SUPPLIER_FAILED,
    SET_LINING_SUPPLIER_SUCCESS,
    GET_LINING_SUPPLIER,
    SET_MAIN_QUILT_SUPPLIER_FAILED,
    SET_MAIN_QUILT_SUPPLIER_SUCCESS,
    GET_MAIN_QUILT_SUPPLIER,
    SET_LINING_QUILT_SUPPLIER_FAILED,
    SET_LINING_QUILT_SUPPLIER_SUCCESS,
    GET_LINING_QUILT_SUPPLIER,
    SET_RIB_SUPPLIER_SUCCESS,
    SET_RIB_SUPPLIER_FAILED,
    GET_RIB_SUPPLIER,
    DETAIL_SUPPLIER_SUCCESS,
    DETAIL_SUPPLIER_FAILED,
    SET_ZIPPER_SUPPLIER_SUCCESS,
    SET_ZIPPER_SUPPLIER_FAILED,
    GET_ZIPPER_SUPPLIER,
    GET_RATIO_SUPPLIER,
    SET_RATIO_SUPPLIER_SUCCESS,
    SET_RATIO_SUPPLIER_FAILED,
    GET_UNIT_SUPPLIER,
    SET_UNIT_SUPPLIER_SUCCESS,
    SET_UNIT_SUPPLIER_FAILED,
} from "../../sharedFile/actionType";
import Api from "../../lib/api";

function getSupplierListApi(action){

  const {data} = action
  let query = ''
  if(data.categoryId) {
    query=query+`&categoryId=${data.categoryId}`
  }
  if(data.city){
    query=query+`&city=${data.city}`
  }
  if(data.state){
    query=query+`&state=${data.state}`
  }
  if(data.date){
    query=query+`&date=${data.date}`
  }
  if(data.limit){
    query=query+`&limit=${data.limit}`
  }
    return Api.get(
        `supplier?`+query
    );
}
/*api to add new supplier*/
function getSupplierAddApi(action){
  const {data} = action
        return Api.post(
          `supplier/`,{
            address: data.address,
            categoryId: data.categoryId,
            city: data.city,
            companyName: data.companyName,
            email: data.email,
            firstName: data.firstName,
            gstNumber: data.gstNumber,
            lastName: data.lastName,
            materialId: data.materialId,
            mobile: data.mobile,
            phone: data.phone,
            state: data.state,
            zip: data.zip,
            country:data.country
          }
    );
}
function* getAllSuppliersList(action){
    try {     
        const resp = yield call(getSupplierListApi, action);       
        if (resp.success === true) {
          yield put({ type: SET_SUPPLIER_SUCCESS, data: resp })
        } else {
          yield put({ type: SET_SUPPLIER_FAILED, error: resp })
        }
      } catch (resp) {
        yield put({ type: SET_SUPPLIER_FAILED, error: resp })
      }
}
function* getAllSuppliersAdd(action){
  try{
    const resp = yield call(getSupplierAddApi, action);
    if (resp.success === true) {
      yield put({ type: ADD_SUPPLIER_SUCCESS, data: resp })
    } else {
      yield put({ type: ADD_SUPPLIER_FAILED, data: resp })
    }
  }catch(resp){
    yield put({ type: ADD_SUPPLIER_FAILED, data: resp })
  }
} 
function viewSupplierApi(action){
  return Api.get(
      `supplier/`+action.data
  );
}
function* viewSupplier(action){
  try {
    const resp = yield call(viewSupplierApi, action);
    if (resp.success === true) {
      yield put({ type: DETAIL_SUPPLIER_SUCCESS, data: resp, redirect:false })
    } else {
      yield put({ type: DETAIL_SUPPLIER_FAILED, error: resp.message })
    }
  } catch (resp) {
    yield put({ type: DETAIL_SUPPLIER_FAILED, error: resp.message })
  }
}

function updateSupplierApi(action){
  return Api.put(
      `supplier/`+action.id,
      action.data
      )
}
function* updateSupplier(action){
  try {
    const resp = yield call(updateSupplierApi, action);
    if (resp.success === true) {
      yield put({ type: DETAIL_SUPPLIER_SUCCESS, data: resp,redirect:true  })
    } else {
      yield put({ type: DETAIL_SUPPLIER_FAILED, error: resp })
    }
  } catch (resp) {
    yield put({ type: DETAIL_SUPPLIER_FAILED, error: resp  })
  }
}
function deleteSupplierApi(action){

  return Api.delete(
      `supplier/`+action.id
  );
}
function* deleteSupplier(action){
  try {
    const resp = yield call(deleteSupplierApi, action);
    if (resp.success === true) {
      yield put({ type: DELETE_SUPPLIER_SUCCESS, data: resp,  })
    } else {
      yield put({ type: DELETE_SUPPLIER_FAILED, error: resp })
    }
  } catch (resp) {
    yield put({ type: DELETE_SUPPLIER_FAILED, error: resp  })
  }
}

function getLiningSupplierListApi(){
  return Api.get(
      `supplier/main/list`,
  );
}
function* getAllLiningSuppliersList(action){
  try {     
      const resp = yield call(getLiningSupplierListApi, action);       
      if (resp.success === true) {
        yield put({ type: SET_LINING_SUPPLIER_SUCCESS, data: resp })
      } else {
        yield put({ type: SET_LINING_SUPPLIER_FAILED, error: resp })
      }
    } catch (resp) {
      yield put({ type: SET_LINING_SUPPLIER_FAILED, error: resp})
    }
}

function getmainquiltSupplierListApi(action){
  return Api.get(
      `supplier/mainquilt/list`
  );
}
function* getAllmainquiltSuppliersList(action){
  try {     
      const resp = yield call(getmainquiltSupplierListApi, action);       
      if (resp.success === true) {
        yield put({ type: SET_MAIN_QUILT_SUPPLIER_SUCCESS, data: resp })
      } else {
        yield put({ type: SET_MAIN_QUILT_SUPPLIER_FAILED, error: resp })
      }
    } catch (resp) {
      yield put({ type: SET_MAIN_QUILT_SUPPLIER_FAILED, error: resp})
    }
}

function getliningquiltSupplierListApi(){
  return Api.get(
      `supplier/liningquilt/list`,
  );
}
function* getAllliningquiltSuppliersList(action){
  try {     
      const resp = yield call(getliningquiltSupplierListApi, action);       
      if (resp.success === true) {
        yield put({ type: SET_LINING_QUILT_SUPPLIER_SUCCESS, data: resp })
      } else {
        yield put({ type: SET_LINING_QUILT_SUPPLIER_FAILED, error: resp })
      }
    } catch (resp) {
      yield put({ type: SET_LINING_QUILT_SUPPLIER_FAILED, error: resp })
    }
}
function ribSupplierApi(){
  return Api.get(
      `/supplier/rib/list`,
  );
}
function* ribSupplier(action){
  try {     
      const resp = yield call(ribSupplierApi, action);       
      if (resp.success === true) {
        yield put({ type: SET_RIB_SUPPLIER_SUCCESS, data: resp })
      } else {
        yield put({ type: SET_RIB_SUPPLIER_FAILED, error: resp })
      }
    } catch (resp) {
      yield put({ type: SET_RIB_SUPPLIER_FAILED, error: resp })
    }
}
function zipperSupplierApi(){
  return Api.get(
      `/supplier/zipper/list`,
  );
}
function* zipperSupplier(action){
  try {     
      const resp = yield call(zipperSupplierApi, action);       
      if (resp.success === true) {
        yield put({ type: SET_ZIPPER_SUPPLIER_SUCCESS, data: resp })
      } else {
        yield put({ type: SET_ZIPPER_SUPPLIER_FAILED, error: resp })
      }
    } catch (resp) {
      yield put({ type: SET_ZIPPER_SUPPLIER_FAILED, error: resp})
    }
}
function unitSupplierApi(){
  return Api.get(
      `/supplier/units/list`,
  );
}
function* unitSupplier(action){
  try {     
      const resp = yield call(unitSupplierApi, action);       
      if (resp.success === true) {
        yield put({ type: SET_UNIT_SUPPLIER_SUCCESS, data: resp })
      } else {
        yield put({ type: SET_UNIT_SUPPLIER_FAILED, error: resp.message })
      }
    } catch (resp) {
      yield put({ type: SET_UNIT_SUPPLIER_FAILED, error: 'Something Went Wrong' })
    }
}
function ratioSupplierApi(){
  return Api.get(
      `/supplier/ratio/list`,
  );
}
function* ratioSupplier(action){
  try {     
      const resp = yield call(ratioSupplierApi, action);       
      if (resp.success === true) {
        yield put({ type: SET_RATIO_SUPPLIER_SUCCESS, data: resp })
      } else {
        yield put({ type: SET_RATIO_SUPPLIER_FAILED, error: resp.message })
      }
    } catch (resp) {
      yield put({ type: SET_RATIO_SUPPLIER_FAILED, error: 'Something Went Wrong' })
    }
}
function* supplier() {
    yield all([
      takeLatest(GET_SUPPLIER, getAllSuppliersList),  
      takeLatest(GET_SUPPLIER_ADD, getAllSuppliersAdd),    
      takeLatest(GET_SUPPLIER_VIEW, viewSupplier),
      takeLatest(GET_SUPPLIER_UPDATE, updateSupplier),
      takeLatest(GET_SUPPLIER_DELETE, deleteSupplier),
      takeLatest(GET_LINING_SUPPLIER, getAllLiningSuppliersList),
      takeLatest(GET_MAIN_QUILT_SUPPLIER, getAllmainquiltSuppliersList),
      takeLatest(GET_LINING_QUILT_SUPPLIER, getAllliningquiltSuppliersList),
      takeLatest(GET_RIB_SUPPLIER, ribSupplier),
      takeLatest(GET_ZIPPER_SUPPLIER, zipperSupplier),
      takeLatest(GET_RATIO_SUPPLIER, ratioSupplier),
      takeLatest(GET_UNIT_SUPPLIER, unitSupplier)
    ])
}
export default supplier;