import React, { Component } from "react";
import Header from "../../../components/Headers/Header";
import { getBrandOrder, ribDhaagaOrder, getRibDhaagaOrder, initialState, noteinitialState, getInhouseStocks } from '../../../services/actions/order';
import { bindActionCreators } from 'redux';
import { ribSupplier } from '../../../services/actions/supplier';
import { connect } from 'react-redux';
import { MDBDataTable } from 'mdbreact';
import { imagerUrl } from '../../../config/config';
import {
  Card,
  CardHeader,
  Row,
  Container,
  Button,
  NavItem,
  Nav,
  NavLink,
  Spinner
} from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom'
import EditTable from '../../../components/EditTable'
import dataJson from '../../../sharedFile/allData'
import PreviewModal from "../../../components/previewModal";
import { koraPo } from '../../../services/actions/po'
import moment from 'moment'
var toastId = null

class Mainribdhaaga extends React.Component {
  state = {
    data: {
      columns: [
        { label: "Action", field: "Action" },
        { label: "#", field: "Sr No" },
        { label: 'Style No', field: 'Style No' },
        { label: 'Style Code', field: 'Style Code' },
        { label: "Order Qty", field: "order qtty", type: "numeric" },
        { label: 'Supplier', field: 'Supplier', class: 'selectList' },
        { label: 'Dhaaga Specification', field: 'Dhaaga Specification', class: 'selectList' },
        { label: "Add Qty %", field: "add qtty", type: "numeric" },
        { label: 'Cuff Size', field: 'Cuff size' },
        { label: 'Border Size', field: 'Border size' },
        { label: 'Collar Size', field: 'Collar size' },
        { label: '(1)Rib Size', field: '(1)Rib size' },
        { label: '(2)Rib Size', field: '(2)Rib size' },
        { label: 'Kora Dhaaga Need (KG)', field: 'kora dhaaga need (in KG)' },
        { label: 'Inhouse', field: 'inHouse' },
        { label: 'Final Need', field: 'Final need' },
        { label: 'Price', field: 'price' },
        { label: "Delivery", field: "Delivery" },
        { label: 'Remarks', field: 'Remarks' },
        { label: 'PO Status', field: 'po status' },
        { label: 'Recd Status', field: 'recd status', class: 'avgWidth' },
        { label: 'Pending Status', field: 'pending status', class: 'avgWidth' }
      ],
      rows: []
    },
    inHouse: '',
    poStatus: '',
    brand: '',
    brandImg: '',
    poSucc: null,
    brandOrderId: this.props.match.params.id,
    suppliers: [],
    material: [],
    edit: [],
    loaderPo: false,
    loaderTable: true,
    loader: true,
    errors: {},
    rows: [],
    pageNumber: 1,
    orderIdPo: '',
    slug: this.props.location.state.split('/'),
    search: '',
    name: '',
    selectPoList: {
      id: []
    },
    previewData: {
      columns: [],
      rows: []
    },
    total: 0,
    open: false,
    previewDataId: null,
    supllierDetail: [],
    noteGetRow: '',
    materialList: []
  }
  componentDidMount() {
    const { slug, pageNumber, brandOrderId, search } = this.state
    const data = { slug: slug[2], pageNumber, brandOrderId, search }
    const dataBrand = {
      brandOrderId, pageNumber
    }
    this.props.ribSupplier()
    this.props.getBrandOrder(dataBrand);
    this.props.getRibDhaagaOrder(data)
    this.setState({
      loaderTable: true
    })
  }
  valueEdit = (rowId, value, key, rows) => {
    let poSucc = rows[rowId]['addQty'] !== null && rows[rowId]['cuffSize'] !== '' && rows[rowId]['borderSize'] !== null && rows[rowId]['collarSize'] !== null && rows[rowId]['ribSizeExtra1'] !== null && rows[rowId]['totalNeedKg'] !== null && rows[rowId]['materialId'] !== null && rows[rowId]['supplierId'] !== null && rows[rowId]['price'] !== null && rows[rowId]['deliveryDate'] !== null ? false : true
    if (key == rowId) {
      value['Action'] = [<div style={{ display: 'flex' }}>{(sessionStorage.userROLE == 'super-admin' || rows[rowId]['poStatus'] === 'Pending') ? <><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i><i title="Add Row" className="fas fa-plus-circle cursor-pointer" onClick={e => (this.onAdd(e, key))} key={'abcd' + key}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, value.id, poSucc))} ></i><input type="checkbox" value={value.id} name="poGenrate" disabled={poSucc} key={key} onChange={e => (this.listPoGenrate(e, key))} style={{ minWidth: '15px', marginLeft: '5px' }} /></> : <><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, value.id, poSucc))} ></i><i title="Add Row" className="fas fa-plus-circle cursor-pointer" onClick={e => (this.onAdd(e, key))} key={'abcd' + key}></i></>}</div>]
      value['Sr No'] = key + 1
      value['Style No'] = value['Style No']
      value['Style Code '] = value['Style Code ']
      value['order qtty'] = value['order qtty']
      value['Supplier'] = rows[rowId]['supplierText']
      value['Dhaaga Specification'] = rows[rowId]['materialId']
      value['add qtty'] = rows[rowId]['addQty']
      value['Cuff size'] = rows[rowId]['cuffSize']
      value['Border size'] = rows[rowId]['borderSize']
      value['Collar size'] = rows[rowId]['collarSize']
      value['(1)Rib size'] = rows[rowId]['ribSizeExtra1']
      value['(2)Rib size'] = rows[rowId]['ribSizeExtra2']
      value['kora dhaaga need (in KG)'] = rows[rowId]['totalNeedKg']
      value['inHouse'] = rows[rowId]['inHouse']
      value['Final need'] = rows[rowId]['finalNeed']
      value['price'] = rows[rowId]['price']
      value['Delivery'] = rows[rowId]['deliveryDate']
      value['Remarks'] = rows[rowId]['Remarks']
      value['po status'] = rows[rowId]['remarks']
      value['recd status'] = rows[rowId]['recdStatus']
      value['pending status'] = rows[rowId]['pendingStatus']
    }
  }
  onKeyPress(evt) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }
  valueUpdate = (value, key, rows, rowId, suppliers, material) => {
    let currentDate = new Date();
    let getYear = currentDate.getFullYear()
    let getMonth = currentDate.getMonth() + 1
    let fullCurrentDate = getYear + '-' + (getMonth <= 9 ? '0' + getMonth : getMonth) + '-' + (currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate())
    if (key == rowId) {
      if (sessionStorage.userROLE == 'super-admin' || rows[rowId]['poStatus'] === 'Pending') {
        value['Action'] = [<i className="fas fa-check pr-2 cursor-pointer" onClick={e => (this.onSubmit(e, key))} key={'abc' + key}></i>]
        value['Sr No'] = key + 1
        value['Style No'] = value['Style No']
        value['Style Code'] = value['Style Code']
        value['order qtty'] = value['order qtty']
        value['Supplier'] = [<select name="supplier" className="inputFiled selectList" defaultValue={rows[key]['supplier']} onChange={e => (this.onChange(e, key))}>
          <option>Supplier</option>
          {
            suppliers && suppliers.map((value, key) => {
              return <option value={value.id}>{value.companyName}</option>
            })}
        </select>]
        value['Dhaaga Specification'] = [<select className="inputFiled selectList" name="materialId" defaultValue={rows[key]['materialId']} onChange={e => (this.onChange(e, key))}>
          <option>specification</option>
          {
            material && material.length && material[0].map((value, key) => {
              return <option value={value.supplyMaterial.id} key={key}>{value.supplyMaterial.name}</option>
            })
          }
        </select>]
        value['add qtty'] = [<select name="addQty" className="inputFiled" defaultValue={rows[key]['addQty']} onChange={e => (this.onChange(e, key))}>
          <option value="0">add qtty</option>
          {
            dataJson.addQuantity.map((value, key) => {
              return <option value={value}>{value + '%'}</option>
            })}
        </select>]
        value['Cuff size'] = [<input type="text" className="inputFiled" name="cuffSize" value={rows[key]['cuffSize']} onChange={e => (this.onChange(e, key))} />, <div style={{ fontSize: '12px' }}>Eg:</div>, <div style={{ fontSize: '12px' }}>14.23 * 16.33</div>]
        value['Border size'] = [<input type="text" className="inputFiled" name="borderSize" value={rows[key]['borderSize']} onChange={e => (this.onChange(e, key))} />, <div style={{ fontSize: '12px' }}>Eg:</div>, <div style={{ fontSize: '12px' }}>14.23 * 16.33</div>]
        value['Collar size'] = [<input type="text" className="inputFiled" name="collarSize" value={rows[key]['collarSize']} onChange={e => (this.onChange(e, key))} />, <div style={{ fontSize: '12px' }}>Eg:</div>, <div style={{ fontSize: '12px' }}>14.23 * 16.33</div>]
        value['(1)Rib size'] = [<input type="text" className="inputFiled" name="ribSizeExtra1" value={rows[key]['ribSizeExtra1']} onChange={e => (this.onChange(e, key))} />, <div style={{ fontSize: '12px' }}>Eg:</div>, <div style={{ fontSize: '12px' }}>14.23 * 16.33</div>]
        value['(2)Rib size'] = [<input type="text" className="inputFiled" name="ribSizeExtra2" value={rows[key]['ribSizeExtra2']} onChange={e => (this.onChange(e, key))} />, <div style={{ fontSize: '12px' }}>Eg:</div>, <div style={{ fontSize: '12px' }}>14.23 * 16.33</div>]
        value['kora dhaaga need (in KG)'] = rows[key]['totalNeedKg']
        value['inHouse'] = rows[key]['inHouse']
        value['Final need'] = rows[key]['finalNeed']
        value['price'] = [<input maxLength="8" type="text" className="inputFiled" onKeyPress={this.onKeyPress.bind(this)} name="price" value={rows[key]['price']} onChange={e => (this.onChange(e, key))} />]
        value['Delivery'] = [<input type="date" className="inputFiled" min={fullCurrentDate} name="deliveryDate" value={rows[key]['deliveryDate']} onChange={e => (this.onChange(e, key))} />]
        value['Remarks'] = [<input type="text" className="inputFiled" name="remarks" value={rows[key]['remarks']} onChange={e => (this.onChange(e, key))} />]
        value['po status'] = value['po status']
        value['recd status'] = [<input type="text" className="inputFiled" onKeyPress={this.onKeyPress.bind(this)} name="recdStatus" value={rows[key]['recdStatus']} onChange={e => (this.onChange(e, key))} />]
        value['pending status'] = rows[key]["pendingStatus"]
      }
      else {
        value['Action'] = [<i className="fas fa-check pr-2 cursor-pointer" onClick={e => (this.onSubmit(e, key))} key={'abc' + key}></i>]
        value['recd status'] = [<input type="text" className="inputFiled" onKeyPress={this.onKeyPress.bind(this)} name="recdStatus" value={rows[key]['recdStatus']} onChange={e => (this.onChange(e, key))} />]
        value['pending status'] = rows[key]["pendingStatus"]
      }
    } else {
      value['Action'] = [<div className="d-flex"><i title="Edit" className="disabledAction fas fa-pen pr-2 cursor-pointer" key={'abc' + key}></i><i title="Add Row" className="disabledAction fas fa-plus-circle cursor-pointer" key={'abcd' + key}></i><i key={'abcr' + key} title="View" className="disabledAction fas fa-eye cursor-pointer" ></i><input style={{ minWidth: '15px', marginLeft: '5px' }} type="checkbox" value={this.state.rows[key]['orderDescId']} name="poGenrate" disabled key={key} onChange={e => (this.listPoGenrate(e, key))} /></div>]
    }
  }
  onSubmit = (event, rowId) => {
    const { rows, data, pageNumber, slug, brandOrderId, search } = this.state
    var row = data.rows.map((value, key) => {
      this.valueEdit(rowId, value, key, rows)
      return value;
    })
    this.setState({
      data: {
        ...this.state.data,
        rows: row
      }
    })
    let dataSubmit = []
    rows.map((val, key) => {
      if (key === rowId) {
        dataSubmit.push({
          "brandOrderId": brandOrderId != '' && brandOrderId != undefined ? brandOrderId : null,
          "orderDescId": val.orderDescId != '' && val.orderDescId != undefined ? val.orderDescId : null,
          "orderid": val.orderid != undefined ? val.orderid : undefined,
          // "FabricOrderId": val.mainFabricOrderId != '' && val.mainFabricOrderId != undefined? val.mainFabricOrderId : null,
          "addQty": val.addQty != '' && val.addQty != undefined ? val.addQty : null,
          "ribSizeExtra1": val.ribSizeExtra1 != '' && val.ribSizeExtra1 != undefined ? val.ribSizeExtra1 : null,
          "ribSizeExtra2": val.ribSizeExtra2 != '' && val.ribSizeExtra2 != undefined ? val.ribSizeExtra2 : null,
          "borderSize": val.borderSize != '' && val.borderSize != undefined ? val.borderSize : null,
          "collarSize": val.collarSize != '' && val.collarSize != undefined ? val.collarSize : null,
          "cuffSize": val.cuffSize != '' && val.cuffSize != undefined ? val.cuffSize : null,
          "deliveryDate": val.deliveryDate != '' && val.deliveryDate != undefined ? val.deliveryDate : null,
          "finalNeed": val.finalNeed !== '' ? val.finalNeed : null,
          "materialId": val.materialId != '' && val.materialId != undefined ? val.materialId : null,
          "supplierId": val.supplier != '' && val.supplier != undefined ? val.supplier : null,
          "totalNeedKg": val.totalNeedKg != '' && val.totalNeedKg != undefined ? val.totalNeedKg : null,
          "poStatus": val.poStatus != '' && val.poStatus != undefined ? val.poStatus : null,
          "inHouse": val.inHouse !== '' ? val.inHouse : null,
          "price": val.price != '' && val.price != undefined ? val.price : null,
          "remarks": val.remarks != '' && val.remarks != undefined ? val.remarks : null,
          'recdStatus': val.recdStatus != '' && val.recdStatus != undefined ? val.recdStatus : null,
          'pendingStatus': val.pendingStatus != '' && val.pendingStatus != undefined ? val.pendingStatus : null,
          oldStock: val.oldStock !== '' ? val.oldStock : null,
          newStock: val.newStock !== '' ? val.newStock : null,
        })
      }
    })
    this.props.ribDhaagaOrder({ brandOrderId, slug: slug[2], pageNumber, search, data: dataSubmit })
  }
  onChange = (e, rowId) => {
    let { rows, data, suppliers, materialList } = this.state
    let materialSet = []
    let material = []
    let row = rows.map((value, key) => {
      if (key == rowId) {
        let total = 0
        value[e.target.name] = e.target.value
        if (e.target.name === 'supplier' && e.target.value !== 'Select Supplier') {
          material = []
          value['supplier'] = e.target.value
          value['materialId'] = ''
          suppliers.map((val, keys) => {
            if ((val.id).toString() === value['supplier']) {
              materialSet.push(val.suppliermaterial)
              material.push(val.suppliermaterial)
            } return true
          })
          this.setState({
            materialList: material
          })
        } else {
          if (e.target.name == 'cuffSize' || e.target.name == 'borderSize' || e.target.name == 'collarSize' || e.target.name == 'ribSizeExtra1' || e.target.name == 'ribSizeExtra2' || e.target.name == 'addQty') {
            // if (e.target.value.length == 2) {  // && value[e.target.name+'length'] < 3
            //   value[e.target.name] = e.target.value + '*'
            // }
            if (value['cuffSize'] != '') {
              let val = value['cuffSize'].split('*')
              total += val[0] * val[1]
            }
            if (value['borderSize'] != '') {
              let val = value['borderSize'].split('*')
              total += val[0] * val[1]
            }
            if (value['collarSize'] != '') {
              let val = value['collarSize'].split('*')
              total += val[0] * val[1]
            }
            if (value['ribSizeExtra1'] != '') {
              let val = value['ribSizeExtra1'].split('*')
              total += val[0] * val[1]
            }
            if (value['ribSizeExtra2'] != '') {
              let val = value['ribSizeExtra2'].split('*')
              total += val[0] * val[1]
            }
            if (e.target.name == 'cuffSize' || e.target.name == 'borderSize' || e.target.name == 'collarSize' || e.target.name == 'ribSizeExtra1' || e.target.name == 'addQty' || e.target.name == 'ribSizeExtra2') {
              let dvideTotal = total * 0.47 * 1.08
              let percent = dvideTotal / 1000
              let addQYt = value['addQty'] ? parseFloat(value['addQty']) : 0
              let QttyOrder = ((addQYt * data.rows[key]['order qtty']) / 100) + data.rows[key]['order qtty']
              value['totalNeedKg'] = Math.ceil(parseFloat(QttyOrder) * percent)
              value['finalNeed'] = value['totalNeedKg'] - value['inHouse']
              data.rows[key]['Final need'] = value['totalNeedKg'] - value['inHouse']
            }
          }
          if (e.target.name === 'recdStatus') {
            value['pendingStatus'] = (value['finalNeed'] - e.target.value)
          }
        }
      }
      return value
    })
    this.setState({
      rows: row
    })
    var dataRows = data.rows.map((value, key) => {
      if (materialList.length)
        this.valueUpdate(value, key, rows, rowId, suppliers, materialList)
      else
        this.valueUpdate(value, key, rows, rowId, suppliers, materialSet)
      return value;
    })
    this.setState({
      data: {
        ...this.state.data,
        rows: dataRows
      }
    })
    this.props.getInhouseStocks({
      materialId: rows[rowId].materialId,
      key: rowId,
      quantity: rows[rowId].totalNeedKg ? rows[rowId].totalNeedKg : 0,
      inhouseId: rows[rowId].newStock,
      oldQuantity: rows[rowId].inHouse,
      orderid: rows[rowId].orderid ? rows[rowId].orderid : undefined
    })
  }
  onEdit = (event, rowId) => {
    let { data, rows, supllierDetail,suppliers } = this.state
    let material = []
    var row = data.rows.map((value, key) => {
      supllierDetail&&supllierDetail.map((val, keys) => {
        if(keys === rowId && val !==null){
        if (val.id === parseInt(rows[key]['supplier'])) {
          material[0] = val.suppliermaterial 
        }}
      })
      this.valueUpdate(value, key, rows, rowId, suppliers, material)
      return value;
    })
    var rowList = rows.map((val, key) => {
      if (key === rowId) {
        val['updateClass'] = 'editRow'
      }
      return val
    })
    this.setState({
      data: {
        ...this.state.data,
        rows: row
      },
      materialList:material,
      rows: rowList
    })
  }
  onDelete = (event, rowId) => {
    const { slug, brandOrderId, pageNumber } = this.state
    let rows = this.state.data.rows
    let row = []
    rows.map((value, key) => {
      if (key !== rowId) {
        if (key >= rowId + 1) {
          let poSucc = this.state.rows[key]['addQty'] !== '' && this.state.rows[key]['totalNeedKg'] !== '' && this.state.rows[key]['materialId'] !== '' && this.state.rows[key]['supplierId'] !== '' && this.state.rows[key]['deliveryDate'] !== '' ? false : true
          value['Sr No'] = this.state.data.rows[key]['Sr No'] - 1
          value['Action'] = [
            <div className="d-flex"><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key - 1))} key={key}></i>
              <i title="Add Row" className="fas fa-plus-circle cursor-pointer" onClick={e => (this.onAdd(e, key - 1))} key={'abc' + key}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, value.id, poSucc))} ></i><input type="checkbox" value={value.id} name="poGenrate" disabled={poSucc} key={'check' + key} onChange={e => (this.listPoGenrate(e, key))} style={{ minWidth: '15px', marginLeft: '5px' }} /></div>
          ]
        }
        row.push(value);
      }
      return true
    })

    let updatedRow = this.state.rows
    let data1 = []

    updatedRow && updatedRow.map((value, key) => {
      if (key !== rowId) {
        data1.push(value);
      } return true;
    })
    this.setState({
      data: {
        ...this.state.data,
        rows: row
      },
      loaderTable: true,
      rows: data1
    })
    this.props.getRibDhaagaOrder({ brandOrderId, pageNumber: pageNumber, slug: slug[2] })
  }
  onAdd = (event, rowId) => {
    let rows = this.state.data.rows
    let row = []
    rows.map((value, key) => {
      var abc = {}
      value['Action'] = [<div className="d-flex"><i title="Edit" className="disabledAction fas fa-pen pr-2 cursor-pointer" key={'abc' + key}></i><i title="Add Row" className="disabledAction fas fa-plus-circle cursor-pointer" key={'abcd' + key}></i><i key={'abcr' + key} title="View" className="disabledAction fas fa-eye cursor-pointer" ></i><input style={{ minWidth: '15px', marginLeft: '5px' }} type="checkbox" value={this.state.rows[key]['orderDescId']} name="poGenrate" disabled key={key} onChange={e => (this.listPoGenrate(e, key))} /></div>]
      if (key === rowId) {
        abc['Action'] = [<i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key + 1))} key={'abc' + key}></i>, <i title="Delete" className="fas fa-minus-circle cursor-pointer" onClick={e => (this.onDelete(e, key + 1))} key={'abcu' + key}></i>]
        abc['Sr No'] = this.state.data.rows[key]['Sr No'] + 1
        abc['Style No'] = value['Style No']
        abc['Style Code'] = value['Style Code']
        abc['order qtty'] = value['order qtty']
        abc['Supplier'] = ''
        abc['Dhaaga Specification'] = ''
        abc['add qtty'] = ''
        abc['Cuff size'] = ''
        abc['Border size'] = ''
        abc['Collar size'] = ''
        abc['(1)Rib size'] = ''
        abc['(2)Rib size'] = ''
        abc['kora dhaaga need (in KG)'] = ''
        abc['inHouse'] = ''
        abc['Final need'] = ''
        abc['price'] = ''
        abc['Delivery'] = ''
        abc['Remarks'] = ''
        abc['po status'] = <>{value.poStatus === 'Generated' || value.poStatus === 'Regenerated' ? <i class="fas fa-check" title={value.poStatus} style={{ color: '#4dba4f' }}></i> : <><i style={{ color: '#f15d5d' }} class="far fa-clock" title='Pending'></i></>}</>
        abc['recd status'] = ''
        abc['pending status'] = ''
      }
      if (key >= rowId + 1) {
        abc = {}
        let poSucc = this.state.rows[key]['addQty'] !== '' && this.state.rows[key]['totalNeedKg'] !== '' && this.state.rows[key]['materialId'] !== '' && this.state.rows[key]['supplierId'] !== '' && this.state.rows[key]['deliveryDate'] !== '' ? false : true
        value['Sr No'] = this.state.data.rows[key]['Sr No'] + 1
        value['Action'] = [<div className="d-flex"><i title="Edit" className="disabledAction fas fa-pen pr-2 cursor-pointer" key={'abc' + key}></i><i title="Add Row" className="disabledAction fas fa-plus-circle cursor-pointer" key={'abcd' + key}></i><i key={'abcr' + key} title="View" className="disabledAction fas fa-eye cursor-pointer" ></i><input style={{ minWidth: '15px', marginLeft: '5px' }} type="checkbox" value={this.state.rows[key]['orderDescId']} name="poGenrate" disabled key={key} onChange={e => (this.listPoGenrate(e, key))} /></div>]
      }
      row.push(value);
      if (abc && Object.keys(abc).length)
        row.push(abc);
    })
    let updatedRow = this.state.rows
    let data1 = []
    updatedRow && updatedRow.map((value, key) => {
      var abc = {}
      if (key == rowId) {
        abc.orderDescId = value.orderDescId
        abc.mainFabricOrderId = value.mainFabricOrderId
        abc.addQty = value.addQty
        abc.cuffSize = ''
        abc.borderSize = ''
        abc.collarSize = ''
        abc.ribSizeExtra1 = ''
        abc.ribSizeExtra2 = ''
        abc.totalNeedKg = null
        abc.inHouse = ''
        abc.finalNeed = ''
        abc.materialId = ''
        abc.supplier = ''
        abc.price = ''
        abc.deliveryDate = ''
        abc.remarks = ''
        abc.poStatus = 'Pending'
        abc.recdStatus = ''
        abc.pendingStatus = ''
      }
      data1.push(value);
      if (abc && Object.keys(abc).length)
        data1.push(abc);
    })
    this.setState({
      data: {
        ...this.state.data,
        rows: row
      },
      rows: data1
    })
  }
  componentWillReceiveProps(newProps) {
    const { brandOrderId, pageNumber, search, slug } = this.state
    const { supplierList, poGenrateSuccess,noteSuccess } = newProps
    if (noteSuccess && noteSuccess.success === true) {
      const data = { slug: slug[2], pageNumber, brandOrderId, search }
      newProps.getRibDhaagaOrder(data)
      this.setState({
        open: false,
        loaderTable: true,
      })
    }
    let dataBrand
    if (newProps.brandorder && newProps.brandorder.success) {
      let brand = newProps.brandorder.order
      this.setState({
        brand: brand && brand.brandsId && brand.brandsId.name,
        brandImg: brand && brand.brandsId && brand.brandsId.logo,
        name: brand && brand.name
      })
    } else if (newProps.brandorder && newProps.brandorder.error) {
      this.setState({
        loader: false
      })
    }
    if (supplierList && supplierList.success) {
      this.setState({
        suppliers: supplierList && supplierList.suppliers
      })
    }
    if (poGenrateSuccess && poGenrateSuccess.success === true) {
      if (poGenrateSuccess.message) {
        if (!toast.isActive(toastId))
          toastId = toast.success(poGenrateSuccess.message)
      }
      this.setState({
        loaderPo: false,
        selectPoList: { id: [] }
      })
      this.props.getRibDhaagaOrder({ brandOrderId, pageNumber, slug: slug[2], search })
      this.props.initialState();
    } if (poGenrateSuccess && poGenrateSuccess.success === false) {
      if (poGenrateSuccess.message) {
        if (!toast.isActive(toastId))
          toastId = toast.error(poGenrateSuccess.message)
      }
      this.setState({
        loaderPo: false
      })
      this.props.initialState();
    }
    if (newProps.inhousestock && (newProps.inhousestock.message || newProps.inhousestock.success)) {
      if (newProps.inhousestock.success) {
        var inHouse = newProps.inhousestock && newProps.inhousestock.inhouse
        let dataa = this.state.data.rows.map((val, keys) => {
          if (keys === newProps.inhousestock.key) {
            val.inHouse = newProps.inhousestock.quantity
            val['Final need'] = val['kora dhaaga need (in KG)'] - val.inHouse
            newProps.initialState();
          }
          return val
        })
        let data1 = this.state.rows.map((val, keys) => {
          if (keys === newProps.inhousestock.key) {
            val.inHouse = newProps.inhousestock.quantity
            val.finalNeed = val.totalNeedKg - val.inHouse
            val.newStock = inHouse && inHouse.id
            newProps.initialState();
          }
          return val
        })
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            rows: dataa
          },
          rows: data1,
          inHouse: inHouse
        })
      } else {
        var inHouse = newProps.inhousestock && newProps.inhousestock.inhouse
        let dataa = this.state.data.rows.map((val, keys) => {
          if (keys == newProps.inhousestock.key) {
            val.inHouse = 0
            val['Final need'] = val['kora dhaaga need (in KG)'] - val.inHouse
          }
          return val
        })
        let data1 = this.state.rows.map((val, keys) => {
          if (keys == newProps.inhousestock.key) {
            val.inHouse = 0
            val.finalNeed = val.totalNeedKg - val.inHouse
            val.oldStock = val.newStock ? val.newStock : null
            val.newStock = undefined
          }
          return val
        })
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            rows: dataa
          },
          rows: data1,
          inHouse: inHouse
        })
        this.props.initialState();
      }
    } else {
      if (newProps.riborder && newProps.riborder.success) {
        let data = newProps.riborder.order.length && newProps.riborder.order.map((value, key) => {
          return ({
            'mainFabricOrderId': value.ribWithMain && value.ribWithMain.id,
            'orderDescId': value.orderDescId,
            'orderid': value.id,
            'addQty': value.addQty != null ? value.addQty : '',
            'cuffSize': value.cuffSize != null ? value.cuffSize : '',
            'borderSize': value.borderSize != null ? value.borderSize : '',
            'collarSize': value.collarSize != null ? value.collarSize : '',
            'ribSizeExtra1': value.ribSizeExtra1 != null ? value.ribSizeExtra1 : '',
            'ribSizeExtra2': value.ribSizeExtra2 != null ? value.ribSizeExtra2 : '',
            'totalNeedKg': value.totalNeedKg != null ? value.totalNeedKg : '',
            'inHouse': value.inHouse != null ? value.inHouse : '',
            'finalNeed': value.finalNeed != null ? value.finalNeed : '',
            'materialId': value.materialId != null ? value.materialId : '',
            'supplier': value.supplierId != null ? value.supplierId : '',
            'supplierText': value.ribSupllier != null ? value.ribSupllier.companyName : '',
            'price': value.price != null ? value.price : '',
            'deliveryDate': value.deliveryDate != null ? value.deliveryDate : '',
            'remarks': value.remarks != null ? value.remarks : '',
            'poStatus': value.poStatus != null ? value.poStatus : 'Pending',
            'recdStatus': value.recdStatus != null ? value.recdStatus : '',
            'pendingStatus': value.pendingStatus != null ? value.pendingStatus : '',
            newStock: value.inhouseId != null ? value.inhouseId : undefined,
            note: value.notes != null ? value.notes : ''
          })
        })
        let supllierDetail = []
        let data1 = newProps.riborder.order.length && newProps.riborder.order.map((value, key) => {
          let orderQttu = value.ribDesc.xs + value.ribDesc.s + value.ribDesc.m + value.ribDesc.l + value.ribDesc.xl + value.ribDesc.xxl + value.ribDesc['3xl'] + value.ribDesc['4xl'] + value.ribDesc['5xl']
          supllierDetail.push(value.ribSupllier)
          let poSucc = value.addQty !== null && value.totalNeedKg !== null && value.materialId !== null && value.supplierId !== null && value.deliveryDate !== null ? false : true
          return ({
            'Action': [<div style={{ display: 'flex' }}>{sessionStorage.userROLE == 'super-admin' || value.poStatus === 'Pending' || value.poStatus === null ? <><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i> <i title="Add Row" className="fas fa-plus-circle cursor-pointer" onClick={e => (this.onAdd(e, key))} key={'abcd' + key}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, value.id, poSucc))} ></i><input style={{ minWidth: '15px', marginLeft: '5px' }} type="checkbox" value={value.id} name="poGenrate" disabled={poSucc} key={key} title={poSucc === true ? 'Fill the row to generate PO.' : ''} onChange={e => (this.listPoGenrate(e, key))} /></> : <><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i> <i title="Add Row" className="fas fa-plus-circle cursor-pointer" onClick={e => (this.onAdd(e, key))} key={'abcd' + key}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, value.id, poSucc))} ></i></>}</div>],
            'Sr No': ((this.state.pageNumber - 1) * 10) + (key + 1),
            'Style No': value.ribDesc.styleno,
            'Style Code': [<span className="tooltip1" key={'abcdZ' + key}>{value.ribDesc.stylecode !== null ? (value.ribDesc.stylecode.length > 5 ? value.ribDesc.stylecode.substr(value.ribDesc.stylecode.length - 5) : value.ribDesc.stylecode) : value.ribDesc.stylecode}<span className="tooltiptext">{value.ribDesc.stylecode}</span></span>],
            'order qtty': orderQttu,
            'Supplier': [<span className="tooltip1" key={'abcdeZ' + key} >{(value.ribSupllier && value.ribSupllier.id) ? (value.ribSupllier.companyName.length > 5 ? value.ribSupllier.companyName.substr(0, 5) : value.ribSupllier.companyName) : ''}<span className="tooltiptext">{value.ribSupllier && value.ribSupllier.companyName ? value.ribSupllier.companyName : ''}</span></span>],
            'Dhaaga Specification': [<span className="tooltip1" key={'abcdeZ' + key} >{(value.ribMaterial && value.ribMaterial.id) ? (value.ribMaterial.name.length > 5 ? value.ribMaterial.name.substr(0, 5) : value.ribMaterial.name) : ''}<span className="tooltiptext">{value.ribMaterial && value.ribMaterial.name ? value.ribMaterial.name : ''}</span></span>],
            'add qtty': value.addQty != null ? value.addQty : '',
            'Cuff size': value.cuffSize != null ? value.cuffSize : '',
            'Border size': value.borderSize != null ? value.borderSize : '',
            'Collar size': value.collarSize != null ? value.collarSize : '',
            '(1)Rib size': value.ribSizeExtra1 != null ? value.ribSizeExtra1 : '',
            '(2)Rib size': value.ribSizeExtra2 != null ? value.ribSizeExtra2 : '',
            'kora dhaaga need (in KG)': value.totalNeedKg != null ? value.totalNeedKg : '',
            'inHouse': value.inHouse != null ? value.inHouse : '',
            'Final need': value.finalNeed != null ? value.finalNeed : 0,
            'price': value.price != null ? value.price : '',
            'Delivery': value.deliveryDate != null ? moment(value.deliveryDate).format('DD/MM/YY') : '',
            'Remarks': [<span className="tooltip1" key={'abcd' + key}>{value.remarks !== null && value.remarks ? (value.remarks.length > 5 ? value.remarks.substr(0, 5) : value.remarks) : value.remarks}<span className="tooltiptext">{value.remarks}</span></span>],
            'po status': value.poStatus != null ? <>{value.poStatus === 'Generated' || value.poStatus === 'Regenerated' ? <i class="fas fa-check" title={value.poStatus} style={{ color: '#4dba4f' }}></i> : <><i style={{ color: '#f15d5d' }} class="far fa-clock" title='Pending'></i></>}</> : <><i style={{ color: '#f15d5d' }} class="far fa-clock" title='Pending'></i></>,
            'recd status': value.recdStatus != null ? value.recdStatus : '',
            'pending status': value.pendingStatus != null ? value.pendingStatus : ''
          })
        })
          this.setState({
            data: {
              ...this.state.data,
              rows: data1
            },
            supllierDetail: supllierDetail,
            rows: data,
            loaderTable: false,
            total: newProps.riborder.page
          })
      } else {
        if(newProps.riborder && newProps.riborder.success === false){
          this.setState({
            loaderTable: false
          })
        }
      }
    }
  }
  preview = (e, rowId, id, poSucc) => {
    const { data, supllierDetail, rows } = this.state
    let poTable = [{}]
    let poSupplierDetail = []
    let orderId, poStatus, noteGetRow
    supllierDetail.map((items, key) => {
      if (rowId == key) {
        poSupplierDetail = items
      }
    })
    rows.map((item, key) => {
      if (rowId == key) {
        orderId = rows[rowId]['orderid']
        poStatus = rows[rowId]['poStatus']
        noteGetRow = rows[rowId]['note']
      }
    })
    data.rows.map((value, key) => {
      if (rowId == key) {
        poTable[0]['Style No'] = data.rows[key]['Style No']
        poTable[0]['Final need'] = data.rows[key]['Final need']
        poTable[0]['Dhaaga Specification'] = data.rows[key]['Dhaaga Specification']
        poTable[0]['Supplier'] = data.rows[key]['Supplier']
        poTable[0]['price'] = data.rows[key]['price']
        poTable[0]['Delivery'] = data.rows[key]['Delivery']
        poTable[0]['Remarks'] = data.rows[key]['Remarks']
      }
      return true;
    })
    if (poSupplierDetail !== null) {
      this.setState({
        previewData: {
          columns: [
            { label: 'Style No', field: 'Style No', class: 'colum_width' },
            { label: 'Final need', field: 'Final need', class: 'colum_width' },
            { label: 'Dhaaga Specification', field: 'Dhaaga Specification', class: '' },
            { label: 'Supplier', field: 'Supplier', class: '' },
            { label: 'price', field: 'price', class: '' },
            { label: "Delivery", field: "Delivery", class: '' },
            { label: 'Remarks', field: 'Remarks', class: '' },
          ],
          rows: poTable
        },
        previewDataId: id,
        open: true,
        noteGetRow:noteGetRow,
        orderIdPo: orderId,
        poSupplierDetail: poSupplierDetail,
        poStatus: poStatus,
        poSucc: poSucc
      })
    }
  }
  previewClose = () => {
    this.setState({
      open: false
    })
    // this.props.noteinitialState()
  }
  poGenerate = (e, rowId) => {
    const { selectPoList } = this.state
    this.props.koraPo(selectPoList)
    this.setState({
      loaderPo: true,
      loaderTable: true
    })
  }
  POSingle = (e, rowId) => {
    let selectPoList = {
      id: [rowId.toString(), null]
    }
    this.setState({
      ...this.state,
      loaderPo: true,
      loaderTable: true,
      open: false
    })
    this.props.koraPo(selectPoList)
  }
  listPoGenrate = (e, key) => {
    const { selectPoList } = this.state
    let index;
    if (e.target.checked) {
      this.setState({
        selectPoList: { ...selectPoList, id: [...selectPoList.id, e.target.value] }
      })
    } else {
      index = selectPoList.id.indexOf(e.target.value)
      selectPoList.id.splice(index, 1)
      this.setState({
        selectPoList: { ...selectPoList, id: [...selectPoList.id] }
      })
    }
  }
  searchChange = (e) => {
    const { brandOrderId, pageNumber, slug } = this.state
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        rows: []
      },
      rows: [],
      search: e.target.value
    })
    if (e.target.value !== '')
      this.props.getRibDhaagaOrder({ brandOrderId, pageNumber: 1, slug: slug[2], search: e.target.value })
    else
      this.props.getRibDhaagaOrder({ brandOrderId, pageNumber: pageNumber, slug: slug[2], search: e.target.value })
  }
  changePage = (e, pageNumber) => {
    const { brandOrderId, slug, search } = this.state
    this.setState({
      ...this.state,
      pageNumber: pageNumber,
      loaderTable: true,
      selectPoList: { id: [] }
    })
    this.props.getRibDhaagaOrder({ brandOrderId, pageNumber: pageNumber, slug: slug[2], search })
  }
  componentWillUnmount() {
    this.props.initialState()
  }
  render() {
    const { previewData, open, poStatus, name, previewDataId, poSupplierDetail, suppliers, loaderPo, loaderTable, selectPoList, orderIdPo, poSucc ,noteGetRow} = this.state
    return (
      <>
        <ToastContainer autoClose={10000} />
        {
          !loaderTable ?
            <>
              <Header />
              <Container className="mt-0" fluid>
                <Row>
                  <div className="col">
                    <Card className="border-0">
                      <CardHeader className="border-0 pt-0">
                        <h1 className="m-0 text-capitalize">Kora Yarn</h1>
                        <Row className="align-items-center">
                          <div className="col-md-8 d-flex pl-0">
                            <div style={{ paddingBottom: '5px', marginRight: '25px' }}>
                              <h6 className="text-uppercase text-light ls-1 mb-1">
                                Brand
                              </h6>
                              <h3 className="mb-0 profile_sec text-capitalize">
                                {
                                  (this.state.brandImg !== '' && this.state.brandImg !== null) ?
                                    <img className="mr-3" src={`${imagerUrl}` + this.state.brandImg} width="40" alt="brand"></img>
                                    : <img className="mr-3" src={'/logo.png'} width="40" alt="brand"></img>
                                }
                                {this.state.brand}
                              </h3>
                            </div>
                            <div style={{ paddingBottom: '5px' }}>
                              <h6 className="text-uppercase text-light ls-1 mb-1">
                                Order name
                              </h6>
                              <h2 className="mb-0">
                                {name}
                              </h2>
                            </div>
                          </div>
                          <div className="col-md-4 justify-contant-flex-xs-start pl-0 text-right d-flex justify-contant-flex-end">
                            <Button style={{ marginRight: '20px' }} type="submit" className="btn btn-primary" disabled={loaderPo === true || selectPoList.id == undefined || selectPoList.id[0] == null ? true : false} color="primary" onClick={this.poGenerate}>{
                              loaderPo === true ?
                                <Spinner /> :
                                'Generate PO'
                            }</Button>
                            <Nav className="justify-content-end" pills>
                              <NavItem>
                                <NavLink className="btn btn-primary " to='/ribdhaaga/kora_dhaaga' tag={Link}  >Back</NavLink>
                              </NavItem>
                            </Nav>


                          </div>
                        </Row>
                      </CardHeader>
                      <div className="table-responsive">
                        <EditTable searchChange={this.searchChange} changePage={this.changePage} {...this.state} />
                      </div>
                    </Card>
                  </div>
                </Row>
                <PreviewModal poStatus={poStatus} previewData={previewData} toggle={this.previewClose} open={open} liningsupplier={suppliers} previewDataId={previewDataId} poSupplierDetail={poSupplierDetail} orderIdPo={orderIdPo} POSingle={this.POSingle} loaderPo={loaderPo} poSucc={poSucc} noteGetRow={noteGetRow}/>
              </Container>
            </>
            :
            <div className="loader">
              <Spinner variant="light" />
              <p style={{ color: '#fff', paddingTop: '15px' }}>Please wait......</p>
            </div>
        }
      </>
    )
  }
}
const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    getBrandOrder,
    ribDhaagaOrder,
    getRibDhaagaOrder,
    ribSupplier,
    initialState,
    koraPo,
    noteinitialState,
    getInhouseStocks
  }, dispatch)
}
const mapGetStateNew = (state) => {
  return {
    brandorder: state.order.brandorder,
    inhousestock: state.order.inhousestock,
    riborder: state.order.riborder,
    poGenrateSuccess: state.poPDF.poGenrate,
    supplierList: state.supplier.supplier,
    noteSuccess: state.order.note
  }
}
export default connect(mapGetStateNew, mapDispatchState)(Mainribdhaaga);