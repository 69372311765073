import { call, put, takeLatest, all } from "redux-saga/effects";
import {
  SET_MATERIAL_SUCCESS,
  SET_MATERIAL_FAILED,
  GET_MATERIAL,
  GET_MATERIAL_ADD,
  ADD_MATERIAL_SUCCESS,
  ADD_MATERIAL_FAILED,
  UPDATE_MATERIAL_FAILED,
  VIEW_MATERIAL_SUCCESS,
  VIEW_MATERIAL_FAILED,
  GET_MATERIAL_DELETE,
  GET_MATERIAL_UPDATE,
  GET_MATERIAL_VIEW,
  DELETE_MATERIAL_SUCCESS,
  DELETE_MATERIAL_FAILED,
  GET_POLYFILL_MATERIAL,
  SET_POLYFILL_MATERIAL_SUCCESS,
  SET_POLYFILL_MATERIAL_FAILED,
  GET_CATEGORY_MATERIAL,
  GET_CATEGORY_MATERIAL_SUCCESS,
  GET_CATEGORY_MATERIAL_FAILED,
  GET_SUB_CATEGORY_MATERIAL,
  GET_SUB_CATEGORY_MATERIAL_SUCCESS,
  GET_SUB_CATEGORY_MATERIAL_FAILED,
  GET_IMAGE_MATERIAL_DELETE,
  GET_IMAGE_MATERIAL_DELETE_SUCCESS,
  GET_IMAGE_MATERIAL_DELETE_FAILED,
} from "../../sharedFile/actionType";
import Api from "../../lib/api";

function getMaterialListApi(action) {
  return Api.get(
    `material/`,
  );
}
function* getMaterialList(action) {
  try {
    const resp = yield call(getMaterialListApi, action);
    if (resp.success === true) {
      yield put({ type: SET_MATERIAL_SUCCESS, data: resp })
    } else {
      yield put({ type: SET_MATERIAL_FAILED, error: 'Something Went Wrong' })
    }
  } catch (resp) {
    yield put({ type: SET_MATERIAL_FAILED, error: 'Something Went Wrong' })
  }
}



function addMaterialApi(action) {
  return Api.post(
    `material/`,
    action.data
  );
}
function* addMaterial(action) {
  try {
    const resp = yield call(addMaterialApi, action);
    if (resp.success === true) {
      yield put({ type: ADD_MATERIAL_SUCCESS, data: resp })
      yield put({ type: GET_MATERIAL })
    } else {
      yield put({ type: ADD_MATERIAL_FAILED, error: resp })
    }
  } catch (resp) {
    yield put({ type: ADD_MATERIAL_FAILED, error: resp })
  }
}



function viewMaterialApi(action) {
  const { data } = action
  return Api.get(
    `material/` + data
  );
}
function* viewMaterial(action) {
  try {
    const resp = yield call(viewMaterialApi, action);
    if (resp.success === true) {
      yield put({ type: VIEW_MATERIAL_SUCCESS, data: resp, redirect: false })
    } else {
      yield put({ type: VIEW_MATERIAL_FAILED, error: resp })
    }
  } catch (resp) {
    yield put({ type: VIEW_MATERIAL_FAILED, error: resp })
  }
}

function updateMaterialApi(action) {
  return Api.put(
    `material/` + action.id,
    {
      name: action.data.name,
      description: action.data.description,
      deletedImage: action.data.deletedImage,
      picture: action.data.picture
    }
  );
}
function* updateMaterial(action) {
  try {
    const resp = yield call(updateMaterialApi, action);
    if (resp.success === true) {
      const resp1 = yield call(viewMaterialApi, { data: action.id });
      if (resp1.success === true) {
        resp1.message = resp.message
        yield put({ type: VIEW_MATERIAL_SUCCESS, data: resp1, redirect: true })
        yield put({ type: GET_MATERIAL })
      } else {
        yield put({ type: VIEW_MATERIAL_FAILED, error: resp1.message })
      }
    } else {
      yield put({ type: UPDATE_MATERIAL_FAILED, error: resp })
    }
  } catch (resp) {
    yield put({ type: UPDATE_MATERIAL_FAILED, error: resp })
  }
}


function deleteMaterialApi(action) {
  const { data } = action
  return Api.delete(
    `material/` + data
  );
}
function* deleteMaterial(action) {
  try {
    const resp = yield call(deleteMaterialApi, action);
    if (resp.success === true) {
      yield put({ type: DELETE_MATERIAL_SUCCESS, data: resp })
    } else {
      yield put({ type: DELETE_MATERIAL_FAILED, error: resp })
    }
  } catch (resp) {
    yield put({ type: DELETE_MATERIAL_FAILED, error: resp })
  }
}


function getPolyfillMaterialListApi(action) {
  return Api.get(
    `material/polyfill/material`,
  );
}
function* getPolyfillMaterialList(action) {
  try {
    const resp = yield call(getPolyfillMaterialListApi, action);
    if (resp.success === true) {
      yield put({ type: SET_POLYFILL_MATERIAL_SUCCESS, data: resp })
    } else {
      yield put({ type: SET_POLYFILL_MATERIAL_FAILED, error: 'Something Went Wrong' })
    }
  } catch (resp) {
    yield put({ type: SET_POLYFILL_MATERIAL_FAILED, error: 'Something Went Wrong' })
  }
}

function getCatMaterialListApi(action) {
  return Api.get(
    `material/category/` + action.id,
  );
}
function* getCatMaterialList(action) {
  try {
    const resp = yield call(getCatMaterialListApi, action);
    if (resp.success === true) {
      yield put({ type: GET_CATEGORY_MATERIAL_SUCCESS, data: resp })
    } else {
      yield put({ type: GET_CATEGORY_MATERIAL_FAILED, error: 'Something Went Wrong' })
    }
  } catch (resp) {
    yield put({ type: GET_CATEGORY_MATERIAL_FAILED, error: 'Something Went Wrong' })
  }
}
function subCategoryMaterialApi(action) {
  return Api.get(
    `subcategory/material/` + action.id,
  );
}
function* subCategoryMaterial(action) {
  try {
    const resp = yield call(subCategoryMaterialApi, action);
    if (resp.success === true) {
      yield put({ type: GET_SUB_CATEGORY_MATERIAL_SUCCESS, data: resp })
    } else {
      yield put({ type: GET_SUB_CATEGORY_MATERIAL_FAILED, error: 'Something Went Wrong' })
    }
  } catch (resp) {
    yield put({ type: GET_SUB_CATEGORY_MATERIAL_FAILED, error: 'Something Went Wrong' })
  }
}
// material Image Delete

function deleteImageMaterialApi(action) {
  return Api.delete(
    `material/deleteMedia/` + action.id,
  );
}
function* deleteImageMaterial(action) {
  try {
    const resp = yield call(deleteImageMaterialApi, action);
    if (resp.success === true) {
      yield put({ type: VIEW_MATERIAL_SUCCESS, data: resp })
      yield put({ type: GET_MATERIAL })
    } else {
      yield put({ type: GET_IMAGE_MATERIAL_DELETE_FAILED, error: resp })
    }
  } catch (resp) {
    yield put({ type: GET_IMAGE_MATERIAL_DELETE_FAILED, error: resp })
  }
}
function* material() {
  yield all([
    takeLatest(GET_MATERIAL, getMaterialList),
    takeLatest(GET_MATERIAL_ADD, addMaterial),
    takeLatest(GET_MATERIAL_UPDATE, updateMaterial),
    takeLatest(GET_MATERIAL_VIEW, viewMaterial),
    takeLatest(GET_MATERIAL_DELETE, deleteMaterial),
    takeLatest(GET_POLYFILL_MATERIAL, getPolyfillMaterialList),
    takeLatest(GET_CATEGORY_MATERIAL, getCatMaterialList),
    takeLatest(GET_SUB_CATEGORY_MATERIAL, subCategoryMaterial),
    takeLatest(GET_IMAGE_MATERIAL_DELETE, deleteImageMaterial)
  ])
}
export default material;