import React, { Component } from "react";
import Header from "../../../components/Headers/Header";
import { getBrandOrder, addMainQuiltOrder, mainorderGroup, getMainQuiltOrder, initialState, getInhouseStocks, noteinitialState } from '../../../services/actions/order';
import { bindActionCreators } from 'redux';
import { mainquiltSupplier } from '../../../services/actions/supplier';
import { connect } from 'react-redux';
import { varientBySlug, varienInitial } from '../../../services/actions/varient'
import { imagerUrl } from '../../../config/config';
import { ToastContainer, toast } from 'react-toastify';
import PreviewModal from "../../../components/previewModal";
import { quiltingPo } from '../../../services/actions/po'
import moment from 'moment'
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  NavItem,
  Nav,
  NavLink,
  Spinner
} from "reactstrap";
import { Link } from 'react-router-dom'
import EditTable from '../../../components/EditTable'
var toastId = null

class MainfabricQuiltingsheet extends React.Component {
  state = {
    data: {
      columns: [],
      rows: []
    },
    brand: '',
    brandImg: '',
    brandOrderId: this.props.match.params.id,
    suppliers: [],
    material: [],
    edit: [],
    loader: false,
    loaderVarient: true,
    loaderTable: true,
    loaderPo: false,
    poSucc: null,
    orderIdPo: '',
    errors: {},
    pageNumber: 1,
    poStatus: '',
    rows: [],
    selectPoList: {
      id: []
    },
    previewData: {
      columns: [],
      rows: []
    },
    previewDataId: null,
    open: false,
    supllierDetail: [],
    poSupplierDetail: [],
    slug: this.props.match.params.item_slug,
    slugOrder: this.props.location.pathname.split('/'),
    backPage: this.props.location.pathname,
    pageHead: '',
    noteGetRow:'',
    name: ''
  }
  componentWillUnmount() {
    this.props.initialState()
  }
  componentWillMount() {
    const { brandOrderId, pageNumber, search, slug, slugOrder } = this.state
    const dataBrand = {
      brandOrderId, pageNumber
    }
    this.props.getBrandOrder(dataBrand);
    this.props.mainquiltSupplier();
    this.props.varientBySlug(slug);
    this.setState({
      loaderVarient: true
    })
  }
  onKeyPress(evt) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }
  valueEdit = (rowsTable, rowId, key, value, rows, suppliers, varient) => {
    let currentDate = new Date();
    let getYear = currentDate.getFullYear()
    let getMonth = currentDate.getMonth() + 1
    let fullCurrentDate = getYear + '-' + (getMonth <= 9 ? '0' + getMonth : getMonth) + '-' + (currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate())
    if (key == rowId) {
      if (sessionStorage.userROLE == 'super-admin' || rows[rowId]['poStatus'] === 'Pending') {
        let data = []
        suppliers.map((val, keys) => {
          if (val.id == rows[key]['quiltingsupplier']) {
            data.push(val.suppliermaterial)
          }
        })
        varient.map(items => {
          if (items.assignedVarient === '') {
            value[items.field] = [<input className="inputFiled" type="text" name={items.name} value={rows[key][items.name]} key={items.name + key} onChange={e => (this.onChange(e, key))} />]
          } else {
            let values = items.assignedVarient.split(',')
            value[items.field] = [<select className="inputFiled" name={items.name} key={'ah' + key} defaultValue={rows[key][items.name]} onChange={e => (this.onChangeSupplier(e, key))}>
              <option>{items.name}</option>
              {values.map(itemValue => {
                return <option value={itemValue}>{itemValue}</option>
              })}
            </select>]
          }
        })
        value['Action'] = [<i className="fas fa-check pr-2 cursor-pointer" key={'abcuo' + key} onClick={e => (this.onSubmit(e, key))}></i>]
        value['Sr No'] = key + 1
        value['Style No'] = value['Style No']
        value['order qtty'] = value['order qtty']
        value['add qtty'] = value['add qtty']
        value['type'] = value['type']
        value['color'] = value['color']
        value['Quilting supplier'] = [<select className="selectList inputFiled" name="quiltingsupplier" defaultValue={rows[key]['quiltingsupplier']} onChange={e => (this.onChangeSupplier(e, key))}>
          <option>Supplier</option>
          {
            suppliers.map((val) => {
              return (<option value={val.id}>{val.companyName}</option>)
            })
          }
        </select>]
        value['quilting type'] = [<select className="selectList inputFiled" name="quiltingtype" defaultValue={rows[key]['quiltingtype']} onChange={e => (this.onChangeSupplier(e, key))}>
          <option>Type</option>
          {
            data[0] && data[0].map((val) => {
              return (<option value={val.supplyMaterial.id}>{val.supplyMaterial.name}</option>)
            })
          }
        </select>]
        value['Quilting avg'] = [<input type="text" className="avgWidth inputFiled" onKeyPress={this.onKeyPress.bind(this)} name="quiltingAvg" value={rows[key]['quiltingAvg']} onChange={e => (this.onChange(e, key))} />]
        value['total need'] = rows[key]['totalNeed']
        value['inhouse'] = rows[key]['inhouse']
        value['final need'] = rows[key]['finalNeed']
        value['price'] = [<input type="text" maxLength="8" className="inputFiled" onKeyPress={this.onKeyPress.bind(this)} name="price" value={rows[key]['price']} onChange={e => (this.onChange(e, key))} />]
        value['Delivery'] = [<input type="date" className="inputFiled" min={fullCurrentDate} name="Delivery" value={rows[key]['Delivery']} onChange={e => (this.onChange(e, key))} />]
        value['Remarks'] = [<input type="text" className="inputFiled" name="Remarks" value={rows[key]['Remarks']} onChange={e => (this.onChange(e, key))} />]
        value['po status'] = rows[key]['poStatus']
        value['recd status'] = [<input type="text" className="inputFiled" onKeyPress={this.onKeyPress.bind(this)} name="recdStatus" value={rows[key]['recdStatus']} onChange={e => (this.onChange(e, key))} />]
        value['pending status'] = rows[key]["pendingStatus"]
        value['supplier'] = value['supplier']
      }
      else {
        value['Action'] = [<i className="fas fa-check pr-2 cursor-pointer" key={'abcuo' + key} onClick={e => (this.onSubmit(e, key))}></i>]
        value['recd status'] = [<input className="inputFiled" type="text" name="recdStatus" value={rows[key]['recdStatus']} onChange={e => (this.onChange(e, key))} />]
        value['pending status'] = rows[key]["pendingStatus"]
      }
    } else {
      value['Action'] = [<div className="d-flex"><i title="Edit" className="disabledAction fas fa-pen pr-2 cursor-pointer" key={'abc' + key}></i><i title="Add Row" className="disabledAction fas fa-plus-circle cursor-pointer" key={'abce' + key}></i><i key={'abcr' + key} title="View" className="disabledAction fas fa-eye cursor-pointer" ></i><input style={{ minWidth: '15px', marginLeft: '5px' }} type="checkbox" key={key} value={value.id} name="poGenrate" disabled /></div>]
    }
  }
  valueSubmit = (updatedRow, value, key, rowId, varient) => {
    let poSucc = updatedRow[key]['Delivery'] !== "" && updatedRow[key]['finalNeed'] !== "" && updatedRow[key]['quiltingAvg'] !== "" && updatedRow[key]['quiltingsupplier'] !== "" && updatedRow[key]['quiltingtype'] !== "" && updatedRow[key]['supplierId'] !== "" && updatedRow[key]['totalNeed'] !== "" ? false : true
    if (key == rowId) {
      varient.map(items => {
        value[items.field] = updatedRow[rowId][items.name]
      })
      value['Action'] = [<div style={{ display: 'flex' }}>{(sessionStorage.userROLE == 'super-admin' || value['po status'] === 'Pending') ? <><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i> <i title="Add Row" className="fas fa-plus-circle cursor-pointer" key={'abce' + key} onClick={e => (this.onAdd(e, key))}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, updatedRow[rowId]['orderid'], poSucc))} ></i><input style={{ minWidth: '15px', marginLeft: '5px' }} type="checkbox" value={value.id} name="poGenrate" disabled={poSucc} key={key} onChange={e => (this.listPoGenrate(e, key))} /></> : <><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i><i title="Add Row" className="fas fa-plus-circle cursor-pointer" key={'abce' + key} onClick={e => (this.onAdd(e, key))}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, updatedRow[rowId]['orderid'], poSucc))} ></i></>}</div>]
      value['Sr No'] = key + 1
      value['Style No'] = value['Style No']
      value['order qtty'] = value['order qtty']
      value['add qtty'] = value['add qtty']
      value['type'] = value['type']
      value['color'] = value['color']
      value['Quilting supplier'] = updatedRow[rowId]['quiltingsuppliertext']
      value['quilting type'] = updatedRow[rowId]['quiltingtypetext']
      value['Quilting avg'] = updatedRow[rowId]['quiltingAvg']
      value['total need'] = updatedRow[rowId]['totalNeed']
      value['inhouse'] = updatedRow[rowId]['inhouse']
      value['final need'] = updatedRow[rowId]['finalNeed']
      value['price'] = updatedRow[rowId]['price']
      value['Delivery'] = updatedRow[rowId]['Delivery']
      value['Remarks'] = updatedRow[rowId]['Remarks']
      value['po status'] = updatedRow[rowId]['poStatus']
      value['recd status'] = updatedRow[rowId]['recdStatus']
      value['pending status'] = updatedRow[rowId]['pendingStatus']
    }
  }
  onChangeSupplier = (e, rowId) => {
    var index = e.nativeEvent.target.selectedIndex;
    let { rows, suppliers, varient } = this.state
    let rowsTable = this.state.data.rows
    let data = rows.map((value, key) => {
      if (key == rowId) {
        value[e.target.name] = e.target.value
        value[e.target.name + 'text'] = e.nativeEvent.target[index].text
        if (e.target.name == 'quiltingsupplier' && e.target.value != 'Select Supplier') {
          let data = []
          suppliers.map((val, keys) => {
            if (val.id == e.target.value) {
              data.push(val.suppliermaterial)
            }
          })

          var row = rowsTable.map((value, key) => {
            this.valueEdit(rowsTable, rowId, key, value, rows, suppliers, varient)
            return value;
          })
          this.setState({
            data: {
              ...this.state.data,
              rows: row
            }
          })
        }

        if (e.target.name == 'quiltingAvg') {
          let rows = this.state.data.rows
          let updatedrows = rows && rows.map((val, key) => {
            if (key == rowId) {
              value['totalNeed'] = Math.ceil(e.target.value * value.addQty)
              val['total need'] = Math.ceil(e.target.value * value.addQty)
              value['finalNeed'] = value['totalNeed'] - value['inhouse']
              val['Final Need'] = value['totalNeed'] - value['inhouse']
            }
            return val
          })
          this.setState({
            data: {
              ...this.state.data,
              rows: updatedrows
            }
          })
        }
        var varientDetail = ''
        varient.map(items => {
          if (rows[rowId][items.name] != null) {
            varientDetail = varientDetail + items.name + ':' + rows[rowId][items.name].toLowerCase() + ',';
          }
        })
        this.props.getInhouseStocks({
          materialId: rows[rowId].quiltingtype,
          varients: varientDetail.substring(0, varientDetail.length - 1),
          key: rowId,
          quantity: rows[rowId].totalNeed ? rows[rowId].totalNeed : 0,
          inhouseId: rows[rowId].newStock,
          oldQuantity: rows[rowId].inhouse,
          orderid: rows[rowId].orderid ? rows[rowId].orderid : undefined
        })
      }
      return value
    })
    this.setState({
      rows: data
    })
  }
  onSubmit = (event, rowId) => {
    let rows = this.state.data.rows
    let updatedRow = this.state.rows
    const { pageNumber, varient, brandOrderId, slug,search } = this.state
    var row = rows.map((value, key) => {
      this.valueSubmit(updatedRow, value, key, rowId, varient)
      return value;
    })
    this.setState({
      data: {
        ...this.state.data,
        rows: row
      }
    })
    let data = []
    updatedRow.map((val, key) => {
      if (key === rowId) {
        let datainner = []
        datainner['quiltingvarients'] = []
        varient.map((items, i) => {
          datainner['quiltingvarients'].push({ id: items.id, value: val[items.name] !== '' ? val[items.name] : null })
          return true;
        })
        data.push({
          "FabricOrderId": val.FabricOrderId && val.FabricOrderId != '' ? val.FabricOrderId : null,
          "brandOrderId": brandOrderId != '' ? brandOrderId : null,
          "orderDescId": val.orderDescId && val.orderDescId != '' ? val.orderDescId : null,
          "subCategoryId": null,
          "quiltingAverage": val.quiltingAvg && val.quiltingAvg != '' ? val.quiltingAvg : null,
          "materialId": val.quiltingtype != '' ? val.quiltingtype : null,
          ...datainner,
          "supplierId": val.quiltingsupplier != '' ? val.quiltingsupplier : null,
          "totalNeed": val.totalNeed != '' ? val.totalNeed : null,
          "inHouse": val.inhouse != '' ? val.inhouse : null,
          "finalNeed": val.finalNeed != '' ? val.finalNeed : null,
          "price": val.price != '' ? val.price : null,
          "deliveryDate": val.Delivery != '' ? val.Delivery : null,
          "remarks": val.Remarks != '' ? val.Remarks : null,
          "recdStatus": val.recdStatus != '' ? val.recdStatus : null,
          "pendingStatus": val.pendingStatus,
          orderid: val.orderid ? val.orderid : undefined,
          oldStock: val.oldStock !== '' ? val.oldStock : null,
          newStock: val.newStock !== '' ? val.newStock : null,
        })
      }
    })
    this.props.addMainQuiltOrder({ brandOrderId: brandOrderId, data: data, pageNumber, slug,search })
  }
  onChange = (e, rowId) => {
    let { rows, data, suppliers, varient } = this.state
    let percentage = data && data.rows && data.rows[rowId] && data.rows[rowId]['add qtty']
    let dataValue = rows.map((value, key) => {
      if (key == rowId) {
        value[e.target.name] = e.target.value
        if (e.target.name == 'quiltingAvg') {
          let percent = value.addQty.substring(0, value.addQty.length - 1)
          let total = Math.ceil(this.state.data.rows[key]['order qtty'] + ((percent / 100) * this.state.data.rows[key]['order qtty']))
          value['totalNeed'] = Math.ceil(e.target.value * total)
          data.rows[rowId]['total need'] = Math.ceil(e.target.value * total)
          value['finalNeed'] = value['totalNeed'] - value['inhouse']
          data.rows[rowId]['final need'] = value['totalNeed'] - value['inhouse']
        }
        if (e.target.name === 'recdStatus') {
          value['pendingStatus'] = (value['finalNeed'] - e.target.value)
        }
      }
      return value
    })
    this.setState({
      rows: dataValue
    })
    var row = data.rows.map((value, key) => {
      let data1 = []
      suppliers.map((val, keys) => {
        if (val.id == rows[key]['quiltingsupplier']) {
          data1.push(val.suppliermaterial)
        }
      })
      this.valueEdit(data.rows, rowId, key, value, rows, suppliers, varient)
      return value;
    })
    var varientDetail = ''
    varient.map(items => {
      if (rows[rowId][items.name] != null) {
        varientDetail = varientDetail + items.name + ':' + rows[rowId][items.name].toLowerCase() + ',';
      }
    })
    this.props.getInhouseStocks({
      materialId: rows[rowId].quiltingtype,
      varients: varientDetail.substring(0, varientDetail.length - 1),
      key: rowId,
      quantity: rows[rowId].totalNeed ? rows[rowId].totalNeed : 0,
      inhouseId: rows[rowId].newStock,
      oldQuantity: rows[rowId].inhouse,
      orderid: rows[rowId].orderid ? rows[rowId].orderid : undefined
    })
    this.setState({
      data: {
        ...this.state.data,
        rows: row
      }
    })
  }
  onEdit = (event, rowId) => {
    const rowsTable = this.state.data.rows
    const { suppliers, rows, varient } = this.state
    var row = rowsTable.map((value, key) => {
      this.valueEdit(rowsTable, rowId, key, value, rows, suppliers, varient)
      return value;
    })
    var rowList = rows.map((val, key) => {
      if (key === rowId && rows[key]['totalNeedKg'] !== null) {
        val['updateClass'] = 'editRow'
      }
      return val
    })
    this.setState({
      data: {
        ...this.state.data,
        rows: row
      },
      rows: rowList
    })
  }
  onAdd = (event, rowId) => {
    let rows = this.state.data.rows
    const { varient } = this.state
    let updatedRow = this.state.rows
    let row = []
    rows.map((value, key) => {
      var abc = {}
      value['Action'] = [<div className="d-flex"><i title="Edit" className="disabledAction fas fa-pen pr-2 cursor-pointer" key={'abc' + key}></i><i title="Add Row" className="disabledAction fas fa-plus-circle cursor-pointer" key={'abce' + key}></i><i key={'abcr' + key} title="View" className="disabledAction fas fa-eye cursor-pointer" ></i><input style={{ minWidth: '15px', marginLeft: '5px' }} type="checkbox" key={key} value={value.id} name="poGenrate" disabled /></div>]
      if (key == rowId) {
        abc['Action'] = [<i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key + 1))} key={'abc' + key + 1}></i>,
        <i title="Delete" className="fas fa-minus-circle cursor-pointer" onClick={e => (this.onDelete(e, key + 1))} key={'abcio' + key + 1}></i>]
        abc['Sr No'] = this.state.data.rows[key]['Sr No'] + 1
        abc['Style No'] = value['Style No']
        abc['Style code'] = value['Style code']
        abc['order qtty'] = value['order qtty']
        abc['add qtty'] = value['add qtty']
        abc['supplier'] = value['supplier']
        abc['type'] = value['type']
        abc['color'] = value['color']
        abc['Quilting supplier'] = ''
        abc['quilting type'] = ''
        abc['Quilting avg'] = ''
        varient.map(items => {
          abc[items.field] = ''
        })
        abc['total need'] = ''
        abc['inhouse'] = ''
        abc['final need'] = ''
        abc['price'] = ''
        abc['Delivery'] = ''
        abc['Remarks'] = ''
        abc['po status'] = <>{value.poStatus === 'Generated' || value.poStatus === 'Regenerated' ? <i class="fas fa-check" title={value.poStatus} style={{ color: '#4dba4f' }}></i> : <><i style={{ color: '#f15d5d' }} class="far fa-clock" title='Pending'></i></>}</>
        abc['recd status'] = ''
        abc['pending status'] = ''
      }
      if (key >= rowId + 1) {
        abc = {}
        value['Sr No'] = this.state.data.rows[key]['Sr No'] + 1
        value['Action'] = [<div className="d-flex"><i title="Edit" className="disabledAction fas fa-pen pr-2 cursor-pointer" key={'abc' + key}></i><i title="Add Row" className="disabledAction fas fa-plus-circle cursor-pointer" key={'abce' + key}></i><i key={'abcr' + key} title="View" className="disabledAction fas fa-eye cursor-pointer" ></i><input style={{ minWidth: '15px', marginLeft: '5px' }} type="checkbox" key={key} value={value.id} name="poGenrate" disabled /></div>]
      }
      row.push(value);
      if (abc && Object.keys(abc).length)
        row.push(abc);
    })
    let data1 = []
    updatedRow && updatedRow.map((value, key) => {
      var abc = {}
      if (key == rowId) {
        abc.Delivery = ""
        abc.Remarks = ""
        abc.orderDescId = value.orderDescId
        abc.brandOrderId = value.brandOrderId
        abc.addQty = ""
        abc.FabricOrderId = value.FabricOrderId
        abc.quiltingsupplier = ''
        abc.quiltingtype = ''
        abc.quiltingAvg = ''
        abc.fabricColor = ""
        abc.fabricType = ""
        abc.finalNeed = ''
        abc.inhouse = ""
        abc.pendingStatus = ""
        abc.price = ""
        abc.poStatus = 'Pending'
        abc.recdStatus = ""
        abc.totalNeed = ''
        varient.map(items => {
          abc[items.name] = ''
        })
      }
      data1.push(value);
      if (abc && Object.keys(abc).length)
        data1.push(abc);
    })
    this.setState({
      data: {
        ...this.state.data,
        rows: row
      },
      rows: data1
    })
  }
  onDelete = (event, rowId) => {
    const { slug, brandOrderId, pageNumber } = this.state
    let rows = this.state.data.rows
    let row = []
    rows.map((value, key) => {
      if (key !== rowId) {
        if (key >= rowId + 1) {
          let poSucc = this.state.rows[key]['Delivery'] !== "" && this.state.rows[key]['finalNeed'] !== "" && this.state.rows[key]['quiltingAvg'] !== "" && this.state.rows[key]['quiltingsupplier'] !== "" && this.state.rows[key]['quiltingtype'] !== "" && this.state.rows[key]['supplierId'] !== "" && this.state.rows[key]['totalNeed'] !== "" ? false : true
          value['Sr No'] = this.state.data.rows[key]['Sr No'] - 1
          value['Action'] = [
            <div className="d-flex"><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i><i title="Add Row" className="fas fa-plus-circle cursor-pointer" key={'abce' + key} onClick={e => (this.onAdd(e, key))}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, this.state.rows[key]['orderDescId'], poSucc))} ></i><input type="checkbox" value={this.state.rows[key]['orderDescId']} name="poGenrate" disabled={poSucc} key={'check' + key} onChange={e => (this.listPoGenrate(e, key))} /></div>
          ]
        }
        row.push(value);
      }
      return true
    })

    let updatedRow = this.state.rows
    let data1 = []

    updatedRow && updatedRow.map((value, key) => {
      if (key !== rowId) {
        data1.push(value);
      } return true;
    })
    this.setState({
      data: {
        ...this.state.data,
        rows: row
      },
      rows: data1
    })
    this.props.getMainQuiltOrder({ slug, brandOrderId, pageNumber })
  }
  componentWillReceiveProps(newProps) {
    const { varientList, poGenrateSuccess, noteSuccess } = newProps
    const { brandOrderId, pageNumber, search, slug, slugOrder } = this.state
    if (noteSuccess && noteSuccess.success === true) {
      newProps.getMainQuiltOrder({ slug, brandOrderId, pageNumber })
      this.setState({
        open: false,
        loaderTable: true,
      })
    }
    var varient = []
    if (varientList.success) {
      varientList.varient && varientList.varient.map((value, key) => {
        let assignedVarient = value.assignedVarient && value.assignedVarient[0].value
        varient.push({ label: value.name, field: value.name, name: value.slug, id: value.id, assignedVarient: assignedVarient })
      })
      this.setState({
        data: {
          ...this.state.data,
          columns: [
            { label: "Action", field: "Action" },
            { label: "S.N.", field: "Sr No" },
            { label: 'Style No', field: 'Style No' },
            { label: 'Style code', field: 'Style code' },
            { label: "Order Qty", field: "order qtty", type: "numeric" },
            { label: "Add Qty %", field: "add qtty", type: "numeric" },
            { label: 'Supplier', field: 'supplier' },
            { label: 'Type', field: 'type' },
            { label: 'Color', field: 'color' },
            { label: 'Quilting Supplier', field: 'Quilting supplier', class: 'selectList' },
            { label: 'Quilting Type', field: 'quilting type', class: 'selectList' },
            { label: 'Quilting Avg', field: 'Quilting avg' },
            ...varient,
            { label: 'Total Need', field: 'total need' },
            { label: 'Inhouse', field: 'inhouse' },
            { label: 'Final Need', field: 'final need' },
            { label: 'Price', field: 'price' },
            { label: "Delivery", field: "Delivery" },
            { label: 'Remarks', field: 'Remarks' },
            { label: 'PO Status', field: 'po status' },
            { label: 'Recd Status', field: 'recd status', class: 'avgWidth' },
            { label: 'Pending Status', field: 'pending status', class: 'avgWidth' }
          ]
        },
        loaderVarient: false,
        loaderTable: true,
        varient: varient
      })
      const data = {
        slug, brandOrderId, pageNumber
      }
      const dataOrder = {
        slug: slugOrder[1], brandOrderId, pageNumber
      }
      newProps.varienInitial()
      newProps.mainorderGroup(dataOrder)
      newProps.getMainQuiltOrder(data)
    } else {
      this.setState({
        loaderVarient: false,
      })
    }
    if (poGenrateSuccess && poGenrateSuccess.success) {
      if (poGenrateSuccess.message) {
        if (!toast.isActive(toastId))
          toastId = toast.success(poGenrateSuccess.message)
      }
      this.setState({
        loaderPo: false,
        selectPoList: { id: [] }
      })
      this.props.getMainQuiltOrder({ brandOrderId, pageNumber, search, slug });
      this.props.initialState();
    } else if (poGenrateSuccess.success === false) {
      if (poGenrateSuccess.message) {
        if (!toast.isActive(toastId))
          toastId = toast.error(poGenrateSuccess.message)
      }
      this.setState({
        loaderPo: false
      })
      this.props.initialState();
    }
    if (newProps.inhousestock && (newProps.inhousestock.message || newProps.inhousestock.success)) {
      if (newProps.inhousestock.success) {
        var inhouse = newProps.inhousestock && newProps.inhousestock.inhouse
        let dataa = this.state.data.rows.map((val, keys) => {
          if (keys == newProps.inhousestock.key) {
            val.inhouse = newProps.inhousestock.quantity
            val['final need'] = val['total need'] - val.inhouse
            newProps.initialState();
          }
          return val
        })
        let data1 = this.state.rows.map((val, keys) => {
          if (keys == newProps.inhousestock.key) {
            val.inhouse = newProps.inhousestock.quantity
            val.finalNeed = val.totalNeed - val.inhouse
            val.newStock = inhouse && inhouse.id
            newProps.initialState();
          }
          return val
        })
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            rows: dataa
          },
          rows: data1,
          inhouse: inhouse
        })
        this.props.initialState();
      } else {
        this.props.initialState();
      }
      if (newProps.inhousestock.success === false) {
        var inhouse = newProps.inhousestock && newProps.inhousestock.inhouse
        let dataa = this.state.data.rows.map((val, keys) => {
          if (keys == newProps.inhousestock.key) {
            val.inhouse = 0
            val['final need'] = val['total need'] - val.inhouse
          }
          return val
        })
        let data1 = this.state.rows.map((val, keys) => {
          if (keys == newProps.inhousestock.key) {
            val.inhouse = 0
            val.finalNeed = val.totalNeed - val.inhouse
            val.oldStock = val.newStock ? val.newStock : null
            val.newStock = undefined
          }
          return val
        })
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            rows: dataa
          },
          rows: data1,
          inhouse: inhouse
        })
        this.props.initialState();
      } else {
        this.props.initialState();
      }
    } else {
      if (newProps.brandorder && newProps.brandorder.success) {
        let brand = newProps.brandorder.order
        this.setState({
          brand: brand && brand.brandsId && brand.brandsId.name,
          brandImg: brand && brand.brandsId && brand.brandsId.logo,
          name: brand && brand.name
        })
      }
      if (newProps.supplierSuccess && newProps.supplierSuccess.success) {
        this.setState({
          suppliers: newProps.supplierSuccess.suppliers
        })
      }
      if (newProps.material && newProps.material.success) {
        this.setState({
          material: newProps.material.material
        })
      }
      if (newProps.mainquiltorder && newProps.mainquiltorder.success) {
        let data = newProps.mainquiltorder && newProps.mainquiltorder.order && newProps.mainquiltorder.order.length && newProps.mainquiltorder.order.map((value, key) => {
          var orderQty = ''
          if (value.quiltingFabricOrder && value.quiltingDesc)
            orderQty = value.quiltingDesc.xs + value.quiltingDesc.s + value.quiltingDesc.m + value.quiltingDesc.l + value.quiltingDesc.xl + value.quiltingDesc.xxl + value.quiltingDesc['3xl'] + value.quiltingDesc['4xl'] + value.quiltingDesc['5xl']
          let datainner = {}
          this.state.varient.map((items, i) => {
            datainner[items.name] = ''
            value.quiltingvarients && value.quiltingvarients.map(val => {
              if (val.varientId == items.id) {
                datainner[items.name] = val.value != '' ? val.value : ''
              }
            })
            return true;
          })
          let valueColor
          value.quiltingFabricOrder && value.quiltingFabricOrder.febricvarients && value.quiltingFabricOrder.febricvarients.map(items => {
            if (items.fabricvarientsData.slug === 'color') {
              valueColor = items.value
            }
          })
          return ({
            orderid: value.id,
            Delivery: value.deliveryDate != null ? value.deliveryDate : '',
            'FabricOrderId': value.FabricOrderId != null ? value.FabricOrderId : '',
            'brandOrderId': value.brandOrderId != null ? value.brandOrderId : '',
            orderDescId: value.quiltingDesc && value.quiltingDesc.id != null ? value.quiltingDesc.id : '',
            Remarks: value.remarks != null ? value.remarks : '',
            addQty: value.quiltingFabricOrder && value.quiltingFabricOrder.addQTY != null ? value.quiltingFabricOrder.addQTY : '',
            supplierId: (value.quiltingFabricOrder && value.quiltingFabricOrder.febricSupllier && value.quiltingFabricOrder.febricSupllier.id) ? value.quiltingFabricOrder.febricSupllier.id : '',
            supplierIdtext: (value.quiltingFabricOrder && value.quiltingFabricOrder.febricSupllier && value.quiltingFabricOrder.febricSupllier.id) ? value.quiltingFabricOrder.febricSupllier.companyName : '',
            fabricTypetext: (value.quiltingFabricOrder && value.quiltingFabricOrder.febricMaterial && value.quiltingFabricOrder.febricMaterial.id) ? value.quiltingFabricOrder.febricMaterial.name : '',
            'quiltingsupplier': value.supplierId != null ? value.supplierId : '',
            'quiltingsuppliertext': value.quiltingSupllier && value.quiltingSupllier.companyName ? value.quiltingSupllier.companyName : '',
            'quiltingtype': value.materialId != null ? value.materialId : '',
            'quiltingtypetext': value.quiltingMaterial && value.quiltingMaterial.name ? value.quiltingMaterial.name : '',
            'quiltingAvg': value.quiltingAverage != null ? value.quiltingAverage : '',
            fabricColor: valueColor && valueColor != null ? valueColor : '',
            fabricType: (value.quiltingFabricOrder && value.quiltingFabricOrder.febricMaterial && value.quiltingFabricOrder.febricMaterial.id) ? value.quiltingFabricOrder.febricMaterial.id : '',
            finalNeed: value.finalNeed != null ? value.finalNeed : '',
            inhouse: value.inHouse != null ? value.inHouse : 0,
            pendingStatus: value.pendingStatus != null ? value.pendingStatus : '',
            poStatus: value.poStatus != null ? value.poStatus : 'Pending',
            ...datainner,
            price: value.price != null ? value.price : '',
            recdStatus: value.recdStatus != null ? value.recdStatus : '',
            totalNeed: value.totalNeed != null ? value.totalNeed : '',
            newStock: value.inhouseId != null ? value.inhouseId : undefined,
            note: value.notes != null ? value.notes : ''
          })

        })
        let supllierDetail = []
        let data1 = newProps.mainquiltorder && newProps.mainquiltorder.order && newProps.mainquiltorder.order.length && newProps.mainquiltorder.order.map((value, key) => {
          var orderQty = ''
          supllierDetail.push(value.quiltingSupllier)
          let datainner = {}
          this.state.varient.map((items, i) => {
            datainner[items.field] = ''
            value.quiltingvarients && value.quiltingvarients.map(val => {
              if (val.varientId == items.id) {
                if(items.name === 'color'){
                  datainner[items.field] = [<span className="tooltip1" key={'abcd' + key}>{val.value !== null && val.value ? (val.value.length > 5 ? val.value.substr(0, 5) : val.value) : val.value}<span className="tooltiptext">{val.value}</span></span>]
                }else{
                  datainner[items.field] = val.value != null ? val.value : ''
                }
              }
            })
            return true;
          })
          let valueColor
          value.quiltingFabricOrder && value.quiltingFabricOrder.febricvarients && value.quiltingFabricOrder.febricvarients.map(items => {
            if (items.fabricvarientsData.slug === 'color') {
              valueColor = [<span className="tooltip1" key={'abcd' + key}>{items.value !== null && items.value ? (items.value.length > 5 ? items.value.substr(0, 5) : items.value) : items.value}<span className="tooltiptext">{items.value}</span></span>]
            }
          })
          let fabricOrd = value.quiltingFabricOrder && value.quiltingFabricOrder
          if (value.quiltingDesc)
            orderQty = value.quiltingDesc.xs + value.quiltingDesc.s + value.quiltingDesc.m + value.quiltingDesc.l + value.quiltingDesc.xl + value.quiltingDesc.xxl + value.quiltingDesc['3xl'] + value.quiltingDesc['4xl'] + value.quiltingDesc['5xl']
          let poSucc = valueColor != null && fabricOrd.febricSupllier !== null && value.quiltingAverage != null && value.totalNeed != null && value.deliveryDate != null && fabricOrd.febricMaterial != null && value.quiltingSupllier != null ? false : true
          return ({
            'Action': [<div style={{ display: 'flex' }}>{sessionStorage.userROLE == 'super-admin' || value.poStatus === 'Pending' || value.poStatus === null ? <><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i><i title="Add Row" className="fas fa-plus-circle cursor-pointer" key={'abce' + key} onClick={e => (this.onAdd(e, key))}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, value.id, poSucc))} ></i><input style={{ minWidth: '15px', marginLeft: '5px' }} type="checkbox" value={value.id} name="poGenrate" disabled={poSucc} title={poSucc === true ? 'Fill the row to generate PO.' : ''} key={key} onChange={e => (this.listPoGenrate(e, key))} /></> : <><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i><i title="Add Row" className="fas fa-plus-circle cursor-pointer" key={'abce' + key} onClick={e => (this.onAdd(e, key))}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, value.id, poSucc))} ></i></>}</div>],
            'Sr No': ((this.state.pageNumber - 1) * 10) + (key + 1),
            'Style No': value.quiltingDesc && value.quiltingDesc != null ? value.quiltingDesc.styleno : '',
            'Style code': [<span className="tooltip1" key={'abcd' + key}>{value.quiltingDesc !== null ? (value.quiltingDesc.stylecode.length > 5 ? value.quiltingDesc.stylecode.substr(value.quiltingDesc.stylecode.length - 5) : value.quiltingDesc.stylecode) : value.quiltingDesc.stylecode}<span className="tooltiptext">{value.quiltingDesc.stylecode}</span></span>],
            'order qtty': orderQty != '' ? orderQty : '',
            'add qtty': fabricOrd && fabricOrd.addQTY != null ? fabricOrd.addQTY : '',
            'supplier': [<span className="tooltip1" key={'abcde' + key} >{fabricOrd && fabricOrd.febricSupllier !== null ? (fabricOrd && fabricOrd.febricSupllier && fabricOrd.febricSupllier.companyName && fabricOrd.febricSupllier.companyName.length > 5 ? fabricOrd && fabricOrd.febricSupllier && fabricOrd.febricSupllier.companyName.substr(0, 5) : fabricOrd && fabricOrd.febricSupllier && fabricOrd.febricSupllier.companyName) : ''}<span className="tooltiptext">{fabricOrd && fabricOrd.febricSupllier !== null && fabricOrd.febricSupllier.companyName ? fabricOrd.febricSupllier.companyName : ''}</span></span>],
            'type': [<span className="tooltip1" key={'abcde' + key} >{(fabricOrd && fabricOrd.febricMaterial !== null) ? (fabricOrd.febricMaterial.name&&fabricOrd.febricMaterial.name.length > 5 ? fabricOrd.febricMaterial.name.substr(0, 5) : fabricOrd.febricMaterial.name) : ''}<span className="tooltiptext">{fabricOrd && fabricOrd.febricMaterial !== null && fabricOrd.febricMaterial.name ? fabricOrd.febricMaterial.name : ''}</span></span>],
            'color': valueColor && valueColor != null ? valueColor : '',
            'Quilting supplier': [<span className="tooltip1" key={'abcde' + key} >{(value.quiltingSupllier && value.quiltingSupllier.id) ? (value.quiltingSupllier.companyName.length > 5 ? value.quiltingSupllier.companyName.substr(0, 5) : value.quiltingSupllier.companyName) : ''}<span className="tooltiptext">{value.quiltingSupllier && value.quiltingSupllier.companyName ? value.quiltingSupllier.companyName : ''}</span></span>],
            'quilting type': [<span className="tooltip1" key={'abcde' + key} >{(value.quiltingMaterial && value.quiltingMaterial.id) ? (value.quiltingMaterial.name.length > 5 ? value.quiltingMaterial.name.substr(0, 5) : value.quiltingMaterial.name) : ''}<span className="tooltiptext">{value.quiltingMaterial && value.quiltingMaterial.name ? value.quiltingMaterial.name : ''}</span></span>],
            'Quilting avg': value.quiltingAverage != null ? value.quiltingAverage : '',
            ...datainner,
            'total need': value.totalNeed != null ? value.totalNeed : '',
            'inhouse': value.inHouse != null ? value.inHouse : 0,
            'final need': value.finalNeed != null ? value.finalNeed : 0,
            'price': value.price != null ? value.price : '',
            'Delivery': value.deliveryDate != null ? moment(value.deliveryDate).format('DD/MM/YY') : '',
            'Remarks': [<span className="tooltip1" key={'abcd' + key}>{value.remarks !== null && value.remarks ? (value.remarks.length > 5 ? value.remarks.substr(0, 5) : value.remarks) : value.remarks}<span className="tooltiptext">{value.remarks}</span></span>],
            'po status': value.poStatus != null ? <>{value.poStatus === 'Generated' || value.poStatus === 'Regenerated' ? <i class="fas fa-check" title={value.poStatus} style={{ color: '#4dba4f' }}></i> : <><i style={{ color: '#f15d5d' }} class="far fa-clock" title='Pending'></i></>}</> : <><i style={{ color: '#f15d5d' }} class="far fa-clock" title='Pending'></i></>,
            'recd status': value.recdStatus != null ? value.recdStatus : '',
            'pending status': value.pendingStatus != null ? value.pendingStatus : '',
          })
        })
          this.setState({
            data: {
              ...this.state.data,
              rows: data1
            },
            supllierDetail: supllierDetail,
            rows: data,
            total: newProps.mainquiltorder.page,
            loaderTable: false,
            pageHead: newProps.mainquiltorder.cat
          })
      } else {
        if(newProps.mainquiltorder && newProps.mainquiltorder.success === false){
          this.setState({
            loaderTable: false
          })
        }
      }
    }
  }
  searchChange = (e) => {
    const { brandOrderId, pageNumber, slug } = this.state
    this.setState({
      ...this.state,
      search: e.target.value
    })
    if (e.target.value !== '')
      this.props.getMainQuiltOrder({ brandOrderId, slug, pageNumber: 1, search: e.target.value });
    else
      this.props.getMainQuiltOrder({ brandOrderId, slug, pageNumber: pageNumber, search: e.target.value });
  }
  changePage = (e, pageNumber) => {
    const { brandOrderId, search, slug } = this.state
    this.setState({
      ...this.state,
      pageNumber: pageNumber,
      loaderTable: true,
      selectPoList: { id: [] }
    })
    this.props.getMainQuiltOrder({ brandOrderId, pageNumber: pageNumber, search, slug });
  }
  preview = (e, rowId, id, poSucc) => {
    const { data, varient, supllierDetail, rows } = this.state
    let poTable = [{}]
    let poSupplierDetail = []
    let orderId, poStatus, noteGetRow
    supllierDetail.map((items, key) => {
      if (rowId == key) {
        poSupplierDetail = items
      }
    })
    rows.map((item, key) => {
      if (rowId == key) {
        orderId = rows[rowId]['orderid']
        poStatus = rows[rowId]['poStatus']
        noteGetRow = rows[rowId]['note']
      }
    })
    data.rows.map((value, key) => {
      if (rowId == key) {
        poTable[0]['Sr No'] = key + 1
        poTable[0]['Style code'] = data.rows[key]['Style code']
        poTable[0]['Quilting type'] = data.rows[key]['quilting type']
        poTable[0]['final need'] = data.rows[key]['final need']
        poTable[0]['price'] = data.rows[key]['price']
        varient.map(items => {
          poTable[0][items.field] = data.rows[key][items.field]
        })
        poTable[0]['Delivery'] = data.rows[key]['Delivery']
        poTable[0]['Remarks'] = data.rows[key]['Remarks']
      }
      return true;
    })
    if (poSupplierDetail !== null) {
      this.setState({
        previewData: {
          columns: [
            { label: "S.N.", field: "Sr No", class: 'colum_width' },
            { label: 'Style code', field: 'Style code', class: 'colum_width' },
            { label: 'Fabric quilting type', field: 'Fabric quilting type' },
            { label: 'final need', field: 'final need', class: 'colum_width' },
            { label: 'price', field: 'price', class: '' },
            ...varient,
            { label: "Delivery", field: "Delivery", class: '' },
            { label: 'Remarks', field: 'Remarks', class: '' },
          ],
          rows: poTable
        },
        previewDataId: id,
        open: true,
        orderIdPo: orderId,
        poStatus: poStatus,
        poSucc: poSucc,
        noteGetRow:noteGetRow,
        poSupplierDetail: poSupplierDetail
      })
    }
  }
  previewClose = () => {
    this.setState({
      open: false,
    })
    this.props.noteinitialState()
  }
  POSingle = (e, rowId) => {
    let selectPoList = {
      id: [rowId.toString(), null]
    }
    this.setState({
      ...this.state,
      loaderPo: true,
      loaderTable: true,
      open: false
    })
    this.props.quiltingPo(selectPoList)
  }
  poGenerate = (e, rowId) => {
    const { selectPoList, varient, rows } = this.state
    this.setState({
      loaderPo: true,
      loaderTable: true
    })
    this.props.quiltingPo(selectPoList)
  }
  listPoGenrate = (e, key) => {
    const { selectPoList } = this.state
    let index;
    if (e.target.checked) {
      this.setState({
        selectPoList: { ...selectPoList, id: [...selectPoList.id, e.target.value] }
      })
    } else {
      index = selectPoList.id.indexOf(e.target.value)
      selectPoList.id.splice(index, 1)
      this.setState({
        selectPoList: { ...selectPoList, id: [...selectPoList.id] }
      })
    }
  }
  render() {
    const { brand, pageHead, brandImg, previewData, open, suppliers, previewDataId, poSupplierDetail, loaderPo, loaderTable, loaderVarient, slug, selectPoList, orderIdPo, name, poStatus, poSucc,noteGetRow } = this.state
    return (
      <>
        <ToastContainer autoClose={10000} />
        {
          !loaderTable && !loaderVarient ?
            <>
              <Header />
              <Container className="mt-0" fluid>
                <Row>
                  <div className="col">
                    <Card className="border-0">
                      <CardHeader className="border-0 pt-0">
                        <h1 className="m-0 text-capitalize">{pageHead}</h1>
                        <Row className="align-items-center">
                          <div className="col-md-8 col-6 d-flex pl-0">
                            <div style={{ paddingBottom: '5px', marginRight: '25px' }}>
                              <h6 className="text-uppercase text-light ls-1 mb-1">
                                Brand
                            </h6>
                              <h3 className="mb-0 profile_sec text-capitalize">
                                {
                                  (brandImg !== '' && brandImg !== null) ?
                                    <img className="mr-3" src={`${imagerUrl}` + brandImg} width="40" alt="brand"></img>
                                    : <img className="mr-3" src={'/logo.png'} width="40" alt="brand"></img>
                                }
                                {brand}
                              </h3>
                            </div>
                            <div style={{ paddingBottom: '5px' }}>
                              <h6 className="text-uppercase text-light ls-1 mb-1">
                                Order name
                              </h6>
                              <h2 className="mb-0">
                                {name}</h2>
                            </div>
                          </div>
                          <div className="col-md-4 text-right d-flex justify-contant-flex-end justify-contant-flex-xs-start pl-0">
                            <Button style={{ marginRight: '20px' }} type="submit" disabled={loaderPo === true || selectPoList.id == undefined || selectPoList.id[0] == null ? true : false} className="btn btn-primary" color="primary" onClick={this.poGenerate}>
                              {
                                loaderPo === true ?
                                  <Spinner /> :
                                  'Generate PO'
                              }
                            </Button>
                            <Nav className="justify-content-end " pills>
                              <NavItem>
                                <NavLink className="btn btn-primary " to={'/main_fabric/' + slug} tag={Link}  >Back</NavLink>
                              </NavItem>
                            </Nav>
                          </div>
                        </Row>
                      </CardHeader>
                      <div className="table-responsive">
                        <EditTable {...this.state} searchChange={this.searchChange} changePage={this.changePage} />
                      </div>
                    </Card>
                  </div>
                </Row>
                <PreviewModal previewData={previewData} toggle={this.previewClose} noteSubmit={this.noteSubmit} open={open} liningsupplier={suppliers} previewDataId={previewDataId} poSupplierDetail={poSupplierDetail} orderIdPo={orderIdPo} poStatus={poStatus} POSingle={this.POSingle} loaderPo={loaderPo} poSucc={poSucc} noteGetRow={noteGetRow}/>
              </Container>
            </>
            :
            <div className="loader">
              <Spinner variant="light" />
              <p style={{ color: '#fff', paddingTop: '15px' }}>Please wait......</p>
            </div>
        }
      </>
    )
  }
}


const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    getBrandOrder,
    addMainQuiltOrder,
    mainorderGroup,
    varientBySlug,
    getInhouseStocks,
    mainquiltSupplier,
    getMainQuiltOrder,
    initialState,
    quiltingPo,
    noteinitialState,
    varienInitial
  }, dispatch)
}

const mapGetStateNew = (state) => {
  return {
    brandorder: state.order.brandorder,
    supplierSuccess: state.supplier.mainquiltsupplier,
    MainOrder: state.order.order,
    inhousestock: state.order.inhousestock,
    mainquiltorder: state.order.mainquiltorder,
    varientList: state.varient.varient,
    poGenrateSuccess: state.poPDF.poGenrate,
    noteSuccess: state.order.note
  }
}
export default connect(mapGetStateNew, mapDispatchState)(MainfabricQuiltingsheet);
