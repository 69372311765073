import React from "react";
import Forgetform from './Forgetform';
import { forgotValid } from '../../../sharedFile/validator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { forgetpassword } from '../../../services/actions/auth';
import { ToastContainer, toast } from 'react-toastify';
class ForgetPassword extends React.Component {
  state = {
    data: {
      email: '',
    },
    errors: {},
    loader: false
  }
  onSubmit = (e) => {
    e.preventDefault();
    const errors = forgotValid(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({
        loader: true
      })
      this.props.forgetpassword(this.state.data);
    }
  }
  onChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value
      }
    })
   
  }
  componentWillReceiveProps(newProps){
    const {mailstatus} = newProps
   if(mailstatus)
    { if(mailstatus.success){
      this.setState({
        loader: false
      })
      toast.success(mailstatus.message);
    }else{
      this.setState({
        loader: false
      })
      toast.error(mailstatus.message);
    }
  }
    
  }
  render() {
    const { errors, loader } = this.state
    return (
      <>
        <ToastContainer autoClose={10000}/>
        <Forgetform onSubmit={this.onSubmit} onChange={this.onChange} errors={errors} loader={loader} />
     
      </>
    );
  }
}
const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    forgetpassword
  }, dispatch)
}
const mapGetStateNew = (state) => {
  return {
    mailstatus: state.user.mailstatus,
   
  }
}
export default connect(mapGetStateNew, mapDispatchState)(ForgetPassword);
