import { combineReducers } from 'redux';
import user from './user'
import category from './category'
import varient from './varient'
import material from './material'
import supplier from './supplier'
import style from './style'
import brand from './brand'
import order from './order'
import subCategory from './subCategory'
import inHouse from './inHouse'
import poPDF from './poPDF'
import unitRatioWise from './unitRatioWise'
import dashboard from './dashboard'
const reduser = combineReducers({
    user,
    category,
    varient,
    material,
    supplier,
    style,
    brand,
    order,
    subCategory,
    inHouse,
    poPDF,
    unitRatioWise,
    dashboard
})
export default reduser;