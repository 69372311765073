import { call, put, takeLatest, all } from "redux-saga/effects";
import { 
  SET_ALLCATEGORY_SUCCESS, 
  SET_ALLCATEGORY_FAILED,
  GET_ALLCATEGORY,
  SET_CATEGORY_SUCCESS, 
  SET_CATEGORY_FAILED,
  GET_CATEGORY, 
  ADD_CATEGORY_SUCCESS, 
  ADD_CATEGORY_FAILED, 
  GET_CATEGORY_ADD, 
  GET_CATEGORY_DELETE, 
  DELETE_CATEGORY_SUCCESS, 
  DELETE_CATEGORY_FAILED,
  GET_CATEGORY_VIEW,
  VIEW_CATEGORY_SUCCESS, 
  VIEW_CATEGORY_FAILED,
  GET_CATEGORY_UPDATE,
  UPDATE_CATEGORY_SUCCESS, 
  UPDATE_CATEGORY_FAILED,
  GET_ASSIGN_VARIENT,
  ASSIGN_VARIENT_SUCCESS,
  ASSIGN_VARIENT_FAILED,
  GET_VIEW_ASSIGN_VARIENT,
  VIEW_ASSIGN_VARIENT_SUCCESS,
  VIEW_ASSIGN_VARIENT_FAILED,
  GET_DELETE_ASSIGN_VARIENT,
  DELETE_ASSIGN_VARIENT_SUCCESS,
  DELETE_ASSIGN_VARIENT_FAILED,
  GET_CATEGORY_ROUTE,
  GET_CATEGORY_ROUTE_SUCCESS,
  GET_CATEGORY_ROUTE_FAILED
} from "../../sharedFile/actionType";
import Api from "../../lib/api";
function getAllCategoryListApi(action){
    return Api.get(
        `category/`,
    );
}
function* getAllCategoryList(action){
    try {
        const resp = yield call(getAllCategoryListApi, action);
        if (resp.success === true) {
          yield put({ type: SET_ALLCATEGORY_SUCCESS, data: resp })
        } else {
          yield put({ type: SET_ALLCATEGORY_FAILED, error: resp })
        }
      } catch (resp) {
        yield put({ type: SET_ALLCATEGORY_FAILED, error: resp })
      }
}

function getCategoryListApi(action){
    return Api.get(
        `category/`,
    );
}
function* getCategoryList(action){
    try {
        const resp = yield call(getCategoryListApi, action);
        if (resp.success === true) {
          yield put({ type: SET_CATEGORY_SUCCESS, data: resp })
        } else {
          yield put({ type: SET_CATEGORY_FAILED, error: resp.message })
        }
      } catch (resp) {
        yield put({ type: SET_CATEGORY_FAILED, error: 'Something Went Wrong' })
      }
}


function addCategoryListApi(action){
  const {name, description} = action.data
  return Api.post(
      `category/`,
      {
        name:name,
        description:description
    }
  );
}
function* addCategoryList(action){
  try {
      const resp = yield call(addCategoryListApi, action);
      if (resp.success === true) {
        yield put({ type: ADD_CATEGORY_SUCCESS, data: resp })
       // sessionStorage.userJWT = resp.token
      } else {
        yield put({ type: ADD_CATEGORY_FAILED, error: resp.message })
      }
    } catch (resp) {
      yield put({ type: ADD_CATEGORY_FAILED, error: 'Something Went Wrong' })
    }
}


function deleteCategoryApi(action){
  const {data} = action
  return Api.delete(
      `category/`+data
  );
}
function* deleteCategory(action){
  try {
      const resp = yield call(deleteCategoryApi, action);
      if (resp.success === true) {
        yield put({ type: DELETE_CATEGORY_SUCCESS, data: resp })
      } else {
        yield put({ type: DELETE_CATEGORY_FAILED, error: resp.message })
      }
    } catch (resp) {
      yield put({ type: DELETE_CATEGORY_FAILED, error: 'Something Went Wrong' })
    }
}


function viewCategoryApi(action){
  const {data} = action
  return Api.get(
      `category/`+data
  );
}
function* viewCategory(action){
  try {
      const resp = yield call(viewCategoryApi, action);
      if (resp.success === true) {
        yield put({ type: VIEW_CATEGORY_SUCCESS, data: resp })
      } else {
        yield put({ type: VIEW_CATEGORY_FAILED, error: resp.message })
      }
    } catch (resp) {
      yield put({ type: VIEW_CATEGORY_FAILED, error: 'Something Went Wrong' })
    }
}

function updateCategoryApi(action){
  const {name, description} = action.data
  return Api.put(
      `category/`+action.id,
      {
        name:name,
        description:description
    }
  );
}
function* updateCategory(action){
  try {
      const resp = yield call(updateCategoryApi, action);
      if (resp.success === true) {
        yield put({ type: UPDATE_CATEGORY_SUCCESS, data: resp })
      } else {
        yield put({ type: UPDATE_CATEGORY_FAILED, error: resp.message })
      }
    } catch (resp) {
      yield put({ type: UPDATE_CATEGORY_FAILED, error: 'Something Went Wrong' })
    }
}


function assignVarientApi(action){
  return Api.post(
      `category/varient`,
      action.data
  );
}
function* assignVarient(action){
  try {
      const resp = yield call(assignVarientApi, action);
      if (resp.success === true) {
        yield put({ type: VIEW_ASSIGN_VARIENT_SUCCESS, data: resp, message:true })
      } else {
        yield put({ type: ASSIGN_VARIENT_FAILED, error: resp })
      }
    } catch (resp) {
      yield put({ type: ASSIGN_VARIENT_FAILED, error: resp })
    }
}
function viewAssignVarientApi(action){
  return Api.get(
      `category/varient/list`
  );
}
function* viewAssignVarient(action){
  try {
      const resp = yield call(viewAssignVarientApi, action);
      if (resp.success === true) {
        yield put({ type: VIEW_ASSIGN_VARIENT_SUCCESS, data: resp })
      } else {
        yield put({ type: VIEW_ASSIGN_VARIENT_FAILED, error: 'Something Went Wrong' })
      }
    } catch (resp) {
      yield put({ type: VIEW_ASSIGN_VARIENT_FAILED, error: 'Something Went Wrong' })
    }
}
function deleteAssignVarientApi(action){
  return Api.delete(
      `category/varient/`+action.data
  );
}
function* deleteAssignVarient(action){
  try {
      const resp = yield call(deleteAssignVarientApi, action);
      if (resp.success === true) {
        yield put({ type: DELETE_ASSIGN_VARIENT_SUCCESS, data: resp })
      } else {
        yield put({ type: DELETE_ASSIGN_VARIENT_FAILED, error: resp })
      }
    } catch (resp) {
      yield put({ type: DELETE_ASSIGN_VARIENT_FAILED, error: resp })
    }
}
function categoryRouteApi(){
  return Api.get(
      `category/order/route`
  );
}
function* categoryRoute(action){
  try {
      const resp = yield call(categoryRouteApi, action);
      if (resp.success === true) {
        yield put({ type: GET_CATEGORY_ROUTE_SUCCESS, data: resp })
      } else {
        yield put({ type: GET_CATEGORY_ROUTE_FAILED, error: resp })
      }
    } catch (resp) {
      yield put({ type: GET_CATEGORY_ROUTE_FAILED, error: resp })
    }
}


function* category() {
    yield all([
      takeLatest(GET_ALLCATEGORY, getAllCategoryList),
      takeLatest(GET_CATEGORY, getCategoryList),
      takeLatest(GET_CATEGORY_ROUTE, categoryRoute),
      takeLatest(GET_CATEGORY_ADD,addCategoryList),
      takeLatest(GET_CATEGORY_DELETE,deleteCategory),
      takeLatest(GET_CATEGORY_VIEW, viewCategory),
      takeLatest(GET_CATEGORY_UPDATE, updateCategory),
      takeLatest(GET_ASSIGN_VARIENT,assignVarient),
      takeLatest(GET_VIEW_ASSIGN_VARIENT,viewAssignVarient),
      takeLatest(GET_DELETE_ASSIGN_VARIENT,deleteAssignVarient)
    ])
}


export default category;