import React from "react";
import AddForm from './addForm';
import { addBrand } from '../../services/actions/brand';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addBrandValid } from '../../sharedFile/validator';
import { toast } from 'react-toastify';
import { initialState } from '../../services/actions/order'
var toastId = null
class AddBrand extends React.Component {
  state = {
    data: {
      name: '',
      description: '',
      picture: []
    },
    errors: {},
    loader: false,
    imagesPreviewUrls: [],
    message: "",
    pageNumber: 1
  }
  onSubmit = (e) => {
    e.preventDefault();
    const errors = addBrandValid(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({
        loader: true
      })
      var dataFile = {
        name: this.state.data.name,
        description: this.state.data.description,
      }
      if (this.state.data.picture.length > 0)
        this.props.addBrand(this.state.data)
      else
        this.props.addBrand(dataFile)
    }
  }
  onChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value.trimLeft().replace(/\s\s+/g, ' ')
      }
    })
  }
  removeLogo = () => {
    this.setState({
      data:{
        ...this.state.data,
        picture: []
      },
      message:'',
      imagesPreviewUrls:[]
    })
  }
  onFileChange = (e) => {
    let files = Array.from(e.target.files);
    files.forEach((file) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          data: {
            ...this.state.data,
            picture: [file],
          },
          imagesPreviewUrls: [reader.result]
        });
      }
      reader.readAsDataURL(file);
    });
  }
  componentWillReceiveProps(newProps) {
    const { brandAddSuccess } = newProps
    if (brandAddSuccess && brandAddSuccess.success) {
      this.setState({
        data: {
          ...this.state.data,
          name: '',
          description: '',
          picture: []
        },
        message:'',
        imagesPreviewUrls: [],
        loader: false
      })
      if (brandAddSuccess.message) {
        if (toast.isActive(toastId)) {
          toast.dismiss(toastId)
          toastId = toast.success(brandAddSuccess.message);
        } else {
          toastId = toast.success(brandAddSuccess.message);
        }
      }
      newProps.initialState()
    } if (brandAddSuccess && brandAddSuccess.success === false) {
      if (toast.isActive(toastId)) {
        toast.dismiss(toastId)
        toastId = toast.error(brandAddSuccess.message);
      } else {
        toastId = toast.error(brandAddSuccess.message);
      }
      this.setState({
        loader: false
      })
      newProps.initialState()
    }
  }
  render() {
    return (
      <>
        <AddForm  {...this.state} onSubmit={this.onSubmit} removeLogo={this.removeLogo} onChange={this.onChange} onFileChange={this.onFileChange} />
      </>
    );
  }
}
const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    addBrand,
    initialState
  }, dispatch)
}
const mapGetStateNew = (state) => {
  return {
    brandAddSuccess: state.brand.brandAdd
  }
}
export default connect(mapGetStateNew, mapDispatchState)(AddBrand);
