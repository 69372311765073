import React from 'react'
import FormAdd from './form';
import { varient } from '../../services/actions/varient';
import { addInhouse } from '../../services/actions/inHouse';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { material } from '../../services/actions/material'
import { inhouseValid } from '../../sharedFile/validator'
import { ToastContainer, toast } from 'react-toastify';
import { initialState } from '../../services/actions/order'
import { subCategory } from '../../services/actions/subCategory';
import { subCategoryMaterial, initialStateMaterial } from '../../services/actions/material';
var toastId = null
class AddInhouse extends React.Component {
  state = {
    dataVarient: {
      materialId: '',
      quantity: '',
      varients: null,
      subCategoryId: '',
      orderSize: '',
      colorName: '',
      varient: [{
        varientId: '',
        value: ''
      }]
    },
    slugSub: '',
    loaderSub:false,
    slugCate: '',
    loader: false,
    valueCate:'',
    errors: {},
  }
  onChangeVarient = idx => e => {
    const { dataVarient } = this.state
    const newvarientList = dataVarient.varient.map((itemList, sidx) => {
      if (idx !== sidx) return itemList;
      return { ...itemList, [e.target.name]: e.target.value };
    });
    let varients
    if (dataVarient.orderSize !== '')
      varients = 'size' + ':' + dataVarient.orderSize.toLowerCase() + ','
    else
      varients = ''
    newvarientList.map(items => {
      varients = varients + items.varientId.toLowerCase() + ":" + items.value.toLowerCase() + ', '
    })
    this.setState({
      dataVarient: {
        ...dataVarient,
        varient: newvarientList,
        varients: varients !== '' ? varients : null
      }
    });
  }
  onChange = (e) => {
    const { dataVarient } = this.state
    if (e.target.name == 'subCategoryId') {
      let value = JSON.parse(e.target.value)
      this.props.subCategoryMaterial(value.id);
      this.setState({
        dataVarient: { ...dataVarient, [e.target.name]: value.id,varients:null,quantity:'', materialId:'',orderSize:'',colorName:''},
        slugCate: value.slug,
        valueCate:e.target.value,
        slugSub: value.slugSub
      })
      this.props.initialStateMaterial()
    } else if (e.target.name == 'colorName') {
      this.setState({
        dataVarient: { ...dataVarient, [e.target.name]: e.target.value.trimLeft().replace(/\s\s+/g, ' '), varients: 'color name' + ':' + e.target.value },
      })
    } else {
      this.setState({
        dataVarient: { ...dataVarient, [e.target.name]: e.target.value.trimLeft().replace(/\s\s+/g, ' ') }
      })
    }
  }
  onAddMoreVraients = (e) => {
    const { dataVarient } = this.state
    const { varientSuccess } = this.props
      this.setState({
        dataVarient: {
          ...this.state.dataVarient,
          varient: [...dataVarient.varient, { varientId: "", value: '' }]
        }
      });
  }
  onSubmit = (e) => {
    const { dataVarient, slugSub, slugCate } = this.state
    e.preventDefault();
    const errors = inhouseValid(dataVarient, slugSub, slugCate);
    this.setState({ errors });
    console.log(errors,dataVarient,"errors===")
    if (Object.keys(errors).length === 0) {
      this.setState({
        loader: true,
        open: false
      })
      this.props.addInhouse(dataVarient)
    }
  }
  handleRemove = idx => () => {
    this.setState({
      dataVarient: {
        ...this.state.dataVarient,
        varient: this.state.dataVarient.varient.filter((s, sidx) => idx !== sidx),
      }
    });
  };
  componentDidMount() {
    this.props.subCategory();
    this.setState({
      loaderSub:true,
    })
  }
  componentWillReceiveProps(newProps) {
    const { inHouse,categorySuccess } = newProps
    if(categorySuccess){
      if(categorySuccess.success){
        this.setState({
          loaderSub: false,
        })
      }
      if(categorySuccess.success === false){
        this.setState({
          loaderSub: false,
        })
      }
    }
    if (inHouse && inHouse.success) {
      this.setState({
        loader: false,
        dataVarient: {
          ...this.state.dataVarient,
          materialId: '',
          subCategoryId: '',
          quantity: '',
          orderSize:'',
          colorName:'',
          varients: null,
          varient: [{
            varientId: '',
            value: ''
          }]
        },
        valueCate: JSON.stringify({ 'id': '', 'slug': '',slugSub:'' }),
      })
      if (toast.isActive(toastId)) {
        toast.dismiss(toastId)
        toastId = toast.success(inHouse.message);
        newProps.initialState()
      } else {
        toastId = toast.success(inHouse.message);
        newProps.initialState()
      }
    } if (inHouse && inHouse.success === false) {
      if (toast.isActive(toastId)) {
        toast.dismiss(toastId)
        toastId = toast.error(inHouse.message)
        newProps.initialState()
      } else {
        toastId = toast.error(inHouse.message);
        newProps.initialState()
      }
      this.setState({
        loader: false
      })
    }
  }
  onKeyPress = (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }
  componentWillUnmount() {
    this.props.initialState()
  }
  render() {
    return (
      <>
        <ToastContainer autoClose={10000} />
        <FormAdd {...this.state} {...this.props} toggle={this.toggle} onSubmit={this.onSubmit} onChangeVarient={this.onChangeVarient} onChange={this.onChange} onAddMoreVraients={this.onAddMoreVraients} handleRemove={this.handleRemove} title="Add" onKeyPress={this.onKeyPress} />
      </>
    )
  }
}
const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    subCategoryMaterial,
    initialStateMaterial,
    varient,
    addInhouse,
    subCategory,
    initialState
  }, dispatch)
}
const mapGetStateNew = (state) => {
  return {
    materialSuccess: state.material.materialCategory,
    categorySuccess: state.subCategory.subCategory,
    varientSuccess: state.varient.varient,
    inHouse: state.inHouse.inHouse
  }
}
export default connect(mapGetStateNew, mapDispatchState)(AddInhouse);