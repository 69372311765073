import { call, put, takeLatest, all } from "redux-saga/effects";
import {
    GET_SUB_CATEGORY,
    SET_SUB_CATEGORY_SUCCESS,
    SET_SUB_CATEGORY_FAILED,
    GET_SUB_CATEGORY_DETAIL,
    SET_SUB_CATEGORY_DETAIL_SUCCESS,
    SET_SUB_CATEGORY_DETAIL_FAILED,
    GET_DELETE_SUB_CATEGORY,
    SET_DELETE_SUB_CATEGORY_SUCCESS,
    SET_DELETE_SUB_CATEGORY_FAILED,
    GET_UPDATE_SUB_CATEGORY,
    SET_UPDATE_SUB_CATEGORY_FAILED,
    GET_ADD_SUB_CATEGORY,
    SET_ADD_SUB_CATEGORY_SUCCESS,
    SET_ADD_SUB_CATEGORY_FAILED,
    SET_ADD_ALL_CATEGORY_FOR_SUB_CATEGORY_SUCCESS,
    GET_CATEGORY_ROUTE
} from "../../sharedFile/actionType";
import Api from "../../lib/api";


function subCategoryApi() {
    return Api.get(
        `/subcategory/`,
    );
}
function* subCategoryList() {
    try {
        const resp = yield call(subCategoryApi);
        if (resp.success === true) {
            yield put({ type: SET_SUB_CATEGORY_SUCCESS, data: resp })
        } else {
            yield put({ type: SET_SUB_CATEGORY_FAILED, error: 'Something Went Wrong' })
        }
    } catch (resp) {
        yield put({ type: SET_SUB_CATEGORY_FAILED, error: 'Something Went Wrong' })
    }
}
function subCategoryDetailApi(action) {
    return Api.get(
        `/subcategory/` + action.data,
    );
}
function* subCategoryDetail(action) {
    try {
        const resp = yield call(subCategoryDetailApi, action);
        if (resp.success === true) {
            yield put({ type: SET_SUB_CATEGORY_DETAIL_SUCCESS, data: resp, redirect:false })
        } else {
            yield put({ type: SET_SUB_CATEGORY_DETAIL_FAILED, error: 'Something Went Wrong' })
        }
    } catch (resp) {
        yield put({ type: SET_SUB_CATEGORY_DETAIL_FAILED, error: 'Something Went Wrong' })
    }
}
function subCategoryDeleteApi(action) {
    return Api.delete(
        `subcategory/` + action.data,
    );
}
function* subCategoryDelete(action) {
    try {
        const resp = yield call(subCategoryDeleteApi, action);
        if (resp.success === true) {
            yield put({ type: SET_DELETE_SUB_CATEGORY_SUCCESS, data: resp })
            yield put({ type: GET_CATEGORY_ROUTE })
        } else {
            yield put({ type: SET_DELETE_SUB_CATEGORY_FAILED, error: resp })
        }
    } catch (resp) {
        yield put({ type: SET_DELETE_SUB_CATEGORY_FAILED, error: resp })
    }
}
function subCategoryUpdateApi(action) {
    const { data, id } = action
    return Api.put(
        `subcategory/` + id, {
            description: data.description,
            name: data.name
        }
    );
}
function* subCategoryUpdate(action) {
    try {
        const resp = yield call(subCategoryUpdateApi, action);
        if (resp.success === true) {
            yield put({ type: SET_SUB_CATEGORY_DETAIL_SUCCESS, data: resp, redirect:true })
            yield put({ type: GET_CATEGORY_ROUTE })
            yield put({ type: GET_SUB_CATEGORY })
        } else {
            yield put({ type: SET_UPDATE_SUB_CATEGORY_FAILED, error: resp })
        }
    } catch (resp) {
        yield put({ type: SET_UPDATE_SUB_CATEGORY_FAILED, error: resp })
    }
}
function subCategoryAddApi(action) {
    return Api.post(
        `subcategory/`, action.data
    );
}
function* subCategoryAdd(action) {
    try {
        const resp = yield call(subCategoryAddApi, action);
        if (resp.success === true) {
            yield put({ type: SET_ADD_SUB_CATEGORY_SUCCESS, data: resp })
            yield put({ type: SET_ADD_ALL_CATEGORY_FOR_SUB_CATEGORY_SUCCESS, data: resp })
            yield put({ type: GET_CATEGORY_ROUTE })
            yield put({ type: GET_SUB_CATEGORY })
        } else {
            yield put({ type: SET_ADD_SUB_CATEGORY_FAILED, error: resp })
        }
    } catch (resp) {
        yield put({ type: SET_ADD_SUB_CATEGORY_FAILED, error: resp })
    }
}
function* subCategory() {
    yield all([
        takeLatest(GET_SUB_CATEGORY, subCategoryList),
        takeLatest(GET_SUB_CATEGORY_DETAIL, subCategoryDetail),
        takeLatest(GET_DELETE_SUB_CATEGORY, subCategoryDelete),
        takeLatest(GET_UPDATE_SUB_CATEGORY, subCategoryUpdate),
        takeLatest(GET_ADD_SUB_CATEGORY, subCategoryAdd)
    ])
}
export default subCategory;