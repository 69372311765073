import React from 'react';
import {
    Button,
    Form,
    FormGroup,
    Input,
    CardHeader,
    NavItem,
    Spinner,
    Nav,
    Row, Col
} from 'reactstrap';
import InlineError from '../../../sharedFile/inlineError';
export default function OrdersFrom(props) {
    const { brandSuccess, errors, loaderBrandList, data, lock, url, loaderBrand } = props
    return (
        <>
            <div className="mt-0 mb-5">
                <CardHeader className="border-0 pt-0 pl-3">
                    <h2 className="mb-0">
                        {
                            url === 'mainordersheet' ?
                                "Order Sheet Listing" :
                                "Order Listing"
                        }
                    </h2>
                </CardHeader>
                <Form className="" onSubmit={props.onSubmit}>
                    <Row className="formPoInline align-items-end">
                        <Col>
                            <FormGroup className="mb-0" error={!errors.subCategoryId}>
                                <label className="">
                                    Select Brand
                                </label>
                                <div className="position-relative" >
                                    {
                                        loaderBrand ?
                                            <div className="position-absolute loader-select">
                                                <Spinner color="danger" />
                                            </div> :
                                            ''
                                    }
                                    <Input type="select" name="brandId" id="exampleSelect" disabled={loaderBrand ? true : false} onChange={props.onChange}>
                                        <option>Select Brand</option>
                                        {
                                            brandSuccess.brands && brandSuccess.brands.map((value, key) => {
                                                return (
                                                    <option value={value.id} key={value.id}>{value.name}</option>
                                                )
                                            })
                                        }
                                    </Input>
                                </div>
                                <div style={{ height: '19px' }}>{errors.brandId && <InlineError text={errors.brandId} />}</div>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="mb-0" error={!errors.dateFrom}>
                                <label className="">From Date:<Button onClick={props.lockDate} type="button" style={{ boxShadow: 'none', border: '0px', background: 'transparent', padding: '2px 10px' }}><i class="fa fa-lock" aria-hidden="true"></i></Button></label>
                                <Input
                                    type="date"
                                    name="dateFrom"
                                    id="dateFrom"
                                    placeholder="date placeholder"
                                    value={data.dateFrom}
                                    onChange={props.onChange}
                                    disabled={lock ? true : false}
                                />
                                <div style={{ height: '19px' }}>{errors.dateFrom && <InlineError text={errors.dateFrom} />}</div>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="mb-0" error={!errors.dateTo}>
                                <label className="">To Date:</label>
                                <Input
                                    type="date"
                                    name="dateTo"
                                    value={data.dateTo}
                                    id="dateTo"
                                    placeholder="date placeholder"
                                    onChange={props.onChange}
                                />
                                <div style={{ height: '19px' }}>{errors.dateTo && <InlineError text={errors.dateTo} />}</div>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="mb-0" error={!errors.dateTo}>
                                <label className=""></label>
                                <Nav className="justify-content-start" pills>
                                    <NavItem className="custom-submit text-left">
                                        <Button type="submit" disabled={loaderBrandList ? true : false} className="btn btn-primary submit-button padding-button" color="primary">
                                            {
                                                loaderBrandList
                                                    ?
                                                    <Spinner color="light" />
                                                    :
                                                    'Search'
                                            }
                                        </Button>
                                        {/* <NavLink to="/Mainordersheet" tag={Link} className="btn btn-primary">Upload</NavLink> */}
                                    </NavItem>
                                </Nav>
                                <div style={{ height: '19px' }}>{errors.dateTo && <InlineError text={errors.dateTo} />}</div>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>

            </div>
        </>
    )
}