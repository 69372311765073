import React from 'react';
import {
    Button,
    Form,
    FormGroup,
    Input,
    Row,
    NavItem,
    Spinner,
    Nav,
    CardHeader,
    Col
} from 'reactstrap';
import InlineError from '../../sharedFile/inlineError';
export default function PoForm(props) {
    const { brandSuccess, errors, loader, data, lock, categorySuccess, loaderBrand, loaderCate } = props
    return (
        <>
            <div className="mt-0 mb-5">
                <CardHeader className="border-0 pt-0 pl-3">
                    <h2 className="mb-0">Purchase Order Listing</h2>
                </CardHeader>
                <Form className="" onSubmit={props.onSubmit}>
                    <Row className="formPoInline align-items-end">
                        <Col>
                            <FormGroup className="mb-0" error={!errors.subCategoryId}>
                                <label className="">
                                    Select Sub Category
                                </label>
                                <div className="position-relative" >
                                    {
                                        loaderCate ?
                                            <div className="position-absolute loader-select">
                                                <Spinner color="danger" />
                                            </div> :
                                            ''
                                    }
                                    <Input type="select" value={data.subCategoryId} name="subCategoryId" id="exampleSelect" onChange={props.onChange} disabled={loaderCate ? true : false}>
                                        <option value="">Select sub category</option>
                                        <option value="All">All</option>
                                        {categorySuccess.subcategories && categorySuccess.subcategories.map((items, key) => {
                                            return <option value={items.id} key={key}>{items.name}</option>
                                        })
                                        }
                                    </Input>
                                </div>
                                <div style={{ height: '19px' }}>{errors.subCategoryId && <InlineError text={errors.subCategoryId} />}</div>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="mb-0" error={!errors.brandId}>
                                <label className="">
                                    Select Brand
                                </label>
                                <div className="position-relative" >
                                    {
                                        loaderBrand ?
                                            <div className="position-absolute loader-select">
                                                <Spinner color="danger" />
                                            </div> :
                                            ''
                                    }
                                    <Input type="select" value={data.brandId} name="brandId" id="exampleSelect" onChange={props.onChange} disabled={loaderBrand ? true : false}>
                                        <option value="">Select Brand</option>
                                        <option value="All">All</option>
                                        {
                                            brandSuccess.brands && brandSuccess.brands.map((value, key) => {
                                                return (
                                                    <option value={value.id} key={key}>{value.name}</option>
                                                )
                                            })
                                        }
                                    </Input>
                                </div>
                                <div style={{ height: '19px' }}>{errors.brandId && <InlineError text={errors.brandId} />}</div>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="mb-0" error={!errors.dateFrom}>
                                <label className="">From Date: <Button onClick={props.lockDate} type="button" style={{ boxShadow: 'none', border: '0px', background: 'transparent', padding: '2px 10px' }}><i class="fa fa-lock" aria-hidden="true"></i></Button></label>
                                <Input
                                    type="date"
                                    name="dateFrom"
                                    id="dateFrom"
                                    placeholder="date placeholder"
                                    value={data.dateFrom}
                                    onChange={props.onChange}
                                    disabled={lock ? true : false}
                                />
                                <div style={{ height: '19px' }}>{errors.dateFrom && <InlineError text={errors.dateFrom} />}</div>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="mb-0" error={!errors.dateTo}>
                                <label className="">To Date:</label>
                                <Input
                                    type="date"
                                    name="dateTo"
                                    value={data.dateTo}
                                    id="dateTo"
                                    placeholder="date placeholder"
                                    onChange={props.onChange}
                                />
                                <div style={{ height: '19px' }}>{errors.dateTo && <InlineError text={errors.dateTo} />}</div>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="mb-0" error={!errors.dateTo}>
                                <label className=""></label>
                                <Nav className="justify-content-start" pills>
                                    <NavItem className="custom-submit text-left">
                                        <Button type="submit" className="btn btn-primary submit-button padding-button" color="primary">

                                            {
                                                loader
                                                    ?
                                                    <Spinner color="light" />
                                                    :
                                                    'Search'
                                            }

                                        </Button>
                                        {/* <NavLink to="/Mainordersheet" tag={Link} className="btn btn-primary">Upload</NavLink> */}
                                    </NavItem>
                                </Nav>
                                <div style={{ height: '19px' }}>{errors.dateTo && <InlineError text="" />}</div>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}