import React from 'react';
import { MDBDataTable } from 'mdbreact';
import { Link } from "react-router-dom";
// reactstrap components
import {
    Card,
    CardHeader,
    NavItem,
    NavLink,
    Nav,
    Row
} from "reactstrap";
export default function Table(props) {
    const { dataTable, title } = props
    return (
        <>
            <Card className="card_border w-100">
                <CardHeader className="border-0">
                    <h2 className="mb-0 text-center">{title}</h2>
                    <Row className="align-items-center mobile_sec_col">
                        <div className="col">
                            <h6 className="text-uppercase text-light ls-1 mb-1">
                            </h6>
                        </div>
                        <div className="col">
                            <Nav className="justify-content-end" pills>
                                <NavItem>
                                    <NavLink to='/poList' tag={Link} className="btn btn-primary">Back</NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </Row>
                </CardHeader>
                <div className="table-responsive">
                    <MDBDataTable striped bordered responsive hover data={dataTable} />
                </div>
            </Card>
        </>
    )
}