import {
    GET_DASHBOARD_COUNTS_SUCCESS,
    GET_DASHBOARD_COUNTS_FAILED,
    GET_DASHBOARD_PO_SUCCESS,
    GET_DASHBOARD_PO_FAILED,
    GET_DASHBOARD_ORDER_SUCCESS,
    GET_DASHBOARD_ORDER_FAILED,
    GET_DASHBOARD_REVENUE_SUCCESS,
    GET_DASHBOARD_REVENUE_FAILED,
    GET_DASHBOARD_PURCHASE_ORDER_SUCCESS,
    GET_DASHBOARD_PURCHASE_ORDER_FAILED
} from "../../sharedFile/actionType";
const initialState = {
    count: [],
    po: [],
    orderDash: [],
    revenue: [],
    purchase:[]
}
const dashboard = (state = initialState, action) => {
    switch (action.type) {
        case GET_DASHBOARD_PURCHASE_ORDER_SUCCESS:
            return {
                ...state,
                purchase: action.data
            }
        case GET_DASHBOARD_PURCHASE_ORDER_FAILED:
            return {
                ...state,
                purchase: action.error
            }
        case GET_DASHBOARD_COUNTS_SUCCESS:
            return {
                ...state,
                count: action.data
            }
        case GET_DASHBOARD_COUNTS_FAILED:
            return {
                ...state,
                count: action.error
            }
        case GET_DASHBOARD_PO_SUCCESS:
            return {
                ...state,
                po: action.data
            }
        case GET_DASHBOARD_PO_FAILED:
            return {
                ...state,
                po: action.error
            }
        case GET_DASHBOARD_ORDER_SUCCESS:
            return {
                ...state,
                orderDash: action.data
            }
        case GET_DASHBOARD_ORDER_FAILED:
            return {
                ...state,
                orderDash: action.error
            }
        case GET_DASHBOARD_REVENUE_SUCCESS:
            return {
                ...state,
                revenue: action.data
            }
        case GET_DASHBOARD_REVENUE_FAILED:
            return {
                ...state,
                revenue: action.error
            }
        default:
            return state
    }

}
export default dashboard;