import React from 'react';
import UserForm from './userForm';
import { userSignup } from '../../sharedFile/validator';
import { userAdd, roleUserList } from '../../services/actions/auth'
import { initialState } from '../../services/actions/order'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ToastContainer, toast } from 'react-toastify';
var toastId = null
class UserAdd extends React.Component {
    state = {
        data: {
            userName: '',
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            phone: '',
            roleId: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            country: ''
        },
        edit: false,
        errors: {},
        loader: false,
        title: 'Add User',
    }
    componentDidMount() {
        this.props.roleUserList();
    }
    componentWillUnmount() {
        this.props.initialState();
    }
    onSubmit = (e) => {
        e.preventDefault();
        const errors = userSignup(this.state.data);
        this.setState({ errors });
        if (Object.keys(errors).length === 0) {
            this.setState({
                loader: true
            })
            this.props.userAdd(this.state.data);
        }
    }
    onChange = (e) => {
        if (e.target.name === 'userName') {
            this.setState({
                data: {
                    ...this.state.data,
                    userName: e.target.value.trim()
                }
            })
        }
        else
            this.setState({
                data: {
                    ...this.state.data,
                    [e.target.name]: e.target.value.trimLeft().replace(/\s\s+/g, ' ')
                }
            })
    }
    componentWillReceiveProps(newProps) {
        const { signUp, history } = newProps
        if (signUp.success) {
            this.setState({
                data: {
                    ...this.state.data,
                    userName: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    password: '',
                    phone: '',
                    roleId: '',
                    address: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: ''
                },
                loader: false
            })
            if (toast.isActive(toastId)) {
                toast.dismiss(toastId)
                toastId = toast.success(signUp.message);
                newProps.initialState();
            } else {
                toastId = toast.success(signUp.message);
                newProps.initialState();
            }
        } if (signUp.success === false) {
            this.setState({
                loader: false
            })
            if (toast.isActive(toastId)) {
                toast.dismiss(toastId)
                toastId = toast.error(signUp.message);
                newProps.initialState();
            } else {
                toastId = toast.error(signUp.message);
                newProps.initialState();
            }
        }
    }
    onKeyPress = (evt) => {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        var regex = /[0-9]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }
    render() {
        const { roleList, location } = this.props
        return (
            <>
                <ToastContainer autoClose={4000} />
                <UserForm onSubmit={this.onSubmit} onChange={this.onChange} {...this.state} roleList={roleList} location={location} onKeyPress={this.onKeyPress} />
            </>
        )
    }
}
const mapDispatchState = (dispatch) => {
    return bindActionCreators({
        userAdd,
        roleUserList,
        initialState
    }, dispatch)
}

const mapGetStateNew = (state) => {
    return {
        roleList: state.user.roleUserList,
        signUp: state.user.signup
    }
}
export default connect(mapGetStateNew, mapDispatchState)(UserAdd);