import React from 'react';
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Spinner
  } from "reactstrap";

  import InlineError from '../../../sharedFile/inlineError';
  
function Forgetform(props) {
    const {errors, loader} = props
    return (
        <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                        <small>Forget Password</small>
                    </div>
                    <Form role="form" onSubmit={props.onSubmit}>
                        <FormGroup className="mb-3"
                         error={!errors.email}
                         >
                            <InputGroup className="input-group-alternative" >
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-email-83" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="Enter your email" type="text" name="email" onChange={props.onChange}/>
                            </InputGroup>
                            {errors.email && <InlineError text={errors.email}/>}
                        </FormGroup>
                                               <div className="text-center">
                            <Button className="my-4" color="primary" type="submit">
                            {
                                loader
                                 ?
                                 <Spinner color="light"/>
                                 :
                                'Send'
                            }
                            </Button>
                        </div>
                    </Form>
                </CardBody>
            </Card>
            <Row className="mt-3">
                
                {/* <Col className="text-right" xs="6">
                    <a
                        className="text-light"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                    >
                        <small>Login</small>
                    </a>
                </Col> */}
            </Row>
        </Col>
    )
}
export default Forgetform;