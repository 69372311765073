import React from 'react';
import UserForm from './userForm';
import { userEdit } from '../../sharedFile/validator';
import { userUpdate, roleUserList } from '../../services/actions/auth';
import { userDetail } from '../../services/actions/auth'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ToastContainer, toast } from 'react-toastify';
import { initialState } from '../../services/actions/stateInitial'
import {
    Spinner
} from 'reactstrap';
var toastId = null

class UserEdit extends React.Component {
    state = {
        data: {
            userName: '',
            firstName: '',
            lastName: '',
            email: '',
            newPassword: '',
            phone: '',
            roleId: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            id: this.props.match.params,
        },
        edit: true,
        errors: {},
        loader: false,
        loaderView: false,
        title: 'Edit User',
    }
    componentDidMount() {
        const { id } = this.props.match.params
        this.props.roleUserList();
        this.props.userDetail(id);
        this.setState({
            loaderView: true
        })
    }
    onSubmit = (e) => {
        e.preventDefault();
        const errors = userEdit(this.state.data);
        this.setState({ errors });
        if (Object.keys(errors).length === 0) {
            this.setState({
                loader: true
            })
            this.props.userUpdate(this.state.data);
        }
    }
    onChange = (e) => {
        if (e.target.name === 'userName') {
            this.setState({
                data: {
                    ...this.state.data,
                    userName: e.target.value.trim()
                }
            })
        }
        else
            this.setState({
                data: {
                    ...this.state.data,
                    [e.target.name]: e.target.value.trimLeft().replace(/\s\s+/g, ' ')
                }
            })
    }
    componentWillReceiveProps(newProps) {
        const { signUpList, signup } = newProps
        if (signUpList && signUpList.success) {
            const { user } = signUpList
            this.setState({
                data: {
                    ...this.state.data,
                    userName: user.userName,
                    firstName: user.useremployees && user.useremployees[0] && user.useremployees[0].firstName,
                    lastName: user.useremployees && user.useremployees[0] && user.useremployees[0].lastName,
                    email: user.email,
                    phone: user.useremployees && user.useremployees[0] && user.useremployees[0].phone,
                    roleId: user.userroles.id,
                    address: user.useremployees && user.useremployees[0] && user.useremployees[0].address1,
                    country: user.useremployees && user.useremployees[0] && user.useremployees[0].country,
                    city: user.useremployees && user.useremployees[0] && user.useremployees[0].city,
                    state: user.useremployees && user.useremployees[0] && user.useremployees[0].state,
                    zip: user.useremployees && user.useremployees[0] && user.useremployees[0].zip,
                },
                loader: false,
                loaderView: false
            })
        }
        if (signUpList && signUpList.success === false) {
            this.setState({
                loader: false,
                loaderView: false
            })
            if (toast.isActive(toastId)) {
                toast.dismiss(toastId)
                toastId = toast.error(signUpList.message);
                newProps.initialState();
            } else {
                toastId = toast.error(signUpList.message);
                newProps.initialState();
            }
        }
        if (signup.redirect === true) {
            if (signUpList && signUpList.message) {
                toast.success(signUpList.message);
            }
            setTimeout(function () { newProps.history.push('/users') }, 1000);
        }
    }
    componentWillUnmount() {
        this.props.initialState()
    }
    onKeyPress = (evt) => {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        var regex = /[0-9]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }
    render() {
        const { roleList, location } = this.props
        const { loaderView } = this.state
        return (
            <>
                <ToastContainer autoClose={4000} />

                {
                    !loaderView ?
                        <>
                            <UserForm onSubmit={this.onSubmit} onChange={this.onChange} {...this.state} roleList={roleList} location={location} onKeyPress={this.onKeyPress} />
                        </>
                        :
                        <div className="loader">
                            <Spinner variant="light" />
                            <p style={{ color: '#fff', paddingTop: '15px' }}>Please wait......</p>
                        </div>
                }
            </>
        )
    }
}
const mapDispatchState = (dispatch) => {
    return bindActionCreators({
        userUpdate,
        roleUserList,
        userDetail,
        initialState
    }, dispatch)
}
const mapGetStateNew = (state) => {
    return {
        roleList: state.user.roleUserList,
        signUpList: state.user.signupList,
        signup: state.user
    }
}
export default connect(mapGetStateNew, mapDispatchState)(UserEdit);