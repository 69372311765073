import React from 'react';
import Table from './table';
import {
    Container,
    Row,
    Button,
    Spinner
} from 'reactstrap';
import { MDBIcon } from 'mdbreact';
import Header from '../../components/Headers/Header';
import { subCategory, subCategoryDelete } from '../../services/actions/subCategory'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ToastContainer, toast } from 'react-toastify';
import ConfirmModal from '../../components/ConfirmModal';
import { initialState, initialStateSub } from '../../services/actions/stateInitial'
import moment from 'moment';
import FormModel from '../../components/formModel';
import AddSubCate from './addSubCate';
import EditSubCate from './editSubCate';
var toastId = null
class SubCateList extends React.Component {
    state = {
        title: 'Sub Category Listing',
        url: '/add-subcategory',
        loader: false,
        open: false,
        data: {
            columns: [
                { label: '#', field: '#', sort: 'asc' },
                { label: 'Sub Category Name', field: 'Name', sort: 'asc' },
                { label: 'Parent Category', field: 'Category', sort: 'asc' },
                { label: 'Sub Category Description', field: 'Description', sort: 'asc' },
                { label: 'Created on', field: 'Created on', sort: 'asc' },
                { label: 'Updated on', field: 'Updated on', sort: 'asc' },
                { label: 'Action', field: 'Action', sort: 'disabled' }
            ],
            rows: [],
        },
        dataList: {
            name: '',
            description: '',
            categoryId: '',
            slug: ''
        },
        openForm: false,
        editId: null,
        openEditFrom: false,
        errors: {},
    }
    componentDidMount() {
        this.props.subCategory()
        this.setState({
            loader: true
        })
    }
    confirm = (event, id) => {
        this.setState({
            open: true,
            id: id,
        })
    }
    toggle = () => {
        this.setState({
            open: false,
            openForm: false,
            openEditFrom: false,
        })
    }
    confirmation = (event, id) => {
        this.setState({
            open: false
        })
        this.props.subCategoryDelete(id)
    }
    componentWillReceiveProps(newProps) {
        const { subCate, subListRedirect, deleteSub, subListDetail, subListUpdate } = newProps
        if (subListRedirect.redirect) {
            this.setState({
                openEditFrom: false
            })
        }
        if (deleteSub && deleteSub.success === false) {
            if (toast.isActive(toastId)) {
                toast.dismiss(toastId)
                toastId = toast.error(deleteSub.message);
                newProps.initialStateSub()
            } else {
                toastId = toast.error(deleteSub.message);
                newProps.initialStateSub()
            }
            this.setState({
                loader: false
            })
        }
        if (subListUpdate && subListUpdate.success === false) {
            if (toast.isActive(toastId)) {
                toast.dismiss(toastId)
                toastId = toast.error(subListUpdate.message);
            } else {
                toastId = toast.error(subListUpdate.message);
            }
            this.setState({
                loader: false
            })
        }
        if (subCate && subCate.success) {
            let descrip, subcateName
            var testData = subCate.subcategories && subCate.subcategories.map((items, key) => {
                const subCatego = items.subcategoryCategory
                let fname = subCatego && subCatego.name
                if (items.description.length > 30 || items.name > 30) {
                    descrip = items.description.substring(0, 30) + '...';
                    subcateName = items.name.substring(0, 30) + '...';
                }
                else {
                    descrip = items.description
                    subcateName = items.name
                }
                var nameSub = items.name.length > 30 ? <span className="tooltip1" key={'abcdeZ' + key} >{subcateName}<span className="tooltiptext textFLow">{items.name}</span></span> : items.name
                var descSub = items.description.length > 30 ? <span className="tooltip1" key={'abcdeZ' + key}>{descrip}<span className="tooltiptext textFLow">{items.description}</span></span> : items.description
                return (
                    {
                        '#': key + 1,
                        'Name': nameSub,
                        'Description': descSub,
                        'Created on': moment(items.createdAt).utcOffset("+05:30").format('DD/MM/YY, H:mm'),
                        'Updated on': moment(items.updatedAt).format('DD/MM/YY, H:mm'),
                        'Category': fname,
                        'Action': [<>{items.deletable === 'Yes' ? <Button className="action_btn text-black" outline color="" onClick={e => this.editFrom(e, items.id)}><MDBIcon title="Edit" far icon="edit" /></Button> : <></>}{items.deletable === 'Yes' ? <Button className="action_btn text-black" outline color="" onClick={e => this.confirm(e, items.id)}><MDBIcon far icon="trash-alt" title="Delete" /></Button> : <div className="text-danger" style={{ fontSize: '12px' }}>Can't Edit/Delete</div>}</>]
                    }
                )
            })
            if (subListRedirect.deleted) {
                if (toast.isActive(toastId)) {
                    toast.dismiss(toastId)
                    toastId = toast.success(subCate.message);
                    newProps.initialStateSub()
                } else {
                    toastId = toast.success(subCate.message);
                    newProps.initialStateSub()
                }
            }
            this.setState({
                data: {
                    ...this.state.data,
                    rows: testData
                },
                loader: false
            })
        } if (subCate && subCate.success === false) {
            this.setState({
                loader: false
            })
        }
    }
    componentWillUnmount() {
        this.props.initialState();
    }
    addForm = () => {
        this.setState({
            openForm: true
        })
    }
    editFrom = (e, id) => {
        this.setState({
            ...this.state,
            openEditFrom: true,
            editId: id
        })
    }
    render() {
        const { open, id, loader, dataList, errors, openForm, openEditFrom, editId } = this.state
        return (
            <div className="subCategoryPopup">
                <ToastContainer autoClose={4000} />
                {
                    !loader ?
                        <>
                            <Header />
                            <Container className="mt-0" fluid>
                                <Row>
                                    <Table {...this.state} addForm={this.addForm} />
                                    <ConfirmModal open={open} toggle={this.toggle} confirmation={this.confirmation} id={id} />
                                    <FormModel modelForm={<AddSubCate location="/add-subcategory" />} openForm={openForm} toggle={this.toggle} />
                                    <FormModel modelForm={<EditSubCate location="" editId={editId} />} openForm={openEditFrom} toggle={this.toggle} />

                                </Row>
                            </Container>
                        </>
                        :
                        <div className="loader">
                            <Spinner variant="light" />
                            <p style={{ color: '#fff', paddingTop: '15px' }}>Please wait......</p>
                        </div>
                }
            </div>
        )
    }
}
const mapDispatchState = (dispatch) => {
    return bindActionCreators({
        subCategory,
        subCategoryDelete,
        initialState,
        initialStateSub
    }, dispatch)
}
const mapGetStateNew = (state) => {
    return {
        subCate: state.subCategory.subCategory,
        subListDetail: state.subCategory.detailSubCategory,
        subListUpdate: state.subCategory.updateSubCategory,
        subListRedirect: state.subCategory,
        deleteSub: state.subCategory.deleteSub
    }
}
export default connect(mapGetStateNew, mapDispatchState)(SubCateList);