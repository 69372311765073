import React from 'react';
import { Link } from 'react-router-dom';
import {
    Button, Card,
    CardHeader,
    CardBody, Container, Form, FormGroup, Label, Input, Col, Row, Spinner,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';
import InlineError from '../../sharedFile/inlineError';
import dataJson from '../../sharedFile/allData'
import Required from '../../sharedFile/required';
export default function FormAdd(props) {
    const { errors, dataVarient, materialSuccess, loader, title, slugCate, slugSub, loaderSub, valueCate } = props
    let varients = materialSuccess && materialSuccess.subcategory && materialSuccess.subcategory.subcategoryCategory && materialSuccess.subcategory.subcategoryCategory.varientCategory
    let role = sessionStorage.userROLE.replace('_worker', '')
    var vairiantsLength = []
    varients && varients.map((items, key) => {
        return (items.deletedAt == null ?
            vairiantsLength.push({name:items.assignedVarient.name}):"")
    })
    return (
        <Container className="mt-0" fluid>
            <Row>
                <Col className="order-xl-1" xl="12">
                    <Card className="card_border">
                        <CardHeader className="bg-white border-0">
                            <Row className="align-items-center mobile_sec_col">
                                <div className="col">
                                    <h2 className="mb-0 text-center">{title} In House</h2>
                                </div>
                                <div className="col back_btn">
                                    <Nav className="justify-content-end" pills>
                                        <NavItem>
                                            <NavLink className="btn btn-primary " to={'/inhouses'} tag={Link}  >Back</NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Form className="form_section mb-5 main_col_form pad_col_none" onSubmit={props.onSubmit}>
                                <Row>
                                    <Col sm="12">
                                        <span className="italic-message">* - indicate required fields!!</span>
                                        <FormGroup error={!errors.subCategoryId}>
                                            <label
                                                className="form-control-label"
                                            >
                                                Sub Category <Required />
                                            </label>
                                            <div className="position-relative" >
                                                {
                                                    loaderSub ?
                                                        <div className="position-absolute loader-select">
                                                            <Spinner color="danger" />
                                                        </div> :
                                                        ''
                                                }
                                                <Input type="select" disabled={loaderSub ? true : false} name="subCategoryId" value={valueCate} onChange={props.onChange}>
                                                    <option value="">Select sub category</option>
                                                    {props.categorySuccess.subcategories && props.categorySuccess.subcategories.map((items, key) => {
                                                        return (
                                                            <>
                                                                {
                                                                    sessionStorage.userROLE === 'super-admin' ?
                                                                        <>{items.slug === 'zipper' ?
                                                                            ''
                                                                            :
                                                                            <option value={JSON.stringify({ 'id': items.id, 'slug': items.subcategoryCategory.slug, 'slugSub': items.slug })}>{items.name}</option>}</>
                                                                        : role === items.slug ?
                                                                            < option value={JSON.stringify({ 'id': items.id, 'slug': items.subcategoryCategory.slug, 'slugSub': items.slug })}>{items.name}</option>
                                                                            :
                                                                            ''
                                                                }
                                                            </>
                                                        )
                                                    })
                                                    }
                                                </Input>
                                                {errors.subCategoryId && <InlineError text={errors.subCategoryId} />}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="12">
                                        <FormGroup error={!errors.materialId}>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-name"
                                            >
                                                Select Material <Required />
                                            </label>
                                            <Input type="select" name="materialId" value={dataVarient.materialId} id="exampleSelectMulti" onChange={props.onChange}>
                                                <option>Select Material</option>
                                                {materialSuccess && materialSuccess.subcategory && materialSuccess.subcategory.materialSubCategory.map((items, key) => {
                                                    return <option value={items.materialId}>{items.assignedMaterial.name}</option>
                                                })
                                                }
                                            </Input>
                                            {errors.materialId && <InlineError text={errors.materialId} />}
                                        </FormGroup>
                                    </Col>
                                    <Col sm="12">
                                        <FormGroup error={!errors.quantity}>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-value"
                                            >
                                                Quantity <Required />
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                value={dataVarient.quantity}
                                                onKeyPress={props.onKeyPress}
                                                onChange={props.onChange}
                                                name="quantity"
                                                maxLength="10"
                                                id="input-value"
                                                placeholder="Quantity"
                                                type="text"
                                            />
                                            {errors.quantity && <InlineError text={errors.quantity} />}
                                        </FormGroup>
                                    </Col>
                                    {slugCate === 'ratiowise-family' && <Col sm="12">
                                        <FormGroup error={!errors.orderSize}>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-name"
                                            >
                                                Select Size<Required />
                                                </label>
                                            <Input type="select" name="orderSize" value={dataVarient.orderSize} id="exampleSelectMulti" onChange={props.onChange}>
                                                <option value=''>Select Size</option>
                                                {dataJson.sizeList.map((items, key) => {
                                                    return <option value={items} key={key}>{items}</option>
                                                })
                                                }
                                            </Input>
                                            {errors.orderSize && <InlineError text={errors.orderSize} />}
                                        </FormGroup>
                                    </Col>}
                                    {slugSub === 'dhaaga_color' && <Col sm="12">
                                        <FormGroup error={!errors.colorName}>
                                            <Label for="exampleSelectMulti">Color Name<Required /></Label>
                                            <Input
                                                className="form-control-alternative"
                                                value={dataVarient.colorName}
                                                onChange={props.onChange}
                                                name="colorName"
                                                id="input-value"
                                                placeholder="Color Name"
                                                type="text"
                                            />
                                            {errors.colorName && <InlineError text={errors.colorName} />}
                                        </FormGroup>
                                    </Col>}

                                </Row>

                                {
                                    slugCate === 'rib-dhaaga-family' ?
                                        <>{}</>
                                        :
                                        <>
                                            {dataVarient.varient && dataVarient.varient.map((itemsList, id) => {
                                                return <Row key={id} className="align-items-center" >
                                                    <Col sm="12">
                                                        <FormGroup error={!errors.varientId}>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-name"
                                                            >
                                                                Select Variants <Required />
                                                        </label>
                                                            <Input type="select" name="varientId" value={itemsList.varientId} className="variants-select" id="exampleSelectMulti" onChange={props.onChangeVarient(id)}>
                                                                <option value="">Select variant</option>
                                                                {vairiantsLength.map((items, key) => {
                                                                    return <option value={items.name}>{items.name}</option>
                                                                })
                                                                }
                                                            </Input>
                                                            <span
                                                                onClick={props.handleRemove(id)}
                                                                className="small button-remove"
                                                                style={{ border: '1px solid #e1e1e1', boxShadow: 'none', background: "#ffffff" }}
                                                            >x</span>
                                                            {errors.varientId && <InlineError text={errors.varientId} />}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="12">
                                                        <FormGroup error={!errors.value} className='m-0'>
                                                            {varients && varients.map((items, key) => {
                                                                if (items.deletedAt == null && items.assignedVarient.name === itemsList.varientId) {
                                                                    if (items.value === '') {
                                                                        return <><label className="form-control-label" htmlFor="input-value">Value<Required /></label><Input type="text" name="value" value={itemsList.value} maxLength="20" placeholder="Value" onChange={props.onChangeVarient(id)} /></>
                                                                    } else {
                                                                        let values = items.value.split(',')
                                                                        return <><label className="form-control-label" htmlFor="input-value">Value<Required /></label><Input type="select" name="value" key={'ah' + key} value={itemsList.value} onChange={props.onChangeVarient(id)} >
                                                                            <option value="">Select</option>
                                                                            {
                                                                                values.map(itemValue => {
                                                                                    return <option value={itemValue}>{itemValue}</option>
                                                                                })
                                                                            }

                                                                        </Input></>
                                                                    }
                                                                }
                                                            })}

                                                            {errors.value && <InlineError text={errors.value} />}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            })}
                                            <Col xs={12}>
                                            {vairiantsLength && vairiantsLength.length === dataVarient.varient.length ? <div className="mb-2"><InlineError text="You have reached at maximum limit of adding variants." /></div>:''}</Col>
                                            <Col sm="12" error={errors.varient}><div style={{ height: '19px' }}>{errors.varient && <InlineError text={errors.varient} />}</div></Col>
                                            <Row className="full-width">
                                                <Button color="primary" 
                                                disabled={vairiantsLength && vairiantsLength.length === dataVarient.varient.length || vairiantsLength.length ===0 ? true : false} className="add-more-button" onClick={props.onAddMoreVraients} >+</Button>
                                            </Row>
                                        </>
                                }
                                <Row className="align-items-center">
                                    <Col lg="12" className="custom-submit">
                                        <Button type="submit" disabled={loader ? true : false} className="my-4 submit-button" color="primary">
                                            {
                                                loader
                                                    ?
                                                    <Spinner color="light" />
                                                    :
                                                    title
                                            }
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
} 