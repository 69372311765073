import { 
    SET_BRAND_FAILED,
    SET_BRAND_SUCCESS, 
    ADD_BRAND_FAILED,
    ADD_BRAND_SUCCESS, 
    DELETE_BRAND_FAILED,
    DELETE_BRAND_SUCCESS, 
    VIEW_BRAND_FAILED,
    VIEW_BRAND_SUCCESS, 
    UPDATE_BRAND_FAILED,
    UPDATE_BRAND_SUCCESS ,
    GET_INITIALSTATE,
    SET_REMOVE_IMAGE_BRAND_FAILED
} from "../../sharedFile/actionType";

const initialState = {
    brand: [],
    detailbrand:[],
    error:false,
    redirect:false
}
const brand = (state = initialState, action) => {
    switch(action.type){
        case SET_REMOVE_IMAGE_BRAND_FAILED:
            return{
                ...state,
                imageDelete:action.error
            }
        case SET_BRAND_SUCCESS:
        return{
            ...state,
            brand: action.data
        }
        case SET_BRAND_FAILED:
        return{
            ...state,
            error:true,
            brand: action.error
        } 
        case ADD_BRAND_SUCCESS:
        return{
            ...state,
            brandAdd: action.data
        }
        case ADD_BRAND_FAILED:
        return{
            ...state,
            error:true,
            brandAdd: action.error
        }
        case DELETE_BRAND_SUCCESS:
        return{
            ...state,
            brand: {brands: state.brand.brands.filter(item => item.id !== action.data.brands.id), success: true, message:'Brand Deleted Successfully'},
            deleted:true
        }
        case DELETE_BRAND_FAILED:
        return{
            ...state,
            error:true,
            deleteBrand:action.error
        }
        case VIEW_BRAND_SUCCESS:
        return{
            ...state,
            detailbrand: action.data,
            redirect:action.redirect
        }
        case VIEW_BRAND_FAILED:
        return{
            ...state,
            error:true,
            detailbrand: action.error
        }
        case UPDATE_BRAND_SUCCESS:
        return{
            ...state,
            detailbrand: action.data,
            redirect:action.redirect
        }
        case UPDATE_BRAND_FAILED:
        return{
            ...state,
            error:true,
            detailbrand: action.error
        }
        case GET_INITIALSTATE:
            return{
                ...state,
                detailbrand:[],
                brandAdd:[],
                deleteBrand:[],
                redirect:false,
                deleted:false
            }
        default:
            return state
    }
    
}
export default brand;