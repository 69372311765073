import React from "react";
import Header from "../../components/Headers/Header";
import { Link } from 'react-router-dom';
import { updateSupplier, viewSupplier } from '../../services/actions/supplier'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MDBDataTable, MDBIcon } from 'mdbreact';
import { Card, CardHeader, Container, Row, Col, Spinner, NavItem, NavLink, Nav } from "reactstrap";
class ViewSupplier extends React.Component {
  state = {
    data: {
      firstName: '',
      lastName: '',
      email: '',
      gstNumber: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      companyName: '',
      mobile: '',
      category: '',
      country: ''
    },
    errors: {},
    loader: false,
    message: ""
  }

  componentDidMount() {
    this.props.viewSupplier(this.props.match.params.id);
    this.setState({
      loader: true
    })
  }

  componentWillReceiveProps(newProps) {
    const { supplier } = newProps
    if (supplier && supplier.success) {
      this.setState({
        data: {
          ...this.state.data,
          firstName: supplier.supplier && supplier.supplier.firstName,
          companyName: supplier.supplier && supplier.supplier.companyName,
          mobile: supplier.supplier && supplier.supplier.mobile,
          category: supplier.supplier && supplier.supplier.suppliercategory.name,
          lastName: supplier.supplier && supplier.supplier.lastName,
          email: supplier.supplier && supplier.supplier.email,
          gstNumber: supplier.supplier && supplier.supplier.gstNumber,
          phone: supplier.supplier && supplier.supplier.phone,
          address: supplier.supplier && supplier.supplier.address,
          city: supplier.supplier && supplier.supplier.city,
          state: supplier.supplier && supplier.supplier.state,
          zip: supplier.supplier && supplier.supplier.zip,
          country: supplier.supplier && supplier.supplier.country
        },
        loader: false
      })
      // toast.success("updated successfully!");
    } if (supplier && supplier.success === false) {
      this.setState({
        loader: false
      })
      // toast.error(newProps.supplier.supplier)
    }
  }
  render() {
    const datatable = {
      columns: [
        {
          label: '#',
          field: 's_no',
          sort: 'asc',
          width: 150
        },
        {
          label: 'Order Number',
          field: 'order_number',
          sort: 'asc',
          width: 270
        },
        {
          label: 'Order Name',
          field: 'order_name',
          sort: 'asc',
          width: 200
        },
        {
          label: 'Created By',
          field: 'created_by',
          sort: 'asc',
          width: 100
        },
        {
          label: 'Created On',
          field: 'created_on',
          sort: 'asc',
          width: 150
        },
        {
          label: 'Action',
          field: 'action',
          sort: 'asc',
          width: 100
        }
      ],
      rows: [
        {
          s_no: '1',
          order_number: '987654321',
          order_name: 'DEMO_ORDER_1',
          created_by: 'DEMO_USER_1',
          created_on: '04/02/2020',
          // action: [<a href="" target="_blank" className="action_btn text-black"><MDBIcon icon="download" /></a>]
          action: [<span className="action_btn text-black"><MDBIcon icon="download" /></span>]
        },
        {
          s_no: '2',
          order_number: '987654322',
          order_name: 'DEMO_ORDER_2',
          created_by: 'DEMO_USER_2',
          created_on: '05/02/2020',
          action: [<span className="action_btn text-black"><MDBIcon icon="download" /></span>]
        },
        {
          s_no: '3',
          order_number: '987654323',
          order_name: 'DEMO_ORDER_3',
          created_by: 'DEMO_USER_3',
          created_on: '06/02/2020',
          action: [<span className="action_btn text-black"><MDBIcon icon="download" /></span>]
        }
      ]
    }
    const { firstName,
      lastName,
      email,
      phone, address, gstNumber,
      city, state, zip,
      companyName,
      mobile,
      category,
      country,
    } = this.state.data

    return (
      <>
        <Header />
        <Container className="mt-0" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="card_border">
                <CardHeader className="bg-white border-0 mb-4">
                  <Row className="align-items-center mobile_sec_col">
                    <div className="col">
                      <h6 className="text-uppercase text-light ls-1 mb-1">
                      </h6>
                      <h2 className="mb-0 text-mob-left">Supplier Information</h2>
                    </div>
                    <div className="col back_btn">
                      <Nav className="justify-content-end" pills>
                        <NavItem>
                          <NavLink className="btn btn-primary" to={'/suppliers'} tag={Link}  >Back</NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </Row>
                </CardHeader>
                <div className="supplier-information">
                  <div className="col-md-12 box-wrapper">
                    <div className="supplier-basic-information">
                      <h2>Basic Information</h2>
                      {
                        this.state.loader ?
                          <div className="text-center"><Spinner /></div>
                          :
                          <div className="supplier-basic-details">
                            <p><span className="test_heading">Name: </span>{firstName} {lastName}</p>
                            <p><span className="test_heading">Company Name: </span>{companyName}</p>
                            <p><span className="test_heading">Email: </span>{email}</p>
                            <p><span className="test_heading">Contact: </span>{mobile}{phone ? ',' : ''}{phone}</p>
                            <p><span className="test_heading">Material Category: </span>{category && category !== '' ? category : 'N/A'}</p>
                            <p><span className="test_heading">GST Number: </span>{gstNumber && gstNumber !== '' ? gstNumber : 'N/A'}</p>
                          </div>
                      }
                    </div>
                  </div>
                  <div className="col-md-12 box-wrapper">
                    <div className="supplier-basic-information">
                      <h2>Address</h2>
                      {
                        this.state.loader ?
                          <div className="text-center"><Spinner /></div>
                          :
                          <div className="supplier-basic-details">
                            <p>{address}</p>
                            <p>{city}</p>
                            <p>{state}</p>
                            <p>{country}</p>
                            <p>{zip}</p>
                          </div>
                      }
                    </div>
                  </div>
                  <div className="col-md-12 box-wrapper">
                    <MDBDataTable
                      striped
                      bordered
                      hover
                      responsive
                      data={datatable}
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    updateSupplier,
    viewSupplier
  }, dispatch)
}
const mapGetStateNew = (state) => {
  return {
    supplier: state.supplier.addSupplier,
  }
}
export default connect(mapGetStateNew, mapDispatchState)(ViewSupplier);