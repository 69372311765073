import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  NavLink,
  Col,
  Nav,
  NavItem,
  Spinner
} from "reactstrap";
import { Link } from 'react-router-dom';
import InlineError from '../../sharedFile/inlineError'
import Required from '../../sharedFile/required';
function EditForm(props) {
  const { data, errors, loader } = props
  return (
    <Container className="mt-0" fluid>
      <Row>
        <Col className="order-xl-1" xl="12">
          <Row className="align-items-center mobile_sec_col">
            <div className="col">
              <h2 className="mb-0 text-center">Edit Variant</h2>
              <span className="italic-message">* - indicate required fields!!</span>
            </div>
          </Row>
          <Form onSubmit={props.onSubmit}>
            <div className="pl-lg-0">
              <Row>
                <Col lg="12">
                  <FormGroup error={!errors.name}>
                    <label
                      className="form-control-label"
                      htmlFor="input-name"
                    >
                      Variant Name<Required />
                    </label>
                    <Input
                      className="form-control-alternative"
                      value={data.name}
                      onChange={props.onChange}
                      id="input-name"
                      placeholder="Name"
                      maxLength="30"
                      name="name"
                      type="text"
                    />
                    {errors.name && <InlineError text={errors.name} />}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup error={!errors.description}>
                    <label
                      className="form-control-label"
                      htmlFor="input-description"
                    >
                      Variant Description<Required />
                    </label>
                    <Input
                      className="form-control-alternative"
                      value={data.description}
                      onChange={props.onChange}
                      name="description"
                      id="input-description"
                      placeholder="Description"
                      type="textarea"
                    />
                    {errors.description && <InlineError text={errors.description} />}
                  </FormGroup>
                </Col>

              </Row>

            </div>
            <Col lg="12" className="custom-submit">
              <Button type="submit" className="my-4 submit-button" disabled={loader ? true : false} color="primary">
                {
                  loader
                    ?
                    <Spinner color="light" />
                    :
                    'Submit'
                }
              </Button>
            </Col>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}
export default EditForm;