import React from "react";
import { MDBDataTable } from 'mdbreact';
// reactstrap components
import {
  Card,
  CardHeader,
} from "reactstrap";
function Tables(props) {
  const { data } = props
  return (
    <Card className="card_border">
      <CardHeader className="border-0 pt-0">
        <h2 className="mb-0">{props.title}</h2>
      </CardHeader>
      <div className="">
        <MDBDataTable
          striped
          bordered
          hover
          responsive
          data={data}
        />
      </div>
    </Card>
  );
}

export default Tables;
