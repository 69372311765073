var routes
  routes = [
    {
      path: "/dashboard",
      name: "Dashboard",
    },
    {
      path: "/variants",
      name: "Variants",
    },
    {
      path: "/categories",
      name: "Categories",
    },
    {
      path: "/sub-categories",
      name: "Sub Categories",
    },
    {
      path: "/material",
      name: "Materials",
    },
    {
      path: "/suppliers",
      name: "Suppliers",
    },
    {
      path: "/brands",
      name: "Brands",
    },
    {
      path: "/users",
      name: "Users",
    },
    {
      path: "/inhouses",
      name: "Inhouse",
    },
    {
      path: "/oldOrders",
      name: "Orders",
    },
    {
      path: "/poList",
      name: "PO",
    },
  ];
export default routes;
