import React from 'react';
import {
  Container,
  Col,
} from "reactstrap";
import error_img from '../../assets/img/error_img.png';
import { Link } from 'react-router-dom'
function Errorpage(props) {
  return (
    <Container className="mt-0" fluid>
      <Col className="error_page text-center mt-5" xl="12">
        <div className="error_img">
          <img src={error_img} className="img-fluid responsive-img" ></img>
        </div>
        <div className='error_msg mt-0'><h2>Page Not found</h2></div>
        <Link to="/">Go to Login Page</Link>
      </Col>
    </Container>
  )
}
export default Errorpage;