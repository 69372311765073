import React from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Spinner,
    NavItem,
    NavLink,
    Nav,
} from "reactstrap";
import InlineError from '../../sharedFile/inlineError'
import Header from '../../components/Headers/Header'
import Required from '../../sharedFile/required';
function UserForm(props) {
    const { errors, loader, title, data, roleList, location, edit } = props
    return (
        <>
            <Header />
            <Container className="mt-0" fluid>
                <Row>
                    <Col className="order-xl-1" xl="12">
                        <Card className="card_border">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center mobile_sec_col">
                                    <div className="col">
                                        <h2 className="mb-0 text-center">{title}</h2>
                                    </div>
                                    <div className="col back_btn">
                                        <Nav className="justify-content-end" pills>
                                            <NavItem>
                                                <NavLink className="btn btn-primary" to={'/users'} tag={Link}  >Back</NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody className="pt-0">
                                <Form className="form_section user_table_cust mb-5" onSubmit={props.onSubmit}>
                                    <div className="pl-lg-0">
                                        <Row>
                                            <Col lg="12" mg="12">
                                                <span className="italic-message">* - indicate required fields!!</span>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup error={!errors.userName}>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="User-name"
                                                    >
                                                        Username <Required />
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        onChange={props.onChange}
                                                        value={data.userName}
                                                        id="User-name"
                                                        placeholder="Username"
                                                        maxLength="30"
                                                        disabled={edit}
                                                        name="userName"
                                                        type="text"
                                                    />
                                                    {errors.userName && <InlineError text={errors.userName} />}
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup error={!errors.firstName}>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="First-name"
                                                    >
                                                        First Name <Required />
                                                </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        onChange={props.onChange}
                                                        value={data.firstName}
                                                        id="First-name"
                                                        maxLength="30"
                                                        placeholder="First Name"
                                                        name="firstName"
                                                        type="text"
                                                    />
                                                    {errors.firstName && <InlineError text={errors.firstName} />}
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup error={!errors.lastName}>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="Last-name"
                                                    >
                                                        Last Name
                                                </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        onChange={props.onChange}
                                                        id="Last-name"
                                                        value={data.lastName}
                                                        placeholder="Last Name"
                                                        maxLength="30"
                                                        name="lastName"
                                                        type="text"
                                                    />
                                                    {errors.lastName && <InlineError text={errors.lastName} />}
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup error={!errors.email}>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-Email"
                                                    >
                                                        Email <Required />
                                                </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        onChange={props.onChange}
                                                        id="input-Email"
                                                        value={data.email ? data.email : ''}
                                                        disabled={edit}
                                                        placeholder="Email"
                                                        name="email"
                                                        type="text"
                                                    />
                                                    {errors.email && <InlineError text={errors.email} />}
                                                </FormGroup>
                                            </Col>
                                            {location.pathname === '/addUser'
                                                ?
                                                <Col lg="6">
                                                    <FormGroup error={!errors.password}>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-Password"
                                                        >
                                                            Password <Required />
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            onChange={props.onChange}
                                                            id="input-Password"
                                                            style={{ textTransform: 'none' }}
                                                            value={data.password}
                                                            maxLength="20"
                                                            placeholder="Password"
                                                            name="password"
                                                            type="password"
                                                        />
                                                        {errors.password && <InlineError text={errors.password} />}
                                                    </FormGroup>
                                                </Col>
                                                :
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-newPassword"
                                                        >
                                                            Password
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            onChange={props.onChange}
                                                            id="input-newPassword"
                                                            style={{ textTransform: 'none' }}
                                                            value={data.newPassword}
                                                            placeholder="Password"
                                                            maxLength="20"
                                                            name="newPassword"
                                                            type="password"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            }

                                            <Col lg="6">
                                                <FormGroup error={!errors.phone}>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-Phone"
                                                    >
                                                        Mobile no. <Required />
                                                </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        onChange={props.onChange}
                                                        onKeyPress={props.onKeyPress}
                                                        id="input-Phone"
                                                        maxLength="15"
                                                        value={data.phone}
                                                        placeholder="Mobile no."
                                                        name="phone"
                                                        type="text"
                                                    />
                                                    {errors.phone && <InlineError text={errors.phone} />}
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup error={!errors.roleId}>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-Role"
                                                    >
                                                        Role <Required />
                                                </label>
                                                    <Input type="select" value={data.roleId} name="roleId" id="input-Role" onChange={props.onChange}>
                                                        <option>Select Role</option>
                                                        {roleList.products && roleList.products.map(items => {
                                                            return <option value={items.id}>{items.name}</option>
                                                        })}
                                                    </Input>
                                                    {errors.roleId && <InlineError text={errors.roleId} />}
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup error={!errors.address}>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-Address"
                                                    >
                                                        Address <Required />
                                                </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        onChange={props.onChange}
                                                        id="input-Address"
                                                        value={data.address}
                                                        placeholder="Address"
                                                        name="address"
                                                        type="text"
                                                    />
                                                    {errors.address && <InlineError text={errors.address} />}
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup error={!errors.city}>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-City"
                                                    >
                                                        City <Required />
                                                </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        onChange={props.onChange}
                                                        value={data.city}
                                                        id="input-City"
                                                        placeholder="City"
                                                        maxLength="30"
                                                        name="city"
                                                        type="text"
                                                    />
                                                    {errors.city && <InlineError text={errors.city} />}
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup error={!errors.state}>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-State"
                                                    >
                                                        State <Required />
                                                </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        onChange={props.onChange}
                                                        id="input-State"
                                                        value={data.state}
                                                        maxLength="30"
                                                        placeholder="State"
                                                        name="state"
                                                        type="text"
                                                    />
                                                    {errors.state && <InlineError text={errors.state} />}
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup error={!errors.country}>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-country"
                                                    >
                                                        Country <Required />
                                                </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        onChange={props.onChange}
                                                        id="input-country"
                                                        value={data.country}
                                                        maxLength="30"
                                                        placeholder="Country"
                                                        name="country"
                                                        type="text"
                                                    />
                                                    {errors.country && <InlineError text={errors.country} />}
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup error={!errors.zip}>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-zipCode"
                                                    >
                                                        Zip Code <Required />
                                                </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        onChange={props.onChange}
                                                        id="input-zipCode"
                                                        value={data.zip}
                                                        placeholder="Zip Code"
                                                        name="zip"
                                                        type="text"
                                                        maxLength="15"
                                                    />
                                                    {errors.zip && <InlineError text={errors.zip} />}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    <Row>
                                        <Col lg="12" className="p-0 pl-md-3 pr-md-3 custom-submit">
                                            <Button type="submit" disabled={loader ? true : false} className="my-4 submit-button" color="primary">
                                                {
                                                    loader
                                                        ?
                                                        <Spinner color="light" />
                                                        :
                                                        'Submit'
                                                }

                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default UserForm;