import React from "react";
import EditForm from './editForm';
import { updateMaterial, viewMaterial, deleteImageMaterial } from '../../services/actions/material'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Spinner
} from "reactstrap";
import { initialState } from '../../services/actions/stateInitial'
import { addUpdateValid,imageSizeValid } from '../../sharedFile/validator';
import { imagerUrl } from '../../config/config';
import { toast } from 'react-toastify';
var toastId = null
class EditMaterial extends React.Component {
  state = {
    data: {
      category: '',
      name: '',
      images: [],
      description: '',
      deletedImage: [],
      picture: []
    },
    imagesPreviewUrls: [],
    images: [],
    errors: {},
    errImage: {},
    loader: false,
    loaderView: false,
    message: ""
  }
  componentDidMount() {
    this.props.viewMaterial(this.props.editId);
    this.setState({
      loaderView: true
    })
  }
  onSubmit = (e) => {
    e.preventDefault();
    const { data, imagesPreviewUrls, images } = this.state
    const errors = addUpdateValid(data, imagesPreviewUrls);
    const errImage = imageSizeValid(data)
    this.setState({ errors,errImage });
    if (Object.keys(errors).length === 0 && Object.keys(errImage.picture).length === 0) {
      this.setState({
        loader: true
      })
      this.props.updateMaterial(this.props.editId, this.state.data)
    }
  }
  onFileChange = (e) => {
    let files = Array.from(e.target.files);
    files.forEach((file) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          data: {
            ...this.state.data,
            picture: [...this.state.data.picture, file],
          },
          imagesPreviewUrls: [...this.state.imagesPreviewUrls, { url: reader.result }]
        });
      }
      reader.readAsDataURL(file);
    });
  }
  onChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value.trimLeft().replace(/\s\s+/g, ' ')
      }
    })
  }
  onRemove = (e, key, id) => {
    this.setState({
      data: {
        ...this.state.data,
        picture: this.state.data.picture.filter((s, sidx) => key !== sidx),
      },
      imagesPreviewUrls: this.state.imagesPreviewUrls.filter((s, sidx) => key !== sidx)
    });
    this.props.deleteImageMaterial(id)
  }
  componentWillReceiveProps(newProps) {
    const { detailmaterial } = newProps
    if (detailmaterial && detailmaterial.success) {
      const material = detailmaterial.material
      let image = []
      image = material.materialImages.map((items) => {
        return { url: imagerUrl + items.image, id: items.id }
      })
      this.setState({
        data: {
          ...this.state.data,
          name: material.name,
          category: material.assignedMaterial[0].materialSubCategory.name,
          description: material.description,
        },
        imagesPreviewUrls: image,
        loader: false,
        loaderView: false
      })
      if (toast.isActive(toastId)) {
        toast.dismiss(toastId)
        toastId = toast.success(detailmaterial.message);
        newProps.initialState()
      } else {
        toastId = toast.success(detailmaterial.message);
        newProps.initialState()
      }
    }
    if (detailmaterial && detailmaterial.success === false) {
      this.setState({
        loader: false,
        loaderView: false
      })
      newProps.initialState()
    }
  }
  render() {
    const { loaderView } = this.state
    return (
      <>
        {
          !loaderView ?
            <EditForm  {...this.state} onSubmit={this.onSubmit} onFileChange={this.onFileChange} onRemove={this.onRemove} onChange={this.onChange} />
            :
            <div className="text-center">
              <Spinner />
            </div>
        }
      </>
    );
  }
}
const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    viewMaterial,
    updateMaterial,
    deleteImageMaterial,
    initialState
  }, dispatch)
}
const mapGetStateNew = (state) => {
  return {
    detailmaterial: state.material.detailmaterial,
    materialRedirect: state.material
  }
}
export default connect(mapGetStateNew, mapDispatchState)(EditMaterial);
