import {apiUrl} from '../config/config';
class Api {
    static headers() {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }
    static fileHeadertpions() {
        return {'Authorization': "Bearer " + sessionStorage.userJWT}
    }

    static get(route) {
        return this.xhr(route, null, 'GET');
    }

    static put(route, params) {
        return this.xhr(route, params, 'PUT')
    }

    static post(route, params) {
        return this.xhr(route, params, 'POST')
    }

    static delete(route, params) {
        return this.xhr(route, params, 'DELETE')
    }

    static xhr(route, params, verb) {
        const url = `${apiUrl}${route}`;
        let formData;
        let options = Object.assign({ method: verb });
        if (params && (params.file || params.logo || params.picture)) {
            options.headers = Api.fileHeadertpions();
            formData  = new FormData();
            for(let name in params) {
                if(name === 'picture') {
                    for(let values of params[name]){
                        formData.append(name, values);
                    }
                } else {
                    formData.append(name, params[name]);
                }
            }
        } else {
            options.headers = Api.headers();
            params && delete params.file
            formData = JSON.stringify(params)
        }
        options = Object.assign(options, params ? { body: formData } : null);
        if (sessionStorage.userJWT) {
            options.headers = {
                ...options.headers,
                'Authorization': `Bearer ${sessionStorage.userJWT}`
            }
        }
        return fetch(url, options).then(resp => {
            let json = resp.json();
            if(resp.status === 401){
                sessionStorage.clear()
            }
            if (resp.ok) {
                return json
            }
            return json.then(err => { throw err });
        }).then(json => json);
    }
}

export default Api;
