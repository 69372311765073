import { all } from 'redux-saga/effects'
import userAuth from './userAuth';
import category from './category';
import varient from './varient';
import material from './material';
import supplier from './supplier';
import style from './style';
import brand from './brand';
import order from './order';
import subCategory from './subCategory';
import inHouse from './inHouse';
import poPDF from './poPDF';
import unitRatioWise from './unitRatioWise'
import dashboard from './dashboard'
export default function* rootSaga() {
    yield all([
        userAuth(),
        category(),
        varient(),
        material(),
        supplier(),
        style(),
        brand(),
        order(),
        subCategory(),
        inHouse(),
        poPDF(),
        unitRatioWise(),
        dashboard()
    ])
}