import {
    SET_PO_GENERATE_QUILTING_SUCCESS,
    SET_PO_GENERATE_QUILTING_FAILED,
    SET_PO_GENERATE_KORA_SUCCESS,
    SET_PO_GENERATE_KORA_FAILED,
    SET_PO_GENERATE_COLOR_SUCCESS,
    SET_PO_GENERATE_COLOR_FAILED,
    SET_PO_GENERATE_ZIPPER_SUCCESS,
    SET_PO_GENERATE_ZIPPER_FAILED,
    SET_PO_GENERATE_UNIT_SUCCESS,
    SET_PO_GENERATE_UNIT_FAILED,
    SET_PO_GENERATE_RATIO_SUCCESS,
    SET_PO_GENERATE_RATIO_FAILED,
    SET_PO_GENERATE_SUCCESS,
    SET_PO_GENERATE_FAILED,
    GET_INITIALSTATE,
    GET_PO_DETAIL_SUCCESS,
    GET_PO_DETAIL_FAILED,
    GET_PO_ORDER_LIST_SUCCESS,
    GET_PO_ORDER_LIST_FAILED,
} from "../../sharedFile/actionType";
const initialState = {
    poGenrate: [],
    poDetail:[],
    error: false,
}
const poPDF = (state = initialState, action) => {
    switch (action.type) {
        case GET_PO_ORDER_LIST_SUCCESS:
            return {
                poGenrate:action.data
            }
        case GET_PO_ORDER_LIST_FAILED:
            return {
                poGenrate:action.error
            }
        case GET_PO_DETAIL_SUCCESS:
            return {
                poDetail:action.data
            }
        case GET_PO_DETAIL_FAILED:
            return {
                poDetail:action.error
            }
        case SET_PO_GENERATE_QUILTING_SUCCESS:
            return {
                ...state,
                poGenrate: action.data
            }
        case SET_PO_GENERATE_QUILTING_FAILED:
            return {
                ...state,
                poGenrate: action.error
            }
        case SET_PO_GENERATE_KORA_SUCCESS:
            return {
                ...state,
                poGenrate: action.data
            }
        case SET_PO_GENERATE_KORA_FAILED:
            return {
                ...state,
                poGenrate: action.error
            }
        case SET_PO_GENERATE_COLOR_SUCCESS:
            return {
                ...state,
                poGenrate: action.data
            }
        case SET_PO_GENERATE_COLOR_FAILED:
            return {
                ...state,
                poGenrate: action.error
            }
        case SET_PO_GENERATE_ZIPPER_SUCCESS:
            return {
                ...state,
                poGenrate: action.data
            }
        case SET_PO_GENERATE_ZIPPER_FAILED:
            return {
                ...state,
                poGenrate: action.error
            }
        case SET_PO_GENERATE_UNIT_SUCCESS:
            return {
                ...state,
                poGenrate: action.data
            }
        case SET_PO_GENERATE_UNIT_FAILED:
            return {
                ...state,
                poGenrate: action.error
            }
        case SET_PO_GENERATE_RATIO_SUCCESS:
            return {
                ...state,
                poGenrate: action.data
            }
        case SET_PO_GENERATE_RATIO_FAILED:
            return {
                ...state,
                poGenrate: action.error
            }
        case SET_PO_GENERATE_SUCCESS:
            return {
                ...state,
                poGenrate: action.data
            }
        case SET_PO_GENERATE_FAILED:
            return {
                ...state,
                poGenrate: action.error
            }
        case GET_INITIALSTATE:
            return {
                poGenrate: []
            }
        default:
            return state
    }

}
export default poPDF;