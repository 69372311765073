import React from "react";
import Header from "../../components/Headers/Header";
import { viewMaterial } from '../../services/actions/material'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ToastContainer, toast } from 'react-toastify';
import { imagerUrl } from '../../config/config';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss'
import {  Card,  CardHeader,  CardBody,  Container,  Row,  Col,  NavItem,  NavLink,  Nav,} from "reactstrap";
class ViewMaterial extends React.Component {
  state = {
    data: {
      category: '',
      name: '',
      images: [],
      description: ''
    },
    errors: {},
    loader: false,
    message: ""
  }
  componentDidMount() {
    this.props.viewMaterial(this.props.match.params.id);
    this.setState({
      loader: true
    })
  }
  componentWillReceiveProps(newProps) {
    if (newProps.detailmaterial && newProps.detailmaterial.success) {
      const material = newProps.detailmaterial.material
      this.setState({
        data: {
          ...this.state.data,
          name: material.name,
          category: material.assignedMaterial && material.assignedMaterial[0].materialSubCategory && material.assignedMaterial[0].materialSubCategory.name,
          description: material.description,
          images: material.materialImages
        },
        loader: false
      })
    } else {
      this.setState({
        loader: false
      })
      if (newProps.detailvarient) {
        toast.error(newProps.detailvarient)
      }
    }
  }
  render() {
    return (
      <>
        <Header />
        <ToastContainer autoClose={10000} />
        <Container className="mt-0" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="card_border">
                <CardHeader className="bg-white border-0 mb-md-3 mb-2">
                  <Row className="align-items-center mobile_sec_col">
                    <div className="col">
                      <h6 className="text-uppercase text-light ls-1 mb-1">
                      </h6>
                      <h2 className="mb-0 text-mob-left text-center">View Material</h2>
                    </div>
                    <div className="col back_btn">
                      <Nav className="justify-content-end" pills>
                        <NavItem>
                          <NavLink className="btn btn-primary" to={'/material'} tag={Link}  >Back</NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0 detail_page mb-5 shadow">
                  <Row>
                    <Col className="p-4" md="5">
                      <h3 className="mb-4">Material Picture</h3>
                      <div className="card_body">{
                        this.state.data.images.length ?
                          this.state.data.images && this.state.data.images.map((items, key) => {
                            return (
                              <Col className="curcle_img text-center">
                                <img src={items.image ? `${imagerUrl}` + items.image : '/defult_image.jpg'} height="100" alt="material"></img>

                              </Col>
                            )
                          }) :
                          <Col className="curcle_img text-center">
                            <img src={'/defult_image.jpg'} height="100" alt="material"></img>
                          </Col>
                      }

                      </div>
                    </Col>
                    <Col className="p-md-4 p-2" md="6">
                      <h3 className="mb-md-3 mb-2">Material Detail</h3>
                      <CardBody className="p-1">
                        <h4 className="mb-0">Name:</h4>
                        <p className="mb-0">{this.state.data.name}</p>
                      </CardBody>
                      <CardBody className="p-1">
                        <h4 className="mb-0">Sub-Catgeory:</h4>
                        <p className="mb-0">{this.state.data.category}</p>
                      </CardBody>
                      <CardBody className="p-1">
                        <h4 className="mb-0">Description:</h4>
                        <p className="mb-0">{this.state.data.description}</p>
                      </CardBody>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    viewMaterial
  }, dispatch)
}
const mapGetStateNew = (state) => {
  return {
    detailmaterial: state.material.detailmaterial
  }
}
export default connect(mapGetStateNew, mapDispatchState)(ViewMaterial);
