import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

const RepeatConfirmModal = (props) => {
  return (
    <div>
      <Modal isOpen={props.open} toggle={props.toggle} className={'clssName modal-dialog-centered delete-confirmation'}>
        <ModalBody>
          <div className="delete-icon" style={{ color: '#58c57e' }}><i class="far fa-check-circle"></i></div>
          <div className="warring-message">Are you sure?</div>
          <div className="main-message"> Are you sure you want to repeat this order?</div>
        </ModalBody>
        <ModalFooter className="pt-0">
          <Button color="primary" onClick={e => props.confirmation(e, props.id)}>Yes</Button>
          <Button color="secondary" onClick={props.toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
export default RepeatConfirmModal;