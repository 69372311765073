import React from "react";
import Header from "../../components/Headers/Header";
import AddForm from "./SupplierForm";
import {
  updateSupplier,
  viewSupplier,
  initialState,
  initialStateSupplier
} from "../../services/actions/supplier";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addSupplierValid } from "../../sharedFile/validator";
import { Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  categoryMaterial,
  initialStateMaterial
} from "../../services/actions/material";
import { category } from "../../services/actions/category";
var toastId = null;
class EditSupplier extends React.Component {
  state = {
    data: {
      companyName: "",
      firstName: "",
      lastName: "",
      email: "",
      gstNumber: "",
      phone: "",
      mobile: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      materialId: [],
      materialjson: []
    },
    materialSuccess: [],
    errors: {},
    loader: false,
    loaderPage: false,
    updatedCategory: "",
    supplier: [],
    selected: [],
    id:this.props.match.params.id,
    // category: [],
    message: ""
  };
  componentDidMount() {
    this.props.viewSupplier(this.state.id);
    this.setState({
      loaderPage: true
    });
  }
  supplierChange = e => {
    this.props.categoryMaterial(e.target.value);
    this.setState({
      materialSuccess: [],
      data: {
        ...this.state.data,
        categoryId: e.target.value,
        materialId: [],
        materialjson: []
      },
      updatedCategory: e.target.value
    });
    this.props.initialStateMaterial();
  };
  onSubmit = e => {
    e.preventDefault();
    const errors = addSupplierValid(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({
        loader: true
      });
      let data = this.state.data;
      if (this.state.updatedCategory != "") {
        data.categoryId = this.state.updatedCategory;
      }
      this.props.updateSupplier(this.props.match.params.id, data);
    }
  };
  onChange = e => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value.trimLeft().replace(/\s\s+/g, " ")
      }
    });
  };
  componentWillMount() {
    this.props.category();
  }
  componentWillReceiveProps(newProps) {
    const { supplier, supplierRedirect, materialSuccess } = newProps;
    if (supplier && supplier.success) {
      const supplierList = supplier.supplier;
      let supplierMaterial = [];
      let material = [];
      let materialJson = [];
      supplierList &&
        supplierList.suppliermaterial &&
        supplierList.suppliermaterial.map(val => {
          if (val != null)
            return (
              material.push(val.materialId),
              supplierMaterial.push(val.name),
              materialJson.push(
                JSON.stringify({ id: val.materialId, name: val.name })
              )
            );
        });
      this.props.categoryMaterial(supplierList.categoryId);
      this.setState({
        data: {
          ...this.state.data,
          companyName: supplierList.companyName,
          firstName: supplierList.firstName,
          lastName: supplierList.lastName,
          email: supplierList.email,
          gstNumber: supplierList.gstNumber,
          phone: supplierList.phone,
          mobile: supplierList.mobile,
          address: supplierList.address,
          city: supplierList.city,
          state: supplierList.state,
          country: supplierList.country,
          zip: supplierList.zip,
          categoryId: supplierList.categoryId,
          materialId: material,
          materialjson: materialJson
        },
        materialSuccess: supplierMaterial,
        supplier: supplier,
        loader: false,
        loaderPage: false
      });
      this.props.initialStateSupplier();
    }
    if (supplier && supplier.success === false) {
      this.setState({
        loaderPage: false,
        loader: false
      });
      if (supplier && supplier.message) {
        if (toast.isActive(toastId)) {
          toast.dismiss(toastId);
          toastId = toast.error(supplier.message);
          newProps.initialState();
        } else {
          toastId = toast.error(supplier.message);
          newProps.initialState();
        }
      }
    }
    if (supplierRedirect.redirect == true) {
      if (supplier && supplier.message) {
        toast.success(supplier.message);
      }
      setTimeout(function() {
        newProps.history.push("/suppliers");
      }, 1000);
    }
  }
  componentWillUnmount() {
    this.props.initialState();
  }
  handleChange = e => {
    let matnam = [];
    let matId = [];
    for (let val of e.target.value) {
      let value = JSON.parse(val);
      matnam.push(value.name);
      matId.push(value.id);
    }
    this.setState({
      data: {
        ...this.state.data,
        materialId: matId,
        materialjson: e.target.value
      },
      materialSuccess: matnam
    });
  };
  onKeyPress = evt => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /^[0-9\b]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };
  render() {
    const { categorySuccess, materialSuccess } = this.props;
    const { supplier, loaderPage } = this.state;
    var materialsList;
    if (materialSuccess && materialSuccess.success == true) {
      materialsList = materialSuccess.materials;
    } else if (
      materialSuccess == undefined ||
      materialSuccess == null ||
      (supplier && supplier.supplier&&supplier.supplier.suppliermaterial&&supplier.supplier.suppliermaterial.length <= 0)
    ) {
      materialsList = supplier && supplier.material;
    }
    return (
      <>
        <ToastContainer autoClose={4000} />
        {!loaderPage ? (
          <>
            <Header />
            <AddForm
              {...this.state}
              onSelect={this.onSelect}
              onRemove={this.onRemove}
              supplierChange={this.supplierChange}
              category={categorySuccess}
              material={materialsList}
              handleChange={this.handleChange}
              onSubmit={this.onSubmit}
              onChange={this.onChange}
              onKeyPress={this.onKeyPress}
              title="Edit"
            />
          </>
        ) : (
          <div className="loader">
            <Spinner variant="light" />
            <p style={{ color: "#fff", paddingTop: "15px" }}>
              Please wait......
            </p>
          </div>
        )}
      </>
    );
  }
}
const mapDispatchState = dispatch => {
  return bindActionCreators(
    {
      category,
      categoryMaterial,
      updateSupplier,
      viewSupplier,
      initialStateSupplier,
      initialStateMaterial,
      initialState
    },
    dispatch
  );
};
const mapGetStateNew = state => {
  return {
    categorySuccess: state.category.category,
    materialSuccess: state.material.materialCategory,
    supplier: state.supplier.addSupplier,
    supplierRedirect: state.supplier
  };
};
export default connect(mapGetStateNew, mapDispatchState)(EditSupplier);
