import React from "react";
import {
  Container
} from 'reactstrap';
import DateOrder from "../dateByOrder/dateOrder";

class MainfabricForm extends React.Component {
  render() {
    let urlHeading = this.props.match.params.item_slug
    let url=this.props.location.pathname
    return (
      <>
        <Container className="mt-0" fluid>
          <div className="card_border card">
            <DateOrder url={url} urlHeading={urlHeading}/>
          </div>
        </Container>
      </>
    );
  }
}
export default MainfabricForm;
