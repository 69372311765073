import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, Form, Row, Col, Input, FormGroup } from 'reactstrap';
import dataJson from '../sharedFile/allData'
import RepeatConfirmModal from './repeatConfirm';
import { mainAddRepeatZipper, initialState } from '../services/actions/order';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { bindActionCreators } from 'redux'
class AddSameZipperData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                useArea: '',
                pageNumber: '',
                zipperNeed: "",
                search: '',
                addQTY: '',
                specification: '',
                supplierId: '',
                deliveryDate: '',
                search:''
            },
            open: false
        }
    }
    onChange = (e) => {
        this.setState({
            data: {
                ...this.state.data,
                [e.target.name]: e.target.value
            }
        })
    }
    onKeyPress(evt) {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        var regex = /^[0-9\b]+$/;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }
    componentWillReceiveProps(props) {
        const { addSameData, addSame, search } = props
        if (addSame == true) {
            this.setState({
                data: {
                    ...this.state.data,
                    ...addSameData,
                    search:search
                },
            })
        }
    }
    confirmation = (e, id) => {
        this.setState({
            open: true
        })
    }
    toggle = () => {
        this.setState({
            open: false
        })
    }
    confirmAddRow = () => {
        this.setState({
            open: false,
        })
        this.props.toggle();
        this.props.updateRowList()
        this.props.mainAddRepeatZipper(this.state.data)
    }
    componentWillUnmount() {
        this.props.initialState()
    }
    render() {
        const { addSame, toggle, suppliers } = this.props
        const { data, open } = this.state
        return (
            <Modal isOpen={addSame} toggle={toggle} className={'clssName modal-dialog-centered'}>
                <ToastContainer autoClose={10000} />
                <ModalBody>
                    <h3 className="text-center">Add Same Detail</h3>
                    <Form className="mb-5 main_col_form pad_col_none" onSubmit={this.onSubmit}>
                        <Row>
                            <Col sm="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-name"
                                    >
                                        Zipper Use Area
                                                </label>
                                    <Input type="select" name="useArea" defaultValue={data.useArea} id="exampleSelectMulti" onChange={this.onChange}>
                                        <option>Select Zipper Area</option>
                                        {dataJson.zipperArea.map((items, key) => {
                                            return (
                                                <option value={items} key={key}>{items}</option>
                                            )
                                        })
                                        }
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-name"
                                    >
                                        No. Of Zipper Use
                                                </label>
                                    <Input type="text" onKeyPress={this.onKeyPress.bind(this)} name="zipperNeed" value={data.zipperNeed} id="exampleSelectMulti" onChange={this.onChange}>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-name"
                                    >
                                        Add Qty %
                                                </label>
                                    <Input type="select" name="addQTY" value={data.addQTY} id="exampleSelectMulti" onChange={this.onChange}>
                                        <option>Select Add Qty %</option>
                                        {dataJson.addQuantity.map((items, key) => {
                                            return (
                                                <option value={items} key={key}>{items + '%'}</option>
                                            )
                                        })
                                        }
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-name"
                                    >
                                        Zipper specification
                                                </label>
                                    <Input type="text" name="specification" value={data.specification} id="exampleSelectMulti" onChange={this.onChange}>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-name"
                                    >
                                        Supllier
                                                </label>
                                    <Input type="select" name="supplierId" value={data.supplierId} id="exampleSelectMulti" onChange={this.onChange}>
                                        <option>Select Supllier</option>
                                        {suppliers && suppliers.map((value, key) => {
                                            return <option value={value.id} key={key}>{value.companyName}</option>
                                        })
                                        }
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-name"
                                    >
                                        Delivery
                                                </label>
                                    <Input type="date" name="deliveryDate" value={data.deliveryDate} id="exampleSelectMulti" onChange={this.onChange}>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter className="pt-0">
                    <Button color="primary" onClick={e => this.confirmation(e, data.brandOrderId)}>Yes</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
                <RepeatConfirmModal name="Edit this record" open={open} toggle={this.toggle} confirmation={this.confirmAddRow} />
            </Modal>
        )
    }
}
const mapDispatchState = (dispatch) => {
    return bindActionCreators({
        mainAddRepeatZipper,
        initialState
    }, dispatch)
}
export default connect(null, mapDispatchState)(AddSameZipperData);