import { 
    GET_ORDER,     
    GET_MATERIALORDER_ADD, 
    GET_ORDER_DELETE,
    GET_ORDER_VIEW,
    GET_ORDER_UPDATE,
    GET_ORDER_UPLOAD,
    GET_BRANDORDER_VIEW,
    GET_BRAND_ORDER_List_VIEW,
    GET_MAIN_GROUP,
    GET_MAIN_ZIPPER,
    GET_POST_MAIN_ZIPPER,
    GET_INITIALSTATE,
    GET_RIB_DHAAGA,
    GET_RIB_DHAAGA_DETAIL,
    GET_POLYFILL,
    GET_POLYFILL_DETAIL,
    GET_LINING,
    GET_LINING_DETAIL,
    GET_MAINQUILT,
    GET_MAINQUILT_DETAIL,
    GET_LININGQUILT,
    GET_LININGQUILT_DETAIL,
    TOOLTIP_TOGGLE,
    GET_INHOUSE_UPLOAD,
    GET_INHOUSE_VIEW,
    GET_INHOUSE_STOCK,
    GET_OLD_ORDERS,
    GET_EDIT_MAIN_ORDERS,
    GET_DELETE_MAIN_ORDERS,
    GET_REPEAT_ORDERS,
    GET_NOTE,
    GET_COLOR_DHAAGA,
    GET_ADD_COLOR_DHAAGA,
    GET_INITIALSTATE_NOTE,
    GET_RATIOINHOUSE_STOCK,
    GET_ZIPPER_ADD_ROW,
    GET_ZIPPER_ADD_REPEAT,
    GET_INITIALSTATE_SUB
} from "../../sharedFile/actionType";

export const materialOrder = (data)=>({
    type:GET_ORDER,
    data
})
export const colorDhaaga = (data)=>({
    type:GET_COLOR_DHAAGA,
    data
})
export const colorDhaagaAdd = (data)=>({
    type:GET_ADD_COLOR_DHAAGA,
    data
})
export const noteSubmit = (data)=>({
    type:GET_NOTE,
    data
})
export const noteinitialState = (data) => ({
    type: GET_INITIALSTATE_NOTE,
    data,
})

export const repeatOrder = (data,)=>({
    type:GET_REPEAT_ORDERS,
    data,
})
export const editMainOrder = (data)=>({
    type:GET_EDIT_MAIN_ORDERS,
    data
})
export const deleteMainOrder = (data,pageNumber,id)=>({
    type:GET_DELETE_MAIN_ORDERS,
    data,
    pageNumber,
    id
})
export const oldOrder = (data)=>({
    type:GET_OLD_ORDERS,
    data
})

export const addMaterialOrder = (data) => ({
    type:GET_MATERIALORDER_ADD,
    data
})

export const deleteOrder = (data) => ({
    type:GET_ORDER_DELETE,
    data
})

export const viewOrder = (data) => ({
    type: GET_ORDER_VIEW,
    data
})

export const updateOrder = (id,data) => ({
    type: GET_ORDER_UPDATE,
    id,
    data
})

export const uploadOrder = (data) => ({
    type: GET_ORDER_UPLOAD,
    data
})

export const uploadInhouse = (data) => ({
    type: GET_INHOUSE_UPLOAD,
    data
})

export const getInhouse = () => ({
    type: GET_INHOUSE_VIEW
})

export const getInhouseStocks = (data) => ({
    type: GET_INHOUSE_STOCK,
    data
})

export const getRatioInhouseStocks = (data) => ({
    type: GET_RATIOINHOUSE_STOCK,
     data
}) 
export const getBrandOrder = (data) => ({
    type: GET_BRANDORDER_VIEW,
    data
})
export const brandOrderList = (data) => ({
    type: GET_BRAND_ORDER_List_VIEW,
    data
})

export const mainorderGroup = (data) => ({
    type: GET_MAIN_GROUP,
    data
})
export const mainZipper = (data) => ({
    type: GET_MAIN_ZIPPER,
    data
})
export const mainAddRowZipper = (data) => ({
    type: GET_ZIPPER_ADD_ROW,
    data
})
export const mainAddRepeatZipper = (data) => ({
    type: GET_ZIPPER_ADD_REPEAT,
    data
})
export const mainZipperAdd  = (data) => ({
    type: GET_POST_MAIN_ZIPPER,
    data
})
export const initialState =()=>({
    type:GET_INITIALSTATE
})

export const ribDhaagaOrder = (data) => ({
    type: GET_RIB_DHAAGA,
    data
})

export const getRibDhaagaOrder = (data) => ({
    type: GET_RIB_DHAAGA_DETAIL,
    data
})

export const addpolyfillOrder = (data) => ({
    type: GET_POLYFILL,
    data
})

export const getpolyfillOrder = (data) => ({
    type: GET_POLYFILL_DETAIL,
    data
})

export const addLiningOrder = (data) => ({
    type: GET_LINING,
    data
})

export const getLiningOrder = (data) => ({
    type: GET_LINING_DETAIL,
    data
})

export const addMainQuiltOrder = (data) => ({
    type: GET_MAINQUILT,
    data
})

export const getMainQuiltOrder = (data) => ({
    type: GET_MAINQUILT_DETAIL,
    data
})

export const addLiningQuiltOrder = (data) => ({
    type: GET_LININGQUILT,
    data
})

export const getLiningQuiltOrder = (data) => ({
    type: GET_LININGQUILT_DETAIL,
    data
})

export const tooltipToggle = (data, id) => ({
    type: TOOLTIP_TOGGLE,
    data,
    id
})
