import { call, put, takeLatest, all } from "redux-saga/effects";
import Api from "../../lib/api";
import {
    GET_ADD_INHOUSE,
    SET_ADD_INHOUSE_SUCCESS,
    SET_ADD_INHOUSE_FAILED,
    GET_DELETE_INHOUSE,
    SET_DELETE_INHOUSE_SUCCESS,
    SET_DELETE_INHOUSE_FAILED,
} from "../../sharedFile/actionType";
function addInhouseApi(action){
    const {data} = action
    return Api.post(
        `inhouse/`, {
          'materialId': data.materialId,
          'quantity': data.quantity,
          'varients': data.varients
        }
    );
}
function* addInhouse(action){
    try {
        const resp = yield call(addInhouseApi, action);
        if (resp.success === true) {
          yield put({ type: SET_ADD_INHOUSE_SUCCESS, data: resp })
        } else {
          yield put({ type: SET_ADD_INHOUSE_FAILED, error: resp })
        }
      } catch (resp) {
        yield put({ type: SET_ADD_INHOUSE_FAILED, error: resp })
      }
}
function deleteInhouseApi(action){
  const {data} = action
  return Api.delete(
      `inhouse/`+data
  );
}
function* deleteInhouse(action){
  try {
      const resp = yield call(deleteInhouseApi, action);
      if (resp.success === true) {
        yield put({ type: SET_DELETE_INHOUSE_SUCCESS, data: resp, id:action.data })
      } else {
        yield put({ type: SET_DELETE_INHOUSE_FAILED, error: resp })
      }
    } catch (resp) {
      yield put({ type: SET_DELETE_INHOUSE_FAILED, error: resp })
    }
}
function* category() {
    yield all([
      takeLatest(GET_ADD_INHOUSE,addInhouse),
      takeLatest(GET_DELETE_INHOUSE,deleteInhouse),
    ])
}


export default category;