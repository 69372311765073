import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import AdminRoute from './layouts/adminRoute';
import GuestRoute from './layouts/guestRoute';
import Login from "./views/auth/login/Login";
import Profile from "./views/auth/profile/Profile";
import Index from "./views/Index.jsx";
import Categories from "./views/categories/CategoriesList";
import Materials from "./views/materials/MaterialsList";
import Varient from "./views/varients/VarientsList";
import ViewCategory from "./views/categories/ViewCategory";
import AddVarient from "./views/varients/AddVarient";
import ViewVarient from "./views/varients/ViewVarient";
import EditVarient from "./views/varients/EditVarient";
import forgetpassword from './views/auth/Forget/ForgetPassword'
import Suppliers from './views/suppliers/SuppliersList';
import AddSupplier from './views/suppliers/AddSupplier';
import EditSupplier from './views/suppliers/EditSupplier';
import ViewSupplier from './views/suppliers/ViewSupplier';
import AddMaterial from './views/materials/AddMaterial';
import ViewMaterial from './views/materials/ViewMaterial';
import EditMaterial from './views/materials/EditMaterial';
import Brand from './views/brand/BrandList';
import AddBrand from './views/brand/AddBrand';
import ViewBrand from './views/brand/ViewBrand';
import EditBrand from './views/brand/EditBrand';
import Orders from './views/orders/brandOrder/Addorders';
import OldOrders from './views/orders/brandOrder/oldOrders';
import Mainordersheet from "./views/orders/brandOrder/Mainordersheet";
import Mainfabricsheet from "./views/orders/mainFabric/Mainfabricsheet";
import Mainfabricform from "./views/orders/mainFabric/MainfabricForm";
import Mainzipperform from "./views/orders/mainZipper/MainzipperForm";
import Mainzippersheet from "./views/orders/mainZipper/Mainzippersheet";
import Mainribdhaga from "./views/orders/ribDhaaga/Mainribdhaga";
import MainRibdhaagafrom from "./views/orders/ribDhaaga/MainribdhaagaForm";
import Fabricquiltingform from "./views/orders/mainFabricQuilting/MainfabricQuiltingForm";
import Fabricquiltingsheet from "./views/orders/mainFabricQuilting/MainfabricQuiltingsheet";
import AddInhouse from './views/inHouse/addInhouse';
import Inhouse from './views/inHouse/Inhouse'
import UserList from './views/uesrSignup/userList'
import UserAdd from './views/uesrSignup/userAdd'
import userEdit from "./views/uesrSignup/userEdit";
import UserDetailList from "./views/uesrSignup/userDetail";
import SubCateList from "./views/subCategories/subCateList";
import AddSubCate from "./views/subCategories/addSubCate";
import EditSubCate from "./views/subCategories/editSubCate";
import Errorpage from "./views/Error404/Errorpage";
import ColorDhaagaForm from './views/orders/ribDhaaga/colorDhaagaForm'
import ColorDhaaga from './views/orders/ribDhaaga/colorDhaaga'
import RatioWiseForm from './views/orders/ratioWise/ratioWiseFrom'
import RatioWise from './views/orders/ratioWise/ratioWise'
import UnitWiseForm from './views/orders/unitWise/unitWiseForm'
import UnitWise from './views/orders/unitWise/unitWise'
import PoList from './views/poList/poList'
import PoListDetail from './views/poList/poListDetail'
import { connect } from 'react-redux';
const App = (props, { location }) => {
    return (
        <div>
            <Switch>
                <GuestRoute location={location} exact path="/" component={Login} />
                <GuestRoute location={location} exact path="/login" component={Login} />
                <GuestRoute location={location} exact path="/forgetpassword" component={forgetpassword} />
                <AdminRoute location={location} exact path="/dashboard" component={sessionStorage.userROLE === 'super-admin' ? Index : Suppliers} />
                <AdminRoute location={location} exact path="/suppliers" component={Suppliers} />
                <AdminRoute location={location} exact path="/add-supplier" component={AddSupplier} />
                <AdminRoute location={location} exact path="/material" component={Materials} />
                <AdminRoute location={location} exact path="/profile" component={Profile} />
                <AdminRoute location={location} exact path="/view-supplier/:id" component={ViewSupplier} />
                <AdminRoute location={location} exact path="/edit-supplier/:id" component={EditSupplier} />
                <AdminRoute location={location} exact path="/add-material" component={AddMaterial} />
                <AdminRoute location={location} exact path="/view-material/:id" component={ViewMaterial} />
                <AdminRoute location={location} exact path="/edit-material/:id" component={EditMaterial} />
                <AdminRoute location={location} exact path="/add-inhouse" component={AddInhouse} />
                <AdminRoute location={location} exact path="/inhouses" component={Inhouse} />
                {
                    sessionStorage.userROLE == 'super-admin' ?
                        <Switch>
                            <AdminRoute location={location} exact path="/poList" component={PoList} />
                            <AdminRoute location={location} exact path="/poList/:id/:subId" component={PoListDetail} />
                            <AdminRoute location={location} exact path="/categories" component={Categories} />
                            <AdminRoute location={location} exact path="/sub-categories" component={SubCateList} />
                            <AdminRoute location={location} exact path="/add-subcategory" component={AddSubCate} />
                            <AdminRoute location={location} exact path="/edit-subcategory/:id" component={EditSubCate} />
                            <AdminRoute location={location} exact path="/variants" component={Varient} />
                            <AdminRoute location={location} exact path="/view-category/:id" component={ViewCategory} />
                            <AdminRoute location={location} exact path="/add-varient" component={AddVarient} />
                            <AdminRoute location={location} exact path="/view-varient/:id" component={ViewVarient} />
                            <AdminRoute location={location} exact path="/edit-varient/:id" component={EditVarient} />
                            <AdminRoute location={location} exact path="/brands" component={Brand} />
                            <AdminRoute location={location} exact path="/add-brand" component={AddBrand} />
                            <AdminRoute location={location} exact path="/view-brand/:id" component={ViewBrand} />
                            <AdminRoute location={location} exact path="/edit-brand/:id" component={EditBrand} />
                            <AdminRoute location={location} exact path="/orders" component={Orders} />
                            <AdminRoute location={location} exact path="/oldOrders" component={OldOrders} />
                            <AdminRoute location={location} exact path="/mainordersheet/:id" component={Mainordersheet} />
                            <AdminRoute location={location} exact path="/fabric/:item_slug" component={Mainfabricform} />
                            <AdminRoute location={location} exact path="/fabric/:item_slug/:id" component={Mainfabricsheet} />
                            <AdminRoute location={location} exact path="/main_zipper/:item_slug" component={Mainzipperform} />
                            <AdminRoute location={location} exact path="/main_zipper/:item_slug/:id" component={Mainzippersheet} />
                            <AdminRoute location={location} exact path="/ribdhaaga/kora_dhaaga" component={MainRibdhaagafrom} />
                            <AdminRoute location={location} exact path="/ribdhaaga/kora_dhaaga/:id" component={Mainribdhaga} />
                            <AdminRoute location={location} exact path="/ribdhaaga/dhaaga_color" component={ColorDhaagaForm} />
                            <AdminRoute location={location} exact path="/ribdhaaga/dhaaga_color/:id" component={ColorDhaaga} />
                            <AdminRoute location={location} exact path="/main_fabric/:item_slug" component={Fabricquiltingform} />
                            <AdminRoute location={location} exact path="/main_fabric/:item_slug/:id" component={Fabricquiltingsheet} />
                            <AdminRoute location={location} exact path="/ratiowise/:item_slug" component={RatioWiseForm} />
                            <AdminRoute location={location} exact path="/ratiowise/:item_slug/:id" component={RatioWise} />
                            <AdminRoute location={location} exact path="/unitwise/:item_slug" component={UnitWiseForm} />
                            <AdminRoute location={location} exact path="/unitwise/:item_slug/:id" component={UnitWise} />
                            <AdminRoute location={location} exact path="/users" component={UserList} />
                            <AdminRoute location={location} exact path="/addUser" component={UserAdd} />
                            <AdminRoute location={location} exact path="/userEdit/:id" component={userEdit} />
                            <AdminRoute location={location} exact path="/userDetail/:id" component={UserDetailList} />
                            <Redirect to="/"/>
                        </Switch> :
                        <Switch>
                            <AdminRoute location={location} exact path="/profile" component={Profile} />
                            <AdminRoute location={location} exact path="/fabric/:item_slug" component={Mainfabricform} />
                            <AdminRoute location={location} exact path="/fabric/:item_slug/:id" component={Mainfabricsheet} />
                            <AdminRoute location={location} exact path="/main_zipper/:item_slug" component={Mainzipperform} />
                            <AdminRoute location={location} exact path="/main_zipper/:item_slug/:id" component={Mainzippersheet} />
                            <AdminRoute location={location} exact path="/ribdhaaga/kora_dhaaga" component={MainRibdhaagafrom} />
                            <AdminRoute location={location} exact path="/ribdhaaga/kora_dhaaga/:id" component={Mainribdhaga} />
                            <AdminRoute location={location} exact path="/ribdhaaga/dhaaga_color" component={ColorDhaagaForm} />
                            <AdminRoute location={location} exact path="/ribdhaaga/dhaaga_color/:id" component={ColorDhaaga} />
                            <AdminRoute location={location} exact path="/main_fabric/:item_slug" component={Fabricquiltingform} />
                            <AdminRoute location={location} exact path="/main_fabric/:item_slug/:id" component={Fabricquiltingsheet} />
                            <AdminRoute location={location} exact path="/ratiowise/:item_slug" component={RatioWiseForm} />
                            <AdminRoute location={location} exact path="/ratiowise/:item_slug/:id" component={RatioWise} />
                            <AdminRoute location={location} exact path="/unitwise/:item_slug" component={UnitWiseForm} />
                            <AdminRoute location={location} exact path="/unitwise/:item_slug/:id" component={UnitWise} />
                            <Redirect to="/"/>
                        </Switch>
                }
                <Redirect to="/"/>
            </Switch>
        </div>
    )
}
export default App;