import React from "react";
import {
  Container,
  Row,
  Spinner
} from "reactstrap";
// core components
import Header from "../../components/Headers/Header.jsx";
import Table from "./SupplierTable"
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { category, deleteCategory } from '../../services/actions/category';
import { supplier, deleteSupplier, initialDelete } from '../../services/actions/supplier';
import { initialState } from '../../services/actions/stateInitial'
import ConfirmModal from '../../components/ConfirmModal';
import { ToastContainer, toast } from 'react-toastify';
var toastId = null
class SuppliersList extends React.Component {
  state = {
    data: {
      categoryId: '',
      city: '',
      state: '',
      date: '',
      limit: ''
    },
    category: {},
    supplier: {},
    pageNumber: 1,
    loader: false,
    open: false,
    id: '',
    name: ''
  }
  componentDidMount() {
    const { data } = this.state
    this.props.supplier(data);
    this.setState({
      loader: true
    })
  }
  handlePreviousClick = () => {
    this.setState({
      pageNumber: this.state.pageNumber - 1
    })
    const { pageNumber } = this.state
    this.props.category(pageNumber);
  }

  handleNextClick = () => {
    this.setState({
      pageNumber: this.state.pageNumber + 1
    })
    const { pageNumber } = this.state
    this.props.category(pageNumber);
  }
  handlePageClick = (event, index) => {
    this.setState({
      pageNumber: index
    })
    this.props.category(index);
  };
  toggle = () => {
    this.setState({
      ...this.state,
      open: false,
    })
  }
  confirm = (event, id, name) => {
    this.setState({
      ...this.state,
      open: true,
      id: id,
    })
  }
  componentWillUnmount() {
    this.props.initialDelete()
    this.props.initialState()
  }
  confirmation = (event, id) => {
    this.setState({
      open: false
    })
    this.props.deleteSupplier(id)
  }
  componentWillReceiveProps(props) {
    const { catgeorySuccess, deleteList, supplierUpdate } = props
    if (catgeorySuccess && catgeorySuccess.success) {
      this.setState({
        loader: false
      })
    }
    if (catgeorySuccess && catgeorySuccess.success === false) {
      this.setState({
        loader: false
      })
    }
    if (deleteList && deleteList.success) {
      if (toast.isActive(toastId)) {
        toast.dismiss(toastId)
        toastId = toast.success('Supplier Deleted Successfully');
        props.initialDelete()
      } else {
        toastId = toast.success('Supplier Deleted Successfully');
        props.initialDelete()
      }
      this.setState({
        loader: false
      })
    }
    if (deleteList && deleteList.success === false) {
      if (toast.isActive(toastId)) {
        toast.dismiss(toastId)
        toastId = toast.error(deleteList.message);
        props.initialDelete()
      } else {
        toastId = toast.error(deleteList.message);
        props.initialDelete()
      }
      this.setState({
        loader: false
      })
    }
  }
  render() {
    const { catgeorySuccess } = this.props
    const { open, loader } = this.state
    return (
      <>
        <ToastContainer autoClose={4000} />
        {
          !loader ?
            <>
              <Header />
              <Container className="mt-0" fluid>
                <Row>
                  <div className="col">
                    <Table title="Supplier Listing" handlePreviousClick={this.handlePreviousClick} handlePageClick={this.handlePageClick} handleNextClick={this.handleNextClick} confirm={this.confirm} data={catgeorySuccess} pageNumber={this.state.pageNumber} />
                  </div>
                  <ConfirmModal open={open} toggle={this.toggle} confirmation={this.confirmation} id={this.state.id} name={this.state.name} />
                </Row>
              </Container>
            </>
            :
            <div className="loader">
              <Spinner variant="light" />
              <p style={{ color: '#fff', paddingTop: '15px' }}>Please wait......</p>
            </div>
        }
      </>
    );
  }
}
const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    category,
    deleteCategory,
    supplier,
    initialDelete,
    deleteSupplier,
    initialState
  }, dispatch)
}
const mapGetStateNew = (state) => {
  return {
    catgeorySuccess: state.supplier.supplier,
    deleteList: state.supplier.deleteSupplier,
    supplierUpdate: state.supplier,
  }
}
export default connect(mapGetStateNew, mapDispatchState)(SuppliersList);
