/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import { subCategory } from '../services/actions/subCategory';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdbreact';
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button, Card, CardHeader, CardBody, NavItem, NavLink, Nav, Progress, Table, Container, Row, Col, Spinner, Badge, FormGroup, Input,
} from "reactstrap";
// core components
import {
  chartOptions, parseOptions, chartExample1, chartExample2
} from "../variables/charts";
import { dashboardCounts, dashboardPo, dashboardOrder, dashboardRevenue, purchaseOrder, subCateInitial } from '../services/actions/dashboard';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Header from "../components/Headers/Header.jsx";
import moment from 'moment';
import { CardActions } from "@material-ui/core";
import { supplier } from '../services/actions/supplier';
import { category } from '../services/actions/category';
class Index extends React.Component {
  state = {
    data: {
      from: "",
      to: ""
    },
    dataList2: [
      { month: 1, sum: "5400" },
      { month: 2, sum: "5400" },
      { month: 3, sum: "5400" },
      { month: 4, sum: "5400" }
    ],
    purchaseOrder: {
      subcategoryId: null,
      limit: '8'
    },
    activeNav: 1,
    supplier: {
      categoryId: '',
      city: '',
      state: '',
      date: '',
      limit: '20'
    },
    subCateList: [],
    dataList: {
      labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: [
        {
          label: "Performance",
          data: [0, 20, 10, 30, 15, 40, 20, 60, 60]
        },
        {
          label: "Performance New",
          data: [10, 20, 30, 25, 10, 30, 15, 40, 90]
        }
      ]
    },
    chartExample1Data: "data1",
    loaderCount: false,
    loaderPo: false,
    loaderOrder: false,
    loaderRevenue: false,
    loaderSupplier: false,
    loaderPurchase: false,
    loaderSubCate: false,
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
    let currentDate = new Date();
    let getYear = currentDate.getFullYear()
    let fullCurrentDate = getYear + '-' + '12' + '-' + '31'
    this.setState({
      data: {
        ...this.state.data,
        from: getYear + '-01-01',
        to: fullCurrentDate
      },
    })
  }
  componentDidMount() {
    this.props.dashboardCounts()
    this.props.dashboardPo()
    this.props.dashboardOrder(this.state.data)
    this.props.dashboardRevenue(this.state.data)
    this.props.supplier(this.state.supplier);
    this.props.subCategory();
    this.props.category();
    this.setState({
      loaderCount: true,
      loaderPo: true,
      loaderOrder: true,
      loaderRevenue: true,
      loaderSupplier: true,
      loaderSubCate: true
    })
  }
  componentWillReceiveProps(props) {
    const { countSuccess, poSuccess, orderDashSuccess, revenueSuccess, catgeorySuccess, subCategorySuccess, purchase } = props
    if (subCategorySuccess) {
      if (subCategorySuccess.success) {
        var purchaseOrder = {
          subcategoryId: subCategorySuccess.subcategories && subCategorySuccess.subcategories[0] && subCategorySuccess.subcategories[0].id,
          limit: '8'
        }
        this.setState({
          purchaseOrder: {
            ...this.state.purchaseOrder,
            ...purchaseOrder
          },
          subCateList: subCategorySuccess,
          loaderPurchase: true,
          loaderSubCate: false
        })
        props.subCateInitial()
        props.purchaseOrder(purchaseOrder)
      }
      if (subCategorySuccess.success === false) {
        this.setState({
          loaderSubCate: false
        })
      }
    }
    if (countSuccess) {
      if (countSuccess.success) {
        this.setState({
          loaderCount: false
        })
      }
      if (countSuccess.success === false) {
        this.setState({
          loaderCount: false
        })
      }
    }
    if (poSuccess) {
      if (poSuccess.success) {
        this.setState({
          loaderPo: false,
        })
      }
      if (poSuccess.success === false) {
        this.setState({
          loaderPo: false,
        })
      }
    }
    if (catgeorySuccess) {
      if (catgeorySuccess.success) {
        this.setState({
          loaderSupplier: false
        })
      }
      if (catgeorySuccess.success === false) {
        this.setState({
          loaderSupplier: false
        })
      }
    }
    if (revenueSuccess) {
      if (revenueSuccess.success) {
        this.setState({
          loaderRevenue: false
        })
      }
      if (revenueSuccess.success === false) {
        this.setState({
          loaderRevenue: false
        })
      }
    }
    if (orderDashSuccess) {
      if (orderDashSuccess.success) {
        this.setState({
          loaderOrder: false
        })
      }
      if (orderDashSuccess.success === false) {
        this.setState({
          loaderOrder: false
        })
      }
    }
    if (purchase) {
      if (purchase.success) {
        this.setState({
          loaderPurchase: false
        })
      }
      if (purchase.success === false) {
        this.setState({
          loaderPurchase: false
        })
      }
    }
  }
  onChange = (e) => {
    var purchaseOrder = {
      subcategoryId: e.target.value,
      limit: '8'
    }
    this.setState({
      purchaseOrder: {
        ...this.state.purchaseOrder,
        subcategoryId: e.target.value
      },
      loaderPurchase: true
    })
    this.props.purchaseOrder(purchaseOrder)
  }
  onChangeCategory = (e) => {
    var supplier = {
      categoryId: e.target.value,
      limit: '20'
    }
    this.setState({
      supplier: {
        ...this.state.supplier,
        categoryId: e.target.value
      },
      loaderSupplier: true
    })
    this.props.supplier(supplier)
  }
  render() {
    const { countSuccess, poSuccess, orderDashSuccess, revenueSuccess, catgeorySuccess, purchase, catgeoryList } = this.props
    const { loaderCount, loaderPo, loaderOrder, loaderRevenue, loaderSupplier, loaderSubCate, loaderPurchase, purchaseOrder, subCateList } = this.state
    let dataTable = {
      columns: [
        { label: 'Order #', field: 'Order #', sort: 'asc', width: 150 },
        { label: 'Style Code', field: 'Style Code', sort: 'asc', width: 200 },
        { label: 'Supplier', field: 'Supplier', sort: 'asc' },
        { label: 'Status', field: 'Status', width: 150, sort: 'asc' },
        { label: 'Due Date', field: 'Due Date', width: 150, sort: 'asc' }
      ],
    }
    let suplierTable = {
      columns: [
        { label: '#', field: 'S.N', sort: 'asc', width: 150 },
        { label: 'Supplier Name', field: 'Supplier Name', sort: 'asc', width: 200 },
        { label: 'Category', field: 'Category', width: 150, sort: 'asc' },
        { label: 'Supplier Address', field: 'Supplier Address', sort: 'asc' },
        { label: 'Total Orders', field: 'Total Orders', width: 150, sort: 'asc' }
      ],
    }
    let dataOrder = {
      labels: [],
      datasets: [
        {
          label: "Performance",
          data: []
        }
      ]
    }
    let dataRevenue = {
      labels: [],
      datasets: [
        {
          label: "Performance",
          data: []
        }
      ]
    }
    let total = 0
    revenueSuccess && revenueSuccess.data && revenueSuccess.data.map(items => {
      total = total + parseFloat(items.sum)
      dataOrder.labels.push(moment(items.month).format('MMM'))
      dataOrder.datasets[0].data.push(items.sum)
    })
    orderDashSuccess && orderDashSuccess.data && orderDashSuccess.data.map(items => {
      dataRevenue.labels.push(moment(items.month).format('MMM'))
      dataRevenue.datasets[0].data.push(items.count)
    })
    return (
      <>
        <Header />
        <Container className="mt-0" fluid>
          <Row className="mobil-res respoMargin">
            <Col xl="2" md="4" xs="6" className="mb-5">
              <Card className="shadow card-dashboard">
                <CardHeader className="bg-transparent">
                  {
                    loaderCount ?
                      <div className="text-center"><Spinner /></div> :
                      <div className="row">
                        <div className="col">
                          <h5 className="text-uppercase text-muted mb-0 card-title">Brand</h5>
                          <span className="h2 mb-0 text-danger">
                            {countSuccess && countSuccess.data && countSuccess.data.brands}</span>
                        </div>
                        <div className="col-auto col">
                          <div className="icon icon-shape text-danger rounded-circle shadow dashboard-icon">
                            <i className="fas fa-tshirt"></i>
                          </div>
                        </div>
                      </div>
                  }
                </CardHeader>
              </Card>
            </Col>
            <Col xl="2" md="4" xs="6" className="mb-5">
              <Card className="shadow card-dashboard">
                <CardHeader className="bg-transparent">
                  {
                    loaderCount ?
                      <div className="text-center"><Spinner /></div> :
                      <div className="row">
                        <div className="col">
                          <h5 className="text-uppercase text-muted mb-0 card-title">Supplier</h5>
                          <span className="h2 mb-0 text-warning">{countSuccess && countSuccess.data && countSuccess.data.supplier}</span>
                        </div>
                        <div className="col-auto col"><div className="icon icon-shape text-warning rounded-circle shadow dashboard-icon "><i className="fas fa-user-friends"></i></div></div></div>
                  }
                </CardHeader>
              </Card>
            </Col>
            <Col xl="2" md="4" xs="6" className="mb-5">
              <Card className="shadow card-dashboard">
                <CardHeader className="bg-transparent">
                  {
                    loaderRevenue ?
                      <div className="text-center"><Spinner /></div> :
                      <div className="row"><div className="col"><h5 className="text-uppercase text-muted mb-0 card-title">Revenue</h5><span className="h2 mb-0 text-yellow">{total} Rs</span></div><div className="col-auto col"><div className="icon icon-shape text-yellow rounded-circle shadow dashboard-icon"><i className="fas fa-rupee-sign"></i></div></div></div>
                  }
                </CardHeader>
              </Card>
            </Col>
            <Col xl="2" md="4" xs="6" className="mb-5">
              <Card className="shadow card-dashboard">
                <CardHeader className="bg-transparent">
                  {
                    loaderPo ?
                      <div className="text-center"><Spinner /></div> :
                      <div className="row"><div className="col"><h5 className="text-uppercase text-muted mb-0 card-title">Generated PO</h5><span className="h2 mb-0 text-info">{poSuccess && poSuccess.count}</span></div><div className="col-auto col"><div className="icon icon-shape text-info dashboard-icon rounded-circle shadow"><i className="far fa-copy"></i></div></div></div>
                  }
                </CardHeader>
              </Card>
            </Col>
            <Col xl="2" md="4" xs="6" className="mb-5">
              <Card className="shadow card-dashboard">
                <CardHeader className="bg-transparent">
                  {
                    loaderPo ?
                      <div className="text-center"><Spinner /></div> :
                      <div className="row"><div className="col"><h5 className="text-uppercase text-muted mb-0 card-title">Generated PO</h5><span className="h2 mb-0 text-primary">{poSuccess && poSuccess.count}</span></div><div className="col-auto col"><div className="icon icon-shape text-primary dashboard-icon rounded-circle shadow"><i className="fas fa-percent"></i></div></div></div>
                  }
                </CardHeader>
              </Card>
            </Col>
            <Col xl="2" md="4" xs="6" className="mb-5">
              <Card className="shadow card-dashboard">
                <CardHeader className="bg-transparent">
                  {
                    loaderPo ?
                      <div className="text-center"><Spinner /></div> :
                      <div className="row"><div className="col"><h5 className="text-uppercase text-muted mb-0 card-title">Generated PO</h5><span className="h2 mb-0 text-dark">{poSuccess && poSuccess.count}</span></div><div className="col-auto col"><div className="icon icon-shape text-dark dashboard-icon rounded-circle shadow"><i className="fas fa-percent"></i></div></div></div>
                  }
                </CardHeader>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className=" shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center mobilDirection">
                    <div className="col">
                      <h2 className="mb-0">Purchase Orders</h2>
                    </div>
                    {loaderSubCate ?
                      <div className="text-center"><Spinner /></div> :
                      <div className="col">
                        <FormGroup className="select_box d-flex align-items-center justify-content-end m-0">
                          <label
                            className="form-control-label pr-2"
                            htmlFor="input-category"
                          >
                            Select Sub Catgeory
                        </label>
                          <Input style={{ width: '150px' }} type="select" name="subcategoryId" value={purchaseOrder.subcategoryId} onChange={this.onChange}>
                            <option>Select sub category</option>
                            {subCateList && subCateList.subcategories && subCateList.subcategories.map((items, key) => {
                              return <option value={items.id}>{items.name}</option>
                            })
                            }
                          </Input>
                        </FormGroup>
                      </div>
                    }

                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="">
                    {
                      loaderPurchase ?
                        <div className="text-center"><Spinner /></div> : purchase && purchase.orders && purchase.orders.length > 0 ?
                        <MDBTable className="table-striped" responsive btn>
                          <MDBTableHead columns={dataTable.columns} />
                          <MDBTableBody >
                            {purchase.orders.map((items, key) => {
                              var stylecode, supplier
                              if (items.ribDesc) {
                                stylecode = items.ribDesc.stylecode
                                supplier = items.ribSupllier.companyName
                              }
                              if (items.febricDesc) {
                                stylecode = items.febricDesc.stylecode
                                supplier = items.febricSupllier.companyName
                              }
                              if (items.ribColor) {
                                stylecode = items.ribColor && items.ribColor.ribDesc && items.ribColor.ribDesc.stylecode
                                supplier = items.colorSupllier.companyName
                              }
                              if (items.zipperDesc) {
                                stylecode = items.zipperDesc.stylecode
                                supplier = items.zipperSupllier.companyName
                              }
                              if (items.quiltingDesc) {
                                stylecode = items.quiltingDesc.stylecode
                                supplier = items.quiltingSupllier.companyName
                              }
                              if (items.unitsDesc) {
                                stylecode = items.unitsDesc.stylecode
                                supplier = items.unitsSupllier.companyName
                              }
                              if (items.ratioDesc) {
                                stylecode = items.ratioDesc.stylecode
                                supplier = items.ratioSupllier.companyName
                              }
                              return <tr>
                                <th scope="row">{items.poId}</th>
                                <td>{stylecode}</td>
                                <td>{supplier}</td>
                                <td>
                                  <Badge color="success">{items.recdStatus > 0 ? items.recdStatus : 0} Received</Badge><br></br>
                                  <Badge color="warning">{items.pendingStatus > 0 ? items.pendingStatus : 0} Pending</Badge>
                                </td>
                                <td>{items.deliveryDate}</td>
                              </tr>
                            })}
                          </MDBTableBody>
                        </MDBTable> : <div className="text-center" style={{color: '#f15d5d'}}>No purchase orders found for this sub category!!</div>
                    }
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="12" className="mt-5 mb-5">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h2 className="mb-0">Recent Suppliers</h2>
                    </div>
                    <div className="col text-right">
                      <Nav className="justify-content-end align-items-center" pills>
                        <NavItem>
                          <FormGroup className="select_box d-flex align-items-center justify-content-end m-0">
                            <label
                              className="form-control-label pr-2"
                              htmlFor="input-category"
                            >
                              Select Catgeory
                        </label>
                            <Input style={{ width: '150px' }} type="select" name="categoryId" value={purchaseOrder.categoryId} onChange={this.onChangeCategory}>
                              <option>Select category</option>
                              {catgeoryList && catgeoryList.categories && catgeoryList.categories.map((items, key) => {
                                return <option value={items.id}>{items.name}</option>
                              })
                              }
                            </Input>
                          </FormGroup>
                        </NavItem>
                        <NavItem><Link className="btn btn-primary" to="/suppliers" tag={Link} >View All</Link>
                        </NavItem>
                      </Nav>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="">
                    {
                      loaderSupplier ?
                        <div className="text-center"><Spinner /></div> : catgeorySuccess && catgeorySuccess.suppliers && catgeorySuccess.suppliers.length > 0 ?
                          <MDBTable className="table-striped" responsive btn>
                            <MDBTableHead columns={suplierTable.columns} />
                            <MDBTableBody >
                              {catgeorySuccess.suppliers.map((items, key) => {
                                let fullAddress = items.address + ', ' + items.city + ', ' + items.state + (items.country === null ? '' : ', ' + items.country) + ', ' + items.zip
                                return <tr>
                                  <th scope="row">{key+1}</th>
                                  <td>{items.companyName}</td>
                                  <td>{items.suppliercategory && items.suppliercategory.name}</td>
                                  <td>{fullAddress}</td>
                                  <td>{items.count}</td>
                                </tr>
                              })}
                            </MDBTableBody>
                          </MDBTable> : <div className="text-center" style={{color: '#f15d5d'}}>No supplier found for this category!!</div>
                    }
                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {/* return <Row className="dash-supplier align-items-center">
                                <Col xs={12} md={2} className="p-0">
                                  <div className="dashboard-supplier-img"><span className="avatar avatar-lg rounded-circle"><img alt="..." src="/logo.png" /></span></div>
                                </Col>
                                <Col xs={12} md={10} className="p-0 pl-2">
                                  <Row>
                                    <Col xs={12} md={9} className="p-0 pr-2" style={{ fontSize: '14px' }}>
                                      <strong>Name:</strong> {items.firstName + ' ' + items.lastName}<br></br>
                                      <strong>Address: </strong>{fullAddress}<br></br>
                                      <strong>Total Orders: </strong> {items.count}
                                    </Col>
                                    <Col xs={12} md={3} className="p-0 text-right text-sm-left" style={{ fontSize: '14px' }}>
                                      {items.suppliercategory.name}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row> */}
            {/* <Col className="mb-5 mb-xl-0" xl="8">
              <Card className="bg-gradient-default shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-light ls-1 mb-1">
                        Overview
                      </h6>
                      <h2 className="text-white mb-0">Revenue</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Line
                      data={dataOrder}
                      options={chartExample1.options}
                      getDatasetAtEvent={e => console.log(e)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Performance
                      </h6>
                      <h2 className="mb-0">Total orders</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Bar
                      data={dataRevenue}
                      options={chartExample2.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
        </Container>
      </>
    );
  }
}
const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    dashboardCounts, dashboardPo, dashboardOrder, dashboardRevenue, supplier, subCategory, purchaseOrder, subCateInitial, category
  }, dispatch)
}
const mapGetStateNew = (state) => {
  return {
    countSuccess: state.dashboard.count,
    poSuccess: state.dashboard.po,
    orderDashSuccess: state.dashboard.orderDash,
    revenueSuccess: state.dashboard.revenue,
    catgeorySuccess: state.supplier.supplier,
    subCategorySuccess: state.subCategory.subCategory,
    purchase: state.dashboard.purchase,
    catgeoryList: state.category.category,
  }
}
export default connect(mapGetStateNew, mapDispatchState)(Index);
