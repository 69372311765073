import {GET_PO_GENERATE_QUILTING, GET_PO_GENERATE_KORA, GET_PO_GENERATE_COLOR, GET_PO_GENERATE_ZIPPER,GET_PO_GENERATE_UNIT,GET_PO_GENERATE_RATIO,GET_PO_GENERATE,GET_PO_DETAIL,GET_PO_ORDER_LIST} from '../../sharedFile/actionType';
export const poDetail = (data) =>({
    type:GET_PO_DETAIL,
    data
})
export const poOrderList = (data) =>({
    type:GET_PO_ORDER_LIST,
    data
})
export const quiltingPo = (data)=>({
    type:GET_PO_GENERATE_QUILTING,
    data
})
export const unitPo = (data)=>({
    type:GET_PO_GENERATE_UNIT,
    data
})
export const ratioPo = (data)=>({
    type:GET_PO_GENERATE_RATIO,
    data
})
export const poGeneratePDF = (data)=>({
    type:GET_PO_GENERATE,
    data
})
export const zipperPo = (data)=>({
    type:GET_PO_GENERATE_ZIPPER,
    data
})
export const koraPo = (data)=>({
    type:GET_PO_GENERATE_KORA,
    data
})
export const colorPo = (data)=>({
    type:GET_PO_GENERATE_COLOR,
    data
})