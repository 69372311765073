import {GET_DASHBOARD_COUNTS,GET_DASHBOARD_PO,GET_DASHBOARD_ORDER,GET_DASHBOARD_REVENUE,GET_DASHBOARD_PURCHASE_ORDER,GET_INITIALSTATE_SUBCATE} from '../../sharedFile/actionType'
export const dashboardCounts = () =>({
    type:GET_DASHBOARD_COUNTS
})
export const dashboardPo = () =>({
    type:GET_DASHBOARD_PO
})
export const dashboardOrder = (data) =>({
    type:GET_DASHBOARD_ORDER,
    data
})
export const dashboardRevenue = (data) =>({
    type:GET_DASHBOARD_REVENUE,
    data
})
export const purchaseOrder = (data) =>({
    type:GET_DASHBOARD_PURCHASE_ORDER,
    data
})
export const subCateInitial = (data) =>({
    type:GET_INITIALSTATE_SUBCATE,
    data
})