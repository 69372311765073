import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getBrandOrder, colorDhaagaAdd, colorDhaaga, initialState, noteinitialState, getInhouseStocks } from '../../../services/actions/order';
import {
    Card,
    CardHeader,
    Row,
    Container,
    Spinner,
    NavItem,
    Nav,
    NavLink,
    Button
} from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import { ribSupplier } from '../../../services/actions/supplier';
import { Link } from 'react-router-dom'
import EditTable from '../../../components/EditTable'
import Header from "../../../components/Headers/Header";
import { imagerUrl } from '../../../config/config';
import PreviewModal from "../../../components/previewModal";
import { colorPo } from '../../../services/actions/po'
import moment from 'moment'
var toastId = null

class ColorDhaaga extends React.Component {
    state = {
        data: {
            columns: [
                { label: "Action", field: "Action" },
                { label: "S.N.", field: "Sr No" },
                { label: 'Style No', field: 'Style No' },
                { label: 'Style Code', field: 'Style Code' },
                { label: 'Need in KG', field: 'Need in KG' },
                { label: 'Color Name', field: 'Color Name' },
                { label: 'Color Percent', field: 'Color Percent' },
                { label: 'Color Need', field: 'Color Nedd' },
                { label: 'Color Inhouse', field: 'Color Inhouse' },
                { label: 'Color Final need', field: 'Color Final need' },
                { label: 'Supplier', field: 'Supplier', class: 'selectList' },
                { label: 'price', field: 'price' },
                { label: "Delivery", field: "Delivery" },
                { label: 'Remarks', field: 'Remarks' },
                { label: 'po status', field: 'po status' },
                { label: 'recd status', field: 'recd status', class: 'avgWidth' },
                { label: 'pending status', field: 'pending status', class: 'avgWidth' }
            ],
            rows: []
        },
        brand: '',
        brandImg: '',
        poStatus: '',
        brandOrderId: this.props.match.params.id,
        rows: [],
        pageNumber: 1,
        total: 0,
        loaderTable: true,
        slug: this.props.location.state.split('/'),
        search: '',
        orderIdPo: '',
        name: '',
        selectPoList: {
            id: []
        },
        previewData: {
            columns: [],
            rows: []
        },
        loaderPo: false,
        suppliers: [],
        open: false,
        previewDataId: null,
        noteGetRow: '',
        poSucc: null,
        supllierDetail: [],
    }
    componentDidMount() {
        const { slug, pageNumber, brandOrderId, search } = this.state
        const data = { slug: slug[2], pageNumber, brandOrderId, search }
        this.props.colorDhaaga(data)
        this.props.getBrandOrder({ brandOrderId, pageNumber })
        this.props.ribSupplier()
        this.setState({
            loaderTable: true
        })
    }
    onDelete = (event, rowId) => {
        let rows = this.state.data.rows
        const { slug, brandOrderId, pageNumber } = this.state
        let row = []
        let updatedRow = this.state.rows
        rows.map((value, key) => {
            if (key !== rowId) {
                if (key >= rowId + 1) {
                    let poSucc = this.state.rows[key]["colorName"] !== '' && this.state.rows[key]["finalNeed"] !== '' && this.state.rows[key]["supplier"] !== '' && this.state.rows[key]["deliveryDate"] !== '' ? false : true
                    value['Sr No'] = this.state.data.rows[key]['Sr No'] - 1
                    value['Action'] = [
                        <div className="d-flex"><Button key={key} style={{ boxShadow: 'unset' }} className="m-0 p-0" disabled={updatedRow[key]['totalNeedKg'] != null ? false : true} onClick={e => (this.onEdit(e, key - 1))}><i title="Edit" className="fas fa-pen pr-2 cursor-pointer"></i></Button>
                            <i title="Add Row" className="fas fa-plus-circle cursor-pointer" onClick={e => (this.onAdd(e, key - 1))} key={'abc' + key}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, this.state.rows[key]['orderDescId'], poSucc))} ></i><input type="checkbox" value={updatedRow[key]['orderDescId']} name="poGenrate" disabled={poSucc} key={'check' + key} onChange={e => (this.listPoGenrate(e, key))} /></div>
                    ]
                }
                row.push(value);
            }
            return true
        })
        let data1 = []
        updatedRow && updatedRow.map((value, key) => {
            if (key !== rowId) {
                data1.push(value);
            } return true;
        })
        this.setState({
            data: {
                ...this.state.data,
                rows: row
            },
            rows: data1
        })
        const data = { slug: slug[2], pageNumber, brandOrderId }
        this.props.colorDhaaga(data)
    }
    onAdd = (event, rowId) => {
        let rows = this.state.data.rows
        let rowList = this.state.rows
        let row = []
        rows.map((value, key) => {
            var abc = {}
            value['Action'] = [<div className="d-flex"><Button key={'abc' + key} style={{ boxShadow: 'unset' }} className="m-0 p-0 " disabled={rowList[key]['totalNeedKg'] !== null ? false : true} ><i title="Edit" className="fas fa-pen pr-2 disabledAction cursor-pointer"></i></Button><i title="Add Row" className="fas fa-plus-circle disabledAction" key={'abce' + key}></i><i key={'abcr' + key} title="View" className="disabledAction fas fa-eye cursor-pointer" ></i><input style={{ minWidth: '15px', marginLeft: '5px' }} type="checkbox" value={this.state.rows[key]['orderDescId']} name="poGenrate" disabled key={key} onChange={e => (this.listPoGenrate(e, key))} /></div>]
            if (key === rowId) {
                abc['Action'] = [<Button key={'abc' + key} style={{ boxShadow: 'unset' }} className="m-0 p-0" disabled={rowList[key]['totalNeedKg'] != null ? false : true} onClick={e => (this.onEdit(e, key + 1))}><i title="Edit" className="fas fa-pen pr-2 cursor-pointer"></i></Button>, <i title="Delete" className="fas fa-minus-circle cursor-pointer" onClick={e => (this.onDelete(e, key + 1))} key={'abcu' + key}></i>]
                abc['Sr No'] = rows[key]['Sr No'] + 1
                abc['Style No'] = value['Style No']
                abc['Style Code'] = value['Style Code']
                abc['Need in KG'] = value['Need in KG']
                abc['Color Name'] = ''
                abc['Color Percent'] = ''
                abc['Color Need'] = 0
                abc['Color Inhouse'] = 0
                abc['Color Final need'] = 0
                abc['Supplier'] = ''
                abc['price'] = ''
                abc['Delivery'] = ''
                abc['Remarks'] = ''
                abc['po status'] = <>{value.poStatus === 'Generated' || value.poStatus === 'Regenerated' ? <i class="fas fa-check" title={value.poStatus} style={{ color: '#4dba4f' }}></i> : <><i style={{ color: '#f15d5d' }} class="far fa-clock" title='Pending'></i></>}</>
                abc['recd status'] = ''
                abc['pending status'] = ''
            }
            if (key >= rowId + 1) {
                abc = {}
                value['Sr No'] = rows[key]['Sr No'] + 1
                value['Action'] = [<div className="d-flex"><Button key={'abc' + key} style={{ boxShadow: 'unset' }} className="m-0 p-0 " disabled={rowList[key]['totalNeedKg'] != null ? false : true} ><i title="Edit" className="fas fa-pen pr-2 disabledAction cursor-pointer"></i></Button><i title="Add Row" className="fas fa-plus-circle disabledAction" key={'abce' + key}></i><i key={'abcr' + key} title="View" className="disabledAction fas fa-eye cursor-pointer" ></i><input style={{ minWidth: '15px', marginLeft: '5px' }} type="checkbox" value={this.state.rows[key]['orderDescId']} name="poGenrate" disabled key={key} onChange={e => (this.listPoGenrate(e, key))} /></div>]
            }
            row.push(value);
            if (abc && Object.keys(abc).length)
                row.push(abc);
        })
        let updatedRow = this.state.rows
        let data1 = []
        updatedRow && updatedRow.map((value, key) => {
            var abc = {}
            if (key === rowId) {
                abc.orderDescId = value.orderDescId
                abc.ribDhaagaId = value.ribDhaagaId
                abc.totalNeedKg = value.totalNeedKg
                abc.materialId = value.materialId
                abc.colorName = ''
                abc.colorPercent = ''
                abc.finalNeed = 0
                abc.inHouse = 0
                abc.totalNeed = 0
                abc.supplierId = ''
                abc.price = ''
                abc.deliveryDate = ''
                abc.remarks = ''
                abc.recdStatus = ''
                abc.poStatus = 'Pending'
                abc.pendingStatus = ''
            }
            data1.push(value);
            if (abc && Object.keys(abc).length)
                data1.push(abc);
        })
        this.setState({
            data: {
                ...this.state.data,
                rows: row
            },
            rows: data1
        })
    }
    valueEdit = (rowId, value, key, rows) => {
        let poSucc = rows[rowId]["colorName"] !== null && rows[rowId]["finalNeed"] !== null && rows[rowId]["supplier"] !== null && rows[rowId]["price"] !== null && rows[rowId]["deliveryDate"] !== null ? false : true
        if (key === rowId) {
            value['Action'] = [<div style={{ display: 'flex' }}><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (rows[rowId]['totalNeedKg'] !== null ? this.onEdit(e, key) : this.onEditDisabled(e, key))} key={'abc' + key}></i><i title="Add Row" className="fas fa-plus-circle cursor-pointer" onClick={e => (this.onAdd(key))} key={'abce' + key}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, rows[rowId]["orderid"], poSucc
            ))} ></i><input type="checkbox" value={rows[rowId]["orderid"]} name="poGenrate" style={{ minWidth: '15px', marginLeft: '5px' }} disabled={poSucc} key={key} onChange={e => (this.listPoGenrate(e, key))} /></div>]
            value['Sr No'] = key + 1
            value['Color Name'] = rows[rowId]['colorName']
            value['Color Percent'] = rows[rowId]['colorPercent']
            value['Color Inhouse'] = rows[rowId]['inHouse']
            value['Color Final need'] = rows[rowId]['finalNeed']
            value['Supplier'] = rows[rowId]['supplierText']
            value['price'] = rows[rowId]['price']
            value['Delivery'] = rows[rowId]['deliveryDate']
            value['Remarks'] = rows[rowId]['Remarks']
            value['po status'] = rows[rowId]['poStatus']
            value['recd status'] = rows[rowId]['recdStatus']
            value['pending status'] = rows[rowId]['pendingStatus']
        }
    }
    onKeyPress(evt) {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        var regex = /[0-9]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }
    valueUpdate = (value, key, rows, rowId, suppliers) => {
        let currentDate = new Date();
        let getYear = currentDate.getFullYear()
        let getMonth = currentDate.getMonth() + 1
        let fullCurrentDate = getYear + '-' + (getMonth <= 9 ? '0' + getMonth : getMonth) + '-' + (currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate())
        if (key === rowId) {
            if (sessionStorage.userROLE === 'super-admin' || rows[rowId]['poStatus'] === 'Pending') {
                value['Action'] = [<i className="fas fa-check pr-2 cursor-pointer" onClick={e => (this.onSubmit(e, key))} key={'abc' + key}></i>]
                value['Sr No'] = key + 1
                value['Color Name'] = [<input className="inputFiled" type="text" name="colorName" value={rows[key]['colorName']} onChange={e => (this.onChange(e, key))} />]
                value['Color Percent'] = [<input maxLength="4" className="inputFiled" onKeyPress={this.onKeyPress.bind(this)} type="text" name="colorPercent" value={rows[key]['colorPercent']} onChange={e => (this.onChange(e, key))} />]
                value['Color Final need'] = rows[key]["finalNeed"]
                value['Supplier'] = [<select className="selectList inputFiled" name="supplier" defaultValue={rows[key]['supplier']} onChange={e => (this.onChange(e, key))}>
                    <option>Supplier</option>
                    {
                        suppliers && suppliers.map((value, key) => {
                            return <option value={value.id}>{value.companyName}</option>
                        })}
                </select>]
                value['price'] = [<input maxLength="8" onKeyPress={this.onKeyPress.bind(this)} type="text" name="price" value={rows[key]['price']} onChange={e => (this.onChange(e, key))} />]
                value['Delivery'] = [<input type="date" min={fullCurrentDate} className="inputFiled" name="deliveryDate" value={rows[key]['deliveryDate']} onChange={e => (this.onChange(e, key))} />]
                value['Remarks'] = [<input type="text" name="remarks" value={rows[key]['remarks']} className="inputFiled" onChange={e => (this.onChange(e, key))} />]
                value['recd status'] = [<input className="inputFiled" type="text" onKeyPress={this.onKeyPress.bind(this)} name="recdStatus" value={rows[key]['recdStatus']} onChange={e => (this.onChange(e, key))} />]
                value['pending status'] = rows[key]["pendingStatus"]
            }
            else {
                value['Action'] = [<i className="fas fa-check pr-2 cursor-pointer" onClick={e => (this.onSubmit(e, key))} key={'abc' + key}></i>]
                value['recd status'] = [<input className="inputFiled" onKeyPress={this.onKeyPress.bind(this)} type="text" name="recdStatus" value={rows[key]['recdStatus']} onChange={e => (this.onChange(e, key))} />]
                value['pending status'] = rows[key]["pendingStatus"]
            }
        } else {
            value['Action'] = [<div className="d-flex"><Button key={'abc' + key} style={{ boxShadow: 'unset' }} className="m-0 p-0 " disabled><i title="Edit" className="fas fa-pen pr-2 disabledAction cursor-pointer"></i></Button><i title="Add Row" className="fas fa-plus-circle disabledAction" key={'abce' + key}></i><i key={'abcr' + key} title="View" className="disabledAction fas fa-eye cursor-pointer" ></i><input style={{ minWidth: '15px', marginLeft: '5px' }} type="checkbox" value={this.state.rows[key]['orderDescId']} name="poGenrate" disabled key={key} onChange={e => (this.listPoGenrate(e, key))} /></div>]
        }
    }
    onChange = (e, rowId) => {
        let { rows, data, suppliers } = this.state
        let row = rows.map((value, key) => {
            if (key === rowId) {
                value[e.target.name] = e.target.value
                if (value['colorPercent'].length) {
                    const percent = parseFloat(value['colorPercent']) / 100
                    value['Color Need'] = Math.ceil(parseInt(value['totalNeedKg']) * percent)
                    value['totalNeed'] = Math.ceil(parseInt(value['totalNeedKg']) * percent)
                    data.rows[key]['Color Need'] = Math.ceil(parseInt(value['totalNeedKg']) * percent)
                    value['finalNeed'] = Math.ceil(value['totalNeed']) - value['inHouse']
                }
                if (e.target.name === 'recdStatus') {
                    value['pendingStatus'] = value['recdStatus'] > 0 ? parseInt(value['finalNeed'] - (value['recdStatus'] ? value['recdStatus'] : 0)) : value['finalNeed']
                }
            }

            return value
        })
        this.setState({
            data: data,
            rows: row
        })
        var dataRows = data.rows.map((value, key) => {
            this.valueUpdate(value, key, rows, rowId, suppliers)
            return value;
        })
        this.setState({
            data: {
                ...this.state.data,
                rows: dataRows
            }
        })
        let varientsList = 'color name' + ':' + rows[rowId].colorName
        this.props.getInhouseStocks({
            materialId: rows[rowId].materialId,
            key: rowId,
            varients: varientsList,
            quantity: rows[rowId].totalNeed ? rows[rowId].totalNeed : 0,
            inhouseId: rows[rowId].newStock,
            oldQuantity: rows[rowId].inHouse,
            orderid: rows[rowId].orderid ? rows[rowId].orderid : undefined
        })
    }
    onSubmit = (event, rowId) => {
        const { rows, data, pageNumber, slug, brandOrderId, search } = this.state
        var row = data.rows.map((value, key) => {
            this.valueEdit(rowId, value, key, rows)
            return value;
        })
        this.setState({
            data: {
                ...this.state.data,
                rows: row
            }
        })
        let dataSubmit = []
        rows.map((val, key) => {
            if (key === rowId) {
                dataSubmit.push({
                    "brandOrderId": brandOrderId !== '' ? brandOrderId : null,
                    "orderDescId": val.orderDescId !== '' ? val.orderDescId : null,
                    "ribDhaagaId": val.ribDhaagaId !== '' ? val.ribDhaagaId : null,
                    "totalNeedKg": val.totalNeedKg !== '' ? val.totalNeedKg : null,
                    'orderid': val.orderid !== undefined ? val.orderid : undefined,
                    "colorName": val.colorName !== '' ? val.colorName : null,
                    "colorPercent": val.colorPercent !== '' ? val.colorPercent : null,
                    "totalNeed": val.totalNeed !== 0 ? val.totalNeed : null,
                    "inHouse": val.inHouse !== 0 ? val.inHouse : null,
                    "finalNeed": val.finalNeed !== 0 ? val.finalNeed : null,
                    "supplierId": val.supplier !== '' ? val.supplier : null,
                    "deliveryDate": val.deliveryDate !== '' ? val.deliveryDate : null,
                    "remarks": val.remarks !== '' ? val.remarks : null,
                    "price": val.price !== '' ? val.price : null,
                    'recdStatus': val.recdStatus !== '' ? val.recdStatus : null,
                    'pendingStatus': val.pendingStatus >= 0 ? val.pendingStatus : null,
                    oldStock: val.oldStock !== '' ? val.oldStock : null,
                    newStock: val.newStock !== '' ? val.newStock : null,
                })
            }
        })
        this.props.colorDhaagaAdd({ brandOrderId, slug: slug[2], pageNumber, search, data: dataSubmit })
        this.setState({
            loaderTable: true
        })
    }
    onEditDisabled=(event, rowId)=>{
        let { data, rows, suppliers } = this.state
        var row = data.rows.map((value, key) => {
            if (key === rowId && rows[key]['totalNeedKg'] === null) {
                if (!toast.isActive(toastId))
                    toastId = toast.info('The kora yarn work is not done for this row!!')
            }
            return value;
        })
    }
    onEdit = (event, rowId) => {
        let { data, rows, suppliers } = this.state
        var row = data.rows.map((value, key) => {
            this.valueUpdate(value, key, rows, rowId, suppliers)
            return value;
        })
        var rowList = rows.map((val, key) => {
            if (key === rowId && rows[key]['totalNeedKg'] !== null) {
                val['updateClass'] = 'editRow'
            }
            return val
        })
        this.setState({
            data: {
                ...this.state.data,
                rows: row
            },
            rows: rowList
        })
    }
    componentWillReceiveProps(newProps) {
        const { brandorder, colorsuccess, colorFailed, supplierList, inhousestock, poGenrateSuccess, noteSuccess } = newProps
        const { brandOrderId, pageNumber, search, slug } = this.state
        if (noteSuccess && noteSuccess.success === true) {
            const data = { slug: slug[2], pageNumber, brandOrderId, search }
            newProps.colorDhaaga(data)
            this.setState({
                open: false,
                loaderTable: true,
            })
        }
        var colorData, colorData1
        if (brandorder && brandorder.success) {
            let brand = brandorder.order
            this.setState({
                brand: brand && brand.brandsId && brand.brandsId.name,
                brandImg: brand && brand.brandsId && brand.brandsId.logo,
                name: brand && brand.name
            })
        }
        if (supplierList && supplierList.success) {
            this.setState({
                suppliers: supplierList && supplierList.suppliers
            })
        }
        if (poGenrateSuccess && poGenrateSuccess.success === true) {
            if (poGenrateSuccess.message) {
                if (!toast.isActive(toastId))
                    toastId = toast.success(poGenrateSuccess.message)
            }
            this.setState({
                loaderPo: false,
                selectPoList: { id: [] }
            })
            const data = { brandOrderId, pageNumber, slug: slug[2], search }
            this.props.colorDhaaga(data)
            this.props.initialState();
        } else if (poGenrateSuccess.success === false) {
            if (poGenrateSuccess.message) {
                if (!toast.isActive(toastId))
                    toastId = toast.error(poGenrateSuccess.message)
            }
            this.setState({
                loaderPo: false
            })
            this.props.initialState();
        }
        if (inhousestock && (inhousestock.message || inhousestock.success)) {
            if (inhousestock.success) {
                var inHouse = inhousestock && inhousestock.inhouse
                let dataa = this.state.data.rows.map((val, keys) => {
                    if (keys === inhousestock.key) {
                        val["Color Inhouse"] = inhousestock.quantity
                        val['Color Final need'] = val['Color Need'] - val["Color Inhouse"]
                        newProps.initialState();
                    }
                    return val
                })
                let data1 = this.state.rows.map((val, keys) => {
                    if (keys === inhousestock.key) {
                        val.inHouse = inhousestock.quantity
                        val.finalNeed = val.totalNeed - val.inHouse
                        val.newStock = inHouse && inHouse.id
                        newProps.initialState();
                    }
                    return val
                })
                this.setState({
                    ...this.state,
                    data: {
                        ...this.state.data,
                        rows: dataa
                    },
                    rows: data1,
                    inHouse: inHouse
                })
            } else {
                var inHouse = inhousestock && inhousestock.inhouse
                let dataa = this.state.data.rows.map((val, keys) => {
                    if (keys === inhousestock.key) {
                        val.inHouse = 0
                        val['Color Final need'] = val['Color Need'] - val.inHouse
                    }
                    return val
                })
                let data1 = this.state.rows.map((val, keys) => {
                    if (keys === inhousestock.key) {
                        val.inHouse = 0
                        val.finalNeed = val.totalNeed - val.inHouse
                        val.oldStock = val.newStock ? val.newStock : null
                        val.newStock = undefined
                    }
                    return val
                })
                this.setState({
                    ...this.state,
                    data: {
                        ...this.state.data,
                        rows: dataa
                    },
                    rows: data1,
                    inHouse: inHouse
                })
                this.props.initialState();
            }
        } else {
            if (colorsuccess && colorsuccess.success) {
                let colorList = colorsuccess && colorsuccess.order
                colorData = colorList.map((value, key) => {
                    let poSucc = value.colorName != null && value.colorPercent != null && value.totalNeed != null && value.colorSupllier != null && value.deliveryDate != null ? false : true
                    return ({
                        'Action': [<div style={{ display: 'flex' }}>{sessionStorage.userROLE === 'super-admin' || value.poStatus === 'Pending' || value.poStatus === null ? <><Button key={key} style={{ boxShadow: 'unset' }} className="m-0 p-0" onClick={e => (value.ribColor && value.ribColor.totalNeedKg !== null ? this.onEdit(e, key) : this.onEditDisabled(e, key))}><i title="Edit" className="fas fa-pen pr-2 cursor-pointer"></i></Button><i title="Add Row" className="fas fa-plus-circle cursor-pointer" onClick={e => (this.onAdd(e, key))} key={'abc' + key}></i><i key={'abcd' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, value.id, poSucc))} ></i><input style={{ minWidth: '15px', marginLeft: '5px' }} type="checkbox" value={value.id} name="poGenrate" disabled={poSucc} key={'ee' + key} title={poSucc === true ? 'Fill the row to generate PO.' : ''} onChange={e => (this.listPoGenrate(e, key))} /></> : <><Button key={key} style={{ boxShadow: 'unset' }} className="m-0 p-0" disabled={value.ribColor && value.ribColor.totalNeedKg != null ? false : true} onClick={e => (this.onEdit(e, key))}><i title="Edit" className="fas fa-pen pr-2 cursor-pointer"></i></Button><i title="Add Row" className="fas fa-plus-circle cursor-pointer" onClick={e => (this.onAdd(e, key))} key={'abc' + key}></i><i key={'abcd' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, value.id, poSucc))} ></i></>}</div>],
                        'Sr No': ((this.state.pageNumber - 1) * 10) + (key + 1),
                        'Style No': value.ribColor && value.ribColor.ribDesc.styleno,
                        'Style Code': [<span className="tooltip1" key={'abcd' + key}>{value.ribColor !== null && value.ribColor.ribDesc && value.ribColor.ribDesc.stylecode ? (value.ribColor.ribDesc.stylecode.length > 5 ? value.ribColor.ribDesc.stylecode.substr(value.ribColor.ribDesc.stylecode.length - 5) : value.ribColor.ribDesc.stylecode) : value.ribColor.ribDesc.stylecode}<span className="tooltiptext">{value.ribColor.ribDesc.stylecode}</span></span>],
                        'Need in KG': value.ribColor && value.ribColor.totalNeedKg,
                        'Color Name': value.colorName != null ? value.colorName : '',
                        'Color Percent': value.colorPercent != null ? value.colorPercent : '',
                        'Color Need': value.totalNeed != null ? value.totalNeed : 0,
                        'Color Inhouse': value.inHouse != null ? value.inHouse : 0,
                        'Color Final need': value.finalNeed != null ? value.finalNeed : 0,
                        'Supplier': [<span className="tooltip1" key={'abcdeZ' + key} >{(value.colorSupllier && value.colorSupllier.id) ? (value.colorSupllier.companyName.length > 5 ? value.colorSupllier.companyName.substr(0, 5) : value.colorSupllier.companyName) : ''}<span className="tooltiptext">{value.colorSupllier && value.colorSupllier.companyName ? value.colorSupllier.companyName : ''}</span></span>],
                        'price': value.price != null ? value.price : '',
                        'Delivery': value.deliveryDate != null ? moment(value.deliveryDate).format('DD/MM/YY') : '',
                        'Remarks': [<span className="tooltip1" key={'abcd' + key}>{value.remarks !== null && value.remarks ? (value.remarks.length > 5 ? value.remarks.substr(0, 5) : value.remarks) : value.remarks}<span className="tooltiptext">{value.remarks}</span></span>],
                        'po status': value.poStatus != null ? <>{value.poStatus === 'Generated' || value.poStatus === 'Regenerated' ? <i class="fas fa-check" title={value.poStatus} style={{ color: '#4dba4f' }}></i> : <><i style={{ color: '#f15d5d' }} class="far fa-clock" title='Pending'></i></>}</> : <><i style={{ color: '#f15d5d' }} class="far fa-clock" title='Pending'></i></>,
                        'recd status': value.recdStatus != null ? value.recdStatus : '',
                        'pending status': value.pendingStatus != null ? value.pendingStatus : 0,
                    })
                })
                let supllierDetail = []
                colorData1 = colorList.map((value, key) => {
                    supllierDetail.push(value.colorSupllier)
                    return ({
                        'mainFabricOrderId': null,
                        'orderDescId': value.ribColor && value.ribColor.orderDescId,
                        'ribDhaagaId': value.ribDhaagaId,
                        'orderid': value.id,
                        'materialId': value.ribColor && value.ribColor.materialId,
                        'totalNeedKg': value.ribColor && value.ribColor.totalNeedKg,
                        'colorName': value.colorName != null ? value.colorName : '',
                        'colorPercent': value.colorPercent != null ? value.colorPercent : '',
                        'totalNeed': value.totalNeed != null ? value.totalNeed : 0,
                        'inHouse': value.inHouse != null ? value.inHouse : 0,
                        'finalNeed': value.finalNeed != null ? value.finalNeed : 0,
                        'supplier': value.supplierId != null ? value.supplierId : '',
                        'supplierText': value.colorSupllier != null ? value.colorSupllier.companyName : '',
                        'price': value.price != null ? value.price : '',
                        'deliveryDate': value.deliveryDate != null ? value.deliveryDate : '',
                        'remarks': value.remarks != null ? value.remarks : '',
                        'poStatus': value.poStatus != null ? value.poStatus : 'Pending',
                        'recdStatus': value.recdStatus != null ? value.recdStatus : '',
                        'pendingStatus': value.pendingStatus != null ? value.pendingStatus : 0,
                        newStock: value.inhouseId != null ? value.inhouseId : undefined,
                        note: value.notes != null ? value.notes : ''
                    })
                })
                this.setState({
                    data: {
                        ...this.state.data,
                        rows: colorData
                    },
                    rows: colorData1,
                    loaderTable: false,
                    supllierDetail: supllierDetail,
                    total: colorsuccess.page
                })
            } else {
                if (colorFailed && colorFailed.message) {
                    if (toast.isActive(toastId)) {
                        toast.dismiss(toastId)
                        toastId = toast.error(colorFailed.message)
                    } else {
                        toastId = toast.error(colorFailed.message)
                    }
                }
                this.setState({
                    ...this.state,
                    loaderTable: false
                })
            }
        }
    }
    preview = (e, rowId, id, poSucc) => {
        const { data, supllierDetail, rows } = this.state
        let poTable = [{}]
        let orderId, poStatus, noteGetRow
        let poSupplierDetail = []
        supllierDetail.map((items, key) => {
            if (rowId === key) {
                poSupplierDetail = items
            }
        })
        rows.map((item, key) => {
            if (rowId === key) {
                orderId = rows[rowId]['orderid']
                poStatus = rows[rowId]['poStatus']
                noteGetRow = rows[rowId]['note']
            }
        })
        data.rows.map((value, key) => {
            if (rowId === key) {
                poTable[0]['Style No'] = data.rows[key]['Style No']
                poTable[0]['Color Name'] = data.rows[key]['Color Name']
                poTable[0]['Color Final need'] = data.rows[key]['Color Final need']
                poTable[0]['Supplier'] = data.rows[key]['Supplier']
                poTable[0]['price'] = data.rows[key]['price']
                poTable[0]['Delivery'] = data.rows[key]['Delivery']
                poTable[0]['Remarks'] = data.rows[key]['Remarks']
            }
            return true;
        })
        if (poSupplierDetail !== null) {
            this.setState({
                previewData: {
                    columns: [
                        { label: 'Style No', field: 'Style No', class: 'colum_width' },
                        { label: 'Color Name', field: 'Color Name', class: '' },
                        { label: 'Color Final need', field: 'Color Final need', class: 'colum_width' },
                        { label: 'Supplier', field: 'Supplier', class: '' },
                        { label: 'price', field: 'price', class: '' },
                        { label: "Delivery", field: "Delivery", class: '' },
                        { label: 'Remarks', field: 'Remarks', class: '' },
                    ],
                    rows: poTable
                },
                previewDataId: id,
                open: true,
                orderIdPo: orderId,
                noteGetRow: noteGetRow,
                poSupplierDetail: poSupplierDetail,
                poStatus: poStatus,
                poSucc: poSucc
            })
        }
    }
    previewClose = () => {
        this.setState({
            open: false
        })
    }
    poGenerate = (e, rowId) => {
        const { selectPoList } = this.state
        this.props.colorPo(selectPoList)
        this.setState({
            loaderPo: true,
            loaderTable: true
        })
    }
    POSingle = (e, rowId) => {
        let selectPoList = {
            id: [rowId.toString(), null]
        }
        this.setState({
            ...this.state,
            loaderTable: true,
            open: false
        })
        this.props.colorPo(selectPoList)
    }
    listPoGenrate = (e, key) => {
        const { selectPoList } = this.state
        let index;
        if (e.target.checked) {
            this.setState({
                selectPoList: { ...selectPoList, id: [...selectPoList.id, e.target.value] }
            })
        } else {
            index = selectPoList.id.indexOf(e.target.value)
            selectPoList.id.splice(index, 1)
            this.setState({
                selectPoList: { ...selectPoList, id: [...selectPoList.id] }
            })
        }
    }
    componentWillUnmount() {
        this.props.initialState()
    }
    searchChange = (e) => {
        const { brandOrderId, pageNumber, slug } = this.state
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                rows: []
            },
            rows: [],
            search: e.target.value
        })
        let data
        if (e.target.value !== '')
            data = { brandOrderId, pageNumber: 1, slug: slug[2], search: e.target.value }
        else
            data = { brandOrderId, pageNumber: pageNumber, slug: slug[2], search: e.target.value }

        this.props.colorDhaaga(data)
    }
    changePage = (e, pageNumber) => {
        const { brandOrderId, slug, search } = this.state
        this.setState({
            ...this.state,
            pageNumber: pageNumber,
            loaderTable: true,
            selectPoList: { id: [] }
        })
        this.props.colorDhaaga({ brandOrderId, pageNumber: pageNumber, slug: slug[2], search })
    }
    render() {
        const { open, previewData, name, previewDataId, poSupplierDetail, suppliers, loaderPo, loaderTable, selectPoList, orderIdPo, poStatus, poSucc, noteGetRow } = this.state
        return (
            <>
                <ToastContainer autoClose={10000} />
                {
                    !loaderTable ?
                        <>
                            <Header />
                            <Container className="mt-0" fluid>
                                <Row>
                                    <div className="col">
                                        <Card className="border-0">
                                            <CardHeader className="border-0 pt-0">
                                                <h1 className="m-0 text-capitalize">Color Yarn</h1>
                                                <Row className="align-items-center">
                                                    <div className="col-md-8 d-flex pl-0">
                                                        <div style={{ paddingBottom: '5px', marginRight: '25px' }}>
                                                            <h6 className="text-uppercase text-light ls-1 mb-1">
                                                                Brand
                                                        </h6>
                                                            <h3 className="mb-0 profile_sec text-capitalize">
                                                                {
                                                                    (this.state.brandImg !== '' && this.state.brandImg !== null) ?
                                                                        <img className="mr-3" src={`${imagerUrl}` + this.state.brandImg} width="40" alt="brand"></img>
                                                                        : <img className="mr-3" src={'/logo.png'} width="40" alt="brand"></img>
                                                                }
                                                                {this.state.brand}</h3>
                                                        </div>
                                                        <div style={{ paddingBottom: '5px' }}>
                                                            <h6 className="text-uppercase text-light ls-1 mb-1">
                                                                Order name
                                                            </h6>
                                                            <h2 className="mb-0">
                                                                {name}</h2>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 justify-contant-flex-xs-start pl-0 text-right d-flex justify-contant-flex-end">
                                                        <Button style={{ marginRight: '20px' }} type="submit" disabled={loaderPo === true || selectPoList.id === undefined || selectPoList.id[0] == null ? true : false} className="btn btn-primary" color="primary" onClick={this.poGenerate}>{
                                                            loaderPo === true ?
                                                                <Spinner /> :
                                                                'Generate PO'
                                                        }</Button>
                                                        <Nav className="justify-content-end" pills>
                                                            <NavItem>
                                                                <NavLink className="btn btn-primary " to='/ribdhaaga/dhaaga_color' tag={Link}  >Back</NavLink>
                                                            </NavItem>
                                                        </Nav>


                                                    </div>
                                                </Row>
                                            </CardHeader>
                                            <div className="table-responsive">
                                                <EditTable searchChange={this.searchChange} changePage={this.changePage} {...this.state} />
                                            </div>
                                        </Card>
                                    </div>
                                </Row>
                                <PreviewModal poSucc={poSucc} previewData={previewData} toggle={this.previewClose} open={open} liningsupplier='dhaaga_color' previewDataId={previewDataId} poSupplierDetail={poSupplierDetail} orderIdPo={orderIdPo} poStatus={poStatus} POSingle={this.POSingle} loaderPo={loaderPo} noteGetRow={noteGetRow} />
                            </Container>
                        </>
                        :
                        <div className="loader">
                            <Spinner variant="light" />
                            <p style={{ color: '#fff', paddingTop: '15px' }}>Please wait......</p>
                        </div>
                }
            </>
        )
    }
}
const mapDispatchState = (dispatch) => {
    return bindActionCreators({
        getBrandOrder,
        colorDhaaga,
        colorDhaagaAdd,
        ribSupplier,
        noteinitialState,
        initialState,
        getInhouseStocks,
        colorPo
    }, dispatch)
}

const mapGetStateNew = (state) => {
    return {
        brandorder: state.order.brandorder,
        poGenrateSuccess: state.poPDF.poGenrate,
        colorsuccess: state.order.colorDhaaga,
        colorFailed: state.order.colorDhaagaAdd,
        inhousestock: state.order.inhousestock,
        supplierList: state.supplier.supplier,
        noteSuccess: state.order.note
    }
}
export default connect(mapGetStateNew, mapDispatchState)(ColorDhaaga);