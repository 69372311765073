
import React from "react";
import {
  Container,
  Row,
  Spinner,
  Button
} from "reactstrap";
import Header from "../../components/Headers/Header.jsx";
import Table from "./categoryTable"
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { category, deleteCategory } from '../../services/actions/category';
import ConfirmModal from '../../components/ConfirmModal';
import { MDBIcon } from 'mdbreact';
import { Link } from "react-router-dom";
import { initialState } from '../../services/actions/stateInitial'
class CategoriesList extends React.Component {
  state = {
    data: {
      columns: [
        { label: '#', field: '#', sort: 'asc' },
        { label: 'Category Name', field: 'Name', sort: 'asc', },
        { label: 'Category Description', field: 'Description', sort: 'asc', },
        { label: 'Action', field: 'Action', sort: 'disabled', width: 100 }
      ],
    },
    category: {},
    pageNumber: 1,
    loader: false,
    open: false,
    id: '',
    name: ''
  }
  componentDidMount() {
    this.props.category();
    this.setState({
      loader: true
    })
  }
  toggle = () => {
    this.setState({
      open: false,
    })
  }
  confirm = (event, id, name) => {
    this.setState({
      open: true,
      id: id,
      name: name
    })
  }

  confirmation = (event, id) => {
    this.setState({
      open: false
    })
    this.props.deleteCategory(id)
  }
  componentWillUnmount() {
    this.props.initialState()
  }
  componentWillReceiveProps(props) {
    const { catgeorySuccess } = props
    if (catgeorySuccess.success) {
      this.setState({
        loader: false
      })
    }
    if (catgeorySuccess.success === false) {
      this.setState({
        loader: false
      })
    }
  }
  render() {
    const { catgeorySuccess } = this.props
    const { open, data, loader } = this.state
    let descrip
    var testData = catgeorySuccess.categories && catgeorySuccess.categories.map((items, key) => {
      if (items.description.length > 30)
        descrip = items.description.substring(0, 30) + '...';
      else
        descrip = items.description
      return (
        {
          '#': key + 1,
          'Name': items.name,
          'Description': [<>{items.description.length > 30 ? <span className="tooltip1" key={'abcdeZ' + key} >{descrip}<span className="tooltiptext textFLow">{items.description}</span></span> : items.description}</>],
          'Action': [<>{items.slug !== 'rib-dhaaga-family' ? <Button className="action_btn text-black" outline color="" tag={Link} to={`/view-category/${items.id}`}><MDBIcon far icon="eye" title="View" /></Button> : <></>}</>
          ]
        }
      )
    })
    const dataList = {
      columns: data.columns,
      rows: testData
    };

    return (
      <>
        {
          !loader ?
            <>
              <Header />
              <Container className="mt-0" fluid>
                <Row>
                  <div className="col">
                    <Table title="Material Category (Family) Listing" data={dataList} pageNumber={this.state.pageNumber} />
                  </div>
                  <ConfirmModal open={open} toggle={this.toggle} confirmation={this.confirmation} id={this.state.id} name={this.state.name} />
                </Row>
              </Container>
            </>
            :
            <div className="loader">
              <Spinner variant="light" />
              <p style={{ color: '#fff', paddingTop: '15px' }}>Please wait......</p>
            </div>
        }
      </>
    );
  }
}
const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    category,
    initialState,
    deleteCategory
  }, dispatch)
}
const mapGetStateNew = (state) => {
  return {
    catgeorySuccess: state.category.category,
    categoryFailed: state.category.category,
    deletecategory: state.category.deleteCategory
  }
}
export default connect(mapGetStateNew, mapDispatchState)(CategoriesList);
