import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Col, Row } from 'reactstrap';
import InlineError from '../../sharedFile/inlineError'
import Required from '../../sharedFile/required';

const ManageVarient = (props) => {
  const { errors, dataVarient, varients } = props
  return (
    <div>
      <Modal isOpen={props.open} toggle={props.toggle} style={{ maxWidth: '650px', width: '90%', marginRight: 'auto', marginLeft: 'auto' }} className={'manage_varients modal-dialog-centered'}>
        <ModalHeader toggle={props.toggle} style={{ borderBottomWidth: '1px' }}><h2>Add Variants</h2></ModalHeader>
        <ModalBody style={{ padding: '0px' }}>
          <Form>
            {
              dataVarient.varient && dataVarient.varient.map((itemsList, id) => {
                return <Row key={id} className="align-items-center">
                  <Col sm="6">
                    <FormGroup className="mb-0" error={errors.varientId.map(err => { return !err.key })}>
                      <Label for="exampleSelectMulti">Select Variants <Required /></Label>
                      <Input type="select" name="varientId" value={itemsList.varientId} id="exampleSelectMulti" onChange={props.onChange(id)}>
                        <option value="">Select variant</option>
                        {varients && varients.varients && varients.varients.map((items, key) => {
                          return <option value={items.id}>{items.name}</option>
                        })
                        }
                      </Input>
                      <div style={{ height: '19px' }}>{errors.varientId.map((err, key) => { if (id === err.key) return err.err && <InlineError text={err.err} /> })}</div>
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup className="mb-0" error={!errors.value}>
                      <label
                        className=""
                        htmlFor="input-value"
                      >
                        Value
                      </label>
                      <div className="d-flex">
                        <Input
                          className="form-control-alternative"
                          value={itemsList.value}
                          style={{ borderRadius: ' 0.375rem 0px 0px 0.375rem' }}
                          onChange={props.onChange(id)}
                          name="value"
                          id="input-value"
                          placeholder="Value"
                          type="text"
                        />
                        <Button
                          onClick={props.handleRemove(id)}
                          className="small button-remove"
                          style={{ border: '1px solid #e1e1e1', boxShadow: 'none', background: "#ffffff" }}
                        >
                          x
                    </Button>
                      </div>
                      <div style={{ height: '19px' }}>{errors.varientId && <InlineError text="" />}</div>
                    </FormGroup>
                  </Col>
                  <Col sm="2">
                    <FormGroup className="m-0 clear_field">
                      <div style={{ height: '19px' }}>{errors.varientId && <InlineError text="" />}</div>
                    </FormGroup>
                  </Col>
                </Row>
              })
            }
            {(varients && varients.varients && varients.varients.length) === dataVarient.varient.length ? <div className="mb-2"><InlineError text="You have reached at maximum limit of adding variants." /></div>:''}
            <Button className="mobil_margin" color="primary" disabled={varients && varients.varients && varients.varients.length === dataVarient.varient.length ? true : false} onClick={props.onAddMoreVraients} >Add More Variant</Button>
            <ModalFooter className="delete-confirmation">
              <Button color="primary" onClick={props.onSubmit}>Assign</Button>
              <Button color="secondary" onClick={props.toggle}>Cancel</Button>
            </ModalFooter>
          </Form>
        </ModalBody>

      </Modal>
    </div>
  );
}
export default ManageVarient;