import { call, put, takeLatest, all } from "redux-saga/effects";
import { 
    GET_RATIO,
    GET_RATIO_SUCCESS,
    GET_RATIO_FAILED,
    GET_UNIT,
    GET_UNIT_SUCCESS,
    GET_UNIT_FAILED,
    GET_RATIO_ADD,
    GET_UNIT_ADD,
    GET_RATIO_ADD_PICTURE,
    GET_RATIO_ADD_PICTURE_FAILED,
    GET_UNIT_ADD_PICTURE,
    GET_UNIT_ADD_PICTURE_FAILED,
    GET_RATIO_ADD_PICTURE_SUCCESS,
} from "../../sharedFile/actionType";
import Api from "../../lib/api";
function ratioGetApi(action){
    return Api.post(
      `order/ratio/list`,action.data
    );
}
function* ratioGet(action){
    try {
        const resp = yield call(ratioGetApi, action);
        if (resp.success === true) {
          yield put({ type: GET_RATIO_SUCCESS, data: resp })
        } else {
          yield put({ type: GET_RATIO_FAILED, error: resp })
        }
      } catch (resp) {
        yield put({ type: GET_RATIO_FAILED, error: resp })
      }
}
function unitGetApi(action){
    return Api.post(
        `order/units/list`,action.data
    );
}
function* unitGet(action){
    try {
        const resp = yield call(unitGetApi, action);
        if (resp.success === true) {
          yield put({ type: GET_UNIT_SUCCESS, data: resp })
        } else {
          yield put({ type: GET_UNIT_FAILED, error: resp })
        }
      } catch (resp) {
        yield put({ type: GET_UNIT_FAILED, error: resp })
      }
}
function ratioAddApi(action){
    return Api.post(
      `order/ratio`,action.data
    );
}
function* ratioAdd(action){
    try {
        const resp = yield call(ratioAddApi, action);
        let id = {'orderid':resp.order.id}
        if (resp.success === true) {
          yield put({ type: GET_RATIO_ADD_PICTURE_SUCCESS, data: resp })
          yield put({ type: GET_RATIO_ADD_PICTURE, dataPic:action.dataPic, id})
        } else {
          yield put({ type: GET_RATIO_FAILED, error: resp })
        }
      } catch (resp) {
        yield put({ type: GET_RATIO_FAILED, error: resp })
      }
}
function unitAddApi(action){
    return Api.post(
      `order/units`,action.data
    );
}
function* unitAdd(action){
    try {
        const resp = yield call(unitAddApi, action);
        let id = {'orderid':resp.order.id}
        if (resp.success === true) {
          yield put({ type: GET_RATIO_ADD_PICTURE_SUCCESS, data: resp })
          yield put({ type: GET_UNIT_ADD_PICTURE, dataPic:action.dataPic, id})
        } else {
          yield put({ type: GET_UNIT_FAILED, error: resp })
        }
      } catch (resp) {
        yield put({ type: GET_UNIT_FAILED, error: resp })
      }
}
function ratioAddPicApi(action){
  return Api.post(
    `order/ratioimage/`+action.id.orderid,action.dataPic
  );
}
function* ratioAddPic(action){
  try {
      const resp = yield call(ratioAddPicApi, action);
      if (resp.success === true) {
        yield put({ type: GET_UNIT_SUCCESS, data: resp })
      } else {
        yield put({ type: GET_RATIO_ADD_PICTURE_FAILED, error: resp })
      }
    } catch (resp) {
      yield put({ type: GET_RATIO_ADD_PICTURE_FAILED, error: resp })
    }
}
function unitAddPicApi(action){
  return Api.post(
    `order/unitsimage/`+action.id.orderid,action.dataPic
  );
}
function* unitAddPic(action){
  try {
      const resp = yield call(unitAddPicApi, action);
      if (resp.success === true) {
        yield put({ type: GET_RATIO_SUCCESS, data: resp })
      } else {
        yield put({ type: GET_UNIT_ADD_PICTURE_FAILED, error: resp })
      }
    } catch (resp) {
      yield put({ type: GET_UNIT_ADD_PICTURE_FAILED, error: resp })
    }
}
function* varient() {
    yield all([
      takeLatest(GET_RATIO, ratioGet),
      takeLatest(GET_UNIT, unitGet),
      takeLatest(GET_RATIO_ADD, ratioAdd),
      takeLatest(GET_UNIT_ADD, unitAdd),
      takeLatest(GET_UNIT_ADD_PICTURE, unitAddPic),
      takeLatest(GET_RATIO_ADD_PICTURE, ratioAddPic)
    ])
}
export default varient;