import {
    SET_CATEGORY_FAILED,
    SET_CATEGORY_SUCCESS,
    SET_ALLCATEGORY_FAILED,
    SET_ALLCATEGORY_SUCCESS,
    ADD_CATEGORY_FAILED,
    ADD_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAILED,
    DELETE_CATEGORY_SUCCESS,
    VIEW_CATEGORY_FAILED,
    VIEW_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_FAILED,
    UPDATE_CATEGORY_SUCCESS,
    VIEW_ASSIGN_VARIENT_SUCCESS,
    VIEW_ASSIGN_VARIENT_FAILED,
    DELETE_ASSIGN_VARIENT_SUCCESS,
    DELETE_ASSIGN_VARIENT_FAILED,
    GET_INITIALSTATE,
    GET_CATEGORY_ROUTE_SUCCESS,
    GET_CATEGORY_ROUTE_FAILED,
    SET_ADD_ALL_CATEGORY_FOR_SUB_CATEGORY_SUCCESS
} from "../../sharedFile/actionType";

const initialState = {
    category: [],
    error: false,
    message:false,
    deleted:false
}
const category = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALLCATEGORY_SUCCESS:
            return {
                ...state,
                category: action.data
            }
        case SET_ADD_ALL_CATEGORY_FOR_SUB_CATEGORY_SUCCESS:
            return{
                category:{categories: state.category.categories,cat:[...state.category.cat,{...action.data.subcategory}]}
            }
        case SET_ALLCATEGORY_FAILED:
            return {
                ...state,
                error: true,
                category: action.error
            }
        case SET_CATEGORY_SUCCESS:
            return {
                ...state,
                category: action.data
            }
        case SET_CATEGORY_FAILED:
            return {
                ...state,
                error: true,
                category: action.error
            }
        case ADD_CATEGORY_SUCCESS:
            return {
                ...state,
                category: action.data
            }
        case ADD_CATEGORY_FAILED:
            return {
                ...state,
                error: true,
                category: action.error
            }
        case DELETE_CATEGORY_SUCCESS:
            return {
                ...state,
                category: { categories: state.category.categories.filter(item => item.id !== action.data.category.id), success: true }
            }
        case DELETE_CATEGORY_FAILED:
            return {
                ...state,
                error: true
            }
        case DELETE_ASSIGN_VARIENT_SUCCESS:
            return {
                ...state,
                varientAssign: { categories: state.varientAssign.categories.filter(item => item.id !== action.data.category.id), success: true, message:'Variant Deleted Successfully' },
                deleted:true
            }
        case DELETE_ASSIGN_VARIENT_FAILED:
            return {
                ...state,
                varientAssignDelete: action.error
            }
        case VIEW_CATEGORY_SUCCESS:
            return {
                ...state,
                detailcategory: action.data
            }
        case VIEW_CATEGORY_FAILED:
            return {
                ...state,
                error: true,
                detailcategory: action.error
            }
        case VIEW_ASSIGN_VARIENT_SUCCESS:
            return {
                ...state,
                varientAssign: action.data,
                message: action.message
            }
        case VIEW_ASSIGN_VARIENT_FAILED:
            return {
                ...state,
                error: true,
                varientAssign: action.error
            }
        case GET_CATEGORY_ROUTE_SUCCESS:
            return {
                ...state,
                routeCategory: action.data
            }
        case GET_CATEGORY_ROUTE_FAILED:
            return {
                ...state,
                routeCategory: action.error
            }
        case UPDATE_CATEGORY_SUCCESS:
            return {
                ...state,
                detailcategory: action.data
            }
        case UPDATE_CATEGORY_FAILED:
            return {
                ...state,
                error: true,
                detailcategory: action.error
            }
        case GET_INITIALSTATE:
            return {
                ...state,
                // category: [],
                varientAssignDelete:[],
                message:false,
                deleted:false,
            }
        default:
            return state
    }

}
export default category;