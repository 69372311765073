import {
    GET_RATIO_SUCCESS,
    GET_RATIO_FAILED,
    GET_UNIT_SUCCESS,
    GET_UNIT_FAILED,
    GET_INITIALSTATE,
} from "../../sharedFile/actionType";
const initialState = {
    ratioWise: [],
    error: false
}
const unitRatioWise = (state = initialState, action) => {
    switch (action.type) {
        case GET_RATIO_SUCCESS:
            return {
                ...state,
                ratioWise: action.data,
            }
        case GET_RATIO_FAILED:
            return {
                ...state,
                ratioWise: action.error,
                error:true
            }
        case GET_UNIT_SUCCESS:
            return {
                ...state,
                ratioWise: action.data,
            }
        case GET_UNIT_FAILED:
            return {
                ...state,
                ratioWise: action.error,
                error:true
            }
        case GET_INITIALSTATE:
            return {
                ratioWise:[]
            }
        default:
            return state
    }
}
export default unitRatioWise;