import React from 'react';
import {
    Button,
    Form,
    FormGroup,
    Input,
    CardHeader,
    NavItem,
    Spinner,
    Nav,
} from 'reactstrap';
import InlineError from '../../../sharedFile/inlineError';
import { imagerUrl } from '../../../config/config';
export default function OrdersFrom(props) {
    const { brandSuccess, errors, loader, location } = props
    return (
                <>
                    <div className="mt-0 mb-5">
                    <CardHeader className="border-0 pt-0 pl-3">
                        <h2 className="mb-0">Import Order (CSV/Excel file)</h2>
                    </CardHeader>
                        <Form className="upload_sheet py-4" onSubmit={props.onSubmit}>
                            <FormGroup error={!errors.brandId}>
                                 <label className="">
                                    Select Brand
                                </label>
                                <Input type="select" name="brandId" id="exampleSelect" onChange={props.onChange}>
                                    <option>Select Brand</option>
                                    {
                                        brandSuccess.brands && brandSuccess.brands.map((value, key) => {
                                            return (
                                                <option value={value.id} key={value.id}>{value.name}</option>
                                            )
                                        })
                                    }
                                </Input>
                                {errors.brandId && <InlineError text={errors.brandId} />}
                            </FormGroup>
                            {
                                location.pathname === '/Orders' ?
                                    <div className="form-group">
                                        <label className="">
                                            Choose order sheet
                                        </label>
                                        <FormGroup className="brandOrder" error={!errors.picture} style={{ width: '100%', position:'relative' }}>
                                            <label className="form-control-file custom_label_input cursor-pointer" for="exampleFile"><i class="fa fa-upload" aria-hidden="true"></i> Add file</label>
                                            <Input type="file" name="file" id="exampleFile" style={{border:'0'}} accept=".xlsx, .xls" multiple onChange={props.onFileChange} />
                                            {errors.picture && <InlineError text={errors.picture} />}
                                        </FormGroup>
                                    </div>
                                    :
                                    <div className="flex">
                                        <FormGroup error={!errors.dateFrom}>
                                            <h2 htmlFor="dateFrom">Date From:</h2>
                                            <Input
                                                type="date"
                                                name="dateFrom"
                                                id="dateFrom"
                                                placeholder="date placeholder"
                                                onChange={props.onChange}
                                            />
                                            {errors.dateFrom && <InlineError text={errors.dateFrom} />}
                                        </FormGroup>
                                        <FormGroup error={!errors.dateTo}>
                                            <h2 htmlFor="dateTo">Date To:</h2>
                                            <Input
                                                type="date"
                                                name="dateTo"
                                                id="dateTo"
                                                placeholder="date placeholder"
                                                onChange={props.onChange}
                                            />
                                            {errors.dateTo && <InlineError text={errors.dateTo} />}
                                        </FormGroup>
                                    </div>
                            }

                            <Nav className="justify-content-start" pills>
                                <NavItem className="custom-submit text-left">
                                    <Button type="submit" disabled={loader ? true : false} className="btn btn-primary submit-button padding-button" color="primary">

                                        {
                                            loader
                                                ?
                                                <Spinner color="light" />
                                                :
                                                'Upload'
                                        }

                                    </Button>
                                    {/* <NavLink to="/Mainordersheet" tag={Link} className="btn btn-primary">Upload</NavLink> */}
                                </NavItem>
                            </Nav>
                            <a href={imagerUrl+'/demo-order.xls'} download className="text-right pb-2 d-block" style={{fontSize: '12px'}} target='_blank'><i class="fas fa-download"></i> Demo Order File</a>
                        </Form>
                    </div>
                </>
    )
}