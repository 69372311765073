import { call, put, takeLatest, all } from "redux-saga/effects";

import { 
  GET_FORGET_PASSWORD,
  SET_FORGET_SUCCESS,
  SET_FORGET_FAILED,
  SET_LOGIN_SUCCESS, 
  SET_LOGIN_FAILED,
  GET_LOGIN, 
  GET_PROFILE_DETAIL,
  SET_PROFILE_DETAIL_SUCCESS,
  SET_PROFILE_DETAIL_FAILED, 
  GET_PROFILE_UPDATE, 
  SET_USER_DETAIL_SUCCESS, 
  GET_ROLE, SET_ROLE_SUCCESS, 
  SET_ROLE_FAILED, 
  GET_USER_ADD, 
  SET_USER_ADD_SUCCESS, 
  SET_USER_ADD_FAILED, 
  GET_USER_LIST, 
  SET_USER_LIST_SUCCESS, 
  SET_USER_LIST_FAILED, 
  DELETE_USER,DELETE_USER_SUCCESS, 
  DELETE_USER_FAILED, 
  GET_USER_DETAIL, 
  SET_USER_DETAIL_FAILED, 
  GET_USER_UPDATE, 
  GET_USER_UPDATE_FAILED,
  GET_CATEGORY_ROUTE
} from "../../sharedFile/actionType";
import Api from "../../lib/api";
import {categoryRoute} from '../actions/category'
// Login API
function postLoginApi(action){
    const {email, password} = action.data
    return Api.post(
        `users/login`,
        {
            userName:email,
            password:password
        }
    );
}
function* login(action){
    try {
        const resp = yield call(postLoginApi, action);
        if (resp.success) {
          sessionStorage.userJWT = resp.token
          sessionStorage.userROLE = resp.user && resp.user.userroles.slug
          yield put({ type: SET_LOGIN_SUCCESS, token: resp.token, role: resp.user.userroles.slug})
          yield put({type:GET_CATEGORY_ROUTE})
        } else {
          yield put({ type: SET_LOGIN_FAILED, error: resp })
        }
      } catch (resp) {
        yield put({ type: SET_LOGIN_FAILED, error: resp })
      }
}
function postforgotApi(action){
  const {email} = action.data
  return Api.post(
    `users/forgetPassword`,
    {
      email:email,
       
    }
);
}
function* forgetpassword(action){
  try {
    const resp = yield call(postforgotApi, action);
    if (resp.success) {
      yield put({ type: SET_FORGET_SUCCESS, data: resp })
    } else {
      yield put({ type: SET_FORGET_FAILED, data: resp })
    }
  } catch (resp) {
    yield put({ type: SET_FORGET_FAILED, data: resp })
  }
}
// get profile Detail
function getProfileApi(){
  return Api.get(
      `users/profile`
  );
}
function* getProfile(){
  try {
      const resp = yield call(getProfileApi);
      if (resp.success) {
        yield put({ type: SET_PROFILE_DETAIL_SUCCESS, data: resp })
      } else {
        yield put({ type: SET_PROFILE_DETAIL_FAILED, error: resp })
      }
    } catch (resp) {
      yield put({ type: SET_PROFILE_DETAIL_FAILED, error: resp })
    }
}
// get profile Update
function postProfileApi(action){
  const {data}= action
  return Api.post(
      `users/profile`,{
        oldPassword:data.oldPassword,
        newPassword:data.newPassword,
        firstName:data.first_name,
        lastName:data.last_name,
        phone:data.phone_number,
        address1:data.address_one,
        address2:data.address_two,
        city:data.city,
        state:data.state,
        zip:data.postal_code,
      }
  );
}
function* updateProfile(action){
  try {
      const resp = yield call(postProfileApi, action);
      if (resp.success) {
        yield put({ type: SET_PROFILE_DETAIL_SUCCESS, data: resp })
      } else {
        yield put({ type: SET_PROFILE_DETAIL_FAILED, error: resp })
      }
    } catch (resp) {
      yield put({ type: SET_PROFILE_DETAIL_FAILED, error: resp })
    }
}
function roleApi(){
  return Api.get(`users/roles`);
}
function* roleListUser(){
  try {
      const resp = yield call(roleApi);
      if (resp.success) {
        yield put({ type: SET_ROLE_SUCCESS, data: resp })
      } else {
        yield put({ type: SET_ROLE_FAILED, error: resp })
      }
    } catch (resp) {
      yield put({ type: SET_ROLE_FAILED, error: resp })
    }
}
function userAddApi(action){
  const {data} = action
  return Api.post(`users/signup`,{
    userName: data.userName,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    password: data.password,
    phone: data.phone,
    roleId: data.roleId,
    address1: data.address,
    city: data.city,
    state: data.state,
    zip: data.zip,
    country:data.country,
  });
}
function* userAdd(action){
  try {
      const resp = yield call(userAddApi, action);
      if (resp.success) {
        yield put({ type: SET_USER_ADD_SUCCESS, data: resp })
      } else {
        yield put({ type: SET_USER_ADD_FAILED, error: resp })
      }
    } catch (resp) {
      yield put({ type: SET_USER_ADD_FAILED, error: resp })
    }
}
function userListApi(){
  return Api.get(`users/list`);
}
function* userList(){
  try {
      const resp = yield call(userListApi);
      if (resp.success) {
        yield put({ type: SET_USER_LIST_SUCCESS, data: resp })
      } else {
        yield put({ type: SET_USER_LIST_FAILED, error: resp })
      }
    } catch (resp) {
      yield put({ type: SET_USER_LIST_FAILED, error: resp })
    }
}
function deleteUserApi(action){
  return Api.delete(`users/`+action.data);
}
function* deleteUser(action){
  try {
      const resp = yield call(deleteUserApi,action);
      if (resp.success) {
        yield put({ type: DELETE_USER_SUCCESS, data: resp, id:action.data })
      } else {
        yield put({ type: DELETE_USER_FAILED, error: resp })
      }
    } catch (resp) {
      yield put({ type: DELETE_USER_FAILED, error: resp })
    }
}
function userDetailApi(action){
  return Api.get(`users/`+action.data);
}
function* userDetail(action){
  try {
      const resp = yield call(userDetailApi,action);
      if (resp.success) {
        yield put({ type: SET_USER_DETAIL_SUCCESS, data: resp, redirect:false })
      } else {
        yield put({ type: SET_USER_DETAIL_FAILED, error: resp })
      }
    } catch (resp) {
      yield put({ type: SET_USER_DETAIL_FAILED, error: resp })
    }
}
function userUpdateApi(action){
  const {data} = action
  return Api.put(`users/`+data.id.id,{
    userName: data.userName,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    newPassword:data.newPassword,
    phone: data.phone,
    roleId: data.roleId,
    address1: data.address,
    city: data.city,
    state: data.state,
    zip: data.zip,
    country:data.country,
  });
}
function* userUpdate(action){
  try {
      const resp = yield call(userUpdateApi, action);
      if (resp.success) {
        yield put({ type: SET_USER_DETAIL_SUCCESS, data: resp,redirect:true })
      } else {
        yield put({ type: GET_USER_UPDATE_FAILED, error: resp })
      }
    } catch (resp) {
      yield put({ type: GET_USER_UPDATE_FAILED, error: resp })
    }
}
function* userAuth() {
    yield all([
      takeLatest(GET_LOGIN, login),
      takeLatest(GET_FORGET_PASSWORD, forgetpassword),
      takeLatest(GET_PROFILE_DETAIL, getProfile),
      takeLatest(GET_PROFILE_UPDATE, updateProfile),
      takeLatest(GET_ROLE, roleListUser),
      takeLatest(GET_USER_ADD, userAdd),
      takeLatest(GET_USER_LIST, userList),
      takeLatest(DELETE_USER, deleteUser),
      takeLatest(GET_USER_DETAIL, userDetail),
      takeLatest(GET_USER_UPDATE, userUpdate)
    ])
}
export default userAuth;