import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, Form, Row, Col, Input, FormGroup } from 'reactstrap';
import dataJson from '../sharedFile/allData'
import RepeatConfirmModal from './repeatConfirm';
import { mainAddRowZipper, initialState } from '../services/actions/order';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { bindActionCreators } from 'redux'
class AddZipperRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                useArea: '',
                pageNumber: 1,
                useArea: "",
            },
            open: false
        }
    }
    onChange = (e) => {
        this.setState({
            data: {
                ...this.state.data,
                [e.target.name]: e.target.value
            }
        })
    }
    componentWillReceiveProps(props) {
        const { addRowData, addRow } = props
        if (addRow == true) {
            this.setState({
                data: {
                    ...this.state.data,
                    ...addRowData
                }
            })
        }
    }
    confirmation = (e, id) => {
        this.setState({
            open: true
        })
    }
    toggle = () => {
        this.setState({
            open: false
        })
    }
    confirmAddRow = () => {
        this.setState({
            open: false,
        })
        this.props.toggle();
        this.props.mainAddRowZipper(this.state.data)
    }
    componentWillUnmount() {
        this.props.initialState()
    }
    render() {
        const { addRow, toggle } = this.props
        const { data, open } = this.state
        return (
            <Modal isOpen={addRow} toggle={toggle} className={'clssName modal-dialog-centered'}>
                <ToastContainer autoClose={10000} />
                <ModalBody>
                    <h3>Add Zipper Row</h3>
                    <Form className="mb-2 mt-3 main_col_form pad_col_none" onSubmit={this.onSubmit}>
                        <Row>
                            <Col sm="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-name"
                                    >
                                        Zipper Use Area
                                                </label>
                                    <Input type="select" name="useArea" defaultValue={data.useArea} id="exampleSelectMulti" onChange={this.onChange}>
                                        <option>Select Zipper Area</option>
                                        {dataJson.zipperArea.map((items, key) => {
                                            return (
                                                <option value={items} key={key}>{items}</option>
                                            )
                                        })
                                        }
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter className="pt-0">
                    <Button color="primary" onClick={e => this.confirmation(e, data.brandOrderId)}>Yes</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
                <RepeatConfirmModal open={open} name="add row" toggle={this.toggle} confirmation={this.confirmAddRow} />
            </Modal>
        )
    }
}
const mapDispatchState = (dispatch) => {
    return bindActionCreators({
        mainAddRowZipper,
        initialState
    }, dispatch)
}
export default connect(null, mapDispatchState)(AddZipperRow);