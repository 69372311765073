import { 
    SET_STYLE_FAILED,
    SET_STYLE_SUCCESS, 
    ADD_STYLE_FAILED,
    ADD_STYLE_SUCCESS, 
    DELETE_STYLE_FAILED,
    DELETE_STYLE_SUCCESS, 
    VIEW_STYLE_FAILED,
    VIEW_STYLE_SUCCESS, 
    UPDATE_STYLE_FAILED,
    UPDATE_STYLE_SUCCESS  
} from "../../sharedFile/actionType";

const initialState = {
    style: [],
    error:false
}
const style = (state = initialState, action) => {
    switch(action.type){
        case SET_STYLE_SUCCESS:
        return{
            ...state,
            style: action.data
        }
        case SET_STYLE_FAILED:
        return{
            ...state,
            error:true,
            style: action.error
        } 
        case ADD_STYLE_SUCCESS:
        return{
            ...state,
            style: action.data
        }
        case ADD_STYLE_FAILED:
        return{
            ...state,
            error:true,
            style: action.error
        }
        case DELETE_STYLE_SUCCESS:
        return{
            ...state,
            style: {styles: state.style.styles.filter(item => item.id !== action.data.styles.id), success: true}
        }
        case DELETE_STYLE_FAILED:
        return{
            ...state,
            error:true
        }
        case VIEW_STYLE_SUCCESS:
        return{
            ...state,
            detailstyle: action.data
        }
        case VIEW_STYLE_FAILED:
        return{
            ...state,
            error:true,
            detailstyle: action.error
        }
        case UPDATE_STYLE_SUCCESS:
        return{
            ...state,
            detailstyle: action.data
        }
        case UPDATE_STYLE_FAILED:
        return{
            ...state,
            error:true,
            detailstyle: action.error
        }
        default:
            return state
    }
    
}
export default style;