import {
    SET_LOGIN_FAILED,
    SET_LOGIN_SUCCESS,
    GET_FORGET_PASSWORD,
    SET_FORGET_SUCCESS,
    SET_FORGET_FAILED,
    GET_LOG_OUT,
    SET_PROFILE_DETAIL_SUCCESS,
    SET_PROFILE_DETAIL_FAILED,
    SET_ROLE_SUCCESS,
    SET_ROLE_FAILED,
    SET_USER_ADD_SUCCESS,
    SET_USER_ADD_FAILED,
    SET_USER_LIST_SUCCESS,
    SET_USER_LIST_FAILED,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILED,
    SET_USER_DETAIL_SUCCESS,
    SET_USER_DETAIL_FAILED,
    GET_INITIALSTATE,
    GET_USER_UPDATE_SUCCESS
} from "../../sharedFile/actionType";

const initialState = {
    login: [],
    profile: [],
    tokenSuccess: false,
    mailstatus: [],
    roleUserList: [],
    signup: [],
    signupList: [],
    redirect:false
}
const user = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_UPDATE_SUCCESS:
            return {
                ...state,
                signup: action.data,
            }
        case SET_USER_DETAIL_SUCCESS:
            return {
                ...state,
                signupList: action.data,
                redirect:action.redirect
            }
        case SET_USER_DETAIL_FAILED:
            return {
                ...state,
                signupList: action.error
            }
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                signupList: { user: state.signupList.user.filter(item => item.id !== action.id), success: true },
                deleteUser:action.data
            }
        case DELETE_USER_FAILED:
            return {
                ...state,
                deleteUser: action.error
            }
        case SET_USER_LIST_SUCCESS:
            return {
                ...state,
                signupList: action.data,
            }
        case SET_USER_LIST_FAILED:
            return {
                ...state,
                signupList: action.error
            }
        case SET_USER_ADD_SUCCESS:
            return {
                ...state,
                signup: action.data,
            }
        case SET_USER_ADD_FAILED:
            return {
                ...state,
                signup: action.error
            }
        case SET_ROLE_SUCCESS:
            return {
                ...state,
                roleUserList: action.data,
            }
        case SET_ROLE_FAILED:
            return {
                ...state,
                roleUserList: action.error
            }
        case SET_LOGIN_SUCCESS:
            return {
                ...state,
                token: action.token,
                role: action.role,
                tokenSuccess: true,
            }
        case SET_LOGIN_FAILED:
            return {
                ...state,
                login: action.error
            }
        case GET_FORGET_PASSWORD:
            return {
                forgetpassword: action.data
            }
        case SET_FORGET_SUCCESS:
            return {
                mailstatus: action.data
            }
        case SET_FORGET_FAILED:
            return {
                mailstatus: action.data
            }
        case SET_PROFILE_DETAIL_SUCCESS:
            return {
                ...state,
                profile: action.data,
            }
        case SET_PROFILE_DETAIL_FAILED:
            return {
                ...state,
                profile: action.error
            }
        case GET_INITIALSTATE:
            return {
                ...state,
                signup: [],
                signupList: [],
                deleteUser:[],
                redirect:false
            }
        case GET_LOG_OUT:
            sessionStorage.clear();
            return {
                ...initialState,
            }

        default:
            return state
    }

}
export default user;