import React from "react";
import Header from "../../components/Headers/Header";
import { userDetail } from '../../services/actions/auth'
import { initialState } from '../../services/actions/order'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ToastContainer, toast } from 'react-toastify';
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Nav,
    Spinner
} from "reactstrap";
class UserDetailList extends React.Component {
    state = {
        data: {
            userName: '',
            firstName: '',
            lastName: '',
            email: '',
            password: 'safs',
            phone: '',
            roleId: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            id: this.props.match.params,
        },
        loader: false,
        message: ""
    }
    componentDidMount() {
        this.props.userDetail(this.props.match.params.id);
        this.setState({
            loader: true
        })
    }
    componentWillReceiveProps(newProps) {
        const { signUpList } = newProps
        if (signUpList && signUpList.success) {
            const { user } = signUpList
            this.setState({
                data: {
                    ...this.state.data,
                    userName: user.userName,
                    firstName: user.useremployees[0] && user.useremployees[0].firstName,
                    lastName: user.useremployees[0] && user.useremployees[0].lastName,
                    email: user.email,
                    phone: user.useremployees[0] && user.useremployees[0].phone,
                    role: user.userroles.name,
                    address: user.useremployees[0] && user.useremployees[0].address1,
                    city: user.useremployees[0] && user.useremployees[0].city,
                    state: user.useremployees[0] && user.useremployees[0].state,
                    zip: user.useremployees[0] && user.useremployees[0].zip,
                },
                loader: false
            })
        }
        if (signUpList && signUpList.success === false) {
            this.setState({
                loader: false
            })
            toast.error(newProps.detailvarient)
        }
    }
    componentWillUnmount() {
        this.props.initialState()
    }
    render() {
        const { data, loader } = this.state
        return (
            <>
                {
                    !loader ?
                        <>
                            <ToastContainer autoClose={10000} />
                            <Container className="mt-0" fluid>
                                <Row>
                                    <Col className="order-xl-1" xl="12">
                                        <Card className="card_border">
                                            <CardHeader className="bg-white border-0 mb-4">
                                                <Row className="align-items-center mobile_sec_col">
                                                    <div className="col">
                                                        <h6 className="text-uppercase text-light ls-1 mb-1">
                                                        </h6>
                                                        <h2 className="mb-0 text-mob-left text-center">User Detail</h2>
                                                    </div>
                                                    <div className="col back_btn">
                                                        <Nav className="justify-content-end" pills>
                                                            <NavItem>
                                                                <NavLink className="btn btn-primary" to={'/users'} tag={Link}  >Back</NavLink>
                                                            </NavItem>
                                                        </Nav>
                                                    </div>
                                                </Row>
                                            </CardHeader>
                                            <CardBody className="pt-0 detail_page mb-5 shadow">
                                                <Row>
                                                    <Col className="p-md-4 p-3" md="5">
                                                        <h3 className="mb-md-3 mb-2">User Basic Information</h3>
                                                        <CardBody className="p-1">
                                                            <h4 className="mb-0">User Name:</h4>
                                                            <p className="mb-0">{data.userName}</p>
                                                        </CardBody>
                                                        <CardBody className="p-1">
                                                            <h4 className="mb-0">First Name:</h4>
                                                            <p className="mb-0">{data.firstName}</p>
                                                        </CardBody>
                                                        <CardBody className="p-1">
                                                            <h4 className="mb-0">Last Name:</h4>
                                                            <p className="mb-0">{data.lastName}</p>
                                                        </CardBody>
                                                        <CardBody className="p-1">
                                                            <h4 className="mb-0">Phone:</h4>
                                                            <p className="mb-0">{data.phone}</p>
                                                        </CardBody>
                                                        <CardBody className="p-1">
                                                            <h4 className="mb-0">Email:</h4>
                                                            <p className="mb-0">{data.email}</p>
                                                        </CardBody>
                                                        <CardBody className="p-1">
                                                            <h4 className="mb-0">Role:</h4>
                                                            <p className="mb-0">{data.role}</p>
                                                        </CardBody>
                                                    </Col>

                                                    <Col className="p-md-4 p-3" md="6">
                                                        <h3 className="mb-md-3 mb-2">User Address Information</h3>
                                                        <CardBody className="p-1">
                                                            <h4 className="mb-0">Address:</h4>
                                                            <p className="mb-0">{data.address}</p>
                                                        </CardBody>
                                                        <CardBody className="p-1">
                                                            <h4 className="mb-0">City:</h4>
                                                            <p className="mb-0">{data.city}</p>
                                                        </CardBody>
                                                        <CardBody className="p-1">
                                                            <h4 className="mb-0">State:</h4>
                                                            <p className="mb-0">{data.state}</p>
                                                        </CardBody>
                                                        <CardBody className="p-1">
                                                            <h4 className="mb-0">Zip Code:</h4>
                                                            <p className="mb-0">{data.zip}</p>
                                                        </CardBody>
                                                    </Col>

                                                </Row>
                                                {/* <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="4">
                                                <CardBody><h4>User Name:</h4></CardBody>
                                            </Col>
                                            <Col lg="8">
                                                <CardBody>{data.userName}</CardBody>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">
                                                <CardBody><h4>Email:</h4></CardBody>
                                            </Col>
                                            <Col lg="8">
                                                <CardBody>{data.email}</CardBody>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">
                                                <CardBody><h4>First Name:</h4></CardBody>
                                            </Col>
                                            <Col lg="8">
                                                <CardBody>{data.firstName}</CardBody>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">
                                                <CardBody><h4>Last Name:</h4></CardBody>
                                            </Col>
                                            <Col lg="8">
                                                <CardBody>{data.lastName}</CardBody>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">
                                                <CardBody><h4>Phone:</h4></CardBody>
                                            </Col>
                                            <Col lg="8">
                                                <CardBody>{data.phone}</CardBody>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">
                                                <CardBody><h4>Role:</h4></CardBody>
                                            </Col>
                                            <Col lg="8">
                                                <CardBody>{data.role}</CardBody>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">
                                                <CardBody><h4>Address:</h4></CardBody>
                                            </Col>
                                            <Col lg="8">
                                                <CardBody>{data.address}</CardBody>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">
                                                <CardBody><h4>City:</h4></CardBody>
                                            </Col>
                                            <Col lg="8">
                                                <CardBody>{data.city}</CardBody>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">
                                                <CardBody><h4>State:</h4></CardBody>
                                            </Col>
                                            <Col lg="8">
                                                <CardBody>{data.state}</CardBody>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">
                                                <CardBody><h4>Zip Code:</h4></CardBody>
                                            </Col>
                                            <Col lg="8">
                                                <CardBody>{data.zip}</CardBody>
                                            </Col>
                                        </Row>
                                    </div> */}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </>
                        :
                        <div className="loader">
                            <Spinner variant="light" />
                            <p style={{ color: '#fff', paddingTop: '15px' }}>Please wait......</p>
                        </div>
                }
            </>
        );
    }
}
const mapDispatchState = (dispatch) => {
    return bindActionCreators({
        userDetail,
        initialState
    }, dispatch)
}
const mapGetStateNew = (state) => {
    return {
        signUpList: state.user.signupList,
    }
}
export default connect(mapGetStateNew, mapDispatchState)(UserDetailList);
