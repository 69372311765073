import React from 'react';
import Header from '../../components/Headers/Header';
import UserTable from './userTable';
import { MDBIcon } from 'mdbreact';
import {
    Container,
    Row,
    Spinner,
    Button
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { userList, deleteUser } from '../../services/actions/auth';
import { initialState } from '../../services/actions/order'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ConfirmModal from '../../components/ConfirmModal';
import { ToastContainer, toast } from 'react-toastify';
var toastId = null
class UserList extends React.Component {
    state = {
        loader: false,
        title: 'Users Listing',
        url: '/addUser',
        open: false,
        id: null,
        data: {
            columns: [
                { label: '#', field: '#', sort: 'asc' },
                { label: 'User Name', field: 'Name', sort: 'asc' },
                { label: 'User Email', field: 'Email', sort: 'asc' },
                { label: 'User Phone', field: 'Phone', sort: 'asc' },
                { label: 'User Role', field: 'Role', sort: 'asc' },
                { label: 'Action', field: 'Action', sort: 'disabled' }
            ],
            rows: []
        },
    }
    componentDidMount() {
        this.props.userList();
        this.setState({ 
            loader: true
        })
    }
    componentWillUnmount() {
        this.props.initialState();
    }
    confirm = (event, id, name) => {
        this.setState({
            open: true,
            id: id,
        })
    }
    toggle = () => {
        this.setState({
            open: false,
        })
    }
    confirmation = (event, id) => {
        this.setState({
            open: false
        })
        this.props.deleteUser(id)
    }
    componentWillReceiveProps(newProps) {
        const { signup, deleteUserRole } = newProps
        if (deleteUserRole) {
            if (deleteUserRole.success) {
                if (toast.isActive(toastId)) {
                    toast.dismiss(toastId)
                    toastId = toast.success(deleteUserRole.message);
                    newProps.initialState();
                } else {
                    toastId = toast.success(deleteUserRole.message);
                    newProps.initialState();
                }
            }
            if (deleteUserRole.success === false) {
                if (toast.isActive(toastId)) {
                    toast.dismiss(toastId)
                    toastId = toast.success(deleteUserRole.message);
                    newProps.initialState();
                } else {
                    toastId = toast.success(deleteUserRole.message);
                    newProps.initialState();
                }
            }
        }
        if (signup.success) {
            var testData = signup && signup.user && signup.user.map((items, key) => {
                const userEmp = items.useremployees[0]
                let fname = userEmp && userEmp.firstName
                let lname = userEmp && userEmp.lastName
                return (
                    {
                        '#': key + 1,
                        // 'User Name': items.userName,
                        'Name': items.userName,
                        'Email': items.email,
                        'Phone': userEmp && userEmp.phone,
                        'Role': items.userroles && items.userroles.name,
                        'Action': [<Button className="action_btn text-black" outline color="" tag={Link} to={`/userDetail/${items.id}`}><MDBIcon title="View" far icon="eye" /></Button>,
                        <Button className="action_btn text-black" outline color="" tag={Link} to={`/userEdit/${items.id}`}><MDBIcon far icon="edit" title="Edit" /></Button>,
                        <>{items.userroles && items.userroles.slug !== 'super-admin' ? <Button className="action_btn text-black" outline color="" onClick={e => this.confirm(e, items.id)}><MDBIcon far icon="trash-alt" title="Delete" /></Button> : <Button disabled style={{ boxShadow: 'none' }}></Button>}</>]
                    }
                )
            })
            this.setState({
                data: {
                    ...this.state.data,
                    rows: testData
                },
                loader: false
            })
        }
        if (signup.success === false) {
            this.setState({
                loader: false
            })
        }
    }
    render() {
        const { open, id, loader } = this.state
        return (
            <>
                <ToastContainer autoClose={10000} />
                {
                    !loader ?
                        <>
                            <Header />
                            <Container className="mt-0" fluid>
                                <Row>
                                    <UserTable {...this.state} />
                                    <ConfirmModal open={open} toggle={this.toggle} confirmation={this.confirmation} id={id} />
                                </Row>
                            </Container>
                        </>
                        :
                        <div className="loader">
                            <Spinner variant="light" />
                            <p style={{ color: '#fff', paddingTop: '15px' }}>Please wait......</p>
                        </div>
                }
            </>
        )
    }
}
const mapDispatchState = (dispatch) => {
    return bindActionCreators({
        userList,
        deleteUser,
        initialState
    }, dispatch)
}
const mapGetStateNew = (state) => {
    return {
        signup: state.user.signupList,
        deleteUserRole: state.user.deleteUser
    }
}
export default connect(mapGetStateNew, mapDispatchState)(UserList);