import React from 'react';
import {
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";
import InlineError from '../../sharedFile/inlineError'
import { imagerUrl } from '../../config/config';
import Required from '../../sharedFile/required';
function editForm(props) {
  const { data, errors, loader, imagesPreviewUrls } = props
  return (
    <Container className="mt-0" fluid>
      <Row>
        <Col className="order-xl-1" xl="12">
          <Row className="align-items-center mobile_sec_col">
            <div className="col">
              <h2 className="mb-0 text-center">Edit Brand</h2>
              <span className="italic-message">* - indicate required fields!!</span>
            </div>
          </Row>
          <Form onSubmit={props.onSubmit}>
            <div className="pl-lg-0">
              <FormGroup>
                <Col>
                  <FormGroup error={!errors.name}>
                    <label
                      className="form-control-label"
                      htmlFor="input-name"
                    >
                      Brand Name<Required />
                    </label>
                    <Input
                      className="form-control-alternative"
                      defaultValue={data.name}
                      onChange={props.onChange}
                      id="input-name"
                      placeholder="Name"
                      maxLength='30'
                      name="name"
                      type="text"
                    />
                    {errors.name && <InlineError text={errors.name} />}
                  </FormGroup>
                </Col>
              </FormGroup>
              {
                imagesPreviewUrls.length > 0 || data.logo
                  ?
                  <FormGroup>
                    <Col error={!errors.picture}>
                      <label
                        className="form-control-label"
                      >Brand Logo:</label>
                      <CardBody className="pl-0 pr-0 pb-0">
                        <FormGroup>
                          <Col lg="6" className="position-relative pl-0 pr-0" style={{ maxWidth: '300px' }}>
                            {
                              imagesPreviewUrls.length > 0
                                ?
                                imagesPreviewUrls.map((items, key) => {
                                  return <div key={key}>
                                    <img src={items} height="150" width="95%" alt="brand" style={{ objectFit: 'cover', border: '1px solid #e1e1e1', borderRadius: '6px' }} ></img>
                                    <label onClick={props.onRemove} className="position-absolute remove icon-file"><i class="fas fa-times"></i></label>
                                  </div>
                                })
                                : data.logo ?
                                  <>
                                    <img src={`${imagerUrl}` + data.logo} height="150" width="95%" alt="brand" style={{ objectFit: 'cover', border: '1px solid #e1e1e1', borderRadius: '6px' }} ></img>
                                    <label onClick={props.onRemove} className="position-absolute remove icon-file"><i class="fas fa-times"></i></label>
                                  </>
                                  :
                                  ''
                            }

                          </Col>
                        </FormGroup>
                      </CardBody>
                      {errors.picture && <InlineError text={errors.picture} />}
                    </Col>
                  </FormGroup>
                  :
                  ''
              }
                  <FormGroup>
                    <Col className="upload_sheet">
                      <FormGroup className="addBrandList" style={{ width: '100%', position: 'relative' }}>
                        <label
                          className="form-control-label custom_label_input cursor-pointer"
                          htmlFor="input-image"
                          for="exampleFile"
                        >
                          <i class="fa fa-image" aria-hidden="true"></i> {
                            data.logo ? 'Change Logo' : 'Add Logo'
                          }
                        </label>
                        <Input type="file"
                          className="form-control-alternative"
                          placeholder="Name" name="picture" id="exampleFile" accept="image/gif,image/jpeg,image/png" onChange={props.onFileChange} />
                      </FormGroup>
                    </Col>
                  </FormGroup>
              <FormGroup>
                <Col>
                  <FormGroup error={!errors.description}>
                    <label
                      className="form-control-label"
                      htmlFor="input-description"
                    >
                      Brand Description<Required />
                    </label>
                    <Input
                      className="form-control-alternative"
                      value={data.description}
                      onChange={props.onChange}
                      name="description"
                      id="input-description"
                      placeholder="Description"
                      type="textarea"
                    />
                    {errors.description && <InlineError text={errors.description} />}
                  </FormGroup>
                </Col>

              </FormGroup>
            </div>
            <Col className="custom-submit">
              <Button type="submit" className="my-4 submit-button" disabled={loader ? true : false} color="primary">
                {
                  loader
                    ?
                    <Spinner color="light" />
                    :
                    'Submit'
                }

              </Button>
            </Col>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}
export default editForm;