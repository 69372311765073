import React, { Component } from "react";
import Header from "../../../components/Headers/Header.jsx";
import { getBrandOrder, mainorderGroup, mainZipper, mainZipperAdd, initialState, noteinitialState } from '../../../services/actions/order';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { imagerUrl } from '../../../config/config';
import { varientBySlug, varienInitial } from '../../../services/actions/varient'
import {
  Card,
  CardHeader,
  Row,
  Container,
  Spinner,
  NavItem,
  Nav,
  NavLink,
  Button
} from "reactstrap";
import EditTable from '../../../components/EditTable'
import { Link } from 'react-router-dom'
import { zipperPo } from '../../../services/actions/po'
import { ToastContainer, toast } from 'react-toastify';
import { zipperSupplier } from '../../../services/actions/supplier'
import PreviewModal from "../../../components/previewModal";
import dataJson from '../../../sharedFile/allData'
import AddZipperRow from "../../../components/addZipperRow.js";
import AddSameZipperData from "../../../components/addSameZipperData.jsx";
import moment from 'moment'
var toastId = null

class Mainzippersheet extends React.Component {
  state = {
    data: {
      columns: [
      ],
      rows: []
    },
    brand: '',
    brandImg: '',
    brandOrderId: this.props.match.params.id,
    suppliers: [],
    material: [],
    poSucc: null,
    edit: [],
    loaderPo: false,
    loader: false,
    loaderVarient: true,
    loaderTable: true,
    errors: {},
    rows: [],
    orderIdPo: '',
    name: '',
    page: 0,
    pageNumber: 1,
    search: '',
    varient: [],
    dataForm: {
      brandId: '',
      orderId: ''
    },
    previewData: {
      columns: [],
      rows: []
    },
    previewDataId: null,
    open: false,
    supllierDetail: [],
    selectPoList: {
      id: []
    },
    total: 0,
    noteGetRow:'',
    addRow: false,
    addSame: false,
    addRowData: {
      brandOrderId: null,
      orderDescId: null,
    },
    addSameData: {
      brandOrderId: null,
      orderDescId: null,
      useArea: null,
      zipperUse: null,
      addQTY: null,
      specification: null,
      supplier: null,
      deliveryDate: null,
      pageNumber: null
    },
    poSupplierDetail: [],
    poStatus: '',
    pageHead: '',
    slug: this.props.match.params.item_slug
  }
  componentDidMount() {
    const { brandOrderId, pageNumber, search, slug } = this.state
    const dataBrand = {
      brandOrderId, pageNumber
    }
    this.props.varientBySlug(slug)
    this.props.zipperSupplier()
    this.props.getBrandOrder(dataBrand);
    this.setState({
      loaderVarient: true,
      loaderTable: true,
    })
  }
  onKeyPress(evt) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }
  valueEdit = (rowId, key, rows, value, suppliers) => {
    const { varient } = this.state
    let currentDate = new Date();
    let getYear = currentDate.getFullYear()
    let getMonth = currentDate.getMonth() + 1
    let fullCurrentDate = getYear + '-' + (getMonth <= 9 ? '0' + getMonth : getMonth) + '-' + (currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate())
    if (key === rowId) {
      if (sessionStorage.userROLE === 'super-admin' || rows[rowId]['poStatus'] === 'Pending') {
        varient.map(items => {
          if (items.assignedVarient === '') {
            value[items.field] = [<input type="text" name={items.name} value={rows[key][items.name]} key={items.name + key} onChange={e => (this.onChange(e, key))} />]
          } else {
            let values = items.assignedVarient.split(',')
            value[items.field] = [<select name={items.name} key={'ah' + key} defaultValue={rows[key][items.name]} onChange={e => (this.onChange(e, key))}>
              <option value={null}>{items.name}</option>
              {values.map(itemValue => {
                return <option value={itemValue}>{itemValue}</option>
              })}
            </select>]
          }
        })
        value['Action'] = [<i className="fas fa-check pr-2 cursor-pointer" onClick={e => (this.onSubmit(e, key))} key={'abc' + key}></i>]
        value['Sr No'] = key + 1
        value['Style No'] = value['Style No']
        value['Style Code'] = value['Style Code']
        value['Size'] = value['Size']
        value['order qtty'] = value['order qtty']
        value['add qty %'] = [<select name="addQty" className="inputFiled" value={rows[key]['addQty']} onChange={e => (this.onChange(e, key))}>
          <option>Qty</option>
          {
            dataJson.addQuantity.map(items => {
              return <option value={items}>{items + '%'}</option>
            })
          }
        </select>]
        value['Zipper Use Area'] = [<select name="zipperArea" className="inputFiled" value={rows[key]['zipperArea']} onChange={e => (this.onChange(e, key))}>
          <option>Zipper Area</option>
          {
            dataJson.zipperArea.map(items => {
              return <option value={items}>{items}</option>
            })
          }
        </select>]
        value['No. of Zipper Use'] = [<input className="inputFiled" onKeyPress={this.onKeyPress.bind(this)} type="text" name="zipperUse" value={rows[key]['zipperUse']} onChange={e => (this.onChange(e, key))} />]
        value['Final Zipper Qtty'] = value['Final Zipper Qtty']
        value['Zipper specification'] = [<input className="inputFiled" type="text" name="specification" value={rows[key]['specification']} onChange={e => (this.onChange(e, key))} />]
        value['Garment Length'] = [<input className="inputFiled" onKeyPress={this.onKeyPress.bind(this)} type="text" name="garmentLength" value={rows[key]['garmentLength']} onChange={e => (this.onChange(e, key))} />]
        value['Garment Front Neck Drop'] = [<input className="inputFiled" onKeyPress={this.onKeyPress.bind(this)} type="text" name="frontNeckDrop" value={rows[key]['frontNeckDrop']} onChange={e => (this.onChange(e, key))} />]
        value['Collar Point'] = [<input type="text" className="inputFiled" onKeyPress={this.onKeyPress.bind(this)} name="collarPoint" value={rows[key]['collarPoint']} onChange={e => (this.onChange(e, key))} />]
        value['Main Zipper Length Needed'] = value['Main Zipper Length Needed']
        value['Round Off final length'] = value['Round Off final length']
        value['Supplier'] = [<select name="supplier" className="selectList inputFiled" value={rows[key]['supplier']} onChange={e => (this.onChange(e, key))}>
          <option>Supplier</option>
          {
            suppliers.map((value, key) => {
              return <option value={value.id} key={key}>{value.companyName}</option>
            })
          }
        </select>]
        value['Price'] = [<input maxLength="8" onKeyPress={this.onKeyPress.bind(this)} className="inputFiled" type="text" name="price" value={rows[key]['price']} onChange={e => (this.onChange(e, key))} />]
        value['Delivery'] = [<input min={fullCurrentDate} type="date" className="inputFiled" name="deliveryDate" value={rows[key]['deliveryDate']} onChange={e => (this.onChange(e, key))} />]
        value['Remarks'] = [<input type="text" name="Remarks" className="inputFiled" value={rows[key]['Remarks']} onChange={e => (this.onChange(e, key))} />]
        value['po status'] = rows[key]["poStatus"]
        value['recd status'] = [<input className="inputFiled" onKeyPress={this.onKeyPress.bind(this)} type="text" name="recdStatus" value={rows[key]['recdStatus']} onChange={e => (this.onChange(e, key))} />]
        value['pending status'] = rows[key]["pendingStatus"]
      }
      else {
        value['Action'] = [<i className="fas fa-check pr-2 cursor-pointer" onClick={e => (this.onSubmit(e, key))} key={'abc' + key}></i>]
        value['recd status'] = [<input className="inputFiled" type="text" onKeyPress={this.onKeyPress.bind(this)} name="recdStatus" value={rows[key]['recdStatus']} onChange={e => (this.onChange(e, key))} />]
        value['pending status'] = rows[key]["pendingStatus"]
      }
    } else {
      value['Action'] = [<div className="d-flex"><i title="Edit" className="disabledAction fas fa-pen pr-2" key={'abc' + key}></i><i title="Add Row" className="disabledAction fas fa-plus-circle " key={'abce' + key}></i><i title="Bulk Edit" className="fas fa-cogs disabledAction" key={'abcSa' + key}></i><i key={'abcr' + key} title="View" className="disabledAction fas fa-eye " ></i><input style={{ minWidth: '15px', marginLeft: '5px' }} type="checkbox" key={key} value={value.id} name="poGenrate" disabled /></div>]
    }
  }
  valueUpdate = (rows, rowId, value, key) => {
    const { varient } = this.state
    if (key == rowId) {
      varient.map(items => {
        value[items.field] = rows[rowId][items.name]
      })
      let poSucc = rows[key]['useArea'] != '' && rows[key]['totalNeed'] != '' && rows[key]['specification'] != '' && rows[key]['garmentLength'] != '' && rows[key]['frontNeckDrop'] != '' && rows[key]['collarPoint'] != '' && rows[key]['totalNeed'] != '' && rows[key]['finalNeed'] != '' && rows[key]['zipperSupllier'] != '' && rows[key]['deliveryDate'] != null && rows[key]['addQty'] != null ? false : true
      value['Action'] = [<div style={{ display: 'flex' }}>{(sessionStorage.userROLE == 'super-admin' || value['po status'] === 'Pending') ? <><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i><i title="Add Row" className="fas fa-plus-circle cursor-pointer" key={'abce' + key} onClick={e => (this.onAdd(e, key))}></i><i title="Bulk Edit" className="fas fa-cogs cursor-pointer" onClick={e => (this.changeSameValue(e, key))} key={'abcSa' + key}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, rows[rowId]['orderid'], poSucc))} ></i><input style={{ minWidth: '15px', marginLeft: '5px' }} type="checkbox" value={value.id} name="poGenrate" disabled={poSucc} key={key} onChange={e => (this.listPoGenrate(e, key))} /></> : <><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i><i title="Add Row" className="fas fa-plus-circle cursor-pointer" key={'abce' + key} onClick={e => (this.onAdd(e, key))}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, rows[rowId]['orderid'], poSucc))} ></i></>}</div>]
      value['Sr No'] = key + 1
      value['Style No'] = value['Style No']
      value['Size'] = value['Size']
      value['order qtty'] = value['order qtty']
      value['add qty %'] = rows[rowId]['addQty']
      value['Zipper Use Area'] = rows[rowId]['zipperArea']
      value['No. of Zipper Use'] = rows[rowId]['zipperUse']
      value['Final Zipper Qtty'] = value['Final Zipper Qtty']
      value['Zipper specification'] = rows[rowId]['specification']
      value['Garment Length'] = rows[rowId]['garmentLength']
      value['Garment Front Neck Drop'] = rows[rowId]['frontNeckDrop']
      value['Collar Point'] = rows[rowId]['collarPoint']
      value['Main Zipper Length Needed'] = value['Main Zipper Length Needed']
      value['Round Off final length'] = value['Round Off final length']
      value['Supplier'] = rows[rowId]['supplier']
      value['Price'] = rows[rowId]['price']
      value['Delivery'] = rows[rowId]['deliveryDate']
      value['Remarks'] = rows[rowId]['Remarks']
      value['po status'] = rows[rowId]['poStatus']
      value['recd status'] = rows[rowId]['recdStatus']
      value['pending status'] = rows[rowId]['pendingStatus']
    }
  }
  onSubmit = (event, rowId) => {
    let { rows, data, brandOrderId, pageNumber, slug, varient, search } = this.state
    var row = data.rows.map((value, key) => {
      this.valueUpdate(rows, rowId, value, key)
      return value;
    })
    this.setState({
      data: {
        ...this.state.data,
        rows: row
      }
    })
    let dataSubmit = []
    rows.map((val, key) => {
      if (key === rowId) {
        let datainner = []
        datainner['zippervarients'] = []
        varient.map((items, i) => {
          datainner['zippervarients'].push({ id: items.id, value: val[items.name] !== '' ? val[items.name] : null })
          return true;
        })
        dataSubmit.push({
          "orderid": val.orderid,
          "brandOrderId": val.brandOrderId,
          'orderDescId': val.orderDescId,
          'useArea': val.zipperArea != null ? val.zipperArea : '',
          'zipperNeed': val.zipperUse !== null && val.zipperUse !== '' ? val.zipperUse : null,
          "addQTY": val.addQty !== '' ? val.addQty : null,
          "specification": val.specification !== '' ? val.specification : null,
          "garmentLength": val.garmentLength !== '' ? val.garmentLength : null,
          "frontNeckDrop": val.frontNeckDrop !== '' ? val.frontNeckDrop : null,
          "collarPoint": val.collarPoint !== '' ? val.collarPoint : null,
          "totalNeed": val.lengthNeeded != '' ? val.lengthNeeded : null,
          "finalNeed": val.roundOffNeed != '' ? val.roundOffNeed : null,
          "supplierId": val.supplier != '' ? val.supplier : null,
          "price": val.price != '' ? val.price : null,
          "deliveryDate": val.deliveryDate != '' ? val.deliveryDate : null,
          "remarks": val.Remarks != '' ? val.Remarks : null,
          ...datainner,
          "recdStatus": val.recdStatus != '' ? val.recdStatus : null,
          "pendingStatus": val.pendingStatus != '' ? val.pendingStatus : null,
        })
      }
    })
    this.props.mainZipperAdd({ brandOrderId, pageNumber, data: dataSubmit, slug,search })
    // this.setState({
    //   loaderTable: true
    // })
  }
  onChange = (e, rowId) => {
    let { rows, data, suppliers } = this.state
    let dataRows = rows.map((value, key) => {
      if (key == rowId) {
        value[e.target.name] = e.target.value
        if (e.target.name == 'zipperUse' || e.target.name == 'addQty') {
          let totalOrder = Math.ceil(data.rows[key]['order qtty'] + ((value['addQty'] / 100) * data.rows[key]['order qtty']))
          data.rows[key]['Final Zipper Qtty'] = totalOrder * value['zipperUse']
        }
        var garmentFirst
        if (e.target.name == 'collarPoint' || e.target.name == 'garmentLength' || e.target.name == 'frontNeckDrop') {
          garmentFirst = (value['garmentLength'] ? value['garmentLength'] : 0) - (value['frontNeckDrop'] ? value['frontNeckDrop'] : 0)
          value['lengthNeeded'] = ((value['collarPoint'] ? parseFloat(value['collarPoint']) : 0) + garmentFirst).toFixed(2)
          data.rows[key]['Main Zipper Length Needed'] = ((value['collarPoint'] ? parseFloat(value['collarPoint']) : 0) + garmentFirst).toFixed(2)
          let lengthNeedValue = value['lengthNeeded'].toString().split(".")
          if((lengthNeedValue&&lengthNeedValue[1]) <= 49){
            value['roundOffNeed'] = Math.round(value['lengthNeeded'])
            data.rows[key]['Round Off final length'] = Math.round(value['lengthNeeded'])
          }else if ((lengthNeedValue&&lengthNeedValue[1]) >= 50){
            value['roundOffNeed'] = lengthNeedValue[0]+'.5'
            data.rows[key]['Round Off final length'] = lengthNeedValue[0]+'.5'
          }else{
            value['roundOffNeed'] = value['lengthNeeded']
            data.rows[key]['Round Off final length'] = value['lengthNeeded']
          }
        }
        if (e.target.name === 'recdStatus') {
          value['pendingStatus'] = (value['roundOffNeed'] - value['recdStatus'])
        }
      }
      return value
    })
    var row = data.rows.map((value, key) => {
      this.valueEdit(rowId, key, rows, value, suppliers)
      return value;
    })
    this.setState({
      data: {
        ...this.state.data,
        rows: row
      },
      rows: dataRows
    })
  }
  onEdit = (event, rowId) => {
    let { rows, data, suppliers } = this.state
    var row = data.rows.map((value, key) => {
      this.valueEdit(rowId, key, rows, value, suppliers)
      return value;
    })
    var rowList = rows.map((val, key) => {
      if (key === rowId) {
        val['updateClass'] = 'editRow'
      }
      return val
    })
    this.setState({
      data: {
        ...this.state.data,
        rows: row
      },
      rows: rowList
    })
  }
  onAdd = (e, rowId) => {
    const { rows } = this.state
    var abc = {}
    rows.map((value, key) => {
      if (key === rowId) {
        abc.orderDescId = value.orderDescId
        abc.brandOrderId = value.brandOrderId
      }
      return true;
    })
    this.setState({
      addRow: true,
      addRowData: abc
    })
  }
  changeSameValue = (e, rowId) => {
    const { rows, pageNumber } = this.state
    var abc = {}
    rows.map((value, key) => {
      if (key === rowId) {
        abc.orderid = value.orderid
        abc.brandOrderId = value.brandOrderId
        abc.useArea = value.zipperArea
        abc.zipperNeed = value.zipperUse.toString()
        abc.addQTY = value.addQty
        abc.specification = value.specification
        abc.supplierId = value.supplier
        abc.deliveryDate = value.deliveryDate
        abc.pageNumber = pageNumber
      }
      return true;
    })
    this.setState({
      addSame: true,
      addSameData: abc
    })
  }
  componentWillReceiveProps(newProps) {
    const { varientList, mainZipperList, supplierSuccess, brandorder, poGenrateSuccess, noteSuccess } = newProps
    const { brandOrderId, pageNumber, search, slug } = this.state
    var varient = []
    if (varientList.success) {
      varientList && varientList.varient && varientList.varient.map((value, key) => {
        let assignedVarient = value.assignedVarient && value.assignedVarient[0].value
        varient.push({ label: value.name, field: value.name, name: value.slug, id: value.id, assignedVarient: assignedVarient })
      })
        this.setState({
          loaderVarient: false,
          loaderTable: true,
          data: {
            ...this.state.data,
            columns: [
              { label: "Action", field: "Action" },
              { label: "#", field: "Sr No" },
              { label: 'Style No', field: 'Style No' },
              { label: 'Style Code', field: 'Style Code' },
              { label: "Size", field: "Size", type: "numeric" },
              { label: "Order Qty", field: "order qtty", type: "numeric" },
              { label: "Add Qty %", field: "add qty %", type: "numeric", class: 'colum_width' },
              { label: "Zipper Use Area", field: "Zipper Use Area", type: "numeric" },
              { label: 'No. of Zipper Use', field: 'No. of Zipper Use' },
              { label: 'Final Zipper Qty', field: 'Final Zipper Qtty' },
              { label: 'Zipper Specification', field: 'Zipper specification' },
              { label: 'Garment Length', field: 'Garment Length' },
              { label: 'Garment Front Neck Drop', field: 'Garment Front Neck Drop' },
              { label: 'Collar Point', field: 'Collar Point' },
              { label: 'Main Zipper Length Needed', field: 'Main Zipper Length Needed' },
              { label: 'Round Off Final length', field: 'Round Off final length' },
              { label: 'Supplier', field: 'Supplier', class: 'selectList' },
              ...varient,
              { label: 'Price', field: 'Price' },
              { label: "Delivery", field: "Delivery" },
              { label: 'Remarks', field: 'Remarks' },
              { label: 'PO Status', field: 'po status' },
              { label: 'Recd Status', field: 'recd status', class: 'avgWidth' },
              { label: 'Pending Status', field: 'pending status', class: 'avgWidth' }
            ]
          },
          varient: varient
        })
        const data = {
          slug, brandOrderId, pageNumber, search
        }
        newProps.varienInitial()
        newProps.mainZipper(data);
    }
    if (noteSuccess && noteSuccess.success === true) {
      newProps.mainZipper({ slug, brandOrderId, pageNumber, search })
      this.setState({
        open: false,
        loaderTable: true,
      })
    }
    if (brandorder && brandorder.success) {
      let brand = brandorder.order && brandorder.order.brandsId
      this.setState({
        ...this.state,
        brand: brand && brand.name,
        brandImg: brand && brand.logo,
        name: brandorder.order && brandorder.order.name
      })
    }
    if (poGenrateSuccess && poGenrateSuccess.success) {
      if (poGenrateSuccess.message) {
        if (!toast.isActive(toastId))
          toastId = toast.success(poGenrateSuccess.message)
      }
      this.setState({
        loaderPo: false,
        selectPoList: { id: [] }
      })
      this.props.mainZipper({ brandOrderId, pageNumber, search, slug });
      this.props.initialState();
    } if (poGenrateSuccess.success === false) {
      if (poGenrateSuccess.message) {
        if (!toast.isActive(toastId))
          toastId = toast.error(poGenrateSuccess.message)
      }
      this.setState({
        loaderPo: false
      })
      this.props.initialState();
    }
    if (mainZipperList && mainZipperList.success) {
      let zipperOrder = mainZipperList && mainZipperList.order
      let data = zipperOrder.map((value, key) => {
        let datainner = {}
        this.state.varient.map((items, i) => {
          datainner[items.field] = ''
          value.zippervarients && value.zippervarients.map(val => {
            if (val.varientId == items.id) {
              if(items.name === 'color'){
                datainner[items.field] = [<span className="tooltip1" key={'abcd' + key}>{val.value !== null && val.value ? (val.value.length > 5 ? val.value.substr(0, 5) : val.value) : val.value}<span className="tooltiptext">{val.value}</span></span>]
              }else{
                datainner[items.field] = val.value != null ? val.value : ''
              }
            }
          })
          return true;
        })
        let totalOrder = Math.ceil(value.zipperDesc[value.size] + (((value.addQTY !== null ? value.addQTY : 0) / 100) * value.zipperDesc[value.size]))
        let poSucc = value.useArea != null && value.zipperNeed != null && value.specification != null && value.garmentLength != null && value.frontNeckDrop != null && value.collarPoint != null && value.zipperNeed != null && value.finalNeed != null && value.zipperSupllier != null && value.deliveryDate != null && value.addQTY != null ? false : true
        return ({
          'Action': [<div style={{ display: 'flex' }}>{sessionStorage.userROLE == 'super-admin' || value.poStatus === 'Pending' || value.poStatus === null ? <><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i><i title="Add Row" className="fas fa-plus-circle cursor-pointer" key={'abce' + key} onClick={e => (this.onAdd(e, key))}></i><i title="Bulk Edit" className="fas fa-cogs cursor-pointer" onClick={e => (this.changeSameValue(e, key))} key={'abcSa' + key}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, value.id, poSucc))} ></i><input style={{ minWidth: '15px', marginLeft: '5px' }} type="checkbox" value={value.id} name="poGenrate" disabled={poSucc} key={key} onChange={e => (this.listPoGenrate(e, key))} title={poSucc === true ? 'Fill the row to generate PO.' : ''} /></> : <><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i><i title="Add Row" className="fas fa-plus-circle cursor-pointer" key={'abce' + key} onClick={e => (this.onAdd(e, key))}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, value.id, poSucc))} ></i></>}</div>],
          'Sr No': ((this.state.pageNumber - 1) * 10) + (key + 1),
          'Style No': value.zipperDesc.styleno,
          'Style Code': [<span className="tooltip1" key={'abcdZ' + key}>{value.zipperDesc.stylecode !== null ? (value.zipperDesc.stylecode.length > 5 ? value.zipperDesc.stylecode.substr(value.zipperDesc.stylecode.length - 5) : value.zipperDesc.stylecode) : value.zipperDesc.stylecode}<span className="tooltiptext">{value.zipperDesc.stylecode}</span></span>],
          'Size': value.size,
          'order qtty': value.zipperDesc[value.size],
          'add qty %': value.addQTY !== null ? value.addQTY : '',
          'Zipper Use Area': value.useArea !== "" ? value.useArea : 'Main',
          'No. of Zipper Use': value.zipperNeed != null ? value.zipperNeed : '',
          'Final Zipper Qtty': value.zipperNeed != null ? totalOrder * value.zipperNeed : '',
          'Zipper specification': [<span className="tooltip1" key={'abcdeZ' + key} >{(value.specification) ? (value.specification.length > 5 ? value.specification.substr(0, 5) : value.specification) : ''}<span className="tooltiptext">{value.specification ? value.specification : ''}</span></span>],
          'Garment Length': value.garmentLength != null ? value.garmentLength : '',
          'Garment Front Neck Drop': value.frontNeckDrop != null ? value.frontNeckDrop : '',
          'Collar Point': value.collarPoint != null ? value.collarPoint : '',
          'Main Zipper Length Needed': value.totalNeed != null ? value.totalNeed : '',
          'Round Off final length': value.finalNeed != null ? value.finalNeed : 0,
          'Supplier': [<span className="tooltip1" key={'abcdeZ' + key} >{(value.zipperSupllier && value.zipperSupllier.id) ? (value.zipperSupllier.companyName.length > 5 ? value.zipperSupllier.companyName.substr(0, 5) : value.zipperSupllier.companyName) : ''}<span className="tooltiptext">{value.zipperSupllier && value.zipperSupllier.companyName ? value.zipperSupllier.companyName : ''}</span></span>],
          ...datainner,
          'Price': value.price != null ? value.price : '',
          'Delivery': value.deliveryDate != null ? moment(value.deliveryDate).format('DD/MM/YY') : '',
          'Remarks': [<span className="tooltip1" key={'abcdeZ' + key} >{(value.remarks) ? (value.remarks.length > 5 ? value.remarks.substr(0, 5) : value.remarks) : ''}<span className="tooltiptext">{value.remarks ? value.remarks : ''}</span></span>],
          'po status': value.poStatus != null ? <>{value.poStatus === 'Generated' || value.poStatus === 'Regenerated' ? <i class="fas fa-check" title={value.poStatus} style={{ color: '#4dba4f' }}></i> : <><i style={{ color: '#f15d5d' }} class="far fa-clock" title='Pending'></i></>}</> : <><i style={{ color: '#f15d5d' }} class="far fa-clock" title='Pending'></i></>,
          'recd status': value.recdStatus != null ? value.recdStatus : '',
          'pending status': value.pendingStatus != null ? value.pendingStatus : ''
        })
      })
      let supllierDetail = []
      var data1 = zipperOrder.map((value, key) => {
        let datainner = {}
        supllierDetail.push(value.zipperSupllier)
        this.state.varient.map((items, i) => {
          datainner[items.name] = ''
          value.zippervarients && value.zippervarients.map(val => {
            if (val.varientId == items.id) {
              datainner[items.name] = val.value != '' ? val.value : ''
            }
          })
          return true;
        })
        return ({
          'zipperArea': value.useArea != null || value.useArea != '' ? value.useArea : 'Main',
          'zipperUse': value.zipperNeed != null ? value.zipperNeed : '',
          'orderid': value.id,
          'orderDescId': value.orderDescId,
          'brandOrderId': value.brandOrderId,
          'addQty': value.addQTY != null ? value.addQTY : '',
          'specification': value.specification != null ? value.specification : '',
          'garmentLength': value.garmentLength != null ? value.garmentLength : '',
          'frontNeckDrop': value.frontNeckDrop != null ? value.frontNeckDrop : '',
          'collarPoint': value.collarPoint != null ? value.collarPoint : '',
          'lengthNeeded': value.totalNeed != null ? value.totalNeed : '',
          'roundOffNeed': value.finalNeed != null ? value.finalNeed : '',
          'supplier': value.supplierId != null ? value.supplierId : '',
          ...datainner,
          'price': value.price != null ? value.price : '',
          'deliveryDate': value.deliveryDate != null ? value.deliveryDate : '',
          'Remarks': value.remarks != null ? value.remarks : '',
          'poStatus': value.poStatus != null ? value.poStatus : 'Pending',
          'recdStatus': value.recdStatus != null ? value.recdStatus : '',
          'pendingStatus': value.pendingStatus != null ? value.pendingStatus : '',
          note: value.notes != null ? value.notes : ''
        })
      })
      this.setState({
        data: {
          ...this.state.data,
          rows: data
        },
        rows: data1,
        total: mainZipperList.page,
        supllierDetail: supllierDetail,
        loaderTable: false,
        pageHead: mainZipperList.cat
      })
    } else if (mainZipperList && mainZipperList.error) {
      this.setState({
        loaderTable: false
      })
    } else {
      if(mainZipperList && mainZipperList.success === false){
        this.setState({
          loaderTable: false
        })
      }
    }
    if (supplierSuccess && supplierSuccess.success) {
      this.setState({
        suppliers: supplierSuccess.suppliers,
      })
    }
  }
  searchChange = (e) => {
    const { brandOrderId, pageNumber, slug } = this.state
    this.setState({
      ...this.state,
      search: e.target.value
    })
    if (e.target.value !== '')
      this.props.mainZipper({ brandOrderId, pageNumber: 1, search: e.target.value, slug });
    else
      this.props.mainZipper({ brandOrderId, pageNumber: pageNumber, search: e.target.value, slug });
  }
  changePage = (e, pageNumber) => {
    const { brandOrderId, slug, search } = this.state
    this.setState({
      ...this.state,
      pageNumber: pageNumber,
      loaderTable: true,
      selectPoList: { id: [] }
    })
    this.props.mainZipper({ brandOrderId, pageNumber: pageNumber, search, slug });
  }
  preview = (e, rowId, id, poSucc) => {
    const { data, varient, supllierDetail, rows } = this.state
    let poTable = [{}]
    let poSupplierDetail = []
    let orderId, poStatus, noteGetRow
    supllierDetail.map((items, key) => {
      if (rowId == key) {
        poSupplierDetail = items
      }
    })
    rows.map((item, key) => {
      if (rowId == key) {
        orderId = rows[rowId]['orderid']
        poStatus = rows[rowId]['poStatus']
        noteGetRow = rows[rowId]['note']
      }
    })
    data.rows.map((value, key) => {
      if (rowId == key) {
        poTable[0]['Sr No'] = key + 1
        poTable[0]['Style Code'] = data.rows[key]['Style Code']
        poTable[0]['Round Off final length'] = data.rows[key]['Round Off final length']
        poTable[0]['Price'] = data.rows[key]['Price']
        varient.map(items => {
          poTable[0][items.field] = data.rows[key][items.field]
        })
        poTable[0]['Delivery'] = data.rows[key]['Delivery']
        poTable[0]['Remarks'] = data.rows[key]['Remarks']
      }
      return true;
    })
    if (poSupplierDetail !== null) {
      this.setState({
        previewData: {
          columns: [
            { label: "S.N.", field: "Sr No", class: 'colum_width' },
            { label: 'Style code', field: 'Style code', class: 'colum_width' },
            { label: 'Round Off final length', field: 'Round Off final length', class: 'colum_width' },
            { label: 'Price', field: 'Price', class: '' },
            ...varient,
            { label: "Delivery", field: "Delivery", class: '' },
            { label: 'Remarks', field: 'Remarks', class: '' },
          ],
          rows: poTable
        },
        previewDataId: id,
        orderIdPo: orderId,
        open: true,
        noteGetRow:noteGetRow,
        poSupplierDetail: poSupplierDetail,
        poStatus: poStatus,
        poSucc: poSucc
      })
    }
  }
  previewClose = () => {
    this.setState({
      open: false,
    })
    this.props.noteinitialState()
  }
  AddRowClose = () => {
    this.setState({
      addRow: false,
      addSame: false,
    })
  }
  poGenerate = (e, rowId) => {
    const { selectPoList, varient, rows } = this.state
    this.props.zipperPo(selectPoList)
    this.setState({
      loaderPo: true,
      loaderTable: true
    })
  }
  POSingle = (e, rowId) => {
    let selectPoList = {
      id: [rowId.toString(), null]
    }
    this.setState({
      ...this.state,
      loaderPo: true,
      loaderTable: true,
      open: false
    })
    this.props.zipperPo(selectPoList)
  }
  listPoGenrate = (e, key) => {
    const { selectPoList } = this.state
    let index;
    if (e.target.checked) {
      this.setState({
        selectPoList: { ...selectPoList, id: [...selectPoList.id, e.target.value] }
      })
    } else {
      index = selectPoList.id.indexOf(e.target.value)
      selectPoList.id.splice(index, 1)
      this.setState({
        selectPoList: { ...selectPoList, id: [...selectPoList.id] }
      })
    }
  }
  componentWillUnmount() {
    this.props.initialState();
  }
  updateRowList = () =>{
    this.setState({
      loaderTable:true
    })
  }
  render() {
    const { brand, addSame, addRowData, pageHead, addRow, brandImg, previewData, open, suppliers, previewDataId, poSupplierDetail, loaderPo, name, loaderTable, loaderVarient, slug, selectPoList, orderIdPo, addSameData, poStatus, poSucc, noteGetRow, search } = this.state
    return (
      <>
        <ToastContainer autoClose={10000} />
        {
          loaderTable || loaderVarient ?
          <div className="loader">
              <Spinner variant="light" />
              <p style={{ color: '#fff', paddingTop: '15px' }}>Please wait......</p>
            </div>
            :
            <>
              <Container className="mt-5" fluid>
                <Row>
                  <div className="col">
                    <Card className="border-0">
                      <CardHeader className="border-0 pt-0">
                        <h1 className="m-0 text-capitalize">{pageHead}</h1>
                        <Row className="align-items-center">
                          <div className="col-md-8 d-flex pl-0">
                            <div style={{ paddingBottom: '5px', marginRight: '25px' }}>
                              <h6 className="text-uppercase text-light ls-1 mb-1">
                                Brand
                          </h6>
                              <h3 className="mb-0 profile_sec text-capitalize">
                                {
                                  (brandImg !== '' && brandImg !== null) ?
                                    <img className="mr-3" src={`${imagerUrl}` + this.state.brandImg} width="40" alt="brand"></img>
                                    : <img className="mr-3" src={'/logo.png'} width="40" alt="brand"></img>
                                }
                                {brand}</h3>
                            </div>
                            <div style={{ paddingBottom: '5px' }}>
                              <h6 className="text-uppercase text-light ls-1 mb-1">
                                Order name
                              </h6>
                              <h2 className="mb-0">
                                {name}</h2>
                            </div>
                          </div>
                          <div className="col-md-4 text-right d-flex justify-contant-flex-end justify-contant-flex-xs-start pl-0">
                            <Button style={{ marginRight: '20px' }} type="submit" disabled={loaderPo === true || selectPoList.id == undefined || selectPoList.id[0] == null ? true : false} className="btn btn-primary" color="primary" onClick={this.poGenerate}>{
                              loaderPo === true ?
                                <Spinner /> :
                                'Generate PO'
                            }</Button>
                            <Nav className="justify-content-end" pills>
                              <NavItem>
                                <NavLink className="btn btn-primary " to={'/main_zipper/' + slug} tag={Link}  >Back</NavLink>
                              </NavItem>
                            </Nav>
                          </div>
                        </Row>
                      </CardHeader>
                      <div className="table-responsive">
                        <EditTable {...this.state} searchChange={this.searchChange} changePage={this.changePage} />
                      </div>
                    </Card>
                  </div>
                </Row>
                <PreviewModal previewData={previewData} toggle={this.previewClose} open={open} liningsupplier={suppliers} previewDataId={previewDataId} poSupplierDetail={poSupplierDetail} orderIdPo={orderIdPo} POSingle={this.POSingle} loaderPo={loaderPo} poStatus={poStatus} poSucc={poSucc} noteGetRow={noteGetRow}/>
                <AddZipperRow addRowData={addRowData} addRow={addRow} toggle={this.AddRowClose} />
                <AddSameZipperData addSameData={addSameData} suppliers={suppliers} addSame={addSame} toggle={this.AddRowClose} updateRowList={this.updateRowList} search={search}/>
              </Container>
            </>
        }
      </>
    )
  }
}
const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    zipperSupplier,
    getBrandOrder,
    mainorderGroup,
    mainZipper,
    mainZipperAdd,
    initialState,
    varientBySlug,
    zipperPo,
    noteinitialState,
    varienInitial
  }, dispatch)
}
const mapGetStateNew = (state) => {
  return {
    brandorder: state.order.brandorder,
    MainOrder: state.order.order,
    mainZipperList: state.order.orderZipper,
    varientList: state.varient.varient,
    supplierSuccess: state.supplier.supplier,
    poGenrateSuccess: state.poPDF.poGenrate,
    noteSuccess: state.order.note
  }
}
export default connect(mapGetStateNew, mapDispatchState)(Mainzippersheet);
