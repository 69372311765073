import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, useHistory, Switch, Route } from "react-router-dom";
import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";
import "./assets/vendor/@fortawesome/fontawesome-free/css/fontawesome.min.css";
import reduser from './services/reducers';
import rootSaga from './services/saga'
import 'react-toastify/dist/ReactToastify.css';
import {userLoggedin} from './services/actions/auth';
import App from "./app.jsx";
import {categoryRoute} from './services/actions/category'

const sagaMiddleWare = createSagaMiddleware();
const store = createStore(reduser, applyMiddleware(sagaMiddleWare));
sagaMiddleWare.run(rootSaga);
if (sessionStorage.userJWT) {
  let token = sessionStorage.userJWT
  let role = sessionStorage.userROLE
  store.dispatch(userLoggedin(token, role))
  store.dispatch(categoryRoute())
}

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <Route component={App}/>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);