import React from "react";
import {
    Container,
 } from 'reactstrap';
import DateOrder from "../dateByOrder/dateOrder";

class MainzipperForm extends React.Component {
  render() {
    let url=this.props.location.pathname
    return (
      <>
        <Container className="mt-0" fluid>
          <div className="card_border card">
            <DateOrder url={url}/>
          </div>
        </Container>
        </>
    );
  }
}
export default MainzipperForm;
  