import React from 'react';
import { Button, Modal,  ModalBody, ModalFooter } from 'reactstrap';

const ConfirmModal = (props) => {
  return (
    <div>
      <Modal isOpen={props.open} toggle={props.toggle} className={'clssName modal-dialog-centered delete-confirmation'}>
        <ModalBody>
        <div className="delete-icon"><i className="far fa-times-circle"></i></div>
          <div className="warring-message">Are you sure?</div>
          <div className="main-message">Do you really want to delete this record? This process can not be undone.</div>
        </ModalBody>
        <ModalFooter className="pt-0">
          <Button color="primary" onClick={e => props.confirmation(e, props.id)}>Yes, delete it!!</Button>
          <Button color="secondary" onClick={props.toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
export default ConfirmModal;