import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";
import InlineError from '../../../sharedFile/inlineError'
function ProfileForm(props) {
  const { data, errors, loader } = props
  return (
    <Container className="mt-0" fluid>
      <Row>
        <Col className="order-xl-1" xl="12">
          <Card className="bg-white card_border ">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="12">
                  <h2 className="mb-0 text-center">My account</h2>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="pt-0">
              <Form className="form_section mb-5" onSubmit={props.onSubmit}>
                <Row>
                  <Col lg="6">
                  </Col>
                  <Col lg="6" className="text-right">
                    {
                      sessionStorage.userROLE === 'super-admin' ?
                        <Button className="cutsomLink" onClick={props.changePassword}>Change Password</Button>
                        : ''
                    }
                  </Col>
                </Row>
                <div className="pl-lg-0">
                  <Row>
                    <Col lg="6">
                      <FormGroup error={!errors.username}>
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          Username
                            </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={data.username}
                          onChange={props.onChange}
                          id="input-username"
                          placeholder="Username"
                          name="username"
                          disabled
                          type="text"
                        />
                        {errors.username && <InlineError text={errors.username} />}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup error={!errors.email}>
                        <label
                          className="form-control-label"
                          htmlFor="input-email"
                        >
                          Email address
                            </label>
                        <Input
                          className="form-control-alternative"
                          id="input-email"
                          defaultValue={data.email}
                          onChange={props.onChange}
                          name="email"
                          disabled
                          placeholder="jesse@example.com"
                          type="text"
                        />
                        {errors.email && <InlineError text={errors.email} />}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup error={!errors.first_name}>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          First name
                            </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={data.first_name}
                          onChange={props.onChange}
                          name="first_name"
                          id="input-first-name"
                          placeholder="First name"
                          type="text"
                        />
                        {errors.first_name && <InlineError text={errors.first_name} />}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup error={!errors.last_name}>
                        <label
                          className="form-control-label"
                          htmlFor="input-last-name"
                        >
                          Last name
                            </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={data.last_name}
                          onChange={props.onChange}
                          name="last_name"
                          id="input-last-name"
                          placeholder="Last name"
                          type="text"
                        />
                        {errors.last_name && <InlineError text={errors.last_name} />}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="pl-lg-0">
                  <Row>
                    <Col lg="6">
                      <FormGroup error={!errors.address_one}>
                        <label
                          className="form-control-label"
                          htmlFor="input-address"
                        >
                          Address Line 1
                            </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={data.address_one}
                          onChange={props.onChange}
                          id="input-address"
                          name="address_one"
                          placeholder="Address Line 1"
                          type="text"
                        />
                        {errors.address_one && <InlineError text={errors.address_one} />}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup error={!errors.address_two}>
                        <label
                          className="form-control-label"
                          htmlFor="input-address"
                        >
                          Address Line 2
                            </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={data.address_two}
                          onChange={props.onChange}
                          id="input-address"
                          name="address_two"
                          placeholder="Address Line 2"
                          type="text"
                        />
                        {errors.address_two && <InlineError text={errors.address_two} />}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup error={!errors.phone_number}>
                        <label
                          className="form-control-label"
                          htmlFor="input-phone"
                        >
                          Mobile no.
                            </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={data.phone_number}
                          onChange={props.onChange}
                          maxLength="15"
                          name="phone_number"
                          id="input-phone"
                          placeholder="Phone Number"
                          type="text"
                        />
                        {errors.phone_number && <InlineError text={errors.phone_number} />}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup error={!errors.city}>
                        <label
                          className="form-control-label"
                          htmlFor="input-city"
                        >
                          City
                            </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={data.city}
                          onChange={props.onChange}
                          name="city"
                          id="input-city"
                          placeholder="City"
                          type="text"
                        />
                        {errors.city && <InlineError text={errors.city} />}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup error={!errors.state}>
                        <label
                          className="form-control-label"
                          htmlFor="input-country"
                        >
                          State
                            </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={data.state}
                          onChange={props.onChange}
                          id="input-country"
                          name="state"
                          placeholder="State"
                          type="text"
                        />
                        {errors.state && <InlineError text={errors.state} />}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup error={!errors.postal_code}>
                        <label
                          className="form-control-label"
                          htmlFor="input-country"
                        >
                          Postal code
                            </label>
                        <Input
                          className="form-control-alternative"
                          id="input-postal-code"
                          name="postal_code"
                          placeholder="Postal code"
                          type="text"
                          defaultValue={data.postal_code}
                          onChange={props.onChange} />
                        {errors.postal_code && <InlineError text={errors.postal_code} />}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col className="custom-submit">
                    <Button type="submit" className="my-4 submit-button" color="primary">
                      {
                        loader
                          ?
                          <Spinner color="light" />
                          :
                          'Update'
                      }

                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
export default ProfileForm;