import React from "react";
import { Row, Col, Card, CardHeader, Button, CardBody } from "reactstrap";
import { MDBDataTable, MDBIcon } from "mdbreact";
import { Link } from "react-router-dom";
import moment from "moment";
import orders from "../../assets/orders.png";
import { imagerUrl } from "../../config/config";
export default function PoOrderList(props) {
  const { poGenrate, url, data, messageList } = props;
  let tableData =
    poGenrate &&
    poGenrate.result &&
    poGenrate.result.map((items, key) => {
      let valueName;
      let supplier;
      if (items.ribDesc) {
        valueName = items.ribDesc.brandorder && items.ribDesc.brandorder.name;
      }
      if (items.febricDesc) {
        valueName =
          items.febricDesc.brandorder && items.febricDesc.brandorder.name;
      }
      if (items.ribColor) {
        valueName =
          items.ribColor &&
          items.ribColor.ribDesc &&
          items.ribColor.ribDesc.brandorder &&
          items.ribColor.ribDesc.brandorder.name;
      }
      if (items.zipperDesc) {
        valueName = items.zipperDesc.brandorder.name;
      }
      if (items.quiltingDesc) {
        valueName =
          items.quiltingDesc.brandorder && items.quiltingDesc.brandorder.name;
      }
      if (items.unitsDesc) {
        valueName =
          items.unitsDesc.brandorder && items.unitsDesc.brandorder.name;
      }
      if (items.ratioDesc) {
        valueName =
          items.ratioDesc.brandorder && items.ratioDesc.brandorder.name;
      }
      if (items.colorSupllier) {
        supplier = items.colorSupllier && items.colorSupllier.companyName;
      }
      if (items.febricSupllier) {
        supplier = items.febricSupllier && items.febricSupllier.companyName;
      }
      if (items.ribSupllier) {
        supplier = items.ribSupllier && items.ribSupllier.companyName;
      }
      if (items.zipperSupllier) {
        supplier = items.zipperSupllier && items.zipperSupllier.companyName;
      }
      if (items.quiltingSupllier) {
        supplier = items.quiltingSupllier && items.quiltingSupllier.companyName;
      }
      if (items.unitsSupllier) {
        supplier = items.unitsSupllier && items.unitsSupllier.companyName;
      }
      if (items.ratioSupllier) {
        supplier = items.ratioSupllier && items.ratioSupllier.companyName;
      }
      return {
        "#": key + 1,
        OrderNo: items.poId,
        Order: valueName,
        Supplier: supplier,
        Created: moment(items.poDate)
          .utcOffset("+05:30")
          .format("DD/MM/YY, H:mm"),
        Action: [
          <Button
            className="action_btn text-black"
            outline
            color=""
            tag={Link}
            to={{
              pathname:
                url +
                "/" +
                items.poId +
                "/" +
                (data.subCategoryId !== "All"
                  ? data.subCategoryId
                  : items.subCategoryId),
            }}
          >
            <MDBIcon far icon="eye" title="View" />
          </Button>,
          <a
            href={items.poLinks ? imagerUrl + items.poLinks : ""}
            target="_blank"
            className="action_btn text-black"
          >
            <MDBIcon icon="download" />
          </a>,
        ],
      };
    });
  let dataTable = {
    columns: [
      {
        label: "#",
        field: "#",
        sort: "asc",
      },
      {
        label: "Order Number",
        field: "OrderNo",
        sort: "asc",
      },
      {
        label: "Order Name",
        field: "Order",
        sort: "asc",
      },
      {
        label: "Supplier",
        field: "Supplier",
        sort: "asc",
      },
      {
        label: "Generated on",
        field: "Created",
        sort: "asc",
      },
      {
        label: "Action",
        field: "Action",
        sort: "disabled",
      },
    ],
    rows: tableData,
  };
  return (
    <Row>
      <Card className="card_border w-100  ">
        <Col className="p-0" xl="12">
          <div className="table-responsive">
            <MDBDataTable striped bordered responsive hover data={dataTable} />
          </div>
        </Col>
      </Card>
      {/* {poGenrate && poGenrate.result && poGenrate.result.length > 0 ?'':'No record found for above filter.'} */}
      {/* <div style={{textAlign: 'center',width: '100%',fontSize: '19px',color: '#df1f2e',textTransform: 'uppercase'}}>{messageList}</div> */}
    </Row>
  );
}
