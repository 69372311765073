import validator from 'validator';
export const loginValid = (data) => {
    let errors = {}
    // if (!validator.isEmail(data.email)) errors.email = "Invalid email";
    if (!data.email) errors.email = "Required*";
    if (!data.password) errors.password = "Required*";
    return errors;
}
export const forgotValid = (data) => {
    let errors = {}
    if (!data.email) errors.email = "Required*";
    return errors;
}
export const profileValid = (data) => {
    let errors = {}
    if (!data.first_name) errors.first_name = "Required*";
    if (!validator.isLength(data.first_name.toString(), { min: 0, max: 30 })) errors.first_name = "First Name must be less than 30 characters.";
    if (!validator.isNumeric(data.phone_number.toString())) errors.phone_number = "Enter only Number";
    if (!validator.isLength(data.phone_number.toString(), { min: 10, max: 15 })) errors.phone_number = "Phone number min length is 10 and max length is 15";
    if (!data.address_one) errors.address_one = "Required*";
    if (!data.city) errors.city = "Required*";
    if (!validator.isLength(data.city.toString(), { min: 0, max: 30 })) errors.city = "City must be less than 30 characters.";
    if (!data.state) errors.state = "Required*";
    if (!validator.isLength(data.state.toString(), { min: 0, max: 30 })) errors.state = "State must be less than 30 characters.";
    if (!validator.isLength(data.postal_code.toString(), { min: 3, max: 15 })) errors.postal_code = "Zipcode min length is 3 and max length is 15";
    if (!data.postal_code) errors.postal_code = "Required*";
    return errors;
}
export const profilePasswordValid = (data) => {
    let errors = {}
    if (!data.oldPassword) errors.oldPassword = "Required*";
    if (!data.newPassword) errors.newPassword = "Required*";
    if (data.newPassword !== data.confirmPassword) errors.confirmPassword = "Enter same Password";
    return errors;
}
export const userSignup = (data) => {
    let errors = {}
    var letterNumber = /^[0-9a-zA-Z]+$/;
    if (!data.userName.match(letterNumber)) errors.userName = "Username must be a combination of alphabets and numbers";
    if (!validator.isLength(data.userName.toString(), { min: 3, max: 30 })) errors.userName = "Username must be greater than 3 less than 30 characters.";
    if (!data.userName) errors.userName = "Required*";
    if (!data.firstName) errors.firstName = "Required*";
    if (!validator.isLength(data.firstName.toString(), { min: 0, max: 30 })) errors.firstName = "First Name must be less than 30 characters.";
    if (data.lastName.length > 0 && !validator.isLength(data.lastName.toString(), { min: 0, max: 30 })) errors.lastName = "Last Name must be less than 30 characters.";
    if (!validator.isEmail(data.email)) errors.email = "Enter Valid Email";
    if (!data.email) errors.email = "Required*";
    if (!data.password) errors.password = "Required*";
    if (!validator.isLength(data.password.toString(), { min: 0, max: 20 })) errors.password = "Password must be less than 30.";
    if (!data.phone) errors.phone = "Required*";
    if (!validator.isLength(data.phone.toString(), { min: 10, max: 15 })) errors.phone = "Mobile No. min length is 10 and max length is 15";
    if (!data.roleId) errors.roleId = "Required*";
    if (!data.address) errors.address = "Required*";
    if (!data.country) errors.country = "Required*";
    if (!validator.isLength(data.country.toString(), { min: 0, max: 30 })) errors.country = "Country must be less than 30 characters.";
    if (!data.city) errors.city = "Required*";
    if (!validator.isLength(data.city.toString(), { min: 0, max: 30 })) errors.city = "City must be less than 30 characters.";
    if (!data.state) errors.state = "Required*";
    if (!validator.isLength(data.state.toString(), { min: 0, max: 30 })) errors.state = "State must be less than 30 characters.";
    if (!validator.isNumeric(data.zip)) errors.zip = "Enter only Zipcode Number";
    if (!validator.isLength(data.zip, { min: 3, max: 15 })) errors.zip = "Zipcode min length is 3 and max length is 15";
    if (!data.zip) errors.zip = "Required*";
    return errors;
}
export const userEdit = (data) => {
    let errors = {}
    var letterNumber = /^[0-9a-zA-Z]+$/;
    if (!data.userName.match(letterNumber)) errors.userName = "Username must be a combination of alphabets and numbers";
    if (!validator.isLength(data.userName.toString(), { min: 3, max: 30 })) errors.userName = "Username must be greater than 3 less than 30 characters.";
    if (!data.userName) errors.userName = "Required*";
    if (!data.firstName) errors.firstName = "Required*";
    if (!validator.isLength(data.firstName.toString(), { min: 0, max: 30 })) errors.firstName = "First Name must be less than 30 characters.";
    if (data.lastName.length > 0 && !validator.isLength(data.lastName.toString(), { min: 0, max: 30 })) errors.lastName = "Last Name must be less than 30 characters.";
    if (!validator.isEmail(data.email)) errors.email = "Enter Valid Email";
    if (!data.email) errors.email = "Required*";
    if (!data.phone) errors.phone = "Required*";
    if (!validator.isLength(data.phone.toString(), { min: 10, max: 15 })) errors.phone = "Mobile No. min length is 10 and max length is 15";
    if (!data.roleId || data.roleId == 'Select Role') errors.roleId = "Required*";
    if (!data.address) errors.address = "Required*";
    if (!data.country) errors.country = "Required*";
    if (!validator.isLength(data.country, { min: 0, max: 30 })) errors.country = "Country must be less than 30 characters.";
    if (!data.city) errors.city = "Required*";
    if (!validator.isLength(data.city.toString(), { min: 0, max: 30 })) errors.city = "City must be less than 30 characters.";
    if (!data.state) errors.state = "Required*";
    if (!validator.isLength(data.state.toString(), { min: 0, max: 30 })) errors.state = "State must be less than 30 characters.";
    if (!data.zip) errors.zip = "Required*";
    if (!validator.isNumeric(data.zip.toString())) errors.zip = "Enter only Zipcode Number";
    if (!validator.isLength(data.zip.toString(), { min: 3, max: 15 })) errors.zip = "Zipcode min length is 3 and max length is 15";
    return errors;
}
export const addCategoryValid = (data) => {
    let errors = {}
    if (!data.name) errors.name = "Required*";
    if (!validator.isLength(data.name.toString(), { min: 0, max: 30 })) errors.name = "Name must be less than 30 characters.";
    if (!data.description) errors.description = "Required*";
    return errors;
}
export const addVarientValid = (data) => {
    let errors = {}
    if (!data.name) errors.name = "Required*";
    if (!validator.isLength(data.name.toString(), { min: 0, max: 30 })) errors.name = "Name must be less than 30 characters.";
    if (!data.description) errors.description = "Required*";
    return errors;
}
export const addSupplierValid = (data) => {
    let errors = {}
    if (!data.companyName) errors.companyName = "Required*";
    if (!validator.isLength(data.companyName.toString(), { min: 0, max: 30 })) errors.companyName = "Company Name must be less than 30 characters.";
    if (!data.firstName) errors.firstName = "Required*";
    if (!validator.isLength(data.firstName.toString(), { min: 0, max: 30 })) errors.firstName = "First Name Name must be less than 30 characters.";
    if (data.lastName.length > 0 && !validator.isLength(data.lastName.toString(), { min: 0, max: 30 })) errors.lastName = "Last Name must be less than 30 characters.";
    if (!validator.isEmail(data.email)) errors.email = "Enter Valid Email";
    if (!data.email) errors.email = "Required*";
    if (!data.mobile) errors.mobile = "Enter valid mobile number";
    if (!validator.isLength(data.mobile.toString(), { min: 10, max: 15 })) errors.mobile = "Mobile number min length is 10 and max length is 15";
    if (data.phone !== '' && !validator.isLength(data.phone.toString(), { min: 3, max: 15 })) errors.phone = "Phone number min length is 3 and max length is 15";
    if (!data.address) errors.address = "Required*";
    if (!data.country) errors.country = "Required*";
    if (!validator.isLength(data.country.toString(), { min: 0, max: 30 })) errors.country = "Country must be less than 30 characters.";
    if (!data.city) errors.city = "Required*";
    if (!validator.isLength(data.city.toString(), { min: 0, max: 30 })) errors.city = "City must be less than 30 characters.";
    if (!data.state) errors.state = "Required*";
    if (!validator.isLength(data.state.toString(), { min: 0, max: 30 })) errors.state = "State must be less than 30 characters.";
    if (!data.zip) errors.zip = "Required*";
    if (!validator.isLength(data.zip.toString(), { min: 3, max: 15 })) errors.zip = "Zipcode min length is 3 and max length is 15";
    if (!data.gstNumber) errors.gstNumber = "Required*";
    if (!validator.isLength(data.gstNumber.toString(), { min: 1, max: 30 })) errors.gstNumber = "GST Number max length is 30";
    if (!data.categoryId || data.categoryId == 'Select Catgeory') errors.categoryId = "Required*";
    return errors;
}
export const addMaterialValid = (data) => {
    let errors = {}
    if (!data.subCategoryId || data.subCategoryId == '' || data.subCategoryId == 'Select sub category') errors.subCategoryId = "Required*";
    if (!data.name) errors.name = "Required*";
    if (!validator.isLength(data.name.toString(), { min: 0, max: 100 })) errors.name = "Name must be less than 100 characters.";
    if (!data.description) errors.description = "Required*";
    if (data.picture.length > 12) errors.picture = "Upload maximum 12 pictures";
    return errors;
}
export const addSubValid = (data, slugCate) => {
    let errors = {}
    if (!data.categoryId) errors.categoryId = "Required*";
    if (!data.name) errors.name = "Required*";
    if (!validator.isLength(data.name.toString(), { min: 0, max: 30 })) errors.name = "Name must be less than 30 characters.";
    if (!data.description) errors.description = "Required*";
    if (slugCate === 'quilting-family') {
        if (!data.slug) errors.slug = "Required*";
    }
    return errors;
}
export const addUpdateValid = (data, imagesPreviewUrls) => {
    let errors = {}
    if (!data.name) errors.name = "Required*";
    if (!validator.isLength(data.name.toString(), { min: 0, max: 100 })) errors.name = "Name must be less than 100 characters.";
    if (!data.description) errors.description = "Required*";
    if (imagesPreviewUrls.length > 12) errors.picture = "Upload maximum 12 pictures";
    return errors;
}
export const imageSizeValid = (data) => {
    let errors = { picture: [] }
    data.picture.map((items, key) => {
        if (items.size > 1000000) errors.picture.push({ key: key, err: "Image max upload size is 1MB" });
    })
    return errors;
}
export const manageVarientValid = (dataVarient) => {
    let errors = { varientId: [] }
    var valueArr = dataVarient.varient.map(function(item){ return item.varientId });
    valueArr.some(function(item, idx){ 
         if(valueArr.indexOf(item) != idx) errors.varientId.push({ key: idx, err: "Same variant can't be added multiple time." });
    });
    dataVarient.varient.map((items, key) => {
        if (!items.varientId && items.varientId != 'Select variant') errors.varientId.push({ key: key, err: "Required*" });
    })
    return errors;
}
export const inhouseValid = (dataVarient, slugSub, slugCate) => {
    let errors = {}
    if (!dataVarient.materialId) errors.materialId = "Required*";
    if (!validator.isNumeric(dataVarient.quantity.toString())) errors.quantity = "Enter numeric value";
    if (!dataVarient.quantity) errors.quantity = "Required*";
    if (!dataVarient.subCategoryId) errors.subCategoryId = "Required*";
    var valueArr = dataVarient.varient.map(function(item){ return item.varientId });
    var isDuplicate = valueArr.some(function(item, idx){ 
         if(valueArr.indexOf(item) != idx) errors.varient = "Same variant can't be added multiple time.";
    });
    console.log(slugSub, slugCate,"errors=== 1")
    if(slugCate !== 'rib-dhaaga-family'){
        dataVarient.varient.map(function(item){ if (!item.value || !item.varientId) errors.varient = "Required*" });
    }else{
    }
    if(slugCate === 'ratiowise-family' && !dataVarient.orderSize) errors.orderSize = "Required*";
    if(slugSub === 'dhaaga_color' && !dataVarient.colorName) errors.colorName = "Required*";
    return errors;
}

export const addBrandValid = (data) => {
    let errors = {}
    if (!data.name) errors.name = "Required*";
    if (!validator.isLength(data.name.toString(), { min: 0, max: 30 })) errors.name = "Name must be less than 30 characters.";
    if (!data.description) errors.description = "Required*";
    if (data.picture && data.picture[0] && data.picture[0].size > 1000000) errors.picture = "Please upload less than 1MB";
    return errors;
}
export const addNote = (data) => {
    let errors = {}
    if (!data.notes) errors.notes = "Required*";
    return errors;
}



export const uploadOrderValid = (data) => {
    let errors = {}
    if (!data.brandId || data.brandId == 'Select Brand') errors.brandId = "Required*";
    if (!data.picture || data.picture.length === 0) errors.picture = "Required*";
    return errors;
}
export const oldOrderValid = (data) => {
    let errors = {}
    let date = new Date(data.dateFrom)
    let dateTo = new Date(data.dateTo)
    if (!data.brandId) errors.brandId = "Required*";
    if (!data.dateFrom) errors.dateFrom = "Required*";
    if (!data.dateTo) errors.dateTo = "Required*";
    if (+date > +dateTo) errors.dateTo = "Incorrect date range";
    return errors;
}
export const poOrderValid = (data) => {
    let errors = {}
    let date = new Date(data.dateFrom)
    let dateTo = new Date(data.dateTo)
    if (!data.subCategoryId) errors.subCategoryId = "Required*";
    if (!data.brandId) errors.brandId = "Required*";
    if (!data.dateFrom) errors.dateFrom = "Required*";
    if (!data.dateTo) errors.dateTo = "Required*";
    if (+date > +dateTo) errors.dateTo = "Incorrect date range";
    return errors;
}
export const supplierSubmit = (data) => {
    let errors = {}
    if (!data.brandId) errors.brandId = "Required*";
    if (!data.orderId) errors.orderId = "Required*";
    return errors;
}

export const uploadInhouseValid = (data) => {
    let errors = {}
    if (!data.picture || data.picture.length === 0) errors.picture = "Required*";
    return errors;
}