import { call, put, takeLatest, all } from "redux-saga/effects";
import {
  GET_PO_GENERATE_QUILTING,
  SET_PO_GENERATE_QUILTING_SUCCESS,
  SET_PO_GENERATE_QUILTING_FAILED,
  GET_PO_GENERATE_KORA,
  SET_PO_GENERATE_KORA_SUCCESS,
  SET_PO_GENERATE_KORA_FAILED,
  GET_PO_GENERATE_COLOR,
  SET_PO_GENERATE_COLOR_SUCCESS,
  SET_PO_GENERATE_COLOR_FAILED,
  GET_PO_GENERATE_ZIPPER,
  SET_PO_GENERATE_ZIPPER_SUCCESS,
  SET_PO_GENERATE_ZIPPER_FAILED,
  GET_PO_GENERATE_UNIT,
  SET_PO_GENERATE_UNIT_SUCCESS,
  SET_PO_GENERATE_UNIT_FAILED,
  GET_PO_GENERATE_RATIO,
  SET_PO_GENERATE_RATIO_SUCCESS,
  SET_PO_GENERATE_RATIO_FAILED,
  GET_PO_GENERATE,
  SET_PO_GENERATE_SUCCESS,
  SET_PO_GENERATE_FAILED,
  GET_PO_DETAIL,
  GET_PO_DETAIL_SUCCESS,
  GET_PO_DETAIL_FAILED,
  GET_PO_ORDER_LIST,
  GET_PO_ORDER_LIST_SUCCESS,
  GET_PO_ORDER_LIST_FAILED,
} from "../../sharedFile/actionType";
import Api from "../../lib/api";
function poGeneratePDFApi(action) {
  return Api.post(
    `order/quilting/generatePO`, action.data);
}
function* poGeneratePDF(action) {
  try {
    const resp = yield call(poGeneratePDFApi, action);
    if (resp.success === true) {
      yield put({ type: SET_PO_GENERATE_QUILTING_SUCCESS, data: resp })
    } else {
      yield put({ type: SET_PO_GENERATE_QUILTING_FAILED, error: resp })
    }
  } catch (resp) {
    yield put({ type: SET_PO_GENERATE_QUILTING_FAILED, error: resp })
  }
}
function koraPoApi(action) {
  return Api.post(
    `order/ribDhaagaWorking/generatePO`, action.data);
}
function* koraPo(action) {
  try {
    const resp = yield call(koraPoApi, action);
    if (resp.success === true) {
      yield put({ type: SET_PO_GENERATE_KORA_SUCCESS, data: resp })
    } else {
      yield put({ type: SET_PO_GENERATE_KORA_FAILED, error: resp })
    }
  } catch (resp) {
    yield put({ type: SET_PO_GENERATE_KORA_FAILED, error: resp })
  }
}
function colorPoApi(action) {
  return Api.post(
    `order/colorDhaaga/generatePO`, action.data);
}
function* colorPo(action) {
  try {
    const resp = yield call(colorPoApi, action);
    if (resp.success === true) {
      yield put({ type: SET_PO_GENERATE_COLOR_SUCCESS, data: resp })
    } else {
      yield put({ type: SET_PO_GENERATE_COLOR_FAILED, error: resp })
    }
  } catch (resp) {
    yield put({ type: SET_PO_GENERATE_COLOR_FAILED, error: resp })
  }
}
function zipperPoApi(action) {
  return Api.post(
    `order/zipper/generatePO`, action.data);
}
function* zipperPo(action) {
  try {
    const resp = yield call(zipperPoApi, action);
    if (resp.success === true) {
      yield put({ type: SET_PO_GENERATE_ZIPPER_SUCCESS, data: resp })
    } else {
      yield put({ type: SET_PO_GENERATE_ZIPPER_FAILED, error: resp })
    }
  } catch (resp) {
    yield put({ type: SET_PO_GENERATE_ZIPPER_FAILED, error: resp })
  }
}
function unitPoApi(action) {
  return Api.post(
    `order/units/generatePO`, action.data);
}
function* unitPo(action) {
  try {
    const resp = yield call(unitPoApi, action);
    if (resp.success === true) {
      yield put({ type: SET_PO_GENERATE_UNIT_SUCCESS, data: resp })
    } else {
      yield put({ type: SET_PO_GENERATE_UNIT_FAILED, error: resp })
    }
  } catch (resp) {
    yield put({ type: SET_PO_GENERATE_UNIT_FAILED, error: resp })
  }
}
function ratioPoApi(action) {
  return Api.post(
    `order/ratio/generatePO`, action.data);
}
function* ratioPo(action) {
  try {
    const resp = yield call(ratioPoApi, action);
    if (resp.success === true) {
      yield put({ type: SET_PO_GENERATE_RATIO_SUCCESS, data: resp })
    } else {
      yield put({ type: SET_PO_GENERATE_RATIO_FAILED, error: resp })
    }
  } catch (resp) {
    yield put({ type: SET_PO_GENERATE_RATIO_FAILED, error: resp })
  }
}
function poFabricPDFApi(action) {
  return Api.post(
    `/order/fabric/generatePO`, action.data);
}
function* poFabricPDF(action) {
  try {
    const resp = yield call(poFabricPDFApi, action);
    if (resp.success === true) {
      yield put({ type: SET_PO_GENERATE_SUCCESS, data: resp })
    } else {
      yield put({ type: SET_PO_GENERATE_FAILED, error: resp })
    }
  } catch (resp) {
    yield put({ type: SET_PO_GENERATE_FAILED, error: resp })
  }
}
function poDetailApi(action){
  return Api.post(
    `order/PODetails`,action.data
  )
}
function* poDetail(action){
  try{
    let resp = yield call(poDetailApi, action)
    if (resp.success === true) {
      yield put({ type: GET_PO_DETAIL_SUCCESS, data: resp })
    }
  }catch(resp){
    yield put({ type: GET_PO_DETAIL_FAILED, error: resp })
  }
}
function poOrderListApi(action){
  return Api.post(
    `order/POList`,action.data
  )
}
function* poOrderList(action){
  try{
    let resp = yield call(poOrderListApi, action)
    if (resp.success === true) {
      yield put({ type: GET_PO_ORDER_LIST_SUCCESS, data: resp })
    }
  }catch(resp){
    yield put({ type: GET_PO_ORDER_LIST_FAILED, error: resp })
  }
}
function* poPDF() {
  yield all([
    takeLatest(GET_PO_GENERATE_QUILTING, poGeneratePDF),
    takeLatest(GET_PO_GENERATE_COLOR, colorPo),
    takeLatest(GET_PO_GENERATE_KORA, koraPo),
    takeLatest(GET_PO_GENERATE_ZIPPER, zipperPo),
    takeLatest(GET_PO_GENERATE_UNIT, unitPo),
    takeLatest(GET_PO_GENERATE_RATIO, ratioPo),
    takeLatest(GET_PO_GENERATE, poFabricPDF),
    takeLatest(GET_PO_DETAIL, poDetail),
    takeLatest(GET_PO_ORDER_LIST, poOrderList),
  ])
}
export default poPDF;