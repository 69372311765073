import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  NavLink,
  Col,
  Nav,
  NavItem,
  Spinner
} from "reactstrap";
import { Link } from 'react-router-dom';
import InlineError from '../../sharedFile/inlineError'
import Required from '../../sharedFile/required';
function AddForm(props) {
  const { errors, loader, data, imagesPreviewUrls } = props
  return (
    <Container className="mt-0" fluid>
      <Row>
        <Col className="order-xl-1" xl="12">
          <Row className="align-items-center ">
            <div className="col">
              <h2 className="mb-0 text-center">Add Brand</h2>
              <span className="italic-message">* - indicate required fields!!</span>
            </div>
          </Row>
          <Form onSubmit={props.onSubmit}>
            <div className="pl-lg-0">
              <Row>
                <Col lg="12">
                  <FormGroup error={!errors.name}>
                    <label
                      className="form-control-label"
                      htmlFor="input-name"
                    >
                      Brand Name<Required />
                    </label>
                    <Input
                      className="form-control-alternative"
                      value={data.name}
                      onChange={props.onChange}
                      id="input-name"
                      placeholder="Name"
                      maxLength='30'
                      name="name"
                      type="text"
                    />
                    {errors.name && <InlineError text={errors.name} />}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12" className="upload_sheet" error={!errors.picture}>
                  <label
                    className="form-control-label"
                    htmlFor="input-name"
                  >
                    Brand Logo
                    </label>
                  <div className="position-relative mb-1">
                    <Col lg="6" className="p-0">
                      {imagesPreviewUrls.map((items, key) => {
                        return <div key={key}>
                          <img src={items} height="150" width="95%" alt="brand" style={{ objectFit: 'cover', border: '1px solid #e1e1e1', borderRadius: '6px' }} ></img>
                          <label onClick={props.removeLogo} className="position-absolute remove icon-file"><i class="fas fa-times"></i></label>
                        </div>
                      })}
                    </Col>
                  </div>
                  <FormGroup className="addBrandList" error={!errors.logo} style={{ width: '100%', position: 'relative' }}>
                    <label
                      className="form-control-label custom_label_input cursor-pointer"
                      htmlFor="input-logo"
                      for="exampleFile"
                    >
                      <i className="fa fa-image" aria-hidden="true"></i> Add Logo
                            </label>
                    <Input type="file"
                      className="form-control-alternative"
                      placeholder="Name" value={data.message} name="picture" id="exampleFile" accept="image/gif,image/jpeg,image/png" onChange={props.onFileChange} />
                    {errors.picture && <InlineError text={errors.picture} />}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup error={!errors.description}>
                    <label
                      className="form-control-label"
                      htmlFor="input-description"
                    >
                      Brand Description<Required />
                    </label>
                    <Input
                      className="form-control-alternative"
                      value={data.description}
                      onChange={props.onChange}
                      name="description"
                      id="input-description"
                      placeholder="Description"
                      type="textarea"
                    />
                    {errors.description && <InlineError text={errors.description} />}
                  </FormGroup>
                </Col>

              </Row>

            </div>
            <Row>
              <Col lg="12" className="custom-submit">
                <Button type="submit" className="my-4 submit-button" disabled={loader ? true : false} color="primary">
                  {
                    loader
                      ?
                      <Spinner color="light" />
                      :
                      'Submit'
                  }

                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}
export default AddForm;