import React from 'react';
import {
    Card, CardHeader, Row, Container, Button, NavItem,
    Nav,
    NavLink, Spinner
} from "reactstrap";
import PreviewModal from "../../../components/previewModal";
import Header from "../../../components/Headers/Header.jsx";
import EditTable from '../../../components/EditTable'
import { imagerUrl } from '../../../config/config';
import { getBrandOrder, initialState, noteinitialState, getRatioInhouseStocks } from '../../../services/actions/order';
import { ratioGet, ratioAdd, addRationPicture } from '../../../services/actions/unitRatioWise'
import { varientBySlug, varienInitial } from '../../../services/actions/varient'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import dataJson from '../../../sharedFile/allData'
import { ratioSupplier } from '../../../services/actions/supplier'
import { ratioPo } from '../../../services/actions/po'
import FormModel from "../../../components/formModel.jsx";
import moment from 'moment'
var toastId = null

class RatioWise extends React.Component {
    state = {
        data: {
            columns: [],
            rows: []
        },
        brand: '',
        poSucc: null,
        brandImg: '',
        name: '',
        brandOrderId: this.props.match.params.id,
        previewData: {
            columns: [],
            rows: []
        },
        previewDataId: null,
        viewImg: '',
        openViewImg: false,
        poStatus: '',
        loaderPo: false,
        open: false,
        loaderVarient: true,
        loaderTable: true,
        supllierDetail: [],
        selectPoList: {
            id: []
        },
        total: 0,
        varient: [],
        suppliers: [],
        materialList: [],
        poSupplierDetail: [],
        slug: this.props.match.params.item_slug,
        pageNumber: 1,
        orderIdPo: '',
        search: '',
        pageHead: ''
    }
    componentWillMount() {
        const { slug } = this.state
        this.props.varientBySlug(slug)
        this.setState({
            loaderVarient: true
        })
    }
    onKeyPress(evt) {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        var regex = /[0-9]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }
    valueEdit = (rowId, key, rows, value, suppliers, material) => {
        const { varient } = this.state
        let currentDate = new Date();
        let getYear = currentDate.getFullYear()
        let getMonth = currentDate.getMonth() + 1
        let fullCurrentDate = getYear + '-' + (getMonth <= 9 ? '0' + getMonth : getMonth) + '-' + (currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate())
        if (key === rowId) {
            if (sessionStorage.userROLE == 'super-admin' || rows[rowId]['poStatus'] === 'Pending') {
                varient.map(items => {
                    console.log(items,'..........varient')
                    if (items.assignedVarient === '') {
                        console.log('items==',items,value,this.state.data,value[items.field])
                        value[items.field] = [<input className="inputFiled" type="text" name={items.name} value={rows[key][items.name]} key={items.name + key} onChange={e => (this.onChange(e, key))} />]
                    } else {
                        let values = items.assignedVarient.split(',')
                        value[items.field] = [<select className="inputFiled" name={items.name} key={'ah' + key} defaultValue={rows[key][items.name]} onChange={e => (this.onChange(e, key))}>
                            <option value={null}>{items.name}</option>
                            {values.map(itemValue => {
                                return <option value={itemValue}>{itemValue}</option>
                            })}
                        </select>]
                    }
                })
                value['Action'] = [<i className="fas fa-check pr-2 cursor-pointer" onClick={e => (this.onSubmit(e, key))} key={'abc' + key}></i>]
                value['Sr No'] = key + 1
                value['Style No'] = value['Style No']
                value['Style Code'] = value['Style Code']
                value['Supplier'] = [<select className="selectList inputFiled" name="supplier" value={rows[key]['supplier']} onChange={e => (this.onChange(e, key))}>
                    <option>Supplier</option>
                    {
                        suppliers.map((value, key) => {
                            return <option value={value.id} key={key}>{value.companyName}</option>
                        })
                    }
                </select>]
                value['Trim Desc'] = [<select className="selectList inputFiled" name="materialId" defaultValue={rows[key]['materialId']} onChange={e => (this.onChange(e, key))}>
                    <option>specification</option>
                    {
                        material && material.length && material[0].map((value, key) => {
                            return <option value={value.supplyMaterial.id} key={key}>{value.supplyMaterial.name}</option>
                        })
                    }
                </select>]
                value['Average'] = [<input onKeyPress={this.onKeyPress.bind(this)} className="inputFiled avgWidth" type="text" name="average" value={rows[key]['average']} onChange={e => (this.onChange(e, key))} />]
                value['Xs Add Qtty %'] = [<select className="inputFiled" name="addQTYXS" value={rows[key]['addQTYXS']} onChange={e => (this.onChange(e, key))}>
                    <option value="">Qty</option>
                    {
                        dataJson.addQuantity.map(items => {
                            return <option value={items}>{items + '%'}</option>
                        })
                    }
                </select>]
                value['Xs Inhouse'] = value['Xs Inhouse']
                value['Xs Final Need'] = value['Xs Final Need']
                value['S Add Qtty %'] = [<select className="inputFiled" name="addQTYS" value={rows[key]['addQTYS']} onChange={e => (this.onChange(e, key))}>
                    <option value="">Qty</option>
                    {
                        dataJson.addQuantity.map(items => {
                            return <option value={items}>{items + '%'}</option>
                        })
                    }
                </select>]
                value['S Inhouse'] = value['S Inhouse']
                value['S Final Need'] = value['S Final Need']
                value['M Add Qtty %'] = [<select className="inputFiled" name="addQTYM" value={rows[key]['addQTYM']} onChange={e => (this.onChange(e, key))}>
                    <option value="">Qty</option>
                    {
                        dataJson.addQuantity.map(items => {
                            return <option value={items}>{items + '%'}</option>
                        })
                    }
                </select>]
                value['M Inhouse'] = value['M Inhouse']
                value['M Final Need'] = value['M Final Need']
                value['L Add Qtty %'] = [<select className="inputFiled" name="addQTYL" value={rows[key]['addQTYL']} onChange={e => (this.onChange(e, key))}>
                    <option value="">Qty</option>
                    {
                        dataJson.addQuantity.map(items => {
                            return <option value={items}>{items + '%'}</option>
                        })
                    }
                </select>]
                value['L Inhouse'] = value['L Inhouse']
                value['L Final Need'] = value['L Final Need']
                value['Xl Add Qtty %'] = [<select className="inputFiled" name="addQTYXL" value={rows[key]['addQTYXL']} onChange={e => (this.onChange(e, key))}>
                    <option value="">Qty</option>
                    {
                        dataJson.addQuantity.map(items => {
                            return <option value={items}>{items + '%'}</option>
                        })
                    }
                </select>]
                value['Xl Inhouse'] = value['Xl Inhouse']
                value['Xl Final Need'] = value['Xl Final Need']
                value['Xxl Add Qtty %'] = [<select className="inputFiled" name="addQTYXXL" value={rows[key]['addQTYXXL']} onChange={e => (this.onChange(e, key))}>
                    <option value="">Qty</option>
                    {
                        dataJson.addQuantity.map(items => {
                            return <option value={items}>{items + '%'}</option>
                        })
                    }
                </select>]
                value['Xxl Inhouse'] = value['Xxl Inhouse']
                value['Xxl Final Need'] = value['Xxl Final Need']
                value['3xl Add Qtty %'] = [<select className="inputFiled" name="addQTY3XL" value={rows[key]['addQTY3XL']} onChange={e => (this.onChange(e, key))}>
                    <option value="">Qty</option>
                    {
                        dataJson.addQuantity.map(items => {
                            return <option value={items}>{items + '%'}</option>
                        })
                    }
                </select>]
                value['3xl Inhouse'] = value['3xl Inhouse']
                value['3xl Final Need'] = value['3xl Final Need']
                value['4xl Add Qtty %'] = [<select className="inputFiled" name="addQTY4XL" value={rows[key]['addQTY4XL']} onChange={e => (this.onChange(e, key))}>
                    <option value="">Qty</option>
                    {
                        dataJson.addQuantity.map(items => {
                            return <option value={items}>{items + '%'}</option>
                        })
                    }
                </select>]
                value['4xl Inhouse'] = value['4xl Inhouse']
                value['4xl Final Need'] = value['4xl Final Need']
                value['5xl Add Qtty %'] = [<select className="inputFiled" name="addQTY5XL" value={rows[key]['addQTY5XL']} onChange={e => (this.onChange(e, key))}>
                    <option value="">Qty</option>
                    {
                        dataJson.addQuantity.map(items => {
                            return <option value={items}>{items + '%'}</option>
                        })
                    }
                </select>]
                value['5xl Inhouse'] = value['5xl Inhouse']
                value['5xl Final Need'] = value['5xl Final Need']
                value['Image'] = [<><><img src={rows[key]['imagesPreviewUrls']} id={'imgLoad' + rowId} style={{ width: '50px', margin: '0px' }} /></><label htmlFor="fileUnit" className="fileUpload"><i class="fas fa-image"></i><div style={{ fontSize: '10px', color: '#f15d5d' }}>Upload img</div></label><input style={{ display: 'none' }} type="file" name="picture" id="fileUnit" onChange={e => (this.onChange(e, key))} accept="image/gif,image/jpeg,image/png" /></>]
                value['Price'] = [<input maxLength="8" className="inputFiled" onKeyPress={this.onKeyPress.bind(this)} type="text" name="price" value={rows[key]['price']} onChange={e => (this.onChange(e, key))} />]
                value['Delivery'] = [<input className="inputFiled" min={fullCurrentDate} type="date" name="deliveryDate" value={rows[key]['deliveryDate']} onChange={e => (this.onChange(e, key))} />]
                value['Remarks'] = [<input className="inputFiled" type="text" name="Remarks" value={rows[key]['Remarks']} onChange={e => (this.onChange(e, key))} />]
                // value['po status'] = rows[key]["poStatus"]
                // value['recd status'] = [<input type="text" name="recdStatus" value={rows[key]['recdStatus']} onChange={e => (this.onChange(e, key))} />]
                // value['pending status'] = rows[key]["pendingStatus"]
            }
            else {
                value['Action'] = [<i className="fas fa-check pr-2 cursor-pointer" onClick={e => (this.onSubmit(e, key))} key={'abc' + key}></i>]
                // value['recd status'] = [<input type="text" name="recdStatus" value={rows[key]['recdStatus']} onChange={e => (this.onChange(e, key))} />]
                // value['pending status'] = rows[key]["pendingStatus"]
            }
        } else {
            value['Action'] = [<div className="d-flex"><i title="Edit" className="disabledAction fas fa-pen pr-2 cursor-pointer" key={'abc' + key + 1}></i><i title="Add Row" className="disabledAction fas fa-plus-circle cursor-pointer" key={'abce' + key + 1} ></i><i key={'abcr' + key + 1} title="View" className="disabledAction fas fa-eye cursor-pointer" ></i><input style={{ minWidth: '15px', marginLeft: '5px' }} type="checkbox" value={this.state.rows[key]['orderid']} name="poGenrate" disabled key={key + 1} onChange={e => (this.listPoGenrate(e, key + 1))} /></div>]
        }

        console.log(value,'................values')
    }
    valueUpdate = (rows, rowId, value, key) => {
        const { varient } = this.state
        if (key == rowId) {
            varient.map(items => {
                value[items.field] = rows[rowId][items.name]
            })
            value['Action'] = [<div style={{ display: 'flex' }}><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i><i title="Add Row" className="fas fa-plus-circle cursor-pointer" key={'abce' + key} onClick={e => (this.onAdd(e, key))}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, rows[rowId]['orderid']))} ></i><input type="checkbox" value={value.id} name="poGenrate" style={{ minWidth: '15px', marginLeft: '5px' }} disabled={true} key={key} onChange={e => (this.listPoGenrate(e, key))} /></div>]
            value['Sr No'] = key + 1
            value['Style No'] = value['Style No']
            value['Style Code'] = value['Style Code']
            value['Supplier'] = rows[rowId]['supplier']
            value['Trim Desc'] = rows[rowId]['materialText']
            value['Xs Add Qtty %'] = rows[rowId]['addQTYXS']
            value['Xs Inhouse'] = value['Xs Inhouse']
            value['Xs Final Need'] = value['Xs Final Need']
            value['S Add Qtty %'] = rows[rowId]['addQTYS']
            value['S Inhouse'] = value['S Inhouse']
            value['S Final Need'] = value['S Final Need']
            value['M Add Qtty %'] = rows[rowId]['addQTYM']
            value['M Inhouse'] = value['M Inhouse']
            value['M Final Need'] = value['M Final Need']
            value['L Add Qtty %'] = rows[rowId]['addQTYL']
            value['L Inhouse'] = value['L Inhouse']
            value['L Final Need'] = value['L Final Need']
            value['Xl Add Qtty %'] = rows[rowId]['addQTYXL']
            value['Xl Inhouse'] = value['Xl Inhouse']
            value['Xl Final Need'] = value['Xl Final Need']
            value['Xxl Add Qtty %'] = rows[rowId]['addQTYXXL']
            value['Xxl Inhouse'] = value['Xxl Inhouse']
            value['Xxl Final Need'] = value['Xxl Final Need']
            value['3xl Add Qtty %'] = rows[rowId]['addQTY3XL']
            value['3xl Inhouse'] = value['3xl Inhouse']
            value['3xl Final Need'] = value['3xl Final Need']
            value['4xl Add Qtty %'] = rows[rowId]['addQTY4XL']
            value['4xl Inhouse'] = value['4xl Inhouse']
            value['4xl Final Need'] = value['4xl Final Need']
            value['5xl Add Qtty %'] = rows[rowId]['addQTY5XL']
            value['5xl Inhouse'] = value['5xl Inhouse']
            value['5xl Final Need'] = value['5xl Final Need']
            value['Average'] = rows[rowId]['average']
            value['Image'] = value['picture']
            value['Price'] = rows[rowId]['price']
            value['Delivery'] = rows[rowId]['deliveryDate']
            value['Remarks'] = rows[rowId]['Remarks']
            value['po status'] = rows[rowId]['poStatus']
            // value['recd status'] = rows[rowId]['recdStatus']
            // value['pending status'] = rows[rowId]['pendingStatus']
        }
    }
    onChange = (e, rowId) => {
        let { rows, data, suppliers, materialList, varient } = this.state
        let materialSet = []

        let dataRows = rows.map((value, key) => {
            if (key === rowId) {
                if (e.target.name === 'picture') {
                    let files = Array.from(e.target.files);
                    if (files[0].size > 1000000) {
                        toastId = toast.error('Image max upload size is 1MB')
                    } else {
                        files.forEach((file) => {
                            let reader = new FileReader();
                            reader.onloadend = () => {
                                value['imagesPreviewUrls'] = reader.result
                                value['picture'] = [file]
                                document.getElementById('imgLoad' + rowId).src = reader.result
                            }
                            reader.readAsDataURL(file);
                        });
                    }
                } else {
                    if (e.target.name === 'supplier' && e.target.value !== 'Select Supplier') {
                        let material = []
                        value['supplier'] = e.target.value
                        suppliers.map((val, keys) => {
                            if ((val.id).toString() === e.target.value) {
                                material.push(val.suppliermaterial)
                                materialSet.push(val.suppliermaterial)
                            } return true
                        })
                        this.setState({
                            materialList: material
                        })
                    } else {
                        value[e.target.name] = e.target.value
                        if (e.target.name === 'recdStatus') {
                            value['pendingStatus'] = value['finalNeed'] - parseFloat(e.target.value)
                        }
                        if (e.target.name === 'addQTYS' || e.target.name === 'average') {
                            let qtyTotal = value['addQTYS'] ? parseFloat(value['addQTYS']) : 0
                            let total = value['addQTYS'] ? Math.ceil(value.orderS + ((qtyTotal / 100) * value.orderS)) : 0
                            let totalNeed = Math.ceil(value['average'] * total)
                            value['finalNeedS'] = totalNeed// - value['inHouseS']
                            data.rows[key]['S Final Need'] = totalNeed// - value['inHouseS']
                        } if (e.target.name === 'addQTYXS' || e.target.name === 'average') {
                            let qtyTotal = value['addQTYXS'] ? parseFloat(value['addQTYXS']) : 0
                            let total = value['addQTYXS'] ? Math.ceil(value.orderXS + ((qtyTotal / 100) * value.orderXS)) : 0
                            value['finalNeedXS'] = Math.ceil(value['average'] * total)
                            value['finalNeedXS'] = value['finalNeedXS']// - value['inHouseXS']
                            data.rows[key]['Xs Final Need'] = value['finalNeedXS'] ///- value['inHouseXS']
                        } if (e.target.name === 'addQTYM' || e.target.name === 'average') {
                            let qtyTotal = value['addQTYM'] ? parseFloat(value['addQTYM']) : 0
                            let total = value['addQTYM'] ? Math.ceil(value.orderM + ((qtyTotal / 100) * value.orderM)):0
                            let totalNeed = Math.ceil(value['average'] * total)
                            value['finalNeedM'] = totalNeed //- value['inHouseM']
                            data.rows[key]['M Final Need'] = totalNeed //- value['inHouseM']
                        } if (e.target.name === 'addQTYL' || e.target.name === 'average') {
                            let qtyTotal = value['addQTYL'] ? parseFloat(value['addQTYL']) : 0
                            let total = value['addQTYL'] ? Math.ceil(value.orderL + ((qtyTotal / 100) * value.orderL)):0
                            let totalNeed = Math.ceil(value['average'] * total)
                            value['finalNeedL'] = totalNeed //- value['inHouseL']
                            data.rows[key]['L Final Need'] = totalNeed //- value['inHouseL']
                        } if (e.target.name === 'addQTYXL' || e.target.name === 'average') {
                            let qtyTotal = value['addQTYXL'] ? parseFloat(value['addQTYXL']) : 0
                            let total = value['addQTYXL'] ? Math.ceil(value.orderXL + ((qtyTotal / 100) * value.orderXL)):0
                            let totalNeed = Math.ceil(value['average'] * total)
                            value['finalNeedXL'] = totalNeed //- value['inHouseXL']
                            data.rows[key]['Xl Final Need'] = totalNeed //- value['inHouseXL']
                        } if (e.target.name === 'addQTYXXL' || e.target.name === 'average') {
                            let qtyTotal = value['addQTYXXL'] ? parseFloat(value['addQTYXXL']) : 0
                            let total = value['addQTYXXL'] ? Math.ceil(value.orderXXl + ((qtyTotal / 100) * value.orderXXl)):0
                            let totalNeed = Math.ceil(value['average'] * total)
                            value['finalNeedXXL'] = totalNeed //- value['inHouseXXL']
                            data.rows[key]['Xxl Final Need'] = totalNeed //- value['inHouseXXL']
                        } if (e.target.name === 'addQTY3XL' || e.target.name === 'average') {
                            let qtyTotal = value['addQTY3XL'] ? parseFloat(value['addQTY3XL']) : 0
                            let total = value['addQTY3XL'] ? Math.ceil(value.order3XL + ((qtyTotal / 100) * value.order3XL)):0
                            let totalNeed = Math.ceil(value['average'] * total)
                            value['finalNeed3XL'] = totalNeed// - value['inHouse3XL']
                            data.rows[key]['3xl Final Need'] = totalNeed //- value['inHouse3XL']
                        } if (e.target.name === 'addQTY4XL' || e.target.name === 'average') {
                            let qtyTotal = value['addQTY4XL'] ? parseFloat(value['addQTY4XL']) : 0
                            let total = value['addQTY4XL'] ? Math.ceil(value.order4XL + ((qtyTotal / 100) * value.order4XL)) : 0
                            let totalNeed = Math.ceil(value['average'] * total)
                            value['finalNeed4XL'] = totalNeed //- value['inHouse4XL']
                            data.rows[key]['4xl Final Need'] = totalNeed //- value['inHouse4XL']
                        } if (e.target.name === 'addQTY5XL' || e.target.name === 'average') {
                            let qtyTotal = value['addQTY5XL'] ? parseFloat(value['addQTY5XL']) : 0
                            let total = value['addQTY5XL'] ? Math.ceil(value.order5Xl + ((qtyTotal / 100) * value.order5Xl)):0
                            let totalNeed = Math.ceil(value['average'] * total)
                            value['finalNeed5XL'] = totalNeed //- value['inHouse5XL']
                            data.rows[key]['5xl Final Need'] = totalNeed //- value['inHouse5XL']
                        }
                    }
                }
            }
            return value
        })
        var row = data.rows.map((value, key) => {
            if (materialSet.length)
                this.valueEdit(rowId, key, rows, value, suppliers, materialSet)
            else
                this.valueEdit(rowId, key, rows, value, suppliers, materialList)
            return value;
        })
        this.setState({
            data: {
                ...this.state.data,
                rows: row
            },
            rows: dataRows,
        })
        var varientDetail = ''
        varient.map(items => {
            if (rows[rowId][items.name] != null && rows[rowId][items.name] != '') {
                varientDetail = varientDetail + items.name + ':' + rows[rowId][items.name] + ',';
            }
        })
        this.props.getRatioInhouseStocks({
            materialId: rows[rowId].materialId,
            varients: 'size:,' + varientDetail.substring(0, varientDetail.length - 1),
            key: rowId,
            quantity: rows[rowId].finalNeedS ? rows[rowId].finalNeedS : 0,
            inhouseId: rows[rowId].newStock,
            oldQuantity: rows[rowId].inHouseS,
            orderid: rows[rowId].orderid ? rows[rowId].orderid : undefined
        })
    }
    onEdit = (event, rowId) => {
        let { rows, data, suppliers,supllierDetail } = this.state
        let material = []
        supllierDetail&&supllierDetail.map((val, keys) => {
            if(keys === rowId && val !==null){
                let suppId = rows[rowId]['supplier'] ? parseInt(rows[rowId]['supplier']) : ''
                if (val.id === suppId) {
                    material[0] = val.suppliermaterial 
                }
            }
        })
        var row = data.rows.map((value, key) => {
            this.valueEdit(rowId, key, rows, value, suppliers, material)
            return value;
        })
        var rowList = rows.map((val, key) => {
            if (key === rowId) {
                val['updateClass'] = 'editRow'
            }
            return val
        })
        this.setState({
            data: {
                ...this.state.data,
                rows: row
            },
            materialList:material,
            rows: rowList
        })
    }
    onSubmit = (event, rowId) => {
        const { varient, brandOrderId, pageNumber, search, slug, rows, data } = this.state
        var row = data.rows.map((value, key) => {
            this.valueUpdate(rows, rowId, value, key)
            return value;
        })
        this.setState({
            data: {
                ...this.state.data,
                rows: row
            }
        })
        var dataPic, id, dataName = {}
        rows.map((val, key) => {
            if (key === rowId) {
                let datainner = []
                datainner['ratiovarients'] = []
                varient.map((items, i) => {
                    datainner['ratiovarients'].push({ id: items.id, value: val[items.name] !== '' ? val[items.name] : null })
                    return true;
                })
                dataName = {
                    "orderid": val.orderid ? val.orderid : undefined,
                    "supplierId": val.supplier && val.supplier !== '' ? val.supplier : '',
                    "brandOrderId": brandOrderId !== '' ? brandOrderId : '',
                    "orderDescId": val.orderDescId,
                    "materialId": val.materialId !== '' ? val.materialId : '',
                    'addQTYXS': val.addQTYXS != '' ? val.addQTYXS : '',
                    'inHouseXS': val.inHouseXS != '' ? val.inHouseXS : '',
                    'inhouseIdXS': val.inhouseIdXS != '' ? val.inhouseIdXS : null,
                    'finalNeedXS': val.finalNeedXS != '' ? `${val.finalNeedXS}` : '',
                    'addQTYS': val.addQTYS != '' ? val.addQTYS : '',
                    'inHouseS': val.inHouseS != '' ? val.inHouseS : '',
                    'inhouseIdS': val.inhouseIdS != '' ? val.inhouseIdS : null,
                    'finalNeedS': val.finalNeedS != '' ? `${val.finalNeedS}` : '',
                    'addQTYM': val.addQTYM != '' ? val.addQTYM : '',
                    'inHouseM': val.inHouseM != '' ? val.inHouseM : '',
                    'inhouseIdM': val.inhouseIdM != '' ? val.inhouseIdM : null,
                    'finalNeedM': val.finalNeedM != '' ? `${val.finalNeedM}` : '',
                    'addQTYL': val.addQTYL != '' ? val.addQTYL : '',
                    'inHouseL': val.inHouseL != '' ? val.inHouseL : '',
                    'inhouseIdL': val.inhouseIdL != '' ? val.inhouseIdL : null,
                    'finalNeedL': val.finalNeedL != '' ? `${val.finalNeedL}` : '',
                    'addQTYXL': val.addQTYXL != '' ? val.addQTYXL : '',
                    'inHouseXL': val.inHouseXL != '' ? val.inHouseXL : '',
                    'inhouseIdXL': val.inhouseIdXL != '' ? val.inhouseIdXL : null,
                    'finalNeedXL': val.finalNeedXL != '' ? `${val.finalNeedXL}` : '',
                    'addQTYXXL': val.addQTYXXL != '' ? val.addQTYXXL : '',
                    'inHouseXXL': val.inHouseXXL != '' ? val.inHouseXXL : '',
                    'inhouseIdXXL': val.inhouseIdXXL != '' ? val.inhouseIdXXL : null,
                    'finalNeedXXL': val.finalNeedXXL != '' ? `${val.finalNeedXXL}` : '',
                    'addQTY3XL': val.addQTY3XL != '' ? val.addQTY3XL : '',
                    'inHouse3XL': val.inHouse3XL != '' ? val.inHouse3XL : '',
                    'inhouseId3XL': val.inhouseId3XL != '' ? val.inhouseId3XL : null,
                    'inhouseId3XL': val.inhouseId3XL != '' ? val.inhouseId3XL : null,
                    'finalNeed3XL': val.finalNeed3XL != '' ? `${val.finalNeed3XL}` : '',
                    'addQTY4XL': val.addQTY4XL != '' ? val.addQTY4XL : '',
                    'inHouse4XL': val.inHouse4XL != '' ? val.inHouse4XL : '',
                    'inhouseId4XL': val.inhouseId4XL != '' ? val.inhouseId4XL : null,
                    'finalNeed4XL': val.finalNeed4XL != '' ? `${val.finalNeed4XL}` : '',
                    'addQTY5XL': val.addQTY5XL != '' ? val.addQTY5XL : '',
                    'inHouse5XL': val.inHouse5XL != '' ? val.inHouse5XL : '',
                    'inhouseId5XL': val.inhouseId5XL != '' ? val.inhouseId5XL : null,
                    'finalNeed5XL': val.finalNeed5XL != '' ? `${val.finalNeed5XL}` : '',
                    "average": val.average !== '' ? val.average : '',
                    ratiovarients: val.picture !== '' && val.picture != null ? datainner['ratiovarients'] : datainner['ratiovarients'],
                    "price": val.price !== '' ? `${val.price}` : '',
                    "deliveryDate": val.deliveryDate !== '' ? val.deliveryDate : '',
                    "remarks": val.Remarks !== '' ? val.Remarks : '',
                    "recdStatus": val.recdStatus !== '' ? val.recdStatus : '',
                    "pendingStatus": val.pendingStatus !== '' ? val.pendingStatus : '',
                    oldStock: val.oldStock !== '' ? val.oldStock : '',
                    newStock: val.newStock !== '' ? val.newStock : '',
                }
                id = { "orderid": val.orderid ? val.orderid : undefined, }
                dataPic = {
                    "brandOrderId": brandOrderId,
                    "orderDescId": val.orderDescId,
                    "picture": val.picture !== '' ? val.picture : '',
                    'slug': slug,
                    search: search,
                    pageNumber: pageNumber
                }
            }
        })
        this.props.ratioAdd({ ...dataName, search, pageNumber, slug }, dataPic)
        // this.props.addRationPicture(id, dataPic);
    }
    onAdd = (event, rowId) => {
        let rows = this.state.data.rows
        const { varient } = this.state
        let row = []
        rows.map((value, key) => {
            var abc = {}
            value['Action'] = [<div className="d-flex"><i title="Edit" className="disabledAction fas fa-pen pr-2 cursor-pointer" key={'abc' + key + 1}></i><i title="Add Row" className="disabledAction fas fa-plus-circle cursor-pointer" key={'abce' + key + 1} ></i><i key={'abcr' + key + 1} title="View" className="disabledAction fas fa-eye cursor-pointer" ></i><input style={{ minWidth: '15px', marginLeft: '5px' }} type="checkbox" value={this.state.rows[key]['orderid']} name="poGenrate" disabled key={key + 1} onChange={e => (this.listPoGenrate(e, key + 1))} /></div>]
            if (key == rowId) {
                abc['Action'] = [<i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key + 1))} key={'abc' + key + 1}></i>, <i title="Delete" className="fas fa-minus-circle cursor-pointer" onClick={e => (this.onDelete(e, key + 1))} key={'abcio' + key + 1}></i>]
                abc['Sr No'] = this.state.data.rows[key]['Sr No'] + 1
                abc['Style No'] = value['Style No']
                abc['Style Code'] = value['Style Code']
                abc['Supplier'] = ''
                abc['Trim Desc'] = ''
                abc['Average'] = ''
                abc['Xs Add Qtty %'] = ''
                abc['Xs Inhouse'] = 0
                abc['Xs Final Need'] = ''
                abc['S Add Qtty %'] = ''
                abc['S Inhouse'] = 0
                abc['S Final Need'] = ''
                abc['M Add Qtty %'] = ''
                abc['M Inhouse'] = 0
                abc['M Final Need'] = ''
                abc['L Add Qtty %'] = ''
                abc['L Inhouse'] = 0
                abc['L Final Need'] = ''
                abc['Xl Add Qtty %'] = ''
                abc['Xl Inhouse'] = 0
                abc['Xl Final Need'] = ''
                abc['Xxl Add Qtty %'] = ''
                abc['Xxl Inhouse'] = 0
                abc['Xxl Final Need'] = ''
                abc['3xl Add Qtty %'] = ''
                abc['3xl Inhouse'] = 0
                abc['3xl Final Need'] = ''
                abc['4xl Add Qtty %'] = ''
                abc['4xl Inhouse'] = 0
                abc['4xl Final Need'] = ''
                abc['5xl Add Qtty %'] = ''
                abc['5xl Inhouse'] = 0
                abc['5xl Final Need'] = ''
                abc['Image'] = ''
                varient.map(items => {
                    abc[items.field] = ''
                })
                abc['Price'] = ''
                abc['Delivery'] = ''
                abc['Remarks'] = ''
                abc['po status'] = <>{value.poStatus === 'Generated' || value.poStatus === 'Regenerated' ? <i class="fas fa-check" title={value.poStatus} style={{ color: '#4dba4f' }}></i> : <><i style={{ color: '#f15d5d' }} class="far fa-clock" title='Pending'></i></>}</>
                // abc['recd status'] = ''
                // abc['pending status'] = ''
            }
            if (key >= rowId + 1) {
                abc = {}
                value['Sr No'] = this.state.data.rows[key]['Sr No'] + 1
                value['Action'] = [<div className="d-flex"><i title="Edit" className="disabledAction fas fa-pen pr-2 cursor-pointer" key={'abc' + key + 1}></i><i title="Add Row" className="disabledAction fas fa-plus-circle cursor-pointer" key={'abce' + key + 1} ></i><i key={'abcr' + key + 1} title="View" className="disabledAction fas fa-eye cursor-pointer" ></i><input style={{ minWidth: '15px', marginLeft: '5px' }} type="checkbox" value={this.state.rows[key]['orderid']} name="poGenrate" disabled key={key + 1} onChange={e => (this.listPoGenrate(e, key + 1))} /></div>]
            }
            row.push(value);
            if (abc && Object.keys(abc).length)
                row.push(abc);
        })
        let updatedRow = this.state.rows
        let data1 = []
        updatedRow && updatedRow.map((value, key) => {
            var abc = {}
            if (key == rowId) {
                abc.deliveryDate = ""
                abc.Remarks = ""
                abc.orderDescId = value.orderDescId
                abc.brandOrderId = value.brandOrderId
                abc.orderid = null
                abc.orderXS = value.orderXS
                abc.orderS = value.orderS
                abc.orderM = value.orderM
                abc.orderL = value.orderL
                abc.orderXL = value.orderXL
                abc.orderXXl = value.orderXXl
                abc.order3XL = value.order3XL
                abc.order4XL = value.order4XL
                abc.order5Xl = value.order5Xl
                abc.supplier = ''
                abc.average = ''
                abc.materialId = ''
                abc.addQTYXS = ''
                abc.inHouseXS = 0
                abc.finalNeedXS = ''
                abc.addQTYS = ''
                abc.inHouseS = 0
                abc.finalNeedS = ''
                abc.addQTYM = ''
                abc.inHouseM = 0
                abc.finalNeedM = ''
                abc.addQTYL = ''
                abc.inHouseL = 0
                abc.finalNeedL = ''
                abc.addQTYXL = ''
                abc.inHouseXL = 0
                abc.finalNeedXL = ''
                abc.addQTYXXL = ''
                abc.inHouseXXL = 0
                abc.finalNeedXXL = ''
                abc.addQTY3XL = ''
                abc.inHouse3XL = 0
                abc.finalNeedXXL = ''
                abc.addQTY4XL = ''
                abc.inHouse4XL = 0
                abc.finalNeed4XL = ''
                abc.addQTY5XL = ''
                abc.inHouse5XL = 0
                abc.finalNeed5XL = ''
                varient.map(items => {
                    abc[items.name] = ''
                })
                abc.picture = ""
                abc.pendingStatus = ""
                abc.poStatus = "Pending"
                abc.price = ""
                abc.recdStatus = ""
            }
            data1.push(value);
            if (abc && Object.keys(abc).length)
                data1.push(abc);
        })
        this.setState({
            data: {
                ...this.state.data,
                rows: row
            },
            rows: data1
        })
    }
    onDelete = (event, rowId) => {
        const { slug, brandOrderId, pageNumber, search } = this.state
        let rows = this.state.data.rows
        let row = []
        rows.map((value, key) => {
            if (key !== rowId) {
                if (key >= rowId + 1) {
                    let poSucc = this.state.rows[key]['addQTYS'] != null && this.state.rows[key]['addQTYXS'] != null && this.state.rows[key]['addQTYM'] != null && this.state.rows[key]['addQTYL'] != null && this.state.rows[key]['addQTYXL'] != null && this.state.rows[key]['addQTYXXL'] != null && this.state.rows[key]['addQTY3XL'] != null && this.state.rows[key]['addQTY4XL'] != null && this.state.rows[key]['addQTY4XL'] != null && this.state.rows[key]['ratioSupllier'] != null && this.state.rows[key]['ratioMaterial'] != null && this.state.rows[key]['average'] != null && this.state.rows[key]['deliveryDate'] != null ? false : true
                    value['Sr No'] = this.state.data.rows[key]['Sr No'] - 1
                    value['Action'] = [
                        <i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i>, <i title="Add Row" className="fas fa-plus-circle cursor-pointer" key={'abce' + key} onClick={e => (this.onAdd(e, key))}></i>, <i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, this.state.rows[key]['orderid'], poSucc))} ></i>, <input type="checkbox" value={this.state.rows[key]['orderid']} name="poGenrate" disabled={poSucc} key={'check' + key} onChange={e => (this.listPoGenrate(e, key))} />
                    ]
                }
                row.push(value);
            }
            return true
        })

        let updatedRow = this.state.rows
        let data1 = []

        updatedRow && updatedRow.map((value, key) => {
            if (key !== rowId) {
                data1.push(value);
            } return true;
        })
        this.setState({
            data: {
                ...this.state.data,
                rows: row
            },
            rows: data1
        })
        this.props.ratioGet({ brandOrderId, pageNumber, slug, search })
    }
    componentWillReceiveProps(newProps) {
        const { brandorder, varientList, ratio, supplierSuccess, poGenrateSuccess, noteSuccess } = newProps
        const { brandOrderId, pageNumber, search, slug } = this.state
        if (noteSuccess && noteSuccess.success === true) {
            newProps.ratioGet({ brandOrderId, pageNumber, slug, search })
            this.setState({
                open: false,
                loaderTable: true,
            })
        }
        var varient = []
        if (brandorder && brandorder.success) {
            let brand = brandorder.order && brandorder.order.brandsId
            this.setState({
                brand: brand && brand.name,
                brandImg: brand && brand.logo,
                name: brandorder.order && brandorder.order.name
            })
        }
        if (supplierSuccess && supplierSuccess.success) {
            this.setState({
                suppliers: supplierSuccess.suppliers
            })
        }
        if (poGenrateSuccess && poGenrateSuccess.success) {
            if (poGenrateSuccess.message) {
                if (!toast.isActive(toastId))
                    toastId = toast.success(poGenrateSuccess.message)
            }
            this.setState({
                loaderPo: false,
                selectPoList: { id: [] }
            })
            this.props.ratioGet({ brandOrderId, pageNumber, search, slug });
            this.props.initialState();
        } else if (poGenrateSuccess.success === false) {
            if (poGenrateSuccess.message) {
                if (!toast.isActive(toastId))
                    toastId = toast.error(poGenrateSuccess.message)
            }
            this.setState({
                loaderPo: false
            })
            this.props.initialState();
        }
        if (varientList.success) {
            varientList.varient && varientList.varient.map((value, key) => {
                let assignedVarient = value.assignedVarient && value.assignedVarient[0].value
                varient.push({ label: value.name, field: value.name, name: value.slug, id: value.id, assignedVarient: assignedVarient })
            })
            console.log(varient)
            this.setState({
                data: {
                    ...this.state.data,
                    columns: [
                        { label: "Action", field: "Action" },
                        { label: "#", field: "Sr No" },
                        { label: 'Style No', field: 'Style No' },
                        { label: 'Style Code', field: 'Style Code' },
                        { label: "Supplier", field: "Supplier", type: "numeric", class: 'selectList' },
                        { label: 'Trim Desc', field: 'Trim Desc', class: 'selectList' },
                        { label: 'Average', field: 'Average' },
                        { label: "Add Qty% (XS)", field: "Xs Add Qtty %", type: "numeric" },
                        { label: 'Inhouse (XS)', field: 'Xs Inhouse' },
                        { label: 'Final Need (XS)', field: 'Xs Final Need' },
                        { label: "Add Qty% (S)", field: "S Add Qtty %", type: "numeric" },
                        { label: 'Inhouse (S)', field: 'S Inhouse' },
                        { label: 'Final Need (S)', field: 'S Final Need' },
                        { label: "Add Qty% (M)", field: "M Add Qtty %", type: "numeric" },
                        { label: 'Inhouse (M)', field: 'M Inhouse' },
                        { label: 'Final Need (M)', field: 'M Final Need' },
                        { label: "Add Qty% (L)", field: "L Add Qtty %", type: "numeric" },
                        { label: 'Inhouse (L)', field: 'L Inhouse' },
                        { label: 'Final Need (L)', field: 'L Final Need' },
                        { label: "Add Qty% (XL)", field: "Xl Add Qtty %", type: "numeric" },
                        { label: 'Inhouse (XL)', field: 'Xl Inhouse' },
                        { label: 'Final Need (XL)', field: 'Xl Final Need' },
                        { label: "Add Qty% (XXL)", field: "Xxl Add Qtty %", type: "numeric" },
                        { label: 'Inhouse (XXL)', field: 'Xxl Inhouse' },
                        { label: 'Final Need (XXL)', field: 'Xxl Final Need' },
                        { label: "Add Qty% (3XL)", field: "3xl Add Qtty %", type: "numeric" },
                        { label: 'Inhouse (3XL)', field: '3xl Inhouse' },
                        { label: 'Final Need (3XL)', field: '3xl Final Need' },
                        { label: "Add Qty% (4XL)", field: "4xl Add Qtty %", type: "numeric" },
                        { label: 'Inhouse (4XL)', field: '4xl Inhouse' },
                        { label: 'Final Need (4XL)', field: '4xl Final Need' },
                        { label: "Add Qty% (5XL)", field: "5xl Add Qtty %", type: "numeric" },
                        { label: 'Inhouse (5XL)', field: '5xl Inhouse' },
                        { label: 'Final Need (5XL)', field: '5xl Final Need' },
                        { label: 'Image', field: 'Image' },
                        ...varient,
                        { label: 'Price', field: 'Price' },
                        { label: "Delivery", field: "Delivery" },
                        { label: 'Remarks', field: 'Remarks' },
                        { label: 'PO Status', field: 'po status' },
                        // { label: 'recd status', field: 'recd status' },
                        // { label: 'pending status', field: 'pending status' }
                    ]
                },
                loaderVarient: false,
                loaderTable: true,
                varient: varient
            })
            this.props.varienInitial()
            const { slug, brandOrderId, pageNumber, search } = this.state
            this.props.ratioSupplier()
            this.props.getBrandOrder({ brandOrderId, pageNumber })
            this.props.ratioGet({ brandOrderId, pageNumber, slug, search })
        } else {
            this.setState({
                loaderVarient: false,
            })
        }
        if (newProps.inhousestock && (newProps.inhousestock.message || newProps.inhousestock.success)) {
            if (newProps.inhousestock.success === true) {
                var inhouse = newProps.inhousestock && newProps.inhousestock.inhouse
                let dataa = this.state.data.rows.map((val, keys) => {
                    if (keys == newProps.inhousestock.key) {
                        for (let vals of inhouse) {
                            let size = vals.inhouse.varients.split(",")[0].split(":")[1]
                            if (size === 'xs') {
                                if (this.state.rows[keys]['average'] != '') {
                                    let total = Math.ceil(this.state.rows[keys].orderXS + ((parseFloat(this.state.rows[keys]['addQTYXS'] != '' ? this.state.rows[keys]['addQTYXS'] : 0) / 100) * this.state.rows[keys].orderXS))
                                    var totalNeed = Math.ceil(this.state.rows[keys]['average'] * total)
                                }
                                let TotalNeedQty = totalNeed ? parseInt(totalNeed) : 0
                                val['Xs Inhouse'] = TotalNeedQty <= vals.quantity ? (TotalNeedQty) : vals.quantity
                                val['Xs Final Need'] = TotalNeedQty >= val['Xs Inhouse'] ? (TotalNeedQty - val['Xs Inhouse']) : 0
                                newProps.initialState();
                            } if (size === 's') {
                                if (this.state.rows[keys]['average'] != '') {
                                    let total = Math.ceil(this.state.rows[keys].orderS + ((parseFloat(this.state.rows[keys]['addQTYS'] != '' ? this.state.rows[keys]['addQTYS'] : 0) / 100) * this.state.rows[keys].orderS))
                                    var totalNeed = Math.ceil(this.state.rows[keys]['average'] * total)
                                }
                                let TotalNeedQty = totalNeed ? parseInt(totalNeed) : 0
                                val['S Inhouse'] = TotalNeedQty > vals.quantity ? vals.quantity : TotalNeedQty
                                val['S Final Need'] = TotalNeedQty > val['S Inhouse'] ? (TotalNeedQty - val['S Inhouse']) : 0
                                newProps.initialState();
                            } if (size === 'm') {
                                if (this.state.rows[keys]['average'] != '') {
                                    let total = Math.ceil(this.state.rows[keys].orderM + ((parseFloat(this.state.rows[keys]['addQTYM'] != '' ? this.state.rows[keys]['addQTYM'] : 0) / 100) * this.state.rows[keys].orderM))
                                    var totalNeed = Math.ceil(this.state.rows[keys]['average'] * total)
                                }
                                let TotalNeedQty = totalNeed ? parseInt(totalNeed) : 0
                                val['M Inhouse'] = TotalNeedQty > vals.quantity ? vals.quantity : TotalNeedQty
                                val['M Final Need'] = TotalNeedQty > val['M Inhouse'] ? (TotalNeedQty - val['M Inhouse']) : 0
                                newProps.initialState();
                            } if (size === 'l') {
                                if (this.state.rows[keys]['average'] != '') {
                                    let total = Math.ceil(this.state.rows[keys].orderL + ((parseFloat(this.state.rows[keys]['addQTYL'] != '' ? this.state.rows[keys]['addQTYL'] : 0) / 100) * this.state.rows[keys].orderL))
                                    var totalNeed = Math.ceil(this.state.rows[keys]['average'] * total)
                                }
                                let TotalNeedQty = totalNeed ? parseInt(totalNeed) : 0
                                val['L Inhouse'] = TotalNeedQty > vals.quantity ? vals.quantity : TotalNeedQty
                                val['L Final Need'] = TotalNeedQty > val['L Inhouse'] ? (TotalNeedQty - val['L Inhouse']) : 0
                                newProps.initialState();
                            } if (size === 'xl') {
                                if (this.state.rows[keys]['average'] != '') {
                                    let total = Math.ceil(this.state.rows[keys].orderXL + ((parseFloat(this.state.rows[keys]['addQTYXL'] != '' ? this.state.rows[keys]['addQTYXL'] : 0) / 100) * this.state.rows[keys].orderXL))
                                    var totalNeed = Math.ceil(this.state.rows[keys]['average'] * total)
                                }
                                let TotalNeedQty = totalNeed ? parseInt(totalNeed) : 0
                                val['Xl Inhouse'] = TotalNeedQty > vals.quantity ? vals.quantity : TotalNeedQty
                                val['Xl Final Need'] = TotalNeedQty > val['Xl Inhouse'] ? (TotalNeedQty - val['Xl Inhouse']) : 0
                                newProps.initialState();
                            } if (size === 'xxl') {
                                if (this.state.rows[keys]['average'] != '') {
                                    let total = Math.ceil(this.state.rows[keys].orderXXl + ((parseFloat(this.state.rows[keys]['addQTYXXL'] != '' ? this.state.rows[keys]['addQTYXXL'] : 0) / 100) * this.state.rows[keys].orderXXl))
                                    var totalNeed = Math.ceil(this.state.rows[keys]['average'] * total)
                                }
                                let TotalNeedQty = totalNeed ? parseInt(totalNeed) : 0
                                val['Xxl Inhouse'] = TotalNeedQty > vals.quantity ? vals.quantity : TotalNeedQty
                                val['Xxl Final Need'] = TotalNeedQty > val['Xxl Inhouse'] ? (TotalNeedQty - val['Xxl Inhouse']) : 0
                                newProps.initialState();
                            } if (size === '3xl') {
                                if (this.state.rows[keys]['average'] != '') {
                                    let total = Math.ceil(this.state.rows[keys].order3XL + ((parseFloat(this.state.rows[keys]['addQTY3XL'] != '' ? this.state.rows[keys]['addQTY3XL'] : 0) / 100) * this.state.rows[keys].order3XL))
                                    var totalNeed = Math.ceil(this.state.rows[keys]['average'] * total)
                                }
                                let TotalNeedQty = totalNeed ? parseInt(totalNeed) : 0
                                val['3xl Inhouse'] = TotalNeedQty > vals.quantity ? vals.quantity : TotalNeedQty
                                val['3xl Final Need'] = TotalNeedQty > val['3xl Inhouse'] ? (TotalNeedQty - val['3xl Inhouse']) : 0
                                newProps.initialState();
                            } if (size === '4xl') {
                                if (this.state.rows[keys]['average'] != '') {
                                    let total = Math.ceil(this.state.rows[keys].order4XL + ((parseFloat(this.state.rows[keys]['addQTY4XL'] != '' ? this.state.rows[keys]['addQTY4XL'] : 0) / 100) * this.state.rows[keys].order4XL))
                                    var totalNeed = Math.ceil(this.state.rows[keys]['average'] * total)
                                }
                                let TotalNeedQty = totalNeed ? parseInt(totalNeed) : 0
                                val['4xl Inhouse'] = TotalNeedQty > vals.quantity ? vals.quantity : TotalNeedQty
                                val['4xl Final Need'] = TotalNeedQty > val['4xl Inhouse'] ? (TotalNeedQty - val['4xl Inhouse']) : 0
                                newProps.initialState();
                            } if (size === '5xl') {
                                if (this.state.rows[keys]['average'] != '') {
                                    let total = Math.ceil(this.state.rows[keys].order5XL + ((parseFloat(this.state.rows[keys]['addQTY5XL'] != '' ? this.state.rows[keys]['addQTY5XL'] : 0) / 100) * this.state.rows[keys].order5XL))
                                    var totalNeed = Math.ceil(this.state.rows[keys]['average'] * total)
                                }
                                let TotalNeedQty = totalNeed ? parseInt(totalNeed) : 0
                                val['5xl Inhouse'] = TotalNeedQty > vals.quantity ? vals.quantity : TotalNeedQty
                                val['5xl Final Need'] = TotalNeedQty > val['5xl Inhouse'] ? (TotalNeedQty - val['5xl Inhouse']) : 0
                                newProps.initialState();
                            }
                        }
                    }
                    return val
                })
                let data1 = this.state.rows.map((val, keys) => {
                    if (keys == newProps.inhousestock.key) {
                        for (let vals of inhouse) {
                            let size = vals.inhouse.varients.split(",")[0].split(":")[1]
                            if (size === 'xs') {
                                if (val['average'] != '') {
                                    let total = Math.ceil(val.orderXS + ((parseFloat(val['addQTYXS'] != '' ? val['addQTYXS'] : 0) / 100) * val.orderXS))
                                    var totalNeed = Math.ceil(val['average'] * total)
                                }
                                let TotalNeedQty = totalNeed ? parseInt(totalNeed) : 0
                                val['inHouseXS'] = TotalNeedQty < vals.quantity ? (TotalNeedQty) : vals.quantity
                                val['finalNeedXS'] = TotalNeedQty > val['inHouseXS'] ? (TotalNeedQty - val['inHouseXS']) : (val['inHouseXS'] - TotalNeedQty)
                                val.inhouseIdXS = vals.inhouse.id
                                newProps.initialState();
                            } if (size === 's') {
                                if (val['average'] != '') {
                                    let total = Math.ceil(val.orderS + ((parseFloat(val['addQTYS'] != '' ? val['addQTYS'] : 0) / 100) * val.orderS))
                                    var totalNeed = Math.ceil(val['average'] * total)
                                }
                                let TotalNeedQty = totalNeed ? parseInt(totalNeed) : 0
                                val['inHouseS'] = TotalNeedQty > vals.quantity ? vals.quantity : TotalNeedQty
                                val['finalNeedS'] = TotalNeedQty > val['inHouseS'] ? (TotalNeedQty - val['inHouseS']) : 0
                                val.inhouseIdS = vals.inhouse.id
                                newProps.initialState();
                            } if (size === 'm') {
                                if (val['average'] != '') {
                                    let total = Math.ceil(val.orderM + ((parseFloat(val['addQTYM'] != '' ? val['addQTYM'] : 0) / 100) * val.orderM))
                                    var totalNeed = Math.ceil(val['average'] * total)
                                }
                                let TotalNeedQty = totalNeed ? parseInt(totalNeed) : 0
                                val['inHouseM'] = TotalNeedQty > vals.quantity ? vals.quantity : TotalNeedQty
                                val['finalNeedM'] = TotalNeedQty > val['inHouseM'] ? (TotalNeedQty - val['inHouseM']) : 0
                                val.inhouseIdM = vals.inhouse.id
                                newProps.initialState();
                            } if (size === 'l') {
                                if (val['average'] != '') {
                                    let total = Math.ceil(val.orderL + ((parseFloat(val['addQTYL'] != '' ? val['addQTYL'] : 0) / 100) * val.orderL))
                                    var totalNeed = Math.ceil(val['average'] * total)
                                }
                                let TotalNeedQty = totalNeed ? parseInt(totalNeed) : 0
                                val['inHouseL'] = TotalNeedQty > vals.quantity ? vals.quantity : TotalNeedQty
                                val['finalNeedL'] = TotalNeedQty > val['inHouseL'] ? (TotalNeedQty - val['inHouseL']) : 0
                                val.inhouseIdL = vals.inhouse.id
                                newProps.initialState();
                            } if (size === 'xl') {
                                if (val['average'] != '') {
                                    let total = Math.ceil(val.orderXL + ((parseFloat(val['addQTYXL'] != '' ? val['addQTYXL'] : 0) / 100) * val.orderXL))
                                    var totalNeed = Math.ceil(val['average'] * total)
                                }
                                let TotalNeedQty = totalNeed ? parseInt(totalNeed) : 0
                                val['inHouseXL'] = TotalNeedQty > vals.quantity ? vals.quantity : TotalNeedQty
                                val['finalNeedXL'] = TotalNeedQty > val['inHouseXL'] ? (TotalNeedQty - val['inHouseXL']) : 0
                                val.inhouseIdXL = vals.inhouse.id
                                newProps.initialState();
                            } if (size === 'xxl') {
                                if (val['average'] != '') {
                                    var total = Math.ceil(val.orderXXl + ((parseFloat(val['addQTYXXL'] != '' ? val['addQTYXXL'] : 0) / 100) * val.orderXXl))
                                    var totalNeed = Math.ceil(val['average'] * total)
                                }
                                let TotalNeedQty = totalNeed ? parseInt(totalNeed) : 0
                                val['inHouseXXL'] = TotalNeedQty > vals.quantity ? vals.quantity : TotalNeedQty
                                val['finalNeedXXL'] = TotalNeedQty > val['inHouseXXL'] ? (TotalNeedQty - val['inHouseXXL']) : 0
                                val.inhouseIdXXL = vals.inhouse.id
                                newProps.initialState();
                            } if (size === '3xl') {
                                if (val['average'] != '') {
                                    let total = Math.ceil(val.order3XL + ((parseFloat(val['addQTY3XL'] != '' ? val['addQTY3XL'] : 0) / 100) * val.order3XL))
                                    var totalNeed = Math.ceil(val['average'] * total)
                                }
                                let TotalNeedQty = totalNeed ? parseInt(totalNeed) : 0
                                val['inHouse3XL'] = TotalNeedQty > vals.quantity ? vals.quantity : TotalNeedQty
                                val['finalNeed3XL'] = TotalNeedQty > val['inHouse3XL'] ? (TotalNeedQty - val['inHouse3XL']) : 0
                                val.inhouseId3XL = vals.inhouse.id
                                newProps.initialState();
                            } if (size === '4xl') {
                                if (val['average'] != '') {
                                    let total = Math.ceil(val.order4XL + ((parseFloat(val['addQTY4XL'] != '' ? val['addQTY4XL'] : 0) / 100) * val.order4XL))
                                    var totalNeed = Math.ceil(val['average'] * total)
                                }
                                let TotalNeedQty = totalNeed ? parseInt(totalNeed) : 0
                                val['inHouse4XL'] = TotalNeedQty > vals.quantity ? vals.quantity : TotalNeedQty
                                val['finalNeed4XL'] = TotalNeedQty > val['inHouse4XL'] ? (TotalNeedQty - val['inHouse4XL']) : 0
                                val.inhouseId4XL = vals.inhouse.id
                                newProps.initialState();
                            } if (size === '5xl') {
                                if (val['average'] != '') {
                                    let total = Math.ceil(val.order5XL + ((parseFloat(val['addQTY5XL'] != '' ? val['addQTY5XL'] : 0) / 100) * val.order5XL))
                                    var totalNeed = Math.ceil(val['average'] * total)
                                }
                                let TotalNeedQty = totalNeed ? parseInt(totalNeed) : 0
                                val['inHouse5XL'] = TotalNeedQty > vals.quantity ? vals.quantity : TotalNeedQty
                                val['finalNeed5XL'] = TotalNeedQty > val['inHouse5XL'] ? (TotalNeedQty - val['inHouse5XL']) : 0
                                val.inhouseId5XL = vals.inhouse.id
                                newProps.initialState();
                            }
                        }
                    }
                    return val
                })
                this.setState({
                    ...this.state,
                    data: {
                        ...this.state.data,
                        rows: dataa
                    },
                    rows: data1,
                    inhouse: inhouse,
                })
            } else {
                var inhouse = newProps.inhousestock && newProps.inhousestock.inhouse
                let dataa = this.state.data.rows.map((val, keys) => {
                    if (keys === newProps.inhousestock.key) {
                        val['Xs Inhouse'] = 0
                        val['Xs Final Need'] = val['Xs Final Need'] - val['Xs Inhouse']
                        val['S Inhouse'] = 0
                        val['S Final Need'] = val['S Final Need'] - val['S Inhouse']
                        val['M Inhouse'] = 0
                        val['M Final Need'] = val['M Final Need'] - val['M Inhouse']
                        val['L Inhouse'] = 0
                        val['L Final Need'] = val['L Final Need'] - val['L Inhouse']
                        val['Xl Inhouse'] = 0
                        val['Xl Final Need'] = val['Xl Final Need'] - val['Xl Inhouse']
                        val['Xxl Inhouse'] = 0
                        val['Xxl Final Need'] = val['Xxl Final Need'] - val['Xxl Inhouse']
                        val['3xl Inhouse'] = 0
                        val['3xl Final Need'] = val['3xl Final Need'] - val['3xl Inhouse']
                        val['4xl Inhouse'] = 0
                        val['4xl Final Need'] = val['4xl Final Need'] - val['4xl Inhouse']
                        val['5xl Inhouse'] = 0
                        val['5xl Final Need'] = val['5xl Final Need'] - val['5xl Inhouse']
                    }
                    return val
                })
                let data1 = this.state.rows.map((val, keys) => {
                    if (keys === (newProps.inhousestock && newProps.inhousestock.key)) {
                        val['inHouseXS'] = 0
                        val['finalNeedXS'] = val['finalNeedXS'] - val['inHouseXS']
                        val.inhouseIdXS = undefined
                        val['inHouseS'] = 0
                        val['finalNeedS'] = val['finalNeedS'] - val['inHouseS']
                        val.inhouseIdS = undefined
                        val['inHouseM'] = 0
                        val['finalNeedM'] = val['finalNeedM'] - val['inHouseM']
                        val.inhouseIdM = undefined
                        val['inHouseL'] = 0
                        val['finalNeedL'] = val['finalNeedL'] - val['inHouseL']
                        val.inhouseIdL = undefined
                        val['inHouseXL'] = 0
                        val['finalNeedXL'] = val['finalNeedXL'] - val['inHouseXL']
                        val.inhouseIdXL = undefined
                        val['inHouseXXL'] = 0
                        val['finalNeedXXL'] = val['finalNeedXXL'] - val['inHouseXXL']
                        val.inhouseIdXXL = undefined
                        val['inHouse3XL'] = 0
                        val['finalNeed3XL'] = val['finalNeed3XL'] - val['inHouse3XL']
                        val.inhouseId3XL = undefined
                        val['inHouse4XL'] = 0
                        val['finalNeed4XL'] = val['finalNeed4XL'] - val['inHouse4XL']
                        val.inhouseId4XL = undefined
                        val['inHouse5XL'] = 0
                        val['finalNeed5XL'] = val['finalNeed5XL'] - val['inHouse5XL']
                        val.inhouseId5XL = undefined
                    }
                    return val
                })
                this.setState({
                    ...this.state,
                    data: {
                        ...this.state.data,
                        rows: dataa
                    },
                    rows: data1,
                    inhouse: inhouse
                })
                this.props.initialState();
            }
        } else {
            if (ratio && ratio.success === true) {
                let data = ratio && ratio.order.map((value, key) => {
                    let datainner = {}
                    this.state.varient.map((items, i) => {
                        datainner[items.field] = ''
                        value.ratiovarients && value.ratiovarients.map(val => {
                            if (val.varientId == items.id) {
                                if(items.name === 'color'){
                                    datainner[items.field] = [<span className="tooltip1" key={'abcd' + key}>{val.value !== null && val.value ? (val.value.length > 5 ? val.value.substr(0, 5) : val.value) : val.value}<span className="tooltiptext">{val.value}</span></span>]
                                  }else{
                                    datainner[items.field] = val.value != null ? val.value : ''
                                  }
                            }
                        })
                        return true;
                    })
                    let poSucc = value.addQTYS != null && value.addQTYXS != null && value.addQTYM != null && value.addQTYL != null && value.addQTYXL != null && value.addQTYXXL != null && value.addQTY3XL != null && value.addQTY4XL != null && value.addQTY4XL != null && value.ratioSupllier != null && value.ratioMaterial != null && value.average != null && value.deliveryDate != null ? false : true
                    return ({
                        'Action': [<div style={{ display: 'flex' }}>{sessionStorage.userROLE == 'super-admin' || value.poStatus === 'Pending' || value.poStatus === null ? <><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i><i title="Add Row" className="fas fa-plus-circle cursor-pointer" key={'abce' + key} onClick={e => (this.onAdd(e, key))}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, value.id, poSucc))} ></i><input style={{ minWidth: '15px', marginLeft: '5px' }} type="checkbox" value={value.id} name="poGenrate" disabled={poSucc} key={key} title={poSucc === true ? 'Fill the row to generate PO.' : ''} onChange={e => (this.listPoGenrate(e, key))} /></> : <><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i><i title="Add Row" className="fas fa-plus-circle cursor-pointer" key={'abce' + key} onClick={e => (this.onAdd(e, key))}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, value.id, poSucc))} ></i></>}</div>],
                        'Sr No': ((this.state.pageNumber - 1) * 10) + (key + 1),
                        'Style No': value.ratioDesc.styleno,
                        'Style Code': [<span className="tooltip1" key={'abcd' + key}>{value.ratioDesc !== null && value.ratioDesc.stylecode ? (value.ratioDesc.stylecode.length > 5 ? value.ratioDesc.stylecode.substr(value.ratioDesc.stylecode.length - 5) : value.ratioDesc.stylecode) : value.ratioDesc.stylecode}<span className="tooltiptext">{value.ratioDesc.stylecode}</span></span>],
                        'Supplier': [<span className="tooltip1" key={'abcde' + key} >{(value.ratioSupllier && value.ratioSupllier.id) ? (value.ratioSupllier.companyName.length > 5 ? value.ratioSupllier.companyName.substr(0, 5) : value.ratioSupllier.companyName) : ''}<span className="tooltiptext">{value.ratioSupllier && value.ratioSupllier.companyName ? value.ratioSupllier.companyName : ''}</span></span>],
                        'Trim Desc': [<span className="tooltip1" key={'abcde' + key} >{(value.ratioMaterial && value.ratioMaterial.id) ? (value.ratioMaterial.name.length > 5 ? value.ratioMaterial.name.substr(0, 5) : value.ratioMaterial.name) : ''}<span className="tooltiptext">{value.ratioMaterial && value.ratioMaterial.name ? value.ratioMaterial.name : ''}</span></span>],
                        'Average': value.average != null ? value.average : '',
                        'Xs Add Qtty %': value.addQTYXS != null ? value.addQTYXS : '',
                        'Xs Inhouse': value.inHouseXS != null ? value.inHouseXS : 0,
                        'Xs Final Need': value.finalNeedXS != null ? value.finalNeedXS : 0,
                        'S Add Qtty %': value.addQTYS != null ? value.addQTYS : '',
                        'S Inhouse': value.inHouseS != null ? value.inHouseS : 0,
                        'S Final Need': value.finalNeedS != null ? value.finalNeedS : 0,
                        'M Add Qtty %': value.addQTYM != null ? value.addQTYM : '',
                        'M Inhouse': value.inHouseM != null ? value.inHouseM : 0,
                        'M Final Need': value.finalNeedM != null ? value.finalNeedM : 0,
                        'L Add Qtty %': value.addQTYL != null ? value.addQTYL : '',
                        'L Inhouse': value.inHouseL != null ? value.inHouseL : 0,
                        'L Final Need': value.finalNeedL != null ? value.finalNeedL : 0,
                        'Xl Add Qtty %': value.addQTYXL != null ? value.addQTYXL : '',
                        'Xl Inhouse': value.inHouseXL != null ? value.inHouseXL : 0,
                        'Xl Final Need': value.finalNeedXL != null ? value.finalNeedXL : 0,
                        'Xxl Add Qtty %': value.addQTYXXL != null ? value.addQTYXXL : '',
                        'Xxl Inhouse': value.inHouseXXL != null ? value.inHouseXXL : 0,
                        'Xxl Final Need': value.finalNeedXXL != null ? value.finalNeedXXL : 0,
                        '3xl Add Qtty %': value.addQTY3XL != null ? value.addQTY3XL : '',
                        '3xl Inhouse': value.inHouse3XL != null ? value.inHouse3XL : 0,
                        '3xl Final Need': value.finalNeed3XL != null ? value.finalNeed3XL : 0,
                        '4xl Add Qtty %': value.addQTY4XL != null ? value.addQTY4XL : '',
                        '4xl Inhouse': value.inHouse4XL != null ? value.inHouse4XL : 0,
                        '4xl Final Need': value.finalNeed4XL != null ? value.finalNeed4XL : 0,
                        '5xl Add Qtty %': value.addQTY5XL != null ? value.addQTY5XL : '',
                        '5xl Inhouse': value.inHouse5XL != null ? value.inHouse5XL : 0,
                        '5xl Final Need': value.finalNeed5XL != null ? value.finalNeed5XL : 0,
                        'Image': value.image != null ? [<img className="cursor-pointer" onClick={e => this.openSlider(e, imagerUrl + value.image)} src={imagerUrl + value.image} width="50" style={{ height: '50px', borderRadius: '50%', objectFit: 'cover' }} />] : '',
                        ...datainner,
                        
                        'Price': value.price != null ? value.price : '',
                        'Delivery': value.deliveryDate != null ? moment(value.deliveryDate).format('DD/MM/YY') : '',
                        'Remarks': [<span className="tooltip1" key={'abcd' + key}>{value.remarks !== null && value.remarks ? (value.remarks.length > 5 ? value.remarks.substr(0, 5) : value.remarks) : value.remarks}<span className="tooltiptext">{value.remarks}</span></span>],
                        'po status': value.poStatus != null ? <>{value.poStatus === 'Generated' || value.poStatus === 'Regenerated' ? <i class="fas fa-check" title={value.poStatus} style={{ color: '#4dba4f' }}></i> : <><i style={{ color: '#f15d5d' }} class="far fa-clock" title='Pending'></i></>}</> : <><i style={{ color: '#f15d5d' }} class="far fa-clock" title='Pending'></i></>,
                        // 'recd status': value.recdStatus != null ? value.recdStatus : '',
                        // 'pending status': value.pendingStatus != null ? value.pendingStatus : '',
                    })
                })
                let supllierDetail = []
                let dataValue = ratio && ratio.order.map((value, key) => {
                    let datainner = {}
                    supllierDetail.push(value.ratioSupllier)
                    this.state.varient.map((items, i) => {
                        datainner[items.name] = ''
                        value.ratiovarients && value.ratiovarients.map(val => {
                            if (val.varientId == items.id) {
                                datainner[items.name] = val.value != '' ? val.value : ''
                            }
                        })
                        return true;
                    })
                    return ({
                        'orderid': value.id,
                        'orderDescId': value.orderDescId,
                        'brandOrderId': value.brandOrderId,
                        'supplier': value.supplierId != null ? value.supplierId : '',
                        'materialId': value.materialId != null ? value.materialId : '',
                        'materialText': value.ratioMaterial != null ? value.ratioMaterial.name : '',
                        'orderXS': value.ratioDesc.xs,
                        'orderS': value.ratioDesc.s,
                        'orderM': value.ratioDesc.m,
                        'orderL': value.ratioDesc.l,
                        'orderXL': value.ratioDesc.xl,
                        'orderXXl': value.ratioDesc.xxl,
                        'order3XL': value.ratioDesc['3xl'],
                        'order4XL': value.ratioDesc['4xl'],
                        'order5Xl': value.ratioDesc['5xl'],
                        'inHouseXS': value.inHouseXS != null ? value.inHouseXS : 0,
                        'totalNeedXS': value.finalNeedXS != null ? value.finalNeedXS : value.ratioDesc.xs,
                        'finalNeedXS': value.finalNeedXS != null ? value.finalNeedXS : value.ratioDesc.xs,
                        'addQTYXS': value.addQTYXS != null ? value.addQTYXS : '',
                        'addQTYS': value.addQTYS != null ? value.addQTYS : '',
                        'inHouseS': value.inHouseS != null ? value.inHouseS : 0,
                        'totalNeedS': value.finalNeedS != null ? value.finalNeedS : value.ratioDesc.s,
                        'finalNeedS': value.finalNeedS != null ? value.finalNeedS : value.ratioDesc.s,
                        'addQTYM': value.addQTYM != null ? value.addQTYM : '',
                        'inHouseM': value.inHouseM != null ? value.inHouseM : 0,
                        'totalNeedM': value.finalNeedM != null ? value.finalNeedM : value.ratioDesc.m,
                        'finalNeedM': value.finalNeedM != null ? value.finalNeedM : value.ratioDesc.m,
                        'addQTYL': value.addQTYL != null ? value.addQTYL : '',
                        'inHouseL': value.inHouseL != null ? value.inHouseL : 0,
                        'totalNeedL': value.finalNeedL != null ? value.finalNeedL : value.ratioDesc.l,
                        'finalNeedL': value.finalNeedL != null ? value.finalNeedL : value.ratioDesc.l,
                        'addQTYXL': value.addQTYXL != null ? value.addQTYXL : '',
                        'inHouseXL': value.inHouseXL != null ? value.inHouseXL : 0,
                        'totalNeedXL': value.finalNeedXL != null ? value.finalNeedXL : value.ratioDesc.xl,
                        'finalNeedXL': value.finalNeedXL != null ? value.finalNeedXL : value.ratioDesc.xl,
                        'addQTYXXL': value.addQTYXXL != null ? value.addQTYXXL : '',
                        'inHouseXXL': value.inHouseXXL != null ? value.inHouseXXL : 0,
                        'totalNeedXXL': value.finalNeedXXL != null ? value.finalNeedXXL : value.ratioDesc.xxl,
                        'finalNeedXXL': value.finalNeedXXL != null ? value.finalNeedXXL : value.ratioDesc.xxl,
                        'addQTY3XL': value.addQTY3XL != null ? value.addQTY3XL : '',
                        'inHouse3XL': value.inHouse3XL != null ? value.inHouse3XL : 0,
                        'totalNeed3XL': value.finalNeed3XL != null ? value.finalNeed3XL : value.ratioDesc['3xl'],
                        'finalNeed3XL': value.finalNeed3XL != null ? value.finalNeed3XL : value.ratioDesc['3xl'],
                        'addQTY4XL': value.addQTY4XL != null ? value.addQTY4XL : '',
                        'inHouse4XL': value.inHouse4XL != null ? value.inHouse4XL : 0,
                        'totalNeed4XL': value.finalNeed4XL != null ? value.finalNeed4XL : value.ratioDesc['4xl'],
                        'finalNeed4XL': value.finalNeed4XL != null ? value.finalNeed4XL : value.ratioDesc['4xl'],
                        'addQTY5XL': value.addQTY5XL != null ? value.addQTY5XL : '',
                        'inHouse5XL': value.inHouse5XL != null ? value.inHouse5XL : 0,
                        'totalNeed5XL': value.finalNeed5XL != null ? value.finalNeed5XL : value.ratioDesc['5xl'],
                        'finalNeed5XL': value.finalNeed5XL != null ? value.finalNeed5XL : value.ratioDesc['5xl'],
                        'average': value.average != null ? value.average : '',
                        'pictureView': value.image != null ? imagerUrl + value.image : '',
                        ...datainner,
                        'price': value.price != null ? value.price : '',
                        'deliveryDate': value.deliveryDate != null ? value.deliveryDate : '',
                        'Remarks': value.remarks != null ? value.remarks : '',
                        'poStatus': value.poStatus != null ? value.poStatus : 'Pending',
                        // 'recdStatus': value.recdStatus != null ? value.recdStatus : '',
                        // 'pendingStatus': value.pendingStatus != null ? value.pendingStatus : '',
                        note: value.notes != null ? value.notes : ''
                    })
                })
                console.log(data,'datainner==')
                this.setState({
                    data: {
                        ...this.state.data,
                        rows: data
                    },
                    rows: dataValue,
                    loaderTable: false,
                    total: ratio.page,
                    supllierDetail: supllierDetail,
                    pageHead: ratio.cat
                })
            } else {
                if(ratio && ratio.success === false){
                    this.setState({
                        loaderTable: false
                    })
                }
            }
        }
    }
    openSlider = (e, viewImg) => {
        this.setState({
            openViewImg: true,
            viewImg: viewImg
        })
    }
    searchChange = (e) => {
        const { brandOrderId, pageNumber, slug } = this.state
        this.setState({
            ...this.state,
            search: e.target.value
        })
        if (e.target.value !== '')
            this.props.ratioGet({ brandOrderId, pageNumber: 1, search: e.target.value, slug });
        else
            this.props.ratioGet({ brandOrderId, pageNumber: pageNumber, search: e.target.value, slug });
    }
    changePage = (e, pageNumber) => {
        const { brandOrderId, slug, search } = this.state
        this.setState({
            ...this.state,
            pageNumber: pageNumber,
            loaderTable: true,
            selectPoList: { id: [] }
        })
        this.props.ratioGet({ brandOrderId, pageNumber: pageNumber, search, slug });
    }
    preview = (e, rowId, id, poSucc) => {
        const { data, varient, supllierDetail, rows } = this.state
        let poTable = [{}]
        let orderId, poStatus, noteGetRow
        let poSupplierDetail = []
        supllierDetail.map((items, key) => {
            if (rowId == key) {
                poSupplierDetail = items
            }
        })
        rows.map((item, key) => {
            if (rowId == key) {
                orderId = rows[rowId]['orderid']
                poStatus = rows[rowId]['poStatus']
                noteGetRow = rows[rowId]['note']
            }
        })
        data.rows.map((value, key) => {
            if (rowId == key) {
                poTable[0]['Sr No'] = key + 1
                poTable[0]['Style Code'] = data.rows[key]['Style Code']
                poTable[0]['price'] = data.rows[key]['price']
                varient.map(items => {
                    poTable[0][items.field] = data.rows[key][items.field]
                })
                poTable[0]['Delivery'] = data.rows[key]['Delivery']
                poTable[0]['Remarks'] = data.rows[key]['Remarks']
            }
            return true;
        })
        if (poSupplierDetail !== null) {
            this.setState({
                previewData: {
                    columns: [
                        { label: "S.N.", field: "Sr No", class: 'colum_width' },
                        { label: 'Style code', field: 'Style code', class: 'colum_width' },
                        { label: 'Round Off final length', field: 'Round Off final length', class: 'colum_width' },
                        { label: 'price', field: 'price', class: '' },
                        ...varient,
                        { label: "Delivery", field: "Delivery", class: '' },
                        { label: 'Remarks', field: 'Remarks', class: '' },
                    ],
                    rows: poTable
                },
                previewDataId: id,
                open: true,
                orderIdPo: orderId,
                poStatus: poStatus,
                noteGetRow:noteGetRow,
                poSucc: poSucc,
                poSupplierDetail: poSupplierDetail
            })
        }
    }
    previewClose = () => {
        this.setState({
            open: false,
            openViewImg: false,
        })
    }
    poGenerate = (e, rowId) => {
        const { selectPoList, varient, rows } = this.state
        this.props.ratioPo(selectPoList)
        this.setState({
            loaderPo: true,
            loaderTable: true
        })
    }
    POSingle = (e, rowId) => {
        let selectPoList = {
            id: [rowId.toString(), null]
        }
        this.setState({
            ...this.state,
            loaderPo: true,
            loaderTable: true,
            open: false
        })
        this.props.ratioPo(selectPoList)
    }
    listPoGenrate = (e, key) => {
        const { selectPoList } = this.state
        let index;
        if (e.target.checked) {
            this.setState({
                selectPoList: { ...selectPoList, id: [...selectPoList.id, e.target.value] }
            })
        } else {
            index = selectPoList.id.indexOf(e.target.value)
            selectPoList.id.splice(index, 1)
            this.setState({
                selectPoList: { ...selectPoList, id: [...selectPoList.id] }
            })
        }
    }
    componentWillUnmount() {
        this.props.initialState()
    }
    render() {
        const { brandOrderId, brand, name, pageHead, brandImg, previewData, open, suppliers, previewDataId, poSupplierDetail, loaderPo, loaderTable, poStatus, loaderVarient, openViewImg, viewImg, slug, selectPoList, orderIdPo, poSucc,noteGetRow } = this.state
        return (
            <>
                <ToastContainer autoClose={10000} />
                {
                    !loaderTable && !loaderVarient ?
                        <>
                            <Header />
                            <Container className="" fluid>
                                <Row>
                                    <div className="col">
                                        <Card className="border-0">
                                            <CardHeader className="border-0 pt-0">
                                                <h1 className="m-0 text-capitalize">{pageHead}</h1>
                                                <Row className="align-items-center">
                                                    <div className="col-md-8 d-flex pl-0">
                                                        <div style={{ paddingBottom: '5px', marginRight: '25px' }}>
                                                            <h6 className="text-uppercase text-light ls-1 mb-1">
                                                                Brand
                                                        </h6>
                                                            <h3 className="mb-0 profile_sec text-capitalize">
                                                                {
                                                                    (brandImg !== '' && brandImg !== null) ?
                                                                        <img className="mr-3" src={`${imagerUrl}` + this.state.brandImg} width="40" alt="brand"></img>
                                                                        : <img className="mr-3" src={'/logo.png'} width="40" alt="brand"></img>
                                                                }
                                                                {brand}</h3>
                                                        </div>
                                                        <div style={{ paddingBottom: '5px' }}>
                                                            <h6 className="text-uppercase text-light ls-1 mb-1">
                                                                Order name
                                                            </h6>
                                                            <h2 className="mb-0">
                                                                {name}</h2>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 justify-contant-flex-xs-start pl-0 text-right d-flex justify-contant-flex-end">
                                                        <Button type="submit" style={{ marginRight: '20px' }} className="btn btn-primary" disabled={loaderPo === true || selectPoList.id == undefined || selectPoList.id[0] == null ? true : false} color="primary" onClick={this.poGenerate}>{
                                                            loaderPo === true ?
                                                                <Spinner /> :
                                                                'Generate PO'
                                                        }</Button>
                                                        <Nav className="justify-content-end" pills>
                                                            <NavItem>
                                                                <NavLink className="btn btn-primary " to={'/ratiowise/' + slug} tag={Link}  >Back</NavLink>
                                                            </NavItem>
                                                        </Nav>
                                                    </div>
                                                </Row>
                                            </CardHeader>
                                            <div className="table-responsive">
                                                <EditTable {...this.state} searchChange={this.searchChange} changePage={this.changePage} />
                                            </div>
                                        </Card>
                                    </div>
                                </Row>
                                <FormModel modelForm={<img src={viewImg} width="100%" />} toggle={this.previewClose} openForm={openViewImg} />
                                <PreviewModal previewData={previewData} toggle={this.previewClose} noteGetRow={noteGetRow} open={open} liningsupplier={suppliers} poSucc={poSucc} previewDataId={previewDataId} poSupplierDetail={poSupplierDetail} orderIdPo={orderIdPo} poStatus={poStatus} POSingle={this.POSingle} loaderPo={loaderPo} />
                            </Container>
                        </>
                        :
                        <div className="loader">
                            <Spinner variant="light" />
                            <p style={{ color: '#fff', paddingTop: '15px' }}>Please wait......</p>
                        </div>
                }
            </>
        )
    }
}
const mapDispatchState = (dispatch) => {
    return bindActionCreators({
        getBrandOrder,
        initialState,
        varientBySlug,
        getRatioInhouseStocks,
        noteinitialState,
        ratioGet,
        ratioSupplier,
        ratioAdd,
        addRationPicture,
        ratioPo,
        varienInitial
    }, dispatch)
}

const mapGetStateNew = (state) => {
    return {
        brandorder: state.order.brandorder,
        varientList: state.varient.varient,
        poGenrateSuccess: state.poPDF.poGenrate,
        ratio: state.unitRatioWise.ratioWise,
        inhousestock: state.order.ratioinhousestock,
        supplierSuccess: state.supplier.supplier,
        noteSuccess: state.order.note
    }
}
export default connect(mapGetStateNew, mapDispatchState)(RatioWise);