import React, {useEffect}  from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col } from "reactstrap";
const GuestRoute = ({ isLoggedin,location, isRole, component: Component, ...rest }) => {
    useEffect(() => {
        document.body.classList.add("bg-default");
    });
    return <div>
        <div className="main-content login_page">
            <div className="header bg-gradient-info custom_login py-6 py-lg-6">
                <Container>
                    <div className="header-body text-center mb-7">
                        <Row className="justify-content-center">
                            <Col lg="5" md="6">
                                <h1 className="text-white">Welcome!</h1>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <div className="separator separator-bottom separator-skew zindex-100">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="fill-default"
                            points="2560 0 2560 100 0 100"
                        />
                    </svg>
                </div>
            </div>
            <Container className="mt--8 ">
                <Row className="justify-content-center">
                    <Route {...rest} render={props => !isLoggedin ? <Component {...props} /> : <Redirect to="/dashboard" /> } />
                    {/* <Route {...rest} render={props => !isLoggedin ? <Component {...props} /> : isRole === 'super-admin' ? <Redirect to="/dashboard" /> : isRole === 'main-zipper-worker' ? <Redirect to="/mainzippersheet"/> : isRole === 'main-polyfill-worker' ? <Redirect to="/mainpolyfill"/> : isRole === 'main-fabric-worker' ? <Redirect to="/mainfabricsheet"/> : isRole === 'main-ribDhaaga-worker' ? <Redirect to="/mainribdhaga"/> :isRole === 'main-lining-worker' ? <Redirect to="/mainlining"/> :isRole === 'lining-quilting-worker' ? <Redirect to="/liningquilting"/> : <Redirect to="/fabricquilting"/> } /> */}
                </Row>
          </Container>
        </div>
    </div>
}
const mapStateAuth = (state) => {
    return {
        isLoggedin: state.user.token,
        isRole: state.user.role
    }
}
export default connect(mapStateAuth)(GuestRoute);