import React from "react";
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import {
  Container,
  NavItem,
  NavLink,
  Nav,
  Card,
  CardHeader,
  Row,
  Button,
  Spinner
} from 'reactstrap';
import { initialState } from '../../services/actions/order'
import { ToastContainer, toast } from 'react-toastify';
import { getInhouse } from '../../services/actions/order';
import { deleteInhouse } from '../../services/actions/inHouse'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { MDBIcon } from 'mdbreact';
import ConfirmModal from '../../components/ConfirmModal';
import moment from 'moment';
var toastId = null
class Inhouse extends React.Component {
  state = {
    data: {
      columns: [
        { label: '#', field: '#', sort: 'asc', },
        { label: 'Sub Categories', field: 'Sub Categories', sort: 'asc', },
        { label: 'Material', field: 'Material', sort: 'asc', },
        { label: 'Variants', field: 'Variants', sort: 'asc', },
        { label: 'Inhouse', field: 'Inhouse', sort: 'asc', },
        { label: 'Created on', field: 'Created on', sort: 'asc' },
        { label: 'Updated on', field: 'Updated on', sort: 'asc' },
        { label: 'Action', field: 'Action', sort: 'disabled', }
      ],
      rows: []
    },
    deleteId: null,
    open: false,
    loader: false,
    errors: {},
  }
  confirm = (event, id) => {
    this.setState({
      open: true,
      deleteId: id,
    })
    this.props.initialState()
  }
  toggle = () => {
    this.setState({
      open: false,
    })
  }
  confirmation = (event, id) => {
    this.setState({
      open: false
    })
    this.props.deleteInhouse(id)
  }
  componentDidMount() {
    this.props.getInhouse();
    this.setState({
      loader: true
    })
  }
  componentWillUnmount(){
    this.props.initialState()
  }
  componentWillReceiveProps(newProps) {
    const {deleteInhouseFailed} = newProps
    const { deleted } = newProps.deleteInhouseSuccess
    if(deleteInhouseFailed){
      if(deleteInhouseFailed.success === false){
        if (toast.isActive(toastId)) {
          toast.dismiss(toastId)
          toastId = toast.error(deleteInhouseFailed.message);
          newProps.initialState()
        } else {
          toastId = toast.error(deleteInhouseFailed.message);
          newProps.initialState()
        }
      }
    }
    let role = sessionStorage.userROLE.replace('_worker', '')
    if (newProps.detailinhouse && newProps.detailinhouse.success) {
      let inhouse = newProps.detailinhouse.inhouse
      if (deleted) {
        if (toast.isActive(toastId)) {
          toast.dismiss(toastId)
          toastId = toast.success(newProps.detailinhouse.message);
          newProps.initialState()
        } else {
          toastId = toast.success(newProps.detailinhouse.message);
          newProps.initialState()
        }
      }
      if (inhouse) {
        let data = []
        inhouse && inhouse.map((value, key) => {
          let vals = ''
          if (value.varients !== "null") {
            let json = JSON.parse(value.varients)
            let varient = json.split(',')
            vals = varient.map((val) => {
              return (<div className="varients_cols">{vals + val}</div>)
            })
          } else {
            vals = ''
          }
          let subCategory
          if (value.inhouseMaterial.assignedMaterial.length === 2){
            // subCategory = value.inhouseMaterial.assignedMaterial && value.inhouseMaterial.assignedMaterial && value.inhouseMaterial.assignedMaterial[1] && value.inhouseMaterial.assignedMaterial[1].materialSubCategory
            if((value.inhouseMaterial.assignedMaterial && value.inhouseMaterial.assignedMaterial && value.inhouseMaterial.assignedMaterial[1] && value.inhouseMaterial.assignedMaterial[1].materialSubCategory) && value.varients === "null"){
              subCategory = {
                name: "Kora Yarn",
                slug: "kora_dhaaga"
              }
            }else{
              subCategory = {
                name: "Yarn Color",
                slug: "dhaaga_color"
              }
            }
          }
          else {
            subCategory = value.inhouseMaterial.assignedMaterial && value.inhouseMaterial.assignedMaterial && value.inhouseMaterial.assignedMaterial[0] && value.inhouseMaterial.assignedMaterial[0].materialSubCategory
          }

          if (role === (subCategory&&subCategory.slug) || sessionStorage.userROLE === 'super-admin') {
            data.push({
              '#': key + 1,
              'Sub Categories': subCategory&&subCategory.name,
              'Material': value.inhouseMaterial && value.inhouseMaterial.name ? value.inhouseMaterial.name : '',
              'Variants': vals,
              'Inhouse': value.temp,
              'Created on': moment(value.createdAt).format('DD/MM/YY, H:mm'),
              'Updated on': moment(value.updatedAt).format('DD/MM/YY, H:mm'),
              'Action': [<>{sessionStorage.userROLE === 'super-admin' ? <><Button className="action_btn text-black" outline color="" onClick={e => this.confirm(e, value.id)}><MDBIcon far icon="trash-alt" title="Delete" /></Button></> : <div className="text-danger" style={{ fontSize: '12px' }}>Can't Edit/Delete</div>}</>]
            })
          } else { }
        })
        this.setState({
          data: {
            ...this.state.data,
            rows: data
          },
          loader: false
        })
      } else {
        this.setState({
          loader: false
        })
        toast.error('Data not found')
      }
    }
    if (newProps.detailinhouse && newProps.detailinhouse.error) {
      this.setState({
        loader: false
      })
      toast.error(newProps.detailinhouse)
    } else {
      this.setState({
        loader: false
      })
    }
  }
  render() {
    const { open, deleteId, loader } = this.state
    return (
      <>
        <ToastContainer autoClose={4000} />
        {
          !loader ?
            <Container className="mt-0" fluid>
              <Card className="card_border">
                <CardHeader className="border-0 pt-0">
                  <Row className="align-items-center mobile_sec_col">
                    <div className="col">
                      <h2 className="mb-0">Inhouse</h2>
                    </div>
                    <div className="col">
                      <Nav className="justify-content-end" pills>
                        <NavItem>
                          <NavLink to="/add-inhouse" tag={Link} className="btn btn-primary">Add Inhouse</NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </Row>
                </CardHeader>
                <MDBDataTable
                  className="tableSize"
                  striped
                  bordered
                  hover
                  responsive
                  data={this.state.data}
                />
              </Card>
              <ConfirmModal open={open} toggle={this.toggle} confirmation={this.confirmation} id={deleteId} />
            </Container>
            :
            <div className="loader">
              <Spinner variant="light" />
              <p style={{ color: '#fff', paddingTop: '15px' }}>Please wait......</p>
            </div>
        }
      </>
    );
  }
}

const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    getInhouse,
    deleteInhouse,
    initialState
  }, dispatch)
}

const mapGetStateNew = (state) => {
  return {
    detailinhouse: state.inHouse.detailinhouse,
    deleteInhouseSuccess: state.inHouse,
    deleteInhouseFailed: state.inHouse.deleteInhouse,
  }
}
export default connect(mapGetStateNew, mapDispatchState)(Inhouse);