import React from 'react';
import {
    Card, CardHeader, Row, Container, Button, NavItem,
    Nav,
    NavLink, Spinner
} from "reactstrap";
import { Link } from 'react-router-dom'
import PreviewModal from "../../../components/previewModal";
import Header from "../../../components/Headers/Header.jsx";
import EditTable from '../../../components/EditTable'
import { imagerUrl } from '../../../config/config';
import { getBrandOrder, initialState, noteinitialState, getInhouseStocks } from '../../../services/actions/order';
import { getUnit, addUnit, addUnitPicture } from '../../../services/actions/unitRatioWise'
import { varientBySlug, varienInitial } from '../../../services/actions/varient'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ToastContainer, toast } from 'react-toastify';
import dataJson from '../../../sharedFile/allData'
import { unitSupplier } from '../../../services/actions/supplier'
import { unitPo } from '../../../services/actions/po'
import FormModel from "../../../components/formModel.jsx";
import moment from 'moment';
var toastId = null

class UnitWise extends React.Component {
    state = {
        data: {
            columns: [],
            rows: []
        },
        brand: '',
        brandImg: '',
        brandOrderId: this.props.match.params.id,
        previewData: {
            columns: [],
            rows: []
        },
        previewDataId: null,
        poSucc: null,
        open: false,
        loaderVarient: true,
        orderIdPo: '',
        loaderTable: true,
        poStatus: '',
        viewImg: '',
        openViewImg: false,
        supllierDetail: [],
        loaderPo: false,
        selectPoList: {
            id: []
        },
        name: '',
        varient: [],
        materialList: [],
        suppliers: [],
        total: 0,
        poSupplierDetail: [],
        imagesPreviewUrls: '',
        slug: this.props.match.params.item_slug,
        pageNumber: 1,
        search: '',
        pageHead: '',
        noteGetRow:''
    }
    componentWillMount() {
        const { slug, brandOrderId, pageNumber } = this.state
        this.props.varientBySlug(slug)
        this.props.getBrandOrder({ brandOrderId, pageNumber })
        this.props.unitSupplier()
        this.setState({
            loaderVarient: true
        })
    }
    onKeyPress(evt) {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        var regex = /[0-9]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }
    valueEdit = (rowId, key, rows, value, suppliers, material) => {
        const { varient } = this.state
        let currentDate = new Date();
        let getYear = currentDate.getFullYear()
        let getMonth = currentDate.getMonth() + 1
        let fullCurrentDate = getYear + '-' + (getMonth <= 9 ? '0' + getMonth : getMonth) + '-' + (currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate())
        if (key === rowId) {
            if (sessionStorage.userROLE === 'super-admin' || rows[rowId]['poStatus'] === 'Pending') {
                varient.map(items => {
                    if (items.assignedVarient === '') {
                        value[items.field] = [<input className="inputFiled" type="text" name={items.name} value={rows[key][items.name]} key={items.name + key} onChange={e => (this.onChange(e, key))} />]
                    } else {
                        let values = items.assignedVarient.split(',')
                        value[items.field] = [<select className="selectList inputFiled" name={items.name} key={'ah' + key} defaultValue={rows[key][items.name]} onChange={e => (this.onChange(e, key))}>
                            <option value={null}>{items.name}</option>
                            {values.map(itemValue => {
                                return <option value={itemValue}>{itemValue}</option>
                            })}
                        </select>]
                    }
                })
                value['Action'] = [<i className="fas fa-check pr-2 cursor-pointer" onClick={e => (this.onSubmit(e, key))} key={'abc' + key}></i>]
                value['Sr No'] = key + 1
                value['Add Qtty'] = [<select className="selectList inputFiled" name="addQtty" value={rows[key]['addQtty']} onChange={e => (this.onChange(e, key))}>
                    <option>Qty</option>
                    {
                        dataJson.addQuantity.map(items => {
                            return <option value={items}>{items + '%'}</option>
                        })
                    }
                </select>]
                value['Trim Code Supplier'] = [<select className="selectList inputFiled" name="supplier" defaultValue={rows[key]['supplier']} onChange={e => (this.onChange(e, key))}>
                    <option>Supplier</option>
                    {
                        suppliers.map((value, key) => {
                            return <option value={value.id} key={key}>{value.companyName}</option>
                        })
                    }
                </select>]
                value['Trim Code specification'] = [<select className="selectList inputFiled" name="materialId" defaultValue={rows[key]['materialId']} onChange={e => (this.onChange(e, key))}>
                    <option value="">specification</option>
                    {
                        material && material.length && material[0].map((value, key) => {
                            return <option value={value.supplyMaterial.id} key={key}>{value.supplyMaterial.name}</option>
                        })
                    }
                </select>]
                value['Average'] = [<input className="avgWidth inputFiled" type="text" onKeyPress={this.onKeyPress.bind(this)} name="average" value={rows[key]['average']} onChange={e => (this.onChange(e, key))} />]
                value['Image'] = [<><><img src={rows[key]['imagesPreviewUrls']} id={'imgLoad' + rowId} style={{ width: '50px', margin: '0px' }} /></><label htmlFor="fileUnit" className="fileUpload"><i class="fas fa-image"></i><div style={{ fontSize: '10px', color: '#f15d5d' }}>Upload img</div></label><input accept="image/gif,image/jpeg,image/png" style={{ display: 'none' }} type="file" id="fileUnit" name="picture" onChange={e => (this.onChange(e, key))} /></>]
                value['Price'] = [<input maxLength="8" className="inputFiled" type="text" onKeyPress={this.onKeyPress.bind(this)} name="price" value={rows[key]['price']} onChange={e => (this.onChange(e, key))} />]
                value['Delivery'] = [<input className="inputFiled" min={fullCurrentDate} type="date" name="deliveryDate" value={rows[key]['deliveryDate']} onChange={e => (this.onChange(e, key))} />]
                value['Remarks'] = [<input className="inputFiled" type="text" name="Remarks" value={rows[key]['Remarks']} onChange={e => (this.onChange(e, key))} />]
                value['recd status'] = [<input type="text" class="inputFiled" className="avgWidth" onKeyPress={this.onKeyPress.bind(this)} name="recdStatus" value={rows[key]['recdStatus']} onChange={e => (this.onChange(e, key))} />]
                value['pending status'] = rows[key]["pendingStatus"]
            }
            else {
                value['Action'] = [<i className="fas fa-check pr-2 cursor-pointer" onClick={e => (this.onSubmit(e, key))} key={'abc' + key}></i>]
                value['recd status'] = [<input className="inputFiled" type="text" onKeyPress={this.onKeyPress.bind(this)} name="recdStatus" value={rows[key]['recdStatus']} onChange={e => (this.onChange(e, key))} />]
                value['pending status'] = rows[key]["pendingStatus"]
            }
        } else {
            value['Action'] = [<div style={{ display: 'flex' }}><i title="Edit" className="disabledAction fas fa-pen pr-2 cursor-pointer" key={'abc' + key}></i><i title="Add Row" className="disabledAction fas fa-plus-circle cursor-pointer" key={'abce' + key} ></i><i key={'abcr' + key} title="View" className="disabledAction fas fa-eye cursor-pointer"></i><input type="checkbox" value={value.id} name="poGenrate" disabled key={key} onChange={e => (this.listPoGenrate(e, key))} style={{ minWidth: '15px', marginLeft: '5px' }} /></div>]
        }
    }
    valueUpdate = (rows, rowId, value, key) => {
        const { varient } = this.state
        if (key == rowId) {
            varient.map(items => {
                value[items.field] = rows[rowId][items.name]
            })
            value['Action'] = [<div style={{ display: 'flex' }}><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i><i title="Add Row" className="fas fa-plus-circle cursor-pointer" key={'abce' + key} onClick={e => (this.onAdd(e, key))}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, rows[rowId]['orderid']))} ></i><input type="checkbox" value={value.id} style={{ minWidth: '15px', marginLeft: '5px' }} name="poGenrate" disabled={true} key={key} onChange={e => (this.listPoGenrate(e, key))} /></div>]
            value['Sr No'] = key + 1
            value['Style No'] = value['Style No']
            value['Style Code'] = value['Style Code']
            value['order qtty'] = value['order qtty']
            value['Add Qtty'] = rows[rowId]['addQtty']
            value['Trim Code Supplier'] = rows[rowId]['supplierText']
            value['Trim Code specification'] = rows[rowId]['materialText']
            value['Average'] = rows[rowId]['average']
            value['Need'] = value['Need']
            value['inHouse'] = value['inHouse']
            value['Final Need'] = rows[rowId]['Final Need']
            value['Image'] = value['picture']
            value['Price'] = rows[rowId]['price']
            value['Delivery'] = rows[rowId]['deliveryDate']
            value['Remarks'] = rows[rowId]['Remarks']
            value['po status'] = rows[rowId]['poStatus']
            value['recd status'] = rows[rowId]['recdStatus']
            value['pending status'] = rows[rowId]['pendingStatus']
        }
    }
    onChange = (e, rowId) => {
        let { rows, data, suppliers, materialList, varient } = this.state
        let materialSet = []
        let dataRows = rows.map((value, key) => {
            if (key == rowId) {
                if (e.target.name === 'picture') {
                    let files = Array.from(e.target.files);
                    if (files[0].size > 1000000) {
                        toastId = toast.error('Image max upload size is 1MB')
                    } else {
                        files.forEach((file) => {
                            let reader = new FileReader();
                            reader.onloadend = () => {
                                value['picture'] = [file]
                                value['imagesPreviewUrls'] = reader.result
                                document.getElementById('imgLoad' + rowId).src = reader.result
                            }
                            reader.readAsDataURL(file);
                        });
                    }
                } else {
                    if (e.target.name === 'supplier' && e.target.value !== 'Select Supplier') {
                        let material = []
                        value['supplier'] = e.target.value
                        suppliers.map((val, keys) => {
                            if ((val.id).toString() === e.target.value) {
                                material.push(val.suppliermaterial)
                                materialSet.push(val.suppliermaterial)
                            } return true
                        })
                        this.setState({
                            materialList: material
                        })
                    } else {
                        value[e.target.name] = e.target.value
                        if (e.target.name == 'average' || e.target.name == 'addQtty') {
                            let addQt = value.addQtty ? parseInt(value.addQtty) : 0
                            let total = Math.ceil(data.rows[key]['order qtty'] + ((addQt / 100) * data.rows[key]['order qtty']))
                            data.rows[key]['Need'] = Math.ceil(value.average * total)
                            value['totalNeed'] = Math.ceil(value.average * total)
                            value['finalNeed'] = value['totalNeed'] - value['inHouse']
                            data.rows[key]['Final Need'] = value['totalNeed'] - value['inHouse']
                        }
                        if (e.target.name === 'recdStatus') {
                            value['pendingStatus'] = parseInt(value['finalNeed'] - e.target.value)
                        }
                    }
                }
            }
            return value
        })
        var row = data.rows.map((value, key) => {
            if (materialSet.length) {
                this.valueEdit(rowId, key, rows, value, suppliers, materialSet)
            }
            else {
                this.valueEdit(rowId, key, rows, value, suppliers, materialList)
            }
            return value;
        })
        var varientDetail = ''
        varient.map(items => {
            if (rows[rowId][items.name] != null) {
                varientDetail = varientDetail + items.name + ':' + rows[rowId][items.name] + ',';
            }
        })
        this.props.getInhouseStocks({
            materialId: rows[rowId].materialId,
            varients: varientDetail.substring(0, varientDetail.length - 1),
            key: rowId,
            quantity: rows[rowId].totalNeed ? rows[rowId].totalNeed : 0,
            inhouseId: rows[rowId].newStock,
            oldQuantity: rows[rowId].inHouse,
            orderid:rows[rowId].orderid ? rows[rowId].orderid : undefined
        })
        this.setState({
            data: {
                ...this.state.data,
                rows: row
            },
            rows: dataRows,
        })
    }
    onEdit = (event, rowId) => {
        let { rows, data, suppliers, supllierDetail } = this.state
        let material = []
        supllierDetail&&supllierDetail.map((val, keys) => {
            if(keys === rowId && val !==null){
            if (val.id === parseInt(rows[rowId]['supplier'])) {
              material[0] = val.suppliermaterial 
            }}
        })
        var row = data.rows.map((value, key) => {
            this.valueEdit(rowId, key, rows, value, suppliers, material)
            return value;
        })
        var rowList = rows.map((val, key) => {
            if (key === rowId && rows[key]['totalNeedKg'] !== null) {
                val['updateClass'] = 'editRow'
            }
            return val
        })
        this.setState({
            data: {
                ...this.state.data,
                rows: row
            },
            materialList:material,
            rows: rowList
        })
    }
    onSubmit = (event, rowId) => {
        const { varient, brandOrderId, pageNumber, search, slug, rows, data } = this.state
        var row = data.rows.map((value, key) => {
            this.valueUpdate(rows, rowId, value, key)
            return value;
        })
        this.setState({
            data: {
                ...this.state.data,
                rows: row
            }
        })
        var dataPic, id, dataName = {}
        rows.map((val, key) => {
            if (key === rowId) {
                let datainner = []
                datainner['unitsvarients'] = []
                varient.map((items, i) => {
                    datainner['unitsvarients'].push({ id: items.id, value: val[items.name] !== '' ? val[items.name] : null })
                    return true;
                })
                dataName = {
                    "orderid": val.orderid ? val.orderid : undefined,
                    "supplierId": val.supplier && val.supplier !== '' ? val.supplier : '',
                    "brandOrderId": brandOrderId !== '' ? brandOrderId : '',
                    "orderDescId": val.orderDescId,
                    "materialId": val.materialId !== '' ? val.materialId : '',
                    "average": val.average !== '' ? val.average : '',
                    "totalNeed": val.totalNeed !== '' ? `${val.totalNeed}` : '',
                    "inHouse": val.inHouse !== '' ? `${val.inHouse}` : 0,
                    "finalNeed": val.finalNeed !== '' ? `${val.finalNeed}` : '',
                    'addQTY': val.addQtty !== '' ? val.addQtty : '',
                    unitsvarients: val.picture !== '' && val.picture != null ? datainner['unitsvarients'] : datainner['unitsvarients'],
                    "price": val.price !== '' ? `${val.price}` : '',
                    // "picture": val.picture !== '' ? val.picture : '',
                    "deliveryDate": val.deliveryDate !== '' ? val.deliveryDate : '',
                    "remarks": val.Remarks !== '' ? val.Remarks : '',
                    "recdStatus": val.recdStatus !== '' ? val.recdStatus : '',
                    "pendingStatus": val.pendingStatus !== '' ? `${val.pendingStatus}` : '',
                    oldStock: val.oldStock !== '' ? val.oldStock : '',
                    newStock: val.newStock !== '' ? val.newStock : '',
                }
                id = { "orderid": val.orderid ? val.orderid : undefined, }
                dataPic = {
                    "brandOrderId": brandOrderId,
                    "orderDescId": val.orderDescId,
                    "picture": val.picture !== '' ? val.picture : '',
                    'slug': slug,
                    search: search,
                    pageNumber: pageNumber
                }
            }
        })
        this.props.addUnit({ ...dataName, search, pageNumber, slug },dataPic)
        // this.props.addUnitPicture(id, dataPic);
    }
    onAdd = (event, rowId) => {
        let rows = this.state.data.rows
        const { varient } = this.state
        let row = []
        rows.map((value, key) => {
            var abc = {}
            value['Action'] = [<div style={{ display: 'flex' }}><i title="Edit" className="disabledAction fas fa-pen pr-2 cursor-pointer" key={'abc' + key}></i><i title="Add Row" className="disabledAction fas fa-plus-circle cursor-pointer" key={'abce' + key} ></i><i key={'abcr' + key} title="View" className="disabledAction fas fa-eye cursor-pointer"></i><input type="checkbox" value={value.id} name="poGenrate" disabled key={key} onChange={e => (this.listPoGenrate(e, key))} style={{ minWidth: '15px', marginLeft: '5px' }} /></div>]
            if (key == rowId) {
                abc['Action'] = [<i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key + 1))} key={'abc' + key}></i>, <i className="fas fa-minus-circle cursor-pointer" title="Delete" onClick={e => (this.onDelete(e, key + 1))} key={'abcio' + key}></i>]
                abc['Sr No'] = this.state.data.rows[key]['Sr No'] + 1
                abc['Style No'] = value['Style No']
                abc['Style Code'] = value['Style Code']
                abc['order qtty'] = value['order qtty']
                abc['Add Qtty'] = ''
                abc['Trim Code Supplier'] = ''
                abc['Trim Code specification'] = ''
                abc['Average'] = ''
                varient.map(items => {
                    abc[items.field] = ''
                })
                abc['Need'] = ''
                abc['inHouse'] = ''
                abc['Final Need'] = ''
                abc['Image'] = ''
                abc['Price'] = ''
                abc['Delivery'] = ''
                abc['Remarks'] = ''
                abc['po status'] = <>{value.poStatus === 'Generated' || value.poStatus === 'Regenerated' ? <i class="fas fa-check" title={value.poStatus} style={{ color: '#4dba4f' }}></i> : <><i style={{ color: '#f15d5d' }} class="far fa-clock" title='Pending'></i></>}</>
                abc['recd status'] = ''
                abc['pending status'] = ''
            }
            if (key >= rowId + 1) {
                abc = {}
                value['Sr No'] = this.state.data.rows[key]['Sr No'] + 1
                value['Action'] = [<div style={{ display: 'flex' }}><i title="Edit" className="disabledAction fas fa-pen pr-2 cursor-pointer" key={'abc' + key}></i><i title="Add Row" className="disabledAction fas fa-plus-circle cursor-pointer" key={'abce' + key} ></i><i key={'abcr' + key} title="View" className="disabledAction fas fa-eye cursor-pointer"></i><input type="checkbox" value={value.id} name="poGenrate" disabled key={key} onChange={e => (this.listPoGenrate(e, key))} style={{ minWidth: '15px', marginLeft: '5px' }} /></div>]
            }
            row.push(value);
            if (abc && Object.keys(abc).length)
                row.push(abc);
        })
        let updatedRow = this.state.rows
        let data1 = []
        updatedRow && updatedRow.map((value, key) => {
            var abc = {}
            if (key == rowId) {
                abc.deliveryDate = ""
                abc.Remarks = ""
                abc.orderDescId = value.orderDescId
                abc.brandOrderId = value.brandOrderId
                abc.addQtty = ""
                abc.orderid = null
                abc.supplier = ''
                abc.average = ''
                abc.materialId = ''
                varient.map(items => {
                    abc[items.name] = ''
                })
                abc.totalNeed = ""
                abc.picture = ""
                abc.finalNeed = ''
                abc.inHouse = ""
                abc.pendingStatus = ""
                abc.poStatus = "Pending"
                abc.price = ""
                abc.recdStatus = ""
            }
            data1.push(value);
            if (abc && Object.keys(abc).length)
                data1.push(abc);
        })
        this.setState({
            data: {
                ...this.state.data,
                rows: row
            },
            rows: data1
        })
    }
    onDelete = (event, rowId) => {
        const { brandOrderId, pageNumber, search, slug } = this.state
        let rows = this.state.data.rows
        let row = []
        rows.map((value, key) => {
            if (key !== rowId) {
                if (key >= rowId + 1) {
                    let poSucc = this.state.rows[key]['addQTY'] != null && this.state.rows[key]['unitsSupllier'] != null && this.state.rows[key]['unitsMaterial'] != null && this.state.rows[key]['average'] != null && this.state.rows[key]['totalNeed'] != null && this.state.rows[key]['finalNeed'] != null && this.state.rows[key]['deliveryDate'] != null ? false : true
                    value['Sr No'] = this.state.data.rows[key]['Sr No'] - 1
                    value['Action'] = [
                        <div style={{ display: 'flex' }}><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i><i title="Add Row" className="fas fa-plus-circle cursor-pointer" key={'abce' + key} onClick={e => (this.onAdd(e, key))}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, value.id, poSucc))} ></i><input type="checkbox" value={value.id} name="poGenrate" disabled={poSucc} key={'check' + key} onChange={e => (this.listPoGenrate(e, key))} /></div>
                    ]
                }
                row.push(value);
            }
            return true
        })

        let updatedRow = this.state.rows
        let data1 = []

        updatedRow && updatedRow.map((value, key) => {
            if (key !== rowId) {
                data1.push(value);
            } return true;
        })
        this.setState({
            data: {
                ...this.state.data,
                rows: row
            },
            rows: data1
        })
        this.props.getUnit({ brandOrderId, pageNumber, slug, search })
    }
    componentWillReceiveProps(newProps) {
        const { brandOrderId, pageNumber, search, slug } = this.state
        const { brandorder, varientList, unit, supplierSuccess, inhousestock, poGenrateSuccess,noteSuccess } = newProps
        var varient = []
        if (noteSuccess && noteSuccess.success === true) {
            newProps.getUnit({ brandOrderId, pageNumber, slug, search })
            this.setState({
                open: false,
                loaderTable: true,
            })
        }
        if (brandorder && brandorder.success) {
            let brand = brandorder.order && brandorder.order.brandsId
            this.setState({
                brand: brand && brand.name,
                brandImg: brand && brand.logo,
                name: brandorder.order && brandorder.order.name
            })
        }
        if (poGenrateSuccess) {
            if (poGenrateSuccess.success) {
                if (!toast.isActive(toastId))
                    toastId = toast.success(poGenrateSuccess.message)
                this.props.getUnit({ brandOrderId, pageNumber, search, slug });
                this.props.initialState();
            }
            if (poGenrateSuccess.success === false) {
                if (!toast.isActive(toastId))
                    toastId = toast.error(poGenrateSuccess.message)
                this.props.initialState();
            }
            this.setState({
                loaderPo: false,
                selectPoList: { id: [] }
            })
        }
        if (supplierSuccess && supplierSuccess.success) {
            this.setState({
                suppliers: supplierSuccess.suppliers
            })
        }
        if (varientList.success) {
            varientList.varient && varientList.varient.map((value, key) => {
                let assignedVarient = value.assignedVarient && value.assignedVarient[0].value
                varient.push({ label: value.name, field: value.name, name: value.slug, id: value.id, assignedVarient: assignedVarient })
            })
            this.setState({
                data: {
                    ...this.state.data,
                    columns: [
                        { label: "Action", field: "Action" },
                        { label: "#", field: "Sr No" },
                        { label: 'Style No', field: 'Style No' },
                        { label: 'Style Code', field: 'Style Code' },
                        { label: "Order Qty", field: "order qtty", type: "numeric" },
                        { label: "Add Qty %", field: "Add Qtty", type: "numeric" },
                        { label: "Trim Code Supplier", field: "Trim Code Supplier", type: "numeric", class: 'selectList' },
                        { label: 'Trim Code Specification', field: 'Trim Code specification', class: 'selectList' },
                        { label: 'Average', field: 'Average' },
                        ...varient,
                        { label: 'Need', field: 'Need' },
                        { label: 'Inhouse', field: 'inHouse' },
                        { label: 'Final Need', field: 'Final Need' },
                        { label: 'Image', field: 'Image' },
                        { label: 'Price', field: 'Price' },
                        { label: "Delivery", field: "Delivery" },
                        { label: 'Remarks', field: 'Remarks' },
                        { label: 'PO Status', field: 'po status' },
                        { label: 'Recd Status', field: 'recd status', class: 'avgWidth' },
                        { label: 'Pending Status', field: 'pending status', class: 'avgWidth' }
                    ]
                },
                loaderVarient: false,
                loaderTable: true,
                varient: varient
            })
            newProps.varienInitial()
            newProps.getUnit({ brandOrderId, pageNumber, slug, search })
        } else {
            this.setState({
                loaderVarient: false,
            })
        }
        if (inhousestock && (inhousestock.message || inhousestock.success)) {
            if (inhousestock.success) {
                var inHouse = inhousestock&&inhousestock.inhouse
                let dataa = this.state.data.rows.map((val, keys) => {
                    if (keys == inhousestock.key) {
                        val.inHouse = inhousestock.quantity
                        val['Final Need'] = val['Need'] - val.inHouse
                        newProps.initialState();
                    }
                    return val
                })
                let data1 = this.state.rows.map((val, keys) => {
                    if (keys == inhousestock.key) {
                        val.inHouse = inhousestock.quantity
                        val.finalNeed = val.totalNeed - val.inHouse
                        val.newStock = inHouse&&inHouse.id
                        newProps.initialState();
                    }
                    return val
                })
                this.setState({
                    ...this.state,
                    data: {
                        ...this.state.data,
                        rows: dataa
                    },
                    rows: data1,
                    inHouse: inHouse
                })
            } else {
                var inHouse = inhousestock&&inhousestock.inhouse
                let dataa = this.state.data.rows.map((val, keys) => {
                    if (keys == newProps.inhousestock.key) {
                        val.inHouse = 0
                        val['Final Need'] = val['Need'] - val.inHouse
                    }
                    return val
                })
                let data1 = this.state.rows.map((val, keys) => {
                    if (keys == newProps.inhousestock.key) {
                        val.inHouse = 0
                        val.finalNeed = val.totalNeed - val.inHouse
                        val.oldStock = val.newStock ? val.newStock : null
                        val.newStock = undefined
                    }
                    return val
                })
                this.setState({
                    ...this.state,
                    data: {
                        ...this.state.data,
                        rows: dataa
                    },
                    rows: data1,
                    inHouse: inHouse
                })
                this.props.initialState();
            }
        } else {
            if (unit && unit.success) {
                let data = unit && unit.order.map((value, key) => {
                    let datainner = {}
                    this.state.varient.map((items, i) => {
                        datainner[items.field] = ''
                        value.unitsvarients && value.unitsvarients.map(val => {
                            if (val.varientId == items.id) {
                                if(items.name === 'color'){
                                    datainner[items.field] = [<span className="tooltip1" key={'abcd' + key}>{val.value !== null && val.value ? (val.value.length > 5 ? val.value.substr(0, 5) : val.value) : val.value}<span className="tooltiptext">{val.value}</span></span>]
                                  }else{
                                    datainner[items.field] = val.value != null ? val.value : ''
                                  }
                            }
                        })
                        return true;
                    })
                    let poSucc = value.addQTY != null && value.unitsSupllier != null && value.unitsMaterial != null && value.average != null && value.totalNeed != null && value.finalNeed != null && value.deliveryDate != null ? false : true
                    return ({
                        'Action': [<div style={{ display: 'flex' }}>{sessionStorage.userROLE == 'super-admin' || value.poStatus === 'Pending' || value.poStatus === null ? <><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i><i title="Add Row" className="fas fa-plus-circle cursor-pointer" key={'abce' + key} onClick={e => (this.onAdd(e, key))}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, value.id, poSucc))} ></i><input type="checkbox" style={{ minWidth: '15px', marginLeft: '5px' }} value={value.id} name="poGenrate" disabled={poSucc} key={key} title={poSucc === true ? 'Fill the row to generate PO.' : ''} onChange={e => (this.listPoGenrate(e, key))} /></> : <><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i><i title="Add Row" className="fas fa-plus-circle cursor-pointer" key={'abce' + key} onClick={e => (this.onAdd(e, key))}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, value.id, poSucc))} ></i></>}</div>],
                        'Sr No': ((this.state.pageNumber - 1) * 10) + (key + 1),
                        'Style No': value.unitsDesc.styleno,
                        'Style Code': [<span className="tooltip1" key={'abcdZ' + key}>{value.unitsDesc.stylecode !== null ? (value.unitsDesc.stylecode.length > 5 ? value.unitsDesc.stylecode.substr(value.unitsDesc.stylecode.length - 5) : value.unitsDesc.stylecode) : value.unitsDesc.stylecode}<span className="tooltiptext">{value.unitsDesc.stylecode}</span></span>],
                        'order qtty': value.unitsDesc.xs + value.unitsDesc.s + value.unitsDesc.m + value.unitsDesc.l + value.unitsDesc.xl + value.unitsDesc.xxl + value.unitsDesc['3xl'] + value.unitsDesc['4xl'] + value.unitsDesc['5xl'],
                        'Add Qtty': value.addQTY != null ? value.addQTY : '',
                        'Trim Code Supplier': [<span className="tooltip1" key={'abcdeZ' + key} >{(value.unitsSupllier && value.unitsSupllier.id) ? (value.unitsSupllier.companyName.length > 5 ? value.unitsSupllier.companyName.substr(0, 5) : value.unitsSupllier.companyName) : ''}<span className="tooltiptext">{value.unitsSupllier && value.unitsSupllier.companyName ? value.unitsSupllier.companyName : ''}</span></span>],
                        'Trim Code specification': [<span className="tooltip1" key={'abcdeZ' + key} >{(value.unitsMaterial && value.unitsMaterial.id) ? (value.unitsMaterial.name.length > 5 ? value.unitsMaterial.name.substr(0, 5) : value.unitsMaterial.name) : ''}<span className="tooltiptext">{value.unitsMaterial && value.unitsMaterial.name ? value.unitsMaterial.name : ''}</span></span>],
                        'Average': value.average != null ? value.average : '',
                        ...datainner,
                        'Need': value.totalNeed != null ? value.totalNeed : '',
                        'inHouse': value.inHouse != null ? value.inHouse : '',
                        'Final Need': value.finalNeed != null ? value.finalNeed : 0,
                        'Image': value.image != null ? [<img className="cursor-pointer" onClick={e => this.openSlider(e, imagerUrl + value.image)} src={imagerUrl + value.image} width="50" style={{ height: '50px', borderRadius: '50%', objectFit: 'cover' }} />] : '',
                        'Price': value.price != null ? value.price : '',
                        'Delivery': value.deliveryDate != null ? moment(value.deliveryDate).format('DD/MM/YY') : '',
                        'Remarks': [<span className="tooltip1" key={'abcd' + key}>{value.remarks !== null && value.remarks ? (value.remarks.length > 5 ? value.remarks.substr(0, 5) : value.remarks) : value.remarks}<span className="tooltiptext">{value.remarks}</span></span>],
                        'po status': value.poStatus != null ? <>{value.poStatus === 'Generated' || value.poStatus === 'Regenerated' ? <i class="fas fa-check" title={value.poStatus} style={{ color: '#4dba4f' }}></i> : <><i style={{ color: '#f15d5d' }} class="far fa-clock" title='Pending'></i></>}</> : <><i style={{ color: '#f15d5d' }} class="far fa-clock" title='Pending'></i></>,
                        'recd status': value.recdStatus != null ? value.recdStatus : '',
                        'pending status': value.pendingStatus != null ? value.pendingStatus : '',
                    })
                })
                let supllierDetail = []
                let dataValue = unit && unit.order.map((value, key) => {
                    let datainner = {}
                    supllierDetail.push(value.unitsSupllier)
                    this.state.varient.map((items, i) => {
                        datainner[items.name] = ''
                        value.unitsvarients && value.unitsvarients.map(val => {
                            if (val.varientId == items.id) {
                                datainner[items.name] = val.value != '' ? val.value : ''
                            }
                        })
                        return true;
                    })

                    return ({
                        'orderid': value.id,
                        'orderDescId': value.orderDescId,
                        'brandOrderId': value.brandOrderId,
                        'addQtty': value.addQTY != null ? value.addQTY : '',
                        'supplier': value.supplierId != null ? `${value.supplierId}` : '',
                        'supplierText': value.unitsSupllier != null ? value.unitsSupllier.companyName : '',
                        'materialText': value.unitsMaterial != null ? value.unitsMaterial.name : '',
                        'materialId': value.materialId != null ? `${value.materialId}` : '',
                        'average': value.average != null ? value.average : '',
                        'totalNeed': value.totalNeed != null ? value.totalNeed : '',
                        'inHouse': value.inHouse != null ? value.inHouse : 0,
                        'finalNeed': value.finalNeed != null ? value.finalNeed : '',
                        ...datainner,
                        'pictureView': value.image != null ? imagerUrl + value.image : '',
                        'price': value.price != null ? value.price : '',
                        'deliveryDate': value.deliveryDate != null ? value.deliveryDate : '',
                        'Remarks': value.remarks != null ? value.remarks : '',
                        'poStatus': value.poStatus != null ? value.poStatus : 'Pending',
                        'recdStatus': value.recdStatus != null ? value.recdStatus : '',
                        'pendingStatus': value.pendingStatus != null ? value.pendingStatus : 0,
                        newStock: value.inhouseId != null ? value.inhouseId : undefined,
                        imagesPreviewUrls: '',
                        note: value.notes != null ? value.notes : ''
                    })
                })
                this.setState({
                    data: {
                        ...this.state.data,
                        rows: data
                    },
                    rows: dataValue,
                    total: unit.page,
                    supllierDetail: supllierDetail,
                    loaderTable: false,
                    pageHead: unit.cat,
                })
            } else {
                if(unit && unit.success === false){
                    this.setState({
                        loaderTable: false
                    })
                }
            }
        }
    }
    openSlider = (e, viewImg) => {
        this.setState({
            openViewImg: true,
            viewImg: viewImg
        })
    }
    searchChange = (e) => {
        const { brandOrderId, pageNumber, slug } = this.state
        this.setState({
            ...this.state,
            search: e.target.value
        })
        if (e.target.value !== '')
            this.props.getUnit({ brandOrderId, pageNumber: 1, search: e.target.value, slug });
        else
            this.props.getUnit({ brandOrderId, pageNumber: pageNumber, search: e.target.value, slug });

    }
    changePage = (e, pageNumber) => {
        const { brandOrderId, slug, search } = this.state
        this.setState({
            ...this.state,
            pageNumber: pageNumber,
            loaderTable: true,
            selectPoList: { id: [] }
        })
        this.props.getUnit({ brandOrderId, pageNumber: pageNumber, search, slug });
    }
    preview = (e, rowId, id, poSucc) => {
        const { data, varient, supllierDetail, rows } = this.state
        let poTable = [{}]
        let orderId, poStatus, noteGetRow
        let poSupplierDetail = []
        supllierDetail.map((items, key) => {
            if (rowId == key) {
                poSupplierDetail = items
            }
        })
        rows.map((item, key) => {
            if (rowId == key) {
                orderId = rows[rowId]['orderid']
                poStatus = rows[rowId]['poStatus']
                noteGetRow = rows[rowId]['note']
            }
        })
        data.rows.map((value, key) => {
            if (rowId == key) {
                poTable[0]['Sr No'] = key + 1
                poTable[0]['Style Code'] = data.rows[key]['Style Code']
                poTable[0]['Final Need'] = data.rows[key]['Final Need']
                poTable[0]['price'] = data.rows[key]['price']
                varient.map(items => {
                    poTable[0][items.field] = data.rows[key][items.field]
                })
                poTable[0]['Delivery'] = data.rows[key]['Delivery']
                poTable[0]['Remarks'] = data.rows[key]['Remarks']
            }
            return true;
        })
        if (poSupplierDetail !== null) {
            this.setState({
                previewData: {
                    columns: [
                        { label: "S.N.", field: "Sr No", class: 'colum_width' },
                        { label: 'Style code', field: 'Style code', class: 'colum_width' },
                        { label: 'Final Need', field: 'Final Need', class: 'colum_width' },
                        { label: 'price', field: 'price', class: '' },
                        ...varient,
                        { label: "Delivery", field: "Delivery", class: '' },
                        { label: 'Remarks', field: 'Remarks', class: '' },
                    ],
                    rows: poTable
                },
                previewDataId: id,
                orderIdPo: orderId,
                open: true,
                poStatus: poStatus,
                poSupplierDetail: poSupplierDetail,
                poSucc: poSucc,
                noteGetRow:noteGetRow
            })
        }
    }
    previewClose = () => {
        this.setState({
            open: false,
            openViewImg: false
        })
    }
    POSingle = (e, rowId) => {
        let selectPoList = {
            id: [rowId.toString(), null]
        }
        this.setState({
            ...this.state,
            loaderPo: true,
            loaderTable: true,
            open: false
        })
        this.props.unitPo(selectPoList)
    }
    poGenerate = (e, rowId) => {
        const { selectPoList, varient, rows } = this.state
        this.props.unitPo(selectPoList)
        this.setState({
            loaderPo: true,
            loaderTable: true
        })
    }
    listPoGenrate = (e, key) => {
        const { selectPoList } = this.state
        let index;
        if (e.target.checked) {
            this.setState({
                selectPoList: { ...selectPoList, id: [...selectPoList.id, e.target.value] }
            })
        } else {
            index = selectPoList.id.indexOf(e.target.value)
            selectPoList.id.splice(index, 1)
            this.setState({
                selectPoList: { ...selectPoList, id: [...selectPoList.id] }
            })
        }
    }
    componentWillUnmount() {
        // this.props.initialState()
    }
    render() {
        const { noteGetRow, brand, poStatus, name, brandImg, pageHead, previewData, open, suppliers, previewDataId, poSupplierDetail, loaderPo, loaderTable, openViewImg, viewImg, loaderVarient, slug, selectPoList, orderIdPo, poSucc } = this.state
        return (
            <>
                <ToastContainer autoClose={10000} />
                {
                    !loaderTable && !loaderVarient ?
                        <>
                            <Header />
                            <Container className="" fluid>
                                <Row>
                                    <div className="col">
                                        <Card className="border-0">
                                            <CardHeader className="border-0 pt-0">
                                                <h1 className="m-0 text-capitalize">{pageHead}</h1>
                                                <Row className="align-items-center">
                                                    <div className="col-md-8 d-flex pl-0">
                                                        <div style={{ paddingBottom: '5px', marginRight: '25px' }}>
                                                            <h6 className="text-uppercase text-light ls-1 mb-1">
                                                                Brand
                                                            </h6>
                                                            <h3 className="mb-0 profile_sec text-capitalize">
                                                                {
                                                                    (this.state.brandImg !== '' && this.state.brandImg !== null) ?
                                                                        <img className="mr-3" src={`${imagerUrl}` + this.state.brandImg} width="40" alt="brand"></img>
                                                                        : <img className="mr-3" src={'/logo.png'} width="40" alt="brand"></img>
                                                                }
                                                                {brand}</h3>
                                                        </div>
                                                        <div style={{ paddingBottom: '5px' }}>
                                                            <h6 className="text-uppercase text-light ls-1 mb-1">
                                                                Order name
                                                            </h6>
                                                            <h2 className="mb-0">
                                                                {name}</h2>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 justify-contant-flex-xs-start pl-0 text-right d-flex justify-contant-flex-end">
                                                        <Button style={{ marginRight: '20px' }} type="submit" className="btn btn-primary" disabled={loaderPo === true || selectPoList.id == undefined || selectPoList.id[0] == null ? true : false} color="primary" onClick={this.poGenerate}>{
                                                            loaderPo === true ?
                                                                <Spinner /> :
                                                                'Generate PO'
                                                        }</Button>
                                                        <Nav className="justify-content-end" pills>
                                                            <NavItem>
                                                                <NavLink className="btn btn-primary " to={'/unitwise/' + slug} tag={Link}  >Back</NavLink>
                                                            </NavItem>
                                                        </Nav>
                                                    </div>
                                                </Row>
                                            </CardHeader>
                                            <div className="table-responsive">
                                                <EditTable {...this.state} searchChange={this.searchChange} changePage={this.changePage} />
                                            </div>
                                        </Card>
                                    </div>
                                </Row>
                                <FormModel modelForm={<img src={viewImg} width="100%" />} toggle={this.previewClose} openForm={openViewImg} />
                                <PreviewModal previewData={previewData} toggle={this.previewClose} noteGetRow={noteGetRow} open={open} liningsupplier={suppliers} previewDataId={previewDataId} poSupplierDetail={poSupplierDetail} orderIdPo={orderIdPo} poStatus={poStatus} poSucc={poSucc} POSingle={this.POSingle} loaderPo={loaderPo} />
                            </Container>
                        </>
                        :
                        <div className="loader">
                            <Spinner variant="light" />
                            <p style={{ color: '#fff', paddingTop: '15px' }}>Please wait......</p>
                        </div>
                }
            </>
        )
    }
}
const mapDispatchState = (dispatch) => {
    return bindActionCreators({
        getBrandOrder,
        initialState,
        varientBySlug,
        noteinitialState,
        getUnit,
        unitSupplier,
        unitPo,
        getInhouseStocks,
        addUnit,
        addUnitPicture,
        varienInitial
    }, dispatch)
}

const mapGetStateNew = (state) => {
    return {
        brandorder: state.order.brandorder,
        unit: state.unitRatioWise.ratioWise,
        inhousestock: state.order.inhousestock,
        poGenrateSuccess: state.poPDF.poGenrate,
        varientList: state.varient.varient,
        supplierSuccess: state.supplier.supplier,
        noteSuccess: state.order.note
    }
}
export default connect(mapGetStateNew, mapDispatchState)(UnitWise);