import { call, put, takeLatest, all } from "redux-saga/effects";
import {
  SET_VARIENT_SUCCESS,
  SET_VARIENT_FAILED,
  GET_VARIENT,
  GET_VARIENT_DELETE,
  ADD_VARIENT_FAILED,
  ADD_VARIENT_SUCCESS,
  GET_VARIENT_ADD,
  DELETE_VARIENT_FAILED,
  DELETE_VARIENT_SUCCESS,
  VIEW_VARIENT_FAILED,
  GET_VARIENT_VIEW,
  VIEW_VARIENT_SUCCESS,
  GET_VARIENT_UPDATE,
  UPDATE_VARIENT_FAILED,
  GET_VARIENT_SLUG,
  UPDATE_VARIENT_SUCCESS,
  SET_VARIENT_SLUG_SUCCESS,
  SET_VARIENT_SLUG_FAILED
} from "../../sharedFile/actionType";
import Api from "../../lib/api";

function getVarientListApi(action) {
  return Api.get(
    `varient/`,
  );
}
function* getVarientList(action) {
  try {
    const resp = yield call(getVarientListApi, action);
    if (resp.success === true) {
      yield put({ type: SET_VARIENT_SUCCESS, data: resp })
      // sessionStorage.userJWT = resp.token
    } else {
      yield put({ type: SET_VARIENT_FAILED, error: 'Something Went Wrong' })
    }
  } catch (resp) {
    yield put({ type: SET_VARIENT_FAILED, error: 'Something Went Wrong' })
  }
}


function deleteVarientApi(action) {
  const { data } = action
  return Api.delete(
    `varient/` + data
  );
}
function* deleteVarient(action) {
  try {
    const resp = yield call(deleteVarientApi, action);
    if (resp.success === true) {
      yield put({ type: DELETE_VARIENT_SUCCESS, data: resp })
    } else {
      yield put({ type: DELETE_VARIENT_FAILED, error: resp })
    }
  } catch (resp) {
    yield put({ type: DELETE_VARIENT_FAILED, error: resp })
  }
}


function addVarientApi(action) {
  return Api.post(
    `varient/`,
    action.data
  );
}
function* addVarient(action) {
  try {
    const resp = yield call(addVarientApi, action);
    if (resp.success === true) {
      yield put({ type: ADD_VARIENT_SUCCESS, data: resp })
      yield put({ type: GET_VARIENT })
    } else {
      yield put({ type: ADD_VARIENT_FAILED, error: resp })
    }
  } catch (resp) {
    yield put({ type: ADD_VARIENT_FAILED, error: resp })
  }
}


function viewVarientApi(action) {
  const { data } = action
  return Api.get(
    `varient/` + data
  );
}
function* viewVarient(action) {
  try {
    const resp = yield call(viewVarientApi, action);
    if (resp.success === true) {
      yield put({ type: VIEW_VARIENT_SUCCESS, data: resp, redirect: false })
    } else {
      yield put({ type: VIEW_VARIENT_FAILED, error: resp.message })
    }
  } catch (resp) {
    yield put({ type: VIEW_VARIENT_FAILED, error: 'Something Went Wrong' })
  }
}

function updateVarientApi(action) {
  return Api.put(
    `varient/` + action.id,
    action.data
  );
}
function* updateVarient(action) {
  try {
    const resp = yield call(updateVarientApi, action);
    if (resp.success === true) {
      yield put({ type: UPDATE_VARIENT_SUCCESS, data: resp, redirect: true })
      yield put({ type: GET_VARIENT })
    } else {
      yield put({ type: UPDATE_VARIENT_FAILED, error: resp })
    }
  } catch (resp) {
    yield put({ type: UPDATE_VARIENT_FAILED, error: resp })
  }
}
function varientBySlugApi(action) {
  return Api.get(
    `varient/orderVarients/` + action.data,
  );
}
function* varientBySlug(action) {
  try {
    const resp = yield call(varientBySlugApi, action);
    if (resp.success === true) {
      yield put({ type: SET_VARIENT_SLUG_SUCCESS, data: resp })
    } else {
      yield put({ type: SET_VARIENT_SLUG_FAILED, error: resp.message })
    }
  } catch (resp) {
    yield put({ type: SET_VARIENT_SLUG_FAILED, error: 'Something Went Wrong' })
  }
}


function* varient() {
  yield all([
    takeLatest(GET_VARIENT, getVarientList),
    takeLatest(GET_VARIENT_DELETE, deleteVarient),
    takeLatest(GET_VARIENT_ADD, addVarient),
    takeLatest(GET_VARIENT_VIEW, viewVarient),
    takeLatest(GET_VARIENT_UPDATE, updateVarient),
    takeLatest(GET_VARIENT_SLUG, varientBySlug)
  ])
}
export default varient;