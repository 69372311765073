
import React from "react";
import { Link } from "react-router-dom";
import { MDBDataTable, MDBIcon } from 'mdbreact';
import './suppliertable.css';
// reactstrap components
import {
  Card,
  CardHeader,
  Button,
  NavItem,
  NavLink,
  Nav,
  Row
} from "reactstrap";
import moment from 'moment';

function Tables(props) {
  var testData = props.data && props.data.suppliers && props.data.suppliers.map((items, key) => {
    let fullAddress = items.address + ', ' + items.city + ', ' + items.state + (items.country === null ? '' : ', ' + items.country) + ', ' + items.zip
    return (
      {
        '#': key + 1,
        'Company Name': items.companyName,
        'Name': items.firstName + ' ' + items.lastName,
        'Category': items.suppliercategory.name,
        'Email': items.email,
        'Mobile': items.mobile != '' ? items.mobile : " ",
        'Address': fullAddress,
        'Created on': moment(items.createdAt).utcOffset("+05:30").format('DD/MM/YY, H:mm'),
        'Updated on': moment(items.updatedAt).format('DD/MM/YY, H:mm'),
        'Action': [<>{sessionStorage.userROLE == 'super-admin' ? <><Button className="action_btn text-black" outline color="" tag={Link} to={`view-supplier/${items.id}`}><MDBIcon far icon="eye" title="View" /></Button><Button className="action_btn text-black" outline color="" tag={Link} to={`/edit-supplier/${items.id}`}><MDBIcon far icon="edit" title="Edit" /></Button><Button className="action_btn text-black" outline color="" onClick={e => props.confirm(e, items.id, items.name)}><MDBIcon far icon="trash-alt" title="Delete" /></Button> </> : <><Button className="action_btn text-black" outline color="" tag={Link} to={`view-supplier/${items.id}`}><MDBIcon far icon="eye" title="View" /></Button></>}</>]
      }
    )
  })

  const data = {
    columns: [
      { label: '#', field: '#', sort: 'asc', },
      { label: 'Supplier Name', field: 'Name', sort: 'asc', },
      { label: 'Business Name', field: 'Company Name', sort: 'asc', },
      { label: 'Category', field: 'Category', sort: 'asc', },
      { label: 'Supplier Email', field: 'Email', sort: 'asc', },
      { label: 'Supplier Mobile #', field: 'Mobile', sort: 'asc', },
      { label: 'Supplier Address', field: 'Address', sort: 'asc', },
      { label: 'Created on', field: 'Created on', sort: 'asc' },
      { label: 'Updated on', field: 'Updated on', sort: 'asc' },
      { label: 'Action', field: 'Action', sort: 'disabled', }
    ],
    rows: testData
  };
  return (
    <Card className="card_border">
      <CardHeader className="border-0 pt-0">
        <Row className="align-items-center mobile_sec_col">
          <div className="col">
            <h2 className="mb-0">{props.title}</h2>
          </div>
          <div className="col">
            {sessionStorage.userROLE == 'super-admin' ?
              <Nav className="justify-content-end" pills>
                <NavItem>
                  <NavLink className="btn btn-primary" to="/add-supplier" tag={Link} >Add Supplier</NavLink>
                </NavItem>
              </Nav>
              :
              ''
            }
          </div>
        </Row>
      </CardHeader>
      <MDBDataTable
        className="tableSize"
        striped
        bordered
        searching={true}
        hover
        responsive
        data={data}
      />
    </Card>
  );
}

export default Tables;
