
import React from "react";
import EditForm from './editForm';
import { updateBrand, viewBrand, brandImageRemove } from '../../services/actions/brand'
import { initialState } from '../../services/actions/stateInitial'
import { connect } from 'react-redux';
import {
  Spinner
} from "reactstrap";
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import { addBrandValid } from '../../sharedFile/validator';
var toastId = null
class EditBrand extends React.Component {
  state = {
    data: {
      name: '',
      logo: '',
      description: '',
      picture: []
    },
    imagesPreviewUrls: [],
    errors: {},
    loader: false,
    loaderView: false,
    message: ""
  }
  componentDidMount() {
    this.props.viewBrand(this.props.editId);
    this.setState({
      loaderView: true
    })
  }
  onSubmit = (e) => {
    e.preventDefault();
    const errors = addBrandValid(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({
        loader: true
      })
      this.props.updateBrand(this.props.editId, this.state.data)
    }
  }

  onFileChange = (e) => {
    let files = Array.from(e.target.files);
    files.forEach((file) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          data: {
            ...this.state.data,
            picture: [file],
          },
          imagesPreviewUrls: [reader.result]
        });
      }
      reader.readAsDataURL(file);
    });
  }
  onChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value.trimLeft().replace(/\s\s+/g, ' ')
      }
    })
  }
  onRemove = () => {
    this.setState({ 
      data: { 
        ...this.state.data,
        picture: [],
        logo: '' 
      },
      imagesPreviewUrls:[]
    });
    this.props.brandImageRemove(this.props.editId)
  }
  componentWillReceiveProps(newProps) {
    const { detailbrand } = newProps
    if (detailbrand && detailbrand.success) {
      const brand = detailbrand.brand
      this.setState({
        data: {
          ...this.state.data,
          name: brand.name,
          description: brand.description,
          logo: brand.logo
        },
        loader: false,
        loaderView: false
      })
      if (detailbrand.message) {
        if (toast.isActive(toastId)) {
          toast.dismiss(toastId)
          toastId = toast.success(detailbrand.message);
          newProps.initialState()
        } else {
          toastId = toast.success(detailbrand.message);
          newProps.initialState()
        }
      }
    } if (detailbrand && detailbrand.success === false) {
      this.setState({
        loader: false,
        loaderView: false
      })
      newProps.initialState()
    }
  }
  componentWillUnmount() {
    this.props.initialState()
  }
  render() {
    const { loaderView } = this.state
    return (
      <>
        {
          !loaderView ?
            <EditForm {...this.state} onSubmit={this.onSubmit} onFileChange={this.onFileChange} onRemove={this.onRemove} onChange={this.onChange} />
            :
            <div className="text-center">
              <Spinner />
            </div>
        }
      </>
    );
  }
}
const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    viewBrand,
    updateBrand,
    initialState,
    brandImageRemove
  }, dispatch)
}
const mapGetStateNew = (state) => {
  return {
    detailbrand: state.brand.detailbrand,
  }
}
export default connect(mapGetStateNew, mapDispatchState)(EditBrand);
