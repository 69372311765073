import React from "react";
import Header from "../../../components/Headers/Header";
import ProfileForm from './profileForm';
import { getProfile, UpdateProfile } from '../../../services/actions/auth'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { profileValid, profilePasswordValid } from '../../../sharedFile/validator';
import { ToastContainer, toast } from 'react-toastify';
import ChangePassword from "./changePassword";
var toastId = null
class Profile extends React.Component {
  state = {
    data: {
      username: '',
      email: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      address_one: '',
      address_two: '',
      city: '',
      state: '',
      postal_code: '',
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    },
    changePassword: false,
    errors: {},
    loader: false,
    message: ""
  }
  onSubmit = (e) => {
    e.preventDefault();
    const errors = profileValid(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({
        loader: true
      })
      this.props.UpdateProfile(this.state.data)
    }
  }
  onSubmitPassword = (e) => {
    e.preventDefault();
    const errors = profilePasswordValid(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({
        loader: true
      })
      this.props.UpdateProfile(this.state.data)
    }
  }
  onChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value
      }
    })
  }
  componentDidMount() {
    this.props.getProfile()
  }
  changePassword = () => {
    this.setState({
      changePassword: !this.state.changePassword
    })
  }
  componentWillReceiveProps(newProps) {
    const profile = newProps.profile.user && newProps.profile.user.useremployees[0]
    if (newProps.profile.success) {
      this.setState({
        data: {
          ...this.state.data,
          username: newProps.profile.user && newProps.profile.user.userName,
          email: newProps.profile.user && newProps.profile.user.email,
          first_name: profile.firstName,
          last_name: profile.lastName,
          phone_number: profile.phone,
          address_one: profile.address1,
          address_two: profile.address2,
          city: profile.city,
          state: profile.state,
          postal_code: profile.zip,
          oldPassword: '',
          newPassword: '',
          confirmPassword: ''
        },
        loader: false
      })
      if (!toast.isActive(toastId))
        toastId = toast.success(newProps.profile.message);
    }
    if (newProps.profile.success === false) {
      this.setState({
        loader: false
      })
      if (!toast.isActive(toastId))
        toastId = toast.error(newProps.profile.message);
    }
  }
  render() {
    const { changePassword } = this.state
    return (
      <>
        <Header />
        <ToastContainer autoClose={10000} />
        {
          changePassword ?
            <ChangePassword {...this.state} onSubmitPassword={this.onSubmitPassword} onChange={this.onChange} changePassword={this.changePassword} />
            :
            <ProfileForm  {...this.state} changePassword={this.changePassword} onSubmit={this.onSubmit} onChange={this.onChange} />
        }

      </>
    );
  }
}
const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    getProfile,
    UpdateProfile
  }, dispatch)
}
const mapGetStateNew = (state) => {
  return {
    profile: state.user.profile
  }
}
export default connect(mapGetStateNew, mapDispatchState)(Profile);
