import { 
    GET_CATEGORY, 
    GET_ALLCATEGORY,
    GET_CATEGORY_ADD, 
    GET_CATEGORY_DELETE,
    GET_CATEGORY_VIEW,
    GET_CATEGORY_UPDATE,
    GET_ASSIGN_VARIENT,
    GET_VIEW_ASSIGN_VARIENT,
    GET_DELETE_ASSIGN_VARIENT,
    GET_CATEGORY_ROUTE
} from "../../sharedFile/actionType";

export const allCategory = ()=>({
    type:GET_ALLCATEGORY
})

export const category = ()=>({
    type:GET_CATEGORY
})
export const categoryRoute = ()=>({
    type:GET_CATEGORY_ROUTE
})

export const addCategory = (data) => ({
    type:GET_CATEGORY_ADD,
    data
})

export const deleteCategory = (data) => ({
    type:GET_CATEGORY_DELETE,
    data
})

export const viewCategory = (data) => ({
    type: GET_CATEGORY_VIEW,
    data
})

export const updateCategory = (id,data) => ({
    type: GET_CATEGORY_UPDATE,
    id,
    data
})

export const assignVarient = (data) => ({
    type: GET_ASSIGN_VARIENT,
    data
})
export const viewAssignVarient = (data) => ({
    type: GET_VIEW_ASSIGN_VARIENT,
    data
})
export const deleteAssignVarient = (data) => ({
    type: GET_DELETE_ASSIGN_VARIENT,
    data
})