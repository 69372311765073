import React from 'react';
import {  Button,  CardBody,  FormGroup,  Form,  Input,  Container,  Row,  Col,  FormText,  Spinner} from "reactstrap";
import InlineError from '../../sharedFile/inlineError'
import Required from '../../sharedFile/required';
function editForm(props) {
  const { data, errors, loader, imagesPreviewUrls,errImage } = props
  return (
    <Container className="mt-0" fluid>
      <Row>
        <Col className="order-xl-1" xl="12">
          <Row className="align-items-center mobile_sec_col">
            <div className="col">
              <h2 className="mb-0 text-center">Edit Material</h2>

              <span className="italic-message">* - indicate required fields!!</span>
            </div>
          </Row>
          <Form onSubmit={props.onSubmit}>
            <div className="">

              <FormGroup>
                <Col lg="12">
                  <FormGroup error={!errors.name}>
                    <label
                      className="form-control-label"
                      htmlFor="input-name"
                    >
                      Material Name<Required />
                    </label>
                    <Input
                      className="form-control-alternative"
                      value={data.name}
                      onChange={props.onChange}
                      id="input-name"
                      maxLength="100"
                      placeholder="Name"
                      name="name"
                      type="text"
                    />
                    {errors.name && <InlineError text={errors.name} />}
                  </FormGroup>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col lg="12">
                  <label className="form-control-label">Sub Catgeory<Required /></label>
                  <Input
                    className="form-control-alternative"
                    defaultValue={data.category}
                    id="input-name"
                    type="text"
                    disabled
                  />
                </Col>
              </FormGroup>
              {
                imagesPreviewUrls.length ?
                  <Col lg="12" >
                    <CardBody className="p-0">
                      <FormGroup className="m-0">
                        <Row className="pl-0 pl-sm-3">
                          {
                            imagesPreviewUrls.map((items, key) => {
                              return <Col error={!errImage.picture} xs={3} className="mb-3 px-1"><div className="upload_img position-relative" key={key}>
                                <img src={items.url} width="100%" height="90px" style={{ objectFit: 'cover', border: '1px solid #e1e1e1', borderRadius: '6px' }} />
                                <p className="position-absolute icon-file" style={{ padding: '0px 9px' }} onClick={e => props.onRemove(e, key, items.id)}><i class="fas fa-times"></i></p>
                              </div>
                              {errImage&&errImage.picture&&errImage.picture.map((err, keyList) => { if (key === err.key) return err.err && <InlineError text={err.err} /> })}
                              </Col>
                            })
                          }
                        </Row>
                      </FormGroup>
                    </CardBody>
                    
                  </Col>
                  :
                  ''
              }
              <FormGroup>
                <Col lg="12" className="upload_sheet">
                  <label
                    className="form-control-label"
                    htmlFor="input-name"
                  >
                    Add Material Images
                            </label>
                  <FormGroup error={!errors.picture} style={{ width: '100%', position: 'relative' }}>
                    <label
                      className="form-control-label custom_label_input cursor-pointer"
                      htmlFor="input-image"
                      for="exampleFile"
                      style={{maxWidth: '127px'}}
                    >
                      <i class="fa fa-image" aria-hidden="true"></i> Add images
                            </label>
                    <Input type="file"
                      className="form-control-alternative"
                      multiple placeholder="Name" name="picture" id="exampleFile" accept="image/gif,image/jpeg,image/png" onChange={props.onFileChange} />
                    {errors.picture && <InlineError text={errors.picture} />}
                    <FormText color="muted">*Press CTRL and select multiple images</FormText>
                  </FormGroup>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col lg="12">
                  <FormGroup error={!errors.description}>
                    <label
                      className="form-control-label"
                      htmlFor="input-description"
                    >
                      Material Description<Required />
                    </label>
                    <Input
                      className="form-control-alternative"
                      value={data.description}
                      onChange={props.onChange}
                      name="description"
                      id="input-description"
                      placeholder="Description"
                      type="textarea"
                    />
                    {errors.description && <InlineError text={errors.description} />}
                  </FormGroup>
                </Col>

              </FormGroup>

            </div>
            <Col lg="12" className="custom-submit">
              <Button type="submit" disabled={loader ? true : false} className="submit-button" color="primary">
                {
                  loader
                    ?
                    <Spinner color="light" />
                    :
                    'Submit'
                }

              </Button>
            </Col>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}
export default editForm;