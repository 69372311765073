import {
    SET_ADD_INHOUSE_SUCCESS,
    SET_ADD_INHOUSE_FAILED,
    SET_DELETE_INHOUSE_SUCCESS,
    SET_DELETE_INHOUSE_FAILED,
    VIEW_INHOUSE_FAILED,
    VIEW_INHOUSE_SUCCESS,
    GET_INITIALSTATE
} from "../../sharedFile/actionType";

const initialState = {
    inHouse: [],
    detailinhouse: [],
    error: false,
    deleted:false,
}
const inHouse = (state = initialState, action) => {
    switch (action.type) {
        case SET_DELETE_INHOUSE_FAILED:
            return{
                ...state,
                deleteInhouse:action.error
            }
        case SET_ADD_INHOUSE_SUCCESS:
            return {
                ...state,
                inHouse: action.data
            }
        case SET_ADD_INHOUSE_FAILED:
            return {
                ...state,
                error: true,
                inHouse: action.error
            }
        case VIEW_INHOUSE_SUCCESS:
            return {
                ...state,
                detailinhouse: action.data
            }
        case VIEW_INHOUSE_FAILED:
            return {
                ...state,
                error: true,
                detailinhouse: action.error
            }
        case SET_DELETE_INHOUSE_SUCCESS:
            return {
                ...state,
                detailinhouse: { inhouse: state.detailinhouse.inhouse.filter(item => item.id !== action.id), success: true, message:'Inhouse Deleted Successfully' },
                deleted:true
            }
        case GET_INITIALSTATE:
            return{
                ...state,
                inHouse:[],
                deleteInhouse:[],
                deleted:false
            }
        default:
            return state
    }

}
export default inHouse;