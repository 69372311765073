import React from "react";
import { NavLink as NavLinkRRD, Link, Redirect } from "react-router-dom";

// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  Row,
  NavItem,
  NavLink,
  Col
} from "reactstrap";
import { logout,getProfile } from '../../services/actions/auth';
import { initialState, noteinitialState } from '../../services/actions/order';
import { initialStateMaterial } from '../../services/actions/material';
import { subCateInitial } from '../../services/actions/dashboard';
import { initialStateSupplier } from '../../services/actions/supplier';
import { varienInitial } from '../../services/actions/varient';
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import css from '../../assets/css/custom.css'
// import shyam_baba from '../../../assets/shyam_baba.png'

class AdminNavbar extends React.Component {
  state = {
    collapseOpen: false,
    orderSub:false
  };
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false
    });
  };
  orderColl = () =>{
    this.setState({
      orderSub:true
    })
    setTimeout(()=>{
      this.setState({
        orderSub:false
      })
    },1000)
  }
componentDidMount(){
  this.props.getProfile()
  this.setState({
    orderSub:false
  })
}
  openCollapse = () => {
    this.setState({
      collapseOpen: true
    });
  };

  // creates the links that appear in the left menu / Sidebar
  createSub = (subCate) => {
    const fabric = 'fabric'
    const zipper = 'main_zipper'
    const quilting = 'main_fabric'
    const ratiowise = 'ratiowise'
    const unitwise = 'unitwise'
    return subCate.map(items => {
      return (
        <NavLink className="position-relative sub_li" activeClassName="active" >
          {items.name}
          {
            items.slug == 'fabric-family' ?
              <Nav navbar className="sub_menu">
                {this.createMenu(items, fabric)}
              </Nav> : items.slug == 'zipper-family' ?
                <Nav navbar className="sub_menu">
                  {this.createMenu(items, zipper)}
                </Nav> : items.slug == 'quilting-family' ?
                  <Nav navbar className="sub_menu">
                    {this.createMenu(items, quilting)}
                  </Nav> : items.slug == 'unitwise-family' ?
                    <Nav navbar className="sub_menu">
                      {this.createMenu(items, unitwise)}
                    </Nav> : items.slug == 'ratiowise-family' ?
                      <Nav navbar className="sub_menu">
                        {this.createMenu(items, ratiowise)}
                      </Nav> : items.slug == 'rib-dhaaga-family' ?
                        <Nav navbar className="sub_menu">
                          <NavItem>
                            <NavLink className="" to='/ribdhaaga/kora_dhaaga' tag={NavLinkRRD} activeClassName="active" onClick={this.orderColl}>
                              KORA YARN
                          </NavLink>
                            <NavLink to='/ribdhaaga/dhaaga_color' tag={NavLinkRRD} activeClassName="active" onClick={this.orderColl}>
                              COLOR YARN
                </NavLink>
                          </NavItem>
                        </Nav> : ''
          }
        </NavLink>
      )
    })
  }
  createMenu = (items, list) => {
    return items.subcategoryCategory.map(items => {
      return (
        <NavItem>
          <NavLink className="dd" to={'/' + list + '/' + items.slug} tag={NavLinkRRD} activeClassName="active" onClick={this.orderColl}>
            {items.name}
          </NavLink>
        </NavItem>
      )
    })
  }
  createLinks = routes => {
    const { subCate } = this.props
    const {orderSub} = this.state
    return routes.map((prop, key) => {
      return (
        <NavItem id="getLinksOrder" className={orderSub ? "linkOrder dropdown_nav position-relative" :"dropdown_nav position-relative"} key={key} >
          <NavLink
            to={prop.path}
            tag={NavLinkRRD}
            onClick={prop.name !== 'Orders' ? this.closeCollapse : ''}
            activeClassName="active"
          >
            {prop.name}
          </NavLink>
          {
            prop.name === 'Orders' &&
            <Nav navbar className="ul_links">
              <NavItem>
                <NavLink className="position-relative sub_li" activeClassName="active" >
                  Brand Orders
                  {
                    <Nav navbar className="sub_menu">
                      <NavItem>
                        <NavLink className="" to='/Orders' tag={NavLinkRRD} activeClassName="active" onClick={this.orderColl}>
                          Add Orders
                        </NavLink>
                        <NavLink to='/oldOrders' tag={NavLinkRRD} activeClassName="active" onClick={this.orderColl}>
                          Old Orders
                        </NavLink>
                      </NavItem>
                    </Nav>

                  }
                </NavLink>
                {subCate && subCate.categories && this.createSub(subCate.categories)}
              </NavItem>
            </Nav>
          }
        </NavItem>
      );
    });
  };
  roleLinks = (subCate) => {
    const fabric = 'fabric'
    const zipper = 'main_zipper'
    const quilting = 'main_fabric'
    const ratiowise = 'ratiowise'
    const unitwise = 'unitwise'
    const ribdhaaga = 'ribdhaaga'
    return subCate.map(items => {
      return (
        <>
          {
            items.slug == 'fabric-family' ?
              this.roleLinksShow(items, fabric)
              : items.slug == 'zipper-family' ?
                this.roleLinksShow(items, zipper)
                : items.slug == 'quilting-family' ?
                  this.roleLinksShow(items, quilting)
                  : items.slug == 'unitwise-family' ?
                    this.roleLinksShow(items, unitwise)
                    : items.slug == 'ratiowise-family' ?
                      this.roleLinksShow(items, ratiowise)
                      : items.slug == 'rib-dhaaga-family' ?
                        this.roleLinksShow(items, ribdhaaga) : ''
          }
        </>
      )
    })
  }
  roleLinksShow = (items, action) => {
    const { pathname } = this.props.location
    return items.subcategoryCategory.map(items => {
      return (
        <>
          {sessionStorage.userROLE == items.slug + '_worker' ?
            <>
              <NavItem>
                <NavLink className="" to={'/' + action + '/' + items.slug} tag={NavLinkRRD} activeClassName="active">
                  {items.name}
                </NavLink>
              </NavItem>
              <>{pathname !== '/dashboard' ?
                '' : <Redirect to={'/' + action + '/' + items.slug} />}</>
            </>
            : ''
          }

        </>
      )
    })
  }
  onCLick = () => {
    this.props.logout()
    this.props.initialState()
    this.props.noteinitialState() 
    this.props.initialStateMaterial()
    this.props.subCateInitial()
    this.props.initialStateSupplier()
    this.props.varienInitial()
  }
  render() {
    const { bgColor, routes, logo, isRole, subCate,profile } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank"
      };
    }
    return (
      <>
        <Navbar className="navbar-dark bg-gradient-info headerNavbar" expand="md" id="navbar-main">
          <Container fluid>
            <Navbar
              className=""
              expand="md"
              id="sidenav-main"
              style={{ width: "100%" }}
            >
              <div className="d-flex custom_nav_image">
                <span className="avatar avatar-sm logo_image">
                  <img
                    alt="..."
                    src={require("../../assets/img/shyam_baba.png")}
                  />
                </span>
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.toggleCollapse}
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <Collapse navbar isOpen={this.state.collapseOpen}>
                  <div className="navbar-collapse-header d-md-none responsive_cross">
                    <Row>
                      <Col className="collapse-close mt-1 p-0" xs="12">
                        <button
                          className="navbar-toggler"
                          type="button"
                          onClick={this.toggleCollapse}
                        >
                          <span />
                          <span />
                        </button>
                      </Col>
                    </Row>
                  </div>

                  <Nav navbar>
                    {
                      isRole === 'super-admin' ?
                        <>{this.createLinks(routes)}</> :
                        <>
                          <NavItem className="dropdown_nav position-relative" >
                            <NavLink
                              to='/suppliers'
                              tag={NavLinkRRD}
                              activeClassName="active"
                            >
                              Suppliers
                            </NavLink>

                          </NavItem>
                          <NavItem className="dropdown_nav position-relative" >
                            <NavLink
                              to='/material'
                              tag={NavLinkRRD}
                              activeClassName="active"
                            >
                              Material
                            </NavLink>

                          </NavItem>
                          <NavItem className="dropdown_nav position-relative" >
                            <NavLink
                              to='/inhouses'
                              tag={NavLinkRRD}
                              activeClassName="active"
                            >
                              Inhouses
                            </NavLink>

                          </NavItem>
                          {subCate && subCate.categories && this.roleLinks(subCate.categories)}
                        </>
                    }
                  </Nav>
                </Collapse>
              </div>
              <Nav className="align-items-center justify-content-end headerProfile">
              <UncontrolledDropdown className="profileTitle" nav>
                <p className="profileName">Welcome {profile.user && profile.user.userName}</p>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav>
                  <DropdownToggle nav>
                    <Media className="align-items-center">
                      <span className="avatar avatar-sm rounded-circle">
                        <img
                          alt="..."
                          src={require("../../assets/img/default-user-image.png")}
                        />
                      </span>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem to="/profile" tag={Link} >
                      <i className="ni ni-single-02" />
                      <span>My profile</span>
                    </DropdownItem>
                    <DropdownItem divider /> 
                    <DropdownItem className="cursor-pointer" onClick={this.onCLick}>
                      <i className="ni ni-user-run" />
                      <span>Logout</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Navbar>
          </Container>
        </Navbar>
      </>

    );
  }
}
AdminNavbar.defaultProps = {
  routes: [{}]
};
AdminNavbar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired
  })
};
const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    logout,
    getProfile,
    noteinitialState,
    initialState,
    initialStateMaterial,
    subCateInitial,
    initialStateSupplier,
    varienInitial,
  }, dispatch)
}
const mapGetStateNew = (state) => {
  return {
    logout: state.user.logout,
    isRole: state.user.role,
    subCate: state.category.routeCategory,
    profile: state.user.profile
  }
}
export default connect(mapGetStateNew, mapDispatchState)(AdminNavbar);
