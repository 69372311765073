import { GET_LOGIN, GET_FORGET_PASSWORD, SET_LOGIN_SUCCESS, GET_PROFILE_DETAIL, GET_PROFILE_UPDATE, GET_LOG_OUT, GET_USER_ADD, GET_ROLE, GET_USER_LIST, DELETE_USER, GET_USER_DETAIL, GET_USER_UPDATE } from "../../sharedFile/actionType";

export const login = (data) => ({
    type: GET_LOGIN,
    data,
})
export const forgetpassword = (data) => {
    return {
        type: GET_FORGET_PASSWORD,
        data,
    }
}
export const userLoggedin = (token, role) => ({
    type: SET_LOGIN_SUCCESS,
    token,
    role
})
export const getProfile = () => ({
    type: GET_PROFILE_DETAIL,
})
export const UpdateProfile = (data) => ({
    type: GET_PROFILE_UPDATE,
    data
})
export const logout = () => ({
    type: GET_LOG_OUT,
})
export const userAdd = (data) => ({
    type: GET_USER_ADD,
    data
})
export const roleUserList = () => ({
    type: GET_ROLE,
})
export const userList = () => ({
    type: GET_USER_LIST,
})
export const deleteUser = (data) => ({
    type: DELETE_USER,
    data
})
export const userDetail = (data) => ({
    type: GET_USER_DETAIL,
    data
})
export const userUpdate = (data) => ({
    type: GET_USER_UPDATE,
    data
})