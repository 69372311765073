import React from 'react';
import {
    Row,
    Col,
    Card,
    Spinner,
    Button,
    CardBody,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import orders from '../../../assets/orders.png'
import { MDBDataTable, MDBIcon } from 'mdbreact';

export default function OldOrderList(props) {
    const { order, url, loaderBrandList } = props
    let tableData = order && order.order && order.order.map((items, key) => {
        return ({
            '#': key + 1,
            Name: items.name,
            Created: moment(items.createdAt).utcOffset("+05:30").format('DD/MM/YY, H:mm'),
            Action: [
                <Link to={{ pathname: url + '/' + items.id, state: url }} style={{ width: '85%' }}>
                    <MDBIcon far icon="eye" title="View" />
                </Link>,
                <>{sessionStorage.userROLE == 'super-admin' ? <Button className="action_btn text-black" outline color="" onClick={e => props.confirm(e, items.id, items.name)}><MDBIcon far icon="trash-alt" title="Delete" /></Button> : <></>}</>
            ]
        })
    })
    let dataTable = {
        columns: [
            {
                label: '#',
                field: '#',
                sort: 'asc'
            },
            {
                label: 'Order Name',
                field: 'Name',
                sort: 'asc'
            },
            {
                label: 'Created On',
                field: 'Created',
                sort: 'asc'
            },
            {
                label: 'Action',
                field: 'Action',
                sort: 'disabled',
            }
        ],
        rows: tableData
    }
    return (
        <Row>
            {
                // order && order.order && order.order.length > 0 ?
                    <Col className="order-xl-1 res_padd p-0" xl="12">
                        <CardBody className="p-0">
                            <Row>
                                {
                                    loaderBrandList ?
                                        <div className="loader-select">
                                            <Spinner color="danger" />
                                        </div>
                                        :
                                        <div className="table-responsive">
                                            <MDBDataTable
                                                striped
                                                bordered
                                                responsive
                                                hover
                                                data={dataTable} />
                                        </div>
                                }
                                {/* {order && order.order && order.order.map(items => {
                                    return <Col md="6" lg="3" className="mb-4">
                                        <Row className="bg-white shadow p-3 rounded align-items-center">
                                            <Col xs="2" className="p-0"><Link to={{ pathname: url + '/' + items.id, state: url }}><img src={orders} className="img-fluid responsive-img" ></img></Link></Col>
                                            <Col xs="10" className="order_content word-break">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <Link to={{ pathname: url + '/' + items.id, state: url }} style={{width:'85%'}}>
                                                        <h3 className="text-truncate">{items.name}</h3>
                                                        <p className="date mb-0">{moment(items.createdAt).utcOffset("+05:30")'LLL')}</p>
                                                    </Link>
                                                    <Button className="action_btn text-black" outline color="" onClick={e => props.confirm(e, items.id, items.name)}><MDBIcon far icon="trash-alt" /></Button>
                                                </div>
                                            </Col>

                                        </Row>
                                    </Col>
                                })} */}
                            </Row>
                        </CardBody>
                    </Col>
                    // : ''
            }
        </Row>
    )
} 