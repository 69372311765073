import { call, put, takeLatest, all } from "redux-saga/effects";
import {
    GET_DASHBOARD_COUNTS,
    GET_DASHBOARD_COUNTS_SUCCESS,
    GET_DASHBOARD_COUNTS_FAILED,
    GET_DASHBOARD_PO,
    GET_DASHBOARD_PO_SUCCESS,
    GET_DASHBOARD_PO_FAILED,
    GET_DASHBOARD_ORDER,
    GET_DASHBOARD_ORDER_SUCCESS,
    GET_DASHBOARD_ORDER_FAILED,
    GET_DASHBOARD_REVENUE,
    GET_DASHBOARD_REVENUE_SUCCESS,
    GET_DASHBOARD_REVENUE_FAILED,
    GET_DASHBOARD_PURCHASE_ORDER,
    GET_DASHBOARD_PURCHASE_ORDER_SUCCESS,
    GET_DASHBOARD_PURCHASE_ORDER_FAILED,
} from "../../sharedFile/actionType";
import Api from "../../lib/api";
function dashboardCountsApi() {
    return Api.get(
        `users/home/counts`
    );
}
function* dashboardCounts() {
    try {
        const resp = yield call(dashboardCountsApi);
        if (resp.success === true) {
            yield put({ type: GET_DASHBOARD_COUNTS_SUCCESS, data: resp })
        }
    } catch (resp) {
        yield put({ type: GET_DASHBOARD_COUNTS_FAILED, error: 'Something Went Wrong' })
    }
}
function dashboardPoApi() {
    return Api.get(
        `users/home/POgenerated`
    );
}
function* dashboardPo() {
    try {
        const resp = yield call(dashboardPoApi);
        if (resp.success === true) {
            yield put({ type: GET_DASHBOARD_PO_SUCCESS, data: resp })
        }
    } catch (resp) {
        yield put({ type: GET_DASHBOARD_PO_FAILED, error: 'Something Went Wrong' })
    }
}
function dashboardOrderApi(action) {
    return Api.post(
        `users/home/order`, action.data
    );
}
function* dashboardOrder(action) {
    try {
        const resp = yield call(dashboardOrderApi, action);
        if (resp.success === true) {
            yield put({ type: GET_DASHBOARD_ORDER_SUCCESS, data: resp })
        }
    } catch (resp) {
        yield put({ type: GET_DASHBOARD_ORDER_FAILED, error: 'Something Went Wrong' })
    }
}
function dashboardRevenueApi(action) {
    return Api.post(
        `users/home/revenue`, action.data
    );
}
function* dashboardRevenue(action) {
    try {
        const resp = yield call(dashboardRevenueApi, action);
        if (resp.success === true) {
            yield put({ type: GET_DASHBOARD_REVENUE_SUCCESS, data: resp })
        }
    } catch (resp) {
        yield put({ type: GET_DASHBOARD_REVENUE_FAILED, error: 'Something Went Wrong' })
    }
}
function purchaseOrderApi(action) {
    const { data } = action
    let query = ''
    if (data.subcategoryId) {
        query = query + `&subcategoryId=${data.subcategoryId}`
    }
    if (data.limit) {
        query = query + `&limit=${data.limit}`
    }
    return Api.get(
        `users/home/purchaseOrder?`+query
    );
}
function* purchaseOrder(action) {
    try {
        const resp = yield call(purchaseOrderApi, action);
        if (resp.success === true) {
            yield put({ type: GET_DASHBOARD_PURCHASE_ORDER_SUCCESS, data: resp })
        }
    } catch (resp) {
        yield put({ type: GET_DASHBOARD_PURCHASE_ORDER_FAILED, error: resp })
    }
}
function* dashboard() {
    yield all([
        takeLatest(GET_DASHBOARD_COUNTS, dashboardCounts),
        takeLatest(GET_DASHBOARD_PO, dashboardPo),
        takeLatest(GET_DASHBOARD_ORDER, dashboardOrder),
        takeLatest(GET_DASHBOARD_REVENUE, dashboardRevenue),
        takeLatest(GET_DASHBOARD_PURCHASE_ORDER, purchaseOrder),
    ])
}
export default dashboard;