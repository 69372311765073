export const GET_LOGIN = 'GET_LOGIN';
export const SET_LOGIN_SUCCESS = 'SET_LOGIN_SUCCESS';
export const SET_LOGIN_FAILED = 'SET_LOGIN_FAILED';
export const GET_FORGET_PASSWORD = 'GET_FORGET_PASSWORD';
export const SET_FORGET_SUCCESS = 'SET_FORGET_SUCCESS';
export const SET_FORGET_FAILED = 'SET_FORGET_FAILED';
export const GET_CATEGORY = 'GET_CATEGORY';
export const GET_CATEGORY_ROUTE = 'GET_CATEGORY_ROUTE';
export const GET_CATEGORY_ROUTE_SUCCESS = 'GET_CATEGORY_ROUTE_SUCCESS';
export const GET_CATEGORY_ROUTE_FAILED = 'GET_CATEGORY_ROUTE_FAILED';
export const GET_ALLCATEGORY = 'GET_ALLCATEGORY';
export const SET_ALLCATEGORY_SUCCESS = 'SET_ALLCATEGORY_SUCCESS';
export const SET_ALLCATEGORY_FAILED = 'SET_ALLCATEGORY_FAILED';
export const SET_CATEGORY_SUCCESS = 'SET_CATEGORY_SUCCESS';
export const SET_CATEGORY_FAILED = 'SET_CATEGORY_FAILED';
export const GET_VARIENT = 'GET_VARIENT';
export const SET_VARIENT_SUCCESS = 'SET_VARIENT_SUCCESS';
export const SET_VARIENT_FAILED = 'SET_VARIENT_FAILED';
export const GET_VARIENT_SLUG = 'GET_VARIENT_SLUG';
export const SET_VARIENT_SLUG_SUCCESS = 'SET_VARIENT_SLUG_SUCCESS';
export const SET_VARIENT_SLUG_FAILED = 'SET_VARIENT_SLUG_FAILED';
export const GET_MATERIAL = 'GET_MATERIAL';
export const SET_MATERIAL_SUCCESS = 'SET_MATERIAL_SUCCESS';
export const SET_MATERIAL_FAILED = 'SET_MATERIAL_FAILED';
export const GET_PROFILE_DETAIL = 'GET_PROFILE_DETAIL';
export const SET_PROFILE_DETAIL_SUCCESS = 'SET_PROFILE_DETAIL_SUCCESS';
export const SET_PROFILE_DETAIL_FAILED = 'SET_PROFILE_DETAIL_FAILED';
export const GET_PROFILE_UPDATE = 'GET_PROFILE_UPDATE';
export const GET_CATEGORY_ADD = 'GET_CATEGORY_ADD';
export const ADD_CATEGORY_FAILED = 'ADD_CATEGORY_FAILED';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const GET_CATEGORY_DELETE = 'GET_CATEGORY_DELETE'; 
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILED = 'DELETE_CATEGORY_FAILED';
export const GET_CATEGORY_VIEW = 'GET_CATEGORY_VIEW';
export const VIEW_CATEGORY_SUCCESS = 'VIEW_CATEGORY_SUCCESS';
export const VIEW_CATEGORY_FAILED = 'VIEW_CATEGORY_FAILED';
export const GET_CATEGORY_UPDATE = 'GET_CATEGORY_UPDATE';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS'; 
export const UPDATE_CATEGORY_FAILED = 'UPDATE_CATEGORY_FAILED';
export const GET_VARIENT_ADD = 'GET_VARIENT_ADD';
export const ADD_VARIENT_FAILED = 'ADD_VARIENT_FAILED';
export const ADD_VARIENT_SUCCESS = 'ADD_VARIENT_SUCCESS';
export const GET_VARIENT_DELETE = 'GET_VARIENT_DELETE'; 
export const DELETE_VARIENT_SUCCESS = 'DELETE_VARIENT_SUCCESS';
export const DELETE_VARIENT_FAILED = 'DELETE_VARIENT_FAILED';
export const GET_VARIENT_VIEW = 'GET_VARIENT_VIEW';
export const VIEW_VARIENT_SUCCESS = 'VIEW_VARIENT_SUCCESS';
export const VIEW_VARIENT_FAILED = 'VIEW_VARIENT_FAILED';
export const GET_VARIENT_UPDATE = 'GET_VARIENT_UPDATE';
export const UPDATE_VARIENT_SUCCESS = 'UPDATE_VARIENT_SUCCESS'; 
export const UPDATE_VARIENT_FAILED = 'UPDATE_VARIENT_FAILED';
export const GET_LOG_OUT = 'GET_LOG_OUT';
export const GET_SUPPLIER= 'GET_SUPPLIER';
export const SET_SUPPLIER_FAILED = 'SET_SUPPLIER_FAILED';
export const SET_SUPPLIER_SUCCESS= 'SET_SUPPLIER_SUCCESS';
export const GET_ASSIGN_VARIENT = 'GET_ASSIGN_VARIENT';
export const ASSIGN_VARIENT_SUCCESS = 'ASSIGN_VARIENT_SUCCESS';
export const ASSIGN_VARIENT_FAILED = 'ASSIGN_VARIENT_FAILED';
export const GET_VIEW_ASSIGN_VARIENT = 'GET_VIEW_ASSIGN_VARIENT';
export const VIEW_ASSIGN_VARIENT_SUCCESS = 'VIEW_ASSIGN_VARIENT_SUCCESS';
export const VIEW_ASSIGN_VARIENT_FAILED = 'VIEW_ASSIGN_VARIENT_FAILED';
export const GET_DELETE_ASSIGN_VARIENT = 'GET_DELETE_ASSIGN_VARIENT';
export const DELETE_ASSIGN_VARIENT_SUCCESS = 'DELETE_ASSIGN_VARIENT_SUCCESS';
export const DELETE_ASSIGN_VARIENT_FAILED = 'DELETE_ASSIGN_VARIENT_FAILED';
export const GET_MATERIAL_ADD = 'GET_MATERIAL_ADD';
export const ADD_MATERIAL_FAILED = 'ADD_MATERIAL_FAILED';
export const ADD_MATERIAL_SUCCESS = 'ADD_MATERIAL_SUCCESS';
export const DELETE_MATERIAL_FAILED = 'DELETE_MATERIAL_FAILED';
export const DELETE_MATERIAL_SUCCESS = 'DELETE_MATERIAL_SUCCESS'; 
export const VIEW_MATERIAL_FAILED = 'VIEW_MATERIAL_FAILED';
export const VIEW_MATERIAL_SUCCESS = 'VIEW_MATERIAL_SUCCESS'; 
export const UPDATE_MATERIAL_FAILED = 'UPDATE_MATERIAL_FAILED';
export const UPDATE_MATERIAL_SUCCESS = 'UPDATE_MATERIAL_SUCCESS';
export const GET_SUPPLIER_ADD= 'GET_SUPPLIER_ADD';
export const ADD_SUPPLIER_FAILED = 'ADD_SUPPLIER_FAILED';
export const ADD_SUPPLIER_SUCCESS = 'ADD_SUPPLIER_SUCCESS';
export const DETAIL_SUPPLIER_FAILED = 'DETAIL_SUPPLIER_FAILED';
export const DETAIL_SUPPLIER_SUCCESS = 'DETAIL_SUPPLIER_SUCCESS';
export const GET_SUPPLIER_UPDATE = 'GET_SUPPLIER_UPDATE';
export const GET_SUPPLIER_INITIAL = 'GET_SUPPLIER_INITIAL';
export const GET_SUPPLIER_VIEW = 'GET_SUPPLIER_VIEW';
export const GET_SUPPLIER_DELETE = 'GET_SUPPLIER_DELETE';
export const DELETE_SUPPLIER_SUCCESS = 'DELETE_SUPPLIER_SUCCESS';
export const DELETE_SUPPLIER_FAILED = 'DELETE_SUPPLIER_FAILED';
export const GET_MATERIAL_DELETE = 'GET_MATERIAL_DELETE';
export const GET_IMAGE_MATERIAL_DELETE = 'GET_IMAGE_MATERIAL_DELETE';
export const GET_IMAGE_MATERIAL_DELETE_SUCCESS = 'GET_IMAGE_MATERIAL_DELETE_SUCCESS';
export const GET_IMAGE_MATERIAL_DELETE_FAILED = 'GET_IMAGE_MATERIAL_DELETE_FAILED';
export const GET_MATERIAL_UPDATE = 'GET_MATERIAL_UPDATE';
export const GET_MATERIAL_VIEW = 'GET_MATERIAL_VIEW';
export const GET_STYLE = 'GET_STYLE';
export const SET_STYLE_FAILED = 'SET_STYLE_FAILED';
export const SET_STYLE_SUCCESS = 'SET_STYLE_SUCCESS';
export const GET_STYLE_UPDATE = 'GET_STYLE_UPDATE';
export const GET_STYLE_VIEW = 'GET_STYLE_VIEW';
export const GET_STYLE_ADD = 'GET_STYLE_ADD';
export const ADD_STYLE_FAILED = 'ADD_STYLE_FAILED';
export const ADD_STYLE_SUCCESS = 'ADD_STYLE_SUCCESS';
export const GET_STYLE_DELETE = 'GET_STYLE_DELETE';
export const UPDATE_STYLE_FAILED = 'UPDATE_STYLE_FAILED'; 
export const UPDATE_STYLE_SUCCESS = 'UPDATE_STYLE_SUCCESS';
export const VIEW_STYLE_FAILED = 'VIEW_STYLE_FAILED';
export const VIEW_STYLE_SUCCESS = 'VIEW_STYLE_SUCCESS';
export const DELETE_STYLE_SUCCESS = 'DELETE_STYLE_SUCCESS';
export const DELETE_STYLE_FAILED = 'DELETE_STYLE_FAILED';
export const GET_BRAND = 'GET_BRAND';
export const SET_BRAND_FAILED = 'SET_BRAND_FAILED';
export const SET_BRAND_SUCCESS = 'SET_BRAND_SUCCESS';

export const GET_REMOVE_IMAGE_BRAND = 'GET_REMOVE_IMAGE_BRAND';
export const SET_REMOVE_IMAGE_BRAND_FAILED = 'SET_REMOVE_IMAGE_BRAND_FAILED';
export const SET_REMOVE_IMAGE_BRAND_SUCCESS = 'SET_REMOVE_IMAGE_BRAND_SUCCESS';

export const GET_BRAND_LIST_DELETE = 'GET_BRAND_LIST_DELETE';
export const SET_BRAND_LIST_DELETE_FAILED = 'SET_BRAND_LIST_DELETE_FAILED';
export const SET_BRAND_LIST_DELETE_SUCCESS = 'SET_BRAND_LIST_DELETE_SUCCESS';
export const GET_BRAND_UPDATE = 'GET_BRAND_UPDATE';
export const GET_BRAND_VIEW = 'GET_BRAND_VIEW';
export const GET_BRAND_ADD = 'GET_BRAND_ADD';
export const ADD_BRAND_FAILED = 'ADD_BRAND_FAILED';
export const ADD_BRAND_SUCCESS = 'ADD_BRAND_SUCCESS';
export const GET_BRAND_DELETE = 'GET_BRAND_DELETE';
export const UPDATE_BRAND_FAILED = 'UPDATE_BRAND_FAILED'; 
export const UPDATE_BRAND_SUCCESS = 'UPDATE_BRAND_SUCCESS';
export const VIEW_BRAND_FAILED = 'VIEW_BRAND_FAILED';
export const VIEW_BRAND_SUCCESS = 'VIEW_BRAND_SUCCESS';
export const DELETE_BRAND_SUCCESS = 'DELETE_BRAND_SUCCESS';
export const DELETE_BRAND_FAILED = 'DELETE_BRAND_FAILED';
export const GET_ORDER = 'GET_ORDER';
export const SET_ORDER_FAILED = 'SET_ORDER_FAILED';
export const SET_ORDER_SUCCESS = 'SET_ORDER_SUCCESS';
export const GET_ORDER_UPDATE = 'GET_ORDER_UPDATE';
export const GET_ORDER_VIEW = 'GET_ORDER_VIEW';
export const GET_MATERIALORDER_ADD = 'GET_MATERIALORDER_ADD';
export const ADD_MATERIALORDER_FAILED = 'ADD_MATERIALORDER_FAILED';
export const ADD_MATERIALORDER_SUCCESS = 'ADD_MATERIALORDER_SUCCESS';
export const GET_CATEGORY_MATERIAL = 'GET_CATEGORY_MATERIAL';
export const GET_CATEGORY_MATERIAL_SUCCESS = 'GET_CATEGORY_MATERIAL_SUCCESS';
export const GET_CATEGORY_MATERIAL_FAILED = 'GET_CATEGORY_MATERIAL_FAILED';
export const GET_SUB_CATEGORY_MATERIAL = 'GET_SUB_CATEGORY_MATERIAL';
export const GET_SUB_CATEGORY_MATERIAL_SUCCESS = 'GET_SUB_CATEGORY_MATERIAL_SUCCESS';
export const GET_SUB_CATEGORY_MATERIAL_FAILED = 'GET_SUB_CATEGORY_MATERIAL_FAILED';
export const GET_ORDER_DELETE = 'GET_ORDER_DELETE';
export const UPDATE_ORDER_FAILED = 'UPDATE_ORDER_FAILED'; 
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const VIEW_ORDER_FAILED = 'VIEW_ORDER_FAILED';
export const VIEW_ORDER_SUCCESS = 'VIEW_ORDER_SUCCESS';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAILED = 'DELETE_ORDER_FAILED';
export const GET_ORDER_UPLOAD = 'GET_ORDER_UPLOAD';
export const UPLOAD_ORDER_SUCCESS = 'UPLOAD_ORDER_SUCCESS';
export const UPLOAD_ORDER_FAILED = 'UPLOAD_ORDER_FAILED';
export const GET_BRANDORDER_VIEW = 'GET_BRANDORDER_VIEW';
export const VIEW_BRANDORDER_SUCCESS = 'VIEW_BRANDORDER_SUCCESS';
export const VIEW_BRANDORDER_FAILED = 'VIEW_BRANDORDER_FAILED';
export const GET_BRAND_ORDER_List_VIEW = 'GET_BRAND_ORDER_List_VIEW';
export const VIEW_BRAND_ORDER_List_SUCCESS = 'VIEW_BRAND_ORDER_List_SUCCESS';
export const VIEW_BRAND_ORDER_List_FAILED = 'VIEW_BRAND_ORDER_List_FAILED';
export const GET_MAIN_GROUP = 'GET_MAIN_GROUP';
export const SET_ORDERGROUP_SUCCESS = 'SET_ORDERGROUP_SUCCESS';
export const SET_ORDERGROUP_FAILED = 'SET_ORDERGROUP_FAILED';
export const GET_COLOR_DHAAGA = 'GET_COLOR_DHAAGA';
export const SET_COLOR_DHAAGA_SUCCESS = 'SET_COLOR_DHAAGA_SUCCESS';
export const SET_COLOR_DHAAGA_FAILED = 'SET_COLOR_DHAAGA_FAILED';
export const GET_ADD_COLOR_DHAAGA = 'GET_ADD_COLOR_DHAAGA';
export const SET_ADD_COLOR_DHAAGA_SUCCESS = 'SET_ADD_COLOR_DHAAGA_SUCCESS';
export const SET_ADD_COLOR_DHAAGA_FAILED = 'SET_ADD_COLOR_DHAAGA_FAILED';
// Dashboard
export const GET_DASHBOARD_COUNTS = 'GET_DASHBOARD_COUNTS'
export const GET_DASHBOARD_COUNTS_SUCCESS = 'GET_DASHBOARD_COUNTS_SUCCESS'
export const GET_DASHBOARD_COUNTS_FAILED = 'GET_DASHBOARD_COUNTS_FAILED'
export const GET_DASHBOARD_PO = 'GET_DASHBOARD_PO'
export const GET_DASHBOARD_PO_SUCCESS = 'GET_DASHBOARD_PO_SUCCESS'
export const GET_DASHBOARD_PO_FAILED = 'GET_DASHBOARD_PO_FAILED'
export const GET_DASHBOARD_ORDER = 'GET_DASHBOARD_ORDER'
export const GET_DASHBOARD_ORDER_SUCCESS = 'GET_DASHBOARD_ORDER_SUCCESS'
export const GET_DASHBOARD_ORDER_FAILED = 'GET_DASHBOARD_ORDER_FAILED'
export const GET_DASHBOARD_REVENUE = 'GET_DASHBOARD_REVENUE'
export const GET_DASHBOARD_REVENUE_SUCCESS = 'GET_DASHBOARD_REVENUE_SUCCESS'
export const GET_DASHBOARD_REVENUE_FAILED = 'GET_DASHBOARD_REVENUE_FAILED'
export const GET_DASHBOARD_PURCHASE_ORDER = 'GET_DASHBOARD_PURCHASE_ORDER'
export const GET_DASHBOARD_PURCHASE_ORDER_SUCCESS = 'GET_DASHBOARD_PURCHASE_ORDER_SUCCESS'
export const GET_DASHBOARD_PURCHASE_ORDER_FAILED = 'GET_DASHBOARD_PURCHASE_ORDER_FAILED'
export const GET_INITIALSTATE_SUBCATE = 'GET_INITIALSTATE_SUBCATE'
// supplier
export const GET_RIB_SUPPLIER = 'GET_RIB_SUPPLIER';
export const SET_RIB_SUPPLIER_SUCCESS = 'SET_RIB_SUPPLIER_SUCCESS';
export const SET_RIB_SUPPLIER_FAILED = 'SET_RIB_SUPPLIER_FAILED';
export const GET_ZIPPER_SUPPLIER = 'GET_ZIPPER_SUPPLIER';
export const SET_ZIPPER_SUPPLIER_SUCCESS = 'SET_ZIPPER_SUPPLIER_SUCCESS';
export const SET_ZIPPER_SUPPLIER_FAILED = 'SET_ZIPPER_SUPPLIER_FAILED';
export const GET_RATIO_SUPPLIER = 'GET_RATIO_SUPPLIER';
export const SET_RATIO_SUPPLIER_SUCCESS = 'SET_RATIO_SUPPLIER_SUCCESS';
export const SET_RATIO_SUPPLIER_FAILED = 'SET_RATIO_SUPPLIER_FAILED';
export const GET_UNIT_SUPPLIER = 'GET_UNIT_SUPPLIER';
export const SET_UNIT_SUPPLIER_SUCCESS = 'SET_UNIT_SUPPLIER_SUCCESS';
export const SET_UNIT_SUPPLIER_FAILED = 'SET_UNIT_SUPPLIER_FAILED';
// Po genrate api's
export const GET_PO_ORDER_LIST = 'GET_PO_ORDER_LIST';
export const GET_PO_ORDER_LIST_SUCCESS = 'GET_PO_ORDER_LIST_SUCCESS';
export const GET_PO_ORDER_LIST_FAILED = 'GET_PO_ORDER_LIST_FAILED';
export const GET_PO_DETAIL = 'GET_PO_DETAIL';
export const GET_PO_DETAIL_SUCCESS = 'GET_PO_DETAIL_SUCCESS';
export const GET_PO_DETAIL_FAILED = 'GET_PO_DETAIL_FAILED';
export const GET_NOTE = 'GET_NOTE';
export const SET_NOTE_SUCCESS = 'SET_NOTE_SUCCESS';
export const SET_NOTE_FAILED = 'SET_NOTE_FAILED';
export const GET_PO_GENERATE = 'GET_PO_GENERATE';
export const SET_PO_GENERATE_SUCCESS = 'SET_PO_GENERATE_SUCCESS';
export const SET_PO_GENERATE_FAILED = 'SET_PO_GENERATE_FAILED';
export const GET_PO_GENERATE_KORA = 'GET_PO_GENERATE_KORA';
export const SET_PO_GENERATE_KORA_SUCCESS = 'SET_PO_GENERATE_KORA_SUCCESS';
export const SET_PO_GENERATE_KORA_FAILED = 'SET_PO_GENERATE_KORA_FAILED';
export const GET_PO_GENERATE_COLOR = 'GET_PO_GENERATE_COLOR';
export const SET_PO_GENERATE_COLOR_SUCCESS = 'SET_PO_GENERATE_COLOR_SUCCESS';
export const SET_PO_GENERATE_COLOR_FAILED = 'SET_PO_GENERATE_COLOR_FAILED';
export const GET_PO_GENERATE_QUILTING = 'GET_PO_GENERATE_QUILTING';
export const SET_PO_GENERATE_QUILTING_SUCCESS = 'SET_PO_GENERATE_QUILTING_SUCCESS';
export const SET_PO_GENERATE_QUILTING_FAILED = 'SET_PO_GENERATE_QUILTING_FAILED';
export const GET_PO_GENERATE_ZIPPER = 'GET_PO_GENERATE_ZIPPER';
export const SET_PO_GENERATE_ZIPPER_SUCCESS = 'SET_PO_GENERATE_ZIPPER_SUCCESS';
export const SET_PO_GENERATE_ZIPPER_FAILED = 'SET_PO_GENERATE_ZIPPER_FAILED';
export const GET_PO_GENERATE_UNIT = 'GET_PO_GENERATE_UNIT';
export const SET_PO_GENERATE_UNIT_SUCCESS = 'SET_PO_GENERATE_UNIT_SUCCESS';
export const SET_PO_GENERATE_UNIT_FAILED = 'SET_PO_GENERATE_UNIT_FAILED';
export const GET_PO_GENERATE_RATIO = 'GET_PO_GENERATE_RATIO';
export const SET_PO_GENERATE_RATIO_SUCCESS = 'SET_PO_GENERATE_RATIO_SUCCESS';
export const SET_PO_GENERATE_RATIO_FAILED = 'SET_PO_GENERATE_RATIO_FAILED';
// old Order
export const GET_OLD_ORDERS = 'GET_OLD_ORDERS';
export const SET_OLD_ORDERS_SUCCESS = 'SET_OLD_ORDERS_SUCCESS';
export const SET_OLD_ORDERS_FAILED = 'SET_OLD_ORDERS_FAILED';
// add inhouse
export const GET_ADD_INHOUSE = 'GET_ADD_INHOUSE';
export const SET_ADD_INHOUSE_SUCCESS = 'SET_ADD_INHOUSE_SUCCESS';
export const SET_ADD_INHOUSE_FAILED = 'SET_ADD_INHOUSE_FAILED';
export const GET_DELETE_INHOUSE = 'GET_DELETE_INHOUSE';
export const SET_DELETE_INHOUSE_SUCCESS = 'SET_DELETE_INHOUSE_SUCCESS';
export const SET_DELETE_INHOUSE_FAILED = 'SET_DELETE_INHOUSE_FAILED';
// Ratio and unit Wise
export const GET_RATIO = 'GET_RATIO';
export const GET_RATIO_SUCCESS = 'GET_RATIO_SUCCESS';
export const GET_RATIO_FAILED = 'GET_RATIO_FAILED';
export const GET_UNIT = 'GET_UNIT';
export const GET_UNIT_SUCCESS = 'GET_UNIT_SUCCESS';
export const GET_UNIT_FAILED = 'GET_UNIT_FAILED';
export const GET_RATIO_ADD = 'GET_RATIO_ADD';
export const GET_RATIO_ADD_SUCCESS = 'GET_RATIO_ADD_SUCCESS';
export const GET_RATIO_ADD_FAILED = 'GET_RATIO_ADD_FAILED';
export const GET_UNIT_ADD = 'GET_UNIT_ADD';
export const GET_UNIT_ADD_SUCCESS = 'GET_UNIT_ADD_SUCCESS';
export const GET_UNIT_ADD_FAILED = 'GET_UNIT_FAILED';
export const GET_RATIO_ADD_PICTURE = 'GET_RATIO_ADD_PICTURE';
export const GET_RATIO_ADD_PICTURE_SUCCESS = 'GET_RATIO_ADD_PICTURE_SUCCESS';
export const GET_RATIO_ADD_PICTURE_FAILED = 'GET_RATIO_ADD_PICTURE_FAILED';
export const GET_UNIT_ADD_PICTURE = 'GET_UNIT_ADD_PICTURE';
export const GET_UNIT_ADD_PICTURE_FAILED = 'GET_UNIT_ADD_PICTURE_FAILED';
// sub category crud
export const GET_SUB_CATEGORY = 'GET_SUB_CATEGORY';
export const SET_SUB_CATEGORY_SUCCESS = 'SET_SUB_CATEGORY_SUCCESS';
export const SET_SUB_CATEGORY_FAILED = 'SET_SUB_CATEGORY_FAILED';
export const GET_SUB_CATEGORY_DETAIL = 'GET_SUB_CATEGORY_DETAIL';
export const SET_SUB_CATEGORY_DETAIL_SUCCESS = 'SET_SUB_CATEGORY_DETAIL_SUCCESS';
export const SET_SUB_CATEGORY_DETAIL_FAILED = 'SET_SUB_CATEGORY_DETAIL_FAILED';
export const GET_DELETE_SUB_CATEGORY = 'GET_DELETE_SUB_CATEGORY';
export const SET_DELETE_SUB_CATEGORY_SUCCESS = 'SET_DELETE_SUB_CATEGORY_SUCCESS';
export const SET_DELETE_SUB_CATEGORY_FAILED = 'SET_DELETE_SUB_CATEGORY_FAILED';
export const GET_UPDATE_SUB_CATEGORY = 'GET_UPDATE_SUB_CATEGORY';
export const SET_UPDATE_SUB_CATEGORY_SUCCESS = 'SET_UPDATE_SUB_CATEGORY_SUCCESS';
export const SET_UPDATE_SUB_CATEGORY_FAILED = 'SET_UPDATE_SUB_CATEGORY_FAILED';
export const GET_ADD_SUB_CATEGORY = 'GET_ADD_SUB_CATEGORY';
export const SET_ADD_SUB_CATEGORY_SUCCESS = 'SET_ADD_SUB_CATEGORY_SUCCESS';
export const SET_ADD_SUB_CATEGORY_FAILED = 'SET_ADD_SUB_CATEGORY_FAILED';
export const SET_ADD_ALL_CATEGORY_FOR_SUB_CATEGORY_SUCCESS = 'SET_ADD_ALL_CATEGORY_FOR_SUB_CATEGORY_SUCCESS'
// main order crud
export const GET_EDIT_MAIN_ORDERS = 'GET_EDIT_MAIN_ORDERS';
export const SET_EDIT_MAIN_ORDERS_SUCCESS = 'SET_EDIT_MAIN_ORDERS_SUCCESS';
export const SET_EDIT_MAIN_ORDERS_FAILED = 'SET_EDIT_MAIN_ORDERS_FAILED';
export const GET_DELETE_MAIN_ORDERS = 'GET_DELETE_MAIN_ORDERS';
export const SET_DELETE_MAIN_ORDERS_SUCCESS = 'SET_DELETE_MAIN_ORDERS_SUCCESS';
export const SET_DELETE_MAIN_ORDERS_FAILED = 'SET_DELETE_MAIN_ORDERS_FAILED';
export const GET_REPEAT_ORDERS = 'GET_REPEAT_ORDERS';
export const SET_REPEAT_ORDERS_SUCCESS = 'SET_REPEAT_ORDERS_SUCCESS';
export const SET_REPEAT_ORDERS_FAILED = 'SET_REPEAT_ORDERS_FAILED';
// Get and post zipper type
export const GET_MAIN_ZIPPER = 'GET_MAIN_ZIPPER';
export const SET_MAIN_ZIPPER_SUCCESS = 'SET_MAIN_ZIPPER_SUCCESS';
export const SET_MAIN_ZIPPER_FAILED = 'SET_MAIN_ZIPPER_FAILED';
export const GET_ZIPPER_ADD_ROW = 'GET_ZIPPER_ADD_ROW';
export const SET_ZIPPER_ADD_ROW_FAILED = 'SET_ZIPPER_ADD_ROW_FAILED';
export const GET_ZIPPER_ADD_REPEAT = 'GET_ZIPPER_ADD_REPEAT';
export const SET_ZIPPER_ADD_REPEAT_FAILED = 'SET_ZIPPER_ADD_REPEAT_FAILED';
export const GET_POST_MAIN_ZIPPER = 'GET_POST_MAIN_ZIPPER';
export const SET_POST_MAIN_ZIPPER_FAILED = 'SET_POST_MAIN_ZIPPER_FAILED';
// Get and post user and role
export const GET_USER_ADD = 'GET_USER_ADD';
export const SET_USER_ADD_SUCCESS = 'SET_USER_ADD_SUCCESS';
export const SET_USER_ADD_FAILED = 'SET_USER_ADD_FAILED';
export const GET_USER_LIST = 'GET_USER_LIST';
export const SET_USER_LIST_SUCCESS = 'SET_USER_LIST_SUCCESS';
export const SET_USER_LIST_FAILED = 'SET_USER_LIST_FAILED';
export const GET_USER_DETAIL = 'GET_USER_DETAIL';
export const SET_USER_DETAIL_SUCCESS = 'SET_USER_DETAIL_SUCCESS';
export const SET_USER_DETAIL_FAILED = 'SET_USER_DETAIL_FAILED';
export const GET_USER_UPDATE = 'GET_USER_UPDATE';
export const GET_USER_UPDATE_SUCCESS = 'GET_USER_UPDATE_SUCCESS'
export const GET_USER_UPDATE_FAILED = 'GET_USER_UPDATE_FAILED';
export const GET_ROLE = 'GET_ROLE';
export const SET_ROLE_SUCCESS = 'SET_ROLE_SUCCESS';
export const SET_ROLE_FAILED = 'SET_ROLE_FAILED';
export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED'

export const GET_INITIALSTATE = 'GET_INITIALSTATE';
export const GET_INITIALSTATE_SUB = 'GET_INITIALSTATE_SUB';
export const GET_INITIALSTATE_VARIENT = 'GET_INITIALSTATE_VARIENT';
export const GET_INITIALSTATE_SUPPLIER = 'GET_INITIALSTATE_SUPPLIER';
export const GET_INITIALSTATE_NOTE = 'GET_INITIALSTATE_NOTE';
export const GET_INITIALSTATE_MATERIAL = 'GET_INITIALSTATE_MATERIAL';
export const GET_RIB_DHAAGA = 'GET_RIB_DHAAGA';
export const ADD_RIBDHAAGAORDER_SUCCESS = 'ADD_RIBDHAAGAORDER_SUCCESS';
export const ADD_RIBDHAAGAORDER_FAILED = 'ADD_RIBDHAAGAORDER_FAILED';
export const GET_RIB_DHAAGA_DETAIL = 'GET_RIB_DHAAGA_DETAIL';
export const GET_RIBDHAAGAORDER_SUCCESS = 'GET_RIBDHAAGAORDER_SUCCESS';
export const GET_RIBDHAAGAORDER_FAILED = 'GET_RIBDHAAGAORDER_FAILED';
export const GET_POLYFILL_MATERIAL = 'GET_POLYFILL_MATERIAL';
export const SET_POLYFILL_MATERIAL_SUCCESS = 'SET_POLYFILL_MATERIAL_SUCCESS';
export const SET_POLYFILL_MATERIAL_FAILED = 'SET_POLYFILL_MATERIAL_FAILED';
export const GET_POLYFILL = 'GET_POLYFILL';
export const ADD_POLYFILLORDER_SUCCESS = 'ADD_POLYFILLORDER_SUCCESS';
export const ADD_POLYFILLORDER_FAILED = 'ADD_POLYFILLORDER_FAILED';
export const GET_POLYFILL_DETAIL = 'GET_POLYFILL_DETAIL';
export const GET_POLYFILLORDER_SUCCESS = 'GET_POLYFILLORDER_SUCCESS';
export const GET_POLYFILLORDER_FAILED = 'GET_POLYFILLORDER_FAILED';

// Lining order
export const GET_LINING_SUPPLIER = 'GET_LINING_SUPPLIER';
export const SET_LINING_SUPPLIER_SUCCESS = 'SET_LINING_SUPPLIER_SUCCESS';
export const SET_LINING_SUPPLIER_FAILED = 'SET_LINING_SUPPLIER_FAILED';
export const GET_LINING = 'GET_LINING';
export const ADD_LININGORDER_SUCCESS = 'ADD_LININGORDER_SUCCESS';
export const ADD_LININGORDER_FAILED = 'ADD_LININGORDER_FAILED';
export const GET_LINING_DETAIL = 'GET_LINING_DETAIL';
export const GET_LININGORDER_SUCCESS = 'GET_LININGORDER_SUCCESS';
export const GET_LININGORDER_FAILED = 'GET_LININGORDER_FAILED';
/***************/

// Main fabric quilting
export const GET_MAIN_QUILT_SUPPLIER = 'GET_MAIN_QUILT_SUPPLIER';
export const SET_MAIN_QUILT_SUPPLIER_SUCCESS = 'SET_MAIN_QUILT_SUPPLIER_SUCCESS';
export const SET_MAIN_QUILT_SUPPLIER_FAILED = 'SET_MAIN_QUILT_SUPPLIER_FAILED';
export const GET_MAINQUILT = 'GET_MAINQUILT';
export const ADD_MAINQUILTORDER_SUCCESS = 'ADD_MAINQUILTORDER_SUCCESS';
export const ADD_MAINQUILTORDER_FAILED = 'ADD_MAINQUILTORDER_FAILED';
export const GET_MAINQUILT_DETAIL = 'GET_MAINQUILT_DETAIL';
export const GET_MAINQUILTORDER_SUCCESS = 'GET_MAINQUILTORDER_SUCCESS';
export const GET_MAINQUILTORDER_FAILED = 'GET_MAINQUILTORDER_FAILED';
/***************/

// Lining quilting
export const GET_LINING_QUILT_SUPPLIER = 'GET_LINING_QUILT_SUPPLIER';
export const SET_LINING_QUILT_SUPPLIER_SUCCESS = 'SET_LINING_QUILT_SUPPLIER_SUCCESS';
export const SET_LINING_QUILT_SUPPLIER_FAILED = 'SET_LINING_QUILT_SUPPLIER_FAILED';
export const GET_LININGQUILT = 'GET_LININGQUILT';
export const ADD_LININGQUILTORDER_SUCCESS = 'ADD_LININGQUILTORDER_SUCCESS';
export const ADD_LININGQUILTORDER_FAILED = 'ADD_LININGQUILTORDER_FAILED';
export const GET_LININGQUILT_DETAIL = 'GET_LININGQUILT_DETAIL';
export const GET_LININGQUILTORDER_SUCCESS = 'GET_LININGQUILTORDER_SUCCESS';
export const GET_LININGQUILTORDER_FAILED = 'GET_LININGQUILTORDER_FAILED';
/***************/

export const TOOLTIP_TOGGLE = 'TOOLTIP_TOGGLE';

export const GET_INHOUSE_UPLOAD = 'GET_INHOUSE_UPLOAD';
export const UPLOAD_INHOUSE_SUCCESS = 'UPLOAD_INHOUSE_SUCCESS';
export const UPLOAD_INHOUSE_FAILED = 'UPLOAD_INHOUSE_FAILED';
export const GET_INHOUSE_VIEW = 'GET_INHOUSE_VIEW';
export const VIEW_INHOUSE_SUCCESS = 'VIEW_INHOUSE_SUCCESS';
export const VIEW_INHOUSE_FAILED = 'VIEW_INHOUSE_FAILED';
export const GET_INHOUSE_STOCK = 'GET_INHOUSE_STOCK';
export const STOCK_INHOUSE_SUCCESS = 'STOCK_INHOUSE_SUCCESS';
export const STOCK_INHOUSE_FAILED = 'STOCK_INHOUSE_FAILED';
export const GET_RATIOINHOUSE_STOCK = 'GET_RATIOINHOUSE_STOCK';
export const STOCK_RATIOINHOUSE_SUCCESS = 'STOCK_RATIOINHOUSE_SUCCESS';
export const STOCK_RATIOINHOUSE_FAILED = 'STOCK_RATIOINHOUSE_FAILED';