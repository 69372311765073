import React from "react";
import Header from "../../../components/Headers/Header";
import { getBrandOrder, addMaterialOrder, materialOrder, getInhouseStocks, initialState, noteinitialState } from '../../../services/actions/order';
import { poGeneratePDF } from '../../../services/actions/po'
import { varientBySlug, varienInitial } from '../../../services/actions/varient'
import { bindActionCreators } from 'redux';
import { supplier, liningSupplier } from '../../../services/actions/supplier';
import { connect } from 'react-redux';
import EditTable from '../../../components/EditTable'
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom'
import { imagerUrl } from '../../../config/config';
import {
  Card,
  CardHeader,
  Button,
  Row,
  Container,
  NavItem,
  Nav,
  NavLink,
  Spinner
} from "reactstrap";
import PreviewModal from "../../../components/previewModal";
import moment from 'moment'
var toastId = null
class Mainfabricsheet extends React.Component {
  state = {
    data: {
      columns: [],
      rows: []
    },
    varient: [],
    brand: '',
    brandImg: '',
    brandOrderId: this.props.match.params.id,
    suppliers: [],
    material: [],
    edit: [],
    loader: false,
    open: false,
    loaderVarient: true,
    loaderTable: true,
    loaderPo: false,
    errors: {},
    rows: [],
    inhouse: [],
    pageNumber: 1,
    total: '',
    orderIdPo: '',
    name: '',
    search: '',
    selectPoList: {
      id: []
    },
    previewData: {
      columns: [],
      rows: []
    },
    previewDataId: null,
    supllierDetail: [],
    note: '',
    noteGetRow:'',
    poStatus: '',
    poSucc: null,
    slug: this.props.match.params.item_slug
  }
  componentWillMount() {
    const { brandOrderId, pageNumber, search, slug } = this.state
    var dataBrand = {
      brandOrderId, pageNumber
    }
    this.props.varientBySlug(slug)
    this.props.getBrandOrder(dataBrand);
    this.props.liningSupplier()
    this.setState({
      loaderVarient: true
    })
  }
  onKeyPress(evt) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }
  valueEdit = (rows, rowId, rowsList, data1, suppliers, key, value) => {
    const { varient } = this.state
    let currentDate = new Date();
    let getYear = currentDate.getFullYear()
    let getMonth = currentDate.getMonth() + 1
    let fullCurrentDate = getYear + '-' + (getMonth <= 9 ? '0' + getMonth : getMonth) + '-' + (currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate())
    if (key === rowId) {
      if (sessionStorage.userROLE == 'super-admin' || rowsList[rowId]['poStatus'] === 'Pending') {
        varient.map(items => {
          if (items.assignedVarient === '') {
            value[items.field] = [<input className="inputFiled" type="text" name={items.name} value={rowsList[key][items.name]} key={items.name + key} onChange={e => (this.onChange(e, key))} />]
          } else {
            let values = items.assignedVarient.split(',')
            value[items.field] = [<select className="inputFiled" name={items.name} key={'ah' + key} defaultValue={rowsList[key][items.name]} onChange={e => (this.onChangeSupplier(e, key))}>
              <option value={null}>{items.name}</option>
              {values.map(itemValue => {
                return <option value={itemValue}>{itemValue}</option>
              })}
            </select>]
          }
        })
        value['Action'] = [<i className="fas fa-check pr-2 cursor-pointer" onClick={e => (this.onSubmit(e, key))} key={'a' + key}></i>]
        value['Sr No'] = rows[key]['Sr No']
        value['add qty'] = [<select className="inputFiled" name="addQty" defaultValue={rowsList[key]['addQty']} onChange={e => (this.onChange(e, key))} key={'addQty' + key}>
          <option>Qty(%)</option>
          <option value="0%">0%</option>
          <option value="1%">1%</option>
          <option value="2%">2%</option>
          <option value="3%">3%</option>
          <option value="4%">4%</option>
          <option value="5%">5%</option>
          <option value="6%">6%</option>
          <option value="7%">7%</option>
          <option value="8%">8%</option>
          <option value="9%">9%</option>
          <option value="10%">10%</option>
          <option value="11%">11%</option>
          <option value="12%">12%</option>
          <option value="13%">13%</option>
          <option value="14%">14%</option>
          <option value="15%">15%</option>
          <option value="16%">16%</option>
          <option value="17%">17%</option>
          <option value="18%">18%</option>
          <option value="19%">19%</option>
          <option value="20%">20%</option>
        </select>]
        value['type'] = [<select name="fabricType" className="selectList inputFiled" onChange={e => (this.onChangeSupplier(e, key))} defaultValue={rowsList[key]['fabricType']} key={'fabricType' + key}>
          <option>Type</option>
          {
            data1.length && data1[0].map((val, key) => {
              if (val)
                return (<option value={val.supplyMaterial.id} key={key}>{val.supplyMaterial.name}</option>)
              else
                return ('')
            })
          }
        </select>]
        value['avg'] = [<input type="text" name="fabricAvg" className="inputFiled" value={rowsList[key]['fabricAvg']} key={'fabricAvg' + key} onChange={e => (this.onChange(e, key))} onKeyPress={this.onKeyPress.bind(this)} />]
        value['total need'] = rowsList[key]['totalNeed']
        value['inhouse'] = rowsList[key]['inhouse']
        value['final need'] = rowsList[key]['finalNeed']
        value['price'] = [<input type="text" maxLength="8" className="inputFiled" name="price" value={rowsList[key]['price']} key={'price' + key} onChange={e => (this.onChange(e, key))} min="0" onKeyPress={this.onKeyPress.bind(this)} />]
        value['Delivery'] = [<input type="date" className="inputFiled" min={fullCurrentDate} name="Delivery" value={rowsList[key]['Delivery']} key={'Delivery' + key} onChange={e => (this.onChange(e, key))} />]
        value['Remarks'] = [<input type="text" className="inputFiled" name="Remarks" value={rowsList[key]['Remarks']} key={'Remarks' + key} onChange={e => (this.onChange(e, key))} />]
        value['recd status'] = [<input type="text" className="inputFiled" onKeyPress={this.onKeyPress.bind(this)} name="recdStatus" value={rowsList[key]['recdStatus']} key={'recdStatus' + key} onChange={e => (this.onChange(e, key))} />]
        value['pending status'] = rowsList[key]["pendingStatus"]
        value['supplier'] = [<select name="supplierId" className="selectList inputFiled" key={'supplierId' + key} defaultValue={rowsList[key]['supplierId']} onChange={e => (this.onChangeSupplier(e, key))}>
          <option>Supplier</option>
          {suppliers.map((value, key) => {
            return (<option value={value.id} key={key}>{value.companyName}</option>)
          })}
        </select>]
      }
      else {
        value['Action'] = [<i className="fas fa-check pr-2 cursor-pointer" onClick={e => (this.onSubmit(e, key))} key={'a' + key}></i>]
        value['recd status'] = [<input type="text" className="inputFiled" onKeyPress={this.onKeyPress.bind(this)} name="recdStatus" value={rowsList[key]['recdStatus']} key={'recdStatus' + key} onChange={e => (this.onChange(e, key))} />]
        value['pending status'] = rowsList[key]["pendingStatus"]
      }
    } else {
      value['Action'] = [<div className="d-flex"><i title="Edit" className="disabledAction fas fa-pen pr-2 cursor-pointer" key={'abc' + key}></i><i title="Add Row" className="disabledAction fas fa-plus-circle cursor-pointer" key={'abce' + key}></i><i key={'abcr' + key} title="View" className="disabledAction fas fa-eye cursor-pointer" ></i><input style={{ minWidth: '15px', marginLeft: '5px' }} type="checkbox" key={key} value={value.id} name="poGenrate" disabled /></div>]
    }
  }
  valueUpdate = (rowId, updatedRow, key, value) => {
    const { varient } = this.state
    let poSucc = updatedRow[rowId]['febricDesc'] !== '' && updatedRow[rowId]['Delivery'] !== '' && updatedRow[rowId]['addQty'] !== '' && updatedRow[rowId]['fabricAvg'] !== '' && updatedRow[rowId]['fabricType'] !== '' && updatedRow[rowId]['finalNeed'] !== '' && updatedRow[rowId]['supplierId'] !== '' && updatedRow[rowId]['totalNeed'] !== '' ? false : true
    if (key === rowId) {
      varient.map(items => {
        value[items.field] = updatedRow[rowId][items.name]
      })
      value['Action'] = [
        <div style={{ display: 'flex' }}>{(sessionStorage.userROLE === 'super-admin' || value['po status'] === 'Pending') ? <><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i><i title="Add Row" className="fas fa-plus-circle cursor-pointer" onClick={e => (this.onAdd(e, key))} key={'abce' + key}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, value.id, poSucc))} ></i><input style={{ minWidth: '15px', marginLeft: '5px' }} type="checkbox" key={key} value={value.id} name="poGenrate" disabled={poSucc} onChange={e => (this.listPoGenrate(e, key))} /></> : <><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i><i title="Add Row" className="fas fa-plus-circle cursor-pointer" onClick={e => (this.onAdd(e, key))} key={'abce' + key}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, value.id, poSucc))} ></i></>}</div>
      ]
      value['Sr No'] = key + 1
      value['add qty'] = updatedRow[rowId]['addQty']
      value['supplier'] = [<span className="tooltip1" key={'sup' + key}>{updatedRow[rowId]['supplierIdtext'] ? (updatedRow[rowId]['supplierIdtext'].length > 5 ? updatedRow[rowId]['supplierIdtext'].substr(0, 5) : updatedRow[rowId]['supplierIdtext']) : ''}<span className="tooltiptext">{updatedRow[rowId]['supplierIdtext'] ? updatedRow[rowId]['supplierIdtext'] : ''}</span></span>]
      value['type'] = updatedRow[rowId]['fabricTypetext'] ? updatedRow[rowId]['fabricTypetext'] : ''
      value['avg'] = updatedRow[rowId]['fabricAvg']
      value['total need'] = updatedRow[rowId]['totalNeed']
      value['inhouse'] = updatedRow[rowId]['inhouse']
      value['final need'] = updatedRow[rowId]['finalNeed']
      value['price'] = updatedRow[rowId]['price']
      value['Delivery'] = updatedRow[rowId]['Delivery']
      value['Remarks'] = updatedRow[rowId]['Remarks']
      value['po status'] = updatedRow[rowId]['poStatus']
      value['recd status'] = updatedRow[rowId]['recdStatus']
      value['pending status'] = updatedRow[rowId]['pendingStatus']
    } else {
      value['Action'] = [
        <div style={{ display: 'flex' }}>{(sessionStorage.userROLE === 'super-admin' || value['po status'] === 'Pending') ? <><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i><i title="Add Row" className="fas fa-plus-circle cursor-pointer" onClick={e => (this.onAdd(e, key))} key={'abce' + key}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, value.id, poSucc))} ></i><input style={{ minWidth: '15px', marginLeft: '5px' }} type="checkbox" key={key} value={value.id} name="poGenrate" disabled={poSucc} onChange={e => (this.listPoGenrate(e, key))} /></> : <><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i><i title="Add Row" className="fas fa-plus-circle cursor-pointer" onClick={e => (this.onAdd(e, key))} key={'abce' + key}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, value.id, poSucc))} ></i></>}</div>
      ]
    }
  }
  onChangeSupplier = (e, rowId) => {
    var index = e.nativeEvent.target.selectedIndex;
    let rowsList = this.state.rows
    let rows = this.state.data.rows
    const { suppliers, varient } = this.state
    let data = rowsList.map((value, key) => {
      if (key === rowId) {
        value[e.target.name] = e.target.value
        value[e.target.name + 'text'] = e.nativeEvent.target[index].text
        if (e.target.name === 'supplierId' && e.target.value !== 'Select Supplier') {
          let data = []
          suppliers.map((val, keys) => {
            if ((val.id).toString() === e.target.value) {
              data.push(val.suppliermaterial)
            } return true
          })
          let data1 = data.length && data[0].map((val) => {
            if (val)
              return (<option value={val.supplyMaterial.id}>{val.supplyMaterial.name}</option>)
            else
              return ('')
          })
          this.setState({
            material: data1
          })
          var row = rows.map((value, key) => {
            this.valueEdit(rows, rowId, rowsList, data, suppliers, key, value)
            return value;
          })
          this.setState({
            data: {
              ...this.state.data,
              rows: row
            }
          })
        }

        if (e.target.name === 'addQty' && e.target.value !== 'Select Qty(%)') {
          let rows = this.state.data.rows
          let updatedrows = rows && rows.map((val, key) => {
            if (key === rowId) {
              let percent = e.target.value.substring(0, e.target.value.length - 1)
              let total = Math.ceil(this.state.data.rows[key]['order qty'] + ((percent / 100) * this.state.data.rows[key]['order qty']))
              val['total need'] = Math.ceil((value.fabricAvg !== '' ? value.fabricAvg : 1) * total)
              val['final need'] = val['total need']
            }
            return val
          })

          this.setState({
            data: {
              ...this.state.data,
              rows: updatedrows
            }
          })
        }
        var varientDetail = ''
        varient.map(items => {
          if (rowsList[rowId][items.name] != null) {
            varientDetail = varientDetail + items.name + ':' + rowsList[rowId][items.name] + ',';
          }
        })
        this.props.getInhouseStocks({
          materialId: rowsList[rowId].fabricType,
          varients: varientDetail.substring(0, varientDetail.length - 1),
          key: rowId,
          quantity: rowsList[rowId].totalNeed ? rowsList[rowId].totalNeed : 0,
          inhouseId: rowsList[rowId].newStock,
          oldQuantity: rowsList[rowId].inhouse,
          orderid:rowsList[rowId].id ? rowsList[rowId].id : undefined
        })
      }
      return value
    })
    this.setState({
      ...this.state,
      rows: data
    })
  }
  onAdd = (event, rowId) => {
    let rows = this.state.data.rows
    let row = []
    let updatedRow = this.state.rows
    const { varient } = this.state
    rows.map((value, key) => {
      var abc = {}
      value['Action'] = [
        <div style={{ display: 'flex' }}><i title="Edit" className="disabledAction fas fa-pen pr-2 cursor-pointer" key={'abc' + key}></i><i title="Add Row" className="disabledAction fas fa-plus-circle cursor-pointer" key={'abce' + key} ></i><i key={'abcr' + key} title="View" className="disabledAction fas fa-eye cursor-pointer" ></i><input type="checkbox" value={this.state.rows[key]['orderDescId']} name="poGenrate" style={{ minWidth: '15px', marginLeft: '5px' }} disabled key={key} onChange={e => (this.listPoGenrate(e, key + 1))} /></div>
      ]
      if (key === rowId) {
        abc['Action'] = [
          <i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key + 1))} key={key}></i>,
          <i title="Delete" className="fas fa-minus-circle cursor-pointer" onClick={e => (this.onDelete(e, key + 1))} key={'abcio' + key}></i>
        ]
        abc['Sr No'] = this.state.data.rows[key]['Sr No'] + 1
        abc['Style No'] = value['Style No']
        abc['Style Code'] = value['Style Code']
        abc['order qty'] = value['order qty']
        abc['add qty'] = ''
        abc['supplier'] = ''
        abc['type'] = ''
        varient.map(items => {
          abc[items.field] = ''
        })
        abc['avg'] = ''
        abc['total need'] = ''
        abc['inhouse'] = ''
        abc['final need'] = ''
        abc['price'] = ''
        abc['Delivery'] = ''
        abc['Remarks'] = ''
        abc['po status'] = <>{value.poStatus === 'Generated' || value.poStatus === 'Regenerated' ? <i class="fas fa-check" title={value.poStatus} style={{ color: '#4dba4f' }}></i> : <><i style={{ color: '#f15d5d' }} class="far fa-clock" title='Pending'></i></>}</>
        abc['recd status'] = ''
        abc['pending status'] = ''
      }
      if (key >= rowId + 1) {
        abc = {}
        value['Sr No'] = this.state.data.rows[key]['Sr No'] + 1
        value['Action'] = [
          <div style={{ display: 'flex' }}><i title="Edit" className="disabledAction fas fa-pen pr-2 cursor-pointer" key={'abc' + key}></i><i title="Add Row" className="disabledAction fas fa-plus-circle cursor-pointer" key={'abce' + key} ></i><i key={'abcr' + key} title="View" className="disabledAction fas fa-eye cursor-pointer" ></i><input type="checkbox" value={this.state.rows[key]['orderDescId']} name="poGenrate" style={{ minWidth: '15px', marginLeft: '5px' }} disabled key={key} onChange={e => (this.listPoGenrate(e, key + 1))} /></div>
        ]
      }
      row.push(value);
      if (abc && Object.keys(abc).length)
        row.push(abc);
      return true;
    })

    let data1 = []

    updatedRow && updatedRow.map((value, key) => {
      var abc = {}
      if (key === rowId) {
        abc.Delivery = ""
        abc.Remarks = ""
        abc.orderDescId = value.orderDescId
        abc.addQty = ""
        abc.fabricAvg = ""
        abc.fabricType = ""
        varient.map(items => {
          abc[items.name] = ''
        })
        abc.finalNeed = ''
        abc.inhouse = ""
        abc.pendingStatus = ""
        abc.price = ""
        abc.recdStatus = ""
        abc.poStatus = 'Pending'
        abc.totalNeed = ''
      }
      data1.push(value);
      if (abc && Object.keys(abc).length)
        data1.push(abc);
      return true;
    })
    this.setState({
      data: {
        ...this.state.data,
        rows: row
      },
      rows: data1
    })
  }
  onChange = (e, rowId) => {
    const rowsList = this.state.rows
    const { suppliers, varient } = this.state
    let data = rowsList.map((value, key) => {
      if (key === rowId) {
        value[e.target.name] = e.target.value
        if (e.target.name === 'fabricAvg' || e.target.name === 'addQty') {
          let percent = value.addQty.substring(0, value.addQty.length - 1)
          let total = Math.ceil(this.state.data.rows[key]['order qty'] + ((percent / 100) * this.state.data.rows[key]['order qty']))
          console.log(value.addQty,percent,"val===")
          value['totalNeed'] = Math.ceil(value['fabricAvg'] * total)
          value['finalNeed'] = value['totalNeed'] - (value['inhouse'] !== '' ? value['inhouse'] : 0)
          console.log(rowsList[rowId],'val===')
        }
        if (e.target.name === 'inhouse') {
          value['finalNeed'] = value['totalNeed'] - e.target.value
        }
        if (e.target.name === 'recdStatus') {
          value['pendingStatus'] = (value['finalNeed'] - e.target.value)
        }
      }
      return value
    })
    this.setState({
      ...this.state,
      rows: data
    })

    let rows2 = this.state.data.rows

    var row = rows2.map((value, key) => {
      let data1 = []
      suppliers.map((val, keys) => {
        if (val.id == rowsList[key]['supplierId']) {
          data1.push(val.suppliermaterial)
        } return true
      })
      this.valueEdit(rows2, rowId, rowsList, data1, suppliers, key, value)
      return value;
    })
    var varientDetail = ''
    varient.map(items => {
      if (rowsList[rowId][items.name] != null) {
        varientDetail = varientDetail + items.name + ':' + rowsList[rowId][items.name] + ',';
      }
    })
    let inhouseStock = {
      materialId: rowsList[rowId].fabricType,
      varients: varientDetail.substring(0, varientDetail.length - 1),
      key: rowId,
      quantity: rowsList[rowId].totalNeed ? rowsList[rowId].totalNeed : 0,
      inhouseId: rowsList[rowId].newStock,
      oldQuantity: rowsList[rowId].inhouse,
      orderid:rowsList[rowId].id ? rowsList[rowId].id : undefined
    }
    this.props.getInhouseStocks(inhouseStock)
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        rows: row
      }
    })
  }
  onEdit = (event, rowId) => {
    let { rows } = this.state.data
    const rowsList = this.state.rows
    const { suppliers } = this.state
    var row = rows.map((value, key) => {
      let data1 = []
      suppliers.map((val, keys) => {
        if (val.id == this.state.rows[key]['supplierId']) {
          data1.push(val.suppliermaterial)
        } return true
      })
      this.valueEdit(rows, rowId, rowsList, data1, suppliers, key, value)
      return value;
    })
    var rowListData = rowsList.map((val, key) => {
      if (key === rowId) {
        val['updateClass'] = 'editRow'
      }
      return val
    })
    this.setState({
      data: {
        ...this.state.data,
        rows: row
      },
      rows: rowListData
    })
  }
  onDelete = (event, rowId) => {
    const { slug, brandOrderId, pageNumber } = this.state
    let rows = this.state.data.rows
    let row = []
    rows.map((value, key) => {
      if (key !== rowId) {
        if (key >= rowId + 1) {
          let poSucc = this.state.rows[key]['febricDesc'] !== '' && this.state.rows[key]['Delivery'] !== '' && this.state.rows[key]['addQty'] !== '' && this.state.rows[key]['fabricAvg'] !== '' && this.state.rows[key]['fabricType'] !== '' && this.state.rows[key]['finalNeed'] !== '' && this.state.rows[key]['supplierId'] !== '' && this.state.rows[key]['totalNeed'] !== '' ? false : true
          value['Sr No'] = this.state.data.rows[key]['Sr No'] - 1
          value['Action'] = [
            <div style={{ display: 'flex' }}><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i><i title="Add Row" className="fas fa-plus-circle cursor-pointer" key={'abce' + key} onClick={e => (this.onAdd(e, key))}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, this.state.rows[key]['orderDescId'], poSucc))} ></i><input type="checkbox" value={this.state.rows[key]['orderDescId']} name="poGenrate" disabled={poSucc} key={'check' + key} onChange={e => (this.listPoGenrate(e, key))} /></div>
          ]
        }
        row.push(value);
      }
      return true
    })
    let updatedRow = this.state.rows
    let data1 = []

    updatedRow && updatedRow.map((value, key) => {
      if (key !== rowId) {
        data1.push(value);
      } return true;
    })
    this.setState({
      data: {
        ...this.state.data,
        rows: row
      },
      rows: data1,
    })
    const data = {
      slug, brandOrderId, pageNumber
    }
    this.props.materialOrder(data)
  }
  onSubmit = (event, rowId) => {
    let rows = rows
    const { varient, brandOrderId, pageNumber, search, slug, data } = this.state
    let updatedRow = this.state.rows
    var row = data.rows.map((value, key) => {
      this.valueUpdate(rowId, updatedRow, key, value)
      return value;
    })
    var rowListData = updatedRow.map((val, key) => {
      if (key === rowId) {
        val['updateClass'] = ''
      }
      return val
    })
    this.setState({
      data: {
        ...this.state.data,
        rows: row
      },
      rows: rowListData
    })
    let dataList = []
    updatedRow.map((val, key) => {
      if (key === rowId) {
        console.log(val,"val===")
        let datainner = []
        datainner['febricvarients'] = []
        varient.map((items, i) => {
          datainner['febricvarients'].push({ id: items.id, value: val[items.name] !== '' ? val[items.name] : null })
          return true;
        })
        dataList.push({
          "orderid": val.id ? val.id : undefined,
          "supplierId": val.supplierId && val.supplierId !== '' ? val.supplierId : null,
          "brandOrderId": this.state.brandOrderId !== '' ? this.state.brandOrderId : null,
          "orderDescId": val.orderDescId,
          "materialId": val.fabricType !== '' ? val.fabricType : null,
          "addQTY": val.addQty !== '' ? val.addQty : null,
          "fabricAverage": val.fabricAvg !== '' ? val.fabricAvg : null,
          "totalNeed": val.totalNeed !== '' ? val.totalNeed : null,
          "inHouse": val.inhouse !== '' ? val.inhouse : null,
          "finalNeed": val.finalNeed !== '' ? val.finalNeed : null,
          "price": val.price !== '' ? val.price : null,
          "deliveryDate": val.Delivery !== '' ? val.Delivery : null,
          "remarks": val.Remarks !== '' ? val.Remarks : null,
          "recdStatus": val.recdStatus !== '' ? val.recdStatus : null,
          "pendingStatus": val.pendingStatus,
          ...datainner,
          oldStock: val.oldStock !== '' ? val.oldStock : null,
          newStock: val.newStock !== '' ? val.newStock : null,
        })
      }
    })
    this.props.addMaterialOrder({ brandOrderId: brandOrderId, data: dataList, pageNumber: pageNumber, search: search, slug })
  }
  componentWillUnmount() {
    this.props.initialState()
  }
  componentWillReceiveProps(newProps) {
    const { varientList, poGenrateSuccess, MainOrder, orderFailed, noteSuccess } = newProps
    const { brandOrderId, pageNumber, search, slug } = this.state
    if(noteSuccess && noteSuccess.success === true){
      newProps.materialOrder({slug, brandOrderId, pageNumber})
      this.setState({
        open:false
      })
    }
    var varient = []
    if (varientList.success) {
      varientList.varient && varientList.varient.map((value, key) => {
        let assignedVarient = value.assignedVarient && value.assignedVarient[0].value
        varient.push({ label: value.name, field: value.name, name: value.slug, id: value.id, assignedVarient: assignedVarient, class: '' })
      })
      if (varient.length >= 0) {
        this.setState({
          data: {
            ...this.state.data,
            columns: [
              { label: "Action", field: "Action", class: '' },
              { label: "S.N.", field: "Sr No", class: 'colum_width' },
              { label: 'Style No', field: 'Style No', class: 'colum_width' },
              { label: 'Style Code', field: 'Style Code', class: 'colum_width' },
              { label: "order qty", field: "order qty", type: "numeric", class: 'colum_width' },
              { label: "add qty %", field: "add qty", type: "numeric", class: 'colum_width' },
              { label: 'supplier', field: 'supplier', class: 'selectList' },
              { label: 'type', field: 'type', class: 'selectList' },
              ...varient,
              { label: 'avg', field: 'avg', class: 'colum_width', class: '' },
              { label: 'total need', field: 'total need', class: 'colum_width' },
              { label: 'inhouse', field: 'inhouse', class: '' },
              { label: 'final need', field: 'final need', class: 'colum_width' },
              { label: 'price', field: 'price', class: '' },
              { label: "Delivery", field: "Delivery", class: '' },
              { label: 'Remarks', field: 'Remarks', class: '' },
              { label: 'po status', field: 'po status', class: 'avgWidth' },
              { label: 'recd status', field: 'recd status', class: 'avgWidth' },
              { label: 'pending status', field: 'pending status', class: 'avgWidth' }
            ]
          },
          loaderVarient: false,
          varient: varient,
          loaderTable: true
        })
        const data = {
          slug, brandOrderId, pageNumber
        }
        newProps.varienInitial()
        newProps.materialOrder(data)
      }
    } else {
      this.setState({
        loaderVarient: false,
      })
    }
    if (poGenrateSuccess && poGenrateSuccess.success) {
      this.props.initialState();
      this.props.materialOrder({ brandOrderId, pageNumber, search, slug });
      if (poGenrateSuccess.message) {
        if (!toast.isActive(toastId))
          toastId = toast.success(poGenrateSuccess.message)
      }
      this.setState({
        loaderPo: false,
        selectPoList: { id: [] }
      })
    }
    if (poGenrateSuccess && poGenrateSuccess.success === false) {
      if (poGenrateSuccess.message) {
        if (!toast.isActive(toastId))
          toastId = toast.error(poGenrateSuccess.message)
      }
      this.setState({
        loaderPo: false
      })
      this.props.initialState();
    }
    if (newProps.brandorder && newProps.brandorder.success) {
      let brand = newProps.brandorder.order
      this.setState({
        total: newProps.brandorder.page,
        brand: brand && brand.brandsId && brand.brandsId.name,
        name: brand && brand.name,
        brandImg: brand && brand.brandsId && brand.brandsId.logo,
      })
    } else {
      this.setState({
        loader: false
      })
    }
    if (newProps.liningsupplier && newProps.liningsupplier.success) {
      this.setState({
        suppliers: newProps.liningsupplier.suppliers
      })
    }
    if (newProps.inhousestock && (newProps.inhousestock.message || newProps.inhousestock.success)) {
      if (newProps.inhousestock.success === true) {
        var inhouse = newProps.inhousestock&&newProps.inhousestock.inhouse
        let dataa = this.state.data.rows.map((val, keys) => {
          if (keys === newProps.inhousestock.key) {
            val.inhouse = newProps.inhousestock.quantity
            val['final need'] = val['total need'] - val.inhouse
            newProps.initialState();
          }
          return val
        })
        let data1 = this.state.rows.map((val, keys) => {
          if (keys == newProps.inhousestock.key) {
            val.inhouse = newProps.inhousestock.quantity
            val.finalNeed = val.totalNeed - val.inhouse
            val.newStock = inhouse&&inhouse.id
            newProps.initialState();
          }
          return val
        })
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            rows: dataa
          },
          rows: data1,
          inhouse: inhouse
        })
      } else {
        this.props.initialState();
      }
      if (newProps.inhousestock.success === false) {
        var inhouse = newProps.inhousestock&&newProps.inhousestock.inhouse
        let dataa = this.state.data.rows.map((val, keys) => {
          if (keys == newProps.inhousestock.key) {
            val.inhouse = 0
            val['final need'] = val['total need'] - val.inhouse
          }
          return val
        })
        let data1 = this.state.rows.map((val, keys) => {
          if (keys == newProps.inhousestock.key) {
            val.inhouse = 0
            val.finalNeed = val.totalNeed - val.inhouse
            val.oldStock = val.newStock ? val.newStock : null
            val.newStock = undefined
          }
          return val
        })
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            rows: dataa
          },
          rows: data1,
          inhouse: inhouse
        })
        this.props.initialState();
      } else {
        this.props.initialState();
      }
    } else {
      if (MainOrder && MainOrder.success === true) {
        let data = MainOrder && MainOrder.order && MainOrder.order.map((value, key) => {
          let datainner = {}
          this.state.varient.map((items, i) => {
            datainner[items.name] = ''
            value.febricvarients && value.febricvarients.map(val => {
              if (val.varientId == items.id) {
                datainner[items.name] = val.value != '' ? val.value : ''
              }
            })
            return true;
          })
          return ({
            id: value.id,
            Delivery: value.deliveryDate != null ? value.deliveryDate : '',
            orderDescId: value.orderDescId != null ? value.orderDescId : '',
            Remarks: value.remarks != null ? value.remarks : '',
            addQty: value.addQTY != null ? value.addQTY : '',
            supplierId: value.supplierId != null ? value.supplierId : '',
            supplierIdtext: (value.febricSupllier && value.febricSupllier.id) ? value.febricSupllier.companyName : '',
            fabricTypetext: (value.febricMaterial && value.febricMaterial.id) ? value.febricMaterial.name : '',
            fabricAvg: value.fabricAverage != null ? value.fabricAverage : '',
            stylecode: value.febricDesc && value.febricDesc.stylecode,
            ...datainner,
            fabricType: value.materialId != null ? value.materialId : '',
            finalNeed: value.finalNeed != null ? value.finalNeed : '',
            inhouse: value.inHouse != null ? value.inHouse : '',
            pendingStatus: value.pendingStatus != null ? value.pendingStatus : 0,
            poStatus: value.poStatus != null ? value.poStatus : 'Pending',
            price: value.price != null ? value.price : '',
            recdStatus: value.recdStatus != null ? value.recdStatus : '',
            totalNeed: value.totalNeed != null ? value.totalNeed : '',
            newStock: value.inhouseId != null ? value.inhouseId : undefined,
            note: value.notes != null ? value.notes : ''
          })
        })
        let supllierDetail = []
        let data1 = MainOrder.order.length && MainOrder.order.map((value, key) => {
          let datainner = {}
          supllierDetail.push(value.febricSupllier)
          this.state.varient.map((items, i) => {
            datainner[items.field] = ''
            value.febricvarients && value.febricvarients.map(val => {
              if (val.varientId == items.id) {
                if(items.name === 'color'){
                  datainner[items.field] = [<span className="tooltip1" key={'abcd' + key}>{val.value !== null && val.value ? (val.value.length > 5 ? val.value.substr(0, 5) : val.value) : val.value}<span className="tooltiptext">{val.value}</span></span>]
                }else{
                  datainner[items.field] = val.value != null ? val.value : ''
                }
              }
            })
            return true;
          })
          var orderQty = ''
          if (value.febricDesc)
            orderQty = value.febricDesc.xs + value.febricDesc.s + value.febricDesc.m + value.febricDesc.l + value.febricDesc.xl + value.febricDesc.xxl + value.febricDesc['3xl'] + value.febricDesc['4xl'] + value.febricDesc['5xl']
          let poSucc = value.febricDesc !== null && orderQty !== '' && value.addQTY !== null && (value.febricSupllier && value.febricSupllier.id) && (value.febricMaterial && value.febricMaterial.id) && value.fabricAverage !== null && value.totalNeed !== null && value.deliveryDate !== null ? false : true
          return ({
            'Action': [<div style={{ display: 'flex' }}>{sessionStorage.userROLE == 'super-admin' || value.poStatus === 'Pending' || value.poStatus === null ? <><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i><i title="Add Row" className="fas fa-plus-circle cursor-pointer" key={'abce' + key} onClick={e => (this.onAdd(e, key))}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, value.id, poSucc))} ></i><input style={{ minWidth: '15px', marginLeft: '5px' }} type="checkbox" value={value.id} name="poGenrate" disabled={poSucc} title={poSucc === true ? 'Fill the row to generate PO.' : ''} key={key} onChange={e => (this.listPoGenrate(e, key))} /></> : <><i title="Edit" className="fas fa-pen pr-2 cursor-pointer" onClick={e => (this.onEdit(e, key))} key={'abc' + key}></i><i title="Add Row" className="fas fa-plus-circle cursor-pointer" key={'abce' + key} onClick={e => (this.onAdd(e, key))}></i><i key={'abcr' + key} title="View" className="fas fa-eye cursor-pointer" onClick={e => (this.preview(e, key, value.id, poSucc))} ></i></>}</div>],
            'Sr No': ((this.state.pageNumber - 1) * 10) + (key + 1),
            'Style No': value.febricDesc !== null ? value.febricDesc.styleno : '',
            'Style Code': [<span className="tooltip1" key={'abcd' + key}>{value.febricDesc !== null && value.febricDesc.stylecode ? (value.febricDesc.stylecode.length > 5 ? value.febricDesc.stylecode.substr(value.febricDesc.stylecode.length - 5) : value.febricDesc.stylecode) : value.febricDesc.stylecode}<span className="tooltiptext">{value.febricDesc.stylecode}</span></span>],
            'order qty': orderQty !== '' ? orderQty : '',
            'add qty': value.addQTY !== null ? value.addQTY : '',
            'supplier': [<span className="tooltip1" key={'abcde' + key} >{(value.febricSupllier && value.febricSupllier.id) ? (value.febricSupllier.companyName.length > 5 ? value.febricSupllier.companyName.substr(0, 5) : value.febricSupllier.companyName) : ''}<span className="tooltiptext">{value.febricSupllier && value.febricSupllier.companyName ? value.febricSupllier.companyName : ''}</span></span>],
            'type': [<span className="tooltip1" key={'abcdyu' + key} >{(value.febricMaterial && value.febricMaterial.id) ? (value.febricMaterial.name.length > 5 ? value.febricMaterial.name.substr(0, 5) : value.febricMaterial.name) : ''}<span className="tooltiptext">{value.febricMaterial && value.febricMaterial.name ? value.febricMaterial.name : ''}</span></span>],
            ...datainner,
            'avg': value.fabricAverage !== null ? value.fabricAverage : '',
            'total need': value.totalNeed !== null ? value.totalNeed : '',
            'inhouse': value.inHouse !== null && value.inHouse !== 0 ? value.inHouse : 0,
            'final need': value.finalNeed !== null && value.finalNeed !== 0 ? value.finalNeed : 0,
            'price': value.price !== null ? value.price : '',
            'Delivery': value.deliveryDate !== null ? moment(value.deliveryDate).format('DD/MM/YY') : '',
            'Remarks': [<span className="tooltip1" key={'abcd' + key}>{value.remarks !== null && value.remarks ? (value.remarks.length > 5 ? value.remarks.substr(0, 5) : value.remarks) : value.remarks}<span className="tooltiptext">{value.remarks}</span></span>],
            'po status': value.poStatus !== null ? <>{value.poStatus === 'Generated' || value.poStatus === 'Regenerated' ? <i class="fas fa-check" title={value.poStatus} style={{ color: '#4dba4f' }}></i> : <><i style={{ color: '#f15d5d' }} class="far fa-clock" title='Pending'></i></>}</> : <><i style={{ color: '#f15d5d' }} class="far fa-clock" title='Pending'></i></>,
            'recd status': value.recdStatus !== null ? value.recdStatus : '',
            'pending status': value.pendingStatus !== null ? value.pendingStatus : 0
          })
        })
        this.setState({
          data: {
            ...this.state.data,
            rows: data1
          },
          rows: data,
          total: newProps.MainOrder.page,
          supllierDetail: supllierDetail,
          loaderTable: false,
          pageHead: newProps.MainOrder.cat
        })
      }
      if (MainOrder && MainOrder.success === false) {
        this.setState({
          ...this.state,
          data: { ...this.state.data },
          loaderTable: false
        })
      }
    }
    if (orderFailed && orderFailed.success === false) {
      this.setState({
        ...this.state,
        data: { ...this.state.data },
        loaderTable: false
      })
      if (!toast.isActive(toastId))
        toastId = toast.error(orderFailed.message)
    }
  }
  searchChange = (e) => {
    const { slug, brandOrderId, pageNumber } = this.state
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        rows: []
      },
      rows: [],
      search: e.target.value
    })
    if (e.target.value !== '')
      this.props.materialOrder({ brandOrderId, pageNumber: 1, search: e.target.value, slug });
    else
      this.props.materialOrder({ brandOrderId, pageNumber: pageNumber, search: e.target.value, slug });
  }
  changePage = (e, pageNumber) => {
    const { slug, brandOrderId, search } = this.state
    this.setState({
      ...this.state,
      pageNumber: pageNumber,
      loaderTable: true,
      selectPoList: { id: [] }
    })
    this.props.materialOrder({ brandOrderId, pageNumber: pageNumber, search, slug });
  }
  preview = (e, rowId, id, poSucc) => {
    const { data, varient, supllierDetail, rows } = this.state
    let poTable = [{}]
    let poSupplierDetail = []
    let orderId, poStatus,noteGetRow
    supllierDetail.map((items, key) => {
      if (rowId == key) {
        poSupplierDetail = items
      }
    })
    rows.map((item, key) => {
      if (rowId === key) {
        orderId = rows[rowId]['id']
        poStatus = rows[rowId]['poStatus']
        noteGetRow = rows[rowId]['note']
      }
    })
    data.rows.map((value, key) => {
      if (rowId == key) {
        poTable[0]['Sr No'] = key + 1
        poTable[0]['Style Code'] = data.rows[key]['Style Code']
        poTable[0]['type'] = data.rows[key]['type']
        poTable[0]['final need'] = data.rows[key]['final need']
        poTable[0]['price'] = data.rows[key]['price']
        varient.map(items => {
          poTable[0][items.field] = data.rows[key][items.field]
        })
        poTable[0]['Delivery'] = data.rows[key]['Delivery']
        poTable[0]['Remarks'] = data.rows[key]['Remarks']
      }
      return true;
    })
    if (poSupplierDetail !== null) {
      this.setState({
        previewData: {
          columns: [
            { label: "S.N.", field: "Sr No", class: 'colum_width' },
            { label: 'Style Code', field: 'Style Code', class: 'colum_width' },
            { label: 'type', field: 'type', class: '' },
            { label: 'final need', field: 'final need', class: 'colum_width' },
            { label: 'price', field: 'price', class: '' },
            ...varient,
            { label: "Delivery", field: "Delivery", class: '' },
            { label: 'Remarks', field: 'Remarks', class: '' },
          ],
          rows: poTable
        },
        previewDataId: id,
        open: true,
        poStatus: poStatus,
        orderIdPo: orderId,
        poSucc: poSucc,
        noteGetRow:noteGetRow,
        poSupplierDetail: poSupplierDetail
      })
    }
  }
  previewClose = () => {
    const { brandOrderId, pageNumber, search, slug } = this.state
    this.setState({
      open: false
    })
    this.props.materialOrder({ brandOrderId, pageNumber, search, slug });
    this.props.noteinitialState()
  }
  POSingle = (e, rowId) => {
    let selectPoList = {
      id: [rowId.toString(), null]
    }
    this.setState({
      ...this.state,
      loaderPo: true,
      loaderTable: true,
      open: false
    })
    this.props.poGeneratePDF(selectPoList)
  }
  poGenerate = (e, rowId) => {
    const { selectPoList, varient, rows } = this.state
    this.setState({
      loaderPo: true,
      loaderTable: true
    })
    this.props.poGeneratePDF(selectPoList)
  }
  listPoGenrate = (e, key) => {
    const { selectPoList } = this.state
    let index;
    if (e.target.checked) {
      this.setState({
        selectPoList: { ...selectPoList, id: [...selectPoList.id, e.target.value] }
      })
    } else {
      index = selectPoList.id.indexOf(e.target.value)
      selectPoList.id.splice(index, 1)
      this.setState({
        selectPoList: { ...selectPoList, id: [...selectPoList.id] }
      })
    }
  }
  render() {
    const { brandOrderId, name, brandImg, brand, open, previewData, previewDataId, poSupplierDetail, suppliers, loaderPo, pageHead, slug, selectPoList, loaderTable, loaderVarient, orderIdPo, poStatus, poSucc,noteGetRow } = this.state
    return (
      <>
        <ToastContainer autoClose={10000} />
        {
          !loaderTable && !loaderVarient ?
            <>
              <Header />
              <Container className="" fluid>
                <Row>
                  <div className="col">
                    <Card className="border-0">
                      <CardHeader className="border-0 pt-0">
                        <h1 className="m-0 text-capitalize">{pageHead}</h1>
                        <Row className="align-items-center">
                          <div className="col-md-8 col-12 d-flex pl-0">
                            <div style={{ paddingBottom: '5px', marginRight: '25px' }}>
                              <h6 className="text-uppercase text-light ls-1 mb-1">
                                Brand
                              </h6>
                              <h3 className="mb-0 profile_sec text-capitalize">
                                {
                                  (brandImg !== '' && brandImg !== null) ?
                                    <img className="mr-3" src={`${imagerUrl}` + brandImg} width="40" alt="brand"></img>
                                    : <img className="mr-3" src={'/logo.png'} width="40" alt="brand"></img>
                                }
                                {brand}</h3>
                            </div>
                            <div style={{ paddingBottom: '5px' }}>
                              <h6 className="text-uppercase text-light ls-1 mb-1">
                                Order name
                            </h6>
                              <h2 className="mb-0">
                                {name}</h2>
                            </div>
                          </div>
                          <div className="col-md-4 col-12 text-right d-flex justify-contant-flex-end justify-contant-flex-xs-start pl-0">
                            <Button style={{ marginRight: '20px' }} type="submit" disabled={loaderPo === true || selectPoList.id == undefined || selectPoList.id[0] == null ? true : false} className="btn btn-primary" color="primary" onClick={this.poGenerate}>{
                              loaderPo === true ?
                                <Spinner style={{ width: '19px', height: '19px' }} /> :
                                'Generate PO'
                              }
                            </Button>
                            <Nav className="justify-content-end" pills>
                              <NavItem>
                                <NavLink className="btn btn-primary " to={'/fabric/' + slug} tag={Link}  >Back</NavLink>
                              </NavItem>
                            </Nav>
                          </div>
                        </Row>
                      </CardHeader>
                      <div className="table-responsive">
                        <EditTable searchChange={this.searchChange} changePage={this.changePage} {...this.state} />
                      </div>
                    </Card>
                  </div>
                </Row>
                <PreviewModal poStatus={poStatus} noteChange={this.noteChange} previewData={previewData} toggle={this.previewClose} noteSubmit={this.noteSubmit} open={open} liningsupplier={suppliers} previewDataId={previewDataId} poSupplierDetail={poSupplierDetail} orderIdPo={orderIdPo} POSingle={this.POSingle} loaderPo={loaderPo} poSucc={poSucc} noteGetRow={noteGetRow} />
              </Container>
            </>
            :
            <div className="loader">
              <Spinner variant="light" />
              <p style={{ color: '#fff', paddingTop: '15px' }}>Please wait......</p>
            </div>
        }
      </>
    )
  }
}


const mapDispatchState = (dispatch) => {
  return bindActionCreators({
    varientBySlug,
    getBrandOrder,
    addMaterialOrder,
    materialOrder,
    initialState,
    getInhouseStocks,
    supplier,
    liningSupplier,
    poGeneratePDF,
    noteinitialState,
    varienInitial
  }, dispatch)
}
const mapGetStateNew = (state) => {
  return {
    varientList: state.varient.varient,
    brandorder: state.order.brandorder,
    supplierSuccess: state.supplier.supplier,
    liningsupplier: state.supplier.liningsupplier,
    MainOrder: state.order.order,
    orderFailed: state.order.orderFailed,
    inhousestock: state.order.inhousestock,
    poGenrateSuccess: state.poPDF.poGenrate,
    noteSuccess: state.order.note
  }
}
export default connect(mapGetStateNew, mapDispatchState)(Mainfabricsheet);
